import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import superagent from 'superagent'
import _ from 'lodash'
import { showToast } from '@/shared/utils/toast'
import { getPhotoPreview } from '@/shared/utils/photos'
import { handleError } from '@/shared/api'
import { Button, Field, Icon } from '@/shared/components'

const propTypes = {
  stripe: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

const c = 'hostPayment_documentUpload'

const initialFileState = () => ({
  previewfront: null,
  previewback: null,
  filefront: null,
  fileback: null,
  isUploading: false,
})

class DocumentUpload extends Component {

  state = { idType: null, ...initialFileState() }

  onDrop = (files, frontOrBack) => {
    getPhotoPreview(files[0], preview => {
      this.setState({
        [`preview${frontOrBack}`]: preview,
        [`file${frontOrBack}`]: files[0],
      })
    })
  }

  handleSubmit = () => {
    if (this.state.fileback) {
      this.uploadImageToStripe(this.state.filefront, frontTokenId => {
        this.uploadImageToStripe(this.state.fileback, backTokenId => {
          this.createTokenAndUpdateAccount({ front: frontTokenId, back: backTokenId })
        })
      })
    } else {
      this.uploadImageToStripe(this.state.filefront, frontTokenId => {
        this.createTokenAndUpdateAccount({ front: frontTokenId })
      })
    }
  }

  uploadImageToStripe = (file, after) => {
    this.setState({ isUploading: true })
    this.stripe = this.props.stripe(window.STRIPE_PUBLISHABLE_KEY)

    superagent
      .post('https://uploads.stripe.com/v1/files')
      .set({'Authorization': `Bearer ${this.stripe._apiKey}`})
      .attach('file', file)
      .field('purpose', 'identity_document')
      .then(res => after(res.body.id))
      .catch(() => {
        showToast({
          duration: 30,
          type: 'danger',
          title: 'The image failed to upload',
          message: 'Please try again. The uploaded file needs to be a color image (smaller than 8,000px by 8,000px), in JPG or PNG format, and less than 5MB in size.',
        })
        this.setState({ isUploading: false })
      })
  }

  createTokenAndUpdateAccount = tokenIds => {
    const handleStripeError = description => {
      handleError({ error: { description } })
      this.setState({ isUploading: false })
    }
    this.stripe.createToken('account', {
      individual: {
        verification: {
          document: tokenIds,
        },
      },
    }).then(result => {
      if (result.error) {
        handleStripeError(result.error.message)
      } else {
        this.props.onSubmit({
          account_token: result.token.id,
        }).then(() => {}, ({ error }) => {
          handleStripeError(error.description || error.message)
        })
      }
    }, error => handleStripeError(error.message))
  }

  arePhotosAdded = () => {
    if (!this.state.filefront) return false
    if (this.state.idType === 'drivers' && !this.state.fileback) return false
    return true
  }

  renderDropzone = frontOrBack => (
    <div className={`${c}_dropzone_cont`}>
      <Dropzone
        className={`${c}_dropzone`}
        activeClassName={`${c}_dropzone--isActive`}
        maxSize={10000000}
        multiple={false}
        accept="image/jpeg, image/png, image/gif"
        onDrop={files => this.onDrop(files, frontOrBack)}
        disabled={this.state.isUploading}
      >
        <Icon type="plus" top={1} />
        {`Add Document ${_.upperFirst(frontOrBack)}`}
      </Dropzone>
      {this.state[`preview${frontOrBack}`] && (
        <img src={this.state[`preview${frontOrBack}`]} className={`${c}_preview`} />
      )}
    </div>
  )

  render() {
    return (
      <div className={`${c}_cont`}>
        <div className={c}>
          <h2>Please upload a photo of government-issued ID</h2>
          <p className={`${c}_tip`}>
            {"A passport photo is preferred but any government-issued ID will do. For IDs that have information on two sides, like the drivers licence, you must provide photos of both the front and back of the ID."}
          </p>
          <p className={`${c}_tip`}>
            {"You can take a photo of your ID using your phone, your computer’s or mobile device’s web camera, or upload an existing photo of your ID. The uploaded file(s) needs to be a color image (smaller than 8,000px by 8,000px), in JPG or PNG format, and less than 5MB in size."}
          </p>
          <Field.Select
            label="What type of ID will you upload?"
            options={[
              { value: '', label: 'Select' },
              { value: 'passport', label: 'Passport' },
              { value: 'drivers', label: 'Drivers Licence' },
              { value: 'other', label: 'Other' },
            ]}
            onChange={(e, idType) => {
              this.setState({ idType, ...initialFileState() })
            }}
          />
          {this.state.idType && (
            <Fragment>
              <div className={`${c}_dropzones`}>
                {this.renderDropzone('front')}
                {this.state.idType !== 'passport' && this.renderDropzone('back')}
              </div>
              {this.arePhotosAdded() && (
                <div className={`${c}_actions`}>
                  <Button working={this.state.isUploading} onClick={this.handleSubmit}>
                    Upload
                  </Button>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

DocumentUpload.propTypes = propTypes

export default DocumentUpload
