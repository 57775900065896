import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleError } from '@/shared/api'
import { updateCurrentUser } from '@/features/auth/state/actions'
import { AvatarUploader } from '@/shared/components'

const mapDispatchToProps = { updateCurrentUser }

const propTypes = {
  currentUser: PropTypes.object,
  updateCurrentUser: PropTypes.func.isRequired,
}

class UploadAvatar extends Component {

  handleUpload = avatar => {
    this.props.updateCurrentUser({ avatar }).then(() => {}, handleError)
  }

  render() {
    return (
      <div className="authConfirm_avatar">
        <div className="authConfirm_avatar_subtitle">Last Step</div>
        <h2 className="authConfirm_avatar_title">Upload Profile Photo</h2>
        <AvatarUploader
          currentUser={this.props.currentUser}
          onChange={this.handleUpload}
        />
      </div>
    )
  }
}

UploadAvatar.propTypes = propTypes

export default connect(null, mapDispatchToProps)(UploadAvatar)
