import { objectToQueryString } from '@/shared/utils/url'

export const openWindow = provider => {
  provider === 'google' ? openGoogleWindow() : openFacebookWindow()
}

export const closeWindowIfOpen = () => {
  if (window.oauthWindow) (
    window.oauthWindow.close()
  )
}

const openGoogleWindow = () => {
  closeWindowIfOpen()

  const query = objectToQueryString({
    client_id: window.GOOGLE_CLIENT_ID,
    redirect_uri: window.GOOGLE_REDIRECT_URL,
    response_type: 'code',
    scope: 'profile email https://www.googleapis.com/auth/user.birthday.read',
  })
  window.oauthWindow = window.open(
    `https://accounts.google.com/o/oauth2/v2/auth?${query}`,
    `oauthGoogleWindowName`,
    getWindowAttributes()
  )
}

const openFacebookWindow = () => {
  closeWindowIfOpen()

  const query = objectToQueryString({
    client_id: window.FACEBOOK_CLIENT_ID,
    redirect_uri: window.FACEBOOK_REDIRECT_URL,
    response_type: 'code',
    scope: 'public_profile,email,user_birthday',
  })
  window.oauthWindow = window.open(
    `https://www.facebook.com/v2.11/dialog/oauth?${query}`,
    `oauthFacebookWindowName`,
    getWindowAttributes()
  )
}

const getWindowAttributes = () => {
  const height = 480
  const width = 600
  const left = window.innerWidth/2 - width/2
  const top = window.innerHeight/2 - height/2
  return  `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`
}
