import React, { Component, Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { formatDateRange, stringToDate, dateToString } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { showToast } from '@/shared/utils/toast'
import { availableToUnavailableDates } from '@/shared/utils/availability'
import { fetchAvailability, fetchRates, extendBooking } from '@/guestBookings/api'
import { DateRangePicker, Modal, Button, ConfirmModal,
  FieldErrors, BookingFees, Spinner } from '@/shared/components'
import MessageModal from '@/features/messageModal/components'
import '@/guestBookings/styles/extendBooking.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const c = 'guestBooking_extendBooking'

const ExtendBooking = props => {
  const { booking, currentUser } = props

  const isExtendableByCurrentUser = (
    /regular|sponsor/.test(booking.kind) &&
    booking.billingUser.id === currentUser.id
  )
  const isExtendableBySponsor = (
    /sponsor/.test(booking.kind) &&
    booking.billingUser.id !== currentUser.id
  )
  const isExtendableByHost = (
    /program|import/.test(booking.kind)
  )
  return (
    <Fragment>
      {isExtendableByCurrentUser && (
        <ExtendBookingModal {...props} />
      )}
      {isExtendableBySponsor && (
        <ConfirmModal
          title="Extend Booking"
          message="Please ask the person who booked your stay to sign into their Roomsie account and extend your booking."
          cancelText="Close"
          confirmText="Okay"
          renderLink={() => <Button>Extend Booking</Button>}
          onConfirm={() => {}}
        />
      )}
      {isExtendableByHost && (
        <MessageModal
          description="Please tell your host to which date you'd like to extend your booking."
          recipient={booking.host}
          contextId={{ bookingId: booking.id }}
          buttonLabel="Extend Booking"
          buttonProps={{ hollow: false, size: 'medium' }}
        />
      )}
    </Fragment>
  )
}

ExtendBooking.propTypes = propTypes

const ExtendBookingModal = (props) => {

  const navigate = useNavigate()
  const isFirstRender = useRef(true)

  const getInitialProps = booking => ({
    availableDates: null,
    rates: null,
    error: null,
    dates: {
      from: stringToDate(booking.startDate),
      to: stringToDate(booking.endDate),
    },
    isFetchingRates: false,
    isSubmitting: false,
  })

  const [state, setState] = useState(() => (getInitialProps(props.booking)))

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return
    }
    calculateRates()
  }, [state.dates])
  
  const fetchBookingAvailability = () => {
    if (state.availableDates) return

    fetchAvailability(props.booking.property.id, {
      listingId: props.booking.listing.id,
    }).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        availableDates: data 
      }))
    })
  }

  const handleChange = dates => {
    const { startDate, endDate } = props.booking

    if (!dates.from || moment(stringToDate(startDate)).isBefore(dates.from)) {
      dates.from = stringToDate(startDate)
    }
    if (!dates.to || moment(stringToDate(endDate)).isAfter(dates.to)) {
      dates.to = stringToDate(endDate)
    }
    if (startDate === dateToString(dates.from) && endDate === dateToString(dates.to)) {
      return setState(prevState => ({
        ...prevState,
        dates: dates, 
        rates: null, 
        error: null 
      }))
    }
    setState(prevState => ({
      ...prevState,
      dates: dates, 
      isFetchingRates: true
    }))
  }

  const calculateRates = () => {
    fetchRates(props.booking.id, {
      startDate: dateToString(state.dates.from),
      endDate: dateToString(state.dates.to),
    }).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        rates: data,
        error: null,
        isFetchingRates: false, 
      }))
    }, ({ error }) => {
      setState(prevState => ({
        ...prevState,
        rates: null,
        error: error.details ? error.details.base : error.description,
        isFetchingRates: false,
      }))
    })
  }

  const handleSubmit = closeModal => {
    const { dates, error } = state

    if (dates.from && dates.to && !error) {
      setState(prevState => ({
        ...prevState,
        isSubmitting: true 
      }))

      extendBooking(props.booking.id, {
        startDate: dateToString(dates.from),
        endDate: dateToString(dates.to),
      }).then(() => {
        props.fetchBooking(() => {
          setState(prevState => (
            getInitialProps(props.booking)
          ))
          closeModal()
          showToast({ title: 'Your booking was extended successfully.' })
        })
      }, ({ error }) => {
        setState(prevState => ({
          ...prevState,
          isSubmitting: false 
        }))
        showToast({
          duration: 0,
          title: 'Please add a payment method',
          message: 'You need to add a payment method before extending your booking.',
          cancelText: 'Cancel',
          confirmText: 'Add payment method',
          onConfirm: () => navigate('/guest/payment-center'),
        })
      })
    }
  }

  const getDisabledDates = () => (
    availableToUnavailableDates(
      [...state.availableDates, {
        startDate: props.booking.startDate,
        endDate: props.booking.endDate,
      }].map(date => ({
        from: stringToDate(date.startDate),
        to: stringToDate(date.endDate),
      }))
    )
  )
  
  const { booking } = props
  const { availableDates, dates, rates, error, isSubmitting } = state
  return (
    <Modal
      className={c}
      containsForm
      onOpen={fetchBookingAvailability}
      renderLink={() => (
        <Button>Extend My Stay</Button>
      )}
      renderContent={({ closeModal }) => (
        <Fragment>
          {!availableDates && <Spinner />}
          <div className={`${c}_title`}>
            Request to Extend My Stay
          </div>
          <p className={`${c}_message`}>
            You can move-up your arrival date and/or extend your departure date. Shortening the booking in any way is not allowed.
          </p>
          <FieldErrors errors={error} />
          {availableDates && !!availableDates.length && (
            <DateRangePicker
              placeholder="Check In — Check Out"
              value={dates}
              onChange={handleChange}
              disabledDays={getDisabledDates()}
              tooltipProps={{ windowBounds: { bottom: true } }}
              closeOnPick={false}
            />
          )}
          {availableDates && !availableDates.length && (
            <p className={`${c}_notAvailable`}>
              You can not extend this booking because the listing is fully booked.
            </p>
          )}
          {rates && (
            <Fragment>
              <p className={`${c}_ratesExplanationTop`}>
                {'Additional charges related to your request:'}
              </p>
              <BookingFees rates={rates} />
              <p className={`${c}_ratesExplanationBottom`}>
                {'The previous grand total for '}
                <span>{formatDateRange(booking.startDate, booking.endDate)}</span>
                {' was '}
                <span>{formatPrice(booking.total)}</span>
                {', and extending your stay to '}
                <span>{formatDateRange(dates.from, dates.to)}</span>
                {' will cost an additional '}
                <span>{formatPrice(rates.total)}</span>
                {'.'}
              </p>
            </Fragment>
          )}
          <Button hollow color="secondary" onClick={closeModal}>Close</Button>
          {availableDates && !!availableDates.length && (
            <Button
              disabled={!rates}
              working={isSubmitting}
              onClick={() => handleSubmit(closeModal)}
            >
              {rates ? (
                `Request Extension for ${formatPrice(rates.charges[0].total)}`
              ) : (
                'Request Extension'
              )}
            </Button>
          )}
        </Fragment>
      )}
    />
  )
}

export default ExtendBooking
