import React from "react";
import { useOutletContext } from "react-router-dom";

const StepComponent = (props) => {
  const { id, path, Component } = props
  
  const [ 
    generatedPath, 
    updateQuery, 
    sessions,
    buildings,
    buildingId,
    setBuildingId,
    selectedBedroom,
    setSelectedBedroom,
    property,
    rates,
    userInput,
    updateUserInput,
    getQuery,
    state,
    submitForm,
    steps,
    toStep,
    goToStep,
    validateStep,
    clearStepErrors,
    resetFormState
   ] = useOutletContext()

  return (
    <Component
      generatedPath={generatedPath}
      updateQuery={updateQuery}
      sessions={sessions}
      buildings={buildings}
      buildingId={buildingId}
      setBuildingId={setBuildingId}
      selectedBedroom={selectedBedroom}
      setSelectedBedroom={setSelectedBedroom}
      property={property}
      rates={rates}
      userInput={userInput}
      updateUserInput={updateUserInput}
      query={getQuery()}
      isSubmitting={state.isSubmitting}
      submitForm={submitForm}
      steps={steps}
      toStep={toStep}
      goToStep={goToStep}
      errors={state.errors[id]}
      validateStep={validateStep(id)}
      clearStepErrors={clearStepErrors(id)}
      resetFormState={resetFormState}
    />
  )
}

export default StepComponent