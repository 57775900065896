import React, { Fragment } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Modal, Button } from '@/shared/components'
import ProfileShow from './ProfileShow'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'

const ProfileShowModal = props => {

  const [searchParams] = useSearchParams()
  const userId = searchParams.get('profile_modal')

  const { toClose, isOpen } = useProfileModalUrl()

  return (
    isOpen() && (
      <Modal
        className="profileShow profileShowModal"
        isOpen
        beforeClose={toClose}
        renderContent={({ closeModal }) => (
          <Fragment>
            <ProfileShow userId={userId} {...props} closeModal={closeModal} />
            <div className="profileShowModal_closeContainer">
              <Button color="secondary" onClick={closeModal}>Close</Button>
            </div>
          </Fragment>
        )}
      />
    )
  )
}

export default ProfileShowModal
