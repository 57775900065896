import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import options from '@/shared/static/options'
import { showToast } from '@/shared/utils/toast'
import { formatAddress } from '@/shared/utils/geo'
import { stringToDate, dateToString, formatDateRange } from '@/shared/utils/dateTime'
import { getBedsWithDisplayId } from '@/shared/utils/beds'
import { sortPhotos } from '@/shared/utils/photos'
import { handleError } from '@/shared/api'
import * as api from '@/hostBookings/api'
import { Modal, Button, Select, Photo, ConfirmModal, Spinner, Icon } from '@/shared/components'
import '@/hostBookings/styles/moveGuest.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const c = 'hostBooking_moveGuest'

class Extend extends Component {

  state = {
    selectedPropertyId: '',
    property: null,
    properties: null,
    availabilityRanges: null,
    isFetchingProperty: false,
  }

  fetchProperties = () => {
    api.fetchProperties({ paused: false }).then(({ data }) => {
      this.setState({
        properties: data.filter(property => (
          property.placementType === 'unisex' ||
          property.placementType === this.props.booking.guest.gender
        ))
      })
    }, handleError)
  }

  handlePropertySelect = (e, selectedPropertyId) => {
    this.setState({
      selectedPropertyId,
      property: null,
      availabilityRanges: null,
      isFetchingProperty: true,
    })
    api.fetchMoveBedAvailability(selectedPropertyId, this.getNewDates()).then(({ data }) => {
      this.setState({ availabilityRanges: data }, () => {
        api.fetchProperty(selectedPropertyId).then(({ data }) => {
          this.setState({ property: data, isFetchingProperty: false })
        }, handleError)
      })
    }, handleError)
  }

  handleRelocate = async (listingId, confirmModal) => {
    api.relocateBooking(this.props.booking.id, listingId).then(() => {
      this.props.fetchBooking()
      this.closeModal()
      showToast({ title: 'The guest was moved successfully.' })
    }, () => {
      confirmModal.close()
      showToast({
        type: 'danger',
        title: `This listing is not available for ${this.getFormattedNewDates()}.`,
      })
    })
  }

  isBedAvailable = bed => (
    this.state.availabilityRanges.some(l => l.listingId === bed.listing.id)
  )

  getNewDates = () => ({
    startDate: dateToString(moment.max(stringToDate(this.props.booking.startDate), moment())),
    endDate: this.props.booking.endDate,
  })

  getFormattedNewDates = () => (
    formatDateRange(this.getNewDates().startDate, this.getNewDates().endDate)
  )

  matchAvailabilityDate = bed => (
    this.getBedUnavailabilityReason(bed).startDate === this.getNewDates().startDate &&
    this.getBedUnavailabilityReason(bed).endDate === this.getNewDates().endDate
  )

  getBedUnavailabilityReason = bed => (
    this.state.property.bedUnavailabilityReasons.find(bur => bur.bedId === bed.id) || {}
  )

  renderProperty = property => (
    <div className={`${c}_property`}>
      <Photo photo={sortPhotos(property.photos)[0]} />
      <div className={`${c}_property_info`}>
        <div className={`${c}_property_title`}>{property.title}</div>
        <div className={`${c}_property_address`}>{formatAddress(property)}</div>
        <div className={`${c}_property_listingType`}>
          {`${options.labels.placementTypes[property.placementType]}
            ${options.labels.listingTypes[property.listingType].replace('Space', '')} Space`}
        </div>
        <Link
          to={`/properties/${property.id}`}
          target="_blank"
          className={`${c}_property_viewListing`}
        >
          View Listing
        </Link>
        <br/>
        {property.listingType === 'entire_space' && (
          this.renderSubmitButton(property.listing.id)
        )}
      </div>
    </div>
  )

  renderBedrooms = property => (
    <div className={`${c}_bedrooms`}>
      {property.bedrooms.sort((a, b) => a.id - b.id).map(bedroom => (
        <Fragment key={bedroom.id}>
          <div className={`${c}_bedroom`}>{bedroom.title}</div>
          {getBedsWithDisplayId(bedroom.beds).map(this.renderBed)}
        </Fragment>
      ))}
    </div>
  )

  renderBed = bed => (
    <div key={bed.id} className={classnames(`${c}_bed`, {
      [`${c}_bed--isUnavailable`]: !this.isBedAvailable(bed),
    })}>
      <span className={`${c}_bed_displayId`}>{bed.displayId}</span>
      <span className={`${c}_bed_size`}>Size: {options.labels.bedSizes[bed.size]}</span>
      <span className={`${c}_bed_kind`}>Type: {options.labels.bedTypes[bed.kind]}</span>
      {this.isBedAvailable(bed) ? (
        this.renderSubmitButton(bed.listing.id)
      ) : (
        <span>
          {  _.isEmpty(this.getBedUnavailabilityReason(bed)) ? (
            <span>Not Available</span>
          ) : (
            this.matchAvailabilityDate(bed) ? (
              <span>
                {`${this.getBedUnavailabilityReason(bed).status} - `}
                  <Link
                    to={`/host/bookings/${this.getBedUnavailabilityReason(bed).bookingId}`}
                    target="_blank"
                    key={this.getBedUnavailabilityReason(bed).bookingId}
                    className="hostBooking_moveGuest_property_viewListing"
                  >
                  {this.getBedUnavailabilityReason(bed).firstName} {this.getBedUnavailabilityReason(bed).lastName}
                </Link>
              </span>
            ) : (
              <span>Not Available</span>
            )

          )}
        </span>
      )}
    </div>
  )

  renderSubmitButton = listingId => (
    <ConfirmModal
      isDanger
      title="Move Guest"
      message="Moving or relocating a guest should only be done with their expressed and written consent. Moving your guest without their express written permission can and will result in the strict enforcement of Roomsie’s refund policy and delisting of your property(ies)."
      confirmText="Move Guest"
      dontCloseOnConfirm
      renderLink={() => <Button size="small">Select</Button>}
      onConfirm={confirmModal => this.handleRelocate(listingId, confirmModal)}
    />
  )

  render() {
    const { selectedPropertyId, property, properties, isFetchingProperty } = this.state
    return (
      <Modal
        className={c}
        onOpen={() => !properties && this.fetchProperties()}
        renderLink={() => (
          <Button size="small">Move Guest</Button>
        )}
        renderContent={({ closeModal }) => {
          this.closeModal = closeModal
          return (
            <Fragment>
              <Icon type="warning" />
              <div className={`${c}_title`}>Move Guest</div>
              <p className={`${c}_message`}>
                Relocate your guest to another room or property only with their written permission
              </p>
              <Select
                placeholder="Select a property"
                options={(properties || []).map(p => ({
                  label: `${p.title}, ${p.streetAddress}, ${p.streetAddress2 || ''}`,
                  value: p.id,
                }))}
                value={selectedPropertyId}
                onChange={this.handlePropertySelect}
              />
              {isFetchingProperty && <Spinner className={`${c}_propertySpinner`} />}
              {property && this.renderProperty(property)}
              {property && property.listingType !== 'entire_space' && (
                <div className={`${c}_availabilityDates`}>
                  {`Availability for ${this.getFormattedNewDates()}:`}
                </div>
              )}
              {property && this.renderBedrooms(property)}
              <Button hollow size="small" color="secondary" className={`${c}_cancel`} onClick={closeModal}>
                Cancel
              </Button>
            </Fragment>
          )
        }}
      />
    )
  }
}

Extend.propTypes = propTypes

export default Extend
