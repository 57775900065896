const countries = [
  ["United States of America", 'USA', 'US'],
  ["Afghanistan", 'AFG', 'AF'],
  ["Albania", 'ALB', 'AL'],
  ["Algeria", 'DZA', 'DZ'],
  ["American Samoa", 'ASM', 'AS'],
  ["Andorra", 'AND', 'AD'],
  ["Angola", 'AGO', 'AO'],
  ["Anguilla", 'AIA', 'AI'],
  ["Antarctica", 'ATA', 'AQ'],
  ["Antigua and Barbuda", 'ATG', 'AG'],
  ["Argentina", 'ARG', 'AR'],
  ["Armenia", 'ARM', 'AM'],
  ["Aruba", 'ABW', 'AW'],
  ["Australia", 'AUS', 'AU'],
  ["Austria", 'AUT', 'AT'],
  ["Azerbaijan", 'AZE', 'AZ'],
  ["Bahamas", 'BHS', 'BS'],
  ["Bahrain", 'BHR', 'BH'],
  ["Bangladesh", 'BGD', 'BD'],
  ["Barbados", 'BRB', 'BB'],
  ["Belarus", 'BLR', 'BY'],
  ["Belgium", 'BEL', 'BE'],
  ["Belize", 'BLZ', 'BZ'],
  ["Benin", 'BEN', 'BJ'],
  ["Bermuda", 'BMU', 'BM'],
  ["Bhutan", 'BTN', 'BT'],
  ["Bolivia", 'BOL', 'BO'],
  ["Bosnia and Herzegovina", 'BIH', 'BA'],
  ["Botswana", 'BWA', 'BW'],
  ["Bouvet Island", 'BVT', 'BV'],
  ["Brazil", 'BRA', 'BR'],
  ["British Virgin Islands", 'VGB', 'VG'],
  ["British Indian Ocean Territory", 'IOT', 'IO'],
  ["Brunei Darussalam", 'BRN', 'BN'],
  ["Bulgaria", 'BGR', 'BG'],
  ["Burkina Faso", 'BFA', 'BF'],
  ["Burundi", 'BDI', 'BI'],
  ["Cambodia", 'KHM', 'KH'],
  ["Cameroon", 'CMR', 'CM'],
  ["Canada", 'CAN', 'CA'],
  ["Cape Verde", 'CPV', 'CV'],
  ["Cayman Islands", 'CYM', 'KY'],
  ["Central African Republic", 'CAF', 'CF'],
  ["Chad", 'TCD', 'TD'],
  ["Chile", 'CHL', 'CL'],
  ["China", 'CHN', 'CN'],
  ["Hong Kong, SAR China", 'HKG', 'HK'],
  ["Macao, SAR China", 'MAC', 'MO'],
  ["Christmas Island", 'CXR', 'CX'],
  ["Cocos (Keeling) Islands", 'CCK', 'CC'],
  ["Colombia", 'COL', 'CO'],
  ["Comoros", 'COM', 'KM'],
  ["Congo (Brazzaville)", 'COG', 'CG'],
  ["Congo, (Kinshasa)", 'COD', 'CD'],
  ["Cook Islands", 'COK', 'CK'],
  ["Costa Rica", 'CRI', 'CR'],
  ["Côte d'Ivoire", 'CIV', 'CI'],
  ["Croatia", 'HRV', 'HR'],
  ["Cuba", 'CUB', 'CU'],
  ["Cyprus", 'CYP', 'CY'],
  ["Czech Republic", 'CZE', 'CZ'],
  ["Denmark", 'DNK', 'DK'],
  ["Djibouti", 'DJI', 'DJ'],
  ["Dominica", 'DMA', 'DM'],
  ["Dominican Republic", 'DOM', 'DO'],
  ["Ecuador", 'ECU', 'EC'],
  ["Egypt", 'EGY', 'EG'],
  ["El Salvador", 'SLV', 'SV'],
  ["Equatorial Guinea", 'GNQ', 'GQ'],
  ["Eritrea", 'ERI', 'ER'],
  ["Estonia", 'EST', 'EE'],
  ["Ethiopia", 'ETH', 'ET'],
  ["Falkland Islands (Malvinas)", 'FLK', 'FK'],
  ["Faroe Islands", 'FRO', 'FO'],
  ["Fiji", 'FJI', 'FJ'],
  ["Finland", 'FIN', 'FI'],
  ["France", 'FRA', 'FR'],
  ["French Guiana", 'GUF', 'GF'],
  ["French Polynesia", 'PYF', 'PF'],
  ["French Southern Territories", 'ATF', 'TF'],
  ["Gabon", 'GAB', 'GA'],
  ["Gambia", 'GMB', 'GM'],
  ["Georgia", 'GEO', 'GE'],
  ["Germany", 'DEU', 'DE'],
  ["Ghana", 'GHA', 'GH'],
  ["Gibraltar", 'GIB', 'GI'],
  ["Greece", 'GRC', 'GR'],
  ["Greenland", 'GRL', 'GL'],
  ["Grenada", 'GRD', 'GD'],
  ["Guadeloupe", 'GLP', 'GP'],
  ["Guam", 'GUM', 'GU'],
  ["Guatemala", 'GTM', 'GT'],
  ["Guernsey", 'GGY', 'GG'],
  ["Guinea", 'GIN', 'GN'],
  ["Guinea-Bissau", 'GNB', 'GW'],
  ["Guyana", 'GUY', 'GY'],
  ["Haiti", 'HTI', 'HT'],
  ["Heard and Mcdonald Islands", 'HMD', 'HM'],
  ["Holy See (Vatican City State)", 'VAT', 'VA'],
  ["Honduras", 'HND', 'HN'],
  ["Hungary", 'HUN', 'HU'],
  ["Iceland", 'ISL', 'IS'],
  ["India", 'IND', 'IN'],
  ["Indonesia", 'IDN', 'ID'],
  ["Iran, Islamic Republic of", 'IRN', 'IR'],
  ["Iraq", 'IRQ', 'IQ'],
  ["Ireland", 'IRL', 'IE'],
  ["Isle of Man", 'IMN', 'IM'],
  ["Israel", 'ISR', 'IL'],
  ["Italy", 'ITA', 'IT'],
  ["Jamaica", 'JAM', 'JM'],
  ["Japan", 'JPN', 'JP'],
  ["Jersey", 'JEY', 'JE'],
  ["Jordan", 'JOR', 'JO'],
  ["Kazakhstan", 'KAZ', 'KZ'],
  ["Kenya", 'KEN', 'KE'],
  ["Kiribati", 'KIR', 'KI'],
  ["Korea (North)", 'PRK', 'KP'],
  ["Korea (South)", 'KOR', 'KR'],
  ["Kuwait", 'KWT', 'KW'],
  ["Kyrgyzstan", 'KGZ', 'KG'],
  ["Lao PDR", 'LAO', 'LA'],
  ["Latvia", 'LVA', 'LV'],
  ["Lebanon", 'LBN', 'LB'],
  ["Lesotho", 'LSO', 'LS'],
  ["Liberia", 'LBR', 'LR'],
  ["Libya", 'LBY', 'LY'],
  ["Liechtenstein", 'LIE', 'LI'],
  ["Lithuania", 'LTU', 'LT'],
  ["Luxembourg", 'LUX', 'LU'],
  ["Macedonia, Republic of", 'MKD', 'MK'],
  ["Madagascar", 'MDG', 'MG'],
  ["Malawi", 'MWI', 'MW'],
  ["Malaysia", 'MYS', 'MY'],
  ["Maldives", 'MDV', 'MV'],
  ["Mali", 'MLI', 'ML'],
  ["Malta", 'MLT', 'MT'],
  ["Marshall Islands", 'MHL', 'MH'],
  ["Martinique", 'MTQ', 'MQ'],
  ["Mauritania", 'MRT', 'MR'],
  ["Mauritius", 'MUS', 'MU'],
  ["Mayotte", 'MYT', 'YT'],
  ["Mexico", 'MEX', 'MX'],
  ["Micronesia, Federated States of", 'FSM', 'FM'],
  ["Moldova", 'MDA', 'MD'],
  ["Monaco", 'MCO', 'MC'],
  ["Mongolia", 'MNG', 'MN'],
  ["Montenegro", 'MNE', 'ME'],
  ["Montserrat", 'MSR', 'MS'],
  ["Morocco", 'MAR', 'MA'],
  ["Mozambique", 'MOZ', 'MZ'],
  ["Myanmar", 'MMR', 'MM'],
  ["Namibia", 'NAM', 'NA'],
  ["Nauru", 'NRU', 'NR'],
  ["Nepal", 'NPL', 'NP'],
  ["Netherlands", 'NLD', 'NL'],
  ["New Caledonia", 'NCL', 'NC'],
  ["New Zealand", 'NZL', 'NZ'],
  ["Nicaragua", 'NIC', 'NI'],
  ["Niger", 'NER', 'NE'],
  ["Nigeria", 'NGA', 'NG'],
  ["Niue", 'NIU', 'NU'],
  ["Norfolk Island", 'NFK', 'NF'],
  ["Northern Mariana Islands", 'MNP', 'MP'],
  ["Norway", 'NOR', 'NO'],
  ["Oman", 'OMN', 'OM'],
  ["Pakistan", 'PAK', 'PK'],
  ["Palau", 'PLW', 'PW'],
  ["Palestinian Territory", 'PSE', 'PS'],
  ["Panama", 'PAN', 'PA'],
  ["Papua New Guinea", 'PNG', 'PG'],
  ["Paraguay", 'PRY', 'PY'],
  ["Peru", 'PER', 'PE'],
  ["Philippines", 'PHL', 'PH'],
  ["Pitcairn", 'PCN', 'PN'],
  ["Poland", 'POL', 'PL'],
  ["Portugal", 'PRT', 'PT'],
  ["Puerto Rico", 'PRI', 'PR'],
  ["Qatar", 'QAT', 'QA'],
  ["Réunion", 'REU', 'RE'],
  ["Romania", 'ROU', 'RO'],
  ["Russian Federation", 'RUS', 'RU'],
  ["Rwanda", 'RWA', 'RW'],
  ["Saint-Barthélemy", 'BLM', 'BL'],
  ["Saint Helena", 'SHN', 'SH'],
  ["Saint Kitts and Nevis", 'KNA', 'KN'],
  ["Saint Lucia", 'LCA', 'LC'],
  ["Saint-Martin (French part)", 'MAF', 'MF'],
  ["Saint Pierre and Miquelon", 'SPM', 'PM'],
  ["Saint Vincent and Grenadines", 'VCT', 'VC'],
  ["Samoa", 'WSM', 'WS'],
  ["San Marino", 'SMR', 'SM'],
  ["Sao Tome and Principe", 'STP', 'ST'],
  ["Saudi Arabia", 'SAU', 'SA'],
  ["Senegal", 'SEN', 'SN'],
  ["Serbia", 'SRB', 'RS'],
  ["Seychelles", 'SYC', 'SC'],
  ["Sierra Leone", 'SLE', 'SL'],
  ["Singapore", 'SGP', 'SG'],
  ["Slovakia", 'SVK', 'SK'],
  ["Slovenia", 'SVN', 'SI'],
  ["Solomon Islands", 'SLB', 'SB'],
  ["Somalia", 'SOM', 'SO'],
  ["South Africa", 'ZAF', 'ZA'],
  ["South Georgia and the South Sandwich Islands", 'SGS', 'GS'],
  ["South Sudan", 'SSD', 'SS'],
  ["Spain", 'ESP', 'ES'],
  ["Sri Lanka", 'LKA', 'LK'],
  ["Sudan", 'SDN', 'SD'],
  ["Suriname", 'SUR', 'SR'],
  ["Svalbard and Jan Mayen Islands", 'SJM', 'SJ'],
  ["Swaziland", 'SWZ', 'SZ'],
  ["Sweden", 'SWE', 'SE'],
  ["Switzerland", 'CHE', 'CH'],
  ["Syrian Arab Republic (Syria)", 'SYR', 'SY'],
  ["Taiwan, Republic of China", 'TWN', 'TW'],
  ["Tajikistan", 'TJK', 'TJ'],
  ["Tanzania, United Republic of", 'TZA', 'TZ'],
  ["Thailand", 'THA', 'TH'],
  ["Timor-Leste", 'TLS', 'TL'],
  ["Togo", 'TGO', 'TG'],
  ["Tokelau", 'TKL', 'TK'],
  ["Tonga", 'TON', 'TO'],
  ["Trinidad and Tobago", 'TTO', 'TT'],
  ["Tunisia", 'TUN', 'TN'],
  ["Turkey", 'TUR', 'TR'],
  ["Turkmenistan", 'TKM', 'TM'],
  ["Turks and Caicos Islands", 'TCA', 'TC'],
  ["Tuvalu", 'TUV', 'TV'],
  ["Uganda", 'UGA', 'UG'],
  ["Ukraine", 'UKR', 'UA'],
  ["United Arab Emirates", 'ARE', 'AE'],
  ["United Kingdom", 'GBR', 'GB'],
  ["US Minor Outlying Islands", 'UMI', 'UM'],
  ["Uruguay", 'URY', 'UY'],
  ["Uzbekistan", 'UZB', 'UZ'],
  ["Vanuatu", 'VUT', 'VU'],
  ["Venezuela (Bolivarian Republic)", 'VEN', 'VE'],
  ["Viet Nam", 'VNM', 'VN'],
  ["Virgin Islands, US", 'VIR', 'VI'],
  ["Wallis and Futuna Islands", 'WLF', 'WF'],
  ["Western Sahara", 'ESH', 'EH'],
  ["Yemen", 'YEM', 'YE'],
  ["Zambia", 'ZMB', 'ZM'],
  ["Zimbabwe", 'ZWE', 'ZW'],
]

export const countryCodes = countries.map(([label, value]) => ({ label, value }))
export const countryCodes2 = countries.map(([label, _, value]) => ({ label, value }))

export const countryNames = countryCodes.map(item => ({
  label: item.label,
  value: item.label,
}))
