import React, { Component } from 'react'
import mediaQuery from '@/shared/utils/media'

class Heading extends Component {

  componentDidMount() {
    this.mediaQuery = window.matchMedia('(max-width: 767px)')
    this.mediaQuery.addListener(this.reset)
    this.initialize()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.mediaQuery.removeListener(this.reset)
  }

  reset = () => {
    clearInterval(this.interval)
    this.initialize()
  }

  initialize = () => {
    const headings = this.mediaQuery.matches ? ([
      ['Find your| perfect| ', 'Room'],
      ['Connect |with your| ', 'Roomies'],
      ['Attend |networking| ', 'Events'],
      ['Start| your D.C.| ', 'Adventure'],
    ]) : ([
      ['Find your| perfect', 'Room'],
      ['Connect with| your', 'Roomies'],
      ['Attend networking| ', 'Events'],
      ['Start your D.C.| ', 'Adventure'],
    ])
    let headingIndex = 0
    let charIndex = headings[0][0].length + headings[0][1].length
    this.$heading.innerHTML = headings[0][0].replace(/\|/g, '<br/>')
    this.$headingBold.innerHTML = headings[0][1]

    this.interval = setInterval(() => {
      const heading = headings[headingIndex][0]
      const headingBold = headings[headingIndex][1]
      const nextChar = heading[charIndex]
      const nextBoldChar = headingBold[charIndex - heading.length]

      if (nextChar) {
        if (nextChar === '|') {
          this.$heading.innerHTML += '<br/>'
          charIndex++
        }
        this.$heading.innerHTML += heading[charIndex]
      }
      if (nextBoldChar) {
        this.$headingBold.innerHTML += charIndex < heading.length ? '' : nextBoldChar
      }
      if (charIndex > heading.length + headingBold.length + 50) {
        if (charIndex > heading.length + headingBold.length) {
          this.$heading.innerHTML = ''
          this.$headingBold.innerHTML = ''
          charIndex = -1
          if (headingIndex < headings.length - 1) {
            headingIndex++
          } else {
            headingIndex = 0
          }
        }
      }
      charIndex++
    }, 60)
  }

  render() {
    return (
      <h2 className="landingsGeneral_hero_title">
        <span ref={el => this.$heading = el} />
        {' '}
        <span ref={el => this.$headingBold = el} className="landingsGeneral_hero_title_bold" />
      </h2>
    )
  }
}

export default Heading
