import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import * as url from '@/shared/utils/url'
import { stringsToDateRange, dateRangeToStrings, stringToDate, getTimeDifference, isDateBetween } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { fetchRates } from '@/property/api'
import { availableToUnavailableDates } from '@/shared/utils/availability'
import { Button, DateRangePicker, FieldErrors, Icon,
  Checkbox, CheckboxGroup, Spinner, BookingFeesGuest } from '@/shared/components'

const propTypes = {
  property: PropTypes.object.isRequired,
  allDatesListings: PropTypes.object.isRequired,
  selectedDatesListings: PropTypes.object.isRequired,
  selectedListing: PropTypes.object,
  isEntireSpace: PropTypes.bool.isRequired,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
}

const c = 'property_booking'

const Rates = (props) => {

  const { property, allDatesListings, selectedDatesListings, selectedListing, isEntireSpace, query, updateQuery, trackEvent } = props

  const { toPrivateRoute } = useAuthUrl()

  const [data, setData] = useState({ 
    rates: null, 
    error: null, 
    isFetching: false 
  })
  const [tooltip, setTooltip] = useState(null)

  const location = useLocation()

  useEffect(() => {
    calculateRates()
  }, [])

  useEffect(() => {
    if (selectedListing) {
      calculateRates()
    }  
  }, [query])

  const calculateRates = () => {
    if (selectedListing && query.startDate && query.endDate) {
      setData({ ...data, isFetching: true })

      fetchRates(selectedListing.id, {
        startDate: query.startDate,
        endDate: query.endDate,
        additionalFees: query.selectedFees,
      }).then(({ data }) => {
        trackEvent('Property Rates Calculated', {
          bedroomsCount: null, roomiesCount: null,
        })
        setData({ rates: data, error: null, isFetching: false })
      }, ({ error }) => {
        let errorString = error.details ? error.details.base : error.description

        if (error.code === 'not_found') {
          errorString = 'This listing is not available for selected dates.'
        }
        trackEvent('Property Rates Calculated', {
          bedroomsCount: null, roomiesCount: null,
          error: errorString || 'true',
        })
        setData({ rates: null, error: errorString, isFetching: false })
      })
    } else {
      setData({ ...data, rates: null, error: null })
    }
  }

  const handleSubmit = () => {
    if (query.startDate && query.endDate && !data.error) {
      trackEvent('Property Rates Form Submit')
      toPrivateRoute({
        pathname: `/book/${selectedListing.id}/${property.id}`,
        search: url.addToQueryString(location.search, {
          ...query,
          additionalFees: query.selectedFees,
        }),
      })
    } else {
      tooltip.openTooltip()
    }
  }

  const getDisabledListingDates = () => availableToUnavailableDates(
    selectedListing?.availableDates.map(stringsToDateRange)
  )

  const getListingNightlyRate = () => (
    _.get(data.rates, 'avgRate') || selectedListing?.minRate
  )

  const findSession = (date) => property?.sessions.find(session => isDateBetween(date, session.startDate, session.endDate))

  const getSessionDuration = (type = 'days') => {
    const { startDate, endDate } = query
    let session
    let start
    let end

    if (!endDate) {
      session = property?.sessions[0]
      start = session?.startDate
      end = session?.endDate  
    }

    if (!!startDate && !!endDate) {
      const startDateSession = findSession(startDate)
      const endDateSession = findSession(endDate)
      start = startDateSession?.startDate
      end = endDateSession?.endDate
    }

    return getTimeDifference(end, start, type) 
  }

  const getListingRentalTotal = (rates) => {
    const rental = rates?.items.find(item => item.kind === 'rental')
    if (!!query?.startDate && !!query?.endDate && !!rental) return Math.ceil(rental?.total)

    const duration = getTimeDifference(property?.sessions[0].endDate, property?.sessions[0].startDate, 'days')
    const rate = selectedListing?.availableDates[0]?.rate
    return Math.ceil(rate * parseInt(duration))
  }

  const getListingMinStayDays = () => {
    const dates = _.get(selectedDatesListings[selectedListing?.id], 'availableDates', [])
    return _.min(dates.map(d => d.minimumStayDays))
  }

  const { rates, isFetching, error } = data
  const selectedFeeLabels = {
    pets: `I have pets`,
    parking: `I need parking`,
    gym: `I want gym membership`,
  }

  return (
    <>
      <div className={`${c}_top`}>
        {data.isFetching && (
          <Spinner size={36} color="#fff" />
        )}
        <div className={`${c}_title`}>
          {!!property?.prorateForShortStay ? 
            ( 
              <div>
                <span>{formatPrice(getListingNightlyRate() * 30)}</span>
                per month
              </div> 
            ) : (
              <div>
                <span>{formatPrice(getListingRentalTotal(rates))}</span>
                entire stay
              </div> 
            )
          }
        </div>
        {!error && (
          <div className={`${c}_minStay`}>
            {!!property?.prorateForShortStay
              ? `${getListingMinStayDays() / 7} week minimum stay` 
              : `for up to ${getSessionDuration('weeks')} weeks of stay`
            }
          </div>
        )}
      </div>
      <div className={`${c}_bottom`}>
        {!isEntireSpace && (
          <div className={`${c}_dateFiltersLink`} onClick={() => {
            updateQuery({ selectedListingId: null, startDate: null, endDate: null })
          }}>
            Filter other beds by available dates
          </div>
        )}
        <FieldErrors errors={error} />
        {(!query?.startDate || !query?.endDate) && (
          <div className={`${c}_accuratePricingTip`}>
            <Icon type="lightbulb" />
            Enter dates for accurate pricing
          </div>
        )}
        <DateRangePicker
          placeholder="Check In — Check Out"
          value={stringsToDateRange(query)}
          onChange={dates => updateQuery(dateRangeToStrings(dates))}
          disabledDays={getDisabledListingDates()}
          minimumStayDays={0}
          getTooltip={comp => setTooltip(comp)}
          tooltipProps={{ windowBounds: { bottom: true } }}
        />
        <div> 
          <CheckboxGroup
            value={query?.selectedFees}
            onChange={selectedFees => updateQuery({ selectedFees })}
            render={({ isChecked, onCheck }) => (
              _.uniqBy(property?.fees, 'kind').filter(f => f.optional).map(fee => (
                <Checkbox
                  key={fee.id}
                  size="small"
                  label={selectedFeeLabels[fee.kind]}
                  value={fee.kind}
                  checked={isChecked(fee.kind)}
                  onChange={onCheck}
                />
              ))
            )}
          />
          {!!rates && <BookingFeesGuest rates={rates} />}
          <Button
            disabled={isFetching}
            onClick={handleSubmit}
            className='guest_property_request_to_book'
          >
            Request to Book
          </Button>
          <div className={`${c}_tip`}>{`You won't be charged yet`}</div>
        </div>
      </div>
    </>
  )
}

Rates.propTypes = propTypes

export default Rates
