

import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { objectToQueryString } from '@/shared/utils/url'
                             
export default function use404() {
  const navigate = useNavigate()

  const to404 = message => {
    navigate({
      pathname: '/not-found',
      search: objectToQueryString({ message }),
    })
  }
    
  return { 
    to404
  }
}
