import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import * as url from '@/shared/utils/url'

const queryManager = ({
  defaultQuery = {},
  parse = query => query,
  serialize = query => query,
}) => Child => props => {
  const location = useLocation()
  const navigate = useNavigate()

  const searchToQueryState = search => parse(
    _.merge({}, serialize(defaultQuery), _.pick(
      url.queryStringToObject(search),
      _.keys(defaultQuery)
    ))
  )
  
  const [query, setQuery] = useState(() => (
    searchToQueryState(location.search)
  ))
  
  const [lastQuery, setLastQuery] = useState(() => (
    searchToQueryState(location.search)
  ))

  useEffect(() => {
    setQueryState(searchToQueryState(location.search))
  }, [location])

  const setQueryState = query => {
    setQuery(query)
    setLastQuery(query)
  }

  const updateQuery = value => {
    const newQuery = { ...lastQuery, ...value }
    setQueryState(newQuery)

    const keysForUrl = _.keys(newQuery).filter(key => (
      defaultQuery[key] !== newQuery[key]
    ))
    const serializedQuery = _.pick(
      serialize(newQuery), keysForUrl
    )
    navigate({
      pathname: location.pathname,
      search: url.objectToQueryString(serializedQuery),
    })
  }


  return (
    <Child
      {...props}
      query={query}
      updateQuery={updateQuery}
      isDefaultQuery={_.isEqual(defaultQuery, query)}
    />
  )
}

export default queryManager

