import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import AppHeader from '@/features/appHeader/components'
import { Button, Icon, Head } from '@/shared/components'
import '@/book/styles/success.scss'

class Success extends Component {

  componentDidMount() {
    analytics.pageview('Booking Wizard Success')
  }

  render() {
    return (
      <Fragment>
        <Head title="Success - Booking Request Sent" />
        <AppHeader />
        <div className="book_success">
          <Icon type="check-circle" className="book_success_icon" />
          <div className="book_success_title">
            Congrats! Your booking request was sent successfully.
          </div>
          <div className="book_success_subtitle">
            <span>Hang tight, your host has up to 48 hours to reply to your request.</span>
          </div>
          <Link to="/guest/bookings">
            <Button hollow>See the status of your request</Button>
          </Link>
        </div>
      </Fragment>
    )
  }
}

export default Success
