import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import { updateCurrentUser } from '@/features/auth/state/actions'
import * as api from '@/hostPayment/api'
import stripeClientLoader from '@/shared/hocs/stripeClientLoader'
import { DashboardMenu, Spinner, Head, Button, ConfirmModal, Icon } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import IndividualForm from './IndividualForm'
import CompanyForm from './CompanyForm'
import BankAccountForm from './BankAccountForm'
import DocumentUpload from './DocumentUpload'
import '@/hostPayment/styles/index.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { updateCurrentUser }

const propTypes = {
  stripe: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
}

const c = 'hostPayment'

class Payment extends Component {

  state = { account: null, activeForm: null }

  componentDidMount() {
    api.fetchAccount().then(({ data }) => {
      this.setState({ account: data })
    }, handleError)
  }

  isAccountFetched = () => !!this.state.account
  hasAccount = () => !_.isEmpty(this.state.account)
  hasIndividualAccount = () => _.get(this.state.account, 'businessType') === 'individual'
  hasCompanyAccount = () => _.get(this.state.account, 'businessType') === 'company'
  hasBankAccount = () => !!_.get(this.state.account, 'externalAccounts.data[0]')

  isDocumentUploadDue = () => (
    _.get(this.state.account, 'requirements.eventuallyDue', []).some(item => (
      item.includes('individual.verification.document')
    ))
  )
  isDocumentUploadedAnd = status => (
    _.get(this.state.account, 'individual.verification.document.front') &&
    _.get(this.state.account, 'individual.verification.status') === status
  )

  upsertAccount = payload => (
    api.upsertAccount(payload).then(({ data }) => {
      showToast({
        title: 'Your account information was saved successfully.',
      })
      this.setState({ account: data })
    })
  )

  renderDocumentShow = (status, { icon, message }) => (
    <div className={`${c}_documentShow_cont`}>
      <div className={`${c}_documentShow ${c}_documentShow--${status}`}>
        <Icon type={icon} />
        {message}
      </div>
    </div>
  )

  render() {
    const { account, activeForm } = this.state
    return (
      <div className={c}>
        <Head title="Host Payment Center - Roomsie" />
        <AppHeader />
        <DashboardMenu role="host" />
        <h1>Payment Info</h1>
        {!this.isAccountFetched() && (
          <div className={`${c}_fetchingMessage`}>
            <h2>{`Fetching your host information...`}</h2>
            <Spinner size={52} />
          </div>
        )}
        {this.isDocumentUploadedAnd('pending') && (
          this.renderDocumentShow('pending', {
            icon: 'info',
            message: 'Your ID is being verified',
          })
        )}
        {this.isDocumentUploadedAnd('verified') && (
          this.renderDocumentShow('verified', {
            icon: 'check-circle',
            message: 'Your ID has been verified',
          })
        )}
        {this.isDocumentUploadedAnd('unverified') && (
          this.renderDocumentShow('unverified', {
            icon: 'warning',
            message: `ID verification has failed: ${account.individual.verification.details}`,
          })
        )}
        {this.isDocumentUploadDue() && (
          <DocumentUpload
            stripe={this.props.stripe}
            onSubmit={this.upsertAccount}
          />
        )}
        {this.hasAccount() && (
          <BankAccountForm
            stripe={this.props.stripe}
            account={account}
            onSubmit={this.upsertAccount}
            updateCurrentUser={this.props.updateCurrentUser}
            hasBankAccount={this.hasBankAccount}
          />
        )}
        {(this.hasIndividualAccount() || activeForm === 'individual') && (
          <IndividualForm
            stripe={this.props.stripe}
            account={account}
            onSubmit={this.upsertAccount}
            currentUser={this.props.currentUser}
            hasIndividualAccount={this.hasIndividualAccount}
          />
        )}
        {(this.hasCompanyAccount() || activeForm === 'company') && (
          <CompanyForm
            stripe={this.props.stripe}
            account={account}
            onSubmit={this.upsertAccount}
            currentUser={this.props.currentUser}
            hasCompanyAccount={this.hasCompanyAccount}
          />
        )}
        {this.isAccountFetched() && !this.hasAccount() && !activeForm && (
          <div className={`${c}_accountTypePicker`}>
            <h2>Would you like to set up an individual or a company account for payouts?</h2>
            <ConfirmModal
              title="Please Confirm"
              message="I'm registering as an individual and will accept payouts to my personal bank account."
              renderLink={() => <Button hollow>Individual</Button>}
              onConfirm={() => this.setState({ activeForm: 'individual' })}
            />
            <ConfirmModal
              title="Please Confirm"
              message="I'm registering as a company and will receive payouts to my corporate bank account."
              renderLink={() => <Button hollow>Company</Button>}
              onConfirm={() => this.setState({ activeForm: 'company' })}
            />
          </div>
        )}
        <AppFooter />
      </div>
    )
  }
}

Payment.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(stripeClientLoader(Payment))
