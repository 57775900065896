export default {
  washingtonDC: {
    center: {
      lat: 38.907454,
      lng: -77.036871,
    },
    zoom: 13,
    boundingBox: [-77.17049272872936, 38.772860709259845, -76.89865620948802, 39.02402302354206],
  },
}
