import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LogoBlack from '@/shared/assets/images/LogoBlack.svg'
import LogoWhite from '@/shared/assets/images/LogoWhite.svg'
import { hostLinks, guestLinks } from '@/shared/static/menuLinks'
import { isCurrentUserAuthenticated, isCurrentUserVerified } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import useHelpModalUrl from '@/features/helpModal/hooks/useHelpModalUrl'
import { Tooltip, Icon } from '@/shared/components'
import PrivateLink from '@/features/auth/components/PrivateLink'
import '@/features/appHeader/styles/mobile.scss'

const propTypes = {
  currentUser: PropTypes.object,
  whiteText: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  updateMode: PropTypes.func.isRequired,
  userLinks: PropTypes.array.isRequired,
}

const Mobile = (props) => {
  const { toOpen } = useHelpModalUrl()
  const { toOpenSignup, toOpenSignin, toOpenConfirm } = useAuthUrl()

  return (
    <header className={classnames('appHeader', {
      'appHeader--whiteText': props.whiteText,
    })}>
      <Logo />
      <Tooltip
        className="appHeader_menu"
        containerClassName="appHeader_menu_container"
        renderLink={() => (
          <Icon type="hamburger-thin" />
        )}
        renderContent={() => (
          isCurrentUserAuthenticated() ? (
            <SignedIn 
              mode={props.mode}
              updateMode={props.updateMode}
              currentUser={props.currentUser}
              toOpen={toOpen}
              toOpenConfirm={toOpenConfirm}
              userLinks={props.userLinks}
            />
            ) : (
              <SignedOut 
                toOpen={toOpen}
                toOpenSignup={toOpenSignup}
                toOpenSignin={toOpenSignin}
              />
            )
        )}
      />
    </header>
  )
}

const Logo = (props) => (
  <Link to="/">
    <img
      className="appHeader_logo"
      src={props.whiteText ? LogoWhite : LogoBlack}
      alt="roomsie logo"
    />
  </Link>
)

const SignedOut = ({toOpen, toOpenSignup, toOpenSignin}) => (
  <Fragment>
    <div className="appHeader_section">
      <div className="appHeader_item" onClick={toOpen}>Help</div>
      <div className="appHeader_item" onClick={() => toOpenSignup()}>
        Sign Up
      </div>
      <div className="appHeader_item" onClick={() => toOpenSignin()}>
        Sign In
      </div>
      <Link to="/hosts" className="appHeader_item">
        Become a Host
      </Link>
    </div>
  </Fragment>
)

const SignedIn = ({ mode, updateMode, currentUser, toOpen, toOpenConfirm, userLinks }) => (
  <Fragment>
    {isCurrentUserVerified() && (
      <Fragment>
        <div className="appHeader_section">
          <div className="appHeader_item" onClick={toOpen}>Help</div>
        </div>
        {mode === 'guest' ? (
          <Fragment>
            <div className="appHeader_section_title">Guest</div>
            <div className="appHeader_section">
              {guestLinks.map(({ to, text }) => (
                <Link key={to} className="appHeader_item" to={to}>
                  {text}
                </Link>
              ))}
              <Link
                className="appHeader_item"
                to="/host/bookings"
                onClick={() => updateMode('host')}
              >
                Switch to Host
              </Link>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="appHeader_section_title">Host</div>
            <div className="appHeader_section">
              {hostLinks.map(({ to, text }) => (
                <Link key={to} className="appHeader_item" to={to}>
                  {text}
                </Link>
              ))}
              <PrivateLink to="/list-space" className="appHeader_item">
                List Space
              </PrivateLink>
              <Link
                className="appHeader_item"
                to="/guest/dashboard"
                onClick={() => updateMode('guest')}
              >
                Switch to Guest
              </Link>
            </div>
          </Fragment>
        )}
      </Fragment>
    )}
    <div className="appHeader_section_title">Other</div>
    <div className="appHeader_section">
      {!isCurrentUserVerified() && (
        <div className="appHeader_item" onClick={toOpenConfirm}>
          Verify Your Account
        </div>
      )}
      {userLinks.map(({ to, text }) => (
        <Link key={to} className="appHeader_item" to={to}>
          {text}
        </Link>
      ))}
    </div>
  </Fragment>
)

Mobile.propTypes = propTypes

export default Mobile
