import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import * as url from '@/shared/utils/url'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { claimAccount } from '@/features/auth/state/actions'
import { Head, Form, Button, Field, FieldLabel } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import '@/features/auth/styles/claimAccount.scss'

const mapDispatchToProps = { claimAccount }

const propTypes = {
  claimAccount: PropTypes.func.isRequired
}

const c = 'claimAccount'

const ClaimAccount = (props) => {
  const location = useLocation()
  const { toPrivateRoute } = useAuthUrl()

  const [isClaimed, setIsClaimed] = useState(false)

  useEffect(() => {
    analytics.pageview('Claim Account')
  }, [])

  useEffect(() => {
    if (isClaimed) {
      toPrivateRoute('/guest/bookings')
    }
  }, [isClaimed])

  const renderForm = () => (
    <Form
      initialValues={{
        password: '',
        passwordRepeat: '',
      }}
      validations={{
        password: [Form.is.required(), Form.is.min(8)],
        passwordRepeat: [Form.is.required(), Form.is.min(8)],
      }}
      onSubmit={(values, form) => {
        const token = url.queryStringToObject(location.search).token

        if (values.password !== values.passwordRepeat) {
          form.setSubmitting(false)
          return form.setErrors({
            passwordRepeat: 'Both fields have to contain the same password',
          })
        }
        props.claimAccount(values.password, token).then(() => {
          setIsClaimed(true)
        }, ({ error }) => {
          if (error.details) {
            form.setErrors(error.details)
          } else {
            showToast({
              type: 'danger',
              title: 'The account could not be claimed.',
              message: `The token was invalid. Make sure to click on a link
                        from the latest email we sent you.`,
              duration: 0,
            })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form onSubmit={handleSubmit} className={`${c}_form`}>
          <FieldLabel>Set a password for your account</FieldLabel>
          <Field.Input
            {...fields.password}
            placeholder="Password"
            icon="lock"
            type="password"
          />
          <Field.Input
            {...fields.passwordRepeat}
            placeholder="Repeat Password"
            icon="lock"
            type="password"
          />
          <Button type="submit" working={isSubmitting}>
            Set Password
          </Button>
        </form>
      )}
    />
  )

  const renderGuide = () => (
    <Button onClick={() =>toPrivateRoute('/guest/bookings')}>
      Finish verifying your account
    </Button>
  )

  return (
    <div className={c}>
      <Head title="Claim Account - Roomsie" />
      <AppHeader />
      <div className={`${c}_page`}>
        <div className={`${c}_title`}>
          Claim your reservation
        </div>
        <p className={`${c}_subtitle`}>
          {"Congrats! You're only steps away from becoming the newest member of the Roomsie community. Complete all required fields on the following screens to successfully claim your reservation."}
        </p>
        {isClaimed ? renderGuide() : renderForm()}
      </div>
    </div>
  )
}

ClaimAccount.propTypes = propTypes

export default connect(null, mapDispatchToProps)(ClaimAccount)
