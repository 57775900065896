import React from 'react'
import { useParams } from 'react-router-dom'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import ProfileShow from './ProfileShow'

const ProfileShowPage = props => {

  let { userId } = useParams()

  return (
    <div className="profileShow profileShowPage">
      <AppHeader />
      <ProfileShow userId={userId} {...props} isPage />
      <AppFooter />
    </div>
  )
}

export default ProfileShowPage
