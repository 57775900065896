import superagent from 'superagent'
import { makeCancelable } from '@/shared/utils/promise'
import api, { handleError } from '@/shared/api'

export const fetchSignedUrls = files => (
  api.post('/uploads', {
    objects: files.map(file => ({
      objectName: file.name,
      contentType: file.type,
    })),
  }).then(({ data }) => {
    data.forEach(({ signedUrl, imageUrl }, i) => {
      files[i].signedUrl = signedUrl
      files[i].imageUrl = imageUrl
    })
  }, handleError)
)

export const uploadPhotoToStorage = file => (
  makeCancelable(
    superagent.put(file.signedUrl).send(file).set({
      'Content-Type': file.type,
      'x-amz-acl': 'public-read'
    })
  )
)
