import api from '@/shared/api'

export const fetchProgram = programId => (
  api.get(`/programs/${programId}`)
)

export const fetchProperties = sessionId => (
  api.get(`/guest/program_sessions/${sessionId}/properties`)
)

export const fetchAttendees = sessionId => (
  api.get(`/program_sessions/${sessionId}/attendees`)
)
