import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Media } from '@/shared/components'
import InputCount from '../InputCount'

const propTypes = {
  property: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

const c = 'listSpace_roomsRates_counts'

const Counts = ({ property, onChange }) => (
  <div className={c}>
    {property.propertyType !== 'studio_apartment' && (
      <div className={`${c}_row`}>
        <div className="listSpace_label">
          {'Total No. of Bedrooms'}
        </div>
        <p className="listSpace_tip">
          {`The total number of bedrooms in the property${property.listingType === 'entire_space' ? '.' : ', including those not reserved for Roomsie guests.'}`}
        </p>
        <InputCount
          min={1}
          value={property.bedroomsCount}
          onChange={value => onChange({ bedroomsCount: value })}
        />
      </div>
    )}
    <div className={`${c}_row`}>
      <div className="listSpace_label">
        {'Total No. of Bathrooms'}
      </div>
      <p className="listSpace_tip">
        {`The total number of bathrooms in the property${property.listingType === 'entire_space' ? '.' : ', including those not reserved for Roomsie guests.'}`}
      </p>
      <InputCount
        min={0.5}
        increment={0.5}
        value={property.bathroomsCount}
        onChange={value => onChange({ bathroomsCount: value })}
      />
    </div>
    <div className={`${c}_row`}>
      <div className="listSpace_label">
        {'Max. No. of Guests'}
      </div>
      <p className="listSpace_tip">
        {"The total number of guests that your property may house among all beds (calculated at 1 person per bed maximum)."}
      </p>
      <InputCount
        min={1}
        value={property.accommodates}
        onChange={value => onChange({ accommodates: value })}
      />
    </div>
  </div>
)

Counts.propTypes = propTypes

export default Counts
