import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Icon } from '@/shared/components'
import '@/book/styles/progressBar.scss'

const propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isLast: PropTypes.bool,
}

const ProgressBar = ({ current, total, isLast }) => (
  <div className="progressBar">
    <div
      className="progressBar_background"
      style={{ width: `${Math.min(current * 100 / total, 100)}%` }}
    />
    {_.range(1, total + 1).map(step => (
      <div
        key={step}
        className={classnames(`progressBar_step`, {
          'progressBar_step--isCurrent': step === current,
          'progressBar_step--isCompleted': step < current || isLast,
        })}
        style={{ left: `${step * 100 / total}%` }}
      >
        {(step < current || isLast) && <Icon type="check" />}
      </div>
    ))}
  </div>
)

ProgressBar.propTypes = propTypes

export default ProgressBar
