import React, { Component } from 'react'
import PropTypes from 'prop-types'
import media from '@/shared/utils/media'
import { getMoreFiltersActiveCount } from '@/search/state/reducers'
import { pageContainerClassName } from '@/search/components'
import { searchFiltersClassName } from '@/search/components/filters'
import { appHeaderClassName } from '@/features/appHeader/components'
import MoreFilters from './MoreFilters'
import { Icon } from '@/shared/components'

const propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilterChanges: PropTypes.func.isRequired,
  cancelFilterChanges: PropTypes.func.isRequired,
  clearFilterSection: PropTypes.func.isRequired,
}

class More extends Component {

  state = { isOpen: false }

  componentDidMount() {
    this.setDropdownHeight()
    window.addEventListener('resize', this.setDropdownHeight)
    document.addEventListener('mouseup', this.onMouseUp)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDropdownHeight)
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  openDropdown = () => {
    this.setState({ isOpen: true }, () => {
      this.setDropdownHeight()
      document.querySelector(`.${pageContainerClassName}`)
        .classList.add(`${pageContainerClassName}--moreDropdownIsOpen`)
    })
  }

  closeDropdown = () => {
    this.setState({ isOpen: false }, () => {
      this.props.cancelFilterChanges()
      document.querySelector(`.${pageContainerClassName}`)
        .classList.remove(`${pageContainerClassName}--moreDropdownIsOpen`)
    })
  }

  toggleDropdown = () => {
    this.state.isOpen ? this.closeDropdown() : this.openDropdown()
  }

  onMouseUp = e => {
    if (
      this.state.isOpen &&
      e.button === 0 &&
      this.$container !== e.target &&
      !this.$container.contains(e.target)
    ) {
      this.closeDropdown()
    }
  }

  setDropdownHeight = () => {
    if (!this.$dropdown) return
    let offset = (
      document.querySelector(`.${appHeaderClassName}`).offsetHeight +
      document.querySelector(`.${searchFiltersClassName}`).offsetHeight
    )
    this.$dropdown.style.height = `${window.innerHeight - offset}px`
  }

  render() {
    const activeCount = getMoreFiltersActiveCount(this.props.filters)
    return (
      <span ref={el => this.$container = el}>
        <div
          className="search_filters_link"
          onClick={this.toggleDropdown}
        >
          <Icon type="more" />
          {activeCount ? `More · ${activeCount}` : 'More'}
        </div>
        {this.state.isOpen && (
          <div
            className="search_filters_more_dropdown"
            ref={el => this.$dropdown = el}
          >
            <MoreFilters
              {...this.props}
              closeDropdown={this.closeDropdown}
            />
          </div>
        )}
      </span>
    )
  }
}

More.propTypes = propTypes

export default More
