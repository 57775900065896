import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import '@/shared/styles/fieldErrors.scss'

const propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
}

const FieldErrors = ({ errors, className }) => {
  if (!errors) return null
  const items = _.isString(errors) ? [errors] : errors
  return (
    <div className={classnames(fieldErrorsClassName, className)}>
      {items.filter(item => item).map(item => (
        <div key={item} className={`${fieldErrorsClassName}_error`}>{item}</div>
      ))}
    </div>
  )
}

FieldErrors.propTypes = propTypes

export default FieldErrors

export const fieldErrorsClassName = 'fieldErrors'
