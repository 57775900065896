import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  query: PropTypes.string.isRequired,
  match: PropTypes.func,
  unmatch: PropTypes.func,
}

const defaultProps = {
  match: () => null,
  unmatch: () => null,
}

class Media extends Component {

  constructor(props) {
    super(props)
    this.mediaQuery = window.matchMedia(props.query)
    this.state = { matches: this.mediaQuery.matches }
  }

  componentDidMount() {
    this.mediaQuery.addListener(this.updateMatches)
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.updateMatches)
  }

  updateMatches = () => {
    this.setState({ matches: this.mediaQuery.matches })
  }

  render() {
    return this.state.matches ? this.props.match() : this.props.unmatch()
  }
}

Media.propTypes = propTypes
Media.defaultProps = defaultProps

export default Media
