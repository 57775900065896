import React, { Component } from 'react'
import { 
  BrowserRouter, 
  Router, 
  Routes as ReactRoutes, 
  Route,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import _ from 'lodash'
import PrivateRoute from '@/features/auth/components/PrivateRoute'

import App from '@/global/components/App'
import LandingsGeneral from '@/landings/components/General'
import LandingsHost from '@/landings/components/Host'
import LandingsTemplate from '@/landings/components/Template'
import AuthResetPasswordRedirect from '@/features/auth/components/ResetPasswordRedirect'
import AuthSignoutRedirect from '@/features/auth/components/SignoutRedirect'
import AuthOauthWindow from '@/features/auth/components/OauthWindow'
import AuthClaimAccount from '@/features/auth/components/ClaimAccount'
import Search from '@/search/components'
import SearchHostLoader from '@/search/components/HostLoader'
import Property from '@/property/components'
import Book from '@/book/components'
import BookSuccess from '@/book/components/Success'
import ListSpace from '@/listSpace/components'
import ListSpaceSuccess from '@/listSpace/components/Success'
import ProfileShowPage from '@/profile/components/ProfileShowPage'
import ProfileShowModal from '@/profile/components/ProfileShowModal'
import ProfileEdit from '@/profile/components/ProfileEdit'
import Conversations from '@/conversations/components/Conversations'
import Conversation from '@/conversations/components/Conversation'
import Events from '@/events/components/events'
import Event from '@/events/components/event'
import EventsForm from '@/events/components/form'
import HostProperties from '@/hostProperties/components/Properties'
import HostProperty from '@/hostProperties/components/Property'
import HostBookings from '@/hostBookings/components/Bookings'
import HostBooking from '@/hostBookings/components/Booking'
import HostManagement from '@/hostManagement/components'
import HostGuestsAll from '@/hostGuests/components/AllGuests'
import HostMessages from '@/hostMessages/components/Messages'
import HostMessagesForm from '@/hostMessages/components/Form'
import HostMessagesFormInstant from '@/hostMessages/components/FormInstant'
import HostPayment from '@/hostPayment/components'
import HostPrograms from '@/hostPrograms/components'
import HostProgramsAssign from '@/hostPrograms/components/Assign'
import GuestDashboard from '@/guestDashboard/components'
import GuestBookings from '@/guestBookings/components/Bookings'
import GuestBooking from '@/guestBookings/components/Booking'
import GuestRoomies from '@/guestRoomies/components'
import GuestPayment from '@/guestPayment/components'
import LeaveReview from '@/leaveReview/components'
import SubscriptionsManage from '@/subscriptions/components/Manage'
import SubscriptionsUnsubscribe from '@/subscriptions/components/Unsubscribe'
import ReferGuest from '@/refer/components/Guest'
import Program from '@/program/components'
import Policy from '@/staticPages/components/Policy'
import NotFound from '@/staticPages/components/NotFound'

// import Plugin from '@/plugin/components'
// import PluginSearch from '@/plugin/search/components'
import PluginProperty from '@/plugin/property/components'

import { stepRoutes } from './listSpace/components'
import { bookRoutes } from './book/components'
import { buildingsStepRoutes } from './plugin/property/components'

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <LandingsGeneral />
      },
      {
        path: "/hosts",
        element: <LandingsHost />
      },
      {
        path: "/parents",
        element: <LandingsTemplate type={"parents"} />
      },
      {
        path: "/students",
        element: <LandingsTemplate type={"students"} />
      },
      {
        path: "/universities",
        element: <LandingsTemplate type={"universities"} />
      },
      {
        path: "/coordinators",
        element: <LandingsTemplate type={"coordinators"} />
      },
      {
        path: "/reset-password",
        element: <AuthResetPasswordRedirect /> 
      },
      {
        path: "/signout",
        element: <AuthSignoutRedirect /> 
      },
      {
        path: "/oauth2/google-callback",
        element: <AuthOauthWindow /> 
      },
      {
        path: "/oauth2/facebook-callback",
        element: <AuthOauthWindow /> 
      },
      {
        path: "/claim-account",
        element: <AuthClaimAccount /> 
      },
      {
        path: "/search",
        element: <Search /> 
      },
      {
        path: "/h/:userId",
        element: <SearchHostLoader /> 
      },
      {
        path: "/properties/:propertyId",
        element: <Property /> 
      },
      {
        path: "/profile/:userId",
        element: <ProfileShowPage /> 
      },
      {
        path: "/plugins/buildings/:buildingId",
        element: <PluginProperty />,
        children: buildingsStepRoutes
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: "/profile",
            canBeUnverified: true,
            element: <ProfileEdit />,
          },
          {
            path: "/book/:listingId/:propertyId",
            element: <Book />,
            children: bookRoutes
          },
          {
            path: "/book-success",
            element: <BookSuccess />
          },
          {
            path: "/list-space",
            element: <ListSpace />,
            children: stepRoutes
          },
          {
            path: "/list-space-success",
            element: <ListSpaceSuccess />,
          },
          {
            path: "/messages",
            element: <Conversations />,
          },
          {
            path: "/messages/:conversationId",
            element: <Conversation />,
          },
          {
            path: "/events",
            element: <Events />,
          },
          {
            path: "/events/show/:eventId",
            element: <Event />,
          },
          {
            path: "/events/create",
            element: <EventsForm />,
          },
          {
            path: "/events/edit/:eventId",
            element: <EventsForm />,
          },
          { 
            path: "/host/properties",
            element: <HostProperties />
          },
          { 
            path: "/host/properties/:propertyId",
            element: <HostProperty />
          },
          { 
            path: "/host/bookings",
            element: <HostBookings />
          },
          { 
            path: "/host/bookings/calendar",
            element: <HostManagement />
          },
          { 
            path: "/host/bookings/:bookingId",
            element: <HostBooking />
          },
          { 
            path: "/host/guests",
            element: <HostGuestsAll />
          },
          { 
            path: "/host/scheduled-messaging",
            element: <HostMessages />
          },
          { 
            path: "/host/scheduled-messaging/create",
            element: <HostMessagesForm />
          },
          { 
            path: "/host/scheduled-messaging/edit/:messageId",
            element: <HostMessagesForm />
          },
          { 
            path: "host/scheduled-messaging/instant",
            element: <HostMessagesFormInstant />
          },
          { 
            path: "host/payment-center",
            element: <HostPayment />
          },
          { 
            path: "/host/partners-assign/:programId",
            element: <HostProgramsAssign />
          },
          { 
            path: "host/partners",
            element: <HostPrograms />
          },
          { 
            path: "/guest/dashboard",
            element: <GuestDashboard />
          },
          { 
            path: "/guest/bookings",
            element: <GuestBookings />
          },
          { 
            path: "/guest/bookings/:bookingId",
            element: <GuestBooking />
          },
          { 
            path: "/guest/roomies",
            element: <GuestRoomies />
          },
          { 
            path: "/guest/payment-center",
            element: <GuestPayment />
          },
          { 
            path: "/refer-guests",
            element: <ReferGuest />
          },
          { 
            path: "/subscriptions",
            element: <SubscriptionsManage />
          },
        ],
      },
      {
        path: "/unsubscribe",
        element: <SubscriptionsUnsubscribe /> 
      },
      {
        path: "/leave-review/:bookingId",
        element: <LeaveReview /> 
      },
      {
        path: "/p/:programId",
        element: <Program /> 
      },
      {
        path: "/terms-of-service",
        element: <Policy fileName="tos" />
      },
      {
        path: "/payments-terms-of-service",
        element: <Policy fileName="payments_tos" />
      },
      {
        path: "/privacy-policy",
        element: <Policy fileName="privacy_policy" />
      },
      {
        path: "/guest-refund-policy",
        element: <Policy fileName="guest_refund_policy" />
      },
      {
        path: "/cancellation-policy",
        element: <Policy fileName="cancellation_policy" />
      },
      {
        path: "/media-press",
        element: <Policy fileName="media" />
      },
      {
        path: "/not-found",
        element: <NotFound />
      },
      {
        path: "*",
        element: <NotFound />
      }
    ]
  }
])

const AppRoutes = ({ location, modal }) => {
  return (
    <RouterProvider router={router} />
  )
}

export default AppRoutes
