import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { countryNames } from '@/shared/static/countries'
import { Form, Button, Field, Icon } from '@/shared/components'

const propTypes = {
  user: PropTypes.object.isRequired,
  hasCustomerInfo: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCustomerFormSubmitSuccess: PropTypes.func.isRequired,
  onCustomerFormSubmitError: PropTypes.func.isRequired,
}

const c = 'guestPaymentForm_customerForm'

class CustomerForm extends Component {

  constructor(props) {
    super(props)
    this.state = { isEditing: !props.hasCustomerInfo() }
  }

  getUserData = () => {
    const get = key => _.get(this.props.user, `shipping.${key}`) || ''
    return {
      name: get('name'),
      line1: get('address.line1'),
      line2: get('address.line2'),
      city: get('address.city'),
      state: get('address.state'),
      postalCode: get('address.postalCode'),
      country: get('address.country') || 'USA',
      phone: get('phone'),
    }
  }

  renderForm = () => (
    <div className={c}>
      <h2 className={`${c}_title`}>
        Please enter your billing information
        {this.props.hasCustomerInfo() && (
          <div
            className={`${c}_editLink`}
            onClick={() => this.setState({ isEditing: false })}
          >
            <Icon type="edit" />
            Cancel
          </div>
        )}
      </h2>
      <Form
        initialValues={this.getUserData()}
        validations={{
          name: Form.is.required(),
          line1: Form.is.required(),
          city: Form.is.required(),
          postalCode: Form.is.required(),
          country: Form.is.required(),
          phone: Form.is.required(),
        }}
        onSubmit={(values, form) => {
          this.props.onSubmit({
            shipping: {
              name: values.name,
              phone: values.phone,
              address: _.omit(values, ['name', 'phone']),
            },
          }).then(() => {
            this.props.onCustomerFormSubmitSuccess()
            this.setState({ isEditing: false })
          }, ({ error }) => {
            this.props.onCustomerFormSubmitError(error.description || error.message)
            handleError({ error: { description: error.description || error.message } })
            form.setSubmitting(false)
          })
        }}
        render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className={`${c}_fields`}>
              <Field.Input {...fields.name} label="Name" />
              <Field.Input {...fields.line1} label="Street Address" />
              <Field.Input {...fields.line2} label="Street Address 2" />
              <Field.Input {...fields.city} label="City" />
              <Field.Input {...fields.state} label="State" />
              <Field.Input {...fields.postalCode} label="Postal Code" />
              <Field.Select {...fields.country} label="Country" options={countryNames} />
              <Field.Input {...fields.phone} label="Phone Number" />
            </div>
            <Button type="submit" working={isSubmitting} className={`${c}_submit`}>
              Submit
            </Button>
          </form>
        )}
      />
    </div>
  )

  renderShow = () => {
    const userData = this.getUserData()

    const renderValue = (label, value) => (
      <div className={`${c}_value`}>
        <span>{label}</span>
        {value || '—'}
      </div>
    )
    return (
      <div className={c}>
        <h2 className={`${c}_title`}>
          Billing Information
          <div
            className={`${c}_editLink`}
            onClick={() => this.setState({ isEditing: true })}
          >
            <Icon type="edit" />
            Edit
          </div>
        </h2>
        <div className={`${c}_values`}>
          {renderValue('Name', userData.name)}
          {renderValue('Street Address', userData.line1)}
          {renderValue('Street Address 2', userData.line2)}
          {renderValue('City', userData.city)}
          {renderValue('State', userData.state)}
          {renderValue('Postal Code', userData.postalCode)}
          {renderValue('Country', userData.country)}
          {renderValue('Phone Number', userData.phone)}
        </div>
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderForm() : this.renderShow()
  }
}

CustomerForm.propTypes = propTypes

export default CustomerForm
