import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import googleMapsApiLoader from '@/shared/hocs/googleMapsApiLoader'
import { getDefaultMapOptions } from '@/shared/utils/map'
import * as url from '@/shared/utils/url'
import * as geo from '@/shared/utils/geo'
import * as device from '@/shared/utils/device'
import { updateQuery, updateQueryState } from '@/search/state/actions'
import { queryStringToState } from '@/search/state/reducers'
import { renderPropertyMarker, mapMarkerActiveClass } from './propertyMarker'
import Result from '@/search/components/results/Result'
import '@/search/styles/map.scss'

const mapStateToProps = ({ search }) => ({
  boundingBox: search.query.boundingBox,
  location: search.location,
  results: search.results,
  ratesPeriod: search.options.ratesPeriod,
})

const mapDispatchToProps = { updateQuery, updateQueryState }

const propTypes = {
  boundingBox: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  results: PropTypes.object.isRequired,
  ratesPeriod: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
  updateQueryState: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  // history: PropTypes.object.isRequired,
  routerLocation: PropTypes.object.isRequired,
}

class Map extends Component {

  markers = []
  padding = 25

  state = {
    $tooltipMarker: null,
    tooltipData: null,
  }

  componentDidMount() {
    const { google } = this.props

    this.map = new google.maps.Map(
      this.$mapContainer,
      getDefaultMapOptions(google)
    )
    const onMove = _.debounce(this.updateQueryWithNewBounds, 500)
    google.maps.event.addListener(this.map, 'bounds_changed', onMove)

    this.setInitialPosition()
    this.renderMarkers()

    document.addEventListener('mouseup', this.onDocumentClick)
    document.addEventListener('touchend', this.onDocumentClick)
  }

  componentDidUpdate(prevProps) {
    const { center, zoom } = this.props.location
    const prevCenter = prevProps.location.center
    const spot = url.queryStringToObject(this.props.routerLocation.search).spot
    const prevSpot = url.queryStringToObject(prevProps.routerLocation.search).spot

    if ((prevSpot && !spot && !center) || (prevCenter && !center && !spot)) {
      this.removeSearchMarker()
    }
    if (prevCenter !== center && center) {
      this.map.setCenter(center)
      this.map.setZoom(zoom)
      this.addSearchMarker(center)
    }
    if (prevProps.ratesPeriod !== this.props.ratesPeriod) {
      this.updateRatesPeriod()
    }
    this.renderMarkers()
  }

  componentWillUnmount() {
    this.removeOldMarkers()
    this.removeSearchMarker()
    document.removeEventListener('mouseup', this.onDocumentClick)
    document.removeEventListener('touchend', this.onDocumentClick)
  }

  setInitialPosition = () => {
    const { pathname, search } = this.props.routerLocation
    const { zoom, centerLat, centerLng } = url.queryStringToObject(search)

    if (zoom && centerLat && centerLng) {
      // this.props.history.replace({
      //   pathname,
      //   search: url.omitFromQueryString(search, ['zoom', 'centerLat', 'centerLng']),
      // })
      const center = { lat: +centerLat, lng: +centerLng }
      this.props.updateQueryState(queryStringToState(search))
      this.map.setCenter(center)
      this.map.setZoom(+zoom)
      this.addSearchMarker(center)
    } else {
      this.preventNextQueryUpdate = true
      //this.map.setZoom(6) /* conflict with fitBounds */
      this.map.fitBounds(
        geo.boundsArrayToObject(this.props.boundingBox),
        this.padding
      )
    }
  }

  updateQueryWithNewBounds = () => {
    if (this.preventNextQueryUpdate) {
      return this.preventNextQueryUpdate = false
    }
    if (!this.$mapContainer) return

    const mapRect = this.$mapContainer.getBoundingClientRect()

    const southWestLatLng = geo.pointToLatLng(this.props.google, this.map, {
      x: this.padding,
      y: mapRect.height - this.padding,
    })
    const northEastLatLng = geo.pointToLatLng(this.props.google, this.map, {
      x: mapRect.width - this.padding,
      y: this.padding,
    })
    this.props.updateQuery({
      boundingBox: [
        southWestLatLng.lng(),
        southWestLatLng.lat(),
        northEastLatLng.lng(),
        northEastLatLng.lat(),
      ]
    })
  }

  renderMarkers = () => {
    this.removeOldMarkers()
    this.addNewMarkers()
  }

  removeOldMarkers = () => {
    const { results } = this.props

    this.markers = this.markers.filter(marker => {
      if (!results.items.find(property => property.id === marker.property.id)) {
        marker.setMap(null)
        return false
      }
      return true
    })
  }

  addNewMarkers = () => {
    const { google, results, ratesPeriod } = this.props

    results.items.forEach(property => {
      if (!this.markers.find(marker => marker.property.id === property.id)) {
        const latLng = new google.maps.LatLng(
          property.coordinates.lat,
          property.coordinates.lng,
        )
        const marker = renderPropertyMarker(google, this.map, property, latLng, ratesPeriod)
        this.markers.push(marker)
      }
    })
  }

  updateRatesPeriod = () => {
    this.markers.forEach(marker => {
      marker.updateRatesPeriod(this.props.ratesPeriod)
      marker.update()
    })
  }

  addSearchMarker = position => {
    this.removeSearchMarker()
    this.searchMarker = new google.maps.Marker({
      position,
      map: this.map,
    })
  }

  removeSearchMarker = () => {
    if (!this.searchMarker) return
    this.searchMarker.setMap(null)
    this.searchMarker = null
  }

  onDocumentClick = e => {
    const { $tooltipMarker } = this.state

    if ($tooltipMarker && (
      $tooltipMarker.contains(e.target) ||
      (this.$touchTooltip && this.$touchTooltip.contains(e.target))
    )) {
      return
    }
    if ($tooltipMarker) {
      $tooltipMarker.classList.remove(mapMarkerActiveClass)
    }
    if (!e.target.classList.contains('search_map_marker')) {
      this.map.setOptions({
        disableDoubleClickZoom: false,
      })
      this.setState({
        $tooltipMarker: null,
        tooltipData: null,
      })
    } else {
      e.target.classList.add(mapMarkerActiveClass)
      this.map.setOptions({
        disableDoubleClickZoom: true,
      })
      this.setState({
        $tooltipMarker: e.target,
        tooltipData: e.target.__PROPERTY__,
      }, () => {
        if (!device.isTouch()) {
          this.panMapToContainTooltip()
        }
      })
    }
  }

  panMapToContainTooltip = () => {
    const { google } = this.props
    const { lat, lng } = this.state.tooltipData.coordinates
    const latLng = new google.maps.LatLng(lat, lng)
    const point = geo.latLngToPoint(google, this.map, latLng)

    const mapRect = this.$mapContainer.getBoundingClientRect()
    const tooltipRect = this.$tooltip.getBoundingClientRect()
    const markerHeight = 35
    const padding = 15

    const tooltipPos = {
      top: point.y - markerHeight - tooltipRect.height,
      bottom: point.y - markerHeight,
      left: point.x - tooltipRect.width / 2,
      right: point.x + tooltipRect.width / 2,
    }
    const panBy = { x: 0, y: 0 }

    if (tooltipPos.right > mapRect.width - padding) {
      panBy.x = tooltipPos.right - mapRect.width + padding
    } else if (tooltipPos.left < padding) {
      panBy.x = tooltipPos.left - padding
    }
    if (tooltipPos.top < padding) {
      panBy.y = tooltipPos.top - padding
    } else if (tooltipPos.bottom > mapRect.height - padding) {
      panBy.y = tooltipPos.top - mapRect.height + padding
    }
    if (panBy.x || panBy.y) {
      this.preventNextQueryUpdate = true
      this.map.panBy(panBy.x, panBy.y)
    }
  }

  renderTooltip = () => (
    device.isTouch() ? (
      <div
        className="search_map_touchDeviceTooltip"
        ref={el => this.$touchTooltip = el}
      >
        <Result
          key={this.state.tooltipData.id}
          item={this.state.tooltipData}
          ratesPeriod={this.props.ratesPeriod}
        />
      </div>
    ) : (
      ReactDOM.createPortal((
        <Result
          item={this.state.tooltipData}
          ratesPeriod={this.props.ratesPeriod}
          getRef={el => this.$tooltip = el}
        />),
        this.state.$tooltipMarker
      )
    )
  )

  render() {
    return (
      <div className="search_map_touchDeviceTooltipContainer">
        <div
          className="search_map"
          ref={el => this.$mapContainer = el}
        />
        {this.state.tooltipData && this.renderTooltip()}
      </div>
    )
  }
}

Map.propTypes = propTypes

Map = connect(mapStateToProps, mapDispatchToProps)(Map)

export default googleMapsApiLoader(Map)
