import moment from 'moment'
import history from '@/browserHistory'
import * as url from '@/shared/utils/url'
import { isCurrentUserAuthenticated, isCurrentUserVerified } from '@/features/auth/utils'

const initialUrl = window.location.href
let initialPage = null
let currentPage = null

const { roomsieref, utm_source } = url.queryStringToObject(history.location.search)
const referrer = roomsieref || utm_source
if (referrer) {
  history.replace({
    pathname: history.location.pathname,
    search: url.omitFromQueryString(history.location.search, ['roomsieref', 'utm_source']),
  })
}

const safely = doStuff => {
  if (!window.mixpanel) return
  try {
    doStuff()
  }
  catch (e) {
  }
}

const defaultProps = () => ({
  dateTime: moment().toISOString(),
  isMobile: window.innerWidth < 768,
  isAuthenticated: !!isCurrentUserAuthenticated(),
  isVerified: !!isCurrentUserVerified(),
})

export const track = (eventName, eventProps) => {
  safely(() => {
    window.mixpanel.track(eventName, {
      ...eventProps,
      currentPage,
      ...defaultProps()
    })
  })
}

export const pageview = (pageName, pageProps) => {
  safely(() => {
    currentPage = pageName
    if (!initialPage) {
      initialPage = pageName
      checkAndTrackFirstVisit()
    }
    window.mixpanel.track(`Page: ${pageName}`, {
      ...pageProps,
      ...defaultProps()
    })
  })
  if (window.gtag) {
    window.gtag('config', window.GOOGLE_ANALYTICS_TRACKING_ID, {
      'page_title': pageName,
      'page_location': window.location.href,
    })
  }
}

export const handleSignUp = (user, provider) => {
  safely(() => {
    window.mixpanel.alias(user.email)
    window.mixpanel.identify(window.mixpanel.get_distinct_id())
    setUserProps(user)
    track('Signed Up', { provider })
  })
}

export const handleSignIn = (user, provider) => {
  safely(() => {
    window.mixpanel.identify(user.email)
    setUserProps(user)
    track('Signed In', { provider })
  })
}

const setUserProps = user => {
  window.mixpanel.people.set({
    '$email': user.email,
    '$first_name': user.firstName,
    '$last_name': user.lastName,
    '$name': `${user.firstName} ${user.lastName}`,
    'Birth Date': user.birthDate,
    'Gender': user.gender,
  })
  window.mixpanel.people.set_once({
    '$created': moment().toISOString(),
    firstVisitPage: window.mixpanel.get_property('firstVisitPage'),
    firstVisitUrl: window.mixpanel.get_property('firstVisitUrl'),
    firstVisitDateTime: window.mixpanel.get_property('firstVisitDateTime'),
    firstVisitReferrer: window.mixpanel.get_property('firstVisitReferrer'),
  })
}

const checkAndTrackFirstVisit = () => {
  window.mixpanel.register_once({
    firstVisitPage: initialPage,
    firstVisitUrl: initialUrl,
    firstVisitReferrer: referrer || 'None',
    firstVisitDateTime: moment().toISOString(),
  })
  trackFirstVisitEvent()
}

let firstVisitRetryCount = 0

const trackFirstVisitEvent = () => {
  try {
    if (!window.mixpanel.get_property('firstVisitTracked')) {
      window.mixpanel.register_once({ firstVisitTracked: true })
      track('First Visit')
    }
  }
  catch (e) {
    if (firstVisitRetryCount < 30) {
      firstVisitRetryCount++
      setTimeout(trackFirstVisitEvent, 350)
    }
  }
}
