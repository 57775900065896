import React, { Component } from 'react'
import pubsub from 'sweet-pubsub'
import { loadScript } from '@/shared/utils/script'

export default Child => {

  window.gmApiCallback = () => pubsub.emit('gmApiLoaded')

  class GoogleMapsApiLoader extends Component {

    constructor() {
      super()
      this.state = { google: window.google }

      
      pubsub.on('gmApiLoaded', this.setGoogle)
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_API_KEY}&libraries=geometry,places&callback=gmApiCallback`,
        'gmApiScript'
      )
    }

    componentWillUnmount() {
      pubsub.off('gmApiLoaded', this.setGoogle)
    }

    setGoogle = () => {
      this.setState({ google: window.google })
    }

    render() {
      if (!this.state.google) return null
      return <Child {...this.props} google={this.state.google} />
    }
  }

  return GoogleMapsApiLoader
}
