import { formatPrice } from '@/shared/utils/numbers'

export const mapMarkerActiveClass = 'search_map_marker--isActive'
export const mapMarkerIdClass = id => `search_map_marker--${id}`

let PropertyMarker = null

const largeWidth = 56
const smallWidth = 44

export const renderPropertyMarker = (google, ...args) => {
  if (!PropertyMarker) {

    PropertyMarker = function(map, property, latLng, ratesPeriod) {
      this.property = property
      this.latLng = latLng
      this.$marker = null
      this.updateRatesPeriod(ratesPeriod)
      this.setMap(map)
    }

    PropertyMarker.prototype = new google.maps.OverlayView()

    PropertyMarker.prototype.updateRatesPeriod = function(ratesPeriod) {
      this.rateMultiplier = ({ month: 30, week: 7, night: 1 })[ratesPeriod]
    }

    PropertyMarker.prototype.onAdd = function() {
      this.$marker = document.createElement('div')
      this.getPanes().overlayMouseTarget.appendChild(this.$marker)
      this.render()
    }

    PropertyMarker.prototype.render = function() {
      this.$marker.className = `
        search_map_marker
        ${mapMarkerIdClass(this.property.id)}
      `
      this.update()
      this.$marker.__PROPERTY__ = this.property
    }

    PropertyMarker.prototype.update = function() {
      const isLarge = formatPrice(this.property.minRate * this.rateMultiplier).length > 4

      this.$marker.textContent = `${formatPrice(this.property.minRate * this.rateMultiplier)}`
      this.$marker.style.width = `${isLarge ? largeWidth : smallWidth}px`
    }

    PropertyMarker.prototype.draw = function() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latLng)
      const isLarge = formatPrice(this.property.minRate * this.rateMultiplier).length > 4

      this.$marker.style.left = `${point.x - (isLarge ? largeWidth/2 : smallWidth/2)}px`
      this.$marker.style.top = `${point.y - 28}px`
    }

    PropertyMarker.prototype.onRemove = function() {
      if (!this.$marker) return
      this.$marker.parentNode.removeChild(this.$marker)
      this.$marker = null
    }
  }
  return new PropertyMarker(...args)
}
