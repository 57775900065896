import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleError } from '@/shared/api'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { isCurrentUserVerified } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { createConversation } from '@/features/messageModal/api'
import { Form, Modal, Button, Field, Avatar } from '@/shared/components'
import '@/features/messageModal/styles/index.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
  description: PropTypes.string.isRequired,
  recipient: PropTypes.object.isRequired,
  contextId: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  renderLink: PropTypes.func,
}

const defaultProps = {
  contextId: {},
  buttonLabel: 'Message',
  buttonProps: {},
}

const c = 'messageModal'

const MessageModal = (props) => {
  const { toAuth } = useAuthUrl()

  const handleCreateMessage = (values, form, closeModal) => {
    createConversation({
      ...props.contextId,
      recipientIds: [props.recipient.id],
      body: values.body,
    }).then(({ data }) => {
      closeModal()
      showToast({ title: 'Your message was sent successfully.' })
      analytics.track('Message Sent', getAnalyticsProps())
    }, ({ error }) => {
      if (error.details) {
        form.setErrors(error.details)
      } else {
        handleError({ error })
      }
      form.setSubmitting(false)
    })
  }

  const getAnalyticsProps = () => ({
    ...props.contextId,
    recipientId: props.recipient.id,
  })

  const { description, recipient, buttonLabel, buttonProps, renderLink } = props
  if (!isCurrentUserVerified()) {
    return <AuthLink toAuth={toAuth} {...props} />
  }
  return (
    <Modal
      className={c}
      containsForm
      onOpen={() => analytics.track('Message Modal Opened', getAnalyticsProps())}
      onClose={() => analytics.track('Message Modal Closed', getAnalyticsProps())}
      renderLink={() => renderLink ? renderLink() : (
        <Button hollow size="small" className={`${c}_link`} {...buttonProps}>
          {buttonLabel}
        </Button>
      )}
      renderContent={({ closeModal }) => (
        <Fragment>
          <div className={`${c}_recipient`}>
            <Avatar {...recipient} size={60} />
            <div className={`${c}_recipient_name`}>{`Message ${recipient.firstName}`}</div>
          </div>
          <p className={`${c}_description`}>{description}</p>
          <MessageForm handleCreateMessage={handleCreateMessage} closeModal={closeModal} {...props} />
        </Fragment>
      )}
    />
  )

}

const MessageForm = ({ handleCreateMessage, closeModal, currentUser, recipient }) => (
  <Form
    initialValues={{ body: '' }}
    validations={{
      body: [
        Form.is.required('Please enter a message'),
        Form.is.match(() => (
          currentUser.id !== recipient.id
        ), 'You can not message yourself'),
      ],
    }}
    onSubmit={(...args) => handleCreateMessage(...args, closeModal)}
    render={({ handleSubmit, isSubmitting, fields }) => (
      <form noValidate onSubmit={handleSubmit}>
        <Field.Textarea
          {...fields.body}
          placeholder="Enter message..."
          minRows={5}
        />
        <Button type="submit" working={isSubmitting}>
          Send Message
        </Button>
      </form>
    )}
  />
)

const AuthLink = ({ toAuth, renderLink, buttonProps, buttonLabel }) => (
  renderLink ? (
    <span onClick={toAuth}>{renderLink()}</span>
  ) : (
    <Button
      hollow
      size="small"
      className={`${c}_link`}
      onClick={toAuth}
      {...buttonProps}
    >
      {buttonLabel}
    </Button>
  )
)

MessageModal.propTypes = propTypes
MessageModal.defaultProps = defaultProps

export default connect(mapStateToProps)(MessageModal)
