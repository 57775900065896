import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import '@/plugin/property/styles/radioToggle.scss';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  getRef: PropTypes.func,
};

const defaultProps = {
  onChange: (event, value) => {},
  getRef: (element) => {},
};

class RadioToggle extends Component {
  handleChange = (e) => {
    this.props.onChange(e, e.target.value);
  };

  render() {
    const { className, label, onChange, getRef, ...radioProps } = this.props;

    const radioId = _.uniqueId('form-radio-');

    return (
      <div className={classnames('radio_toggle', className)}>
        <label tabIndex="0" htmlFor={radioId}>
          <input
            {...radioProps}
            type="radio"
            id={radioId}
            className="radio_element"
            onChange={this.handleChange}
            ref={getRef}
          />
          {/* <label tabIndex="0" htmlFor={radioId} className="radio_label">
            {label}
          </label> */}
          <span className="toggle_label">{label}</span>
        </label>
      </div>
    );
  }
}

RadioToggle.propTypes = propTypes;
RadioToggle.defaultProps = defaultProps;

export default RadioToggle;
