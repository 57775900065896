import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { handleError } from '@/shared/api'
import { report } from '@/guestBookings/api'
import { Modal, Button, Icon, ConfirmModal } from '@/shared/components'
import MessageModal from '@/features/messageModal/components'
import '@/guestBookings/styles/report.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  host: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const c = 'guestBooking_report'

class Report extends Component {

  handleSubmit = confirmModal => {
    report(this.props.booking.id).then(() => {
      this.props.fetchBooking()
    }, ({ error }) => {
      confirmModal.close()
      handleError({ error })
    })
  }

  renderStepOne = () => (
    <Fragment>
      <Icon type="warning" />
      <div className={`${c}_title`}>Report Booking Issue</div>
      <div className={`${c}_subtitle`}>Rent Safe with Roomsie</div>
      <p>This tool exist to allow you to report <strong>material issue(s)</strong> with your booking within 48 hours of your check-in date.</p>
      <p>If this is a simple maintenance or repair request for your home then please contact your host directly via our site.</p>
      <p><strong>Roomsie takes any reports filed through this process very seriously so make certain that you have attemped to address these issues with your host first.</strong></p>
      <p><strong>Abuse of this reporting tool can lead to penalities, up to and including, cancellation of your stay and surrendering of your deposit.</strong></p>
      <div className={`${c}_actions`}>
        <MessageModal
          buttonLabel="Message Host"
          buttonProps={{ size: 'medium', hollow: false }}
          description="Make sure to be polite and respectful when communicating with your host."
          recipient={this.props.host}
          contextId={{ bookingId: this.props.booking.id }}
        />
        <ConfirmModal
          renderLink={() => <Button hollow color="danger">Submit Report</Button>}
          title="Report issue and freeze all payments"
          confirmText="Submit Report"
          confirmInput="REPORT"
          message="Are you sure you want to report this booking and freeze all payments?"
          isDanger
          dontCloseOnConfirm
          onConfirm={this.handleSubmit}
        />
      </div>
    </Fragment>
  )

  renderStepTwo = closeModal => (
    <Fragment>
      <Icon type="check-circle" />
      <div className={`${c}_title`}>Report Successfully Submitted</div>
      <div className={`${c}_subtitle`}>Our investigation in to this matter has begun</div>
      <p>Your report has been successfully submitted and any outstanding payments to your host have been suspended until we conclude our investigation. In the meantime, please continue to try and work with your host.</p>
      <p>While Roomsie will do our best to help remedy this situation to the satisfaction of both our hosts and our guests, we are bound by all local rental law and landlord/tenant regulations.</p>
      <div className={`${c}_actions`}>
        <Button hollow onClick={closeModal}>Okay</Button>
      </div>
    </Fragment>
  )

  render() {
    return (
      <Modal
        className={c}
        renderLink={() => <Button hollow color="danger">Report Issue</Button>}
        renderContent={({ closeModal }) => (
          <Fragment>
            <div className={`${c}_top`}>
              {this.props.booking.status === 'reported' ? (
                this.renderStepTwo(closeModal)
              ) : (
                this.renderStepOne()
              )}
            </div>
            <div className={`${c}_bottom`}>
              <Link to="/guest-refund-policy" target="_blank">Guest Refund Policy</Link>
              <a href="https://roomsie.zendesk.com" target="_blank">Help Center</a>
            </div>
          </Fragment>
        )}
      />
    )
  }
}

Report.propTypes = propTypes

export default Report
