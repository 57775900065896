import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { formatDateRange } from '@/shared/utils/dateTime'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import MessageModal from '@/features/messageModal/components'
import { Avatar } from '@/shared/components'
import '@/hostGuests/styles/guestCard.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
}

const c = 'hostGuests_guestCard'

const GuestCard = ({ booking: { guest, propertySnapshot, bed, ...booking } }) => {

  const { toOpen } = useProfileModalUrl()

  return (
    <div className={`${c} ${c}--${booking.status}`}>
      <div className={`${c}_inner`}>
        <Avatar {...guest} size={100} />
        <div className={`${c}_guest`} onClick={() => toOpen(guest.id)}>
          <div className={`${c}_guest_status`}>
            {booking.status === 'started' ? 'Current Guest' : 'Scheduled Guest'}
          </div>
          <div className={`${c}_guest_name`}>
            {`${guest.firstName} ${guest.lastName}`}
          </div>
          <div className={`${c}_guest_ageGender`}>
            {`${guest.age} year old, ${_.upperFirst(guest.gender)}`}
          </div>
          <div className={`${c}_guest_location`}>
            {`${[guest.city, guest.state].filter(p => p).join(', ')}`}
          </div>
        </div>
        <div className={`${c}_booking`}>
          <Link to={`/host/bookings/${booking.id}`}>
            <div className={`${c}_booking_propertyName`}>
              {_.truncate(propertySnapshot.title, { length: 35 })}
            </div>
            <div className={`${c}_booking_address`}>
              {[
                propertySnapshot.streetAddress,
                propertySnapshot.streetAddress2,
                propertySnapshot.bedroomTitle,
              ].filter(p => p).join(', ')}
            </div>
            <div className={`${c}_booking_dates`}>
              {formatDateRange(booking.startDate, booking.endDate)}
              <div>{' | '}</div>
              <span>View Booking</span>
            </div>
          </Link>
        </div>
        <div className={`${c}_message`}>
          <MessageModal
            description="Make sure to provide your guests with all the information they need. Don't be afraid to over-communicate."
            recipient={guest}
            buttonProps={{ size: 'medium' }}
            contextId={{ bookingId: booking.id }}
          />
        </div>
      </div>
    </div>
  )
}

GuestCard.propTypes = propTypes

export default GuestCard
