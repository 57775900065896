import React, { Component, Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import { handleError } from '@/shared/api'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { sortPhotos } from '@/shared/utils/photos'
import { formatAddress } from '@/shared/utils/geo'
import { formatDate } from '@/shared/utils/dateTime'
import { fetchConversation, createMessage } from '@/conversations/api'
import { Form, Avatar, PageSpinner, Button,
  Textarea, FieldErrors, Icon, Head, TextEditedHtml } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/conversations/styles/conversation.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object.isRequired
}

const c = 'conversation'

const initialPathname = window.location.pathname

const Conversation = (props) => {
  
  const { conversationId } = useParams()
  const navigate = useNavigate()

  const [state, setState] = useState({
   convo: null
  })

  useEffect(() => {
    fetchConversation(conversationId).then(({ data }) => {
      analytics.pageview('Conversation', { conversationId: data.id })
      setState(prevState => ({
        ...prevState,
        convo: data 
      }))
    }, handleError)
  }, [])

  const handleCreateMessage = (values, form) => {
    createMessage(conversationId, values).then(({ data }) => {
      if (!initialPathname.includes('/messages/')) {
        navigate(-1)
      }
      showToast({ title: 'Your message was sent successfully.' })
      form.resetForm()
      setState(prevState => ({
        ...prevState,
        convo: {
          ...state.convo,
          messages: [data, ...convo.messages],
        },
      }))
    }, ({ error }) => {
      if (error.details) {
        form.setErrors(error.details)
      } else {
        handleError({ error })
      }
      form.setSubmitting(false)
    })
  }

  const { convo } = state
  const { currentUser } = props

  return (
    <div className={c}>
      <AppHeader />
      <div className={`${c}_page`}>
        {convo ? (
          <Fragment>
            <Head title={`${convo.users[0].firstName} - Conversation - Roomsie`} />
            <Sidebar currentUser={currentUser} users={convo.users} convo={convo} />
            <Messages currentUser={currentUser} convo={convo} handleCreateMessage={handleCreateMessage} />
          </Fragment>
        ) : (
          <PageSpinner />
        )}
      </div>
      <AppFooter />
    </div>
  )

}

const Sidebar = ({ currentUser, users, ...convo }) => (
  <div className={`${c}_sidebar`}>
    <div className={`${c}_user`}>
      <Avatar {...users[0]} size={100}/>
      <div className={`${c}_user_name`}>
        {users[0].firstName} {users[0].lastName}
      </div>

      {convo.convo && convo.convo.booking && (
        <div className={`${c}_tip_title`}>
          {formatDate(convo.convo.booking.startDate, 'monthDay')} - {formatDate(convo.convo.booking.endDate, 'monthDay')}
        </div>
      )}

      <div className={`${c}_user_address`}>
        {users[0].school}
      </div>

      {convo.convo && convo.convo.booking && (
        <div className={`${c}_user_address`}>
          {convo.convo.booking.propertySnapshot.streetAddress}, {convo.convo.booking.propertySnapshot.streetAddress2}
        </div>
      )}
      <div className={`${c}_user_address`}>
        {users[0].email}
      </div>

      <div className={`${c}_user_address`}>
        {convo.convo.phoneNumber}
      </div>

      {(users[0].city || users[0].state) && (
        <div className={`${c}_user_address`}>
          {formatAddress(users[0])}
        </div>
      )}
    </div>
    <Context convo={convo} currentUser={currentUser}/>
    <div className={`${c}_tip`}>
      <div className={`${c}_tip_title`}>
        Always communicate through Roomsie
      </div>
      <p>To protect your payment, never transfer money or communicate outside of the Roomsie website.</p>
    </div>
  </div>
)

const Context = ({ convo, currentUser }) => {
  if (convo.property && !convo.booking) {
    return <ContextProperty const property={convo.property} />
  }
  if (convo.booking) {
    return (
      <Fragment>
        <ContextBooking booking={convo.booking} currentUser={currentUser} />
        <ContextProperty property={convo.booking.propertySnapshot} />
      </Fragment>
    )
  }
  if (convo.program) {
    <ContextProgram program={convo.program} />
  }
  return null
}

const ContextProperty = ({ property }) => (
  <Link
    to={`/properties/${property.id}`}
    target="_blank"
    className={`${c}_property`}
  >
    <div className={`${c}_property_title`}>
      {property.title}
    </div>
    <div className={`${c}_property_type`}>
      {`${options.labels.placementTypes[property.placementType]}
        ${options.labels.listingTypes[property.listingType].replace('Space', '')} Space`}
    </div>
    <div className={`${c}_property_address`}>
      {formatAddress(property)}
    </div>
  </Link>
)

const ContextBooking = ({ booking, currentUser }) => {
  const path = currentUser.id === booking.host.id ? 'host' : 'guest'
  return (
    <Link
      to={`/${path}/bookings/${booking.id}`}
      target="_blank"
      className={`${c}_booking`}
    >
      <div className={`${c}_booking_id`}>
        {`Booking #${booking.id}`}
      </div>
      <div className={`${c}_booking_dates`}>
        <div>
          <div className={`${c}_booking_label`}>Arrival</div>
          <div className={`${c}_booking_date`}>
            {formatDate(booking.startDate)}
          </div>
        </div>
        <Icon type="chevron-right" />
        <div>
          <div className={`${c}_booking_label`}>Departure</div>
          <div className={`${c}_booking_date`}>
            {formatDate(booking.endDate)}
          </div>
        </div>
      </div>
    </Link>
  )
}

const ContextProgram = ({ program }) => (
  <Link
    to={`/p/${program.slug}`}
    target="_blank"
    className={`${c}_program`}
  >
    {program.title}
  </Link>
)

const Messages = ({ currentUser, convo, handleCreateMessage }) => (
  <div className={`${c}_messages`}>
    <NewMessageForm currentUser={currentUser} handleCreateMessage={handleCreateMessage} />
    {convo.messages.sort((a, b) => b.id - a.id).map((message) => 
      <Message key={`message-${message.id}`} message={message} currentUser={currentUser} />
    )}
  </div>
)

const Message = ({ message, currentUser }) => (
  <div key={message.id} className={classnames(`${c}_message`, {
    [`${c}_message--fromCurrentUser`]: message.user.id === currentUser.id,
  })}>
    <Avatar {...message.user} size={60} />
    <div className={`${c}_message_content`}>
      <TextEditedHtml html={message.body} />
      <div className={`${c}_message_date`}>
        {formatDate(message.createdAt, 'monthDayYearTime')}
      </div>
    </div>
  </div>
)

const NewMessageForm = ({ handleCreateMessage, currentUser }) => (
  <Form
    initialValues={{ body: '' }}
    validations={{
      body: Form.is.required('Please enter a message'),
    }}
    onSubmit={handleCreateMessage}
    render={({ handleSubmit, isSubmitting, fields }) => (
      <form noValidate onSubmit={handleSubmit} className={`${c}_newMessageForm`}>
        <Textarea
          {...fields.body}
          placeholder="Enter message..."
          minRows={1}
          errors={null}
        />
        <div className={`${c}_newMessageForm_bottom`}>
          <Button size="small" type="submit" working={isSubmitting}>
            Send Message
          </Button>
        </div>
        <FieldErrors errors={fields.body.errors} />
        <Avatar {...currentUser} size={60} />
      </form>
    )}
  />
)

Conversation.propTypes = propTypes

export default connect(mapStateToProps)(Conversation)
