export const getProperty = () => ({
  title: '',
  description: '',
  listingType: 'entire_space',
  placementType: 'unisex',
  propertyType: '',
  bedroomsCount: 1,
  bathroomsCount: 1,
  accommodates: 1,
  sessions: [],
  blackoutDates: [],
  bedrooms: [],
  rates: [],
  fees: [],
  amenities: [],
  rules: '',
  streetAddress: '',
  streetAddress2: '',
  city: '',
  state: '',
  zip: '',
  neighborhood: '',
  coordinates: null,
  photos: [],
  checkInTimeEnd: null,
  checkInTimeStart: null,
  prorateForShortStay: true
})

export const getErrors = () => ({
  basics: {},
  location: {},
  amenitiesRules: {},
  photos: {},
  availability: {},
  ratesRooms: {},
  fees: {},
})
