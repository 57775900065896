import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import options from '@/shared/static/options'
import { dateToString, stringToDate } from '@/shared/utils/dateTime'
import { uniqueDateId } from '@/shared/utils/id'
import { syncRatesWithSessions } from '@/listSpace/state/sync'
import { Button, DateRangePicker, Select, FieldErrors, Icon, CheckboxGroup, Checkbox } from '@/shared/components'
import PrevNext from './PrevNext'
import '@/listSpace/styles/datesAvailability.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  sessions: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

const SESSIONS = 'sessions'
const BLACKOUTS = 'blackoutDates'

const c = 'listSpace_datesAvailability'

class DatesAvailability extends Component {

  state = {
    prorateForShortStay: true,
  }

  componentDidMount() {
    this.setState({ prorateForShortStay: this.props.property.prorateForShortStay})

    if (!this.props.property.sessions.length) {
      this.addSession()
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.prorateForShortStay !== prevState.prorateForShortStay) {
      const proratesEnabled = this.state.prorateForShortStay
      this.props.updateProperty({ prorateForShortStay: proratesEnabled })
    }
  }

  datesToStrings = items => items.map(item => ({
    ...item,
    startDate: dateToString(item.startDate),
    endDate: dateToString(item.endDate),
  }))

  stringsToDates = items => items.map(item => ({
    ...item,
    startDate: stringToDate(item.startDate),
    endDate: stringToDate(item.endDate),
  }))

  getItems = type => (
    this.stringsToDates(this.props.property[type])
  )

  getMainSessions = () => {
    let filteredSessions = []
    const sessions = this.getItems(SESSIONS).sort((a, b) => a.uid - b.uid)
    const groupedSessions =  _.groupBy(sessions, 'startDate')
    filteredSessions = Object.entries(groupedSessions).map(sessionGroup => (
      sessionGroup[1][0]
    ))
    return filteredSessions
  }

  setSessionMinStay = (duration) => {
    const sessions = this.getMainSessions().map(session => {
        session.minimumStayDays = duration
        return session
      }
    )
    return sessions
  }

  getMinimumStayOptions = session => (
    _.range(1, 53).map(i => ({
      value: i * 7,
      label: `${i} Week${i === 1 ? '' : 's'}`,
      disabled: (
        session.startDate &&
        session.endDate &&
        moment(session.endDate).diff(session.startDate, 'days') < i * 7 ? true : false
      )
    }))
  )

  getSessionRates = (startDate, endDate) => 
    this.props.sessions.filter(session => session.startDate === dateToString(startDate) && session.endDate === dateToString(endDate)).sort((a, b) => a.uid - b.uid)

  getDisabledItemDays = (type, itemUid) => {
    let items = []
    type === SESSIONS
      ? items = this.getMainSessions()
      : items = this.getItems(type)
    return (
      items
        .filter(s => s.startDate && s.endDate && s.uid !== itemUid)
        .map(s => ({ from: s.startDate, to: s.endDate }))
    )
  }

  addSession = () => {
    this.handleChange({
      sessions: [
        ...this.getItems(SESSIONS), {
          uid: uniqueDateId(),
          startDate: null,
          endDate: null,
          minimumStayDays: 7,
        },
      ]
    })
  }

  addBlackout = () => {
    this.handleChange({
      blackoutDates: [
        ...this.getItems(BLACKOUTS), {
          uid: uniqueDateId(),
          startDate: null,
          endDate: null,
        },
      ]
    })
  }

  updateItem = (type, item, data) => {
    const items = [...this.getItems(type)]
    const index = _.findIndex(items, { uid: item.uid })
    items[index] = { ...item, ...data }
    this.handleChange({
      [type]: this.resetOtherIncompleteDates(index, items),
    })
    if (items[index].startDate && items[index].endDate) {
      this.props.clearStepErrors(`${type}.${item.uid}`)
    }
  }

  resetOtherIncompleteDates = (index, items) => (
    items.map((item, i) => (
      i === index || (item.startDate && item.endDate) ? item : {
        ...item, startDate: null, endDate: null,
      }
    ))
  )

  removeItem = (type, itemUid) => {
    this.handleChange({
      [type]: this.getItems(type).filter(({ uid }) => (
        uid !== itemUid
      ))
    })
  }

  updateMinStay = async (session, value) => {
    await this.updateItem(SESSIONS, session, {
      minimumStayDays: parseInt(value),
    })
    // remove session rates with shorter or equal min stay
    const { sessions } = this.props
    let filteredSessions = _.filter(sessions, o => (
        o.uid == session.uid ||
        o.minimumStayDays > value ||
        o.startDate != dateToString(session.startDate) 
      )
    )
    this.handleChange({ sessions: filteredSessions })
  }

  removeSessionRates = (session) => {
    const sessionRates = this.getSessionRates(session.startDate, session.endDate)
    this.handleChange({
      sessions: _.difference(this.props.sessions, sessionRates)
    })
  }

  handleChange = items => {
    const type = items.sessions ? SESSIONS : BLACKOUTS
    if (items.sessions) {
      this.props.updateProperty({
        [type]: this.datesToStrings(items[type]),
        ...syncRatesWithSessions(items.sessions, this.props.property),
      })
    } else {
      this.props.updateProperty({
        [type]: this.datesToStrings(items[type]),
      })
    }
  }

  handleSessionDateChange = (session, from, to) => {
    const sessionRates = this.getSessionRates(session.startDate, session.endDate)
    const filteredSessions = _.difference(this.props.sessions, sessionRates)
    session.startDate = from
    session.endDate = to
    this.handleChange({
      sessions: [session, ...filteredSessions]
    })
  }

  onCheck = () => {
    if (this.state.prorateForShortStay) {
      this.setState({ prorateForShortStay: false })
      this.handleChange({
        sessions: [...this.setSessionMinStay(1)]
      })
    } else {
      this.setState({ prorateForShortStay: true })
      this.handleChange({
        sessions: [...this.setSessionMinStay(7)]
      })
    }
    // !!this.state.prorateForShortStay ? (
    //   this.handleChange({
    //     sessions: [...this.setSessionMinStay(1)]
    //   })
    // ) : (
    //   this.props.handleChange({
    //     sessions: [...this.setSessionMinStay(7)]
    //   })
    // )  
  }

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next')
    }
  }

  renderSessions = (sessions, errors = {}) => (
    <div className={`${c}_sessions`}>
      <div className={`${c}_sessions_labels`}>
        <div className="listSpace_label">Availability Range</div>
        {!!this.state.prorateForShortStay && <div className="listSpace_label">Minimum Stay</div>}
      </div>
      <div className={`${c}_sessions_tips`}>
        <p className="listSpace_tip">
          {"Select the dates for which you'd like to rent your property."}
        </p>
        {!!this.state.prorateForShortStay &&
          <p className="listSpace_tip">
            {"Set the minimum number of consecutive weeks for which you will accept a reservation."}
          </p>
        }
      </div>
      {this.getMainSessions().map((session, i) => (
        <div key={session.uid} className={`${c}_session`}>
          <div className={`${c}_session_dates`}>
            <DateRangePicker
              value={{ from: session.startDate, to: session.endDate }}
              onChange={({ from, to }) => this.handleSessionDateChange(session, from, to)}
              minimumStayDays={+session.minimumStayDays}
              disabledDays={this.getDisabledItemDays(SESSIONS, session.uid)}
              inputProps={{ invalid: !!errors[session.uid] }}
            />
            <FieldErrors errors={errors[session.uid]} />
          </div>
          {!!this.state.prorateForShortStay &&
            <Select
              options={this.getMinimumStayOptions(session)}
              value={+session.minimumStayDays}
              onChange={(e, value) => this.updateMinStay(session, value)}
            />
          }
          {!!i && (
            <Icon
              type="trash"
              onClick={() => this.removeSessionRates(session)}
            />
          )}
        </div>
      ))}
      <Button
        className={`${c}_addItem`}
        hollow
        square
        icon="plus"
        type="button"
        onClick={this.addSession}
      >
        Add Session Dates
      </Button>
    </div>
  )

  renderBlackouts = (blackoutDates, errors = {}) => (
    <div className={`${c}_blackouts`}>
      <div className="listSpace_label">
        Blackout Dates
      </div>
      <p className="listSpace_tip">
        Dates for which your property is not available for rent.
      </p>
      {blackoutDates.sort((a, b) => a.uid - b.uid).map((blackout, i) => (
        <div key={blackout.uid} className={`${c}_blackout`}>
          <div className={`${c}_blackout_dates`}>
            <DateRangePicker
              value={{ from: blackout.startDate, to: blackout.endDate }}
              onChange={({ from, to }) => this.updateItem(BLACKOUTS, blackout, {
                startDate: from,
                endDate: to,
              })}
              minimumStayDays={0}
              disabledDays={this.getDisabledItemDays(BLACKOUTS, blackout.uid)}
              inputProps={{ invalid: !!errors[blackout.uid] }}
              tooltipProps={{ windowBounds: { bottom: true }}}
            />
            <FieldErrors errors={errors[blackout.uid]} />
          </div>
          <Icon
            type="trash"
            onClick={() => this.removeItem(BLACKOUTS, blackout.uid)}
          />
        </div>
      ))}
      <Button
        className={`${c}_addItem`}
        hollow
        square
        icon="plus"
        type="button"
        onClick={this.addBlackout}
      >
        Add Blackout Dates
      </Button>
    </div>
  )

  renderProratesCheckbox = () => (
    <div className={`${c}_prorates`}>
      <Checkbox
        size="large"
        value={this.state.prorateForShortStay}
        label="Do you want to prorate for shorter stay?"
        checked={this.state.prorateForShortStay}
        onChange={() => this.onCheck()}
      />
    </div>
  )

  render() {
    return (
      <div className={`${c} listSpace_content`}>
        <h1 className="listSpace_title">
          Dates of Availability
        </h1>
        <p className="listSpace_subtitle">
          {'Specify one or more unique date ranges for which your property is available (i.e match your dates with those of local academic calendars or set your own high and low seasons). You will later be able to set different rates for each session.'}
        </p>
        {this.renderProratesCheckbox()}
        {this.renderSessions(this.getItems(SESSIONS), this.props.errors.sessions)}
        {this.renderBlackouts(this.getItems(BLACKOUTS), this.props.errors.blackoutDates)}
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    )
  }
}

DatesAvailability.propTypes = propTypes

export default DatesAvailability
