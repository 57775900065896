import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { formatDate } from '@/shared/utils/dateTime'
import * as utils from '@/hostManagement/utils'
import '@/hostManagement/styles/month.scss'

const propTypes = {
  rangeStart: PropTypes.any.isRequired,
  bookings: PropTypes.array.isRequired,
  propertyIdFilter: PropTypes.number,
  onDaySelect: PropTypes.func.isRequired,
}

const c = 'hostManagement_month'

class Month extends Component {

  shouldComponentUpdate(nextProps) {
    return utils.shouldCalendarRender(nextProps, this.props)
  }

  renderDayNames = () => (
    ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(name => (
      <div key={name} className={`${c}_dayName`}>{name}</div>
    ))
  )

  renderFillerDates = dates => (
    <Fragment>
      {dates.map(date => (
        <div key={date} className={`${c}_day ${c}_day--isFiller`}>
          <div className={`${c}_day_label`}>
            {formatDate(date, 'dayNumber')}
          </div>
        </div>
      ))}
    </Fragment>
  )

  render() {
    let { bookings, rangeStart, propertyIdFilter, onDaySelect } = this.props

    bookings = utils.filterBookingsByProperty(bookings, propertyIdFilter)

    const fillerCountBefore = rangeStart.diff(moment(rangeStart).startOf('isoWeek'), 'days')
    const fillerDatesBefore = _.times(fillerCountBefore).map(i => (
      moment(rangeStart).subtract(fillerCountBefore - i, 'days')
    ))

    const rangeEnd = moment(rangeStart).endOf('month')
    const fillerCountAfter = moment(rangeEnd).endOf('isoWeek').diff(rangeEnd, 'days')
    const fillerDatesAfter = _.times(fillerCountAfter).map(i => (
      moment(rangeEnd).add(i + 1, 'days')
    ))

    const monthDates = _.times(rangeStart.daysInMonth()).map(i => (
      moment(rangeStart).add(i, 'days')
    ))
    return (
      <div className={c}>
        {this.renderDayNames()}
        {this.renderFillerDates(fillerDatesBefore)}
        {monthDates.map(date => {
          const { checkInIds, checkOutIds } = utils.getCheckInOutIds(bookings, date)
          const dayBookings = utils.getDayBookings(bookings, checkInIds, checkOutIds)
          const checkInCount = _.values(checkInIds).length
          const checkOutCount = _.values(checkOutIds).length
          const totalCount = checkInCount + checkOutCount
          return (
            <div
              key={date}
              className={classnames(`${c}_day`, {
                [`${c}_day--isToday`]: moment().isSame(date, 'day'),
                [`${c}_day--isEmpty`]: !totalCount,
              })}
              onClick={() => onDaySelect(date)}
            >
              <div className={`${c}_day_label`}>
                {formatDate(date, 'dayNumber')}
              </div>
              {!!totalCount && (
                <div className={`${c}_counts`}>
                  <div className={`${c}_checkIn_count`}>{checkInCount}</div>
                  &#183;
                  <div className={`${c}_checkOut_count`}>{checkOutCount}</div>
                </div>
              )}
              {utils.sortBookings(dayBookings, checkOutIds).slice(0, 2).map(booking => (
                <Link
                  key={booking.id}
                  to={`/host/bookings/${booking.id}`}
                  className={classnames(`${c}_booking`, {
                    [`${c}_booking--isCheckIn`]: checkInIds[booking.id],
                    [`${c}_booking--isCheckOut`]: checkOutIds[booking.id],
                    [`${c}_booking--isProcessed`]: utils.isProcessed(booking, checkInIds, checkOutIds),
                  })}
                >
                  {`${booking.guest.firstName} ${booking.guest.lastName}`}
                </Link>
              ))}
              {totalCount > 2 && (
                <div className={`${c}_viewAllGuests`}>
                  {`View ${totalCount} guests`}
                </div>
              )}
            </div>
          )
        })}
        {this.renderFillerDates(fillerDatesAfter)}
      </div>
    )
  }
}

Month.propTypes = propTypes

export default Month
