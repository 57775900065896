import _ from 'lodash'

const browserHasStorage = () => {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.getItem('test')
    window.localStorage.removeItem('test')
    return true
  } catch(e) {
    return false
  }
}

const safeParseJSON = str => {
  let value;
  try {
    value = JSON.parse(str)
  }
  catch (e) {
    value = null
  }
  return value
}

const safeStringifyJSON = obj => {
  let value;
  try {
    value = JSON.stringify(obj)
  }
  catch (e) {
    value = '{}'
  }
  return value
}

const storage = {}

if (browserHasStorage()) {
  _.each([
    ['session', 'sessionStorage'],
    ['local', 'localStorage'],
  ], ([type, windowType]) => {
    storage[type] = {
      get: key => window[windowType].getItem(key),
      set: (key, val) => window[windowType].setItem(key, val),
      remove: key => window[windowType].removeItem(key),
      clear: () => window[windowType].clear(),

      getJSON: (key, path) => {
        const str = window[windowType].getItem(key)
        if (!str) return null
        const value = safeParseJSON(str)
        return path ? _.get(value, path) : value
      },
      setJSON: (key, callback) => {
        const str = window[windowType].getItem(key)
        const value = str ? (safeParseJSON(str) || {}) : {}
        const updatedValue = callback(value)
        window[windowType].setItem(key, safeStringifyJSON(updatedValue))
      },
      updateJSON: (key, callback) => {
        const str = window[windowType].getItem(key)
        let value = str ? (safeParseJSON(str) || {}) : {}
        callback(value)
        window[windowType].setItem(key, safeStringifyJSON(value))
      },
    }
  })
} else {
  const stores = { session: {}, local: {} }

  _.each(['session', 'local'], type => {
    storage[type] = {
      get: key => stores[type][key],
      set: (key, val) => stores[type][key] = val,
      remove: key => delete stores[type][key],
      clear: () => stores[type] = {},

      getJSON: (key, path) => {
        const str = stores[type][key]
        if (!str) return null
        const value = safeParseJSON(str)
        return path ? _.get(value, path) : value
      },
      setJSON: (key, callback) => {
        const value = stores[type][key] ? safeParseJSON(stores[type][key] || {}) : {}
        const updatedValue = callback(value)
        stores[type][key] = safeStringifyJSON(updatedValue)
      },
      updateJSON: (key, callback) => {
        let value = stores[type][key] ? safeParseJSON(stores[type][key] || {}) : {}
        callback(value)
        stores[type][key] = safeStringifyJSON(value)
      },
    }
  })
  alert(
    `Your web browser does not support storing settings locally.
    The most common cause of this is using "Private Browsing Mode".
    Some features may not work properly for you.`
  )
}

export default storage
