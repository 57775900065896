import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, useNavigate, useLocation, useSearchParams, Outlet } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import storage from '@/shared/utils/storage'
import { to404 } from '@/shared/utils/404'
import { showToast } from '@/shared/utils/toast'
import { queryStringToObject } from '@/shared/utils/url'
import { scrollToFirstError } from '@/shared/utils/validation'
import * as initialState from '@/listSpace/state/initialState'
import { steps } from '@/listSpace/state/steps'
import { validateSteps } from '@/listSpace/state/validation'
import { handleError } from '@/shared/api'
import * as api from '@/listSpace/api'
import { apiToFormState, formStateToApi } from '@/listSpace/api/resource'
import { Head } from '@/shared/components'
import Header from './Header'
import StepBar from './StepBar'
import '@/listSpace/styles/index.scss'
import StepComponent from './StepComponent'

export const stepRoutes = 
  steps.map(({ id, path, Component }, i) => ({
    path: `/list-space${path}`,
    element: <StepComponent id={id} path={path} Component={Component} />
  })
)

const ListSpace = (props) => {
  // Make sure to increase version every time you make changes to state structure
  // to prevent users from pulling data with old structure from their local storage
  const version = '1'

  const navigate = useNavigate()
  const location = useLocation()
  let [searchParams, setSearchParams] = useSearchParams()
  
  const propertyId = searchParams.get('propertyId')
  const isEdit = () => !!propertyId

  const [state, setState] = useState(() =>  ({
    isLoaded: false,
    isSubmitting: false,
    property: initialState.getProperty(),
    errors: initialState.getErrors(),
  }))

  useEffect(() => {
    analytics.pageview('List Space Form', {
      formType: isEdit() ? 'Edit' : 'Create',
      propertyId: propertyId,
    })
    isEdit() ? fetchExistingProperty() : fetchStoredFormState()
  }, [])
  
  const fetchExistingProperty = () => {
    api.fetchProperty(propertyId).then(({ data }) => {
      updateProperty(apiToFormState(data))
      setState(prevState => ({
        ...prevState,
        isLoaded: true
      }))
    }, () => {
      to404('We could not find this property.')
    })
  }

  const fetchStoredFormState = () => {
    const storedState = storage.local.getJSON('listSpaceFormState')

    if (storedState && storedState.version === version) {
      updateProperty(storedState.property)
    }
    setState(prevState => ({
      ...prevState,
      isLoaded: true
    }))
  }

  const storeFormState = () => {
    const { property } = state

    const photosToStorage = photos => (
      photos.map(photo => ({ ...photo, base64Preview: null }))
    )
    storage.local.setJSON('listSpaceFormState', () => ({
      version: version,
      property: {
        ...property,
        photos: photosToStorage(property.photos),
        bedrooms: property.bedrooms.map(bedroom => ({
          ...bedroom,
          photos: photosToStorage(bedroom.photos),
        })),
      },
    }))
  }

  const updateProperty = propertyData => {
    setState(prevState => ({
      ...prevState,
      property: { ...state.property, ...propertyData }
    }))
  }

  useEffect(() => {
    if (!isEdit()) storeFormState()
  }, [state.property])
  
  const resetCreateForm = () => {
    setState(prevState => ({
      ...prevState,
      property: initialState.getProperty(),
      errors: initialState.getErrors()
    }))

    storeFormState()
    goToStep('basics')
  }

  const validateAllSteps = () => {
    const stepValidations = validateSteps(state.property)
    const formSteps = steps.filter(s => s.id !== 'review')
    const invalidStep = formSteps.find(step => !stepValidations[step.id].isValid)
    const invalidStepId = _.get(invalidStep, 'id')

    if (invalidStepId) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...state.errors,
          [invalidStepId]: stepValidations[invalidStepId].errors,
        },
      }))
    }
    return { invalidStepId }
  }

  useEffect(() => {
    if (!!state.errors) {
      scrollToFirstError()
    }
  }, [state.errors])

  const validateStep = step => () => {
    const { errors, isValid } = validateSteps(state.property)[step]
    if (!isValid) {
      setState(prevState => ({
        ...prevState,
        errors: { ...state.errors, [step]: errors },
      }))
    }
    return { isValid }
  }

  const clearStepErrors = step => (paths, value = null) => {
    const errors = _.cloneDeep(state.errors)
    let hasClearedAnyErrors = false

    _.flatten([paths]).forEach(path => {
      if (!_.isEmpty(_.get(errors, `${step}.${path}`))) {
        _.set(errors, `${step}.${path}`, value)
        hasClearedAnyErrors = true
      }
    })
    if (hasClearedAnyErrors) {
      setState(prevState => ({
        ...prevState,
        errors: errors,
      }))
    }
  }

  const submitForm = ({ shouldActivate } = {}) => {
    const { invalidStepId } = validateAllSteps()
    if (invalidStepId) {
      return goToStep(invalidStepId)
    }
    setState(prevState => ({
      ...prevState,
      isSubmitting: true
    }))

    api[isEdit() ? 'updateProperty' : 'createProperty'](
      formStateToApi(state.property)
    ).then(({ data }) => {
      if (!isEdit()) {
        storage.local.remove('listSpaceFormState')
        if (shouldActivate) {
          activateProperty(data.id)
        } else {
          navigate('/list-space-success')
        }
      } else {
        navigate('/host/properties')
      }
    }, ({ error }) => {
      setState(prevState => ({
        ...prevState,
        isSubmitting: false
      }))
      handleSubmitApiError(error)
    })
  }

  const activateProperty = propertyId => {
    const toSuccess = () => {
      navigate('/list-space-success')
    }
    api.fetchProperty(propertyId).then(({ data }) => {
      api.pauseListing(
        _.get(data, 'listing.id'), { paused: false }
      ).then(toSuccess, toSuccess)
    }, toSuccess)
  }

  const handleSubmitApiError = error => {
    if (error.details) {
      showToast({
        type: 'danger',
        title: 'You forgot to fill in some fields. Please go through the form by clicking the "Next" button.',
        message: _.flatMap(error.details, (messages, field) => (
          messages.map(message => `- ${field}: ${message}`)
        )),
        duration: 25,
      })
    } else {
      handleError({ error })
    }
    goToStep('basics')
  }

  const getCurrentStepPath = () => {
    let stepPath = location.pathname.split('/').pop()
    if (stepPath === 'list-space') return ''
    return `/${stepPath}`
  }

  const getCurrentStepIndex = () => (
    _.findIndex(steps, { path: getCurrentStepPath() })
  )


  const goToStep = step => {
    let path = null
    if (step === 'prev' || step === 'next') {
      const index = getCurrentStepIndex() + (step === 'prev' ? -1 : 1)
      path = steps[index].path
    } else {
      path = steps.find(s => s.id === step).path
    }
    navigate(toStep(path))
  }

  const toStep = stepPath => ({
    pathname: `/list-space${stepPath}`,
    search: location.search,
  })

  const getTitle = () => isEdit() ? (
    'Edit Property - Roomsie'
  ) : (
    'Become a Host and Rent Out Your Room, House or Apartment on Roomsie'
  )
  
  return state.isLoaded && (
    <div className="listSpace">
      <Head title={getTitle()} url="/list-space" />
      <Header
        property={state.property}
        isEdit={isEdit()}
        isSubmitting={state.isSubmitting}
        submitForm={submitForm}
        resetCreateForm={resetCreateForm}
      />
      <StepBar
        steps={steps}
        currentStepIndex={getCurrentStepIndex()}
        toStep={toStep}
        isEdit={isEdit()}
      />
      <Outlet 
        context={[ 
          state, 
          updateProperty,
          validateStep, 
          clearStepErrors, 
          submitForm, 
          isEdit, 
          steps, 
          toStep, 
          goToStep 
        ]} 
      />
    </div>
  )
}

export default ListSpace
