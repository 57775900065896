import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Photo, Media } from '@/shared/components'
import '@/property/styles/heroGrid.scss'

const propTypes = {
  photos: PropTypes.array.isRequired,
  onClick: PropTypes.func,
}

const HeroGrid = ({ photos, onClick }) => {
  photos = photos.slice(0, 5)

  const renderPhoto = (index, props = {}) => (
    <Photo
      size="medium"
      {...props}
      cover
      photo={photos[index]}
      onClick={() => {
        if (onClick) onClick(index)
      }}
    />
  )
  return (
    <Media
      query="(max-width: 1180px)"
      match={() => (
        <div className="property_hero_grid">
          <div className="property_hero_grid_left">
            {renderPhoto(0, { size: 'large' })}
          </div>
          <div className="property_hero_grid_right">
            {renderPhoto(1)}
            {renderPhoto(2)}
          </div>
        </div>
      )}
      unmatch={() => (
        <div className={classnames('property_hero_grid', {
          'property_hero_grid--4': photos.length > 4,
        })}>
          <div className="property_hero_grid_left">
            {renderPhoto(0, { size: 'large' })}
          </div>
          <div className="property_hero_grid_right">
            {renderPhoto(1)}
            {renderPhoto(2)}
            {photos.length > 4 && renderPhoto(3)}
            {photos.length > 4 && renderPhoto(4)}
          </div>
        </div>
      )}
    />
  )
}

HeroGrid.propTypes = propTypes

export default HeroGrid
