import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { getAvatarUrl } from '@/shared/utils/photos'
import { formatDate } from '@/shared/utils/dateTime'
import { to404 } from '@/shared/utils/404'
import { fetchUser, fetchUserProperties } from '@/profile/api'
import { PageSpinner, Button, Avatar, Head, Icon,
  VerifiedMark, Media, PropertiesList } from '@/shared/components'
import MessageModal from '@/features/messageModal/components'
import '@/profile/styles/profileShow.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
  closeModal: PropTypes.func,
  isPage: PropTypes.bool,
}

const c = 'profileShow'

const ProfileShow = (props) => {
  const { userId } = props
  const location = useLocation()

  const [user, setUser] = useState(null)

  useEffect(() => {
    fetchUser(userId).then(({ data }) => {
      if (props.isPage) {
        analytics.pageview('User Profile', {
          userId, isHost: data.isHost,
        })
      } else {
        analytics.track('User Profile Modal Opened', {
          userId, isHost: data.isHost,
        })
      }
      setUser(data)
    }, () => {
      to404('We could not find this user.')
    })
  }, [])

  const isUnrestricted = () => (
    _.get(props.currentUser, 'unrestricted') ||
    _.get(props.currentUser, 'id') === user.id
  )

  return (
    <div className="profileShow_container">
      {!!user ? (
        <Fragment>
          <Head
            title={`${user.firstName}'s profile - Roomsie`}
            description={user.bio}
            url={location.pathname}
            image={user.avatar ? getAvatarUrl(user.avatar.imageUrl, 'large') : undefined}
          />
          <Media query="(min-width: 768px)" match={() => (
            <div className={`${c}_avatarBox`}>
              <UserAvatar user={user} isUnrestricted={isUnrestricted} />
              <UserMessageModal 
                currentUser={props.currentUser} 
                user={user} 
                isUnrestricted={isUnrestricted}  
              />
            </div>
          )} />
          <div className={`${c}_right`}>
            <Media query="(max-width: 767px)" match={() => (
              <MobileTop 
                currentUser={props.currentUser} 
                user={user} 
                isUnrestricted={isUnrestricted} 
              />
            )} />
            <UserContent
              user={user} 
              isUnrestricted={isUnrestricted} 
              location={location}
              closeModal={props.closeModal}
            />
            {!!user.interests.length && <UserInterests user={user} />}
            {user.isHost && (
              <PropertiesList
                title={`${user.firstName}'s Properties`}
                fetchProperties={after => {
                  fetchUserProperties(user.id).then(({ data }) => after(data), () => {})
                }}
              />
            )}
          </div>
        </Fragment>
      ) : (
        <PageSpinner padding={40} />
      )}
    </div>
  )
}

const UserContent = ({ user, isUnrestricted, location, closeModal }) => (
  <div className={`${c}_userContent`}>
    <Media query="(min-width: 768px)" match={() => (
      <Fragment>
        <h1>{user.firstName}</h1>
        <UserCreatedAt user={user} />
        <UserGenderLocation user={user} />
      </Fragment>
    )} />
    {user.verifiedHost && (
      <div className={`${c}_verified`}>
        <VerifiedMark />
        <p>{`${user.firstName} has earned the status of verified host. Learn more about how Roomsie protects guests:`}</p>
        <a
          className={`${c}_readMoreLink`}
          href="https://roomsie.zendesk.com/hc/en-us/articles/360008126114-What-does-it-mean-to-be-a-verified-host-"
          target="_blank"
        >
          How we verify hosts
        </a>
      </div>
    )}
    {!user.isHost && (user.instagramUrl || user.facebookUrl) && (
      <div className={`${c}_socialUrls`}>
        {user.instagramUrl && (
          <a href={user.instagramUrl} target="_blank">
            <Icon type="instagram-circle" />
            Instagram
          </a>
        )}
        {user.facebookUrl && (
          <a href={user.facebookUrl} target="_blank">
            <Icon type="facebook-circle" />
            Facebook
          </a>
        )}
      </div>
    )}
    {location.state && location.state.completeBookingCta && !isUnrestricted() && closeModal && (
      <div className={`${c}_completeBookingCta`}>
        <span>{`Unlock ${user.firstName}'s full profile`}</span>
        <Button hollow size="small" onClick={closeModal}>
          Complete Your Reservation
        </Button>
      </div>
    )}
    <div className={`${c}_moreInfo`}>
      {(user.school || user.schoolYear || user.major || user.internshipEmployer) && (
        <div className={`${c}_education`}>
          {user.school && (
            <div><span>School/University</span>{user.school}</div>
          )}
          {user.schoolYear && (
            <div><span>School Year</span>{user.schoolYear}</div>
          )}
          {user.major && (
            <div><span>Area of Study</span>{user.major}</div>
          )}
          {user.internshipEmployer && (
            <div><span>Internship Employer</span>{user.internshipEmployer}</div>
          )}
        </div>
      )}
      {user.bio && (
        <div className={`${c}_bio`}>
          <div><span>About</span>{user.bio}</div>
        </div>
      )}
    </div>
  </div>
)

const MobileTop = ({ currentUser, user, isUnrestricted }) => (
  <div className={`${c}_mobileTop`}>
    <div className={`${c}_mobileTop_avatarName`}>
      <UserAvatar user={user} isUnrestricted={isUnrestricted} />
      <h1>{user.firstName}</h1>
    </div>
    <UserGenderLocation user={user} />
    <UserCreatedAt user={user} />
    <UserMessageModal 
      currentUser={currentUser} 
      user={user} 
      isUnrestricted={isUnrestricted}  
    />
  </div>
)

const UserAvatar = ({ user, isUnrestricted }) => (
  <div className={`${c}_avatarCont`}>
    {isUnrestricted() || user.isHost ? (
      <Avatar {...user} size={130} fullWidth withoutLink />
    ) : (
      <div className={`${c}_avatarRestricted`}>
        <div><Icon type="lock" /></div>
      </div>
    )}
  </div>
)

const UserMessageModal = ({ currentUser, user, isUnrestricted }) => (
  (currentUser && (isUnrestricted() || user.isHost)) && (
    <MessageModal
      description="Make sure to be polite and respectful when communicating with others."
      recipient={user}
    />
  )
)

const UserCreatedAt = ({ user }) => (
  <div className={`${c}_createdAt`}>
    {`Member since ${formatDate(user.createdAt, 'monthYear')}`}
  </div>
)

const UserGenderLocation = ({ user }) => (
  <div className={`${c}_ageGenderLocation`}>
    {`${user.age} years old, ${user.gender}`}
    {(user.city || user.state) && (
      `, from ${[user.city, user.state].filter(p => p).join(', ')}`
    )}
  </div>
)

const UserInterests = ({ user }) => (
  <div className={`${c}_interests`}>
    <h2>Interests</h2>
    <div className={`${c}_interests_list`}>
      {user.interests.map(interest => (
        <div key={interest} className={`${c}_interests_item`}>
          {options.labels.interests[interest]}
        </div>
      ))}
    </div>
  </div>
)

ProfileShow.propTypes = propTypes

export default connect(mapStateToProps)(ProfileShow)
