import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import DefaultPhotoPath from '@/shared/assets/images/default.jpg'

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.number,
  url: PropTypes.string,
  image: PropTypes.string,
  imagePath: PropTypes.string,
  noindex: PropTypes.bool,
  twitterCard: PropTypes.string,
  children: PropTypes.node,
}

const removeTag = (attr, value) => {
  const $tag = document.querySelector(`meta[${attr}="${value}"]`)
  if($tag != null){
    $tag.parentNode.removeChild($tag)
  }
}
const removeDefaultTags = () => {
  removeTag('name', 'description')
  removeTag('name', 'twitter:card')
  removeTag('name', 'twitter:site')
  removeTag('name', 'twitter:title')
  removeTag('name', 'twitter:description')
  removeTag('name', 'twitter:image')
  removeTag('property', 'og:title')
  removeTag('property', 'og:type')
  removeTag('property', 'og:image')
  removeTag('property', 'og:description')
  removeTag('property', 'og:site_name')
}
let areDefaultTagsRemoved = false

const rootUrl = window.ROOT_URL.replace(/\/$/, '')

const Head = ({
  title = `Affordable housing for interns, students & grads in Washington DC - Roomsie`,
  description = 'Budget friendly, furnished housing for interns, students & recent graduates. Find your housing, connect with your roommates, start your adventure.',
  status = 200,
  url,
  image,
  imagePath,
  noindex,
  twitterCard = 'summary_large_image',
  children,
}) => {
  if (!areDefaultTagsRemoved) {
    areDefaultTagsRemoved = true
    removeDefaultTags()
  }
  title = _.truncate(title, { length: 100 })
  description = _.truncate(description, { length: 200 })

  if (!image && !imagePath) {
    image = rootUrl + DefaultPhotoPath
  } else if (imagePath) {
    image = rootUrl + imagePath
  }
  return (
    <Helmet>
      <title>{title}</title>
      {!_.isUndefined(url) && <link rel="canonical" href={rootUrl + url} />}
      {description && <meta name="description" content={description} />}

      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content="@roomsiedc" />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      <meta name="twitter:image" content={image} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      {!_.isUndefined(url) && <meta property="og:url" content={rootUrl + url} />}
      <meta property="og:image" content={image} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:site_name" content="Roomsie" />
      <meta name="prerender-status-code" content={status} />
      {noindex && <meta name="robots" content="noindex" />}

      {children}
    </Helmet>
  )
}

Head.propTypes = propTypes

export default Head
