import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import { addToQueryString } from '@/shared/utils/url'
import { getTextFromHtml } from '@/shared/utils/html'
import * as content from '@/landings/static'
import { Head, Button, Icon, Media, Slider } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import SearchForm from './SearchForm'
import Guides from './Guides'
import '@/landings/styles/template.scss'

const propTypes = {
  type: PropTypes.string.isRequired,
}

class Template extends Component {

  constructor(props) {
    super(props)
    this.content = content[props.type]
  }

  componentDidMount() {
    analytics.pageview('Landings Parents')
  }

  renderHero = (c = 'landingsTemplate_hero') => (
    <Fragment>
      <div className={c} style={{ backgroundImage: `url(${this.content.heroImage})` }}>
        <div className={`${c}_gradient`}>
          <div className={`${c}_inner`}>
            <div className={`${c}_subtitle`}>{this.content.heroSubtitle}</div>
            <div
              className={`${c}_title`}
              dangerouslySetInnerHTML={{ __html: this.content.heroTitle }}
            />
            <Link to={this.props.type === 'students' ? '/list-space' : '/search'}>
              <Button size="large" className={`${c}_ctaMobile`}>
                {this.props.type === 'students' ? 'List Your Space' : 'Find Rooms'}
              </Button>
            </Link>
          </div>
          <Media query="(max-width: 767px)" unmatch={this.renderHeroTestimonial} />
          {this.props.type === 'students' ? (
            <div className={`${c}_listSpace`}>
              <span>{"It's easy to get started"}</span>
              <Link to="/list-space">
                <Button size="large">List Your Space</Button>
              </Link>
            </div>
          ) : (
            <SearchForm isTop templateType={this.props.type} />
          )}
        </div>
      </div>
      <Media query="(max-width: 767px)" match={this.renderHeroTestimonial} />
    </Fragment>
  )

  renderHeroTestimonial = (c = 'landingsTemplate_hero') => (
    <div className={`${c}_testimonial`}>
      <p>{this.content.topTestimonial}</p>
      <div className={`${c}_testimonial_name`}>
        {this.content.topTestimonialName}
        <span>|</span>
        {this.content.topTestimonialTitle}
      </div>
    </div>
  )

  renderFeatures = (c = 'landingsTemplate_features') => (
    <div className={c}>
      <div className={`${c}_title`} dangerouslySetInnerHTML={{ __html: this.content.featuresTitle }} />
      <p className={`${c}_subtitle`} dangerouslySetInnerHTML={{ __html: this.content.featuresSubtitle }} />
      <div className={`${c}_items`}>
        {this.content.features.map(feature => (
          <div key={feature.title} className={`${c}_item`}>
            <div className={`${c}_item_iconContainer`}>
              <Icon {...feature.icon} />
            </div>
            <div className={`${c}_item_title`}>{feature.title}</div>
            <p className={`${c}_item_message`}>{feature.message}</p>
          </div>
        ))}
      </div>
    </div>
  )

  renderTestimonials = (c = 'landingsTemplate_testimonials') => (
    <div className={c}>
      <h2 className={`${c}_title`}>{this.content.testimonialsTitle}</h2>
      <div className={`${c}_content`}>
        <Media query="(max-width: 767px)"
          match={() => (
            <Slider largeDots>
              {this.content.testimonials.map(item => this.renderTestimonial(item, c))}
            </Slider>
          )}
          unmatch={() => this.content.testimonials.map(item => this.renderTestimonial(item, c))}
        />
      </div>
    </div>
  )

  renderTestimonial = (item, c) => (
    <div key={item.name} className={`${c}_testimonial`}>
      <img src={item.image} alt={item.name} />
      <p className={`${c}_testimonial_quote`}><span>{`“${item.quote}”`}</span></p>
      <div className={`${c}_testimonial_name`}>{item.name}</div>
      <div className={`${c}_testimonial_title`}>{item.title}</div>
    </div>
  )

  renderBottomCta = (c = 'landingsTemplate_bottomCta') => (
    <div className={c}>
      <h2 className={`${c}_title`}>{this.content.bottomCta}</h2>
      {this.props.type === 'students' ? (
        <Link to="/list-space">
          <Button size="large" className={`${c}_studentsCta`}>List Your Space</Button>
        </Link>
      ) : (
        <SearchForm />
      )}
    </div>
  )

  render() {
    return (
      <div className="landingsTemplate">
        <Head
          url={`/${this.props.type}`}
          title={getTextFromHtml(this.content.heroTitle).replace(/\s+/g, ' ') + ' - Roomsie'}
          description={getTextFromHtml(this.content.featuresSubtitle).replace(/\s+/g, ' ')}
          imagePath={this.content.heroImage}
        />
        <AppHeader />
        {this.renderHero()}
        {this.renderFeatures()}
        <Guides title={this.content.spotsTitle} subtitle={this.content.spotsSubtitle} />
        {this.renderTestimonials()}
        {this.renderBottomCta()}
        <AppFooter />
      </div>
    )
  }
}

Template.propTypes = propTypes

export default Template
