import React, { Component } from 'react'
import { loadScript } from '@/shared/utils/script'

export default Child => {

  class StripeClientLoader extends Component {

    constructor() {
      super()
      this.state = { stripe: window.Stripe }

      loadScript('https://js.stripe.com/v3/', 'stripeClient', () => {
        this.setState({ stripe: window.Stripe })
      })
    }

    render() {
      return <Child {...this.props} stripe={this.state.stripe} />
    }
  }

  return StripeClientLoader
}
