import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as url from '@/shared/utils/url'

const OauthWindow = () => {
  const location = useLocation()

  useEffect(() => {
    const { search } = location
    const code = url.queryStringToObject(search).code
    if (window.opener) window.opener.onOauthRedirect(code)
    window.close()
  }, [])
  
  return null 
}

export default OauthWindow
