import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@/shared/components'
import '@/shared/styles/switch.scss'

const propTypes = {
  linkText: PropTypes.string.isRequired,
  shouldShowLink: PropTypes.func,
  renderDefault: PropTypes.func,
  renderSwitch: PropTypes.func.isRequired,
}

const defaultProps = {
  shouldShowLink: () => true,
  renderDefault: () => null,
}

class Switch extends Component {

  state = { isSwitched: false }

  render() {
    return !this.state.isSwitched ? (
      <Fragment>
        {this.props.renderDefault()}
        {this.props.shouldShowLink() && (
          <div
            className="switch_link"
            onClick={() => this.setState({ isSwitched: true })}
          >
            {this.props.linkText}
            <Icon type="chevron-down-fat" />
          </div>
        )}
      </Fragment>
    ) : (
      <Fragment>
        {this.props.renderSwitch()}
        <div
          className="switch_link"
          onClick={() => this.setState({ isSwitched: false })}
        >
          Hide
          <Icon type="chevron-up-fat" />
        </div>
      </Fragment>
    )
  }
}

Switch.propTypes = propTypes
Switch.defaultProps = defaultProps

export default Switch
