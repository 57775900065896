import React from 'react'
import PropTypes from 'prop-types'
import { Slider } from '@/shared/components'
import '@/landings/styles/howItWorks.scss'

const propTypes = {
  items: PropTypes.array.isRequired,
}

const c = 'landings_howItWorks'

const HowItWorks = ({ items }) => (
  <div className={c}>
    <div className={`${c}_inner`}>
      <h2 className={`${c}_title`}>
        How it works
      </h2>
      <Slider largeDots>
        {items.map((item, i) => (
          <div key={i} className={`${c}_item`}>
            <img src={item.image} alt={item.title} />
            <div className={`${c}_item_right`}>
              <div className={`${c}_item_number`}>{i + 1}</div>
              <div className={`${c}_item_title`}>{item.title}</div>
              <p className={`${c}_item_description`}>{item.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
)

HowItWorks.propTypes = propTypes

export default HowItWorks
