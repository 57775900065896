import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import spots, { spotsCategories } from '@/shared/static/spots'
import { addToQueryString } from '@/shared/utils/url'
import { Button } from '@/shared/components'
import imageMap from '@/landings/assets/images/general/map.jpg'
import '@/landings/styles/guides.scss'

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

const defaultProps = {
  title: "Where will you </br> intern or study?",
  subtitle: "Choose one of our guides to help you navigate nearby neighborhoods.",
}

const c = 'landingsGuides'

const Guides = (props) => {
  const { title, subtitle } = props
  let location = useLocation()

  const [areGuidesOpen, setAreGuidesOpen] = useState(false)

  return (
    <div className={`${c} ${areGuidesOpen ? `${c}--guidesOpen` : ''}`}>
      <div className={`${c}_hero`} style={{ backgroundImage: `url(${imageMap})` }}>
        <h2 className={`${c}_title`} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={`${c}_subtitle`}>
          {subtitle}
        </div>
        <Button size="large" onClick={() => setAreGuidesOpen(true)}>
          View Guides
        </Button>
      </div>
      {areGuidesOpen && (
        <div className={`${c}_categories_outer`}>
          <div className={`${c}_categories`}>
            <div className={`${c}_categories_inner`}>
              {spotsCategories.map((category, i) => (
                <div key={i} className={classnames(`${c}_category`)}>
                  <div
                    className={`${c}_category_title`}
                  >
                    {category}
                  </div>
                  {spots.filter(s => s.category === i).map(spot => (
                    <Link
                      key={spot.title}
                      to={{
                        pathname: '/search',
                        search: addToQueryString(location.search, {
                          locationText: spot.title,
                          spot: spot.title,
                          centerLat: spot.center.lat,
                          centerLng: spot.center.lng,
                          zoom: 13,
                        }),
                      }}
                    >
                      <div className={`${c}_spot`}>{spot.title}</div>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
            <div className={`${c}_categories_buttonContainer`}>
              <Button size="large" onClick={() => setAreGuidesOpen(false)}>
                Hide Guides
              </Button> 
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Guides.propTypes = propTypes
Guides.defaultProps = defaultProps

export default Guides
