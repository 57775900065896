import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import * as api from '@/features/shareProperty/api'
import { sortPhotos } from '@/shared/utils/photos'
import { showToast } from '@/shared/utils/toast'
import { Form, Field, Button, Photo, Icon } from '@/shared/components'

const propTypes = {
  property: PropTypes.object.isRequired,
  activeSection: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

const c = 'shareProperty_email'

class Email extends Component {

  render() {
    return (
      <div className={`${c}_inner`}>
        <Form
          initialValues={{
            recipients: '',
            message: '',
          }}
          validations={{
            recipients: Form.is.required(),
          }}
          onSubmit={(values, form) => {
            api.sendEmail(this.props.property.id, {
              recipients: values.recipients.trim(),
              message: values.message.trim() || 'I hope you like it!',
            }).then(() => {
              this.props.closeModal()
              showToast({ title: 'Your message was sent successfully.' })
            }, ({ error }) => {
              if (error.details) {
                form.setErrors(error.details)
              } else {
                handleError({ error })
              }
              form.setSubmitting(false)
            })
          }}
          render={({ handleSubmit, isSubmitting, fields }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div
                className={`${c}_backLink`}
                onClick={() => this.props.changeSection('options')}
              >
                <Icon type="chevron-left-fat" />
                Back
              </div>
              <div className={`${c}_title`}>Share this property</div>
              <Field.Input
                {...fields.recipients}
                label="Send to"
                placeholder="jane@mail.com, mary@mail.com"
              />
              <div className={`${c}_description`}>
                {`Enter email addresses, separated by commas`}
              </div>
              <Field.Textarea
                {...fields.message}
                label="Personal message (optional)"
                minRows={4}
              />
              <Button type="submit" working={isSubmitting}>Send Email</Button>
            </form>
          )}
        />
        <Photo photo={sortPhotos(this.props.property.photos)[0]} size="medium" cover />
      </div>
    )
  }
}

Email.propTypes = propTypes

export default Email
