import _ from 'lodash'

export const syncRatesWithSessions = (sessions, property) => ({
  rates: sync(sessions, property.rates),
  bedrooms: _.cloneDeep(property.bedrooms).map(bedroom => ({
    ...bedroom,
    beds: bedroom.beds.map(bed => ({
      ...bed,
      rates: sync(sessions, bed.rates),
    })),
  })),
})

const sync = (sessions, rates) => (
  sessions.map(({ uid }) => {
    const rate = rates.find(r => r.sessionUid === uid) || {}
    return {
      ...rate,
      amount: rate.amount || '',
      sessionUid: uid,
    }
  })
)
