import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import { sortPhotos, getPhotoUrl, getPhotoDimensions } from '@/shared/utils/photos'
import * as device from '@/shared/utils/device'
import mediaQuery from '@/shared/utils/media'
import { Icon } from '@/shared/components'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import '@/property/styles/lightbox.scss'

const propTypes = {
  photos: PropTypes.array.isRequired,
  getOpenFunc: PropTypes.func.isRequired,
}

class Lightbox extends Component {

  componentDidMount() {
    this.props.getOpenFunc(this.openLightbox)
  }

  componentWillUnmount() {
    if (this.photoswipe && this.photoswipe.close) {
      this.photoswipe.close()
    }
  }

  openLightbox = (index = 0) => {
    const size = mediaQuery('(max-width: 768px)') ? 'medium' : 'large'

    const photos = sortPhotos(this.props.photos).map(photo => ({
      src: getPhotoUrl(photo.imageUrl, size),
      ...getPhotoDimensions(photo, size),
    }))

    const options = {
      index,
      history: false,
      bgOpacity: 0.85,
      closeOnScroll: false,
      preload: [1, 2],
      shareEl: false,
      fullscreenEl: false,
      zoomEl: false,
      clickToCloseNonZoomable: false,
      timeToIdle: 9999999999,
      timeToIdleOutside: 9999999999,
    }
    this.photoswipe = new PhotoSwipe(this.$container, PhotoSwipeUI, photos, options)
    this.photoswipe.init()
  }

  render() {
    return (
      <span className={classnames('property_lightbox', {
        'property_lightbox--deviceIsTouch': device.isTouch(),
      })}>
        <div
          className="pswp"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          ref={el => this.$container = el}
        >
          <div className="pswp__bg"></div>
          <div className="pswp__scroll-wrap">
            <div className="pswp__container">
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
            </div>
            <div className="pswp__ui pswp__ui--hidden">
              <div className="pswp__top-bar">
                <div className="pswp__counter"></div>
                <button className="pswp__button pswp__button--close" title="Close (Esc)" />
                <div className="pswp__preloader">
                  <div className="pswp__preloader__icn">
                    <div className="pswp__preloader__cut">
                      <div className="pswp__preloader__donut"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="property_lightbox_arrow property_lightbox_arrow_prev"
                onClick={() => this.photoswipe.prev()}
              >
                <Icon type="chevron-left" />
              </div>
              <div
                className="property_lightbox_arrow property_lightbox_arrow_next"
                onClick={() => this.photoswipe.next()}
              >
                <Icon type="chevron-right" />
              </div>
              <div className="pswp__caption">
                <div className="pswp__caption__center"></div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

Lightbox.propTypes = propTypes

export default Lightbox
