import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { formatDate, stringToDate } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { Icon } from '@/shared/components'
import '@/guestBookings/styles/payments.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
}

const c = 'guestBooking'

class Payments extends Component {

  getValidSortedCharges = charges => (
    charges.filter(charge => /scheduled|paid|failed/.test(charge.status))
           .sort((a, b) => stringToDate(a.date) - stringToDate(b.date))
  )

  getTotalPaidAmount = charges => (
    charges.filter(charge => charge.status === 'paid')
           .reduce((sum, charge) => sum + charge.total, 0)
  )

  getTotalRefundedAmount = refunds => (
    refunds.reduce((sum, r) => sum + r.total, 0)
  )

  getChargeStatusText = charge => {
    if (charge.status === 'paid') {
      return 'Paid '
    } else if (charge.status === 'scheduled') {
      return 'Will be charged '
    } else if (charge.status === 'failed') {
      return 'Failed! Retry for '
    }
  }

  render() {
    const { charges, refunds } = this.props.booking
    return (
      <Fragment>
        <div className={`${c}_payments`}>
          <div className={`${c}_payments_title`}>
            Charge Transactions
          </div>
          {this.getValidSortedCharges(charges).map((charge, i) => (
            <div key={i} className={`${c}_payments_item`}>
              {`${i + 1}. ${this.getChargeStatusText(charge)} `}
              <span>{formatPrice(charge.total, { precise: true })}</span>
              {` on ${formatDate(charge.date)}`}
            </div>
          ))}
          <div className={`${c}_payments_total`}>
            Total Paid
            <span>{formatPrice(this.getTotalPaidAmount(charges), { precise: true })}</span>
          </div>
        </div>
        {!!refunds.length && (
          <div className={`${c}_refunds`}>
            <div className={`${c}_refunds_title`}>
              Refund Transactions
            </div>
            {refunds.map((refund, i) => (
              <div key={i} className={`${c}_refunds_item`}>
                {`${i + 1}. `}
                <span>{formatPrice(refund.total, { precise: true })}</span>
                {` on ${formatDate(refund.date)}`}
                <div className={`${c}_refunds_item_description`}>
                  {refund.description}
                </div>
              </div>
            ))}
            <div className={`${c}_refunds_total`}>
              Total Refunded So Far
              <span>{formatPrice(this.getTotalRefundedAmount(refunds), { precise: true })}</span>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

Payments.propTypes = propTypes

export default Payments
