import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import * as url from '@/shared/utils/url'

export default function useProfileModalUrl() {
  const location = useLocation()
  const navigate = useNavigate()

  const toOpen = (id) => navigate({
    pathname: location.pathname,
    search: url.addToQueryString(location.search, { profile_modal: id })
  })
  
  const toClose = key => navigate({
    pathname: location.pathname,
    search: url.omitFromQueryString(location.search, 'profile_modal')
  })
  
  const isOpen = key => (
    !!url.queryStringToObject(location.search).profile_modal
  )
  
  return { 
    toOpen,
    toClose,
    isOpen
  }
}