import _ from 'lodash'

export const apiToFormState = property => {
  const idToUid = item => ({
    ...item,
    uid: item.id,
  })
  const rateIdToUid = rate => ({
    ...idToUid(rate),
    sessionUid: rate.sessionId,
  })
  return {
    ...property,
    photos: property.photos.map(idToUid),
    sessions: property.sessions.map(idToUid),
    blackoutDates: property.blackoutDates.map(idToUid),
    bedrooms: (property.bedrooms || []).map(bedroom => ({
      ...idToUid(bedroom),
      photos: bedroom.photos.map(idToUid),
      beds: bedroom.beds.map(bed => ({
        ...idToUid(bed),
        rates: bed.rates.map(rateIdToUid),
      })),
    })),
    rates: (property.rates || []).map(rateIdToUid),
  }
}

export const formStateToApi = property => {
  const isEntireSpace = () => (
    property.listingType === 'entire_space'
  )
  const ratesToApi = rates => rates.map(rate => ({
    ...rate,
    amount: +rate.amount,
    sessionKey: rate.sessionUid,
  }))
  const photosToApi = photos => photos.map(photo => (
    _.omit(photo, ['base64Preview'])
  ))
  return {
    ...property,
    photos: photosToApi(property.photos),
    sessions: property.sessions.map(s => ({ ...s, key: s.uid })),
    bedrooms: isEntireSpace() ? [] : property.bedrooms.map(bedroom => ({
      ...bedroom,
      photos: photosToApi(bedroom.photos),
      beds: bedroom.beds.map(bed => ({
        ...bed,
        rates: ratesToApi(bed.rates),
      }))
    })),
    rates: !isEntireSpace() ? [] : ratesToApi(property.rates),
    fees: property.fees.map(fee => ({ ...fee, amount: +fee.amount })),
  }
}
