import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { FieldLabel, FieldErrors } from './'
import * as components from './'

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
  ]),
}

const fieldComponent = type => ({
  className,
  label,
  errors,
  ...componentProps
}) => {
  const Component = components[type]
  return (
    <div className={classnames(
      'field',
      `field_${_.lowerFirst(type)}`,
      className
    )}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <Component {...componentProps} invalid={!!errors} />
      {(_.isArray(errors) || _.isString(errors)) && (
        <FieldErrors errors={errors} />
      )}
    </div>
  )
}

const Field = {
  Input: fieldComponent('Input'),
  Select: fieldComponent('Select'),
  Textarea: fieldComponent('Textarea'),
  TextEditor: fieldComponent('TextEditor'),
  DatePicker: fieldComponent('DatePicker'),
  DateRangePicker: fieldComponent('DateRangePicker'),
  LocationSearch: fieldComponent('LocationSearch'),
}

Field.Input.propTypes = propTypes
Field.Select.propTypes = propTypes
Field.Textarea.propTypes = propTypes
Field.TextEditor.propTypes = propTypes
Field.DatePicker.propTypes = propTypes
Field.DateRangePicker.propTypes = propTypes
Field.LocationSearch.propTypes = propTypes

export default Field
