import PropertyBasics from '@/listSpace/components/PropertyBasics'
import Location from '@/listSpace/components/Location'
import AmenitiesRules from '@/listSpace/components/AmenitiesRules'
import Photos from '@/listSpace/components/Photos'
import DatesAvailability from '@/listSpace/components/DatesAvailability'
import RoomsRates from '@/listSpace/components/roomsRates'
import Fees from '@/listSpace/components/Fees'
import Review from '@/listSpace/components/Review'

export const steps = [
  {
    id: 'basics',
    path: '',
    label: 'Basics',
    labelLong: 'Property Basics',
    Component: PropertyBasics,
  }, {
    id: 'location',
    path: '/location',
    label: 'Location',
    labelLong: 'Property Location',
    Component: Location,
  }, {
    id: 'amenitiesRules',
    path: '/amenities-rules',
    label: 'Amenities & Rules',
    labelLong: 'Amenities & House Rules',
    Component: AmenitiesRules,
  }, {
    id: 'photos',
    path: '/photos',
    label: 'Photos',
    labelLong: 'Property Photos',
    Component: Photos,
  }, {
    id: 'availability',
    path: '/dates-availability',
    label: 'Availability',
    labelLong: 'Dates of Availability',
    Component: DatesAvailability,
  }, {
    id: 'ratesRooms',
    path: '/rooms-rates',
    label: 'Rates & Rooms',
    labelLong: 'Rates & Rooms',
    Component: RoomsRates,
  }, {
    id: 'fees',
    path: '/fees',
    label: 'Fees',
    labelLong: 'Additional Fees',
    Component: Fees,
  }, {
    id: 'review',
    path: '/review',
    label: 'Review',
    labelLong: 'Review',
    Component: Review,
  },
]
