import React, { useState } from 'react'
import { useLocation, useResolvedPath, useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import spots from '@/shared/static/spots'
import * as url from '@/shared/utils/url'
import { Switch, Icon, Head } from '@/shared/components'
import '@/search/styles/spot.scss'

const c = 'search_results_spot'

const Spot = () => {
  const location = useLocation()
  const matchUrl = useResolvedPath("").pathname

  const [searchParams, setSearchParams] = useSearchParams()
  const [spot, setSpot] = useState(() => 
    spots.find(({ title }) => (
      title === url.queryStringToObject(location.search).spot
    ))
  )

  const handleSpotClose = () => {
    if (!searchParams.has('spot')) return

    searchParams.delete('spot')
    setSearchParams(searchParams)
    setSpot(null)
  }

  return !!spot && (
    <div className={`${c}_container`}>
      <Head
        title={`Housing Near ${spot.title} in Washington DC ${new Date().getFullYear()} - Roomsie`}
        description={spot.description}
        url={matchUrl}
        imagePath={spot.imageUrl}
      />
      <div className={`${c}`}>
        <Icon type="close" onClick={handleSpotClose} />
        <div className={`${c}_inner`}>
          <div
            className={`${c}_image`}
            style={{ backgroundImage: `url(${spot.imageUrl})` }}
          />
          <div className={`${c}_info`}>
            <h1 className={`${c}_title`}>{spot.title}</h1>
            <div className={`${c}_address`}>{spot.address}</div>
            {spot.transport && (
              <p className={`${c}_transport`}>
                <span>{`Public transport options: `}</span>
                {spot.transport}
              </p>
            )}
            {spot.neighborhoods && (
              <p className={`${c}_neighborhoods`}>
                <span>{`Suggested neighborhoods: `}</span>
                {spot.neighborhoods}
              </p>
            )}
          </div>
        </div>
        <div className={`${c}_description`}>
          <Switch
            linkText="Read More"
            shouldShowLink={() => spot.description.length > 340}
            renderDefault={() => (
              <span>{_.truncate(spot.description, { length: 340 })}</span>
            )}
            renderSwitch={() => (
              <span className={`${c}_formatted`}>{spot.description}</span>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default Spot
