import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import * as analytics from '@/shared/utils/analytics'
import { Head, Button, Icon, Input } from '@/shared/components'
import PrivateLink from '@/features/auth/components/PrivateLink'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import BoxGrid from './BoxGrid'
import HowItWorks from './HowItWorks'
import imageHero from '@/landings/assets/images/host/hero.jpg'
import imageListYourSpace from '@/landings/assets/images/host/list-your-space.png'
import imagePickYourGuests from '@/landings/assets/images/host/pick-your-guests.png'
import imageGetPaid from '@/landings/assets/images/host/get-paid.png'
import imageBookings from '@/landings/assets/images/host/bookings.jpg'
import '@/landings/styles/host.scss'

const Host = () => {
  const [info, setInfo] = useState({
    rate: 2500,
    openedQuestionIndex: 0,
    faq: [{
      question: 'How much can I rent my property for?',
      answer: "Roomsie rates are set by our hosts and will vary based upon a number of different factors including; bedroom occupancy, bed type, location, property type etc. However, hosts using Roomsie's suggested rental rates will see, on average, a 30% monthly increase in income. Combining this increased income with the infrequency of turnover will boosts your R.O.I.",
    }, {
      question: 'How does Roomsie process monthly rental payments?',
      answer: "Roomsie collects all rental fees up front and escrows them on behalf of our hosts.  Your first rental payment will be pro-rated for the days remaining in the month and will be disbursed 48 hours after your guest has moved in (assuming no issues have been reported). All future payments will be disbursed on the 1st of each subsequent month.",
    }, {
      question: 'What are the service fees?',
      answer: "To help with the cost of maintaining the Roomsie platform, the host will be charged a 7.5% Roomsie service fee. This fee includes all credit card processing as well as transaction fees.",
    }, {
      question: 'Who takes care of utilities?',
      answer: "Similar to other home sharing websites, all rentals and rates listed on the Roomsie platform should include utilities as provided by the host.",
    }, {
      question: 'How many booking requests will I receive?',
      answer: "According to the Department of Labor, nearly 60,000 interns visit Washington, D.C. each year.  The vast majority of those will be searching for housing. There is no way that Roomsie can guarantee a number of booking requests.  Following our recommended best practices increase your odds of creating a successful intern and student housing rental.",
    }, {
      question: 'Will my address be made public?',
      answer: "Street Addresses will ONLY be made public to those who have a paid, confirmed reservation. Roomsie will display city, state, neighborhood, distance to the closest metro station and an 'approximate' map location.",
    }, {
      question: 'How do security deposits work?',
      answer: "As a Roomsie host, you set a security deposit that works for you.  This amount is escrowed and held by Roomsie until the stay is complete. You have the authority to make claims against this deposit until finalizing and reviewing the stay in your dashboard.",
    }],
  })

  useEffect(() => {
    analytics.pageview('Landings Host')
  }, [])

  const handleRateChange = (rate) => {
    setInfo({ ...info, rate: rate.replace('$', '')})
  }

  const handleQuestionOnClick = (i) => {
    setInfo({ ...info, openedQuestionIndex: i })
  }

  return (
    <div className="landingsHost">
      <Head
        url="/hosts"
        title="Rent Out Your House, Apartment or Room on Roomsie"
        description="Whether you're a seasoned property manager or just want to earn some extra money by renting a room, you're in the right place. Get started today, it’s free to create a listing."
      />
      <AppHeader />
      <Hero />
      <Rent info={info} onChange={handleRateChange} />
      <MainFeatures />
      <HowTo />
      <Questions />
      <Faq info={info} onClick={handleQuestionOnClick} />
      <CallToAction />
      <AppFooter />
    </div>
  )
}

const Hero = ({ c = 'landingsHost_hero' }) => (
  <div className={c} style={{ backgroundImage: `url(${imageHero})` }}>
    <div className={`${c}_inner`}>
      <div className={`${c}_top`}>
        <p>{"I'm no longer having to turn over my property every three days. The savings have been huge and so have the returns on my investment."}</p>
        <div className={`${c}_top_name`}>
          Mariah Anderson <span>|</span> Roomsie Host
        </div>
      </div>
      <div className={`${c}_form`}>
        <div className={`${c}_form_text`}>
          Lower turnover and higher rent. <span>What's not to love?</span>
        </div>
        <PrivateLink to="/list-space">
          <Button size="large">List Your Space</Button>
        </PrivateLink>
      </div>
    </div>
  </div>
)

const Rent = ({ c = 'landingsHost_rent', info, onChange }) => (
  <div className={c}>
    <div className={`${c}_line`}>
      {"I currently rent my property for "}
      <Input
        line
        filter={/^\$[0-9]{0,5}$/}
        type="tel"
        value={`$${info.rate}`}
        onChange={(e, rate) => onChange(rate)}
      />
      {" per month"}
    </div>
    <div className={`${c}_line`}>
      {"with Roomsie, you could be bringing in"}
    </div>
    <div className={`${c}_amount`}>
      <div className={`${c}_amount_item`}>
        <span>$</span>
      </div>
      {`${Math.round(+(info.rate || 0) * 1.34)}`.split('').map((letter, i) => (
        <div key={i} className={`${c}_amount_item`}>
          <span>{letter}</span>
        </div>
      ))}
      <div className={`${c}_amount_label`}>per <br/> month</div>
    </div>
  </div>
)

const MainFeatures = () => (
  <BoxGrid
    title="Why list your property on Roomsie?"
    items={[{
      icon: 'spyglass',
      title: <span>Screened & Verified<br />Tenants</span>,
      description: "View tenants personal bio's and profiles to decide if they're the right fit for your listing. Roomsie makes it super simple to find your ideal guest.",
    }, {
      icon: 'rental-guarantee',
      title: <span>Rental Default <br/> Guarantee</span>,
      description: "Our payment policies guarantee your rents. Roomsie covers you in the event of a default or rent deficiency from a guest booking through our platform.",
    }, {
      icon: 'paperwork',
      title: <span>Agreements & Documentation</span>,
      description: "Say goodbye to messy paperwork. All leases, terms, and conditions are processed digitally and automatically through our platform.",
    }, {
      icon: 'tools-hollow',
      title: <span>Manage your Property <br /> with Roomsie Tools</span>,
      description: "Your host dashboard provides insightful metrics that illustrate what's going on with your Roomsie portfolio.",
    }]}
  />
)

const HowTo = () => (
  <HowItWorks
    items={[{
      image: imageListYourSpace,
      title: <span>List your space</span>,
      description: "Listing your property on Roomsie is simple and free. Describe your space, enter your preferred guest gender, add photos and house rules. You have complete freedom to set your own pricing. Set different rates for different parts of the year, add additional fees like parking, pets, and more.",
    }, {
      image: imagePickYourGuests,
      title: <span>Pick your guests</span>,
      description: "After listing your space just sit tight and wait for booking requests to start rolling in. Upon receiving a booking request, you can check out guests profile page and chat with them to make sure they're the right fit for your property.",
    }, {
      image: imageGetPaid,
      title: <span>Get paid</span>,
      description: "Roomsie's secure booking system means you never have to deal with contracts and money directly. We handle all that automatically for you. Guests are charged as soon as you accept their booking request and you are paid out on a monthly basis after they check in.",
    }]}
  />
)

const Questions = ({ c = 'landingsHost_questions' }) => (
  <div className={c}>
    <img src={imageBookings} />
    <h2 className={`${c}_title`}>
      Have a question? <br/> We can help.
    </h2>
  </div>
)

const Faq = ({ c = 'landingsHost_faq', info, onClick }) => (
  <div className={c}>
    <div className={`${c}_inner`}>
      <div className={`${c}_title`}>
        Question
      </div>
      <div className={`${c}_items`}>
        {info.faq.map(({ question, answer }, i) => (
          <div
            key={question}
            className={classnames(`${c}_item`, {
              [`${c}_item--isOpen`]: info.openedQuestionIndex === i,
            })}
            onClick={() => onClick(i)}
          >
            <h3 className={`${c}_item_question`}>{question}</h3>
            {info.openedQuestionIndex === i && (
              <p className={`${c}_item_answer`}>{answer}</p>
            )}
          </div>
        ))}
      </div>
      <div className={`${c}_answer_container`}>
        <div className={`${c}_title`}>
          Answer
        </div>
        {info.faq[info.openedQuestionIndex] && (
          <p className={`${c}_answer_container_text`}>
            {info.faq[info.openedQuestionIndex].answer}
          </p>
        )}
      </div>
    </div>
  </div>
)

const CallToAction = ({ c = 'landingsHost_cta' }) => (
  <div className={c}>
    <div className={`${c}_text`}>
      {"It's easy to get started"}
    </div>
    <PrivateLink to="/list-space">
      <Button size="large">List Your Space</Button>
    </PrivateLink>
  </div>
)

export default Host
