import { combineReducers } from 'redux'

import auth from '@/features/auth/state/featureReducer.js'
import search from '@/search/state/moduleReducer'

const rootReducer = combineReducers({
  auth,
  search,
})

export default rootReducer
