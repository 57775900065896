import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import * as api from '@/hostManagement/api'
import * as analytics from '@/shared/utils/analytics'
import { formatDate, formatDateRange, dateToString, stringToDate } from '@/shared/utils/dateTime'
import * as utils from '@/hostManagement/utils'
import queryManager from '@/shared/hocs/queryManager'
import { DashboardMenu, Head, Button, Select, Icon, Spinner } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import Month from './Month'
import Week from './Week'
import Day from './Day'
import '@/hostManagement/styles/index.scss'

const propTypes = {
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
}

const c = 'hostManagement'

class HostManagement extends Component {

  state = {
    bookings: [],
    properties: {},
    isFetching: true,
    selectedDay: null,
  }

  componentDidMount() {
    analytics.pageview('Host Arrival/Departure Calendar')
    this.fetchBookings()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.query.mode !== this.props.query.mode ||
      prevProps.query.rangeStart !== this.props.query.rangeStart
    ) {
      this.fetchBookings()
    }
  }

  fetchBookings = () => {
    this.setState({ isFetching: true })
    api.fetchBookings({
      startDate: dateToString(this.getRange().startDate),
      endDate: dateToString(this.getRange().endDate),
    }).then(({ data }) => {
      const { bookings, properties } = utils.apiToClient(data)
      this.setState({
        bookings,
        properties: { ...this.state.properties, ...properties },
        isFetching: false,
      })
    }, handleError)
  }

  getRange = () => ({
    startDate: this.props.query.rangeStart,
    endDate: moment(this.props.query.rangeStart).endOf(this.props.query.mode === 'week' ? 'isoWeek' : 'month'),
  })

  changeRange = subtractOrAdd => {
    const rangeStart = moment(this.props.query.rangeStart)[subtractOrAdd](1, this.props.query.mode)
    this.props.updateQuery({ rangeStart })
  }

  changeMode = mode => {
    const rangeStart = moment().startOf(mode === 'week' ? 'isoWeek' : 'month')
    this.setState({ bookings: [] })
    this.props.updateQuery({ mode, rangeStart })
  }

  filterByProperty = (e, propertyId) => {
    this.props.updateQuery({ propertyIdFilter: +propertyId })
  }

  selectDay = day => this.setState({ selectedDay: day })

  updateBookingsOnDayUnmount = updatedBookings => {
    const common = _.intersectionBy(updatedBookings, this.state.bookings, 'id')
    this.setState({ bookings: _.uniqBy([...common, ...this.state.bookings], 'id') })
  }

  renderRange = () => this.props.query.mode === 'week' ? (
    formatDateRange(this.getRange().startDate, this.getRange().endDate)
  ) : (
    formatDate(this.getRange().startDate, 'longMonthYear')
  )

  render() {
    const {
      bookings,
      properties,
      isFetching,
      selectedDay,
    } = this.state
    const {
      mode,
      rangeStart,
      propertyIdFilter,
    } = this.props.query
    const calendarProps = {
      rangeStart,
      bookings,
      properties,
      propertyIdFilter,
      onDaySelect: this.selectDay,
    }
    return (
      <div className={c}>
        <Head title="Host Management - Roomsie" />
        <AppHeader />
        <DashboardMenu role="host" />
        {selectedDay && (
          <Day
            date={selectedDay}
            calendarBookings={bookings}
            calendarProperties={properties}
            calendarRange={this.getRange()}
            propertyIdFilter={propertyIdFilter}
            onUnmountUpdateBookings={this.updateBookingsOnDayUnmount}
            onModalClose={() => this.selectDay(null)}
          />
        )}
        <div className={`${c}_top`}>
          <div className={`${c}_top_breadcrumbs`}>
            <Link to="/host/bookings">My Bookings</Link>
            {' / Guest Calendar'}
          </div>
          <h1>Guest Calendar</h1>
        </div>
        <div className={`${c}_controls`}>
          <div className={`${c}_controls_inner`}>
            <div className={`${c}_mode`}>
              <Button
                square
                size="small"
                hollow={mode !== 'week'}
                className={mode === 'week' ? `${c}_mode_button--isActive` : ''}
                onClick={() => this.changeMode('week')}
              >
                Week
              </Button>
              <Button
                square
                size="small"
                hollow={mode !== 'month'}
                className={mode === 'month' ? `${c}_mode_button--isActive` : ''}
                onClick={() => this.changeMode('month')}
              >
                Month
              </Button>
            </div>
            <Select
              size="small"
              options={[
                { label: 'All Properties', value: 0 },
                ..._.values(properties).map(p => ({
                  label: `${p.title}, ${p.streetAddress}, ${p.streetAddress2 || ''}`,
                  value: p.id,
                })),
              ]}
              value={propertyIdFilter}
              onChange={this.filterByProperty}
            />
            <div className={`${c}_range`}>
              <Icon type="chevron-left-fat" onClick={() => this.changeRange('subtract')} />
              <Icon type="chevron-right-fat" onClick={() => this.changeRange('add')} />
              <div className={`${c}_range_label`}>{this.renderRange()}</div>
            </div>
            {isFetching && <Spinner size={40} />}
          </div>
        </div>
        <div className={`${c}_calendar`}>
          {mode === 'week' ? (
            <Week {...calendarProps} />
          ) : (
            <Month {...calendarProps} />
          )}
        </div>
        {!isFetching && !bookings.length && (
          <div className={`${c}_noResults`}>
            {`No arrivals or departures found for ${this.renderRange()}`}
          </div>
        )}
        <AppFooter />
      </div>
    )
  }
}

HostManagement.propTypes = propTypes

export default queryManager({
  defaultQuery: {
    mode: 'week',
    rangeStart: moment().startOf('isoWeek'),
    propertyIdFilter: 0,
  },
  parse: query => ({
    ...query,
    rangeStart: moment(stringToDate(query.rangeStart)),
    propertyIdFilter: +query.propertyIdFilter,
  }),
  serialize: query => ({
    ...query,
    rangeStart: dateToString(query.rangeStart),
  }),
})(HostManagement)
