import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import { Form, Button, Field, Icon } from '@/shared/components'

const propTypes = {
  stripe: PropTypes.func,
  account: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasBankAccount: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
}

const c = 'hostPayment_bankAccount'

class BankAccountForm extends Component {

  constructor(props) {
    super(props)
    this.state = { isEditing: !props.hasBankAccount(), isSubmitting: false }
  }

  handleSubmit = (values, form) => {
    const handleStripeError = description => {
      handleError({ error: { description } })
      form.setSubmitting(false)
    }
    this.stripe = this.props.stripe(window.STRIPE_PUBLISHABLE_KEY)
    this.stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      account_holder_name: values.accountHolderName,
      account_holder_type: values.accountHolderType,
      account_number: values.accountNumber,
      routing_number: values.routingNumber,
    }).then(result => {
      if (result.error) {
        handleStripeError(result.error.message)
      } else {
        this.props.onSubmit({ external_account: result.token.id }).then(() => {
          this.setState({ isEditing: false })
          this.props.updateCurrentUser({ hasBankAccount: true })
        }, ({ error }) => {
          handleStripeError(error.description || error.message)
        })
      }
    }, error => handleStripeError(error.message))
  }

  renderForm = () => (
    <Form
      initialValues={{
        accountHolderName: '',
        accountHolderType: '',
        routingNumber: '',
        accountNumber: '',
      }}
      validations={{
        accountHolderName: Form.is.required(),
        accountHolderType: Form.is.required(),
        routingNumber: Form.is.required(),
        accountNumber: Form.is.required(),
      }}
      onSubmit={this.handleSubmit}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form noValidate onSubmit={handleSubmit} className={`${c}_form`}>
          <h2>
            Add Bank Account
            {this.props.hasBankAccount() && (
              <div
                className={`${c}_form_editLink`}
                onClick={() => this.setState({ isEditing: false })}
              >
                <Icon type="edit" />
                Cancel
              </div>
            )}
          </h2>
          <div className={`${c}_form_fields`}>
            <Field.Input {...fields.accountHolderName} label="Account Holder Name" />
            <Field.Select
              {...fields.accountHolderType}
              label="Account Holder Type"
              placeholder="Select"
              options={[
                { label: 'Individual', value: 'individual' },
                { label: 'Company', value: 'company' },
              ]}
            />
            <Field.Input {...fields.routingNumber} label="Routing Number (ACH)" />
            <Field.Input {...fields.accountNumber} label="Account Number (Checking)" />
          </div>
          <Button type="submit" working={isSubmitting}>
            Save
          </Button>
        </form>
      )}
    />
  )

  renderShow = account => (
    <Fragment>
      <h2>Bank Account to Receive Payouts</h2>
      <div className={`${c}_show`}>
        <div className={`${c}_show_left`}>
          <div className={`${c}_show_name`}>{account.bankName}</div>
          <div className={`${c}_show_accountName`}>
            {`${account.accountHolderName}, ${account.country}`}
          </div>
        </div>
        <div className={`${c}_show_mid`}>
          <div><span>Account Number</span>{`Ends in ${account.last4}`}</div>
          <div><span>Routing Number</span>{account.routingNumber}</div>
          <div><span>Holder Type</span>{_.upperFirst(account.accountHolderType)}</div>
        </div>
        <Button hollow onClick={() => this.setState({ isEditing: true })}>
          Change Bank Account
        </Button>
      </div>
    </Fragment>
  )

  render() {
    const { isEditing } = this.state
    const { account } = this.props
    return (
      <div className={c}>
        <div className={`${c}_inner`}>
          {isEditing && this.renderForm(account)}
          {!isEditing && this.renderShow(account.externalAccounts.data[0])}
        </div>
      </div>
    )
  }
}

BankAccountForm.propTypes = propTypes

export default BankAccountForm
