import React from "react";
import { Link } from "react-router-dom";
import PrivateLink from "@/features/auth/components/PrivateLink";
import useHelpModalUrl from '@/features/helpModal/hooks/useHelpModalUrl'
import { Icon } from "@/shared/components";
import "@/features/appFooter/styles/index.scss";

const AppFooter = () => {
  const { toOpen } = useHelpModalUrl()

  return (
    <div className="appFooter">
      <div className="appFooter_columns">
        <div className="appFooter_column">
          <div className="appFooter_title">Roomsie</div>
          <div className="appFooter_items">
            <span onClick={toOpen}>Contact Us</span>
            <br />
            <a href="https://roomsie.zendesk.com" target="_blank">
              Help Center
            </a>
            <br />
            <PrivateLink to="/refer-guests">Refer Guests</PrivateLink>
            <br />
            <a href="/media-press" target="_blank">
              Media / Press
            </a>
          </div>
        </div>
        <div className="appFooter_column">
          <div className="appFooter_title">Hosting</div>
          <div className="appFooter_items">
            <Link to="/list-space">List your space</Link>
            <br />
            <Link to="/hosts">Why host?</Link>
          </div>
        </div>
        <div className="appFooter_column">
          <div className="appFooter_title">Policies</div>
          <div className="appFooter_items">
            <Link to="/cancellation-policy" target="_blank">
              Cancellation
            </Link>
            <br />
            <Link to="/guest-refund-policy" target="_blank">
              Guest Refund
            </Link>
            <br />
            <Link to="/privacy-policy" target="_blank">
              Privacy
            </Link>
          </div>
        </div>
        <div className="appFooter_column">
          <div className="appFooter_social">
            <a href="https://www.instagram.com/roomsiedc/" target="_blank">
              <Icon type="instagram-circle" />
            </a>
            <a href="https://www.facebook.com/RoomsieDC" target="_blank">
              <Icon type="facebook-circle" />
            </a>
            <a href="https://twitter.com/@roomsiedc" target="_blank">
              <Icon type="twitter-circle" />
            </a>
          </div>
          <div className="appFooter_items">
            <Link to="/terms-of-service" target="_blank">
              Terms
            </Link>
            <br />
            <Link to="/payments-terms-of-service" target="_blank">
              Payments Terms
            </Link>
          </div>
        </div>
      </div>
      <div className="appFooter_bottom">© Roomsie, LLC</div>
    </div>
  );
}
export default AppFooter;
