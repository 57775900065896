import api, { handleError } from '@/shared/api'
import history from '@/browserHistory'
import * as analytics from '@/shared/utils/analytics'
import * as url from '@/shared/utils/url'
import storage from '@/shared/utils/storage'
import { queryKeys, queryStringToState, queryStateToParams } from '@/search/state/reducers'
import { router } from '../../Routes'

export const updateQuery = query => (dispatch, getState) => {
  const queryState = { ...getState().search.query, page: 1, ...query }
  updateQueryStateAndfetchResults(queryState, dispatch)
}

export const updateLocationText = locationText => (dispatch, getState) => {
  const queryState = { ...getState().search.query, locationText }
  dispatch({
    type: 'search/query/locationText/UPDATED',
    locationText,
  })
  router.navigate({ search: queryStateToUrl(queryState).search })
}

export const restoreQuery = queryString => dispatch => {
  const queryState = queryStringToState(queryString)
  updateQueryStateAndfetchResults(queryState, dispatch, false)
}

const updateQueryStateAndfetchResults = (queryState, dispatch, shouldPush = true) => {
  dispatch(updateQueryState(queryState))

  const apiQuery = addRestrictedHostToApiQuery(
    queryStateToApi(queryState)
  )
  api.get('/search', apiQuery, {
    abortCurrentRequest: 'searchFetchResults',
  }).then(({ data, meta }) => {
    if (shouldPush) {
      router.navigate({ search: queryStateToUrl(queryState).search })
    }
    dispatch({
      type: 'search/results/FETCHED',
      results: {
        items: data,
        pagination: meta,
      },
    })
    analytics.track('Search Results Loaded', {
      ...queryState,
      ...queryState.filters,
      filters: undefined,
      resultsCount: meta.totalCount,
    })
  }, handleError)
}

export const updateQueryState = queryState => ({
  type: 'search/query/UPDATED',
  query: queryState,
})

const queryStateToUrl = queryState => ({
  pathname: history.location.pathname,
  search: `?${url.addToQueryString(
    url.omitFromQueryString(history.location.search, queryKeys),
    queryStateToParams(queryState)
  )}`,
})

const queryStateToApi = queryState => ({
  ...queryState.filters,
  boundingBox: queryState.boundingBox,
  userId: queryState.userId,
  sort: queryState.sort,
  page: queryState.page,
})

export const updateLocation = location => ({
  type: 'search/location/UPDATED',
  location,
})

export const fetchHost = hostId => dispatch => {
  api.get(`/users/${hostId}`).then(({ data }) => {
    dispatch({
      type: 'search/host/SET',
      host: data,
    })
  }, () => router.navigate('/search'))
}

export const updateOptions = options => ({
  type: 'search/options/UPDATED',
  options,
})

export const resetPage = () => ({
  type: 'search/RESET',
})

// Admin can add 'rh=true' to URL in order to
// force the API to only return their properties.
const urlrh = url.queryStringToObject(history.location.search).rh
const storagerh = storage.local.get('rh')
const storageDuration = 1000 * 60 * 60 * 24 * 7
const storageDurationPassed = new Date().getTime() - (+storagerh) > storageDuration

if (urlrh) {
  history.replace({
    pathname: history.location.pathname,
    search: url.omitFromQueryString(history.location.search, ['rh']),
  })
}
if (urlrh) {
  storage.local.set('rh', new Date().getTime())
} else if (storagerh && storageDurationPassed) {
  storage.local.remove('rh')
}

const addRestrictedHostToApiQuery = apiQuery => {
  if (storage.local.get('rh')) {
    apiQuery.userId = 'thewihn'
  }
  return apiQuery
}
