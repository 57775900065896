import _ from 'lodash'
import moment from 'moment'
import { timeFormat } from '@/shared/utils/dateTime'

export const apiToClient = data => ({
  bookings: _.flatMap(data, property => (
    property.bookings.map(booking => ({
      ...booking,
      propertyId: property.id,
      startDate: booking.arrivalDate || booking.startDate,
    }))
  )),
  properties: _.keyBy(
    data.map(property => ({ ...property, bookings: null })), 'id'
  ),
})

export const sortBookings = (bookings, checkOutIds) => {
  const byProperty = _.sortBy(bookings, ['propertyId', 'id'])
  const byTime = _.sortBy(byProperty, b => +moment(b.arrivalTime || '11:30 pm', timeFormat))
  const byCheckInOut = _.sortBy(byTime, b => !!checkOutIds[b.id])
  return _.sortBy(byCheckInOut, b => !!(b.checkedOut || b.checkedIn))
}

export const filterBookingsByProperty = (bookings, propertyIdFilter) => (
  propertyIdFilter ? bookings.filter(b => b.propertyId === propertyIdFilter) : bookings
)

export const getCheckInOutIds = (bookings, date) => ({
  checkInIds: bookings.reduce((obj, { id, startDate }) => (
    moment(startDate).isSame(date, 'day') ? { ...obj, [id]: true } : obj
  ), {}),
  checkOutIds: bookings.reduce((obj, { id, endDate }) => (
    moment(endDate).isSame(date, 'day') ? { ...obj, [id]: true } : obj
  ), {}),
})

export const getDayBookings = (bookings, checkInIds, checkOutIds) => (
  bookings.filter(booking => checkInIds[booking.id] || checkOutIds[booking.id])
)

export const isProcessed = (booking, checkInIds, checkOutIds) => (
  (checkInIds[booking.id] && booking.checkedIn) ||
  (checkOutIds[booking.id] && booking.checkedOut)
)

export const shouldCalendarRender = (nextProps, thisProps) => (
  nextProps.bookings !== thisProps.bookings ||
  nextProps.propertyIdFilter !== thisProps.propertyIdFilter
)
