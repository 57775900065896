import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { formatDate, dateToString } from '@/shared/utils/dateTime'
import { sortPhotos } from '@/shared/utils/photos'
import { formatAddress } from '@/shared/utils/geo'
import * as utils from '@/hostManagement/utils'
import { handleError } from '@/shared/api'
import * as api from '@/hostManagement/api'
import { Modal, Button, Spinner, Photo, Avatar } from '@/shared/components'
import '@/hostManagement/styles/day.scss'

const propTypes = {
  date: PropTypes.any,
  calendarBookings: PropTypes.array.isRequired,
  calendarProperties: PropTypes.object.isRequired,
  calendarRange: PropTypes.object.isRequired,
  propertyIdFilter: PropTypes.number,
  onUnmountUpdateBookings: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
}

const c = 'hostManagement_day'

class Day extends Component {

  constructor(props) {
    super(props)
    this.updatedBookings = []
    this.state = {
      date: props.date,
      bookings: [],
      properties: {},
      isFetching: true,
    }
  }

  componentDidMount() {
    this.fetchBookings()
  }

  componentWillUnmount() {
    this.props.onUnmountUpdateBookings(this.updatedBookings)
  }

  fetchBookings = () => {
    const { date } = this.state
    const { startDate, endDate } = this.props.calendarRange

    if (date.isBetween(startDate, endDate, 'day', '[]')) {
      this.fetchBookingsFromProps(date)
    } else {
      this.fetchBookingsFromApi(date)
    }
  }

  fetchBookingsFromProps = date => {
    this.setState({
      bookings: this.props.calendarBookings.filter(b => (
        moment(b.startDate).isSame(date, 'day') ||
        moment(b.endDate).isSame(date, 'day')
      )),
      properties: this.props.calendarProperties,
      isFetching: false,
    })
  }

  fetchBookingsFromApi = date => {
    api.fetchBookings({
      startDate: dateToString(date),
      endDate: dateToString(date),
    }).then(({ data }) => {
      this.setState({ ...utils.apiToClient(data), isFetching: false })
    }, handleError)
  }

  changeRange = subtractOrAdd => {
    const date = moment(this.state.date)[subtractOrAdd](1, 'day')
    this.setState({ date, isFetching: true }, this.fetchBookings)
  }

  toggleProcessBooking = (booking, checkInIds) => {
    const field = checkInIds[booking.id] ? 'checkedIn' : 'checkedOut'
    api.updateBooking(booking.id, { [field]: !booking[field] }).then(() => {}, handleError)
    const updatedBooking = { ...booking, [field]: !booking[field] }
    this.updatedBookings = _.uniqBy([updatedBooking, ...this.updatedBookings], 'id')
    this.setState({ bookings: _.uniqBy([updatedBooking, ...this.state.bookings], 'id') })
  }

  render() {
    const { date, bookings, properties, isFetching } = this.state
    return (
      <Modal
        isOpen
        onClose={this.props.onModalClose}
        className={c}
        overlayClassName={`${c}_overlay`}
        renderContent={() => (
          <Fragment>
            <div className={`${c}_controls`}>
              <Button
                square
                size="small"
                icon="chevron-left-fat"
                onClick={() => this.changeRange('subtract')}
              />
              <Button
                square
                size="small"
                icon="chevron-right-fat"
                onClick={() => this.changeRange('add')}
              />
              <div className={`${c}_controls_date`}>
                <span>{formatDate(date, 'dayMonthDayYear')}</span>
                <div>{`${bookings.length} guests`}</div>
              </div>
              {isFetching && <Spinner size={40} />}
            </div>
            <Bookings
              bookings={bookings}
              properties={properties}
              date={date}
              propertyIdFilter={this.props.propertyIdFilter}
              toggleProcessBooking={this.toggleProcessBooking}
            />
            {!isFetching && !utils.filterBookingsByProperty(
              bookings, this.props.propertyIdFilter
            ).length && (
              <div className={`${c}_noResults`}>
                {`No bookings found for ${formatDate(date)}`}
              </div>
            )}
          </Fragment>
        )}
      />
    )
  }
}

Day.propTypes = propTypes

class Bookings extends Component {

  shouldComponentUpdate(nextProps) {
    return utils.shouldCalendarRender(nextProps, this.props)
  }

  render() {
    let { bookings, properties, date, propertyIdFilter, toggleProcessBooking } = this.props

    bookings = utils.filterBookingsByProperty(bookings, propertyIdFilter)

    const { checkInIds, checkOutIds } = utils.getCheckInOutIds(bookings, date)
    const propertyBookings = _.groupBy(bookings, b => b.propertyId)
    return (
      _.map(propertyBookings, (bookings, propertyId) => (
        <div key={propertyId} className={`${c}_property_group`}>
          <Link to={`/properties/${propertyId}`} className={`${c}_property`}>
            <Photo photo={sortPhotos(properties[propertyId].photos)[0]} />
            <div className={`${c}_property_title`}>
              {properties[propertyId].title}
            </div>
            <div className={`${c}_property_address`}>
              {formatAddress(properties[propertyId])}
            </div>
          </Link>
          {utils.sortBookings(bookings, checkOutIds).map(booking => (
            <div key={booking.id} className={classnames(`${c}_booking`, {
              [`${c}_booking--isProcessed`]: utils.isProcessed(booking, checkInIds, checkOutIds),
            })}>
              <Link to={`/host/bookings/${booking.id}`}>
                <Avatar {...booking.guest} size={40} withoutLink />
                <div className={`${c}_booking_info`}>
                  <div className={`${c}_booking_guestName`}>
                    {`${booking.guest.firstName} ${booking.guest.lastName}`}
                    <span>{`${booking.guest.age} ${booking.guest.gender}`}</span>
                  </div>
                  {booking.bedroomTitle && (
                    <div className={`${c}_booking_bedroom`}>
                      {_.truncate(booking.bedroomTitle, { length: 36 })}
                    </div>
                  )}
                </div>
              </Link>
              <div
                className={classnames(`${c}_booking_action`, {
                  [`${c}_booking_action--isCheckIn`]: checkInIds[booking.id],
                  [`${c}_booking_action--isCheckOut`]: checkOutIds[booking.id],
                })}
                onClick={() => toggleProcessBooking(booking, checkInIds)}
              >
                {checkInIds[booking.id] ? `${booking.arrivalTime || ''} Check In` : 'Check Out'}
              </div>
            </div>
          ))}
        </div>
      ))
    )
  }
}

export default Day
