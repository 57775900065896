import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'

const propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
}

class CheckboxGroup extends Component {

  constructor(props) {
    super(props)
    this.isControlled = !_.isUndefined(props.value)
    this.state = {
      value: props.defaultValue || [],
    }
  }

  getValue = () => (
    (this.isControlled ? this.props.value : this.state.value).map(v => v.toString())
  )

  isChecked = value => (
    !!this.getValue().find(v => v === value.toString())
  )

  onCheck = e => {
    const value = e.target.checked ? (
      _.uniq([...this.getValue(), e.target.value.toString()])
    ) : (
      this.getValue().filter(v => v !== e.target.value.toString())
    )
    if (!this.isControlled) {
      this.setState({ value })
    }
    this.props.onChange(value)
  }

  renderProps = () => this.props.render({
    isChecked: this.isChecked,
    onCheck: this.onCheck,
  })

  render() {
    return (
      <div className={classnames('checkboxGroup', this.props.className)}>
        {this.renderProps()}
      </div>
    )
  }
}

CheckboxGroup.propTypes = propTypes

export default CheckboxGroup
