import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import options from '@/shared/static/options'
import { getFirstParagraphFromHtml } from '@/shared/utils/html'
import { Icon, Switch, TextEditedHtml, Avatar, Tooltip, VerifiedMark } from '@/shared/components'
import '@/property/styles/propertyBasics.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
}

const c = 'property_propertyBasics'
export const propertyBasicsClassname = c

const PropertyBasics = ({ property }) => {
  const descHtml = property.descriptionHtml.trim()
  const descFirstParagraph = getFirstParagraphFromHtml(descHtml)
  return (
    <div className={c}>
      <div className={`${c}_inner`}>
        <div className={`${c}_accommodation`}>
          {`${property.bedroomsCount}
            Bedroom${property.bedroomsCount === 1 ? '' : 's'}/${property.bathroomsCount}
            Bath${property.bathroomsCount === 1 ? '' : 's'} `}
          &#183;
          {` ${options.labels.propertyTypes[property.propertyType]} `}
          &#183;
          {` ${options.labels.placementTypes[property.placementType]}
             ${property.placementType === 'unisex' ? '' : 'only'} `}
          &#183;
          {` ${options.labels.listingTypes[property.listingType]} `}
          &#183;
          {property.listingType === 'entire_space' ? (
            ` Sleeps ${property.accommodates}`
          ) : (
            +property.accommodates <= 1 ? (
              ` Private Bedroom`
            ) : (
              ` Up to ${+property.accommodates - 1} Roomies`
            )
          )}
        </div>
        <h1 className={`${c}_title`}>{property.title}</h1>
        <div className={`${c}_address`}>
          <Icon type="location" />
          {`${property.neighborhood}, ${property.city}`}
        </div>
        {!!property.closestStationDistance && (
          <div className={`${c}_metro`}>
            <Icon type="train" />
            {`${_.round(property.closestStationDistance, 2)} Miles to ${property.closestStationName}`}
          </div>
        )}
        <div className={`${c}_host`}>
          <Avatar {...property.user} size={70} />
          <div>
            <div className={`${c}_host_name`}>
              {`Hosted by ${property.user.firstName}`}
            </div>
            {property.user.verifiedHost && <VerifiedMark label="Verified" />}
          </div>
        </div>
      </div>
      <Switch
        linkText="Read more about the property"
        shouldShowLink={() => descHtml.length > descFirstParagraph.length}
        renderDefault={() => <TextEditedHtml html={descFirstParagraph} />}
        renderSwitch={() => <TextEditedHtml html={descHtml} />}
      />
    </div>
  )
}

PropertyBasics.propTypes = propTypes

export default PropertyBasics
