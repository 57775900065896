import api from '@/shared/api'

export const fetchBookings = query => (
  api.get('/host/calendar', query, {
    abortCurrentRequest: 'hostManagementFetchBookings',
  })
)

export const updateBooking = (bookingId, query) => (
  api.patch(`/host/bookings/${bookingId}`, query)
)
