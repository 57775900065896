/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//import 'babel-polyfill'

import React, { StrictMode } from 'react'
import WebFont from 'webfontloader'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'

import '../application.scss'

import store from '../store'
import Routes from '../Routes'

WebFont.load({
  google: { families: ['Montserrat:300,400,500,600,700'] },
})

const $loader = document.getElementById('applicationLoader')
$loader.parentNode.removeChild($loader)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <Routes />
  </Provider>
)