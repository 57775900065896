export const hostLinks = [{
  to: '/host/bookings',
  text: 'Bookings',
  icon: 'calendar',
}, {
  to: '/host/properties',
  text: 'Properties',
  icon: 'home',
}, {
  to: '/host/guests',
  text: 'Guests',
  icon: 'people',
}, {
  to: '/host/scheduled-messaging',
  text: 'Templates',
  icon: 'email',
}, {
  to: '/host/partners',
  text: 'Partners',
  icon: 'graduation-cap',
}, {
  to: '/host/payment-center',
  text: 'Payments',
  icon: 'money-bag',
}]

export const guestLinks = [{
  to: '/guest/dashboard',
  text: 'Dashboard',
  icon: 'dashboard',
}, {
  to: '/guest/bookings',
  text: 'My Stays',
  icon: 'calendar',
}, {
  to: '/guest/roomies',
  text: 'My Roomies',
  icon: 'people',
}, {
  to: '/events',
  text: 'Events',
  icon: 'list-alt',
}, {
  to: '/messages',
  text: 'Messages',
  icon: 'email',
}, {
  to: '/guest/payment-center',
  text: 'Billing',
  icon: 'money-bag',
}]

export const userLinks = [{
  to: '/profile',
  text: 'Profile',
}, {
  to: '/events',
  text: 'Events',
}, {
  to: '/messages',
  text: 'Messages',
}, {
  to: '/refer-guests',
  text: 'Refer Guests',
}, {
  to: '/signout',
  text: 'Sign Out',
}]
