import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import googleMapsApiLoader from '@/shared/hocs/googleMapsApiLoader'
import coordinates from '@/shared/static/coordinates'
import { parseGooglePlace, boundsArrayToObject } from '@/shared/utils/geo'
import { Input } from './'
import '@/shared/styles/locationSearch.scss'

const propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
}

const defaultProps = {
  inputProps: {},
  placeholder: 'Search location, address...',
  onChange: (value) => {},
}

class LocationSearch extends Component {

  constructor(props) {
    super(props)
    this.isControlled = !_.isUndefined(props.value)
    this.state = {
      value: props.defaultValue || ''
    }
  }

  componentDidMount() {
    const { places, event } = this.props.google.maps

    this.autoComplete = new places.Autocomplete(this.$input, {
      bounds: boundsArrayToObject(coordinates.washingtonDC.boundingBox),
      componentRestrictions: { country: 'us' },
    })
    this.autoComplete.addListener('place_changed', this.updateLocation)

    this.inputListener = event.addDomListener(this.$input, 'keydown', e => {
      if (e.keyCode === 13) e.preventDefault()
    })
  }

  componentWillUnmount() {
    this.inputListener.remove()
  }

  updateLocation = () => {
    const place = this.autoComplete.getPlace()
    if (!place?.place_id) return
    const parsedPlace = parseGooglePlace(place)

    if (!this.isControlled) {
      this.setState({ value: parsedPlace.name })
    }
    this.props.onChange(parsedPlace.name)
    this.props.onSelect(parsedPlace)
  }

  onInputChange = (e, value) => {
    if (!this.isControlled) {
      this.setState({ value })
    }
    this.props.onChange(value)
  }

  render() {
    return (
      <Input
        icon="search"
        invalid={this.props.invalid}
        {...this.props.inputProps}
        className={classnames('locationSearch', this.props.className)}
        placeholder={this.props.placeholder}
        value={this.isControlled ? this.props.value : this.state.value}
        onChange={this.onInputChange}
        getRef={el => this.$input = el}
      />
    )
  }
}

LocationSearch.propTypes = propTypes
LocationSearch.defaultProps = defaultProps

export default googleMapsApiLoader(LocationSearch)
