import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleError } from '@/shared/api'
import { sendVerificationEmail, verifyEmail } from '@/features/auth/state/actions'
import { Modal, Form, Button, Field, Icon } from '@/shared/components'

const mapDispatchToProps = { sendVerificationEmail, verifyEmail }

const propTypes = {
  sendVerificationEmail: PropTypes.func.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  isChangeEmailDefault: PropTypes.bool,
}

const defaultProps = {
  onSuccess: () => {},
}

const c = 'authConfirm_email'

class Email extends Component {

  constructor(props) {
    super(props)
    this.state = { isChangeEmailFormActive: !!props.isChangeEmailDefault }
  }

  renderChangeEmailForm = () => (
    <Form
      key="changeEmailForm"
      initialValues={{
        email: '',
      }}
      validations={{
        email: [Form.is.required(), Form.is.email()],
      }}
      onSubmit={(values, form) => {
        this.props.sendVerificationEmail(values.email).then(() => {
          this.setState({ isChangeEmailFormActive: false })
        }, ({ error }) => {
          if (error.details) {
            form.setErrors(error.details)
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className={c}>
            <Icon className={`${c}_icon`} type="email-circle" />
            <h1 className={`${c}_title`}>
              Use a different email
            </h1>
            <p className={`${c}_tip`}>
              Please enter a new email address to use from now on.
            </p>
            <Field.Input
              {...fields.email}
              placeholder="Enter new email"
              icon="email"
              type="email"
            />
            <Button working={isSubmitting}>
              Update Email
            </Button>
            <div className={`${c}_change`} onClick={() => {
              this.setState({ isChangeEmailFormActive: false })
            }}>
              Let me enter the code
            </div>
          </div>
        </form>
      )}
    />
  )

  renderVerifyEmailForm = () => (
    <Form
      key="VerifyEmailForm"
      initialValues={{
        code: '',
      }}
      validations={{
        code: Form.is.match(v => /^[0-9]{6}$/.test(v), 'Verification code must be a 6 digit number'),
      }}
      onSubmit={(values, form) => {
        this.props.verifyEmail(values.code).then(() => {
          this.props.onSuccess()
        }, ({ error }) => {
          if (error.code === 'invalid_resource') {
            form.setErrors({
              code: 'The code does not match. Make sure to enter code from the latest email we sent you.',
            })
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form onSubmit={handleSubmit}>
          <div className={c}>
            <Icon className={`${c}_icon`} type="email-circle" />
            <h1 className={`${c}_title`}>
              Check your email inbox and enter the code we sent you
            </h1>
            <p className={`${c}_tip`}>
              Confirming your email address helps us send you trip information.
            </p>
            <Field.Input
              {...fields.code}
              type="tel"
              autoComplete="off"
              className={`${c}_pin_input`}
              placeholder="Enter code"
            />
            <Button working={isSubmitting}>
              Verify Email
            </Button>
            <div className={`${c}_change`} onClick={() => {
              this.setState({ isChangeEmailFormActive: true })
            }}>
              Use a different email
            </div>
          </div>
        </form>
      )}
    />
  )

  render() {
    return this.state.isChangeEmailFormActive ?
      this.renderChangeEmailForm() :
      this.renderVerifyEmailForm()
  }
}

Email.propTypes = propTypes
Email.defaultProps = defaultProps

export default connect(null, mapDispatchToProps)(Email)
