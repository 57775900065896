import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import options from '@/shared/static/options';
import { CheckboxGroup, Checkbox } from '@/shared/components';
import '@/plugin/property/styles/roomTypeFilters.scss';

const propTypes = {
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
};

const typeDescriptions = {
  entire_space: 'Have the whole unit all to yourself',
  private_bedroom: 'Have a private bedroom and share common spaces',
  shared_bedroom: 'Split a bedroom among friends or other roomies',
};

const c = 'plugin_room_roomTypeFilters';

class RoomTypeFilters extends Component {
  render() {
    const { query, updateQuery } = this.props;
    return (
      <Fragment>
        <div className={`${c}_container`}>
          <div className={`${c}_filtersContainer`}>
            <CheckboxGroup
              className={`${c}_checkboxGroup`}
              value={query?.privacyTypes || []}
              onChange={(value) =>
                updateQuery({
                  privacyTypes: value,
                  bedsCount: value.find((t) => t === 'shared_bedroom')
                    ? query.bedsCount
                    : [],
                })
              }
              render={({ isChecked, onCheck }) =>
                options?.privacyTypes?.map(({ value, label }) => (
                  <div key={value} className={`${c}_filterItem`}>
                    <Checkbox
                      className={`${c}_checkbox`}
                      name="privacyTypes"
                      value={value}
                      label={label}
                      checked={isChecked(value)}
                      onChange={onCheck}
                    />
                    <div className={`${c}_tip`}>{typeDescriptions[value]}</div>
                  </div>
                ))
              }
            />
          </div>
          {query.privacyTypes &&
            query.privacyTypes?.includes('shared_bedroom') && (
              <div className={`${c}_filtersContainer`}>
                <div className={`${c}_occupancyHeading`}>
                  <div className={`${c}_occupancyHeading_title`}>Occupancy</div>
                  <div className={`${c}_occupancyHeading_description`}>
                    How many roomies would you like to share your room with?
                  </div>
                </div>

                {query.privacyTypes?.includes('shared_bedroom') && (
                  <CheckboxGroup
                    className={`${c}_checkboxGroup`}
                    value={query?.bedsCount || []}
                    onChange={(value) => {
                      updateQuery({ bedsCount: value });
                    }}
                    render={({ isChecked, onCheck }) =>
                      query.privacyTypes.includes('shared_bedroom') && (
                        <Fragment>
                          {options?.bedsCount
                            ?.slice(1)
                            .map(({ value, label, description }) => (
                              <div
                                key={value}
                                className={`${c}_bedsCount_filterItem`}
                              >
                                <Checkbox
                                  name="bedsCount"
                                  value={value}
                                  label={label}
                                  checked={isChecked(value)}
                                  onChange={onCheck}
                                />
                                <div className={`${c}_bedsCount_tip`}>
                                  {description}
                                </div>
                              </div>
                            ))}
                        </Fragment>
                      )
                    }
                  />
                )}
              </div>
            )}
        </div>
      </Fragment>
    );
  }
}

RoomTypeFilters.propTypes = propTypes;

export default RoomTypeFilters;
