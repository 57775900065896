import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import options from '@/shared/static/options'
import { addToQueryString } from '@/shared/utils/url'
import { dateToString } from '@/shared/utils/dateTime'
import { DateRangePicker, Select, Button } from '@/shared/components'
import '@/landings/styles/searchForm.scss'

const propTypes = {
  isTop: PropTypes.bool,
  templateType: PropTypes.string,
}

const defaultProps = {
  templateType: 'default',
}

const c = 'landingsSearchForm'

const content = {
  default: {
    text1: 'I am looking for ',
    select: { unisex: 'Co-ed', female: 'Female', male: 'Male' },
    text2: ' housing from ',
  },
  parents: {
    text1: 'My ',
    select: { unisex: 'Child', female: 'Daughter', male: 'Son' },
    text2: ' is looking for housing from ',
  },
  universities: {
    text1: "My university's DC program session dates are ",
  },
  coordinators: {
    text1: 'Our DC interns will need housing from ',
  },
}

const SearchCta = (props) => {
  const { isTop, templateType } = props
  let location = useLocation()

  const [search, setSearch] = useState({
    startDate: null,
    endDate: null,
    placementTypes: []
  })

  return (
    <div className={isTop ? `${c}--isTop` : `${c}--isBottom`}>
      <div className={`${c}_inner`}>
        {isTop && (
          <>
            <span>{content[templateType].text1}</span>
            {content[templateType].select && (
              <>
                <Select
                  line
                  size="small"
                  defaultValue="unisex"
                  options={options.placementTypes.map(({ value }) => (
                    { value, label: content[templateType].select[value] }
                  ))}
                  onChange={(e, value) => setSearch({
                    ...search,
                    placementTypes: value ? _.uniq([value, 'unisex']) : []
                  })}
                />
                <span>{content[templateType].text2}</span>
              </>
            )}
            <br/>
          </>
        )}
        <DateRangePicker
          className="landingsGeneral_hero_dateRangePicker"
          placeholder="Select dates of stay"
          inputProps={{ line: isTop, size: 'small' }}
          tooltipProps={{ windowBounds: { bottom: true } }}
          onChange={({ from, to }) => setSearch({
            ...search,
            startDate: dateToString(from),
            endDate: dateToString(
              from && !to ? moment(from).add(7, 'd').toDate() : to
            ),
          })}
        />
        <Link to={{
          pathname: '/search',
          search: addToQueryString(location.search, search),
        }}>
          <Button size="large">Find Rooms</Button>
        </Link>
      </div>
    </div>
  )
}

SearchCta.propTypes = propTypes
SearchCta.defaultProps = defaultProps

export default SearchCta

