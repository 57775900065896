import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formatPrice } from '@/shared/utils/numbers';
import { formatDateRange } from '@/shared/utils/dateTime';
import '@/plugin/property/styles/details.scss';

const propTypes = {
  rates: PropTypes.object,
  selectedBedroom: PropTypes.object,
  userInput: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  sessions: PropTypes.array,
  label: PropTypes.string.isRequired,
};

const c = 'plugin_roomSelection';

class Details extends Component {
  formattedDates = () =>
    formatDateRange(this.props.query.startDate, this.props.query.endDate);

  getSemesterLabel = () => {
    if (!this.props.query.sessionId) return;
    let selectedSemester = this.props.sessions?.find(
      (semester) => semester.id === parseInt(this.props.query?.sessionId)
    );
    return selectedSemester?.name;
  };

  isSharedSpace = (bedroom) => {
    return bedroom.property.listingType === 'shared';
  };

  getCost = (itemKind) => {
    let rate = this.props.rates?.items.find(
      (item) => item.kind === itemKind
    ).total;
    return formatPrice(rate, { precise: true });
  };

  renderRoomSelection = (
    selectedBedroom,
    housingCost,
    securityDeposit,
    total,
    dueToday,
    label
  ) => (
    <div className={`${c}`}>
      <div className={`${c}_header`}>
        <h2>Your Room Selection</h2>
      </div>
      <div className={`${c}_roomDetails`}>
        <div className={`${c}_roomDetails_container`}>
          <div className={`${c}_roomDetails_label`}>Property:</div>
          <div className={`${c}_roomDetails_value`}>
            {selectedBedroom?.property.title || 'N/A'}
          </div>
        </div>
        {this.isSharedSpace(selectedBedroom) && (
          <div className={`${c}_roomDetails_container`}>
            <div className={`${c}_roomDetails_label`}>Room:</div>
            <div className={`${c}_roomDetails_value`}>
              {selectedBedroom?.title || 'N/A'}
            </div>
          </div>
        )}
        {this.props.query.sessionId !== 'custom_dates' &&
          this.props.query.sessionId !== '' && (
            <div className={`${c}_roomDetails_container`}>
              <div className={`${c}_roomDetails_label`}>Term:</div>
              <div className={`${c}_roomDetails_value`}>
                {this.getSemesterLabel()}
              </div>
            </div>
          )}
        <div className={`${c}_roomDetails_container`}>
          <div className={`${c}_roomDetails_label`}>Dates Of Stay:</div>
          <div className={`${c}_roomDetails_value`}>
            {this.formattedDates()}
          </div>
        </div>
        <div className={`${c}_roomDetails_container`}>
          <div className={`${c}_roomDetails_label`}>Housing Cost:</div>
          <div className={`${c}_roomDetails_value`}>
            {housingCost === '$NaN' ? 'N/A' : housingCost}
          </div>
        </div>
        <div className={`${c}_roomDetails_container`}>
          <div className={`${c}_roomDetails_label`}>Security Deposit:</div>
          <div className={`${c}_roomDetails_value`}>
            {securityDeposit === '$NaN' ? 'N/A' : securityDeposit}
          </div>
        </div>
        <div className={`${c}_roomDetails_container`}>
          <div className={`${c}_roomDetails_label`}>Total:</div>
          <div className={`${c}_roomDetails_value`}>
            {total === '$NaN' ? 'N/A' : total}
          </div>
        </div>
        <div className={`${c}_roomDetails_dueToday`}>
          <div className={`${c}_roomDetails_dueToday_label`}>{label}</div>
          <div className={`${c}_roomDetails_dueToday_value`}>
            {dueToday === '$NaN' ? 'N/A' : dueToday}
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const { rates, selectedBedroom, label } = this.props;

    let housingCost = this.getCost('rental');
    let securityDeposit = this.getCost('security_deposit');
    let total = formatPrice(rates?.total, { precise: true });
    let dueToday = formatPrice(rates?.charges[0].total, { precise: true });

    return (
      rates && (
        <div className={c}>
          {this.renderRoomSelection(
            selectedBedroom,
            housingCost,
            securityDeposit,
            total,
            dueToday,
            label
          )}
        </div>
      )
    );
  }
}

Details.propTypes = propTypes;

export default Details;
