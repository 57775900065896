import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Icon } from './'
import '@/shared/styles/input.scss'

const propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  line: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filter: PropTypes.any, // regex
  onChange: PropTypes.func,
  getRef: PropTypes.func,
}

const defaultProps = {
  type: 'text',
  size: 'medium',
  onChange: (event, value) => {},
  onKeyDown: (event, value) => {},
  getRef: (element) => {},
}

class Input extends Component {

  handleChange = e => {
    if (!this.props.filter || this.props.filter.test(e.target.value)) {
      this.props.onChange(e, e.target.value)
    }
  }

  handleKeyDown = e => {
    this.props.onKeyDown(e, e.target.value)
  }

  render() {
    const {
      size,
      line,
      icon,
      className,
      invalid,
      filter,
      onChange,
      onKeyDown,
      getRef,
      ...inputProps
    } = this.props

    return (
      <div className={classnames(
        'input',
        `input--size-${size}`, {
          'input--line': line,
          'input--icon': icon,
          'input--invalid': invalid,
        },
        className
      )}>
        {icon && (
          _.isString(icon) ? <Icon type={icon} /> : icon
        )}
        <input
          {...inputProps}
          className="input_element"
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          ref={getRef}
        />
      </div>
    )
  }
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
