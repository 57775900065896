import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import options from '@/shared/static/options'
import { syncRatesWithSessions } from '@/listSpace/state/sync'
import { Field, Radio, FieldErrors, Icon, TextEditor } from '@/shared/components'
import PrevNext from './PrevNext'
import '@/listSpace/styles/propertyBasics.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

const c = 'listSpace_propertyBasics'

class ListSpace extends Component {

  handleChange = values => {
    this.props.updateProperty(values)
    this.props.clearStepErrors(_.keys(values))
  }

  handleListingTypeChange = value => {
    if (value === 'entire_space') {
      this.handleChange({
        listingType: value,
        placementType: 'unisex',
        ...this.syncedRates(),
      })
    } else {
      this.handleChange({
        listingType: value,
        ...this.syncedRates(),
      })
    }
  }

  handlePropertyTypeChange = value => {
    if (value === 'studio_apartment') {
      this.handleChange({
        propertyType: value,
        bedroomsCount: 0,
        listingType: 'entire_space',
        placementType: 'unisex',
        ...this.syncedRates(),
      })
    } else if (+this.props.property.bedroomsCount === 0) {
      this.handleChange({ propertyType: value, bedroomsCount: 1 })
    } else {
      this.handleChange({ propertyType: value })
    }
  }

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next')
    }
  }

  syncedRates = () => (
    syncRatesWithSessions(this.props.property.sessions, this.props.property)
  )

  renderRadioBox = props => (
    <label className={classnames(`${c}_radioBox`, {
      [`${c}_radioBox--isChecked`]: props.checked,
    })}>
      <Radio {...props} />
      <div className={`${c}_radioBox_description`}>
        {props.description}
      </div>
    </label>
  )

  render() {
    const { property, errors } = this.props
    return (
      <div className={`${c} listSpace_content`}>
        <h1 className="listSpace_title">Property Basics</h1>
        <div className="listSpace_label">Title</div>
        <Field.Input
          placeholder="Name your property"
          value={property.title}
          onChange={(e, value) => this.handleChange({ title: value })}
          errors={errors.title}
        />
        <p className="listSpace_tip">
          {"The title should be short, catchy, and easily scannable while conveying the most important information."}
        </p>
        <div className="listSpace_label">Description</div>
        <TextEditor
          value={property.description}
          onChange={(e, value) => this.handleChange({ description: value })}
          invalid={!!errors.description}
        />
        <FieldErrors errors={errors.description} />
        <p className="listSpace_tip">
          {"Describe your neighborhood, proximity to public transport, super markets or anything you’d like your prospective guest to know about your home. Guests love learning about your property so be as detailed as possible."}
        </p>
        <div className="listSpace_label">What type of property is this?</div>
        <Field.Select
          placeholder="Select property type"
          options={options.propertyTypes}
          value={property.propertyType}
          onChange={(e, value) => this.handlePropertyTypeChange(value)}
          errors={errors.propertyType}
        />
        {property.propertyType !== 'studio_apartment' && (
          <Fragment>
            <div className="listSpace_label">Listing Type</div>
            <div className={`${c}_radioBox_row`}>
              {this.renderRadioBox({
                label: 'Entire Space',
                description: 'Listings where you have the entire home/unit to yourself',
                name: 'listingType',
                value: 'entire_space',
                checked: property.listingType === 'entire_space',
                onChange: (e, value) => this.handleListingTypeChange(value),
              })}
              {this.renderRadioBox({
                label: 'Shared',
                description: 'Listings where the property/unit is occupied entirely by Roomsie guests',
                name: 'listingType',
                value: 'shared',
                checked: property.listingType === 'shared',
                onChange: (e, value) => this.handleListingTypeChange(value),
              })}
              {this.renderRadioBox({
                label: 'Hosted',
                description: 'Listings where the owner/host lives on premises',
                name: 'listingType',
                value: 'hosted',
                checked: property.listingType === 'hosted',
                onChange: (e, value) => this.handleListingTypeChange(value),
              })}
            </div>
          </Fragment>
        )}
        {property.listingType !== 'entire_space' && (
          <Fragment>
            <div className="listSpace_label">Placement Type</div>
            <div className={`${c}_radioBox_row`}>
              {this.renderRadioBox({
                label: 'Co-ed',
                description: 'I have no gender preference for my applicants',
                name: 'placementType',
                value: 'unisex',
                checked: property.placementType === 'unisex',
                onChange: (e, value) => this.handleChange({ placementType: value }),
              })}
              {this.renderRadioBox({
                label: 'Female',
                description: 'I prefer female applicants only',
                name: 'placementType',
                value: 'female',
                checked: property.placementType === 'female',
                onChange: (e, value) => this.handleChange({ placementType: value }),
              })}
              {this.renderRadioBox({
                label: 'Male',
                description: 'I prefer male applicants only',
                name: 'placementType',
                value: 'male',
                checked: property.placementType === 'male',
                onChange: (e, value) => this.handleChange({ placementType: value }),
              })}
            </div>
            <p className="listSpace_tip">
              {"In cases where you are hosting a Roomsie guest, you may have a preference for you applicants gender. Your listing will not show in search results for your non-designated preference. If you wish to have your home shown to all genders then please choose 'Co-ed' as your placement type."}
            </p>
          </Fragment>
        )}
        <PrevNext onNextClick={this.handleSubmit} />
      </div>
    )
  }
}

ListSpace.propTypes = propTypes

export default ListSpace
