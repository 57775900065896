const initialCurrentUserState = null

export const currentUser = (state = initialCurrentUserState, action) => {
  switch (action.type) {
    case 'auth/currentUser/UPDATED':
      return action.currentUser
    case 'auth/currentUser/REMOVED':
      return initialCurrentUserState
    default:
      return state
  }
}
