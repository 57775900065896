import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { getAvatarUrl } from '@/shared/utils/photos'
import '@/shared/styles/avatar.scss'


const propTypes = {
  id: PropTypes.number,
  avatar: PropTypes.object,
  firstName: PropTypes.string,
  size: PropTypes.number,
  fullWidth: PropTypes.bool,
  withoutLink: PropTypes.bool,
}

const colors = ['#E88C3F', '#C34046', '#E6D19A', '#3A8FD2', '#49A486', '#FD8531']

const letterColors = _.range(26).reduce((ret, n) => {
  ret[String.fromCharCode(n + 97)] = colors[Math.floor(n / (26 / colors.length))]
  return ret
}, {})

const getColorFromName = firstName => (
  letterColors[firstName.charAt(0).toLowerCase()] || letterColors[0]
)

const Avatar = ({
  id,
  avatar,
  firstName = '',
  size = 38,
  fullWidth,
  withoutLink,
}) => {
  const { toOpen } = useProfileModalUrl()

  const renderAvatar = () => (
    _.get(avatar, 'imageUrl') ? (
      <div
        className="avatar"
        style={fullWidth ? (
          { width: '100%' }
        ) : (
          { width: size, height: size }
        )}
      >
        <img
          className="avatar_img"
          src={getAvatarUrl(_.get(avatar, 'imageUrl'), size >= 120 ? 'large' : 'small')}
          alt={`${firstName}'s avatar`}
        />
      </div>
    ) : (
      <div
        className={classnames('avatar avatar--initials', {
          'avatar--isLarge': size >= 60,
        })}
        style={fullWidth ? (
          { width: '100%', paddingBottom: '100%', borderColor: getColorFromName(firstName) }
        ) : (
          { width: size, height: size, borderColor: getColorFromName(firstName) }
        )}
      >
        <div className="avatar_initials_container">
          <div
            className="avatar_initials"
            style={{ fontSize: Math.round(size / 2.2) }}
          >
            {firstName.charAt(0).toUpperCase()}
          </div>
        </div>
      </div>
    )
  )
  return (
    withoutLink ? (
      renderAvatar()
    ) : (
      <div className="avatar--withLink" onClick={() => toOpen(id)}>
        {renderAvatar()}
      </div>
    )
  )
}

Avatar.propTypes = propTypes

export default Avatar
