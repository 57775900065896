import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import usStates from '@/shared/static/usStates'
import { countryNames } from '@/shared/static/countries'
import * as policies from '@/shared/static/policies'
import { handleError } from '@/shared/api'
import { Form, Field, Button, Input, Select, Checkbox, BirthDateSelects,
  Textarea, Icon, FieldErrors, Modal } from '@/shared/components'
import ProgressBar from './ProgressBar'
import '@/book/styles/guest.scss'

const c = 'book_guest'

const Guest = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const [ 
    property,
    currentUser,
    updateCurrentUser,
    trackEvent,
    warnAndRedirect,
    query,
    isImportingBooking,
    rates
  ] = useOutletContext()

  if (
    /male|female/.test(_.get(property, 'placementType')) &&
    property.placementType !== currentUser.gender
  ) {
    warnAndRedirect(`This is a ${property.placementType} only listing.`)
  }
  const get = key => _.get(currentUser, key) || ''
  return (
    <Form
      enableReinitialize
      initialValues={{
        firstName: get('firstName'),
        lastName: get('lastName'),
        birthDate: get('birthDate'),
        city: get('city'),
        state: get('state'),
        country: get('country'),
        school: get('school'),
        schoolYear: get('schoolYear'),
        major: get('major'),
        internshipEmployer: get('internshipEmployer'),
        bio: get('bio'),
        acceptedRules: false,
      }}
      validations={{
        firstName: Form.is.required(),
        lastName: Form.is.required(),
        birthDate: Form.is.required(),
        city: Form.is.required(),
        country: Form.is.required(),
        school: Form.is.required(),
        schoolYear: Form.is.required(),
        major: Form.is.required(),
        internshipEmployer: Form.is.required(),
        bio: Form.is.required(),
        acceptedRules: Form.is.match(v => !!v, 'Please agree to continue'),
      }}
      onSubmit={(values, form) => {
        updateCurrentUser(values).then(() => {
          trackEvent('Booking Wizard Guest Info Submitted')
          navigate({
            pathname: `${location.pathname.replace('/guest', '')}/payment`,
            search: location.search,
          })
        }, ({ error }) => {
          if (error.details) {
            form.setErrors(error.details)
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit} className={c}>
          <h1 className={`${c}_title`}>Secure Booking</h1>
          <ProgressBar current={2} total={3} />
          <h2 className={`${c}_title_small`}>Guest Information</h2>
          <p className={`${c}_subtitle`}>Please enter your information here.</p>
          <div className={`${c}_fields`}>
            <Field.Input {...fields.firstName} label="First Name" />
            <Field.Input {...fields.lastName} label="Last Name" />
            <BirthDateSelects
              label="Date of Birth"
              value={fields.birthDate.value}
              onChange={value => setFieldValue('birthDate', value)}
              errors={fields.birthDate.errors}
            />
            <p className={`${c}_subtitle`}>
              The following information helps your host and fellow roomies learn a bit about you.
            </p>
            <Field.Input {...fields.city} label="City" />
            <Field.Select
              {...fields.state}
              label="State"
              options={[{ value: '', label: 'Select' }, ...usStates]}
            />
            <Field.Select
              {...fields.country}
              label="Country"
              placeholder="Select"
              options={countryNames}
            />
            <Field.Input {...fields.school} label="School/University Name" />
            <Field.Select
              {...fields.schoolYear}
              label="School Year"
              placeholder="Select"
              options={[
                'Freshman',
                'Sophomore',
                'Junior',
                'Senior',
                'Graduate Student',
                'Graduated',
              ].map(value => ({ value, label: value }))}
            />
            <Field.Input {...fields.major} label="Major Field of Study" />
            <Field.Input {...fields.internshipEmployer} label="Internship/Employer" />
          </div>
          <div className={`${c}_about`}>
            <div className={`${c}_about_textareaContainer`}>
              <Field.Textarea {...fields.bio} label="About Me (Bio)" minRows={7} />
            </div>
            <div className={`${c}_about_tips`}>
              <div className={`${c}_about_tip`}>
                <Icon type="check" />
                <span>Tell your future host and roommates a little about yourself</span>
              </div>
              <div className={`${c}_about_tip`}>
                <Icon type="check" />
                <span>What brings you to this area?</span>
              </div>
              <div className={`${c}_about_tip`}>
                <Icon type="check" />
                <span>Which university are you going to study in?</span>
              </div>
            </div>
          </div>
          <div className={`${c}_acceptRules`}>
            <Checkbox
              label=""
              checked={fields.acceptedRules.value}
              {...fields.acceptedRules}
            />
            <div className={`${c}_acceptRules_label`}>
              {`I agree to the `}
              <Modal
                className="book_infoModal"
                renderLink={() => (
                  <span>House Rules</span>
                )}
                renderContent={() => (
                  <Fragment>
                    <h2 className="book_infoModal_title">House Rules</h2>
                    <p className="book_infoModal_content">
                      {_.get(property, 'rules') || policies.rulesDefault}
                    </p>
                  </Fragment>
                )}
              />
              {`, `}
              <Link to="/cancellation-policy" target="_blank">
                Cancellation Policy
              </Link>
              {`, and to the `}
              <Link to="/guest-refund-policy" target="_blank">
                Guest Refund Policy
              </Link>
              {`. I also agree to pay the total amount shown, which includes Service Fees.`}
            </div>
            <FieldErrors errors={fields.acceptedRules.errors} />
          </div>
          <Button className={`${c}_submit`} type="submit" working={isSubmitting}>
            Continue
          </Button>
        </form>
      )}
    />
  )
}

export default Guest
