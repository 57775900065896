import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@/shared/components'
import '@/shared/styles/emptyState.scss'

const propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  renderButton: PropTypes.func,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
}

const EmptyState = ({ title, message, renderButton, buttonLink, buttonText }) => (
  <div className="emptyState">
    <div className="emptyState_title">{title}</div>
    <p className="emptyState_message">{message}</p>
    {renderButton ? (
      renderButton()
    ) : (
      buttonLink && buttonText && (
        <Link to={buttonLink}>
          <Button size="small">{buttonText}</Button>
        </Link>
      )
    )}
  </div>
)

EmptyState.propTypes = propTypes

export default EmptyState
