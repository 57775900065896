import React, { Component, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { withRouter } from '@/shared/hooks/withRouter'
import { hostLinks, guestLinks } from '@/shared/static/menuLinks'
import { Icon, Media } from '@/shared/components'
import '@/shared/styles/dashboardMenu.scss'

const propTypes = {
  role: PropTypes.string.isRequired,
}

const DashboardMenu = (props) => {
  const location = useLocation()

  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false)

  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen(!isMobileDropdownOpen)
  }

  const closeMobileDropdown = () => {
    setIsMobileDropdownOpen(false)
  }

  const getLinks = () => (
    props.role === 'host' ? hostLinks : guestLinks
  )

  const getCurrentLink = () => (
    getLinks().find(({ to }) => location.pathname.includes(to))
  )

  return (
    <Media query="(max-width: 767px)"
      match={() => (
        <Fragment>
          <div className="dashboardMenuMobile" onClick={() => toggleMobileDropdown()}>
            <Icon type={getCurrentLink().icon} className="dashboardMenuMobile_currentIcon" />
            <span className="dashboardMenuMobile_currentText">{getCurrentLink().text}</span>
            <Icon type="chevron-down-fat" />
          </div>
          {isMobileDropdownOpen && (
            <div className="dashboardMenuMobile_dropdown">
              <Links
                location={location}
                getLinks={getLinks}
                closeMobileDropdown={closeMobileDropdown}
              />
            </div>
          )}
        </Fragment>
      )}
      unmatch={() => (
        <div className="dashboardMenu">
          <Links 
            location={location}
            getLinks={getLinks}
            closeMobileDropdown={closeMobileDropdown}
          />
        </div>
      )}
    />
  )
}

const Links = ({ location, getLinks, closeMobileDropdown }) => (
  <Fragment>
    {getLinks().map(({ to, text, icon }) => (
      <Link
        key={to}
        to={to}
        onClick={() => closeMobileDropdown()}
        className={classnames('dashboardMenu_link', {
          'dashboardMenu_link--isActive': location.pathname.includes(to),
        })}
      >
        <Icon type={icon} />
        <span>{text}</span>
      </Link>
    ))}
  </Fragment>
)

DashboardMenu.propTypes = propTypes

export default withRouter(DashboardMenu)
