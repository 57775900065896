import api from '@/shared/api'

export const createEvent = payload => (
  api.post('/events', payload)
)

export const updateEvent = (eventId, payload) => (
  api.put(`/events/${eventId}`, payload)
)

export const fetchEvents = (query = {}) => (
  api.get('/events', query, {
    abortCurrentRequest: 'fetchEvents',
  })
)

export const fetchEvent = eventId => (
  api.get(`/events/${eventId}`)
)

export const attendEvent = eventId => (
  api.post(`/events/${eventId}/attend`)
)

export const unattendEvent = eventId => (
  api.post(`/events/${eventId}/unattend`)
)

export const cancelEvent = eventId => (
  api.post(`/events/${eventId}/cancel`)
)
