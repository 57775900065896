import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@/shared/components'
import '@/shared/styles/tooltip.scss'

const propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  offset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  windowBounds: PropTypes.shape({
    top: PropTypes.bool,
    right: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
  }),
  attachToLink: PropTypes.bool,
  openOnHover: PropTypes.bool,
  closeOnEscapeKey: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  renderLink: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

const defaultProps = {
  placement: 'right',
  offset: {
    top: 0,
    left: 0,
  },
  windowBounds: {
    top: false,
    right: true,
    bottom: false,
    left: true,
  },
  attachToLink: false,
  openOnHover: false,
  closeOnEscapeKey: true,
  closeOnOutsideClick: true,
  onClose: () => {},
}

const $root = document.getElementById('root')

class Tooltip extends Component {

  state = { isOpen: false }

  componentDidMount() {
    this.linkHoverEventListeners('add')
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      this.setTooltipPosition()
    }
  }

  componentWillUnmount() {
    this.linkHoverEventListeners('remove')
    this.closeTooltipEventListeners('remove')
    this.windowScrollEventListeners('remove')
    this.removeContainer()
  }

  openTooltip() {
    this.insertContainer()
    this.closeTooltipEventListeners('add')
    this.windowScrollEventListeners('add')
    this.setState({ isOpen: true }, this.setTooltipPosition)
  }

  closeTooltip = () => {
    this.closeTooltipEventListeners('remove')
    this.windowScrollEventListeners('remove')
    this.setState({ isOpen: false }, () => {
      this.props.onClose()
      this.removeContainer()
    })
  }

  insertContainer = () => {
    this.$container = document.createElement('span')
    $root.appendChild(this.$container)
  }

  removeContainer = () => {
    if ($root.contains(this.$container)) {
      $root.removeChild(this.$container)
    }
  }

  linkHoverEventListeners(addOrRemove) {
    if (this.props.openOnHover) {
      this.$link[`${addOrRemove}EventListener`]('mouseover', this.onLinkMouseEnter)
      this.$link[`${addOrRemove}EventListener`]('mouseleave', this.onLinkMouseLeave)
    }
  }

  closeTooltipEventListeners(addOrRemove) {
    if (this.props.closeOnEscapeKey) {
      document[`${addOrRemove}EventListener`]('keydown', this.onEscapeKeyDown)
    }
    if (this.props.closeOnOutsideClick) {
      document[`${addOrRemove}EventListener`]('mouseup', this.onOutsideMouseClick)
    }
  }

  windowScrollEventListeners(addOrRemove) {
    if (!this.props.attachToLink) {
      window[`${addOrRemove}EventListener`]('resize', this.setTooltipPosition)
      window[`${addOrRemove}EventListener`]('scroll', this.setTooltipPosition)
    }
  }

  onLinkMouseEnter = () => {
    if (!this.state.isOpen) {
      this.openTooltip()
    }
  }

  onLinkMouseLeave = () => {
    if (this.state.isOpen) {
      this.closeTooltip()
    }
  }

  onEscapeKeyDown = e => {
    if (this.state.isOpen && e.keyCode === 27) {
      this.closeTooltip()
    }
  }

  onOutsideMouseClick = e => {
    if (
      this.state.isOpen &&
      e.button === 0 &&
      this.$tooltip !== e.target &&
      !this.$tooltip.contains(e.target) &&
      !this.$link.contains(e.target)
    ) {
      this.closeTooltip()
    }
  }

  onLinkClick = () => {
    this.state.isOpen ? this.closeTooltip() : this.openTooltip()
  }

  setTooltipPosition = () => {
    const { top, left } = this.calcPosition()
    this.$tooltip.style.top = `${top}px`
    this.$tooltip.style.left = `${left}px`
  }

  calcPosition = () => {
    const { placement, attachToLink } = this.props
    const offset = {
      ...defaultProps.offset,
      ...this.props.offset,
    }
    const margin = 20
    const tooltip = this.$tooltip.getBoundingClientRect()
    const link = this.$link.firstChild.getBoundingClientRect()

    const placements = attachToLink ?
      this.calcAbsolutePlacements(margin, tooltip, link) :
      this.calcFixedPlacements(margin, tooltip, link)

    let position = {
      top: placements[placement].top + offset.top,
      left: placements[placement].left + offset.left,
    }

    if (!attachToLink) {
      position = this.calcPositionWithinWindowBounds(tooltip, position)
    }

    return position
  }

  calcAbsolutePlacements = (margin, tooltip, link) => {
    return {
      top: {
        top: -tooltip.height - margin,
        left: -tooltip.width / 2 + link.width / 2,
      },
      right: {
        top: -tooltip.height / 2 + link.height / 2,
        left: link.width + margin,
      },
      bottom: {
        top: link.height + margin,
        left: -tooltip.width / 2 + link.width / 2,
      },
      left: {
        top: -tooltip.height / 2 + link.height / 2,
        left: -tooltip.width - margin,
      },
    }
  }

  calcFixedPlacements = (margin, tooltip, link) => {
    const linkCenterY = link.top + link.height / 2
    const linkCenterX = link.left + link.width / 2

    return {
      top: {
        top: link.top - margin - tooltip.height,
        left: linkCenterX - tooltip.width / 2,
      },
      right: {
        top: linkCenterY - tooltip.height / 2,
        left: link.right + margin,
      },
      bottom: {
        top: link.bottom + margin,
        left: linkCenterX - tooltip.width / 2,
      },
      left: {
        top: linkCenterY - tooltip.height / 2,
        left: link.left - margin - tooltip.width,
      },
    }
  }

  calcPositionWithinWindowBounds = (tooltip, position) => {
    const windowBounds = {
      ...defaultProps.windowBounds,
      ...this.props.windowBounds,
    }
    const boundPosition = { ...position }

    if (windowBounds.top) {
      if (position.top < 0) {
        boundPosition.top = 0
      }
    }
    if (windowBounds.right) {
      if (position.left + tooltip.width + 25 > window.innerWidth) {
        boundPosition.left = window.innerWidth - tooltip.width - 25
      }
    }
    if (windowBounds.bottom) {
      if (position.top + tooltip.height + 0 > window.innerHeight) {
        boundPosition.top = window.innerHeight - tooltip.height - 0
      }
    }
    if (windowBounds.left) {
      if (position.left < 15) {
        boundPosition.left = 15
      }
    }
    return boundPosition
  }

  renderMobileCloseIcon = () => (
    <div
      className="tooltip_mobileCloseIcon_container"
      onClick={this.closeTooltip}
    >
      Close
      <Icon type="close" className="tooltip_mobileCloseIcon" />
    </div>
  )

  renderTooltip = () => (
    <div
      className={classnames('tooltip_container', this.props.containerClassName)}
      style={{
        position: this.props.attachToLink ? 'absolute' : 'fixed',
        transform: this.props.attachToLink ? 'none' : 'translateZ(0)',
      }}
      ref={el => this.$tooltip = el}
    >
      {this.renderMobileCloseIcon()}
      <div className={classnames('tooltip', this.props.className)}>
        {this.props.renderContent({
          closeTooltip: this.closeTooltip,
        })}
      </div>
    </div>
  )

  createPortal = () => (
    ReactDOM.createPortal(this.renderTooltip(), this.$container)
  )

  render() {
    return (
      this.props.attachToLink ? (
        <span
          className={classnames('tooltip_linkContainer', this.props.linkClassName)}
          style={{ position: 'relative', display: 'inline-block' }}
        >
          <span
            ref={el => this.$link = el}
            onClick={this.onLinkClick}
          >
            {this.props.renderLink()}
          </span>
          {this.state.isOpen && this.renderTooltip()}
        </span>
      ) : (
        <Fragment>
          <span
            className={classnames('tooltip_linkContainer', this.props.linkClassName)}
            ref={el => this.$link = el}
            onClick={this.onLinkClick}
          >
            {this.props.renderLink()}
          </span>
          {this.state.isOpen && this.createPortal()}
        </Fragment>
      )
    )
  }
}

Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default Tooltip
