import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { addToQueryString, queryStringToObject } from '@/shared/utils/url'
import { fetchHost } from '@/search/state/actions'
import Search from './index'

const mapStateToProps = ({ search }) => ({
  host: search.host,
})

const mapDispatchToProps = { fetchHost }

const propTypes = {
  host: PropTypes.object,
  fetchHost: PropTypes.func.isRequired,
}

const HostLoader = (props) => {

  const { host } = props

  const { userId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    props.fetchHost(userId)
  }, [])

  useEffect(() => {
    if (!host || isReady) return

    const queryData = { userId: host.id }

    if (
      host.propertiesBounds &&
      host.propertiesBounds.length &&
      !queryStringToObject(location.search).boundingBox
    ) {
      queryData.boundingBox = host.propertiesBounds
    }
    navigate({
      pathname: location.pathname,
      search: addToQueryString(location.search, queryData),
    })
    setIsReady(true)
  }, [host])
  

  return isReady && <Search {...props} />
}

HostLoader.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(HostLoader)
