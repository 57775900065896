import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import use404 from '@/shared/hooks/use404'
import { handleError } from '@/shared/api'
import * as api from '@/hostMessages/api'
import { Form, PageSpinner, Button, Field, Checkbox, Spinner,
  DashboardMenu, FieldLabel, Head, Icon, AssignProperties } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/hostMessages/styles/form.scss'

const c = 'hostMessagesForm'

const HostMessagesForm = () => {
  const { messageId } = useParams()
  const isEdit = !!messageId

  const navigate = useNavigate()
  const { to404 } = use404()

  const [state, setState] = useState({ message: null, properties: null })

  useEffect(() => {
    analytics.pageview('Host Guest Messaging Scheduled Form', {
      formType: isEdit ? 'Edit' : 'Create',
      messageId: messageId,
    })
    fetchMessage()
    api.fetchProperties().then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        properties: data
      }))
    }, handleError)
  }, [])


  const fetchMessage = () => {
    if (isEdit) {
      api.fetchMessage(messageId).then(({ data }) => {
        setState(prevState => ({
          ...prevState,
          message: data
        }))
      }, () => {
        to404('We could not find this message template.')
      })
    } else {
      setState(prevState => ({
        ...prevState,
        message: {}
      }))
    }
  }

  const handleSubmit = ({ moment, days, ...values }, form) => {
    const payload = {
      ...values,
      days: moment === 'before' ? -days : +days,
    }
    const request = isEdit ?
      api.updateMessage(messageId, payload) :
      api.createMessage(payload)

    request.then(() => {
      navigate('/host/scheduled-messaging')
      const action = isEdit ? 'updated' : 'created'
      showToast({ title: `Your message template was ${action} successfully.` })
    }, ({ error }) => {
      if (error.details) {
        form.setErrors(error.details)
      } else {
        handleError({ error })
      }
      form.setSubmitting(false)
    })
  }

  const getInitialValues = () => {
    const get = (prop, defaultVal = '') => {
      const val = _.get(state.message, prop)
      return _.isNil(val) ? defaultVal : val
    }
    return {
      name: get('name'),
      subject: get('subject'),
      body: get('body'),
      days: Math.abs(get('days', -1)),
      moment: get('days', -1) < 0 ? 'before' : 'after',
      trigger: get('trigger', 'arrival'),
      propertyIds: get('propertyIds', []),
      includeBooking: get('includeBooking', true),
      active: get('active', false),
    }
  }

  return (
    <div className={c}>
      <Head title={`${isEdit ? 'Edit' : 'Create'} Message Template - Roomsie`} />
      <AppHeader />
      <DashboardMenu role="host" />
      {state.message ? (
          <MessageForm 
            isEdit={isEdit}
            properties={state.properties}
            getInitialValues={getInitialValues}
            handleSubmit={handleSubmit}
          /> 
        ) : (
          <PageSpinner /> 
        ) 
      }
      <AppFooter />
    </div>
  )
}

const MessageForm = ({ isEdit, properties, getInitialValues, handleSubmit }) => (
  <Form
    enableReinitialize
    initialValues={getInitialValues()}
    validations={{
      name: [Form.is.required(), Form.is.max(120)],
      subject: [Form.is.required(), Form.is.max(70)],
      body: [Form.is.required(), Form.is.max(10000)],
      days: Form.is.required(),
      trigger: Form.is.required(),
    }}
    onSubmit={handleSubmit}
    render={(props) => (
      <FormContent 
        {...props}
        isEdit={isEdit}
        properties={properties}
      />
    )}
  />
)

const FormContent = ({ properties, isEdit, handleSubmit, isSubmitting, fields, setFieldValue }) => (
  <form noValidate onSubmit={handleSubmit}>
    <div className={`${c}_container--white`}>
      <div className={`${c}_breadcrumbs`}>
        <Link to="/host/scheduled-messaging">Message Templates</Link>
        {` / ${isEdit ? 'Edit Template' : 'Create Template'}`}
      </div>
      <h1 className={`${c}_title`}>
        {`${isEdit ? 'Edit' : 'Create'} Message Template`}
      </h1>
      <div className={`${c}_subtitle`}>
        Create a scheduled message that will automatically be sent to all guests before or after they arrive to selected properties.
      </div>
      <Field.Input {...fields.name} label="Template Name" />
      <div className={`${c}_field_description`}>
        Name is used so you can manage templates more easily. It will not be shown to guests.
      </div>
      <div className={`${c}_section_title`}>
        <Icon type="calendar" />
        Schedule
      </div>
    </div>
    <div className={`${c}_container--grey`}>
      <div className={`${c}_container--grey_inner`}>
        <div className={`${c}_schedule`}>
          <div className={`${c}_schedule_label`}>Send Message</div>
          <Field.Select
            {...fields.days}
            options={_.range(1, 61).map(v => ({ value: v, label: `${_.upperFirst(v)} Days` }))}
          />
          <Field.Select
            {...fields.moment}
            options={['before', 'after'].map(v => ({ value: v, label: _.upperFirst(v) }))}
          />
          <Field.Select
            {...fields.trigger}
            options={['arrival', 'departure'].map(v => ({ value: v, label: `Guest ${_.upperFirst(v)}` }))}
          />
        </div>
      </div>
    </div>
    <div className={`${c}_container--white`}>
      <div className={`${c}_section_title`}>
        <Icon type="home" />
        Properties
      </div>
      <div className={`${c}_properties_description`}>
        Your message will only be sent to guests from selected properties.
      </div>
    </div>
    <div className={`${c}_container--grey`}>
      <div className={`${c}_container--grey_inner`}>
        {properties ? (
          <AssignProperties
            allProperties={properties}
            selectedProperties={fields.propertyIds.value.map(id => (
              properties.find(p => p.id === id)
            )).filter(p => p)}
            onChange={properties => setFieldValue('propertyIds', properties.map(p => p.id))}
          />
        ) : (
          <Spinner size={50} />
        )}
      </div>
    </div>
    <div className={`${c}_container--white`}>
      <Field.Input {...fields.subject} label="Message Subject" />
      <Field.Textarea minRows={5} {...fields.body} label="Message Body" />
      <Checkbox
        {...fields.includeBooking}
        className={`${c}_bookingCheckbox`}
        label="Include booking dates and basic property information at the bottom of the email (recommended)"
        checked={!!fields.includeBooking.value}
      />
      <div className={`${c}_actions`}>
        <Link to="/host/scheduled-messaging">
          <Button hollow type="button">Cancel</Button>
        </Link>
        <Button type="submit" working={isSubmitting}>
          {isEdit ? 'Save Template' : 'Create Template'}
        </Button>
      </div>
    </div>
  </form>
)

export default HostMessagesForm
