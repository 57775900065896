

import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import storage from '@/shared/utils/storage'
import * as url from '@/shared/utils/url'
import { isCurrentUserVerified, isCurrentUserAuthenticated } from '@/features/auth/utils'

const keys = {
  signup: 'auth_signup',
  signin: 'auth_signin',
  resetPassword: 'auth_reset_password',
  createPassword: 'auth_create_password',
  confirm: 'auth_confirm',
}
                             
export default function useAuthUrl() {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const toOpen = key => {
    navigate({
      pathname,
      search: url.addToQueryString(
        search,
        _.mapValues(_.invert(keys), keyVal => (
          key === keyVal ? true : undefined
        ))
      )
    })
  }

  const toClose = key => {
    navigate({
      pathname,
      search: url.omitFromQueryString(search, keys[key])
    })
  }
  
  const isOpen = key => (
    !!url.queryStringToObject(search)[keys[key]]
  )

  const toOpenSignup = () => toOpen('signup')
  const toOpenSignin = () => toOpen('signin')
  const toOpenResetPassword = () => toOpen('resetPassword')
  const toOpenConfirm = () => toOpen('confirm')

  const toCloseSignup = () => toClose('signup')
  const toCloseSignin = () => toClose('signin')
  const toCloseResetPassword = () => toClose('resetPassword')
  const toCloseConfirm = () => toClose('confirm')

  const isOpenSignup = () => isOpen('signup')
  const isOpenSignin = () => isOpen('signin')
  const isOpenResetPassword = () => isOpen('resetPassword')
  const isOpenCreatePassword = () => isOpen('createPassword')
  const isOpenConfirm = () => isOpen('confirm')


  const toOpenCreatePassword = token => {
    navigate({
      pathname: '/',
      search: url.objectToQueryString({
        [keys.createPassword]: true,
        token,
      })
    })
  }

  const createPasswordToSignin = () => {
    navigate({
      pathname,
      search: url.addToQueryString(search, {
        token: undefined,
        [keys.createPassword]: undefined,
        [keys.signin]: true,
      })
    })
  }

  const rootConfirm = `/?${keys.confirm}=true`
  const rootSignin = `/?${keys.signin}=true`

  const toPrivateRoute = location => {
    if (isCurrentUserVerified()) {
      navigate(location)
    } else if (isCurrentUserAuthenticated()) {
      storeAfterAuthUrl(location)
      toOpenConfirm()
    } else {
      storeAfterAuthUrl(location)
      toOpenSignup()
    }
  }

  const toOpenSigninAndReload = location => {
    if (!isCurrentUserAuthenticated()) {
      storeAfterAuthUrl(location)
      storeAfterAuthUrlReload()
      toOpenSignin()
    }
  }

  const toAuth = (options = {}) => {
    if (!isCurrentUserAuthenticated()) {
      options.signIn ? toOpenSignin() : toOpenSignup()
    } else if (!isCurrentUserVerified()) {
      toOpenConfirm()
    }
  }

  const AuthYorself = () => {
    navigate({
      pathname: '/not-found',
      search: objectToQueryString({ message }),
    })
  }

  const storeAfterAuthUrlReload = () => (
    storage.local.set('afterAuthUrlReload', true)
  )

  const storeAfterAuthUrl = to => (
    storage.local.set(
      'afterAuthUrl',
      _.isString(to) ? to : url.joinPathnameSearch(to.pathname, to.search)
    )
  )

  const toAfterAuthUrlIfExists = () => {
    const afterAuthUrl = storage.local.get('afterAuthUrl')
    const afterAuthUrlReload = storage.local.get('afterAuthUrlReload')
    if (afterAuthUrl) {
      storage.local.remove('afterAuthUrl')
      navigate(afterAuthUrl)

      if(afterAuthUrlReload){
        storage.local.remove('afterAuthUrlReload')
        location.reload()
      }
    }
    return !!afterAuthUrl
  }
    
  return { 
    location,
    toOpenSignup,
    toOpenSignin,
    toOpenResetPassword,
    toOpenConfirm,
    toCloseSignup,
    toCloseSignin,
    toCloseResetPassword,
    toCloseConfirm,
    isOpenSignup,
    isOpenSignin,
    isOpenResetPassword,
    isOpenCreatePassword,
    isOpenConfirm,
    toOpenCreatePassword,
    createPasswordToSignin,
    rootConfirm,
    rootSignin,
    toPrivateRoute,
    toOpenSigninAndReload,
    toAuth,
    AuthYorself,
    storeAfterAuthUrlReload,
    storeAfterAuthUrl,
    toAfterAuthUrlIfExists
  }
}
