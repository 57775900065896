import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import '@/shared/styles/checkbox.scss'

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  size: PropTypes.string,
  label: PropTypes.any.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  getRef: PropTypes.func,
}

const defaultProps = {
  onChange: (event, value) => {},
  getRef: (element) => {},
}

class Checkbox extends Component {

  handleChange = e => {
    this.props.onChange(e, e.target.value)
  }

  render() {
    const {
      className,
      size,
      label,
      onChange,
      getRef,
      ...checkboxProps
    } = this.props

    const checkboxId = _.uniqueId('form-checkbox-')

    return (
      <div className={classnames('checkbox', className, {
        [`checkbox--size-${size}`]: size,
      })}>
        <input
          {...checkboxProps}
          type="checkbox"
          id={checkboxId}
          className="checkbox_element"
          onChange={this.handleChange}
          ref={getRef}
        />
        {checkboxProps.disabled ? (
          <label tabIndex="0" htmlFor={checkboxId} className="checkbox_disabled_label">
            {label}
          </label>
        ) : (
          <label tabIndex="0" htmlFor={checkboxId} className="checkbox_label">
            {label}
          </label>
        )}

      </div>
    )
  }
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
