import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import options from '@/shared/static/options'
import { Tooltip, Button, CheckboxGroup, Checkbox, Icon } from '@/shared/components'

const propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilterChanges: PropTypes.func.isRequired,
  cancelFilterChanges: PropTypes.func.isRequired,
  clearFilterSection: PropTypes.func.isRequired,
}

const typeDescriptions = {
  female: 'Female guests only',
  male: 'Male guests only',
  unisex: 'Both female and male guests are welcome',
}

const c = 'search_filters_placement'

const Placement = props => (
  <Tooltip
    className={`search_filters_tooltip ${c}`}
    placement="bottom"
    offset={{ top: -20 }}
    windowBounds={{ bottom: true }}
    onClose={props.cancelFilterChanges}
    renderLink={() => (
      <div className="search_filters_link">
        <Icon type="people" />
        {!props.filters.placementTypes.length ? (
          'Placement'
        ) : (
          props.filters.placementTypes.length === 1 ? (
            `${options.labels.placementTypes[props.filters.placementTypes[0]]}`
          ) : (
            `Placement · ${props.filters.placementTypes.length}`
          )
        )}
      </div>
    )}
    renderContent={({ closeTooltip }) => (
      <Fragment>
        <CheckboxGroup
          className={`${c}_checkboxGroup`}
          value={props.filters.placementTypes}
          onChange={value => {
            props.onFilterChange({ placementTypes: value })
          }}
          render={({ isChecked, onCheck }) => (
            options.placementTypes.map(({ value, label }) => (
              <Fragment key={value}>
                <Checkbox
                  value={value}
                  label={label}
                  checked={isChecked(value)}
                  onChange={onCheck}
                />
                <div className={`${c}_tip`}>
                  {typeDescriptions[value]}
                </div>
              </Fragment>
            ))
          )}
        />
        <Button size="small" onClick={() => {
          props.applyFilterChanges()
          closeTooltip()
        }}>
          Apply
        </Button>
        <Button hollow size="small" color="secondary" onClick={() => {
          props.clearFilterSection('placement')
        }}>
          Clear
        </Button>
      </Fragment>
    )}
  />
)

Placement.propTypes = propTypes

export default Placement
