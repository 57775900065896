import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import LogoBlack from '@/shared/assets/images/LogoBlack.svg'
import { Button, ConfirmModal } from '@/shared/components'
import '@/listSpace/styles/header.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  resetCreateForm: PropTypes.func.isRequired,
}

class Header extends Component {

  renderCreateActions = () => (
    <div className="listSpace_header_right">
      <ConfirmModal
        title="Reset Form"
        message="Are you sure you want to reset the entire form? All data you entered will be lost. This action is permanent and cannot be reversed."
        confirmText="Reset Form"
        onConfirm={this.props.resetCreateForm}
        renderLink={() => (
          <Button hollow size="small">Reset Form</Button>
        )}
      />
      <Link to="/host/properties">
        <Button size="small">Finish Later</Button>
      </Link>
    </div>
  )

  renderEditActions = () => (
    <div className="listSpace_header_right">
      <Link to="/host/properties">
        <Button hollow size="small">Cancel</Button>
      </Link>
      <Button
        size="small"
        working={this.props.isSubmitting}
        onClick={this.props.submitForm}
      >
        Save and Exit
      </Button>
    </div>
  )

  render() {
    const { property, isEdit } = this.props
    return (
      <header className="listSpace_header">
        <div className="listSpace_header_left">
          <Link to="/">
            <img src={LogoBlack} alt="roomsie logo" className="listSpace_header_logo" />
          </Link>
          <div className="listSpace_header_title">
            {`${isEdit ? 'Editing' : 'Listing'}
              ${_.truncate(property.title, { length: 30 })}`}
          </div>
        </div>
        {isEdit ? this.renderEditActions() : this.renderCreateActions()}
      </header>
    )
  }
}

Header.propTypes = propTypes

export default Header
