import React, { Fragment } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { withRouter } from '@/shared/hooks/withRouter'
import * as analytics from '@/shared/utils/analytics'
import useHelpModalUrl from '@/features/helpModal/hooks/useHelpModalUrl'
import { Modal, Button } from '@/shared/components'
import '@/features/helpModal/styles/index.scss'

const HelpModal = () => {
  const { isOpen, toClose } = useHelpModalUrl()
  
  return (
    isOpen() && (
      <Modal
        isOpen
        className="helpModal"
        onOpen={() => analytics.track('Help Modal Opened')}
        onClose={toClose}
        renderContent={() => (
          <Fragment>
            <div className="helpModal_top">
              <h2 className="helpModal_title">Ask us anything</h2>
              <div className="helpModal_subtitle">Call Us</div>
              <div className="helpModal_description">
                Our phone line is open during working hours.
              </div>
              <a className="helpModal_phone" href="tel:+12024102038">
                +1 (202) 410-2038
              </a>
              <div className="helpModal_subtitle">Chat with us</div>
              <div className="helpModal_description">
                Our live chat is open during working hours.
              </div>
              <Button size="small" onClick={() => {
                if (window.zE) window.zE.activate()
              }}>
                Open Chat
              </Button>
              <div className="helpModal_subtitle">Email us</div>
              <div className="helpModal_description">
                {"Contact us anytime and we'll get back to you quickly."}
              </div>
              <a className="helpModal_email" href="mailto:support@roomsie.com">
                support@roomsie.com
              </a>
            </div>
            <div className="helpModal_bottom">
              <h2 className="helpModal_title">
                Not quite ready to talk to someone?
              </h2>
              <p className="helpModal_paragraph">
                {"Check out our "}
                <a href="https://roomsie.zendesk.com" target="_blank">
                  Help Center & FAQ
                </a>
                {"."}
              </p>
            </div>
          </Fragment>
        )}
      />
    )
  )
}

export default withRouter(HelpModal)
