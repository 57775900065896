import api from '@/shared/api'

export const fetchProperties = query => (
  api.get('/host/properties', query)
)

export const fetchProperty = propertyId => (
  api.get(`/host/properties/${propertyId}`)
)

export const pauseListing = (listingId, query) => (
  api.patch(`/host/listings/${listingId}/paused`, query)
)

export const cloneProperty = propertyId => (
  api.post(`/host/properties/${propertyId}/clone`)
)

export const deleteProperty = propertyId => (
  api.delete(`/host/properties/${propertyId}`)
)
