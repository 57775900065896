import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '@/shared/styles/fieldLabel.scss'

const propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  muted: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

const FieldLabel = ({
  className,
  large,
  muted,
  children,
}) => (
  <div className={classnames(
      'fieldLabel', {
        'fieldLabel--large': large,
        'fieldLabel--muted': muted,
      },
      className,
  )}>
    {children}
  </div>
)

FieldLabel.propTypes = propTypes

export default FieldLabel
