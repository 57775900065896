import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { loadPhoto, getPhotoPreview } from '@/shared/utils/photos'
import { fetchSignedUrls, uploadPhotoToStorage } from '@/shared/api/photos'
import { Button, Photo } from '@/shared/components'

const propTypes = {
  photo: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

const c = 'eventsForm_photoUploader'

class EventPhotoUploader extends Component {

  state = { isUploading: null }

  componentWillUnmount() {
    if (this.promise) this.promise.cancel()
  }

  onDrop = files => {
    this.setState({ isUploading: true })
    fetchSignedUrls(files).then(() => {
      getPhotoPreview(files[0], (preview, { width, height }) => {

        this.promise = uploadPhotoToStorage(files[0])
        this.promise.promise.then(() => {
          loadPhoto(files[0].imageUrl, () => {
            this.props.onChange({
              imageUrl: files[0].imageUrl,
              width,
              height,
            })
            this.setState({ isUploading: false })
          })
        }, () => {})
      })
    })
  }

  render() {
    const { photo } = this.props
    const { isUploading } = this.state
    return (
      <Photo photo={photo} size="medium" className={c}>
        <Dropzone
          className={`${c}_dropzone`}
          activeClassName={`${c}_dropzone--isActive`}
          maxSize={10000000}
          multiple={false}
          accept="image/jpeg, image/png, image/gif"
          onDrop={this.onDrop}
          disabled={isUploading}
        >
          <Button size="small" type="button" working={isUploading}>
            {isUploading ? 'Uploading Photo...' : 'Upload Cover Photo'}
          </Button>
        </Dropzone>
      </Photo>
    )
  }
}

EventPhotoUploader.propTypes = propTypes

export default EventPhotoUploader
