import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import { formatDate } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { sortPhotos } from '@/shared/utils/photos'
import { Icon, Photo, BookingFeesGuest } from '@/shared/components'
import '@/book/styles/details.scss'

const propTypes = {
  listingId: PropTypes.any.isRequired,
  property: PropTypes.object,
  rates: PropTypes.object,
  query: PropTypes.object.isRequired,
}

const c = 'book_details'

class Details extends Component {

  getBedroomAndBed = () => {
    const findBed = bedroom => bedroom.beds.find(bed => (
      bed.listing.id === +this.props.listingId
    ))
    const bedroom = this.props.property.bedrooms.find(findBed)
    const bed = findBed(bedroom)
    return { bedroom, bed }
  }

  getListingImage = property => {
    const isEntireSpace = property.listingType === 'entire_space'
    const { photos } = isEntireSpace ? property : this.getBedroomAndBed().bedroom
    return sortPhotos(photos)[0]
  }

  renderListingLabel = property => {
    if (property.listingType === 'entire_space') {
      return <div className={`${c}_listingLabel`}>Entire Space</div>
    } else {
      const { bed } = this.getBedroomAndBed()
      return (
        <div className={`${c}_listingLabel`}>
          {`${options.labels.privacyTypes[bed.listing.privacyType]} `}
          &#183;
          {` ${options.labels.bedTypes[bed.kind]} `}
          &#183;
          {` ${options.labels.bedSizes[bed.size]} `}
          &#183;
          {` ${options.labels.placementTypes[property.placementType]} placement`}
        </div>
      )
    }
  }

  renderListingTitle = property => (
    <div className={`${c}_title`}>
      {property.listingType === 'entire_space' ? (
        property.title
      ) : (
        `${property.title} — ${this.getBedroomAndBed().bedroom.title}`
      )}
    </div>
  )

  renderCharges = ({ charges }) => {
    if (!charges.length) return null
    return charges.length === 1 ? (
      <p className={`${c}_charges`}>
        {'If the host accepts your booking request you will be charged '}
        <span>{formatPrice(charges[0].total, { precise: true })}</span>
        {' immediately.'}
      </p>
    ) : (
      <p className={`${c}_charges`}>
        {'If the host accepts your booking request you will be charged '}
        <span>{formatPrice(charges[0].total, { precise: true })}</span>
        {' immediately and '}
        <span>{formatPrice(charges[1].total, { precise: true })}</span>
        {` on ${formatDate(charges[1].date)} before you arrive.`}
      </p>
    )
  }

  render() {
    const { property, rates, query } = this.props
    return property && rates && (
      <div className={c}>
        <Photo photo={this.getListingImage(property)} />
        <div className={`${c}_content`}>
          {query.programSessionId && (
            <div className={`${c}_programSessionName`}>
              {query.programSessionName}
            </div>
          )}
          {this.renderListingLabel(property)}
          {this.renderListingTitle(property)}
          <div className={`${c}_address`}>
            {`${property.neighborhood}, ${property.city}`}
          </div>
          <div className={`${c}_dates`}>
            <div>
              <div className={`${c}_dates_label`}>Arrive on</div>
              <div className={`${c}_dates_date`}>{formatDate(query.startDate)}</div>
            </div>
            <Icon type="chevron-right" />
            <div>
              <div className={`${c}_dates_label`}>Depart on</div>
              <div className={`${c}_dates_date`}>{formatDate(query.endDate)}</div>
            </div>
          </div>
          <BookingFeesGuest rates={rates} />
          {this.renderCharges(rates)}
          <div className={`${c}_cancellation`}>
            <Link to="/cancellation-policy" target="_blank">
              {'Read our '}
              <span>Cancellation Policy</span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

Details.propTypes = propTypes

export default Details
