import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import googleMapsApiLoader from '@/shared/hocs/googleMapsApiLoader'
import { getDefaultMapOptions } from '@/shared/utils/map'
import { isElementInViewport } from '@/shared/utils/dom'
import colors from '@/shared/static/colors'
import spots from '@/shared/static/spots'
import { Button, Icon } from '@/shared/components'
import '@/property/styles/location.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  google: PropTypes.object.isRequired,
}

const c = 'property_location'
export const locationClassname = c
export const locationMapClassname = `${c}_map`

class Location extends Component {

  state = {
    travelMode: 'TRANSIT',
    directions: null,
  }

  componentDidMount() {
    const { google, property } = this.props

    this.map = new google.maps.Map(this.$mapContainer, {
      ...getDefaultMapOptions(google),
      center: property.coordinates,
      zoom: 14,
      maxZoom: 16,
    })
    this.drawProperty()

    window.addEventListener('scroll', this.initializeMapFeatures)
    this.initializeMapFeatures()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.initializeMapFeatures)
  }

  initializeMapFeatures = () => {
    if (isElementInViewport(this.$mapContainer, -400)) {
      window.removeEventListener('scroll', this.initializeMapFeatures)
      this.drawSpots()
      this.initializeDirections()
    }
  }

  drawProperty = () => {
    new this.props.google.maps.Circle({
      map: this.map,
      center: this.props.property.coordinates,
      radius: 200,
      strokeColor: colors.blue,
      strokeOpacity: 1,
      strokeWeight: 1.5,
      fillColor: colors.blue,
      fillOpacity: 0.25,
    })
  }

  drawSpots = () => {
    const infoWindow = new this.props.google.maps.InfoWindow({
      maxWidth: 220,
    })
    this.map.addListener('click', () => {
      infoWindow.close()
    })
    spots.forEach(spot => {
      const marker = new this.props.google.maps.Marker({
        map: this.map,
        position: spot.center,
        icon: {
          path: this.props.google.maps.SymbolPath.CIRCLE,
          scale: 4,
          fillColor: colors.text,
          fillOpacity: 0.6,
          strokeColor: colors.text,
          strokeWeight: 1,
        },
      })
      marker.addListener('click', e => {
        infoWindow.setContent(`
          <div style="padding: 10px 0 5px 4px;">
            ${spot.imageUrl ? `<img src=${spot.imageUrl} style="width: 100%;padding: 0 0 8px;"/>` : ''}
            <h3 style="padding: 0 0 4px;font-size: 16px;">${spot.title}</h3>
            <div style="font-size: 14px;font-weight: 400;">${spot.address}</div>
          </div>
        `)
        infoWindow.open(this.map, marker)
        this.showDirections(e)
      })
    })
  }

  initializeDirections = () => {
    this.directionsService = new this.props.google.maps.DirectionsService()
    this.directionsRenderer = new this.props.google.maps.DirectionsRenderer({
      preserveViewport: true,
      suppressInfoWindows: true,
      suppressBicyclingLayer: true,
    })
    this.map.addListener('click', this.showDirections)
  }

  showDirections = ({ latLng }) => {
    if (!this.state.travelMode) return

    this.directionsService.route({
      origin: this.props.property.coordinates,
      destination: latLng,
      travelMode: this.state.travelMode,
      transitOptions: {
        departureTime: moment().add(1, 'w').startOf('isoWeek').add(14, 'h').toDate(),
      },
    }, (response, status) => {
      if (status === 'OK') {
        this.setState({ directions: response }, () => {
          this.directionsRenderer.setMap(this.map)
          this.directionsRenderer.setDirections(response)
        })
      }
    })
  }

  handleTravelModeChange = travelMode => {
    if (this.state.travelMode !== travelMode) {
      this.setState({ travelMode }, () => {
        if (this.state.directions) {
          this.showDirections({
            latLng: this.state.directions.request.destination.location,
          })
        }
      })
    } else {
      this.setState({ travelMode: null, directions: null })
      this.directionsRenderer.setMap(null)
    }
  }

  renderTravelModeButton = (travelMode, label) => (
    <span
      className={classnames(`${c}_directions_button`, {
        [`${c}_directions_button--isActive`]: this.state.travelMode === travelMode,
      })}
      onClick={() => this.handleTravelModeChange(travelMode)}
    >
      <Icon type={`transport-${travelMode.toLowerCase()}`} />
      <div>{label}</div>
    </span>
  )

  render() {
    const { property } = this.props
    const route = _.get(this.state.directions, 'routes[0]')
    const trip = _.get(route, 'legs[0]')
    return (
      <div className={c}>
        <div className="property_container">
          <h2 className="property_section_title">Location</h2>
          <p className={`${c}_description`}>
            <span>{'Neighborhood: '}</span>
            {`${property.neighborhood}, ${property.city}`}
          </p>
          {!!property.closestStationDistance && !!property.closestStationName && (
            <p className={`${c}_description`}>
              <span>{'Public Transport: '}</span>
              {`${_.round(property.closestStationDistance, 2)} Miles to ${property.closestStationName}`}
            </p>
          )}
          <div className={`${c}_directions`}>
            <div className={`${c}_directions_label`}>
              {!this.state.travelMode ? (
                'Calculate commute time:'
              ) : (
                'Click anywhere on the map to calculate commute time'
              )}
            </div>
            <div>
              {this.renderTravelModeButton('TRANSIT', 'Public Transit')}
              {this.renderTravelModeButton('DRIVING', 'Driving')}
              {this.renderTravelModeButton('BICYCLING', 'Cycling')}
              {this.renderTravelModeButton('WALKING', 'Walking')}
            </div>
            {trip && (
              <div className={`${c}_directions_result`}>
                {`You will commute for ${trip.duration.text} over ${trip.distance.text.replace('mi', 'miles')}.`}
                <div>{`${route.copyrights}. ${route.warnings.length ? route.warnings[0] : ''}`}</div>
              </div>
            )}
          </div>
        </div>
        <div className={locationMapClassname} ref={el => this.$mapContainer = el} />
      </div>
    )
  }
}

Location.propTypes = propTypes

export default googleMapsApiLoader(Location)
