import React, { Component } from 'react'
import pubsub from 'sweet-pubsub'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import _ from 'lodash'
import { Icon } from '@/shared/components'
import '@/global/styles/toast.scss'

class Toast extends Component {

  topMargin = 0
  state = { toasts: [] }

  componentDidMount() {
    pubsub.on('toast', this.addToast)
  }

  componentWillUnmount() {
    pubsub.off('toast', this.addToast)
  }

  setAndCalcOffsets = () => {
    const toasts = this.$container.querySelectorAll('.toast')
    return _.reduce(toasts, (sum, el) => {
      el.style.top = `${sum}px`
      return sum + el.offsetHeight + 2
    }, this.topMargin)
  }

  addToast = ({
    type = 'primary',
    title,
    message,
    confirmText,
    cancelText,
    onConfirm = () => {},
    onCancel = () => {},
    duration,
    removeOthers = false,
  }) => {
    const toasts = removeOthers ? [] : this.state.toasts
    const offset = removeOthers ? this.topMargin : this.setAndCalcOffsets()
    const id = _.uniqueId('toast-')
    duration = _.isNumber(duration) ? duration : (type === 'primary' ? 3 : 10)

    this.setState({
      toasts: [
        ...toasts, {
          id,
          type,
          title,
          message,
          confirmText,
          cancelText,
          onConfirm,
          onCancel,
          offset,
        }
      ]
    })

    if (duration) {
      setTimeout(() => this.removeToast(id), duration * 1000)
    }
  }

  removeToast = id => {
    this.setState({
      toasts: _.reject(this.state.toasts, { id })
    }, this.setAndCalcOffsets)
  }

  confirm = ({ id, onConfirm }) => {
    onConfirm()
    this.removeToast(id)
  }

  cancel = ({ id, onCancel }) => {
    onCancel()
    this.removeToast(id)
  }

  render() {
    return (
      <span ref={el => this.$container = el}>
        <TransitionGroup>
          {this.state.toasts.map(toast => (
            <CSSTransition
              key={toast.id}
              classNames="toast"
              timeout={200}
              onClick={() => this.removeToast(toast.id)}
            >
              <div
                key={toast.id}
                className={`toast toast--${toast.type}`}
                style={{ top: toast.offset }}
              >
                <Icon type="close" className="toast_close" />
                {toast.title && (
                  <div className="toast_title">{toast.title}</div>
                )}
                {toast.message && (
                  <div className="toast_message">
                    {_.isArray(toast.message) ? (
                      toast.message.map(msg => <div key={msg}>{msg}</div>)
                    ) : (
                      toast.message
                    )}
                  </div>
                )}
                {(toast.cancelText || toast.confirmText) && (
                  <div className="toast_actions">
                    {toast.cancelText && (
                      <span
                        className="toast_cancel"
                        onClick={() => this.cancel(toast)}
                      >
                        {toast.cancelText}
                      </span>
                    )}
                    {toast.confirmText && (
                      <span
                        className="toast_confirm"
                        onClick={() => this.confirm(toast)}
                      >
                        {toast.confirmText}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </span>
    )
  }
}

export default Toast
