import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import classnames from 'classnames'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { formatDate, dateToString, getTimeDifference } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { formatAddress } from '@/shared/utils/geo'
import { handleError } from '@/shared/api'
import { fetchBookings, fetchPrograms } from '@/hostBookings/api'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import { DashboardMenu, Input, Select, Button, Icon, DateRangePicker, EmptyState,
  Pagination, PageSpinner, SortChevron, Media, Head } from '@/shared/components'
import '@/hostBookings/styles/bookings.scss'

const c = 'hostBookings'

class Bookings extends Component {

  fetchCount = 0

  getInitialQuery = () => ({
    page: 1,
    query: null,
    sort: 'status',
    status: null,
    startDate: null,
    endDate: null,
    programId: null,
  })

  state = {
    bookings: [],
    pagination: null,
    isFetching: true,
    programs: [],
    query: this.getInitialQuery(),
  }

  componentDidMount() {
    analytics.pageview('Host Bookings')
    this.fetchBookings()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.query.page !== prevState.query.page) {
      window.scrollTo(0, 140)
    }
  }

  fetchBookings = (query = {}) => {
    if (!!query.query) {
      query.query = query.query.trim()
    }
    this.fetchCount += 1
    query = {
      ...this.state.query,
      ...query,
      sort: query.sort || 'status',
      page: query.page || 1,
    }
    this.setState({
      query,
      isFetching: true,
    })
    fetchBookings(query).then(({ data, meta }) => {
      this.setState({
        bookings: data,
        pagination: meta,
        isFetching: false,
      })
    }, handleError)
  }

  fetchPrograms = () => {
    if (this.fetchedPrograms) return
    this.fetchedPrograms = true
    fetchPrograms().then(({ data }) => this.setState({ programs: data }))
  }

  daysToDepositRelease = booking => (
    Math.max(0, 45 - getTimeDifference(moment(), booking.endDate))
  )

  hoursToExpiration = booking => (
    Math.max(0, 48 - getTimeDifference(moment(), booking.createdAt, 'hours'))
  )

  renderPage = () => {
    const hasActiveFilters = !_.isEqual(this.state.query, this.getInitialQuery())

    if (this.state.bookings.length || hasActiveFilters || this.fetchCount > 1) {
      return this.renderPageContent(this.state)
    }
    if (this.state.isFetching) {
      return <PageSpinner />
    }
    return (
      <EmptyState
        title="You do not have any booking requests yet."
        message="All incoming booking requests will be displayed here. You will be able to accept/reject them, exchange messages with potential guests, and more."
        buttonText="Manage your properties"
        buttonLink="/host/properties"
      />
    )
  }

  renderPageContent = state => (
    <Fragment>
      <div className={`${c}_top`}>
        <h1>My Bookings</h1>
        <Link to="/host/bookings/calendar">
          <Media query="(max-width: 767px)"
            match={() => <Button hollow size="small">Calendar</Button>}
            unmatch={() => <Button hollow size="small" icon="calendar">Guest Calendar</Button>}
          />
        </Link>
      </div>
      {this.renderFilters()}
      {this.renderBookings(state)}
    </Fragment>
  )

  renderFilters = () => (
    <div className={`${c}_filters_container`}>
      <div className={classnames(`${c}_filters`, {
        [`${c}_filters--areMobileFiltersOpen`]: this.state.areMobileFiltersOpen,
      })}>
        <div className={`${c}_filters_inner`}>
          <div className={`${c}_filters_item`}>
            <Select
              size="small"
              options={[
                { value: '', label: 'All Bookings' },
                ...options.bookingStatuses,
                { value: 'claimed', label: 'Claimed' },
                { value: 'unclaimed', label: 'Unclaimed' },
              ]}
              value={(
                this.state.query.claimed === false ? 'unclaimed' :
                this.state.query.claimed === true ? 'claimed' :
                (this.state.query.status || '')
              )}
              onChange={(e, value) => {
                const status = value || null
                this.fetchBookings({
                  status: /claimed/.test(status) ? '' : status,
                  claimed: (
                    status === 'unclaimed' ? false :
                    status === 'claimed' ? true :
                    undefined
                  ),
                })
              }}
            />
            {!this.state.areMobileFiltersOpen && (
              <div
                className={`${c}_filters_showMoreMobile`}
                onClick={() => this.setState({ areMobileFiltersOpen: true })}
              >
                Show more filters
              </div>
            )}
          </div>
          <div className={`${c}_filters_item`}>
            <DateRangePicker
              placeholder="Filter bookings by date"
              inputProps={{ size: 'small' }}
              minimumStayDays={0}
              disablePastDays={false}
              onChange={dates => this.fetchBookings({
                startDate: dateToString(dates.from),
                endDate: dateToString(dates.to),
              })}
            />
          </div>
          <div className={`${c}_filters_item`}>
            <Select
              size="small"
              options={[
                { label: 'Select Partner', value: '' },
                ...this.state.programs.map(p => ({ label: p.title, value: p.id })),
              ]}
              value={this.state.query.programId || ''}
              onChange={(e, value) => this.fetchBookings({ programId: value || null })}
              onClick={this.fetchPrograms}
            />
          </div>
          <div className={`${c}_filters_item`}>
            <Input
              size="small"
              icon="search"
              placeholder="Search properties, guests"
              onChange={_.debounce((e, query) => this.fetchBookings({ query }), 700)}
            />
          </div>
        </div>
      </div>
    </div>
  )

  renderBookings = ({ bookings, pagination, isFetching, query }) => (
    <div className={classnames(`${c}_list_container`, {
      [`${c}_list_container--isFetching`]: isFetching,
    })}>
      {!bookings.length && isFetching && <PageSpinner />}
      {!bookings.length && !isFetching && (
        <div className={`${c}_list_empty`}>
          {`We could not find any
            ${query.status ? options.labels.bookingStatuses[query.status].toLowerCase() : ''}
            bookings.`}
        </div>
      )}
      {!!bookings.length && (
        <Fragment>
          <div className={`${c}_list_columnLabels`}>
            <div>Guest</div>
            <div>Unit</div>
            <div>Property</div>
            <div>
              <SortChevron
                label="Submitted"
                field="created_at"
                current={this.state.query.sort}
                onChange={sort => this.fetchBookings({ sort })}
              />
            </div>
            <div>
              <SortChevron
                label="Dates of stay"
                field="start_date"
                current={this.state.query.sort}
                onChange={sort => this.fetchBookings({ sort })}
              />
            </div>
            <div>
              <SortChevron
                label="Payout"
                field="host_payout"
                current={this.state.query.sort}
                onChange={sort => this.fetchBookings({ sort })}
              />
            </div>
          </div>
          <div className={`${c}_list`}>
            {bookings.map(this.renderBooking)}
          </div>
          <Pagination
            onChange={page => this.fetchBookings({ page })}
            {...pagination}
          />
        </Fragment>
      )}
    </div>
  )

  renderBooking = ({ program, propertySnapshot, bed, guest, ...booking }) => (
    <Link
      to={`/host/bookings/${booking.id}`}
      key={booking.id}
      className={classnames(`${c}_booking`, `${c}_booking--status-${booking.status}`)}
    >
      <div className={`${c}_booking_status`}>
        {/pending|review/.test(booking.status) && (
          <Icon type="warning-circle" />
        )}
        {`${options.labels.bookingStatuses[booking.status]} | #${booking.id}`}
        {booking.status === 'pending' && (
          <span>{` | ${this.hoursToExpiration(booking)} hours left`}</span>
        )}
        {booking.status === 'review' && (
          <span>{` | ${this.daysToDepositRelease(booking)} days left`}</span>
        )}
      </div>
      <div className={`${c}_booking_inner`}>
        <div>
          <span><strong>{`${guest.firstName} ${guest.lastName}`}</strong></span>
          {/import|sponsor/.test(booking.kind) && (
            <span>{guest.claimed ? 'Claimed' : 'Unclaimed'}</span>
          )}
          {program && program.title && <span>{program.title}</span>}
        </div>
        <div>
          <div>Unit</div>
          <span>{!bed ? 'Entire Space' : (
            `${options.labels.bedSizes[propertySnapshot.bedSize]}
             ${options.labels.bedTypes[propertySnapshot.bedKind]} Bed in
             ${propertySnapshot.bedroomTitle}`
          )}</span>
        </div>
        <div>
          <div>Property</div>
          <span><strong>{_.truncate(propertySnapshot.title, { length: 30 })}</strong></span>
          <span>{propertySnapshot.streetAddress} <br /> {propertySnapshot.streetAddress2}</span>
        </div>
        <div>
          <div>Submitted</div>
          <span>{formatDate(booking.createdAt)}</span>
        </div>
        <div>
          <div>Dates of stay</div>
          <span>
            <strong>
              {formatDate(booking.startDate)}
              <br/>
              {formatDate(booking.endDate)}
            </strong>
          </span>
          <span>{`${booking.stayLength} Days`}</span>
        </div>
        <div>
          <div>Payout</div>
          <span>{formatPrice(booking.hostPayout)}</span>
          <span>{formatPrice(booking.depositAmount)}</span>
        </div>
      </div>
    </Link>
  )

  render() {
    return (
      <div className={c}>
        <Head title="Bookings - Roomsie" />
        <AppHeader />
        <DashboardMenu role="host" />
        {this.renderPage()}
        <AppFooter />
      </div>
    )
  }
}

export default Bookings
