import React, { Component, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleError } from '@/shared/api'
import { withRouter } from '@/shared/hooks/withRouter'
import * as analytics from '@/shared/utils/analytics'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { signIn, signInOauth } from '@/features/auth/state/actions'
import { Modal, Form, Button, Field, Checkbox } from '@/shared/components'
import googleLogo from '@/features/auth/assets/googleLogo.png'
import '@/features/auth/styles/authModal.scss'
import '@/features/auth/styles/signin.scss'

const mapDispatchToProps = { signIn, signInOauth }

const propTypes = {
  signIn: PropTypes.func.isRequired,
  signInOauth: PropTypes.func.isRequired,
}

const Signin = (props) => {
  const { isOpenSignin, toAfterAuthUrlIfExists, toCloseSignin, toOpenResetPassword, toOpenSignup } = useAuthUrl()

  const [oauthSubmitting, setOauthSubmitting] = useState(false)

  const handleSigninOauth = provider => {
    props.signInOauth({
      provider,
      setSubmitting: value => {
        setOauthSubmitting(value)
      },
      onSuccess: () => {
        setOauthSubmitting(false)
        if (!toAfterAuthUrlIfExists()) {
          toCloseSignin()
        }
      },
    })
  }

  return (
    isOpenSignin() && (
      <Modal
        isOpen
        containsForm
        onOpen={() => analytics.track('Sign In Modal Opened')}
        onClose={() => {
          analytics.track('Sign In Modal Closed')
          toCloseSignin()
        }}
        className="authModal signin"
        renderContent={() => ( 
          <SignInForm 
            oauthSubmitting={oauthSubmitting} 
            handleSigninOauth={handleSigninOauth}
            toOpenResetPassword={toOpenResetPassword} 
            toOpenSignup={toOpenSignup} 
            toAfterAuthUrlIfExists={toAfterAuthUrlIfExists}
            signIn={props.signIn} 
            toCloseSignin={toCloseSignin}
          />
        )}
      />
    )
  )
}

const SignInForm = ({ 
  oauthSubmitting, 
  handleSigninOauth, 
  toOpenResetPassword, 
  toOpenSignup, 
  toAfterAuthUrlIfExists, 
  signIn, 
  toCloseSignin 
}) => (
  <Form
    initialValues={{
      email: '',
      password: '',
      rememberMe: true,
    }}
    validations={{
      email: [Form.is.required(), Form.is.email()],
      password: [Form.is.required(), Form.is.min(8)],
    }}
    onSubmit={(values, form) => {
      signIn(values).then(() => {
        if (!toAfterAuthUrlIfExists()) {
          toCloseSignin()
        }
      }, ({ error }) => {
        if (error.code === 'not_found') {
          form.setErrors({ email: true, password: 'Could not find user with provided email and password' })
        } else if (error.details) {
          form.setErrors(error.details)
        } else {
          handleError({ error })
        }
        form.setSubmitting(false)
      })
    }}
    render={({ handleSubmit, isSubmitting, fields }) => (
      <Fragment>
        <div className="authModal_inner">
          <div className="authModal_title">
            Sign In to Continue
          </div>
          <Button
            className="authModal_facebook"
            icon="facebook"
            working={oauthSubmitting === 'facebook'}
            onClick={() => handleSigninOauth('facebook')}
          >
            Through Facebook
          </Button>
          <Button
            hollow
            className="authModal_google"
            icon={<img src={googleLogo} />}
            working={oauthSubmitting === 'google'}
            onClick={() => handleSigninOauth('google')}
          >
            Through Google
          </Button>
          <div className="authModal_divider">
            OR
          </div>
          <div className="authModal_subtitle">
            Sign in with Email
          </div>
          <form noValidate onSubmit={handleSubmit}>
            <Field.Input
              {...fields.email}
              placeholder="Email Address"
              autoComplete="username"
              type="email"
              icon="email"
            />
            <Field.Input
              {...fields.password}
              type="password"
              autoComplete="current-password"
              spellCheck="false"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder="Password"
              icon="lock"
            />
            <div className="signin_clearfix">
              <div className="signin_rememberMe">
                <Checkbox
                  {...fields.rememberMe}
                  checked={fields.rememberMe.value}
                  size="small"
                  label="Remember me"
                />
              </div>
              <span
                className="signin_resetPassword"
                onClick={() => toOpenResetPassword()}
              >
                Forgot password?
              </span>
            </div>
            <Button type="submit" working={isSubmitting} className="authModal_submit">
              Sign In Now
            </Button>
          </form>
        </div>
        <div className="authModal_bottom">
          Don't have an account?
          <span
            className="authModal_bottom_link"
            onClick={() => toOpenSignup()}
          >
            Sign Up
          </span>
        </div>
      </Fragment>
    )}
  />
)

Signin.propTypes = propTypes

export default withRouter(connect(null, mapDispatchToProps)(Signin))
