import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'
import _ from 'lodash'
import media from '@/shared/utils/media'
import { formatDateRange } from '@/shared/utils/dateTime'
import { Input, Tooltip, Icon, Button, FieldErrors } from './'
import 'react-day-picker/lib/style.css'
import '@/shared/styles/datePicker.scss'
import '@/shared/styles/dateRangePicker.scss'

const propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  renderAnchor: PropTypes.func,
  defaultValue: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
  value: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
  minimumStayDays: PropTypes.number,
  disabledDays: PropTypes.array,
  disablePastDays: PropTypes.bool,
  confirmText: PropTypes.string,
  closeOnPick: PropTypes.bool,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  getTooltip: PropTypes.func,
}

const defaultProps = {
  placement: 'bottom',
  placeholder: 'Select dates',
  inputProps: {},
  tooltipProps: {},
  minimumStayDays: 7,
  disabledDays: [],
  disablePastDays: true,
  confirmText: 'Okay',
  closeOnPick: true,
  onChange: (value) => {},
  onConfirm: () => {},
  onClose: () => {},
  getTooltip: () => {},
}

class DateRangePicker extends Component {

  constructor(props) {
    super(props)
    const value = props.value || props.defaultValue || this.getInitialValue()
    this.isControlled = !_.isUndefined(props.value)
    this.state = {
      errors: null,
      value,
      invalidSelectionDays: this.getInvalidSelectionDays(value),
    }
  }

  componentDidMount() {
    this.props.getTooltip(this.tooltip)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.disabledDays !== this.props.disabledDays
    ) {
      this.setState({
        invalidSelectionDays: this.getInvalidSelectionDays(this.getValue()),
      })
    }
  }

  getInitialValue = () => ({ from: null, to: null })

  getValue = () => this.isControlled ? this.props.value : this.state.value

  getInvalidSelectionDays = value => {
    if (!value.from) return []
    const invalidSelectionDays = []
    _.concat(
      this.props.disabledDays.filter(d => d.to).map(d => d.to),
      this.props.disabledDays.filter(_.isDate)
    ).forEach(date => {
      invalidSelectionDays.push({
        [DateUtils.isDayBefore(date, value.from) ? 'before' : 'after']: date,
      })
    })
    return invalidSelectionDays
  }

  getDisabledDays = () => {
    const disabledDays = [
      ...this.props.disabledDays,
      ...this.state.invalidSelectionDays,
    ]
    if (this.props.disablePastDays) {
      disabledDays.unshift({ before: new Date() })
    }
    let filteredAfterDates = disabledDays.map(date => {
        return moment(date["after"])
    });
    let filteredDisabledDays = disabledDays.map(date => {
      if( date.hasOwnProperty("after")) {
        if (moment(date["after"]).valueOf() === moment.max(filteredAfterDates).valueOf())
          return { after: date["after"] }
      } else {
        return date
      }
    });
    return filteredDisabledDays
  }

  handleDayClick = (day, { disabled }) => {
    if (disabled) return
    this.handleChange(this.addDayToRange(day, this.getValue()))
  }

  addDayToRange = (day, range) => {
    if (!range.from) {
      return { from: day, to: null }
    } else if (!range.to) {
      if (moment(day).isSameOrAfter(range.from)) {
        return { from: range.from, to: day }
      } else {
        return { from: day, to: range.from }
      }
    }
    if (moment(day).isSameOrBefore(range.from)) {
      return { from: day, to: range.to }
    } else if (moment(day).isSameOrAfter(range.to)) {
      return { from: range.from, to: day }
    }
    if (moment(day).diff(range.to) < moment(range.from).diff(day)) {
      return { from: day, to: range.to }
    } else {
      return { from: range.from, to: day }
    }
  }

  handleClear = () => {
    this.handleChange(this.getInitialValue())
  }

  handleConfirm = () => {
    this.props.onConfirm()
    this.tooltip.closeTooltip()
  }

  handleChange = value => {
    value = this.validateValue(value)

    if (!this.isControlled) {
      this.setState({ value })
    }
    this.setState({
      invalidSelectionDays: this.getInvalidSelectionDays(value),
    })
    this.props.onChange(value)

    if (this.props.closeOnPick && value.from && value.to) {
      setTimeout(this.tooltip.closeTooltip, 150)
    }
  }

  validateValue = value => {
    let errors = [
      this.validateMinumumStay(value),
    ].filter(e => e)

    if (errors.length) {
      this.setState({ errors })
      return { from: value.from }
    }
    this.setState({ errors: null })
    return value
  }

  validateMinumumStay = value => {
    const min = this.props.minimumStayDays
    const minWeeks = Math.round(min / 7)
    if (
      min &&
      value.from &&
      value.to &&
      moment(value.to).diff(value.from, 'days') < min
    ) {
      return `The minimum stay is ${minWeeks} week${minWeeks === 1 ? '' : 's'}
      (${min} days). Please adjust your dates accordingly.`
    }
  }

  renderNavBar = ({
    onPreviousClick = () => {},
    onNextClick = () => {},
  }) => (
    <div className="DayPicker-Nav">
      <Icon onClick={() => onPreviousClick()} type="chevron-left-fat" />
      <Icon onClick={() => onNextClick()} type="chevron-right-fat" />
    </div>
  )

  render() {
    const value = this.getValue()
    const {
      className,
      placement,
      placeholder,
      inputProps,
      tooltipProps,
      renderAnchor,
      onClose,
      confirmText,
    } = this.props
    return (
      <Tooltip
        placement={placement}
        className={classnames('datePicker_tooltip dateRangePicker_tooltip', className)}
        linkClassName={renderAnchor ? 'dateRangePicker_link' : 'dateRangePicker'}
        ref={comp => this.tooltip = comp}
        onClose={onClose}
        renderLink={() => (
          renderAnchor ? (
            renderAnchor({
              displayValue: formatDateRange(value.from, value.to),
            })
          ) : (
            <div className="datePicker_input_container">
              <div className="datePicker_input_overlay" />
              <Input
                icon="calendar"
                {...inputProps}
                className="dateRangePicker_input"
                placeholder={placeholder}
                value={formatDateRange(value.from, value.to)}
              />
            </div>
          )
        )}
        renderContent={() => (
          <Fragment>
            <DayPicker
              selectedDays={[value.from, value]}
              disabledDays={this.getDisabledDays()}
              month={value.from}
              modifiers={{
                start: value.from,
                end: value.to,
              }}
              numberOfMonths={media('(max-width: 767px)') ? 1 : 2}
              onDayClick={this.handleDayClick}
              navbarElement={this.renderNavBar}
            />
            {this.state.errors && (
              <FieldErrors errors={this.state.errors} />
            )}
            <div className="dateRangePicker_tooltip_actions">
              <Button size="small" onClick={this.handleConfirm}>
                {confirmText}
              </Button>
              <Button hollow size="small" color="secondary" onClick={this.handleClear}>
                Clear
              </Button>
            </div>
          </Fragment>
        )}
        {...tooltipProps}
      />
    )
  }
}

DateRangePicker.propTypes = propTypes
DateRangePicker.defaultProps = defaultProps

export default DateRangePicker
