import api from '@/shared/api'

export const fetchBookings = () => (
  api.get('/guest/bookings')
)

export const fetchBooking = bookingId => (
  api.get(`/guest/bookings/${bookingId}`)
)

export const fetchAvailability = (propertyId, query) => (
  api.get(`/guest/properties/${propertyId}/availability`, query)
)

export const cancelBooking = bookingId => (
  api.patch(`/guest/bookings/${bookingId}/cancel`)
)

export const fetchRates = (bookingId, dates) => (
  api.get(`/guest/bookings/${bookingId}/recalculate`, dates)
)

export const extendBooking = (bookingId, dates) => (
  api.patch(`/guest/bookings/${bookingId}/extend`, dates)
)

export const updateBooking = (bookingId, payload) => (
  api.patch(`/guest/bookings/${bookingId}`, payload)
)

export const reassignBooking = (bookingId, payload) => (
  api.patch(`/guest/bookings/${bookingId}/reassign`, payload)
)

export const report = bookingId => (
  api.patch(`/guest/bookings/${bookingId}/report`)
)
