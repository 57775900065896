import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from '@/shared/hooks/withRouter'
import { connect } from 'react-redux'
import { handleError } from '@/shared/api'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { resetPassword } from '@/features/auth/state/actions'
import { Modal, Form, Button, Field } from '@/shared/components'
import '@/features/auth/styles/authModal.scss'
import '@/features/auth/styles/resetPassword.scss'

const mapDispatchToProps = { resetPassword }

const propTypes = {
  resetPassword: PropTypes.func.isRequired,
}

const ResetPassword = (props) => {
  const { isOpenResetPassword, toCloseResetPassword, toOpenSignin } = useAuthUrl()
  
  return (
    isOpenResetPassword() && (
      <Modal
        isOpen
        containsForm
        onClose={toCloseResetPassword}
        className="authModal resetPassword"
        renderContent={() => <ResetPasswordForm toOpenSignin={toOpenSignin} resetPassword={props.resetPassword} />}
      />
    )
  )
}

const ResetPasswordForm = ({ toOpenSignin, resetPassword }) => (
  <Form
    initialValues={{
      email: '',
    }}
    validations={{
      email: [Form.is.required(), Form.is.email()],
    }}
    onSubmit={(values, form) => {
      resetPassword(values.email).then(() => {
        form.setStatus({ success: true })
        form.setSubmitting(false)
      }, ({ error }) => {
        if (error.code === 'bad_credentials') {
          form.setErrors({ email: error.description })
        } else if (error.details) {
          form.setErrors(error.details)
        } else {
          handleError({ error })
        }
        form.setSubmitting(false)
      })
    }}
    render={({ handleSubmit, isSubmitting, fields, status = {} }) => (
      <form onSubmit={handleSubmit}>
        <div className="authModal_inner">
          <div className="authModal_title">
            Reset Password
          </div>
          {status.success && (
            <div className="resetPassword_successStatus">
              Reset link should arrive to your email inbox shortly.
            </div>
          )}
          <Field.Input
            {...fields.email}
            placeholder="Email Address"
            icon="email"
          />
          <div className="authModal_tip">
            Enter the email address associated with your account, and we’ll email you a link to reset your password.
          </div>
          <Button
            className="authModal_submit"
            type="submit"
            working={isSubmitting}
            disabled={status.success}
          >
            Send Reset Link
          </Button>
        </div>
        <div className="authModal_bottom">
          Remember your password?
          <span
            className="authModal_bottom_link"
            onClick={toOpenSignin}
          >
            Sign In
          </span>
        </div>
      </form>
    )}
  />
)

ResetPassword.propTypes = propTypes

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword))
