import React, { Component } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { handleError } from '@/shared/api'
import * as api from '@/guestDashboard/api'
import * as analytics from '@/shared/utils/analytics'
import { getTextFromHtml } from '@/shared/utils/html'
import { formatDate } from '@/shared/utils/dateTime'
import { getCurrentBooking } from '@/shared/utils/guestBookings'
import { Head, DashboardGuestSideMenu, Avatar, PageSpinner,
  GuestCurrentBooking, Button, Photo, EmptyState } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/guestDashboard/styles/index.scss'

const c = 'guestDashboard'

class GuestDashboard extends Component {

  state = {
    bookings: null,
    currentBooking: null,
    convos: null,
    events: null,
  }

  componentDidMount() {
    analytics.pageview('Guest Dashboard')

    api.fetchBookings().then(({ data }) => {
      this.setState({
        bookings: data,
        currentBooking: getCurrentBooking(data),
      })
    }, handleError)

    api.fetchConversations({ page: 1 }).then(({ data }) => {
      this.setState({ convos: data })
    }, handleError)

    api.fetchEvents({ page: 1, status: 'accepted' }).then(({ data }) => {
      this.setState({ events: data })
    }, handleError)
  }

  renderConversations = convos => (
    <div className={`${c}_convos`}>
      <div className={`${c}_convos_title`}>
        Recent Messages
      </div>
      {convos.length ? (
        <Link to="/messages">
          <Button hollow size="small">See All</Button>
        </Link>
      ) : (
        <div className={`${c}_noResults`}>You have no recent messages.</div>
      )}
      {convos.slice(0, 3).map(convo => (
        <Link
          key={convo.id}
          to={`/messages/${convo.id}`}
          className={classnames(`${c}_convo`, {
            [`${c}_convo--isUnread`]: !!convo.unreadMessagesCount,
          })}
        >
          <div className={`${c}_convo_avatar`}>
            <Avatar {...convo.users[0]} size={50} withoutLink />
          </div>
          <div className={`${c}_convo_nameDate`}>
            <div>{convo.users[0].firstName}</div>
            <div>{formatDate(convo.lastMessage.createdAt)}</div>
          </div>
          <div className={`${c}_convo_content`}>
            <div>{getTextFromHtml(convo.lastMessage.body)}</div>
            <div>{convo.title}</div>
          </div>
          {!!convo.unreadMessagesCount && (
            <div className={`${c}_convo_newMessagesTag`}>
              <div>
                {`${convo.unreadMessagesCount} New `}
                <br />
                {convo.unreadMessagesCount > 1 ? 'Messages' : 'Message'}
              </div>
            </div>
          )}
        </Link>
      ))}
    </div>
  )

  renderEvents = events => (
    <div className={`${c}_events`}>
      <div className={`${c}_events_title`}>
        Upcoming Events
      </div>
      {events.slice(0, 3).map(event => (
        <Link
          key={event.id}
          className={`${c}_event`}
          to={`/events/show/${event.id}`}
        >
          <Photo cover photo={event.photo} />
          <div className={`${c}_event_info`}>
            <div className={`${c}_event_dateTime`}>
              {`${formatDate(event.startDate)}, ${event.startTime}`}
            </div>
            <div className={`${c}_event_title`}>{event.title}</div>
            <div className={`${c}_event_location`}>{event.location}</div>
          </div>
        </Link>
      ))}
      {events.length ? (
        <Link to="/events">
          <Button hollow size="small">See All Events</Button>
        </Link>
      ) : (
        <div className={`${c}_noResults`}>Stay tuned for upcoming events.</div>
      )}
    </div>
  )

  render() {
    const { bookings, currentBooking, convos, events } = this.state
    return (
      <div className={c}>
        <Head title="Guest Dashboard - Roomsie" />
        <AppHeader />
        <DashboardGuestSideMenu>
          {bookings ? (
            currentBooking ? (
              <GuestCurrentBooking booking={currentBooking} />
            ) : (
              <EmptyState
                title="You have no current or upcoming stays."
                message="Details about your stay will be displayed here after you send a booking request. You will be able to talk to your host, see map directions, and more."
                buttonLink="/search"
                buttonText="Search for a place to stay"
              />
            )
          ) : (
            <PageSpinner />
          )}
          {convos && this.renderConversations(convos)}
          {events && this.renderEvents(events)}
          {bookings && (!convos || !events) && <PageSpinner />}
        </DashboardGuestSideMenu>
        <AppFooter />
      </div>
    )
  }
}

export default GuestDashboard
