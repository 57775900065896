import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import { yearMonthDayToString, stringToYearMonthDay } from '@/shared/utils/dateTime'
import { Field, FieldLabel } from '@/shared/components'
import '@/shared/styles/birthDateSelects.scss'

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  errors: PropTypes.any,
}

const getBirthMonths = () => (
  moment.months().map((month, i) => ({
    label: month, value: i + 1,
  }))
)
const getBirthDays = () => (
  _.range(1, 32).map(i => ({
    label: i, value: i,
  }))
)
const nextYear = new Date().getFullYear() + 1
const getBirthYears = () => (
  _.range(1900, nextYear - 18).reverse().map(i => ({
    label: i, value: i,
  }))
)

class BirthDateSelects extends Component {

  constructor(props) {
    super(props)
    this.state = stringToYearMonthDay(props.value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState(stringToYearMonthDay(this.props.value))
    }
  }

  handleChange = (type, value) => {
    this.setState({ [type]: +value }, () => {
      this.props.onChange(yearMonthDayToString(this.state))
    })
  }

  render() {
    const { year, month, day } = this.state
    const { label, errors, className } = this.props
    return (
      <div className={classnames('birthDateSelects', className)}>
        {label && <FieldLabel>{label}</FieldLabel>}
        <div className="birthDateSelects_inner">
          <Field.Select
            placeholder="Month"
            options={getBirthMonths()}
            value={month}
            onChange={(e, value) => this.handleChange('month', value)}
            errors={
              (!month && errors && 'Month is required') ||
              (year && month && day && errors)
            }
          />
          <Field.Select
            placeholder="Day"
            options={getBirthDays()}
            value={day}
            onChange={(e, value) => this.handleChange('day', value)}
            errors={errors && !day && 'Day is required'}
          />
          <Field.Select
            placeholder="Year"
            options={getBirthYears()}
            value={year}
            onChange={(e, value) => this.handleChange('year', value)}
            errors={errors && !year && 'Year is required'}
          />
        </div>
      </div>
    )
  }
}

BirthDateSelects.propTypes = propTypes

export default BirthDateSelects
