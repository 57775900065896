import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@/shared/components';
import '@/plugin/property/styles/prevNext.scss';

const propTypes = {
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

const c = 'plugin_prevNext';

const PrevNext = ({ label = 'Next Step', onPrevClick, onNextClick }) => (
  <div className={c}>
    <div className={`${c}_inner`}>
      {onPrevClick && (
        <Button
          className={`${c}_prev`}
          type="button"
          color="secondary"
          onClick={onPrevClick}
        >
          &#60; Previous
        </Button>
      )}
      <Button className={`${c}_next`} onClick={onNextClick}>
        {label} &#62;
      </Button>
    </div>
  </div>
);

PrevNext.propTypes = propTypes;

export default PrevNext;
