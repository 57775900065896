import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { withRouter } from '@/shared/hooks/withRouter'
import { guestLinks } from '@/shared/static/menuLinks'
import { Icon, DashboardMenu, Media } from '@/shared/components'
import '@/shared/styles/dashboardGuestSideMenu.scss'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const DashboardGuestSideMenu = ({ children }) => {
  
  const location = useLocation()

  return (
    <Fragment>
      <Media query="(max-width: 1199px)" match={() => <DashboardMenu role="guest" />} />
      <div className="dashboardGuestSideMenu_container">
        <div className="dashboardGuestSideMenu">
          {guestLinks.map(({ to, text, icon }) => (
            <Link key={to} to={to} className={classnames('dashboardGuestSideMenu_link', {
              'dashboardGuestSideMenu_link--isActive': location.pathname.includes(to),
            })}>
              <Icon type={icon} />
              <span>{text}</span>
            </Link>
          ))}
        </div>
        {children}
      </div>
    </Fragment>
  )
}

DashboardGuestSideMenu.propTypes = propTypes

export default withRouter(DashboardGuestSideMenu)
