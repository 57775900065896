import React from 'react'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isCurrentUserVerified, isCurrentUserAuthenticated } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'

const PrivateRoute = ({ canBeUnverified, ...rest }) => {
  const location = useLocation()
  const { rootSignin, rootConfirm, storeAfterAuthUrl } = useAuthUrl()
  
  if (isCurrentUserVerified()) {
    return <Outlet />
  } else if (isCurrentUserAuthenticated()) {
    if (canBeUnverified) {
      return <Outlet />
    } else {
      storeAfterAuthUrl(location)
      return  <Navigate replace to={rootConfirm} />
    }
  } else {
    storeAfterAuthUrl(location)
    return <Navigate replace to={rootSignin} />
  }
}

export default PrivateRoute

