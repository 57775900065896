import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { stringToDate } from '@/shared/utils/dateTime'
import options from '@/shared/static/options'
import { handleError } from '@/shared/api'
import * as api from '@/events/api'
import { Button, ConfirmModal, Icon } from '@/shared/components'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  fetchEvent: PropTypes.func.isRequired,
}

class Controls extends Component {

  state = { isWorking: false }

  handleChange = () => {
    this.props.fetchEvent()
    this.setState({ isWorking: false })
  }

  renderLabel = message => (
    <div className="event_controls">
      <Button disabled square size="small" color="danger">
        {message}
      </Button>
    </div>
  )

  renderCreatorControls = event => (
    <div className="event_controls event_controls--creator">
      <div className="event_controls_tip">
        <Icon type="lightbulb" top={1} />
        Event organizer must attend.
      </div>
      <Link to={`/events/edit/${event.id}`}>
        <Button hollow size="small">Edit</Button>
      </Link>
      <ConfirmModal
        title="Cancel Event"
        message="Are you sure you want to cancel this event? This action is permanent and cannot be reversed. Event will be removed from the list and all guests will be notified."
        cancelText="Close"
        confirmText="Cancel Event"
        confirmInput="CANCEL"
        isDanger
        renderLink={() => (
          <Button
            hollow
            size="small"
            color="danger"
            working={this.state.isWorking}
          >
            Cancel Event
          </Button>
        )}
        onConfirm={() => {
          this.setState({ isWorking: true })
          api.cancelEvent(event.id).then(this.handleChange, handleError)
        }}
      />
    </div>
  )

  renderGuestControls = event => (
    <div className="event_controls event_controls--guest">
      {event.currentUserAttending ? (
        <Fragment>
          <div className="event_controls_status">
            <Icon type="check-circle" top={2} />
            You are attending this event.
          </div>
          <Button
            hollow
            color="danger"
            size="small"
            working={this.state.isWorking}
            onClick={() => {
              this.setState({ isWorking: true })
              api.unattendEvent(event.id).then(this.handleChange, handleError)
            }}
          >
            Cancel My RSVP
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <div className="event_controls_status">
            Would you like to attend this event?
          </div>
          <Button
            size="small"
            working={this.state.isWorking}
            onClick={() => {
              this.setState({ isWorking: true })
              api.attendEvent(event.id).then(this.handleChange, handleError)
            }}
          >
            Attend Event
          </Button>
        </Fragment>
      )}
    </div>
  )

  render() {
    const { currentUser, event } = this.props

    if (event.user.id === currentUser.id) {
      return this.renderCreatorControls(event)
    }
    if (event.status === 'canceled') {
      return this.renderLabel('Event is cancelled')
    }
    if (moment(stringToDate(event.startDate)).isBefore(moment().startOf('day'))) {
      return this.renderLabel('Event has ended')
    }
    if (event.maximumGuests && event.attendeesCount >= event.maximumGuests) {
      return this.renderLabel('Event is sold out')
    }
    return this.renderGuestControls(event)
  }
}

Controls.propTypes = propTypes

export default connect(mapStateToProps)(Controls)
