import React, { Fragment } from 'react'
import SignupModal from '@/features/auth/components/Signup'
import SigninModal from '@/features/auth/components/Signin'
import ResetPasswordModal from '@/features/auth/components/ResetPassword'
import CreatePasswordModal from '@/features/auth/components/CreatePassword'
import ConfirmModal from '@/features/auth/components/confirm'

const AuthModals = () => (
  <Fragment>
    <SignupModal />
    <SigninModal />
    <ResetPasswordModal />
    <CreatePasswordModal />
    <ConfirmModal />
  </Fragment>
)

export default AuthModals
