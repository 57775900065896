import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import { updateNewsletter } from '@/subscriptions/api'
import { fetchCurrentUser } from '@/features/auth/state/actions'
import { Head, Form, Button, Checkbox } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/subscriptions/styles/manage.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { fetchCurrentUser }

const propTypes = {
  currentUser: PropTypes.object,
  fetchCurrentUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const c = 'subscriptions'

class Subscriptions extends Component {

  componentDidMount() {
    analytics.pageview('Subscriptions')
  }

  render() {
    return (
      <div className={c}>
        <Head title="Subscriptions - Roomsie" />
        <AppHeader />
        <div className={`${c}_page`}>
          <div className={`${c}_title`}>
            You are subscribed to the following channels:
          </div>
          <Form
            initialValues={{
              subscribed: !!this.props.currentUser.newsletter,
            }}
            onSubmit={(values, form) => {
              updateNewsletter(values).then(() => {
                this.props.fetchCurrentUser()
                form.setSubmitting(false)
                showToast({ title: 'Your subscriptions have been updated successfully.' })
              }, ({ error }) => {
                handleError({ error })
                form.setSubmitting(false)
              })
            }}
            render={({ handleSubmit, isSubmitting, fields }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className={`${c}_checkboxes`}>
                  <Checkbox
                    {...fields.subscribed}
                    checked={fields.subscribed.value}
                    label="New Listings"
                  />
                </div>
                <Button type="submit" working={isSubmitting}>Update</Button>
              </form>
            )}
          />
        </div>
        <AppFooter />
      </div>
    )
  }
}

Subscriptions.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
