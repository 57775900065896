import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import usStates from '@/shared/static/usStates'
import { formatDate, stringToYearMonthDay, yearMonthDayToString } from '@/shared/utils/dateTime'
import { handleError } from '@/shared/api'
import { Form, Button, Field, Icon, BirthDateSelects } from '@/shared/components'

const propTypes = {
  stripe: PropTypes.func,
  account: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  hasIndividualAccount: PropTypes.func.isRequired,
}

const c = 'hostPayment_accountForm'

class IndividualForm extends Component {

  constructor(props) {
    super(props)
    this.state = { isEditing: !props.hasIndividualAccount() }
  }

  isSSNProvided = () => _.get(this.props.account, 'individual.idNumberProvided')

  getAccountData = () => {
    const get = key => _.get(this.props.account, `individual.${key}`) || ''
    return {
      firstName: get('firstName'),
      lastName: get('lastName'),
      dob: yearMonthDayToString(get('dob')),
      line1: get('address.line1'),
      city: get('address.city'),
      state: get('address.state'),
      postalCode: get('address.postalCode'),
      idNumber: '',
    }
  }

  handleSubmit = (values, form) => {
    const handleStripeError = description => {
      handleError({ error: { description } })
      form.setSubmitting(false)
    }
    this.stripe = this.props.stripe(window.STRIPE_PUBLISHABLE_KEY)
    this.stripe.createToken('account', {
      business_type: 'individual',
      individual: {
        address: {
          line1: values.line1,
          city: values.city,
          state: values.state,
          postal_code: values.postalCode,
          country: 'US',
        },
        first_name: values.firstName,
        last_name: values.lastName,
        dob: stringToYearMonthDay(values.dob),
        id_number: values.idNumber || undefined,
        ssn_last_4: values.idNumber ? values.idNumber.slice(-4) : undefined,
      },
      tos_shown_and_accepted: true,
    }).then(result => {
      if (result.error) {
        handleStripeError(result.error.message)
      } else {
        this.props.onSubmit({
          account_token: result.token.id,
          business_profile: {
            product_description: 'Apartment/Bedroom rentals for students & interns',
            url: `https://roomsie.com/profile/${this.props.currentUser.id}`,
          },
        }).then(() => {
          this.setState({ isEditing: false })
        }, ({ error }) => {
          handleStripeError(error.description || error.message)
        })
      }
    }, error => handleStripeError(error.message))
  }

  renderForm = () => (
    <div className={c}>
      <h2 className={`${c}_title`}>
        Please enter your personal information
        {this.props.hasIndividualAccount() && (
          <div
            className={`${c}_editLink`}
            onClick={() => this.setState({ isEditing: false })}
          >
            <Icon type="edit" />
            Cancel
          </div>
        )}
      </h2>
      <Form
        initialValues={this.getAccountData()}
        validations={{
          firstName: Form.is.required(),
          lastName: Form.is.required(),
          dob: Form.is.required(),
          line1: Form.is.required(),
          city: Form.is.required(),
          state: Form.is.required(),
          postalCode: Form.is.required(),
          idNumber: Form.is.match(v => (
            this.isSSNProvided() ? (/^\d{9}$/.test(v) || v === '') : /^\d{9}$/.test(v)
          ), 'Must be 9 digits'),
        }}
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className={`${c}_fields`}>
              <Field.Input {...fields.firstName} label="First Name" />
              <Field.Input {...fields.lastName} label="Last Name" />
              <BirthDateSelects
                {...fields.dob}
                label="Date of Birth"
                onChange={dob => setFieldValue('dob', dob)}
              />
              <Field.Input {...fields.line1} label="Street Address" />
              <Field.Input {...fields.city} label="City" />
              <Field.Select {...fields.state} label="State" placeholder="Select" options={usStates} />
              <Field.Input {...fields.postalCode} label="Postal Code" />
              <Field.Input
                {...fields.idNumber}
                label="Social Security Number"
                placeholder={this.isSSNProvided() ? 'Provided' : ''}
                onChange={(e, value) => setFieldValue('idNumber', value.trim())}
              />
            </div>
            <Button type="submit" working={isSubmitting} className={`${c}_submit`}>
              Submit
            </Button>
            <p className={`${c}_stripeTerms`}>
              By clicking "Submit", you agree to <a href="https://stripe.com/US/connect-account/legal" target="_blank">Stripe Connected Account Agreement</a>.
            </p>
          </form>
        )}
      />
    </div>
  )

  renderShow = () => {
    const accountData = this.getAccountData()

    const renderValue = (label, value) => (
      <div className={`${c}_value`}>
        <span>{label}</span>
        {value || '—'}
      </div>
    )
    return (
      <div className={c}>
        <h2 className={`${c}_title`}>
          Personal Information
          <div
            className={`${c}_editLink`}
            onClick={() => this.setState({ isEditing: true })}
          >
            <Icon type="edit" />
            Edit
          </div>
        </h2>
        <div className={`${c}_values`}>
          {renderValue('First Name', accountData.firstName)}
          {renderValue('Last Name', accountData.lastName)}
          {renderValue('Date of Birth', formatDate(accountData.dob))}
          {renderValue('Street Address', accountData.line1)}
          {renderValue('City', accountData.city)}
          {renderValue('State', accountData.state)}
          {renderValue('Postal Code', accountData.postalCode)}
          {renderValue('Social Security N.', this.isSSNProvided() ? 'Provided' : '')}
        </div>
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderForm() : this.renderShow()
  }
}

IndividualForm.propTypes = propTypes

export default IndividualForm
