import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import '@/shared/styles/radio.scss'

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  getRef: PropTypes.func,
}

const defaultProps = {
  onChange: (event, value) => {},
  getRef: (element) => {},
}

class Radio extends Component {

  handleChange = e => {
    this.props.onChange(e, e.target.value)
  }

  render() {
    const {
      className,
      label,
      onChange,
      getRef,
      ...radioProps
    } = this.props

    const radioId = _.uniqueId('form-radio-')

    return (
      <div className={classnames('radio', className)}>
        <input
          {...radioProps}
          type="radio"
          id={radioId}
          className="radio_element"
          onChange={this.handleChange}
          ref={getRef}
        />
        <label tabIndex="0" htmlFor={radioId} className="radio_label">
          {label}
        </label>
      </div>
    )
  }
}

Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default Radio
