import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import pubsub from 'sweet-pubsub'
import history from '@/browserHistory'
import storage from '@/shared/utils/storage'
import { removeStoredToken } from '@/features/auth/utils'
import { removeCurrentUser } from '@/features/auth/state/actions'
import { Button } from '@/shared/components'
import '@/global/styles/adminMode.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { removeCurrentUser }

const propTypes = {
  currentUser: PropTypes.object,
  removeCurrentUser: PropTypes.func.isRequired,
}

class AdminMode extends Component {

  state = { isHidden: false }

  componentDidMount() {
    pubsub.on('signout', this.removeAdminMode)
  }

  componentWillUnmount() {
    pubsub.off('signout', this.removeAdminMode)
  }

  toggleHidden = () => this.setState({ isHidden: !this.state.isHidden })

  removeAdminMode = () => storage.local.remove('adminMode')

  render() {
    const { email, firstName, lastName } = this.props.currentUser || {}

    return storage.local.get('adminMode') && (
      <div className="adminMode">
        <div className="adminMode_inner">
          {!this.state.isHidden && (
            <Fragment>
              <div className="adminMode_title">
                {`You are impersonating ${firstName} ${lastName} (${email})`}
              </div>
              <Button size="small" onClick={() => history.push('/signout')}>
                End Session
              </Button>
            </Fragment>
          )}
          <div className="adminMode_toggle" onClick={this.toggleHidden}>Toggle</div>
        </div>
      </div>
    )
  }
}

AdminMode.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(AdminMode)
