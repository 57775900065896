import React, { Fragment, Component, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useParams, useLocation } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import { to404 } from '@/shared/utils/404'
import { getTimeDifference } from '@/shared/utils/dateTime'
import { sortPhotos, getPhotoUrl } from '@/shared/utils/photos'
import { getTextFromHtml } from '@/shared/utils/html'
import { fetchProperty, fetchAvailability,
  fetchProgramSessionListingIds, fetchUserProperties } from '@/property/api'
import { handleError } from '@/shared/api'
import queryManager from '@/shared/hocs/queryManager'
import { Button, PageSpinner, Icon, Media, Head, PropertiesList } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import Navigation from './Navigation'
import Hero from './Hero'
import PropertyBasics from './PropertyBasics'
import Bedrooms from './Bedrooms'
import MoreInfo from './MoreInfo'
import Roomies from './Roomies'
import Host from './Host'
import Location from './Location'
import BookingForm from './bookingForm'
import '@/property/styles/index.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  // match: PropTypes.object.isRequired,
  // location: PropTypes.object.isRequired,
  // history: PropTypes.object.isRequired,
}

const Property = (props) => {
  const { propertyId } = useParams()
  const location = useLocation()
  const didMount = useRef(false)

  const [state, setState] = useState({
    property: null,
    selectedDatesListings: null,
    allDatesListings: null,
    roomies: null,
  })

  const [isEntireSpace, setIsEntireSpace] = useState(null)

  useEffect(() => {
    fetchProperty(propertyId).then(({ data }) => {
      setIsEntireSpace(data.listingType === 'entire_space')
      if (props.query.programSessionId) {
        fetchProgramSessionListings()
      } else {
        fetchAllAvailability()
      }
      setState(prevState => ({
        ...prevState,
        property: data 
      }))
    }, () => {
      to404('We could not find this property — perhaps it was deleted.')
    })
  }, [])

  useEffect(() => {
    fetchDatesAvailability()
  }, [props.query.startDate, props.query.endDate])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      analytics.pageview('Property', getAnalyticsProps())
    }
  }, [state.property])

  const fetchAllAvailability = () => {
    fetchAvailability(propertyId).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        allDatesListings: availabilityToListings(data) 
      }))
      if (!isEntireSpace && props.query.startDate) {
        fetchDatesAvailability()
      } else {
        setState(prevState => ({
          ...prevState,
          selectedDatesListings: availabilityToListings(data)
        }))
      }
    }, handleError)
  }

  const fetchDatesAvailability = () => {
    const { startDate, endDate } = props.query
    const areDatesValid = (!startDate && !endDate) || (startDate && endDate)
    if (!areDatesValid) return
    fetchAvailability(propertyId, { startDate, endDate }).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        selectedDatesListings: availabilityToListings(data)
      }))
      trackEvent('Property Reloaded')
    }, handleError)
  }

  const fetchProgramSessionListings = () => {
    fetchProgramSessionListingIds(query.programSessionId, propertyId).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        selectedDatesListings: _.keyBy(data),
        allDatesListings: _.keyBy(data)
      }))
    })
  }

  const availabilityToListings = allAvailability => {
    const getData = availability => ({
      availableDates: availability,
      minRate: _.min(availability.map(a => a.rate)),
      minStayDays: _.min(availability.map(a => a.minimumStayDays)),
    })
    const listings = getData(allAvailability)
    _.each(_.groupBy(allAvailability, 'listingId'), (listingAvailability, listingId) => {
      listings[listingId] = {
        id: listingId,
        ...getData(listingAvailability),
      }
    })
    return listings
  }

  const getAnalyticsProps = () => {
    const { property } = state
    const { startDate, endDate } = props.query
    return {
      propertyId: _.get(property, 'id'),
      propertyTitle: _.get(property, 'title'),
      hostId: _.get(property, 'user.id'),
      placementType: _.get(property, 'placementType'),
      isEntireSpace: isEntireSpace,
      bedroomsCount: _.get(property, 'bedrooms.length'),
      roomiesCount: _.get(property, 'roomies.length'),
      stayDuration: getTimeDifference(endDate, startDate),
      startDate,
      endDate,
    }
  }

  const trackEvent = (eventName, eventProps = {}) => {
    analytics.track(eventName, {
      ...getAnalyticsProps(),
      ...eventProps
    })
  }
  

  const { property, selectedDatesListings, allDatesListings } = state
  const { currentUser, query, updateQuery } = props
  return (
    <div className="property">
      <AppHeader />
      {property ? (
        <Fragment>
          <Head
            title={`${property.title} - D.C. Housing for Interns, Students & Grads`}
            description={getTextFromHtml(property.descriptionHtml)}
            image={getPhotoUrl(sortPhotos(property.photos)[0].imageUrl, 'medium')}
            url={location.pathname}
          />
          <Navigation property={property} />
          <Hero property={property} />
          <div className="property_container">
            {property && selectedDatesListings && allDatesListings && (
              <BookingForm
                property={property}
                allDatesListings={allDatesListings}
                selectedDatesListings={selectedDatesListings}
                selectedListing={isEntireSpace ? (
                  allDatesListings[property.listing.id]
                ) : (
                  allDatesListings[query.selectedListingId]
                )}
                query={query}
                updateQuery={updateQuery}
                isEntireSpace={isEntireSpace}
                trackEvent={trackEvent}
              />
            )}
            <PropertyBasics property={property} />
            {currentUser &&
              property.placementType !== 'unisex' &&
              property.placementType !== currentUser.gender && (
              <div className="property_placementWarning">
                <Icon type="warning-circle" />
                <span>{`This is a ${property.placementType} only listing.`}</span>
              </div>
            )}
            {!isEntireSpace && selectedDatesListings && (
              <Bedrooms
                property={property}
                selectedDatesListings={selectedDatesListings}
                query={query}
                updateQuery={updateQuery}
                trackEvent={trackEvent}
              />
            )}
            <MoreInfo property={property} />
            <Host property={property} />
            {!isEntireSpace && (
              <Roomies property={property} query={query} />
            )}
          </div>
          <Location property={property} />
          <div className="property_container">
            <PropertiesList
              title={`${property.user.firstName}'s Other Properties`}
              fetchProperties={after => {
                fetchUserProperties(property.user.id).then(({ data }) => (
                  after(data.filter(p => p.id !== property.id))
                ), () => {})
              }}
            />
          </div>
          <AppFooter />
        </Fragment>
      ) : (
        <PageSpinner padding={220} />
      )}
    </div>
  )
}

Property.propTypes = propTypes

const PropertyWithQuery = queryManager({
  defaultQuery: {
    startDate: null,
    endDate: null,
    selectedFees: [],
    selectedListingId: null,
    programSessionId: null,
    programSessionName: null,
  },
  parse: query => ({
    ...query,
    selectedListingId: !_.isNil(query.selectedListingId) ? +query.selectedListingId : null,
    programSessionId: !_.isNil(query.programSessionId) ? +query.programSessionId : null,
  }),
  serialize: query => query,
})(Property)

export default connect(mapStateToProps)(PropertyWithQuery)

