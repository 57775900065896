import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { handleError } from '@/shared/api'
import { fetchPropertyByProgram, updateProgramBed } from '@/hostPrograms/api'
import { getBedsWithDisplayId } from '@/shared/utils/beds'
import options from '@/shared/static/options'
import { PageSpinner, Spinner, Form, Button, Checkbox } from '@/shared/components'
import '@/hostPrograms/styles/toggleBeds.scss'

const propTypes = {
  propertyId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
}

class ToggleBeds extends Component {

  state = { property: null }

  componentDidMount() {
    fetchPropertyByProgram(this.props.propertyId, this.props.programId).then(({ data }) => {
      this.setState({ property: data })
    })
  }

  handleBedToggle = bed => {
    const payload = {
      excludedFromProgram: !bed.excludedFromProgram,
      propertyId: this.props.propertyId
    }
    this.setState({
      [`${bed.id}Submitting`]: true,
    })
    updateProgramBed(this.props.programId, bed.id, payload).then(() => {
      this.setState({
        [`${bed.id}Submitting`]: false,
        property: {
          ...this.state.property,
          bedsPropertiesPrograms:
            this.state.property.bedsPropertiesPrograms.some(el => el.bedId === bed.id) ?
              this.state.property.bedsPropertiesPrograms.filter(el => el.bedId !== bed.id) :
              [...this.state.property.bedsPropertiesPrograms, { bedId: bed.id }],
          bedrooms: this.state.property.bedrooms.map(bedroom => ({
            ...bedroom,
            beds: bedroom.beds.map(b => (
              b.id === bed.id ? { ...b, ...payload } : b
            )),
          })),
        },
      })
    }, handleError)
  }

  render() {
    const { property } = this.state
    return property ? (
      <Fragment>
        <h3>{`Manage ${property.title} beds`}</h3>
        <div className="hostProgramsAssign_toggleBeds_description">
          Unselected beds will not be available for any partnerships.
        </div>
        {property.bedrooms.sort((a, b) => a.id - b.id).map(bedroom => (
          <Fragment key={bedroom.id}>
            { bedroom.beds && bedroom.beds.length > 0 && (
              <h4>{bedroom.title}</h4>
            )}
            {getBedsWithDisplayId(bedroom.beds).map(bed => (
              <div>
                { bed.usedInOtherProgram ? (
                  <Checkbox
                    size="small"
                    key={bed.id}
                    value={bed.id}
                    disabled={true}
                    label={
                      <Fragment>
                        {`${bed.displayId}
                      ${options.labels.bedTypes[bed.kind]} —
                      ${options.labels.bedSizes[bed.size]}`}
                        {this.state[`${bed.id}Submitting`] && <Spinner size={30} />}
                        {` — ${bed.usedInOtherProgram}`}

                      </Fragment>
                    }
                    checked={bed.usedInOtherProgram}
                  />
                ) : (
                  <Checkbox
                    size="small"
                    key={bed.id}
                    value={bed.id}
                    label={
                      <Fragment>
                        {`${bed.displayId}
                        ${options.labels.bedTypes[bed.kind]} —
                        ${options.labels.bedSizes[bed.size]}`}
                        {this.state[`${bed.id}Submitting`] && <Spinner size={30} />}
                      </Fragment>
                    }
                    checked={property.bedsPropertiesPrograms.some(el => el.bedId === bed.id)}
                    onChange={() => this.handleBedToggle(bed)}
                  />
                )}
              </div>
            ))}
          </Fragment>
        ))}
      </Fragment>
    ) : (
      <PageSpinner />
    )
  }
}

ToggleBeds.propTypes = propTypes

export default ToggleBeds
