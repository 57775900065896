import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import colors from '@/shared/static/colors'
import { countryCodes2 } from '@/shared/static/countries'
import { handleError } from '@/shared/api'
import stripeClientLoader from '@/shared/hocs/stripeClientLoader'
import { Button } from '@/shared/components'
import visa from '@/features/guestPaymentForm/assets/visa.jpg'
import mastercard from '@/features/guestPaymentForm/assets/mastercard.jpg'
import amex from '@/features/guestPaymentForm/assets/amex.jpg'
import diners from '@/features/guestPaymentForm/assets/diners.png'
import discover from '@/features/guestPaymentForm/assets/discover.png'
import jcb from '@/features/guestPaymentForm/assets/jcb.png'
import union from '@/features/guestPaymentForm/assets/union.png'

const propTypes = {
  stripe: PropTypes.func,
  user: PropTypes.object.isRequired,
  hasCreditCard: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCreditCardSubmitSuccess: PropTypes.func.isRequired,
  onCreditCardSubmitError: PropTypes.func.isRequired,
}

const c = 'guestPaymentForm_creditCard'

const cardIcons = {
  'Visa': visa,
  'MasterCard': mastercard,
  'American Express': amex,
  'Diners Club': diners,
  'JCB': jcb,
  'UnionPay': union,
  'Discover': discover,
}

class CreditCardForm extends Component {

  constructor(props) {
    super(props)
    this.state = { isEditing: !props.hasCreditCard(), isSubmitting: false }
  }

  componentDidMount() {
    this.toggleStripeElements()
  }

  componentDidUpdate() {
    this.toggleStripeElements()
  }

  toggleStripeElements = () => {
    if (!this.props.stripe) return
    if (this.state.isEditing) {
      if (!this.stripe) this.initStripeCreditCardForm()
    } else {
      this.stripe = null
    }
  }

  initStripeCreditCardForm = () => {
    this.stripe = this.props.stripe(window.STRIPE_PUBLISHABLE_KEY)

    this.card = this.stripe.elements().create('card', {
      style: {
        base: {
          color: colors.text,
          fontFamily: 'Montserrat',
          fontSize: '15px',
          fontWeight: '500',
        },
      },
      hidePostalCode: true,
    })
    this.card.mount(this.$creditCardInput)

    this.card.addEventListener('change', event => {
      this.$creditCardErrors.textContent = event.error ? event.error.message : ''
    })
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true })
    const { shipping } = this.props.user

    this.stripe.createToken(this.card, {
      name: shipping.name,
      address_line1: shipping.address.line1,
      address_line2: shipping.address.line2,
      address_city: shipping.address.city,
      address_state: shipping.address.state,
      address_zip: shipping.address.postalCode,
      address_country: _.get(countryCodes2.find(c => c.label === shipping.address.country), 'value'),
    }).then(result => {
      if (result.error) {
        this.$creditCardErrors.textContent = result.error.message
        this.props.onCreditCardSubmitError(result.error.message)
        this.setState({ isSubmitting: false })
      } else {
        this.props.onSubmit({ source: result.token.id }).then(() => {
          this.props.onCreditCardSubmitSuccess()
          this.setState({ isEditing: false, isSubmitting: false })
        }, ({ error }) => {
          handleError({ error: { description: error.description || error.message } })
          this.props.onCreditCardSubmitError(error.description || error.message)
          this.setState({ isSubmitting: false })
        })
      }
    }, () => {})
  }

  formatMonth = month => (
    `${month}`.length === 1 ? `0${month}` : month
  )

  renderCreditCardForm = user => (
    <div className={`${c}_form`}>
      <h2>Please enter your credit card details</h2>
      <div className={`${c}_form_icons`}>
        {_.values(cardIcons).map(image => (
          <img key={image} src={image} className={`${c}_form_icon`} />
        ))}
      </div>
      <div className={`${c}_form_input`} ref={el => this.$creditCardInput = el} />
      <div className={`${c}_form_error`} ref={el => this.$creditCardErrors = el} />
      {this.props.hasCreditCard() && (
        <div className={`${c}_form_viewLink`} onClick={() => {
          this.setState({ isEditing: false })
        }}>
          Cancel
        </div>
      )}
      <Button
        size="small"
        working={this.state.isSubmitting}
        onClick={this.handleSubmit}
      >
        Submit
      </Button>
    </div>
  )

  renderCreditCard = card => (
    <Fragment>
      <h2>Current Credit Card For Payments</h2>
      <div className={`${c}_show`}>
        <div className={`${c}_show_name`}>{card.name}</div>
        <div className={`${c}_show_number`}>{`XXXX-XXXX-XXXX-${card.last4}`}</div>
        <div>{`Expires on: ${this.formatMonth(card.expMonth)}/${card.expYear}`}</div>
        <div className={`${c}_show_formLink`} onClick={() => {
          this.setState({ isEditing: true })
        }}>
          Change Credit Card
        </div>
        {this.renderCardIcon(card.brand)}
      </div>
    </Fragment>
  )

  renderCardIcon = type => cardIcons[type] && (
    <img className={`${c}_show_icon`} src={cardIcons[type]} />
  )

  render() {
    const { isEditing } = this.state
    const { user } = this.props

    return (
      <div className={c}>
        <div className={`${c}_inner`}>
          {isEditing && this.renderCreditCardForm(user)}
          {!isEditing && this.renderCreditCard(user.sources.data[0])}
        </div>
      </div>
    )
  }
}

CreditCardForm.propTypes = propTypes

export default stripeClientLoader(CreditCardForm)
