import React, { Component, Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { to404 } from '@/shared/utils/404'
import { formatDate, getTimeDifference } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { formatAddress } from '@/shared/utils/geo'
import * as api from '@/guestBookings/api'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { DashboardGuestSideMenu, Avatar, Icon, Button, PageSpinner, Switch,
  Photo, BookingFeesGuest, Head, Modal, Media, CheckinTime } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import MessageModal from '@/features/messageModal/components'
import Payments from './Payments'
import CancelBooking from './CancelBooking'
import ExtendBooking from './ExtendBooking'
import Report from './Report'
import ReassignBooking from './ReassignBooking'
import '@/guestBookings/styles/booking.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
}

const c = 'guestBooking'

const Booking = (props) => {

  const { bookingId } = useParams()
  const $pageCont = useRef()

  const [booking, setBooking] = useState(null)

  useEffect(() => {
    analytics.pageview('Guest Booking', {
      bookingId: bookingId,
    })
    fetchGuestBooking()
  }, [])
  
  const fetchGuestBooking = () => {
    api.fetchBooking(bookingId).then(({ data }) => {
      setBooking(data)
    }, () => {
      to404('We could not find this booking.')
    })
  }

  return (
    <div className={c} ref={$pageCont}>
      {booking && <Head title={`Booking - ${booking.property.title} - Roomsie`} />}
      <AppHeader />
      <DashboardGuestSideMenu>
        {booking ? (
          <Fragment>
            <Top booking={booking} fetchBooking={fetchGuestBooking} currentUser={props.currentUser} />
            {booking.program && booking.program.title && (
              <Program booking={booking} />
            )}
            <div className={`${c}_page`}>
              <div className={`${c}_page_left`}>
                <Listing {...booking} />
                <Host {...booking} />
                <Guest currentUser={props.currentUser} fetchBooking={fetchGuestBooking} {...booking} />
                <Help />
              </div>
              <div className={`${c}_page_middle`}>
                {/accepted|started/.test(booking.status) && (
                  <CheckinTime
                    booking={booking}
                    updateBooking={api.updateBooking}
                    description="Please select your approximate check-in time to help your host prepare for your arrival."
                  />
                )}
                <Dates booking={booking} />
                <Rates booking={booking} />
                {/*<Deposit {...booking} />*/}
              </div>
              <div className={`${c}_page_right`}>
                <Payments booking={booking} />
                {!!(booking.receipts.length || booking.invoices.length) && (
                  <ReceiptsAndInvoices {...booking} />
                )}
              </div>
            </div>
            <Media query="(max-width: 767px)" match={() => (
              <div className={`${c}_bottom_mobile_actions`}>
                <Actions booking={booking} fetchBooking={fetchGuestBooking} currentUser={props.currentUser} />
              </div>
            )} />
          </Fragment>
        ) : (
          <PageSpinner />
        )}
      </DashboardGuestSideMenu>
      <AppFooter />
    </div>
  )
}

const Top = ({ booking, fetchBooking, currentUser }) => (
  <div className={`${c}_top`}>
    <div className={`${c}_top_left`}>
      <div className={`${c}_top_breadcrumbs`}>
        <Link to="/guest/bookings">My Stays</Link>
        {' / Booking Details'}
      </div>
      <h1 className={`${c}_top_title`}>
        {`Booking #${booking.id}`}
      </h1>
      <div className={`${c}_top_status ${c}_top_status--${booking.status}`}>
        {/pending|review/.test(booking.status) && (
          <Icon type="warning-circle" />
        )}
        {options.labels.bookingStatuses[booking.status]}
      </div>
      <div className={`${c}_top_createdAt`}>
        {`Submitted on ${formatDate(booking.createdAt, 'dayMonthDayYear')}`}
      </div>
    </div>
    <Actions booking={booking} fetchBooking={fetchBooking} currentUser={currentUser} />
  </div>
)

const Actions = ({ booking, fetchBooking, currentUser }) => {
  const isBookingReportable = booking => (
    getTimeDifference(moment(), booking.startDate, 'hours') < 72
  )

  return (
    <Fragment>
      {/accepted|started/.test(booking.status) && (
        <ExtendBooking booking={booking} fetchBooking={fetchBooking} currentUser={currentUser} />
      )}
      {/accepted|pending/.test(booking.status) && (
        <CancelBooking booking={booking} fetchBooking={fetchBooking} />
      )}
      {/started|reported/.test(booking.status) && isBookingReportable(booking) && (
        <Report booking={booking} host={booking.host} fetchBooking={fetchBooking} />
      )}
      {/review|finished/.test(booking.status) && (
        <Link to={`/leave-review/${booking.id}`}>
          <Button>Review Your Stay</Button>
        </Link>
      )}
    </Fragment>
  )
}

const Program = ({ booking }) => (
  <div className={`${c}_program`}>
    <strong>{'Partner: '}</strong>
    {booking.program.title}
  </div>
)

const Listing = ({ listing, propertySnapshot, bed }) => (
  <div className={`${c}_listing`}>
    <Photo photo={propertySnapshot?.photo} />
    <div className={`${c}_listing_inner`}>
      <div className={`${c}_listing_title`}>
        {propertySnapshot?.title}
      </div>
      <div className={`${c}_listing_address`}>
        {formatAddress(propertySnapshot)}
      </div>
      <a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${
        propertySnapshot?.coordinates.lat},${propertySnapshot?.coordinates.lng
      }`}>
        <div className={`${c}_listing_directions`}>
          Get Directions
        </div>
      </a>
      <div className={`${c}_listing_type`}>
        {`${options.labels.placementTypes[propertySnapshot?.placementType]}
          ${options.labels.listingTypes[propertySnapshot?.listingType].replace('Space', '')} Space`}
      </div>
      {!!bed && (
        <Fragment>
          <div className={`${c}_listing_bedroom_title`}>
            {`${options.labels.bedSizes[propertySnapshot?.bedSize]}
              ${options.labels.bedTypes[propertySnapshot?.bedKind]} in
              ${propertySnapshot?.bedroomTitle}`}
          </div>
          <div className={`${c}_listing_bedroom_details`}>
            <div>
              <Icon type="bed" />
              {`${options.labels.bedsCount[propertySnapshot?.bedsCount]}`}
            </div>
            <div>
              <Icon type="bath" />
              {`${propertySnapshot?.bathroomAttached ? 'Attached' : 'Shared'} Bathroom`}
            </div>
          </div>
        </Fragment>
      )}
      <Link to={`/properties/${propertySnapshot?.id}`} target="_blank">
        <Button hollow size="small">View Listing</Button>
      </Link>
    </div>
  </div>
)

const Host = ({ host, ...booking }) => {
  
  const { toOpen } = useProfileModalUrl()

  return (
    <div className={`${c}_host`}>
      <div className={`${c}_host_title`}>
        Hosted By
      </div>
      <div className={`${c}_host_basic`}>
        <Avatar {...host} size={70} />
        <div className={`${c}_host_basic_text`}>
          <div className={`${c}_host_basic_name`}>
            {`${host.firstName} ${host.lastName}`}
          </div>
          <div className={`${c}_host_basic_ageGender`}>
            {`${host.age}, ${host.gender}`}
          </div>
        </div>
      </div>
      {host.phoneNumber && (
        <div className={`${c}_host_phone`}>
          <Icon type="phone" />
          {`Phone: ${host.phoneNumber}`}
        </div>
      )}
      {(host.city || host.state || host.country) && (
        <div className={`${c}_host_meta`}>
          <div>
            <span>{'From: '}</span>
            {[host.city, host.state, host.country].filter(p => p).join(', ')}
          </div>
        </div>
      )}
      {host.bio && (
        <div className={`${c}_host_about`}>
          <div>About</div>
          <p>{_.truncate(host.bio, { length: 130 })}</p>
        </div>
      )}
      <Button hollow size="small" className={`${c}_host_details`}onClick={() => toOpen(host.id)}>Details</Button>
      {booking.conversationId ? (
        <Link to={`/messages/${booking.conversationId}`}>
          <Button size="small">Messages</Button>
        </Link>
      ) : (
        <MessageModal
          description="Questions about your housing that were not addressed in the listing? Ask your hosts! They are here to help."
          recipient={host}
          contextId={{ bookingId: booking.id }}
          buttonProps={{ hollow: false }}
        />
      )}
    </div>
  )
}

const Guest = ({ guest, currentUser, fetchBooking, ...booking }) => guest.id !== currentUser.id && (
  <div className={`${c}_guest`}>
    {console.log(currentUser)}
    <div className={`${c}_guest_title`}>
      Guest Information
    </div>
    <div className={`${c}_guest_basic`}>
      <Avatar {...guest} size={70} />
      <div className={`${c}_guest_basic_text`}>
        <div className={`${c}_guest_basic_name`}>
          {`${guest.firstName} ${guest.lastName}`}
        </div>
        <div className={`${c}_guest_basic_ageGender`}>
          {`${guest.age}, ${guest.gender}`}
        </div>
      </div>
    </div>
    {guest.email && (
      <div className={`${c}_guest_email`}>
        <Icon type="email" />
        {guest.email}
      </div>
    )}
    <ReassignBooking
      booking={{ guest, ...booking }}
      fetchBooking={fetchBooking}
      renderLink={() => <Button size="small">Change Guest Email</Button>}
    />
  </div>
)

const Help = () => (
  <div className={`${c}_help`}>
    <div className={`${c}_help_title`}>
      Need help?
    </div>
    <div className={`${c}_help_message`}>
      <Icon type="help" />
      {`Visit our `}
      <a href="https://roomsie.zendesk.com" target="_blank">
        Help Center
      </a>
      {` for any questions.`}
    </div>
  </div>
)

const Dates = ({ booking }) => (
  <div className={`${c}_dates`}>
    <div className={`${c}_dates_title`}>Dates of Stay</div>
    <div className={`${c}_dates_inner`}>
      <div className={`${c}_dates_label`}>Starts On</div>
      <div className={`${c}_dates_date`}>{formatDate(booking.startDate, 'dayMonthDayYear')}</div>
      <div className={`${c}_dates_stayLength`}>{`${booking.stayLength} nights`}</div>
      <div className={`${c}_dates_label`}>Ends On</div>
    </div>
    <div className={`${c}_dates_date`}>{formatDate(booking.endDate, 'dayMonthDayYear')}</div>
  </div>
)

const Rates = ({ booking }) => (
  <div className={`${c}_rates`}>
    <div className={`${c}_rates_title`}>Rates & Fees</div>
    <BookingFeesGuest rates={booking} />
  </div>
)

const Deposit = ({ depositAmount, depositDeduction }) => {
  const renderLabel = () => {
    if (!depositAmount) {
      return '<strong>No escrow deposit was set.</strong>'
    } else if (depositDeduction) {
      return `Deposit: The host refunded <strong>${formatPrice(depositAmount - depositDeduction.amount)}</strong>
              out of <strong>${formatPrice(depositAmount)}</strong>
              on <strong>${formatDate(depositDeduction.date)}</strong>.`
    } else if (!depositDeduction) {
      return `Escrow deposit is set to <strong>${formatPrice(depositAmount)}</strong>.`
    }
  }
  return (
    <div className={`${c}_deposit`}>
      <div dangerouslySetInnerHTML={{ __html: renderLabel() }} />
      {depositDeduction && depositDeduction.comments && (
        <div className={`${c}_deposit_comments`}>
          {`They kept `}
          <strong>{formatPrice(depositDeduction.amount)}</strong>
          {` for the `}
          <Modal
            className={`${c}_deposit_comments_modal`}
            renderLink={() => <span>following reasons.</span>}
            renderContent={() => (
              <Fragment>
                <h3>{`Reasons for keeping ${formatPrice(depositDeduction.amount)}:`}</h3>
                <p>{depositDeduction.comments}</p>
              </Fragment>
            )}
          />
        </div>
      )}
    </div>
  )
}

const ReceiptsAndInvoices = ({ receipts, invoices }) => (
  <div className={`${c}_receipts`}>
    <div className={`${c}_receipts_title`}>Receipts & Invoices</div>
    {[[receipts, 'Receipt'], [invoices, 'Invoice']].map(([items, label]) => (
      items.map((item, i) => (
        <div key={label + i} className={`${c}_receipts_item`}>
          <a href={item.url} target="_blank">
            {`${label} for ${formatDate(item.date)}`}
          </a>
        </div>
      ))
    ))}
  </div>
)

Booking.propTypes = propTypes

export default connect(mapStateToProps)(Booking)
