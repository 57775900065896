import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { Formik } from 'formik'
import { is, getErrors, scrollToFirstError } from '@/shared/utils/validation'

const defaultProps = {
  validateOnBlur: false,
  onReset: () => {},
}

class Form extends Component {

  changed = {}

  handleChange = (e, renderProps) => {
    this.changed[e.target.name || e.target.id] = true
    renderProps.handleChange(e)
  }

  handleSubmit = (e, renderProps) => {
    _.keys(this.props.initialValues).forEach(key => {
      this.changed[key] = true
    })
    this.wasSubmitted = true
    renderProps.handleSubmit(e)
  }

  setFieldValue = (field, value, renderProps) => {
    this.changed[field] = true
    renderProps.setFieldValue(field, value)
  }

  getFields = renderProps => (
    _.mapValues(this.props.initialValues, (value, key) => ({
      onChange: e => this.handleChange(e, renderProps),
      onBlur: renderProps.handleBlur,
      name: key,
      value: renderProps.values[key],
      errors: (
        this.changed[key] &&
        renderProps.touched[key] &&
        (renderProps.errors || {})[key]
      ),
    }))
  )

  onReset = (...args) => {
    this.wasReset = true
    this.props.onReset(...args)
  }

  resetChanged = () => {
    if (this.wasReset) {
      this.wasReset = false
      this.changed = {}
    }
  }

  validate = values => new Promise((resolve, reject) => {
    const errors = getErrors(values, this.props.validations)
    _.isEmpty(errors) ? resolve() : reject(errors)
  })

  scrollToFirstErrorIfInvalid = renderProps => {
    if (!renderProps.isValid && this.wasSubmitted) {
      this.wasSubmitted = false
      setTimeout(scrollToFirstError, 100)
    }
  }

  render() {
    return (
      <Formik
        {...this.props}
        validate={this.props.validate || this.validate}
        onReset={this.onReset}
        render={renderProps => {
          this.resetChanged()
          this.scrollToFirstErrorIfInvalid(renderProps)
          return (
            this.props.render({
              ...renderProps,
              changed: this.changed,
              fields: this.getFields(renderProps),
              handleChange: e => this.handleChange(e, renderProps),
              handleSubmit: e => this.handleSubmit(e, renderProps),
              setFieldValue: (field, value) => this.setFieldValue(field, value, renderProps),
            })
          )
        }}
      />
    )
  }
}

Form.defaultProps = defaultProps

Form.is = is

export default Form

// The Form component adds the following props to Formik's render:
// changed: a hash of fields that were changed by onChange
// fields: a hash of fields with corresponding onChange, onBlur,
//   name, value, and errors to make the forms less verbose
// validations: a simple validation interface

