import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { stringToDate, formatDate, formatDateRange, getTimeDifference } from '@/shared/utils/dateTime'
import { getBodyScrollTop } from '@/shared/utils/dom'
import { formatAddress } from '@/shared/utils/geo'
import { getCurrentBooking } from '@/shared/utils/guestBookings'
import { handleError } from '@/shared/api'
import { fetchBookings } from '@/guestRoomies/api'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import MessageModal from '@/features/messageModal/components'
import { DashboardGuestSideMenu, Avatar, Photo, PageSpinner,
  Icon, Select, EmptyState, Head } from '@/shared/components'
import '@/guestRoomies/styles/index.scss'

const c = 'guestRoomies'

class GuestRoomies extends Component {

  state = { bookings: null, selectedBooking: null }

  componentDidMount() {
    analytics.pageview('Guest Roomies')

    fetchBookings().then(({ data }) => {
      const bookings = data.filter(booking => (
        booking.propertySnapshot.listingType !== 'entire_space' &&
        /accepted|started|review|finished|reported/.test(booking.status)
      ))
      this.setState({
        bookings,
        selectedBooking: getCurrentBooking(bookings),
      })
    }, handleError)

    window.addEventListener('scroll', this.setStickyLinesBar)
    this.setStickyLinesBar()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setStickyLinesBar)
  }

  setStickyLinesBar = () => {
    if (!this.$linesContainer) return
    const areLinesSticky = this.$linesContainer.getBoundingClientRect().top < 10
    this.$linesSticky.className = areLinesSticky ? 'guestRoomies_lines_sticky' : ''
  }

  onBookingSelect = bookingId => this.setState({
    selectedBooking: this.state.bookings.find(b => b.id === bookingId),
  }, () => {
    if (!this.$roomieLine) return
    this.$roomieName.innerHTML = ''
    this.$roomieLine.style.display = 'none'
  })

  onRoomieMouseEnter = roomie => {
    const { startDate, endDate } = this.state.selectedBooking
    const guestDays = getTimeDifference(endDate, startDate)
    const guestWidth = this.$guestLine.getBoundingClientRect().width
    const roomieLeft = Math.max(getTimeDifference(roomie.startDate, startDate) / guestDays, 0) * guestWidth
    const roomieRight = Math.min(getTimeDifference(roomie.endDate, startDate) / guestDays, 1) * guestWidth
    this.$roomieName.innerHTML = roomie.firstName.slice(0, 16)
    this.$roomieLine.style.left = `${Math.round(roomieLeft)}px`
    this.$roomieLine.style.width = `${Math.round(roomieRight) - Math.round(roomieLeft)}px`
    this.$roomieLine.style.display = 'inline-block'
  }

  getRoomieDuration = roomie => {
    const { startDate, endDate } = this.state.selectedBooking
    const guestDays = getTimeDifference(endDate, startDate)
    const roomieStart = Math.max(getTimeDifference(roomie.startDate, startDate), 0)
    const roomieEnd = Math.min(getTimeDifference(roomie.endDate, startDate), guestDays)
    return roomieEnd - roomieStart
  }

  sortRoomiesByDate = roomies => roomies.sort((a, b) => (
    stringToDate(a.startDate) - stringToDate(b.startDate)
  ))

  renderBookingSelect = ({ propertySnapshot, ...booking }) => (
    <div className={`${c}_booking`}>
      <div className={`${c}_booking_top`}>
        My Roomies
      </div>
      <div className={`${c}_booking_description`}>
        {"The roomies listed below have a confirmed reservation for dates that overlap with your stay at:"}
      </div>
      <div className={`${c}_booking_bottom`}>
        <Link to={`/properties/${propertySnapshot.id}`} target="_blank">
          <Photo photo={propertySnapshot.photo} />
        </Link>
        <div className={`${c}_booking_dates`}>
          {formatDateRange(booking.startDate, booking.endDate)}
        </div>
        <Link to={`/properties/${propertySnapshot.id}`} target="_blank">
          <div className={`${c}_booking_propertyTitle`}>
            {propertySnapshot.title}
          </div>
          <div className={`${c}_booking_address`}>
            {formatAddress(propertySnapshot)}
          </div>
        </Link>
        <Select
          placeholder="Choose another stay"
          options={this.state.bookings.map(b => ({
            value: b.id,
            label: `${b.propertySnapshot.title}, ${formatDateRange(b.startDate, b.endDate)}`,
          }))}
          onChange={(e, bookingId) => this.onBookingSelect(+bookingId)}
          value=""
        />
      </div>
    </div>
  )

  renderLines = booking => (
    <div className={`${c}_lines_container`} ref={el => this.$linesContainer = el}>
      <div ref={el => this.$linesSticky = el}>
        <div className={`${c}_lines`}>
          <div className={`${c}_lines_guest`} ref={el => this.$guestLine = el} />
          <span className={`${c}_lines_guestName`}>You</span>
          <span className={`${c}_lines_startDate`}>
            {formatDate(booking.startDate, 'monthDay')}
          </span>
          <span className={`${c}_lines_endDate`}>
            {formatDate(booking.endDate, 'monthDay')}
          </span>
          <span className={`${c}_lines_roomieName`} ref={el => this.$roomieName = el} />
          <div className={`${c}_lines_roomie`} ref={el => this.$roomieLine = el} />
        </div>
      </div>
    </div>
  )

  renderRoomie = roomie => (
    <div
      key={roomie.id}
      className={`${c}_roomie`}
      onMouseEnter={() => this.onRoomieMouseEnter(roomie)}
      onTouchStart={() => this.onRoomieMouseEnter(roomie)}
    >
      <div className={`${c}_roomie_inner`}>
        <div className={`${c}_roomie_avatar_container`}>
          {_.get(this.state.selectedBooking, 'bed.bedroom.id') === roomie.bedroomId && (
            <Icon type="bed-2" />
          )}
          <Avatar {...roomie} size={80} />
        </div>
        <div className={`${c}_roomie_content`}>
          <div className={`${c}_roomie_name`}>
            {roomie.firstName}
          </div>
          {_.get(this.state.selectedBooking, 'bed.bedroom.id') === roomie.bedroomId && (
            <div className={`${c}_roomie_bedroommate`}>
              Bedroommate
            </div>
          )}
          <div className={`${c}_roomie_description`}>
            {`${roomie.age} year old ${_.upperFirst(roomie.gender)} from ${roomie.school}`}
          </div>
          <div className={`${c}_roomie_dates`}>
            {formatDateRange(roomie.startDate, roomie.endDate)}
          </div>
          <div className={`${c}_roomie_duration`}>
            {`Roomies for ${this.getRoomieDuration(roomie)} days`}
          </div>
        </div>
        <div className={`${c}_roomie_actions`}>
          <MessageModal
            description="Make sure to be cool and respectful to your fellow roomies during your stay."
            recipient={roomie}
            contextId={{ propertyId: this.state.selectedBooking.property.id }}
          />
          <ProfileLink roomie={roomie} />
        </div>
      </div>
    </div>
  )

  renderNoResults = booking => (
    <div className={`${c}_noResults`}>
      {`You are the first person to book this property from ${formatDateRange(booking.startDate, booking.endDate)}`}
    </div>
  )

  render() {
    const { bookings, selectedBooking } = this.state
    return (
      <div className={c}>
        <Head title="My Roomies - Roomsie" />
        <AppHeader />
        <DashboardGuestSideMenu>
          {!bookings && <PageSpinner />}
          {bookings && !bookings.length && (
            <EmptyState
              title="You have not booked a shared space yet."
              message="After booking a shared space your future roomies will be listed right here. You will be able to see their full profiles, exchange messages, and more."
              buttonText="Search for a place to stay"
              buttonLink="/search"
            />
          )}
          {bookings && !!bookings.length && (
            <Fragment>
              {this.renderBookingSelect(selectedBooking)}
              {!!selectedBooking.roomies.length ? (
                <Fragment>
                  {this.renderLines(selectedBooking)}
                  {this.sortRoomiesByDate(selectedBooking.roomies).map(this.renderRoomie)}
                </Fragment>
              ) : (
                this.renderNoResults(selectedBooking)
              )}
            </Fragment>
          )}
        </DashboardGuestSideMenu>
        <AppFooter />
      </div>
    )
  }
}

const ProfileLink = ({ roomie }) => {

  const { toOpen } = useProfileModalUrl()

  return (
    <div className={`${c}_roomie_profileLink`} onClick={() => toOpen(roomie.id)}>
      View Profile
    </div>
  )
}

export default GuestRoomies
