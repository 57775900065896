import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Icon } from './'
import '@/shared/styles/select.scss'

const propTypes = {
  size: PropTypes.string,
  line: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  options: PropTypes.array.isRequired,
  optionAttributes: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  getRef: PropTypes.func,
}

const defaultProps = {
  size: 'medium',
  optionAttributes: (option) => ({}),
  onChange: (event, value) => {},
  getRef: (element) => {},
}

class Select extends Component {

  handleChange = e => {
    this.props.onChange(e, e.target.value)
  }

  render() {
    let {
      size,
      line,
      icon,
      className,
      placeholder,
      invalid,
      options,
      optionAttributes,
      onChange,
      getRef,
      ...selectProps
    } = this.props

    if (placeholder) {
      options = [
        { label: placeholder, value: '', disabled: true },
        ...options,
      ]
    }

    return (
      <div className={classnames(
        'select',
        `select--size-${size}`, {
          'select--line': line,
          'select--icon': icon,
          'select--invalid': invalid,
        },
        className
      )}>
        {icon && (
          _.isString(icon) ? <Icon type={icon} className="select_icon" /> : icon
        )}
        <select
          {...selectProps}
          className="select_element"
          onChange={this.handleChange}
          ref={getRef}
        >
          {options.map((o, i) => (
            <option key={i} value={o.value} disabled={o.disabled} {...optionAttributes(o)}>
              {o.label}
            </option>
          ))}
        </select>
        <Icon type="chevron-down-fat" />
      </div>
    )
  }
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
