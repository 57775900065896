import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import _ from 'lodash'
import { uniqueDateId } from '@/shared/utils/id'
import { getPhotoUrl, getPhotoPreview } from '@/shared/utils/photos'
import { fetchSignedUrls, uploadPhotoToStorage } from '@/shared/api/photos'
import { Icon, Button, Spinner } from '@/shared/components'
import '@/listSpace/styles/photosUploader.scss'

const propTypes = {
  initialPhotos: PropTypes.array.isRequired,
  maxPhotos: PropTypes.number,
  minPhotos: PropTypes.number,
  onPhotosChange: PropTypes.func.isRequired,
  getProgress: PropTypes.func,
}

const defaultProps = {
  maxPhotos: 24,
  minPhotos: 0,
  getProgress: () => {},
}

const c = 'listSpace_photosUploader'

class PhotosUploader extends Component {

  promises = {}

  constructor(props) {
    super(props)
    this.state = {
      photos: this.makeSureHasCover(props.initialPhotos),
    }
  }

  componentDidMount() {
    this.props.getProgress(this.getProgress)
  }

  componentWillUnmount() {
    _.each(this.promises, promise => {
      promise.cancel()
    })
  }

  handlePhotosChange = photos => {
    this.setState({
      photos: this.makeSureHasCover(
        photos.map((photo, index) => ({ ...photo, index }))
      ),
    }, () => {
      this.props.onPhotosChange(
        this.state.photos.filter(p => !p.isUploading)
      )
    })
  }

  makeSureHasCover = photos => {
    if (photos.length && !photos.find(p => p.cover)) {
      return photos.map((photo, i) => (
        i ? photo : { ...photo, cover: true }
      ))
    }
    return photos
  }

  getProgress = (props = this.props) => {
    const total = this.state.photos.length
    const uploaded = this.state.photos.filter(p => !p.isUploading).length
    const uploading = this.state.photos.filter(p => p.isUploading).length
    const isAboveMin = uploaded >= props.minPhotos
    const isMaxAttached = total >= props.maxPhotos
    const isMaxUploaded = uploaded >= props.maxPhotos
    return { total, uploaded, uploading, isAboveMin, isMaxAttached, isMaxUploaded }
  }

  onDrop = files => {
    files.splice(this.props.maxPhotos - this.state.photos.length)
    if (!files.length) return

    fetchSignedUrls(files).then(() => {
      _.each(files, file => {

        getPhotoPreview(file, (base64Preview, { width, height }) => {
          const photoId = uniqueDateId()

          this.handlePhotosChange([
            ...this.state.photos, {
              uid: photoId,
              isUploading: true,
              width,
              height,
              base64Preview,
              imageUrl: file.imageUrl,
            },
          ])
          this.promises[photoId] = uploadPhotoToStorage(file)
          this.promises[photoId].promise.then(() => {
            const photos = _.cloneDeep(this.state.photos)
            const photo = photos.find(p => p.uid === photoId)
            if (photo) {
              photo.isUploading = false
              this.handlePhotosChange(photos)
            }
          }, () => {})
        })
      })
    })
  }

  setCover = index => {
    this.handlePhotosChange(
      this.state.photos.map((photo, i) => ({
        ...photo, cover: i === index,
      }))
    )
  }

  deletePhoto = index => {
    this.handlePhotosChange(
      this.state.photos.filter((p, i) => i !== index)
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.handlePhotosChange(
      arrayMove(this.state.photos, oldIndex, newIndex)
    )
  }

  renderInfoMessage = () => {
    const { minPhotos, maxPhotos } = this.props
    const { isAboveMin, isMaxAttached, isMaxUploaded } = this.getProgress()
    const pluralized = n => `photo${n !== 1 ? 's' : ''}`
    let message = ''

    if (!isAboveMin) {
      message = `Please upload at least ${minPhotos} ${pluralized(minPhotos)}`
    } else if (isMaxUploaded) {
      message = `You have uploaded the maximum amount of ${maxPhotos} ${pluralized(maxPhotos)}`
    } else if (isMaxAttached) {
      message = `You are uploading the maximum amount of ${maxPhotos} ${pluralized(maxPhotos)}`
    } else {
      return null
    }
    return <div className={`${c}_infoMessage`}>{message}</div>
  }

  render() {
    return (
      <div className={c}>
        <div className={`${c}_tip`}>
          {`Drag photos to reorder them. Maximum file size of 10mb per .png, .gif, or .jpg file.`}
        </div>
        <div className={`${c}_dropzone_container`}>
          <Dropzone
            className={`${c}_dropzone`}
            activeClassName={`${c}_dropzone--isActive`}
            maxSize={10000000}
            accept="image/jpeg, image/png, image/gif"
            disablePreview
            onDrop={this.onDrop}
          >
            <Button type="button">Upload Photos</Button>
            <div className={`${c}_dropzone_tip`}>
              or Drag them in
            </div>
          </Dropzone>
        </div>
        <SortableList
          photos={this.state.photos}
          deletePhoto={this.deletePhoto}
          setCover={this.setCover}
          onSortEnd={this.onSortEnd}
          helperClass={`${c}_sortableHelper`}
          axis="xy"
          useDragHandle
          transitionDuration={250}
        />
        {this.renderInfoMessage()}
      </div>
    )
  }
}

const SortableList = SortableContainer(props => (
  <div className={`${c}_photos`}>
    {props.photos.sort((a, b) => a.index - b.index).map((photo, idx) => (
      <SortableItem
        key={`item-${idx}`}
        index={idx}
        idx={idx}
        photo={photo}
        deletePhoto={props.deletePhoto}
        setCover={props.setCover}
      />
    ))}
  </div>
))

const SortableItem = SortableElement(({ idx, photo, deletePhoto, setCover }) => (
  <div className={`${c}_photo`}>
    <div
      className={`${c}_photo_inner`}
      style={{ backgroundImage: `url(${photo.base64Preview || getPhotoUrl(photo.imageUrl, 'small')})` }}
    >
      <SortableHandleOverlay />
      {photo.isUploading && (
        <div className={`${c}_photo_spinner_container`}>
          <Spinner size={80} color="#fff" />
        </div>
      )}
      <Icon type="trash" onClick={() => deletePhoto(idx)} />
      {photo.cover ? (
        <div className={`${c}_photo_isCover`}>
          Cover Photo
        </div>
      ) : (
        <div
          className={`${c}_photo_setCover`}
          onClick={() => setCover(idx)}
        >
          Set as Cover
        </div>
      )}
    </div>
  </div>
))

const SortableHandleOverlay = SortableHandle(({ photo }) => (
  <div className={`${c}_photo_dragHandle`} />
))

PhotosUploader.propTypes = propTypes
PhotosUploader.defaultProps = defaultProps

export default PhotosUploader
