import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { queryStringToObject } from '@/shared/utils/url'
import { handleError } from '@/shared/api'
import { updateNewsletter } from '@/subscriptions/api'
import { Head, Icon, Button } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/subscriptions/styles/unsubscribe.scss'

const c = 'subscriptionsUnsubscribe'

const Unsubscribe = () => {
  const [status, setStatus] = useState({
    isUnsubscribing: false, 
    isUnsubscribed: false
  })

  const location = useLocation()

  const handleSubmit = () => {
    setStatus({ ...status, isUnsubscribing: true })
    updateNewsletter({
      token: queryStringToObject(location.search).token,
      subscribed: false,
    }).then(() => {
      setStatus({ isUnsubscribing: false, isUnsubscribed: true })
    }, handleError)
  }

  return (
    <div className={c}>
      <Head title="Unsubscribe - Roomsie" />
      <AppHeader />
      <div className={`${c}_page`}>
        {status.isUnsubscribed ? (
          <>
            <Icon type="check-circle" />
            <div className={`${c}_title`}>
              You will no longer receive any emails about new listings from Roomsie.
            </div>
          </>
        ) : (
          <>
            <div className={`${c}_title`}>
              Are you sure you want to stop receiving information about new listings?
            </div>
            <Button working={status.isUnsubscribing} onClick={handleSubmit}>
              Unsubscribe
            </Button>
          </>
        )}
      </div>
      <AppFooter />
    </div>
  )
}

export default Unsubscribe
