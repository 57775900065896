import _ from 'lodash';
import { is, getErrors } from '@/shared/utils/validation';

export const validateSteps = (userInput, selectedBedroom) => ({
  room: validateRoom(userInput, selectedBedroom),
  information: validateInformation(userInput, selectedBedroom),
  payment: validatePayment(userInput, selectedBedroom),
});

const validateRoom = (userInput, selectedBedroom) => {
  const errors = getErrors(userInput, {
    gender: is.match((v) => {
      if (selectedBedroom.property.placementType === 'unisex') {
        return true;
      }
      return v == selectedBedroom.property.placementType;
    }, `Selected listing is ${selectedBedroom.property.placementType} only. Please select another listing.`),
  });
  return { errors, isValid: _.isEmpty(errors) };
};

const validateInformation = (userInput) => {
  const errors = getErrors(userInput, {
    firstName: is.required(),
    lastName: is.required(),
    gender: is.match((v) => v === 'male' || v === 'female'),
    email: [is.required(), is.email()],
    phone: is.required(),
    birthDate: is.required(),
    acceptedToS: is.match((v) => v === 'true', 'Please agree to continue'),
  });
  return { errors, isValid: _.isEmpty(errors) };
};

const validatePayment = (userInput) => {
  const errors = getErrors(userInput, {
    billingName: is.required(),
    billingLine1: is.required(),
    billingCity: is.required(),
    billingPostalCode: is.required(),
    billingCountry: is.required(),
    billingPhone: is.required(),
  });
  return { errors, isValid: _.isEmpty(errors) };
};
