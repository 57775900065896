import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Textarea, Modal } from '@/shared/components'
import '@/shared/styles/textEditor.scss'

const propTypes = {
  className: PropTypes.string,
}

const TextEditor = ({ className, ...textareaProps }) => (
  <div className={classnames('textEditor', className)}>
    <Textarea minRows={5} {...textareaProps} />
    <div className="textEditor_tip">
      <strong>**bold**</strong>
      <em>*italic*</em>
      <Modal
        className="textEditor_formattingHelp"
        renderLink={() => (
          <div className="textEditor_formattingHelp_link">
            Formatting Help
          </div>
        )}
        renderContent={() => (
          <Fragment>
            <div>
              <div>You Type:</div>
              <div>People See:</div>
            </div>
            <div>
              <div># Heading</div>
              <div><h1>Heading</h1></div>
            </div>
            <div>
              <div>**bold**</div>
              <div><strong>bold</strong></div>
            </div>
            <div>
              <div>*italics*</div>
              <div><em>italics</em></div>
            </div>
            <div>
              <div>~~strikethrough~~</div>
              <div><strike>strikethrough</strike></div>
            </div>
            <div>
              <div>
                - item 1
                <br />
                - item 2
                <br />
                - item 3
              </div>
              <div>
                <ul>
                  <li>item 1</li>
                  <li>item 2</li>
                  <li>item 3</li>
                </ul>
              </div>
            </div>
          </Fragment>
        )}
      />
    </div>
  </div>
)

TextEditor.propTypes = propTypes

export default TextEditor
