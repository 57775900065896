import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import * as api from '@/hostBookings/api'
import { Button, ConfirmModal } from '@/shared/components'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const StatusModals = (props) => {

  const navigate = useNavigate()
  
  const handleChange = (request, status) => {
    request(props.booking.id).then(() => {
      props.fetchBooking(() => {
        showToast({ title: `Your booking was ${status} successfully.` })
      })
    }, handleError)
  }

  return (
    <Fragment>
      {props.booking.status === 'extended' && (
        <Fragment>
          <ConfirmModal
            renderLink={() => (
              <Button hollow color="danger" icon="close">Decline Request</Button>
            )}
            title="Decline Request?"
            confirmText="Decline Request"
            message="Are you sure you want to decline this booking request?"
            isDanger
            dontCloseOnConfirm
            onConfirm={() => handleChange(api.rejectExtendedBooking, 'rejected')}
          />
          {/*<ConfirmModal*/}
          {/*  renderLink={() => <Button icon="check">Accept Request</Button>}*/}
          {/*  title="Extend Booking"*/}
          {/*  confirmText="Extend Booking"*/}
          {/*  message="Are you sure you want to extend this booking request?"*/}
          {/*  isDanger*/}
          {/*  dontCloseOnConfirm*/}
          {/*  onConfirm={() => handleChange(api.acceptExtendedBooking, 'extend')}*/}
          {/*/>*/}
        </Fragment>
      )}
      {props.booking.status === 'pending' && (
        <Fragment>
          <ConfirmModal
            renderLink={() => (
              <Button hollow color="danger" icon="close">Decline</Button>
            )}
            title="Decline Booking"
            confirmText="Decline Booking"
            message="Are you sure you want to decline this booking request?"
            isDanger
            dontCloseOnConfirm
            onConfirm={() => handleChange(api.declineBooking, 'declined')}
          />
          {props.currentUser.hasBankAccount && props.currentUser.stripeAccount ? (
            <ConfirmModal
              renderLink={() => <Button icon="check">Accept</Button>}
              title="Accept Booking"
              confirmText="Accept Booking"
              message="Once you accept this booking all Roomsie policies, terms, and conditions will apply to this booking. Accept this booking request?"
              dontCloseOnConfirm
              onConfirm={() => handleChange(api.acceptBooking, 'accepted')}
            />
          ) : (
            <ConfirmModal
              renderLink={() => <Button icon="check">Accept</Button>}
              title="Add Payout Method"
              confirmText="Add Payout Method"
              message="You need to add a payout method before accepting any booking requests. We require this in order to verify your information and make sure you can accept payments."
              onConfirm={() => {
                navigate('/host/payment-center')
              }}
            />
          )}
        </Fragment>
      )}
      {props.booking.status === 'accepted' && props.booking.kind != 'import' && (
        <ConfirmModal
          renderLink={() => <Button hollow color="danger">Cancel Booking</Button>}
          title="Cancel Booking"
          confirmText="Cancel Booking"
          cancelText="Nevermind"
          confirmInput="CANCEL"
          message={
            <Fragment>
              All Roomsie
              <Link to="/cancellation-policy" target="_blank">
                {' policies, terms, and conditions '}
              </Link>
              will apply upon cancellation of this booking. Are you sure you want to cancel this booking?
            </Fragment>
          }
          isDanger
          dontCloseOnConfirm
          onConfirm={() => handleChange(api.cancelBooking, 'canceled')}
        />
      )}
    </Fragment>
  )
}

StatusModals.propTypes = propTypes

export default connect(mapStateToProps)(StatusModals)
