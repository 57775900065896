import React, { Component, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useLocation, useSearchParams, useLoaderData } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from '@/shared/hooks/withRouter'
import { Head } from '@/shared/components'
import { isCurrentUserAuthenticated, getStoredCurrentUser } from '@/features/auth/utils'
import { fetchCurrentUser, updateCurrentUserState } from '@/features/auth/state/actions'
import AuthModals from '@/features/auth/components/AuthModals'
import HelpModal from '@/features/helpModal/components'
import Toast from './Toast'
import Reminders from './Reminders'
import AdminMode from './AdminMode'
import ProfileShowModal from '../../profile/components/ProfileShowModal'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { fetchCurrentUser, updateCurrentUserState }

const propTypes = {
  currentUser: PropTypes.object,
  fetchCurrentUser: PropTypes.func.isRequired,
  updateCurrentUserState: PropTypes.func.isRequired
}

const App = (props) => {
  const { search, pathname } = useLocation()

  useEffect(() => {
    if (getStoredCurrentUser()) {
      props.updateCurrentUserState(getStoredCurrentUser())
    }
    fetchCurrentUserIfAuthenticated()
    // Keep refreshing auth token every 20min while browser tab is open
    setInterval(fetchCurrentUserIfAuthenticated, 20 * 60 * 1000)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const fetchCurrentUserIfAuthenticated = () => {
    if (isCurrentUserAuthenticated()) {
      props.fetchCurrentUser()
    }
  }

  return (
    !isCurrentUserAuthenticated() ||
    props.currentUser ||
    pathname === '/signout'
  ) && (
    <Fragment>
      <Head />
      <Toast />
      <Reminders />
      <AdminMode />
      <AuthModals />
      <HelpModal />
      <ProfileShowModal />
      <Outlet />
    </Fragment>
  )
}

App.propTypes = propTypes

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
