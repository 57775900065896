import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import options from '@/shared/static/options'
//import CountSlider from './CountSlider'
import { Button, CheckboxGroup, Checkbox } from '@/shared/components'

const propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilterChanges: PropTypes.func.isRequired,
  clearFilterSection: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
}

const c = 'search_filters_more'

const MoreFilters = props => {

  const modifiedOptions = {
    ...options,
    listingTypes: [
      { value: 'hosted', label: 'Hosted' },
      { value: 'shared', label: 'Shared' },
    ],
  }

  const descriptions = {
    listingTypes: {
      hosted: 'The owner/host lives on the property',
      shared: 'The property is exclusively occupied by fellow Roomsie guests',
    },
    bedSizes: {
      twin: '38 in. x 75 in.',
      twin_xl: '38 in. x 80 in.',
      full: '54 in. x 75 in.',
      queen: '60 in. x 80 in.',
      king: '76 in. x 80 in.',
      ca_king: '72 in. x 84 in.',
    },
  }

  const renderCheckboxGroup = section => (
    <CheckboxGroup
      value={props.filters[section]}
      onChange={value => {
        props.onFilterChange({ [section]: value })
      }}
      render={({ isChecked, onCheck }) => (
        modifiedOptions[section].map(({ value, label }) => (
          <div className={`${c}_col`} key={value}>
            <Checkbox
              section={section}
              value={value}
              label={label}
              checked={isChecked(value)}
              onChange={onCheck}
            />
            {descriptions[section] && (
              <div className={`${c}_tip`}>
                {descriptions[section][value]}
              </div>
            )}
          </div>
        ))
      )}
    />
  )

  return (
    <div className={c}>
      <div className={`${c}_content`}>
        {/*<div className={`${c}_slider_row`}>
          <CountSlider
            filters={props.filters}
            itemType="bedrooms"
            onFilterChange={props.onFilterChange}
          />
          <CountSlider
            filters={props.filters}
            itemType="bathrooms"
            onFilterChange={props.onFilterChange}
          />
        </div>*/}
        {/*<div className={`${c}_title`}>Listing Type</div>
        {renderCheckboxGroup('listingTypes')}
        <div className={`${c}_title`}>Bed Size</div>
        {renderCheckboxGroup('bedSizes')}
        <div className={`${c}_title`}>Bed Type</div>
        {renderCheckboxGroup('bedTypes')}*/}
        <div className={`${c}_title`}>Amenities</div>
        {renderCheckboxGroup('amenities')}
        {/*<div className={`${c}_title`}>Property Type</div>
        {renderCheckboxGroup('propertyTypes')}*/}
      </div>
      <div className={`${c}_actions`}>
        <Button hollow onClick={props.closeDropdown}>
          Close
        </Button>
        <div className={`${c}_actions_right`}>
          <Button hollow color="secondary" onClick={() => {
            props.clearFilterSection('more')
          }}>
            Clear
          </Button>
          <Button onClick={() => {
            props.applyFilterChanges()
            props.closeDropdown()
          }}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}

MoreFilters.propTypes = propTypes

export default MoreFilters
