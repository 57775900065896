import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import { cancelBooking } from '@/hostBookings/api'
import { Modal, Button, Field, Form, ConfirmModal } from '@/shared/components'
import {Link} from "react-router-dom";

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const c = 'hostBooking_cancelImported'

class CancelImported extends Component {

  handleChange = (request, status) => {
    request(this.props.booking.id).then(() => {
      this.props.fetchBooking(() => {
        showToast({ title: `Your booking was ${status} successfully.` })
      })
    }, handleError)
  }

  render() {
    const { booking } = this.props
    return (
      <ConfirmModal
        renderLink={() => <Button hollow color="danger">Cancel Booking</Button>}
        title="Cancel Booking"
        confirmText="Cancel Booking"
        cancelText="Nevermind"
        confirmInput="CANCEL"
        message={
          <Fragment>
            All Roomsie
            <Link to="/cancellation-policy" target="_blank">
              {' policies, terms, and conditions '}
            </Link>
            will apply upon cancellation of this booking. Are you sure you want to cancel this booking?
          </Fragment>
        }
        isDanger
        dontCloseOnConfirm
        onConfirm={() => this.handleChange(cancelBooking, 'canceled')}
      />
    )
  }
}

CancelImported.propTypes = propTypes

export default CancelImported
