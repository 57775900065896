export const isTouch = () => (
  !!(typeof window !== 'undefined' &&
    ('ontouchstart' in window ||
      (window.DocumentTouch &&
        typeof document !== 'undefined' &&
        document instanceof window.DocumentTouch))) ||
  !!(typeof navigator !== 'undefined' &&
    (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
)

export const isMobileBrowser = () => (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
)

export const isIOS = () => (
  /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream
)
