import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { formatPrice } from '@/shared/utils/numbers'
import '@/shared/styles/bookingFees.scss'

const propTypes = {
  rates: PropTypes.object.isRequired,
}

const BookingFees = ({ rates }) => {

  const renderItem = (label, kind) => {
    const item = rates.items.find(i => i.kind === kind)
    return item && renderFee(label, item.total)
  }

  const renderFee = (label, total) => {
    return (
      <div key={label} className="bookingFees_fee">
        {label}
        <span>
          {`${total < 0 ? '—' : ''}
            ${formatPrice(Math.abs(total), { precise: true })}`}
        </span>
      </div>
    )
  }
  const renderRoomsieFee = (total) => {
    return (
      <div key="Roomsie Fees" className="bookingFees_fee">
        Roomsie Fees
        {
          isNaN(total) ? (
            <span>
              Free
            </span>
          ) : (
            <span>
             - {`${total < 0 ? '—' : ''}
              ${formatPrice(Math.abs(total), {precise: true})}`}
            </span>
          )
        }
      </div>
    )
  }
  return (
    <div className="bookingFees">
      {!!rates.property?.prorateForShortStay ? (
        renderItem(
          `${formatPrice(rates.avgRate, { precise: true })} x ${rates.stayLength} Nights`,
          'rental'
        )
      ) : (
        renderItem(
          `Housing Cost`,
          'rental'
        )
      )}
      {renderItem('Cleaning Fee', 'cleaning')}
      {renderItem('Parking Fee', 'parking')}
      {renderItem('Pets Fee', 'pets')}
      {renderItem('Gym Membership Fee', 'gym')}
      {renderItem('Security Deposit', 'security_deposit')}
      {renderItem('Occupancy Tax', 'taxes')}
      {renderRoomsieFee(rates.hostPayout * 0.075)}

      <div className="bookingFees_total">
        {rates.hostPayout ? (
          renderFee('Total Payout', rates.hostPayout - (rates.hostPayout * 0.075))
        ) : (
          renderFee('Grand Total', rates.total)
        )}
      </div>
    </div>
  )
}

BookingFees.propTypes = propTypes

export default BookingFees
