import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { formatDateRange } from '@/shared/utils/dateTime'
import { formatAddress } from '@/shared/utils/geo'
import { statuses, getCurrentBooking } from '@/shared/utils/guestBookings'
import { handleError } from '@/shared/api'
import { fetchBookings } from '@/guestBookings/api'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import MessageModal from '@/features/messageModal/components'
import { DashboardGuestSideMenu, PageSpinner, Button, GuestCurrentBooking,
  Switch, Photo, Head, EmptyState, InfoBanner } from '@/shared/components'
import '@/guestBookings/styles/bookings.scss'

const c = 'guestBookings'

class Bookings extends Component {

  state = { bookings: null, currentBooking: null }

  componentDidMount() {
    analytics.pageview('Guest Bookings')

    fetchBookings().then(({ data }) => {
      const currentBooking = getCurrentBooking(data)
      this.setState({
        bookings: currentBooking ? data.filter(b => b.id !== currentBooking.id) : data,
        currentBooking,
      })
    }, handleError)
  }

  hasAnyBookings = type => (
    !!_.flatMap(statuses[type], ({ value }) => (
      this.state.bookings.filter(b => b.status === value)
    )).length
  )

  renderSections = () => (
    <Fragment>
      {this.hasAnyBookings('primary') && (
        statuses.primary.map(this.renderSection)
      )}
      {!this.hasAnyBookings('primary') && !this.state.currentBooking && (
        this.renderEmptyState('You have no current or upcoming stays.')
      )}
      <Switch
        linkText="See declined, cancelled and expired bookings"
        shouldShowLink={() => this.hasAnyBookings('secondary')}
        renderSwitch={() => statuses.secondary.map(this.renderSection)}
      />
    </Fragment>
  )

  renderSection = ({ value, label }) => {
    const bookings = this.state.bookings.filter(b => b.status === value)
    return !!bookings.length && (
      <Fragment key={value}>
        <h1 className={`${c}_sectionTitle`}>{label}</h1>
        {bookings.map(this.renderBooking)}
      </Fragment>
    )
  }

  renderBooking = ({ propertySnapshot, ...booking }) => (
    <div key={booking.id} className={`${c}_booking`}>
      <div className={`${c}_booking_inner`}>
        <Link to={`/properties/${propertySnapshot.id}`} target="_blank">
          <Photo photo={propertySnapshot.photo} />
        </Link>
        <div className={`${c}_booking_content`}>
          <div className={`${c}_booking_dates`}>
            {formatDateRange(booking.startDate, booking.endDate)}
          </div>
          <Link to={`/properties/${propertySnapshot.id}`} target="_blank">
            <div className={`${c}_booking_title`}>
              {_.truncate(propertySnapshot.title, { length: 50 })}
            </div>
            <div className={`${c}_booking_address`}>
              {formatAddress(propertySnapshot)}
            </div>
          </Link>
        </div>
        <div className={`${c}_booking_actions`}>
          <Link to={`/guest/bookings/${booking.id}`}>
            <Button hollow size="small">Stay Details</Button>
          </Link>
          <MessageModal
            description="Questions about your housing that were not addressed in the listing? Ask your hosts! They are here to help."
            recipient={booking.host}
            contextId={{ bookingId: booking.id }}
            renderLink={() => (
              <div className={`${c}_booking_messageLink`}>Message Host</div>
            )}
          />
        </div>
      </div>
    </div>
  )

  renderReferGuests = () => (
    <InfoBanner
      title="Earn $100 for every guest you refer"
      message="Get a friend to book on Roomsie and earn $100 when they complete their first booking."
      renderButton={() => (
        <Link to="/refer-guests">
          <Button square size="small">Learn More</Button>
        </Link>
      )}
    />
  )

  renderEmptyState = title => (
    <EmptyState
      title={title}
      message="Details about your stay will be displayed here after you send a booking request. You will be able to talk to your host, see map directions, and more."
      buttonText="Search for a place to stay"
      buttonLink="/search"
    />
  )

  render() {
    const { bookings, currentBooking } = this.state
    return (
      <div className={c}>
        <Head title="My Stays - Roomsie" />
        <AppHeader />
        <DashboardGuestSideMenu>
          {bookings ? (
            currentBooking || bookings.length ? (
              <Fragment>
                {this.renderReferGuests()}
                {currentBooking && (
                  <GuestCurrentBooking booking={currentBooking} />
                )}
                {this.renderSections()}
              </Fragment>
            ) : (
              this.renderEmptyState('You have not sent any booking requests yet.')
            )
          ) : (
            <PageSpinner />
          )}
        </DashboardGuestSideMenu>
        <AppFooter />
      </div>
    )
  }
}

export default Bookings
