import React, { Component, Fragment } from 'react'
import { Modal, Button, Icon } from '@/shared/components'
import { showToast } from '@/shared/utils/toast'
import { formatPrice } from '@/shared/utils/numbers'
import { stringToDate, formatDate } from '@/shared/utils/dateTime'
import { captureFailedCharges, fetchFailedCharges } from '@/guestPayment/api'
import '@/guestPayment/styles/failedPayments.scss'

const c = 'guestPayment_failedPayments'

class FailedPayments extends Component {

  state = {
    charges: [],
    isSubmitting: false,
    error: null,
  }

  componentDidMount() {
    fetchFailedCharges().then(({ data }) => {
      this.setState({ charges: data })
    }, () => {})
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true })

    captureFailedCharges().then(() => {
      this.setState({
        error: false,
        charges: [],
        isSubmitting: false,
      })
    }, () => {
      this.setState({
        error: 'We could not charge your credit card.',
        isSubmitting: false,
      })
    })
  }

  totalOutstanding = () => (
    this.state.charges.reduce((sum, charge) => sum + charge.total, 0)
  )

  renderSuccessModal = () => (
    <Modal
      isOpen
      className={`${c}_successModal`}
      renderContent={({ closeModal }) => (
        <Fragment>
          <Icon type="check-circle" className={`${c}_successModal_icon`} />
          <div className={`${c}_successModal_title`}>
            Congrats!
          </div>
          <div className={`${c}_successModal_subtitle`}>
            You have successfully paid the outstanding balance.
          </div>
          <Button hollow onClick={closeModal}>Okay</Button>
        </Fragment>
      )}
    />
  )

  renderErrorModal = () => (
    <Modal
      isOpen
      className={`${c}_errorModal`}
      onClose={() => this.setState({ error: null })}
      renderContent={({ closeModal }) => (
        <Fragment>
          <Icon type="warning" className={`${c}_errorModal_icon`} />
          <div className={`${c}_errorModal_title`}>
            Error!
          </div>
          <div className={`${c}_errorModal_subtitle`}>
            {this.state.error}
          </div>
          <p>{`Before trying again, please make sure you have taken the following steps to insure a successful transaction:`}</p>
          <p><span>{`1) Contact your bank at the number listed on the back of your card to inform them of a charge in this amount. Sometimes banks will mistakenly flag larger transactions as fraudulent and decline the charges.`}</span></p>
          <p><span>{`2) Insure that your available credit or debit balance is enough to cover this amount.`}</span></p>
          <p>{`Failure to follow these two simple steps can, and may, result in the cancellation of your reservation.`}</p>
          <Button hollow onClick={closeModal}>
            I Understand
          </Button>
        </Fragment>
      )}
    />
  )

  render() {
    const { charges, error, isSubmitting } = this.state
    return (
      <Fragment>
        {!!charges.length && (
          <div className={c}>
            <h2>Failed Payments</h2>
            {charges.map((charge, i) => (
              <div key={i} className={`${c}_item`}>
                {`${i + 1}. ${'Failed to charge '} `}
                <span>{formatPrice(charge.total, { precise: true })}</span>
                {` on ${formatDate(stringToDate(charge.date))}`}
              </div>
            ))}
            <Button working={isSubmitting} onClick={this.handleSubmit}>
              {`Pay ${formatPrice(this.totalOutstanding())} Now`}
            </Button>
            {isSubmitting && (
              <div className={`${c}_pleaseWait`}>
                Please wait, this could take a minute or two...
              </div>
            )}
          </div>
        )}
        {error === false && this.renderSuccessModal()}
        {error && this.renderErrorModal()}
      </Fragment>
    )
  }
}

export default FailedPayments
