import React, { Component, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Outlet, Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { generatePath } from 'react-router'
import * as url from '@/shared/utils/url'
import {
  fetchRates,
  fetchProgramSessionRates,
  fetchBuildings,
  fetchProgramSessions,
} from '@/plugin/property/api'
import { handleError } from '@/shared/api'
import * as initialState from '@/plugin/property/state/initialState'
import storage from '@/shared/utils/storage'
import queryManager from '@/shared/hocs/queryManager'
import { steps } from '@/plugin/property/state/steps'
import { validateSteps } from '@/plugin/property/state/validation'
import { scrollToFirstError } from '@/shared/utils/validation'
import roomsieLogo from '@/shared/assets/images/logo.png'
import BuildingsStepComponent from './BuldingsStepComponent'
import StepBar from './StepBar'
import '@/plugin/property/styles/index.scss'

export const buildingsStepRoutes = 
  steps.map(({ id, path, Component }, i) => ({
    path: `/plugins/buildings/:buildingId${path}`,
    element: <BuildingsStepComponent id={id} path={path} Component={Component} />
  })
)

const propTypes = {
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
}

const c = 'plugin'

const PluginProperty = (props) => {
  // Make sure to increase version every time you make changes to state structure
  // to prevent users from pulling data with old structure from their local storage
  const version = '1'

  const { listingId, buildingId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const isFirstRender = useRef(true)

  const [state, setState] = useState({
    buildings: null,
    sessions: [],
    property: null,
    selectedBedroom: null,
    rates: null,
    buildingId: null,
    generatedPath: null,
    userInput: initialState.getUserInput(),
    errors: initialState.getErrors(),
    isSubmitting: false,
    isLoaded: false,
  })

  useEffect(() => {
    const {
      selectedListingId,
      startDate,
      endDate,
      placementTypes,
    } = getQuery()

    fetchStoredFormState()
    getBuildings()
    getProgramSessions()

    setState(prevState => ({
      ...prevState,
      buildingId: buildingId,
      generatedPath: generatePath(location.pathname, {
        buildingId: buildingId,
      }), 
    }))

    if (!selectedListingId || !startDate || !endDate || !placementTypes) {
      redirectToRoomSelection()
    }
    getRates()
  }, [])
  
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const {
      selectedListingId,
      startDate,
      endDate,
      placementTypes,
    } = getQuery()    
    if (!selectedListingId || !startDate || !endDate | !placementTypes) {
      redirectToRoomSelection()
    }
    getRates()
  }, [location.search])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    storeFormState()
  }, [state.userInput, state.selectedBedroom])
  
  useEffect(() => {
    scrollToFirstError()
  }, [state.errors])
  
  const getBuildings = () => {
    fetchBuildings().then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        buildings: data
      }))
    }, handleError)
  }

  const getProgramSessions = () => {
    fetchProgramSessions().then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        sessions: data
      }))
    }, handleError)
  }

  const getRates = () => {
    const {
      selectedListingId,
      programSessionId,
      startDate,
      endDate,
      additionalFees,
    } = getQuery()
    if (!selectedListingId || !startDate || !endDate) return

    if (programSessionId) {
      fetchProgramSessionRates(programSessionId, selectedListingId, {
        additionalFees,
      }).then(({ data }) => {
        setState(prevState => ({
          ...prevState,
          rates: data
        }))
      }, redirectToRoomSelection)
    } else {
      fetchRates(selectedListingId, {
        startDate,
        endDate,
        additionalFees,
      }).then(({ data }) => {
        setState(prevState => ({
          ...prevState,
          rates: data
        }))
      }, redirectToRoomSelection)
    }
  }

  const getQuery = () => url.queryStringToObject(location.search)

  const redirectToRoomSelection = () => {
    navigate({
      pathname: generatePath(location.pathname, {
        buildingId: buildingId,
      }),
      search: location.search,
    })
  }

  const getAnalyticsProps = (property = state.property) => {
    const { startDate, endDate, additionalFees } =
      getQuery()
    return {
      listingId: listingId,
      propertyId: propertyId,
      propertyTitle: _.get(property, 'title'),
      placementType: _.get(property, 'placementType'),
      additionalFees,
      startDate,
      endDate,
      stayDuration: getTimeDifference(endDate, startDate),
    }
  }

  const trackPageview = (property, error) => {
    analytics.pageview('Booking Wizard', {
      ...getAnalyticsProps(property),
      error,
    })
  }

  const trackEvent = (eventName, eventProps) => {
    analytics.track(eventName, {
      ...getAnalyticsProps(),
      ...eventProps,
    })
  }

  const fetchStoredFormState = () => {
    const storedState = storage.local.getJSON('bookingFormState')

    if (storedState && storedState.version === version) {
      updateUserInput(storedState.userInput)
      setSelectedBedroom(storedState.userInput.selectedBedroom)
    }
    setState(prevState => ({
      ...prevState,
      isLoaded: true
    }))
  }

  const storeFormState = () => {
    const { userInput, selectedBedroom } = state

    storage.local.setJSON('bookingFormState', () => ({
      version: version,
      userInput: {
        ...userInput,
        selectedBedroom: selectedBedroom,
      },
    }))
  }

  const resetFormState = () => {
    storage.local.setJSON('bookingFormState', () => ({
      version: version,
      userInput: {
        ...initialState.getUserInput(),
        selectedBedroom: null,
      },
    }))
    setState(prevState => ({
      ...prevState,
      isLoaded: true
    }))
  }

  const updateUserInput = (userInputData) => {
    setState(prevState => ({
      ...prevState,
      userInput: { ...state.userInput, ...userInputData }
    }))
  }

  const setBuildingId = async (newId) => {
    await props.updateQuery({ selectedListingId: null, homePage: null })
    navigate({
      pathname: `/plugins/buildings/${newId}`,
      search: location.search,
    })
    navigate(0)
    setState(prevState => ({
      ...prevState,
      buildingId: buildingId
    }))
  }

  const setSelectedBedroom = (value) => {
    setState(prevState => ({
      ...prevState,
      selectedBedroom: value
    }))
  }

  const bedIdExists = (bedId) => {
    const bedsArray = state.selectedBedroom?.beds
    return bedsArray?.some((el) => el.listing.id === parseInt(bedId))
  }

  const submitForm = () => {
    const { invalidStepId } = validateAllSteps()
    if (invalidStepId) {
      return goToStep(invalidStepId)
    }
  }

  const validateAllSteps = () => {
    const stepValidations = validateSteps(
      state.userInput,
      state.selectedBedroom
    )
    const formSteps = steps.filter((s) => s.id !== 'confirmation')
    const invalidStep = formSteps.find(
      (step) => !stepValidations[step.id].isValid
    )
    const invalidStepId = _.get(invalidStep, 'id')

    if (invalidStepId) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...state.errors,
          [invalidStepId]: stepValidations[invalidStepId].errors,
        },
      }))
    }
    return { invalidStepId }
  }

  const validateStep = (step) => () => {
    const { errors, isValid } = validateSteps(
      state.userInput,
      state.selectedBedroom
    )[step]
    if (!isValid) {
      setState(prevState => ({
        ...prevState,
        errors: { ...state.errors, [step]: errors }
      }))
    }
    return { isValid }
  }

  const clearStepErrors =
    (step) =>
    (paths, value = null) => {
      const errors = _.cloneDeep(state.errors)
      let hasClearedAnyErrors = false

      _.flatten([paths]).forEach((path) => {
        if (!_.isEmpty(_.get(errors, `${step}.${path}`))) {
          _.set(errors, `${step}.${path}`, value)
          hasClearedAnyErrors = true
        }
      })
      if (hasClearedAnyErrors) {
        setState(prevState => ({
          ...prevState,
          errors: errors
        }))
      }
    }

  const getCurrentStepIndex = () =>
    _.findIndex(steps, { path: getCurrentStepPath() })

  const getCurrentStepPath = () =>
    location.pathname.split(state.generatedPath)[1]

  const goToStep = (step) => {
    let path = null
    if (step === 'prev' || step === 'next') {
      const index = getCurrentStepIndex() + (step === 'prev' ? -1 : 1)
      path = steps[index].path
    } else {
      path = steps.find((s) => s.id === step).path
    }
    window.scrollTo(0, 100)
    navigate(toStep(path))
  }

  const toStep = (stepPath) => ({
    pathname: `${state.generatedPath}${stepPath}`,
    search: location.search,
  })

  const {
    buildings,
    sessions,
    property,
    rates,
    userInput,
    selectedBedroom,
    generatedPath,
    isLoaded,
  } = state
  const { query, updateQuery } = props

  return isLoaded ? (
    <div className={`${c}_container`}>
      <div className={c}>
        <Header />
        <StepBar
          steps={steps}
          currentStepIndex={getCurrentStepIndex()}
          toStep={toStep}
        />
        {buildings &&
          <Outlet 
            context={[ 
              generatedPath, 
              updateQuery, 
              sessions,
              buildings,
              buildingId,
              setBuildingId,
              selectedBedroom,
              setSelectedBedroom,
              property,
              rates,
              userInput,
              updateUserInput,
              getQuery,
              state,
              submitForm,
              steps,
              toStep,
              goToStep,
              validateStep,
              clearStepErrors,
              resetFormState
            ]} 
          />
        }
      </div>
    </div>
  ) : (
    <span>
      Local Storage is not enabled! Please enable it or try exiting the
      private browsing mode.
    </span>
  )
}

const Header = ({ c = 'plugin_header' }) => (
  <div className={c}>
    <div className={`${c}_title_container`}>
      <h1 className={`${c}_title`}>Join us in D.C. today!</h1>
      <div className={`${c}_poweredBy`}>
        Powered By{' '}
        <Link to="/" target="_blank">
          <span className={`${c}_roomsieLogo`}>
            <img src={roomsieLogo} alt="logo" />
            Roomsie
          </span>
        </Link>
      </div>
    </div>
    <p className={`${c}_subtitle`}>
      We're delighted to welcome you to our nation's capital. Book your room
      instantly below. Still have questions? Call us at{' '}
      <a href="tel:1-202-579-9446">1-202-579-9446</a> or{' '}
      <button
        className={`${c}_btnLiveChat`}
        onClick={() => {
          if (window.zE) window.zE.activate();
        }}
      >
        Live Chat
      </button>{' '}
      from 9:00 a.m. to 6:00 p.m. EDT and we'll be happy to answer any
      questions you might have.
    </p>
  </div>
);

PluginProperty.propTypes = propTypes;

const PluginPropertyWithQuery = queryManager({
  defaultQuery: {
    startDate: null,
    endDate: null,
    selectedFees: [],
    selectedListingId: null,
    sessionId: null,
    programSessionName: null,
    placementTypes: '',
    privacyTypes: [],
    bedsCount: [],
    homePage: null,
  },
  parse: (query) => ({
    ...query,
    selectedListingId: !_.isNil(query.selectedListingId)
      ? +query.selectedListingId
      : null
  }),
  serialize: (query) => query,
})(PluginProperty)

export default PluginPropertyWithQuery
