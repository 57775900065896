import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { stringsToDateRange, dateRangeToStrings, stringToDate, getTimeDifference } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { availableToUnavailableDates } from '@/shared/utils/availability'
import { DateRangePicker, Icon, Button } from '@/shared/components'

const propTypes = {
  allDatesListings: PropTypes.object.isRequired,
  selectedDatesListings: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  onDatesSelected: PropTypes.func,
}

const c = 'property_booking'

class Dates extends Component {

  getAllDisabledListingsDates = () => availableToUnavailableDates(
    this.props.allDatesListings.availableDates.map(stringsToDateRange)
  )

  getSessionDuration = (type = 'days') => {
    const session = this.props.property?.sessions[0]
    const start = stringToDate(session?.startDate)
    const end = stringToDate(session?.endDate)

    return getTimeDifference(end, start, type)
  }

  getListingNightlyRate = () => (
    this.props.allDatesListings?.minRate
  )

  getListingRentalTotal = () => Math.ceil(this.props.allDatesListings?.availableDates[0]?.rate) * parseInt(this.getSessionDuration())

  getListingMinStayDays = () => {
    const dates = _.get(this.props.selectedDatesListings[this.props.selectedListing?.id], 'availableDates', [])
    return _.min(dates.map(d => d.minimumStayDays)) || this.props.allDatesListings?.minStayDays
  }

  render() {
    return (
      <Fragment>
        <div className={`${c}_top`}>
          {!!this.props.property?.prorateForShortStay && <div className={`${c}_title`}>From</div>}
          <div className={`${c}_title`}>
            {!!this.props.property?.prorateForShortStay ? 
             ( 
                <div>
                  <span>{formatPrice(this.getListingNightlyRate() * 30)}</span>
                  per month
                </div> 
              ) : (
                <div>
                  <span>{formatPrice(this.getListingRentalTotal())}</span>
                  entire stay
                </div> 
              )
            }
          </div>
          <div className={`${c}_minStay`}>
            {!!this.props.property?.prorateForShortStay
                ? `${this.getListingMinStayDays() / 7} week minimum stay` 
                : `for up to ${this.getSessionDuration('weeks')} weeks of stay`
              }
          </div>
        </div>
        <div className={`${c}_bottom`}>
          {(!this.props.query?.startDate || !this.props.query?.endDate) && (
            <div className={`${c}_accuratePricingTip`}>
              <Icon type="lightbulb" />
              Enter dates for accurate pricing
            </div>
          )}
          <DateRangePicker
            placeholder="Check In — Check Out"
            value={stringsToDateRange(this.props.query)}
            onChange={(dates) => this.props.updateQuery(dateRangeToStrings(dates))}
            disabledDays={this.getAllDisabledListingsDates()}
            minimumStayDays={this.getListingMinStayDays()}
            tooltipProps={{ windowBounds: { bottom: true } }}
          />
          <div className={`${c}_message`}>
            {this.props.query.startDate && this.props.query.endDate ? (
              !!this.props.selectedDatesListings?.availableDates?.length ? (
                <Fragment>
                  {`Please select a bed you're interested in booking`}
                  {this.props.onDatesSelected && (
                    <Button size="small" onClick={this.props.onDatesSelected}>
                      Select a bed
                    </Button>
                  )}
                </Fragment>
              ) : (
                `There are no available beds for selected dates`
              )
            ) : (
              `Please select your arrival and departure dates`
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

Dates.propTypes = propTypes

export default Dates
