import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as analytics from '@/shared/utils/analytics'
import { Button, Icon, Head } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import '@/listSpace/styles/success.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
}

const c = 'listSpace_success'

class Success extends Component {

  componentDidMount() {
    analytics.pageview('List Space Success')
  }

  render() {
    return (
      <Fragment>
        <Head title="Success - Property Submitted" />
        <AppHeader />
        <div className={c}>
          <Icon type="check-circle" className={`${c}_icon`} />
          <div className={`${c}_title`}>
            Congrats! Your property has been successfully submitted to Roomsie.
          </div>
          {!this.props.currentUser.hasBankAccount && (
            <Fragment>
              <p className={`${c}_subtitle`}>
                Now let's get you paid. Before you can begin accepting booking requests you'll need to setup your payment method by visiting your payment center.
              </p>
              <Link to="/host/payment-center">
                <Button>Setup Payment</Button>
              </Link>
            </Fragment>
          )}
          <Link to="/host/properties">
            <Button hollow>View my Properties</Button>
          </Link>
          <div className={`${c}_listSpaceLink`}>
            <Link to="/list-space">Or List Another Space</Link>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(Success)
