import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import storage from '@/shared/utils/storage'
import { Button, Icon } from '@/shared/components'
import '@/global/styles/reminders.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
}

class Reminders extends Component {

  constructor(props) {
    super(props)
    const halfDay = 1000 * 60 * 60 * 12
    const lastTimeShown = storage.local.getJSON('timers', 'remindersHostProfile')
    this.state = {
      canBeOpen: !lastTimeShown || new Date().getTime() - (+lastTimeShown) > halfDay,
    }
  }

  close = () => {
    storage.local.updateJSON('timers', o => {
      o.remindersHostProfile = new Date().getTime()
    })
    this.setState({ canBeOpen: false })
  }

  renderHostProfileReminder = user => user.isHost && (!user.avatar || !user.bio) && (
    <div className="reminders_hostProfile">
      <div className="reminders_hostProfile_title">
        Complete your profile
      </div>
      <p className="reminders_hostProfile_description">
        Adding the following information will greatly increase your chances of getting booking requests.
      </p>
      <ul>
        {!user.avatar && <li>Avatar</li>}
        {!user.bio && <li>Bio</li>}
        {user.interests.length < 2 && <li>Interests</li>}
        {(!user.city || !user.country) && <li>City, Country</li>}
      </ul>
      <Link to="/profile">
        <Button size="small" onClick={this.close}>Go to profile settings</Button>
      </Link>
      <span className="reminders_hostProfile_cancel" onClick={this.close}>Cancel</span>
      <Icon type="close" onClick={this.close} />
    </div>
  )

  render() {
    const { currentUser } = this.props
    return currentUser && this.state.canBeOpen && (
      <Fragment>
        {this.renderHostProfileReminder(currentUser)}
      </Fragment>
    )
  }
}

Reminders.propTypes = propTypes

export default connect(mapStateToProps)(Reminders)
