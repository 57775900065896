import loadImage from 'blueimp-load-image'
import * as url from '@/shared/utils/url'

export const sortPhotos = photos => {
  photos = photos.sort((a, b) => a.index - b.index)
  const coverPhoto = photos.find(p => p.cover)
  return !coverPhoto ? photos : [
    coverPhoto,
    ...photos.filter(p => p !== coverPhoto),
  ]
}

const sizes = {
  large: { w: 1440, h: 960 },
  medium: { w: 960, h: 640 },
  small: { w: 600, h: 400 },
}

const avatarSizes = {
  large: { w: 500, h: 500 },
  small: { w: 150, h: 150 },
}

export const getPhotoUrl = (path, size) => {
  if (!sizes[size]) {
    console.error('Please select a valid size')
  }
  return url.joinPathnameSearch(path, url.objectToQueryString({
    ...sizes[size],
    fit: 'clip',
    auto: 'format,compress',
    fm: 'jpg',
  }))
}

export const getAvatarUrl = (path, size) => {
  if (!avatarSizes[size]) {
    console.error('Please select a valid size')
  }
  return url.joinPathnameSearch(path, url.objectToQueryString({
    ...avatarSizes[size],
    fit: 'crop',
    crop: 'faces',
    auto: 'format,compress',
    fm: 'jpg',
  }))
}

export const getPhotoDimensions = (photo, size) => {
  if (!sizes[size]) {
    console.error('Please select a valid size')
  }
  if (!photo.width || !photo.height) {
    return sizes[size]
  }
  const scale = Math.max(photo.width / sizes[size].w, photo.height / sizes[size].h)
  return {
    w: Math.round(photo.width / scale),
    h: Math.round(photo.height / scale),
  }
}

export const loadPhoto = (url, then) => {
  let dummyImage = new Image()
  const onLoad = () => {
    then()
    dummyImage.removeEventListener('load', onLoad)
    dummyImage = null
  }
  dummyImage.addEventListener('load', onLoad)
  dummyImage.src = url
}

export const getPhotoPreview = (file, then) => {
  loadImage(file, image => {
    let imgCanvas = document.createElement("canvas"),
    imgContext = imgCanvas.getContext("2d");
    imgCanvas.width = image.width;
    imgCanvas.height = image.height;
    imgContext.drawImage(image, 0, 0, image.width, image.height);
    then(imgCanvas.toDataURL("image/jpg", 0.8), {
      width: image.width,
      height: image.height,
    })
    // then(canvas.toDataURL('image/jpeg', 0.8), {
    //   width: canvas.width,
    //   height: canvas.height,
    // })
  }, {
    maxWidth: 450,
    orientation: true,
    pixelRatio: window.devicePixelRatio || 1,
  })
}
