import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import options from '@/shared/static/options'
import { timeOptions } from '@/shared/static/dateTime'
import * as analytics from '@/shared/utils/analytics'
import storage from '@/shared/utils/storage'
import { dateToString, stringToDate } from '@/shared/utils/dateTime'
import { to404 } from '@/shared/utils/404'
import { handleError } from '@/shared/api'
import * as api from '@/events/api'
import { Form, PageSpinner, Button, Field, Checkbox, CheckboxGroup,
  FieldLabel, FieldErrors, Head, LocationMap } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import EventPhotoUploader from './EventPhotoUploader'
import '@/events/styles/form.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object.isRequired
}

const c = 'eventsForm'

const EventsForm = (props) => {

  // Make sure to increase version every time you make changes to state structure
  // to prevent users from pulling data with old structure from their local storage
  const version = '1'

  const navigate = useNavigate()
  const { eventId } = useParams()
  const isEdit = !!eventId

  const [state, setState] = useState({ event: null })

  useEffect(() => {
    analytics.pageview('Event Form', {
      formType: isEdit ? 'Edit' : 'Create',
      eventId: eventId,
    })
    isEdit ? fetchExistingEvent() : fetchStoredFormState()
  }, [])

  const fetchExistingEvent = () => {
    api.fetchEvent(eventId).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        event: data 
      }))
    }, () => {
      to404('We could not find this event.')
    })
  }

  const fetchStoredFormState = () => {
    const storedState = storage.local.getJSON('createEventFormState')
    if (storedState && storedState.version === version) {
      setState(prevState => ({
        ...prevState,
        event: storedState.event
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        event: {}
      }))
    }
  }

  const storeFormState = event => {
    storage.local.setJSON('createEventFormState', () => ({
      version: version,
      event,
    }))
  }
  

  const handleCreateSubmit = (values, form) => {
    api.createEvent(values).then(({ data }) => {
      storage.local.remove('createEventFormState')
      navigate(`/events/show/${data.id}`)
    }, ({ error }) => {
      handleError(error, form)
    })
  }

  const handleUpdateSubmit = (values, form) => {
    api.updateEvent(eventId, values).then(() => {
      navigate(`/events/show/${eventId}`)
    }, ({ error }) => {
      handleError(error, form)
    })
  }

  const handleError = (error, form) => {
    if (error.details) {
      form.setErrors(error.details)
    } else {
      handleError({ error })
    }
    form.setSubmitting(false)
  }

  const getInterests = () => (
    props.currentUser.admin ?
      options.interests :
      options.interests.filter(i => i.value !== 'roomsie_socials')
  )

  const getInitialValues = () => {
    const get = (prop, defaultVal = '') => {
      const val = _.get(state.event, prop)
      return _.isNil(val) ? defaultVal : val
    }
    return {
      title: get('title'),
      maximumGuests: get('maximumGuests'),
      startDate: get('startDate', null),
      startTime: get('startTime'),
      endDate: get('endDate', null),
      endTime: get('endTime'),
      location: get('location'),
      coordinates: get('coordinates', null),
      mapZoom: get('mapZoom', null),
      description: get('description'),
      categories: get('categories', []),
      photo: get('photo', null),
    }
  }

  return (
    <div className={c}>
      <Head title={`${isEdit ? 'Edit' : 'Create'} Event - Roomsie`} />
      <AppHeader />
      {state.event ? (
          <EventForm 
            eventId={eventId}
            isEdit={isEdit} 
            getInitialValues={getInitialValues} 
            getInterests={getInterests}
            storeFormState={storeFormState} 
            handleUpdateSubmit={handleUpdateSubmit}
            handleCreateSubmit={handleCreateSubmit}
          /> 
        ) : ( 
          <PageSpinner />
      )}
      <AppFooter />
    </div>
  )
}

const EventForm = ({ 
  eventId,
  isEdit, 
  getInitialValues, 
  getInterests,
  storeFormState, 
  handleUpdateSubmit, 
  handleCreateSubmit 
}) => (
  <Form
    enableReinitialize
    initialValues={getInitialValues()}
    validations={{
      title: [Form.is.required(), Form.is.max(60)],
      maximumGuests: [Form.is.match(v => !v || +v >= 3, 'Must be at least 3')],
      startDate: Form.is.required(),
      startTime: Form.is.required(),
      location: Form.is.required(),
      coordinates: Form.is.required(),
      description: [Form.is.required(), Form.is.max(100000)],
      categories: Form.is.min(1, 'Event must have at least 1 category'),
      photo: Form.is.required('Please upload a cover photo'),
    }}
    onSubmit={isEdit ? handleUpdateSubmit : handleCreateSubmit}
    render={formProps => {
      if (!isEdit) {
        storeFormState(formProps.values)
      }
      return  <FormContent eventId={eventId} isEdit={isEdit} getInterests={getInterests} {...formProps} />
    }}
  />
)

const FormContent = ({
  eventId,
  isEdit,
  getInterests,
  handleSubmit,
  isSubmitting,
  fields,
  values,
  setValues,
  setFieldValue,
}) => {
  const toBack = isEdit ? `/events/show/${eventId}` : '/events'
  
  return (
    <form
      className={`${c}_page`}
      noValidate
      onSubmit={handleSubmit}
    >
      <div className={`${c}_breadcrumbs`}>
        <Link to={toBack}>Events</Link>
        {` / ${isEdit ? 'Edit' : 'Create'} Event`}
      </div>
      <h1 className={`${c}_title`}>
        {`${isEdit ? 'Edit' : 'Create'} Event`}
      </h1>
      <FieldLabel>Cover Photo</FieldLabel>
      <EventPhotoUploader
        photo={fields.photo.value}
        onChange={value => setFieldValue('photo', value)}
      />
      <FieldErrors
        errors={fields.photo.errors}
        className={`${c}_largeErrors`}
      />
      <p className={`${c}_field_subtitle`}>
        Please upload a cover photo that might help guests easily identify the nature of your event.
      </p>
      <Field.Input {...fields.title} label="Event Title" />
      <p className={`${c}_field_subtitle`}>
        Title should be easily scannable and convey the most important information.
      </p>
      <Field.Input
        {...fields.maximumGuests}
        className={`${c}_capacity`}
        label="Maximum no. of guests (optional)"
        placeholder="Enter a number"
        filter={/^[0-9]*$/}
      />
      <p className={`${c}_field_subtitle`}>
        Limit the amount of people who can attend the event.
      </p>
      <div className={`${c}_dates`}>
        <Field.DatePicker
          label="Start Date"
          placeholder="Select"
          value={stringToDate(fields.startDate.value)}
          onChange={value => setFieldValue('startDate', dateToString(value))}
          errors={fields.startDate.errors}
          disabledDays={fields.endDate.value ? [{
            after: stringToDate(fields.endDate.value),
          }] : undefined}
        />
        <Field.Select
          {...fields.startTime}
          label="Time"
          options={[{ label: 'Select', value: '' }, ...timeOptions]}
        />
        <div className={`${c}_dates_divider`}>to</div>
        <Field.DatePicker
          label="End Date (optional)"
          placeholder="Select"
          value={stringToDate(fields.endDate.value)}
          onChange={value => setFieldValue('endDate', dateToString(value))}
          errors={fields.endDate.errors}
          disabledDays={fields.startDate.value ? [{
            before: stringToDate(fields.startDate.value),
          }] : undefined}
        />
        <Field.Select
          {...fields.endTime}
          label="Time (optional)"
          options={[{ label: 'Select', value: '' }, ...timeOptions]}
        />
      </div>
      <Field.LocationSearch
        {...fields.location}
        label="Event Location"
        onChange={value => setFieldValue('location', value)}
        onSelect={place => {
          setValues({
            ...values,
            location: [
              place.streetWithNumber,
              place.city,
              place.stateCode,
              place.postalCode,
            ].filter(p => p).join(', '),
            coordinates: place.center || null,
            mapZoom: place.suggestedZoom || null,
          })
        }}
      />
      <LocationMap
        center={fields.coordinates.value}
        zoom={fields.mapZoom.value || 15}
        invalid={!!fields.coordinates.errors}
        onCoordinatesChange={value => setFieldValue('coordinates', value)}
      />
      <p className={`${c}_field_subtitle`}>
        Provide an understandable address to make it easy for people to locate your event.
      </p>
      <Field.TextEditor {...fields.description} label="Event Details" />
      <p className={`${c}_field_subtitle`}>
        Describe your event and explain why it's interesting. Why should people attend it? What's the schedule? Folks love learning about events so be as detailed as possible.
      </p>
      <FieldLabel>Categorize This Event</FieldLabel>
      <CheckboxGroup
        value={fields.categories.value}
        onChange={categories => setFieldValue('categories', categories)}
        render={({ isChecked, onCheck }) => (
          getInterests().map(category => (
            <div key={category.value}>
              <Checkbox
                size="small"
                value={category.value}
                label={category.label}
                checked={isChecked(category.value)}
                onChange={onCheck}
              />
            </div>
          ))
        )}
      />
      <FieldErrors
        errors={fields.categories.errors}
        className={`${c}_largeErrors`}
      />
      <p className={`${c}_field_subtitle`}>
        We compare event categories with user interests to match people with events they are passionate about.
      </p>
      <div className={`${c}_actions`}>
        <Link to={toBack}>
          <Button type="button" color="secondary">Cancel</Button>
        </Link>
        <Button type="submit" working={isSubmitting}>
          {isEdit ? 'Save Changes' : 'Create Event'}
        </Button>
      </div>
    </form>
  )
}

EventsForm.propTypes = propTypes

export default connect(mapStateToProps)(EventsForm)
