import api from '@/shared/api'

export const fetchProperty = propertyId => (
  api.get(`/guest/properties/${propertyId}`)
)

export const fetchRates = (listingId, query) => (
  api.get(`/listings/${listingId}/bookings/calculate`, query)
)

export const fetchProgramSessionRates = (programSessionId, listingId, query) => (
  api.get(`/program_sessions/${programSessionId}/listings/${listingId}/bookings/calculate`, query)
)

export const createBooking = (listingId, payload) => (
  api.post(`/listings/${listingId}/bookings`, payload)
)

export const createProgramSessionBooking = (programSessionId, listingId, payload) => (
  api.post(`/program_sessions/${programSessionId}/listings/${listingId}/bookings`, payload)
)

export const createUser = payload => (
  api.post(`/sponsor/users`, payload)
)

export const createImportedBooking = payload => (
  api.post(`/host/bookings`, payload)
)
