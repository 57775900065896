import React from 'react'
import PropTypes from 'prop-types'
import { ConfirmModal } from '@/shared/components'

const propTypes = {
  renderLink: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  isBed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

const TogglePaused = ({ renderLink, value, isBed, onChange }) => {
  const listingType = isBed ? 'bed' : 'property'
  return (
    <ConfirmModal
      title={value ? `Pause ${listingType}` : `Activate ${listingType}`}
      message={value ? (
        `Pausing this ${listingType} will remove the public listing and make it impossible for anyone to make a booking. Are you sure you want to pause this ${listingType}?`
      ) : (
        `Activating this ${listingType} will create a public listing and make it possible for anyone to make a booking. Are you sure you want to activate this ${listingType}?`
      )}
      confirmText={value ? 'Pause' : 'Activate'}
      onConfirm={onChange}
      dontCloseOnConfirm
      renderLink={renderLink}
    />
  )
}

TogglePaused.propTypes = propTypes

export default TogglePaused
