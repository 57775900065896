import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as analytics from '@/shared/utils/analytics'
import { copyToClipboard } from '@/shared/utils/html'
import { Head, Button } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/refer/styles/guest.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
}

const c = 'referGuest'

class ReferGuest extends Component {

  state = { isCopied: false }

  componentDidMount() {
    analytics.pageview('Refer Guests')
  }

  render() {
    return (
      <div className={c}>
        <Head title="Refer Guests - Roomsie" />
        <AppHeader />
        <div className={`${c}_page`}>
          <div className={`${c}_top`}>
            <h1 className={`${c}_title`}>
              {"Earn $100 for every guest you refer"}
            </h1>
            <div className={`${c}_subtitle`}>
              {"Get a friend to book on Roomsie and earn $100 when they complete their first booking."}
            </div>
            <div className={`${c}_link`}>
              <span>{this.props.currentUser.referralCode}</span>
              <Button square onClick={() => {
                copyToClipboard(this.props.currentUser.referralCode)
                this.setState({ isCopied: true })
                setTimeout(() => this.setState({ isCopied: false }), 2000)
              }}>
                {this.state.isCopied ? 'Copied' : 'Copy'}
              </Button>
            </div>
            <div className={`${c}_tip`}>
              {"Copy or share your referral code with friends"}
            </div>
          </div>
          <div className={`${c}_howItWorks`}>
            <div className={`${c}_howItWorks_title`}>
              How referrals work
            </div>
            <div className={`${c}_steps`}>
              <div className={`${c}_step`}>
                <div className={`${c}_step_number`}>1</div>
                <div className={`${c}_step_title`}>
                  Refer friends
                </div>
                <p className={`${c}_step_description`}>
                  {"Send referral code to your friends and tell them to enter it on the booking checkout page."}
                </p>
              </div>
              <div className={`${c}_step`}>
                <div className={`${c}_step_number`}>2</div>
                <div className={`${c}_step_title`}>
                  Follow along
                </div>
                <p className={`${c}_step_description`}>
                  {"Follow your friend’s progress and send encouraging messages along the way."}
                </p>
              </div>
              <div className={`${c}_step`}>
                <div className={`${c}_step_number`}>3</div>
                <div className={`${c}_step_title`}>
                  Get paid
                </div>
                <p className={`${c}_step_description`}>
                  {"When your friend completes their booking we'll send you a $100 check."}
                </p>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    )
  }
}

ReferGuest.propTypes = propTypes

export default connect(mapStateToProps)(ReferGuest)
