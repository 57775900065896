import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import media from '@/shared/utils/media'
import { stringToDate, getTimeDifference } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { pickFromQueryString } from '@/shared/utils/url'
import { sortPhotos } from '@/shared/utils/photos'
import { queryKeys } from '@/search/state/reducers'
import { Slider, Icon, Photo, Avatar, VerifiedMark } from '@/shared/components'
import { mapMarkerIdClass, mapMarkerActiveClass } from '@/search/components/map/propertyMarker'

const propTypes = {
  item: PropTypes.object.isRequired,
  ratesPeriod: PropTypes.string.isRequired,
  highlightMapMarkerOnHover: PropTypes.bool,
  getRef: PropTypes.func,
}

const c = 'search_result'

const Result = (props) => {
  const { item, ratesPeriod, highlightMapMarkerOnHover, getRef = () => {} } = props
  const location = useLocation()
  const rateMultiplier = ({ month: 30, week: 7, night: 1 })[ratesPeriod]

  const modifyMapMarkerClass = (itemId, addOrRemove) => {
    if (
      media('(max-width: 1139px)') ||
      !highlightMapMarkerOnHover
    ) {
      return
    }
    const $marker = document.querySelector(`.${mapMarkerIdClass(itemId)}`)
    if ($marker) {
      $marker.classList[addOrRemove](mapMarkerActiveClass)
    }
  }

  const linkProps = () => ({
    to: {
      pathname: `/properties/${item.id}`,
      search: pickFromQueryString(location.search, queryKeys),
    },
    target: '_blank',
  })

  return (
    <div
        className={c}
        onMouseOver={() => modifyMapMarkerClass(item.id, 'add')}
        onMouseLeave={() => modifyMapMarkerClass(item.id, 'remove')}
        ref={getRef}
      >
      <div className={`${c}_outer`}>
        <div className={`${c}_inner`}>
          <Slider>
            {sortPhotos(item.photos).map(photo => (
              <Link key={photo.id} {...linkProps()}>
                <Photo photo={photo} lazyLoad="data-slider-image-url" />
              </Link>
            ))}
          </Slider>
          <Link {...linkProps()} className={`${c}_bedroomSizes`}>
            {item.bedsCounts.map(count => (
              <span key={count}>
                {options.labels.bedsCount[count].replace('Bedroom', 'Room')}
              </span>
            ))}
            {item.listingType === 'entire_space' && <span>Entire Space</span>}
          </Link>
        </div>
        <div className={`${c}_link`}>
          <Link {...linkProps()}>
            <div className={`${c}_content`}>
              <Avatar {...item.user} size={50} withoutLink />
              {item.user.verifiedHost && (
                <VerifiedMark label={""} withOutline withoutLink />
              )}
              <div className={`${c}_accommodation`}>
                {`${options.labels.listingTypes[item.listingType]} `}
                &#183;
                {` ${options.labels.propertyTypes[item.propertyType]}`}
              </div>
              <h2 className={`${c}_title`}>
                {_.truncate(item.title, { length: 58 })}
              </h2>
              <div className={`${c}_container`}>
                <div className={`${c}_left`}>
                  <div className={`${c}_price`}>
                    <Price 
                      item={item} 
                      rateMultiplier={rateMultiplier} 
                      ratesPeriod={ratesPeriod}
                    />
                  </div>
                  <div className={`${c}_placement`}>
                    {`${item.placementType === 'unisex' ? '' : 'All '}
                      ${options.labels.placementTypes[item.placementType]}`}
                  </div>
                </div>
                <div className={`${c}_right`}>
                  <div className={`${c}_roomsBaths`}>
                    <span>{item.bedroomsCount}</span>
                    {` bdrm / `}
                    <span>{item.bathroomsCount}</span>
                    {` bath`}
                  </div>
                  <div className={`${c}_accommodates`}>
                    {item.listingType === 'entire_space' ? (
                      <>
                        {`Sleeps `}
                        <span>{item.accommodates}</span>
                      </>
                    ) : (
                      +item.accommodates <= 1 ? (
                        `Private Bedroom`
                      ) : (
                        <>
                          {`Up to `}
                          <span>{+item.accommodates - 1}</span>
                          {` roomies`}
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${c}_bottom`}>
              <div className={`${c}_address`}>
                <Icon type="location" />
                {`${item.neighborhood}, ${item.city}`}
              </div>
              <div className={`${c}_metro`}>
                <Icon type="train" />
                {`${_.round(item.closestStationDistance, 2)} Miles to Metro Rail Station`}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

const Price = ({ item, rateMultiplier, ratesPeriod }) => {
  const getSessionDuration = (type = 'days') => {
    const session = item?.sessions[0]
    const start = stringToDate(session?.startDate)
    const end = stringToDate(session?.endDate)

    return getTimeDifference(end, start, type)
  }

  const getListingRentalTotal = () => parseInt(item?.minRate) * parseInt(getSessionDuration())

  return (
    item?.prorateForShortStay ? (
      <>
        <span>{` ${formatPrice(item.minRate * rateMultiplier)}`}</span>
        {` / ${ratesPeriod} and up`}
      </> 
    ) : (
      <>
        <span>{`${formatPrice(getListingRentalTotal())} `}</span>
        {`for up to ${getSessionDuration('weeks')} weeks of stay`}
      </>
    )
  )
}

Result.propTypes = propTypes

export default Result
