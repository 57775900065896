import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@/shared/components'
import '@/shared/styles/verifiedMark.scss'

const propTypes = {
  withOutline: PropTypes.bool,
  withoutLink: PropTypes.bool,
  label: PropTypes.string,
}

const VerifiedMark = ({ withOutline, withoutLink, label = 'Verified Host' }) => {
  const renderContent = () => (
    <Fragment>
      {withOutline && <Icon type="verified" className="verifiedMark_outline" />}
      <Icon type="verified" className="verifiedMark_icon" />
      {label && <span>{label}</span>}
    </Fragment>
  )
  return withoutLink ? (
    <div className="verifiedMark">{renderContent()}</div>
  ) : (
    <a
      className="verifiedMark"
      href="https://roomsie.zendesk.com/hc/en-us/articles/360008126114-What-does-it-mean-to-be-a-verified-host-"
      target="_blank"
    >
      {renderContent()}
    </a>
  )
}

VerifiedMark.propTypes = propTypes

export default VerifiedMark
