import history from '@/browserHistory'
import * as url from '@/shared/utils/url'

export const toOpen = () => history.push({
  pathname: history.location.pathname,
  search: url.addToQueryString(history.location.search, { help_contact: true })
})

export const toClose = key => history.push({
  pathname: history.location.pathname,
  search: url.omitFromQueryString(history.location.search, 'help_contact')
})

export const isOpen = key => (
  !!url.queryStringToObject(history.location.search).help_contact
)
