import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { stringToDate, dateToString } from '@/shared/utils/dateTime'
import { decimalPriceInputFilter } from '@/shared/utils/numbers'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import { extendBooking } from '@/hostBookings/api'
import { Modal, Button, Field, Form } from '@/shared/components'
import '@/hostBookings/styles/extend.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const c = 'hostBooking_extend'

class Extend extends Component {

  handleDatesChange = (dates, setFieldValue) => {
    const { startDate, endDate } = this.props.booking

    if (!dates.from || moment(stringToDate(startDate)).isBefore(dates.from)) {
      dates.from = stringToDate(startDate)
    }
    if (!dates.to || moment(stringToDate(endDate)).isAfter(dates.to)) {
      dates.to = stringToDate(endDate)
    }
    setFieldValue('dates', dates)
  }

  handleExtend = (closeModal, values, form) => {
    const { dates, total } = values

    if (dates.from && dates.to) {
      extendBooking(this.props.booking.id, {
        startDate: dateToString(dates.from),
        endDate: dateToString(dates.to),
        total: +total,
      }).then(() => {
        this.props.fetchBooking(() => {
          closeModal()
          showToast({ title: 'Your booking was extended successfully.' })
        })
      }, ({ error }) => {
        if (error.code === 'invalid_resource') {
          form.setErrors({ dates: 'This listing is not available for selected dates' })
        } else {
          handleError({ error })
        }
        form.setSubmitting(false)
      })
    }
  }

  render() {
    const { booking } = this.props
    return (
      <Modal
        className={c}
        containsForm
        renderLink={() => (
          <Button>Extend Booking</Button>
        )}
        renderContent={({ closeModal }) => (
          <Form
            initialValues={{
              dates: {
                from: stringToDate(booking.startDate),
                to: stringToDate(booking.endDate),
              },
              total: '',
            }}
            validations={{
              total: Form.is.required(),
            }}
            onSubmit={(...args) => this.handleExtend(closeModal, ...args)}
            render={({ handleSubmit, isSubmitting, fields, setFieldValue, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className={`${c}_title`}>
                  Extend Booking
                </div>
                <p className={`${c}_message`}>
                  You can extend your guests booking, but any additional payments will have to be handled off Roomsie platform between yourself and the guest.
                </p>
                <Field.DateRangePicker
                  {...fields.dates}
                  label="Please update existing booking dates"
                  placeholder="Check In — Check Out"
                  tooltipProps={{ windowBounds: { bottom: true } }}
                  closeOnPick={false}
                  onChange={dates => this.handleDatesChange(dates, setFieldValue)}
                />
                <Field.Input
                  {...fields.total}
                  size="small"
                  icon="dollar"
                  label="Price for additional dates"
                  filter={decimalPriceInputFilter}
                />
                <Button hollow color="secondary" onClick={closeModal}>Close</Button>
                <Button type="submit" working={isSubmitting}>Extend Booking</Button>
              </form>
            )}
          />
        )}
      />
    )
  }
}

Extend.propTypes = propTypes

export default Extend
