import React, { Component, Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import * as url from '@/shared/utils/url'
import { formatPrice } from '@/shared/utils/numbers'
import { formatDateRange } from '@/shared/utils/dateTime'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { fetchProgramSessionRates } from '@/property/api'
import { Button, FieldErrors, Checkbox,
  CheckboxGroup, Spinner, BookingFeesGuest } from '@/shared/components'

const propTypes = {
  property: PropTypes.object.isRequired,
  selectedListing: PropTypes.number,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
}

const c = 'property_booking'

const Program = (props) => {

  const { property, selectedListing, query, updateQuery, trackEvent } = props
  const { selectedFees, selectedListingId } = query

  const { toPrivateRoute } = useAuthUrl()


  const [program, setProgram] = useState({ 
    rates: null, 
    error: null, 
    isFetching: false 
  })

  const location = useLocation()

  useEffect(() => {
    calculateRates()
  }, [])
  
  useEffect(() => {
    calculateRates()
  }, [selectedFees, selectedListingId])

  const calculateRates = () => {
    if (!!selectedListing) {
      setProgram({ ...program, isFetching: true })

      fetchProgramSessionRates(query.programSessionId, selectedListing, {
        additionalFees: query.selectedFees,
      }).then(({ data }) => {
        trackEvent('Property Rates Calculated', {
          bedroomsCount: null, roomiesCount: null,
        })
        setProgram({ rates: data, error: null, isFetching: false })
      }, ({ error }) => {
        let errorString = error.details ? error.details.base : error.description

        if (error.code === 'not_found') {
          errorString = 'This listing is not available for selected dates.'
        }
        trackEvent('Property Rates Calculated', {
          bedroomsCount: null, roomiesCount: null,
          error: errorString || 'true',
        })
        setProgram({ rates: null, error: errorString, isFetching: false })
      })
    } else {
      setProgram({ ...program, rates: null, error: null })
    }
  }

  const handleSubmit = () => {
    if (!program.error) {
      trackEvent('Property Rates Form Submit')
      toPrivateRoute({
        pathname: `/book/${selectedListing}/${property.id}`,
        search: url.addToQueryString(location.search, {
          ...query,
          additionalFees: query.selectedFees,
        }),
      })
    } else {
      this.tooltip.openTooltip()
    }
  }

  const { rates, error, isFetching } = program
  const selectedFeeLabels = {
    pets: `I have pets`,
    parking: `I need parking`,
    gym:  `I want gym membership`
  }

  return (
    <>
      <div className={`${c}_top`}>
        {program.isFetching && (
          <Spinner size={36} color="#fff" />
        )}
        <div className={`${c}_title`}>
          {query.programSessionName}
          <br />
          <br />
          {formatDateRange(query.startDate, query.endDate)}
        </div>
      </div>
      <div className={`${c}_bottom`}>
        {!!rates ? (
          <Fragment>
            <FieldErrors errors={error} />
            <CheckboxGroup
              value={query.selectedFees}
              onChange={selectedFees => updateQuery({ selectedFees })}
              render={({ isChecked, onCheck }) => (
                _.uniqBy(property.fees, 'kind').filter(f => f.optional).map(fee => (
                  <Checkbox
                    key={fee.id}
                    size="small"
                    label={selectedFeeLabels[fee.kind]}
                    value={fee.kind}
                    checked={isChecked(fee.kind)}
                    onChange={onCheck}
                  />
                ))
              )}
            />
            {rates && <BookingFeesGuest rates={rates} />}
            <Button
              disabled={isFetching}
              onClick={handleSubmit}
            >
              Request to Book
            </Button>
            <div className={`${c}_message`}>
              {"All other expenses are handled by the program."}
            </div>
          </Fragment>
        ) : (
          <div className={`${c}_message`}>
            {"Please select a bed you're interested in booking"}
          </div>
        )}
      </div> 
    </>
  )
}

Program.propTypes = propTypes

export default Program
