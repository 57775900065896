import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { formatDate, formatDateRange } from '@/shared/utils/dateTime'
import { Icon, Photo } from '@/shared/components'
import '@/events/styles/eventCard.scss'

const propTypes = {
  event: PropTypes.object.isRequired,
  showStatus: PropTypes.bool,
}

const c = 'eventCard'

const EventCard = ({ event, showStatus }) => (
  <Link
    key={event.id}
    className={c}
    to={`/events/show/${event.id}`}
    target="_blank"
  >
    <Photo cover photo={event.photo} className={`${c}_top`}>
      <div className={`${c}_top_inner`}>
        {event.categories.includes('roomsie_socials') ? (
          <div className={`${c}_tag ${c}_tag--roomsie`}>
            Roomsie Social
          </div>
        ) : (
          <div className={`${c}_tag`} style={{ opacity: 0 }}>_</div>
        )}
        <div className={`${c}_title`}>{event.title}</div>
        <div className={`${c}_dates`}>
          {event.endDate && event.endDate !== event.startDate ? (
            formatDateRange(event.startDate, event.endDate)
          ) : (
            formatDate(event.startDate)
          )}
        </div>
        <div className={`${c}_times`}>
          {event.endTime ? (
            `${event.startTime} — ${event.endTime}`
          ) : (
            event.startTime
          )}
        </div>
      </div>
    </Photo>
    <div className={`${c}_bottom`}>
      <div className={`${c}_label`}>Location</div>
      <div className={`${c}_location`}>
        {event.location}
      </div>
      <div className={`${c}_label`}>People Attending</div>
      <div className={`${c}_capacity`}>
        {`${event.attendeesCount}${event.maximumGuests ? `/${event.maximumGuests}` : ''}`}
        {event.currentUserAttending && (
          <Fragment>
            <Icon type="check-circle" top={3} />
            {"I'm attending"}
          </Fragment>
        )}
      </div>
      {showStatus && (
        <Fragment>
          <div className={`${c}_label`}>Status</div>
          <div className={`${c}_status ${c}_status--${event.status}`}>
            {event.status === 'pending' && 'Pending Approval'}
            {event.status === 'accepted' && 'Accepted'}
            {event.status === 'rejected' && 'Rejected'}
            {event.status === 'canceled' && 'Cancelled'}
          </div>
        </Fragment>
      )}
    </div>
  </Link>
)

EventCard.propTypes = propTypes

export default EventCard
