import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import _ from 'lodash'
import { formatDate } from '@/shared/utils/dateTime'
import { Input, Tooltip, Icon } from './'
// import 'react-day-picker/lib/style'
// import 'shared/styles/datePicker'

const propTypes = {
  placement: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  defaultValue: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string,
  disabledDays: PropTypes.array,
  disablePastDays: PropTypes.bool,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
}

const defaultProps = {
  placement: 'bottom',
  placeholder: 'Select date',
  inputProps: {},
  tooltipProps: {},
  disabledDays: [],
  disablePastDays: true,
  onChange: (value) => {},
}

class DatePicker extends Component {

  constructor(props) {
    super(props)
    this.isControlled = !_.isUndefined(props.value)
    this.state = {
      value: props.defaultValue || null
    }
  }

  getDisabledDays = () => {
    const disabledDays = [...this.props.disabledDays]
    if (this.props.disablePastDays) {
      disabledDays.unshift({ before: new Date() })
    }
    return disabledDays
  }

  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) return
    const value = selected ? null : day

    if (!this.isControlled) {
      this.setState({ value })
    }
    this.props.onChange(value)
    setTimeout(this.tooltip.closeTooltip, 150)
  }

  renderNavBar = ({
    onPreviousClick = () => {},
    onNextClick = () => {},
  }) => (
    <div className="DayPicker-Nav">
      <Icon onClick={() => onPreviousClick()} type="chevron-left-fat" />
      <Icon onClick={() => onNextClick()} type="chevron-right-fat" />
    </div>
  )

  render() {
    const value = this.isControlled ? this.props.value : this.state.value
    const { placement, placeholder, inputProps, dateFormat, tooltipProps, invalid } = this.props
    return (
      <Fragment>
        <Tooltip
          placement={placement}
          className="datePicker_tooltip"
          linkClassName="datePicker"
          ref={el => this.tooltip = el}
          renderLink={() => (
            <div className="datePicker_input_container">
              <div className="datePicker_input_overlay" />
              <Input
                icon="calendar"
                disabled
                invalid={invalid}
                {...inputProps}
                className="datePicker_input"
                placeholder={placeholder}
                value={(value && formatDate(value, dateFormat)) || ''}
              />
            </div>
          )}
          renderContent={() => (
            <DayPicker
              selectedDays={value}
              disabledDays={this.getDisabledDays()}
              month={value}
              onDayClick={this.handleDayClick}
              navbarElement={this.renderNavBar}
            />
          )}
          {...tooltipProps}
        />
      </Fragment>
    )
  }
}

DatePicker.propTypes = propTypes
DatePicker.defaultProps = defaultProps

export default DatePicker
