import Room from '@/plugin/property/components/Room';
import Information from '@/plugin/property/components/Information';
import Payment from '@/plugin/property/components/Payment';
import Confirmation from '@/plugin/property/components/Confirmation';

export const steps = [
  {
    id: 'room',
    path: '',
    label: 'Room',
    labelLong: 'Room',
    Component: Room,
  },
  {
    id: 'information',
    path: '/information',
    label: 'Information',
    labelLong: 'Personal Information',
    Component: Information,
  },
  {
    id: 'payment',
    path: '/payment',
    label: 'Payment',
    labelLong: 'Payment',
    Component: Payment,
  },
  {
    id: 'confirmation',
    path: '/confirmation',
    label: 'Confirmation',
    labelLong: 'Confirmation',
    Component: Confirmation,
  },
];
