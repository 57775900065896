import React from 'react';
import { Link } from 'react-router-dom';
import roomsieLogo from '@/shared/assets/images/logo.png';
import '@/plugin/property/styles/staticInfo.scss';

const c = 'plugin_staticInfo';

export const DepositInfo = () => {
  return (
    <div className={`${c}_additionalInfo`}>
      <div className={`${c}_additionalInfo_asterisk`}>&lowast;</div>
      <span className={`${c}_additionalInfo_text`}>
        Security deposits reserve your placement so that it is not offered to
        other prospective residents. Your $400.00 deposit is fully refundable
        within 45 days from our session end date barring any deductions for
        atypical wear and tear or other WIHN policy violations.
      </span>
    </div>
  );
};

export const AvailabilityInfo = () => {
  return (
    <div className={`${c}_additionalInfo`}>
      <span className={`${c}_additionalInfo_asterisk`}>&lowast;</span>
      <span className={`${c}_additionalInfo_text`}>
        Subject to availability. All placements will be continued to be offered
        to other applicants until we receive your security deposit.
      </span>
    </div>
  );
};

export const FinalizationInfo = () => {
  return (
    <div className={`${c}_additionalInfo`}>
      <span className={`${c}_additionalInfo_asterisk`}>&lowast;</span>
      <span className={`${c}_additionalInfo_text`}>
        All booking requests are subject to availability and not finalized until
        accepted by The Washington Intern Housing Network, LCC
      </span>
    </div>
  );
};

export const EditInfo = () => {
  return (
    <div className={`${c}_additionalInfo`}>
      <span className={`${c}_additionalInfo_asterisk`}>&lowast;</span>
      <span className={`${c}_additionalInfo_text`}>
        To edit this information, monitor the status of this request, connect
        with your roommates or modify other details please provide login to your{' '}
        <Link to="/" target="_blank">
          Roomsie account
        </Link>
        .
      </span>
    </div>
  );
};

export const PolicyInfo = () => {
  return (
    <div className={`${c}_policy`}>
      <h3>Cancellation Policy:</h3>
      <p>
        In order to receive a complete refund of your security deposit,{' '}
        <strong>ALL</strong> cancellations <strong>MUST</strong> be made from
        within your Roomsie account no later than 30 days prior to our session
        start or arrival date, whichever is sooner. Cancellations made within 30
        days of your arrival will result in forfeiture of all deposits and
        housing fees paid <strong>WITHOUT</strong> exception.
      </p>
      <p>
        Eligible refunds will be applied back to the card from which they were
        paid within 7-10 business days of a properly cancelled reservation.
      </p>
      <h3 className={`${c}_policy_container`}>
        Roomsie Powered:
        <span className={`${c}_policy_poweredBy`}>
          Powered By
          <Link to="/">
            <span className={`${c}_policy_roomsieLogo`}>
              <img src={roomsieLogo} alt="logo" />
              Roomsie
            </span>
          </Link>
        </span>
      </h3>
      <p>
        In late 2018, The WIHN partnered with Roomsie to bring our guests the
        best housing and booking experience anywhere in D.C. Booking your stay
        with us has never been easier while Roomsie also offers you the
        following benefits;
      </p>
      <ul>
        <li>
          View all details pertaining to your property and assigned bedroom
          BEFORE you book. This includes highly detailed pictures, commute/map
          info and fellow roommates. Always 100% transparency.
        </li>
        <li>
          Connect with your Roomie(s) before you arrive. No more first day
          awkwardness.
        </li>
        <li>
          Access fellow event attendee or Roomie community members' profiles.
        </li>
        <li>
          Create your own or receive invitations to events based on what
          interest you!
        </li>
        <li>
          Exclusive invites to Roomie sponsored events to network socially and
          professionally with other guests.
        </li>
      </ul>
      <p>
        All of these benefits are made available to you at{' '}
        <strong>NO EXTRA COST</strong> to you or your housing program.
      </p>
      <p>
        Do note that all payments, correspondence, reservation management and
        other tools are provided courtesy of Roomsie.
      </p>
    </div>
  );
};

export const FieldsRequired = () => {
  return (
    <div className={`${c}_fieldsRequired`}>
      <p>All fields are required unless otherwise stated.</p>
    </div>
  );
};
