import React, { Fragment, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { InfoBanner } from '@/shared/components'
import GuestPaymentForm from '@/features/guestPaymentForm/components'
import CreateBooking from './CreateBooking'
import ProgressBar from './ProgressBar'
import '@/book/styles/payment.scss'

const c = 'book_payment'

const Payment = () => {

  const [ 
    property,
    currentUser,
    updateCurrentUser,
    trackEvent,
    warnAndRedirect,
    query,
    isImportingBooking,
    rates
  ] = useOutletContext()

  const [isReadyToBook, setIsReadyToBook] = useState(false)

  const hasCharges = !!rates?.charges?.length

  return (
    <div className={c}>
      <h1 className={`${c}_title`}>{"You're Almost There"}</h1>
      <ProgressBar current={3} total={3} />
      {hasCharges && !isImportingBooking && (
        <Fragment>
          <InfoBanner
            title="Roomsie will never ask you to wire money"
            message="Paying through the Roomsie website or app helps keep your payments secure, and helps you stay covered by applicable policies."
          />
          <GuestPaymentForm
            onReadyChange={isReady => setIsReadyToBook(isReady)}
            onCreditCardSubmitSuccess={() => {
              trackEvent('Booking Wizard Credit Card Submitted')
            }}
            onCreditCardSubmitError={errorString => {
              trackEvent('Booking Wizard Credit Card Submitted', {
                error: errorString || 'true',
              })
            }}
            onCustomerFormSubmitSuccess={() => {
              trackEvent('Booking Wizard Billing Info Submitted')
            }}
            onCustomerFormSubmitError={errorString => {
              trackEvent('Booking Wizard Billing Info Submitted', {
                error: errorString || 'true',
              })
            }}
          />
          {isReadyToBook && (
            <CreateBooking 
              rates={rates} 
              trackEvent={trackEvent} 
              query={query} 
              isImportingBooking={isImportingBooking} 
            />
          )}
          <div className={`${c}_note`}>
            <span>Note</span>
            <p>{`You will only be charged if the host accepts your request. They will have 48 hours to reply. If the host declines or does not respond, no charge will be made to your card.`}</p>
          </div>
        </Fragment>
      )}
      {!hasCharges && query.programSessionId && (
        <Fragment>
          <p className={`${c}_message`}>
            {`You're one step away from booking your placement for `}
            <strong>{query.programSessionName}</strong>
            {` and all the benefits of the Roomsie community!  Your host will have up to 48 hours to confirm your booking request.`}
          </p>
          <CreateBooking
            rates={rates} 
            trackEvent={trackEvent} 
            query={query} 
            isImportingBooking={isImportingBooking} 
          />
        </Fragment>
      )}
      {isImportingBooking && (
        <Fragment>
          <p className={`${c}_message`}>
            {"You're one step away from securing the booking!"}
          </p>
          <CreateBooking
            rates={rates} 
            trackEvent={trackEvent} 
            query={query} 
            isImportingBooking={isImportingBooking} 
          />
        </Fragment>
      )}
    </div>
  )
}

export default Payment
