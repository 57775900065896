import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { formatDate } from '@/shared/utils/dateTime'
import * as api from '@/hostMessages/api'
import { Head, DashboardMenu, PageSpinner,
  EmptyState, Button, ConfirmModal, Icon } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/hostMessages/styles/messages.scss'

const c = 'hostMessages'

class HostMessages extends Component {

  state = { messages: null }

  componentDidMount() {
    analytics.pageview('Host Guest Messaging')

    api.fetchMessages().then(({ data }) => {
      this.setState({ messages: data })
    }, handleError)
  }

  handlePauseMessage = ({ id, active }, confirmModal) => {
    api.updateMessage(id, { active: !active }).then(() => {
      const messages = _.cloneDeep(this.state.messages)
      const message = messages.find(m => m.id === id)
      message.active = !active
      this.setState({ messages })
      confirmModal.close()
    }, handleError)
  }

  handleDeleteMessage = id => {
    api.deleteMessage(id).then(() => {
      this.setState({
        messages: this.state.messages.filter(m => m.id !== id),
      })
      showToast({ title: 'Your message template was deleted successfully.' })
    }, handleError)
  }

  handleSendTestMessage = (id, confirmModal) => {
    api.sendTestMessage(id).then(() => {
      confirmModal.close()
      showToast({ title: 'Your test message was sent successfully. Check your email inbox!' })
    }, handleError)
  }

  renderMessage = message => (
    <div key={message.id} className={`${c}_message ${c}_message--${message.active ? 'active' : 'paused'}`}>
      <div className={`${c}_message_statusBar--${message.active ? 'active' : 'paused'}`} />
      <div className={`${c}_message_info`}>
        <div className={
          `${c}_message_status
           ${c}_message_status--${message.active ? 'active' : 'paused'}`
        }>
          {message.active ? 'Active' : 'Paused'}
        </div>
        <div className={`${c}_message_name`}>
          {message.name}
        </div>
        <div className={`${c}_message_info_bottom`}>
          <div className={`${c}_message_info_bottom_left`}>
            <div>
              Assigned to
              <span>{` ${message.propertyIds.length} properties`}</span>
            </div>
            <div>
              Sent
              <span>
                {` ${Math.abs(message.days)} days
                   ${message.days < 0 ? 'before' : 'after'} guest ${message.trigger}`}
              </span>
            </div>
          </div>
          <div className={`${c}_message_info_bottom_right`}>
            <div>{`Created ${formatDate(message.createdAt)}`}</div>
            <div>{`Last Edited ${formatDate(message.updatedAt)}`}</div>
          </div>
        </div>
      </div>
      <div className={`${c}_message_actions`}>
        <ConfirmModal
          title={message.active ? 'Pause Messaging' : 'Activate Messaging'}
          message={message.active ? (
            `Automatic scheduled emails will stop being sent. Are you sure you want to pause this template?`
          ) : (
            `Automatic scheduled emails will start being sent. Are you sure you want to activate this template?`
          )}
          dontCloseOnConfirm
          confirmText={message.active ? 'Pause' : 'Activate'}
          onConfirm={confirmModal => this.handlePauseMessage(message, confirmModal)}
          renderLink={() => (
            <div className={`${c}_message_action`}>
              <Icon type={`${message.active ? 'pause' : 'play'}-circle`} />
              <div>{message.active ? 'Pause' : 'Activate'}</div>
            </div>
          )}
        />
        <ConfirmModal
          title="Send Test Message"
          message="Preview your message by sending yourself a test email."
          confirmText="Send"
          dontCloseOnConfirm
          onConfirm={confirmModal => this.handleSendTestMessage(message.id, confirmModal)}
          renderLink={() => (
            <div className={`${c}_message_action`}>
              <Icon type="share" />
              <div>Send Test</div>
            </div>
          )}
        />
        <Link
          to={`/host/scheduled-messaging/edit/${message.id}`}
          className={`${c}_message_action`}
        >
          <Icon type="edit" />
          <div>Edit</div>
        </Link>
        <ConfirmModal
          title="Delete Template"
          message="Are you sure you want to delete this template? Scheduled emails will stop being sent. This action is permanent and cannot be reversed."
          confirmText="Delete"
          onConfirm={() => this.handleDeleteMessage(message.id)}
          isDanger
          dontCloseOnConfirm
          renderLink={() => (
            <div className={`${c}_message_action`}>
              <Icon type="trash" />
              <div>Delete</div>
            </div>
          )}
        />
      </div>
    </div>
  )

  render() {
    const { messages } = this.state
    return (
      <div className={c}>
        <Head title="Guest Messaging - Roomsie" />
        <AppHeader />
        <DashboardMenu role="host" />
        <div className={`${c}_page`}>
          <div className={`${c}_top`}>
            <h1>Message Templates</h1>
            <Link to="/host/scheduled-messaging/create">
              <Button size="small">Create Template</Button>
            </Link>
            <Link to="/host/scheduled-messaging/instant">
              <Button hollow size="small">Send Instant Message</Button>
            </Link>
          </div>
          {!messages && <PageSpinner />}
          {!!messages && !messages.length && (
            <EmptyState
              title="You have not created any scheduled message templates yet."
              message="You can create, schedule, and automatically send messages to your guests before or after their arrival or departure."
            />
          )}
          {!!messages && !!messages.length && (
            messages.sort((a, b) => b.id - a.id).map(this.renderMessage)
          )}
        </div>
        <AppFooter />
      </div>
    )
  }
}

export default HostMessages
