import imageParents from '@/landings/assets/images/template/parents.jpg'
import imageStudents from '@/landings/assets/images/template/students.jpg'
import imageUniversities from '@/landings/assets/images/template/universities.jpg'
import imageCoordinators from '@/landings/assets/images/template/coordinators.jpg'
import imageZoey from '@/landings/assets/images/template/zoey.jpg'
import imageDavid from '@/landings/assets/images/template/david.jpg'
import imageMariah from '@/landings/assets/images/template/mariah.jpg'

const testimonials = [{
  image: imageZoey,
  quote: "I really love my apartment and the opportunity to meet other DC newcomers. Thank you for this, you have no idea how thankful I am for your site!",
  name: "Zoey",
  title: "Russellville, AR",
}, {
  image: imageDavid,
  quote: "At the risk of sounding cliche, my roomies have become some of my best friends. No other site allowed me the chance to connect with others who were also new to DC like Roomsie.",
  name: "David",
  title: "Lancaster, CA",
}, {
  image: imageMariah,
  quote: "I've lived all over and this was, hands down, the easiest time I've ever had finding housing in a new city. Thanks Roomsie!",
  name: "Mariah",
  title: "Seattle, WA",
}]

export const parents = {
  heroImage: imageParents,
  heroSubtitle: "For Parents",
  heroTitle: "Take the <strong>headache</strong> <br/> out of your housing search",
  topTestimonial: "I was incredibly nervous when my son needed to move to D.C. Roomsie’s verified hosts, neighborhood research tools and payment protections set my mind at ease.",
  topTestimonialName: "John A",
  topTestimonialTitle: "New York",
  featuresTitle: "Thousands of parents can’t be wrong.<br/>Why should <strong>you</strong> choose Roomsie?",
  featuresSubtitle: "No one knows the uncertainties that can accompany your student or graduate’s move to a new city like Roomsie. Roomsie has helped thousands of parents find safe, convenient and affordable housing for their son or daughter. With Roomsie’s staff of dedicated professionals and support specialist standing by your side, you’ll never be alone in your housing search.",
  features: [{
    icon: { type: 'landing-calendar', size: 39 },
    title: "Flexible lease dates",
    message: "Whether it’s a dream internship, academic opportunity or just starting their career, Roomsie offers lease dates that match perfectly.",
  }, {
    icon: { type: 'landing-money', size: 44, top: -6 },
    title: "Secure payment protection",
    message: "Roomsie’s payment policies insure that you’re getting exactly what’s advertised on our platform. No more surprises or risks with other third party sites.",
  }, {
    icon: { type: 'landing-bed', size: 37 },
    title: "Save thousands on furniture",
    message: "Fully furnished housing is easier on your wallet and dad’s back. No hassle move-ins? We’ve got it covered!",
  }, {
    icon: { type: 'landing-people', size: 44, top: 4 },
    title: "Socializing and networking opportunities",
    message: "Roomsie helps your child get the most out of their stay by organizing free concerts, lectures and other exclusive events to enrich their overall experience.",
  }, {
    icon: { type: 'landing-drop', size: 34 },
    title: "All utilities included",
    message: "All rates include: electric, water, trash, gas, cable/dish TV and high speed internet. No more worrying about who owes what for utilities.",
  }, {
    icon: { type: 'landing-chat', size: 39, top: 2 },
    title: "A welcoming built-in community'",
    message: "Roomsie provides tools that let your students connect with other Roomsie friends who share their passions and interests.",
  }, {
    icon: { type: 'landing-safe', size: 42, top: -1 },
    title: "Verified and trusted hosts",
    message: "Roomsie conducts ID and background checks for every host on our platform. No more worrying about being taken advantage of on third party sites.",
  }, {
    icon: { type: 'landing-map', size: 43, top: -3 },
    title: "Neighborhood research tools",
    message: "View up to the minute crime stats overlayed with neighborhoods and internship locations. Rest easy knowing your child is living in a location that meets your standards.",
  }],
  spotsTitle: "Where will your son or <br/> daughter intern or study?",
  testimonialsTitle: "Hear what others have to say about Roomsie",
  testimonials,
  bottomCta: "Find your son or daughter's home away from home",
}

export const students = {
  heroImage: imageStudents,
  heroSubtitle: "For Students",
  heroTitle: "Sublet your space to <br/> <strong>+40k interns</strong> visiting D.C.",
  topTestimonial: "Leaving DC for Italy for the Summer, I didn't want to just sublet to anyone. Listed my room in Foggy Bottom and immediately started getting inquiries from other students coming to D.C. I was able to learn everything I needed about my tenant and Roomsie made sure I got paid like clockwork. Too easy!",
  topTestimonialName: "Ellen C",
  topTestimonialTitle: "Junior, Georgetown",
  featuresTitle: "Host with Roomsie and <strong>earn up to 20% more</strong> rent <br/> for your bedroom or apartment.",
  featuresSubtitle: "You signed that 12 month lease and are now all set for your Summer adventure to Machu Pichu, Thailand, Paris or Detroit. You don't want to rent to just anyone. You need a responsible Summer subletter who will respect your place & pays their rent on time, everytime. Trust us, we get it. We're here to help.<br/><br/>Roomsie connects you with the +40k students and interns that flock to the city each Summer in search of a place to call home. We provide you the tools to learn everything you need to know about your potential guest and we guaranteed their rent will be sent to you like clockwork. Plus, with Roomsie, our student hosts can often charge up to 20% more in rent.<br/><br/>Get help funding those Summer adventures, help out a fellow student in need and cover those three pesky months of rent. What's not to like?",
  features: [{
    icon: { type: 'landing-people', size: 44, top: 4 },
    title: "Guest profiles",
    message: "Roomsie guests are required to share details about their background, bio and even social media handles so you know exactly who you're renting to.",
  }, {
    icon: { type: 'landing-money', size: 44, top: -6 },
    title: "Rent Like Clockwork",
    message: "Roomsie guarantees your guests rent and that it will be sent to you on time, everytime directly to you account. No ancient paper checks to handle.",
  }, {
    icon: { type: 'landing-calendar', size: 39 },
    title: "Summer Sublet Premium",
    message: "Our student hosts can often charge up to 20% more for their bedroom or space during Summer months. Get that cheddar.",
  }, {
    icon: { type: 'landing-safe', size: 42, top: -1 },
    title: "Students Helping Students",
    message: "Make money and get paid to turn the DC internship dreams of fellow college students in to a reality? What's not to like?",
  }],
  spotsTitle: "Where is your <br/> space located?",
  testimonialsTitle: "Hear what others have to say about Roomsie",
  testimonials,
  bottomCta: "It's easy to get started",
}

export const universities = {
  heroImage: imageUniversities,
  heroSubtitle: "For Universities",
  heroTitle: "Find your college or <br/> university's new <strong>D.C. campus</strong>",
  topTestimonial: "Our current housing provider partnered with Roomsie to allow our students to easily view and book their D.C. homes online. The detailed information on their listing pages really helped to reduce any questions or uncertainty among parents and students. Their special programming and Roomsie socials were just an added bonus that our students loved.",
  topTestimonialName: "Oliver M",
  topTestimonialTitle: "Program Director",
  featuresTitle: "Why use Roomsie to book your <strong>school's</strong> DC housing?",
  featuresSubtitle: "Roomsie is very familiar with the demands of running a semester in DC program. Finding group housing options that satisfy your students, parents AND administrators can be an enormous task. Roomsie has relationships with large housing operators that understand the unique challenges faced by public institutions such as budget constraints and compliance with state and federal statutes. We'll help pair you with your ideal DC group housing provider.<br/><br/>Already have a housing provider? Ask them about joining Roomsie and unlock access to private reservation portals, roomie to roomie chat, Roomsie's award winning student programming, lectures, concert, professional development and real-world networking events. All available at no additional cost to your school or students.",
  features: [{
    icon: { type: 'landing-calendar', size: 39 },
    title: "Flexible lease dates",
    message: "Whether you operate on semesters, trimesters or something in between, Roomsie has something to fit your needs.",
  }, {
    icon: { type: 'landing-money', size: 44, top: -6 },
    title: "Payment options that work",
    message: "Our providers offer flexible payment methods that work for your students. Financial aid? Scholarships? Work studies? We'll make it happen.",
  }, {
    icon: { type: 'landing-bed', size: 37 },
    title: "Private online booking portal",
    message: "Private booking pages that answer every question about your DC program cut down on parent and student inquiries. Portals also include links to your listings that students can book directly online.",
  }, {
    icon: { type: 'landing-chat', size: 39, top: 2 },
    title: "A welcoming built-in community'",
    message: "Roomsie provides tools that let your students connect with other Roomsie friends who share their passions and interests.",
  }, {
    icon: { type: 'landing-map', size: 43, top: -3 },
    title: "Classroom Space",
    message: "Need space for receptions, classes, presentations etc.? No problem. Many Roomsie providers offer these facilities at very little or no costs.",
  }, {
    icon: { type: 'landing-people', size: 44, top: 4 },
    title: "Proven, professional housing operators",
    message: "Our housing operators partners that have been housing students and interns for decades. They know your standards and we give them the tools they need to exceed them.",
  }, {
    icon: { type: 'landing-safe', size: 42, top: -1 },
    title: "Put Parents & Students at Ease",
    message: "Our property listing pages include high res photo galleries, map and commute information as well as links to roomie profiles. Drastically reduce questions from your concerned parents, students or admins.",
  }],
  spotsTitle: "Where will your<br/>students intern or study?",
  testimonialsTitle: "Hear what others have to say about Roomsie",
  testimonials,
  bottomCta: "Find your college or university's new D.C. campus",
}

export const coordinators = {
  heroImage: imageCoordinators,
  heroSubtitle: "For Intern Coordinators",
  heroTitle: "One <strong>trusted</strong> source for <br/> your intern's housing needs",
  topTestimonial: "The greatest challenge to running our D.C. program is finding affordable yet convenient housing options for our participants. Roomsie offers up hundreds of options to choose from all in one place and comes with a built-in community that connects them with others from all over DC. Our interns love it.",
  topTestimonialName: "Marley S",
  topTestimonialTitle: "Coordinator",
  featuresTitle: "Why recommend Roomsie to members <br/> of your <strong>DC intern program</strong>?",
  featuresSubtitle: "As an intern coordinator, the success of your program often depends on a number of factors. DC's lack of affordable housing often stands in the way of those who wish to take advantage of the opportunity and career enrichment your program can provide.<br/><br/>With Roomsie, you've got one trusted source for hundreds of furnished, flexible, yet affordable housing options. Our verified host and listing program insures that your interns are in great hands and will always get what they pay for. We guarantee it.",
  features: [{
    icon: { type: 'landing-calendar', size: 39 },
    title: "Flexible lease dates",
    message: "Whether your program runs on semesters, trimesters or everything in between, Roomsie has options to fit your interns' needs.",
  }, {
    icon: { type: 'landing-money', size: 44, top: -6 },
    title: "Secure payment protection",
    message: "Roomsie's Rent Safe guarantee insures that your intern gets exactly what was advertised on our site. Being taken advantage by bad actors on other sites stops now.",
  }, {
    icon: { type: 'landing-chat', size: 39, top: 2 },
    title: "Socializing and networking opportunities",
    message: "Roomsie helps your child get the most out of their stay by organizing free concerts, lectures and other exclusive events to enrich their overall experience.",
  }, {
    icon: { type: 'landing-people', size: 44, top: 4 },
    title: "A welcoming built-in community'",
    message: "Roomsie provides tools that let your students connect with other Roomsie friends who share their passions and interests.",
  }, {
    icon: { type: 'landing-map', size: 43, top: -3 },
    title: "Neighborhood research tools",
    message: "DC, MD or VA? We've got you covered! From Capitol Hill & K Street to Arlington, National Landing and Silver Spring, your interns will find a Roomsie host within easy commuting distance to your offices.",
  }],
  spotsTitle: "View examples <br/> of our intern guides",
  spotsSubtitle: "Want to have your office added to our list? Reach out and say hello.",
  testimonialsTitle: "What others have to say about Roomsie",
  testimonials,
  bottomCta: "Browse options available to your interns",
}
