import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { formatDateRange, stringToDate, dateToString } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { showToast } from '@/shared/utils/toast'
import { acceptExtendedBooking, rejectExtendedBooking } from '@/hostBookings/api'
import { Modal, Button, ConfirmModal,
  FieldErrors, BookingFees, Spinner } from '@/shared/components'
import '@/hostBookings/styles/extendBooking.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired
}

const c = 'hostBooking_extendBooking'

const ExtendBooking = props => {
  const { booking } = props

  return (
    <Fragment>
      <ExtendBookingModal {...props} />
    </Fragment>
  )
}

ExtendBooking.propTypes = propTypes

class ExtendBookingModal extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialProps(props.booking)
  }

  getInitialProps = booking => ({
    availableDates: null,
    bookingExtension: booking.bookingExtension,
    bookingExtensionRates: booking.bookingExtensionRates,
    error: null,
    dates: {
      from: stringToDate(booking.startDate),
      to: stringToDate(booking.endDate),
    },
    isFetchingRates: false,
    isSubmitting: false,
  })

  handleRejectExtendBooking = closeModal => {
    rejectExtendedBooking(this.props.booking.id).then(() => {
      this.props.fetchBooking(() => {
        closeModal()
        showToast({ title: `Your booking was rejected successfully.` })
      })
    }, ({ error }) => {
      this.setState({ isSubmitting: false })
    })
  }

  handleExtendBooking = (closeModal, withFees) => {
    const { dates, error } = this.state
    if (dates.from && dates.to && !error) {
      this.setState({ isSubmitting: true })

      acceptExtendedBooking(this.props.booking.id, {
        withFees: withFees,
      }).then(() => {
        this.props.fetchBooking(() => {
          this.setState(this.getInitialProps(this.props.booking))
          closeModal()
          showToast({ title: 'Your booking was extended successfully.' })
        })
      }, ({ error }) => {
        this.setState({ isSubmitting: false })
      })
    }
  }

  render() {
    const { booking } = this.props
    const {
      availableDates,
      bookingExtensionRates,
      bookingExtension,
      error,
      isSubmitting
    } = this.state
    return (
      <Modal
        className={c}
        containsForm
        renderLink={() => (
          <Button icon="check">Extend Booking</Button>
        )}
        renderContent={({ closeModal }) => (
          <Fragment>
            <div className={`${c}_title`}>
              Request to extend a stay
            </div>
            <p className={`${c}_message`}>
              If you wish to collect an additional security deposit, cleaning or other fees or want to extend at a higher nightly rate then please ask your guest to a make a new booking request through our system.
            </p>
            {bookingExtensionRates && (
              <div>
                <Fragment>
                  <p className={`${c}_ratesExplanationTop`}>
                    {'Additional charges related to this request'}
                  </p>
                  <BookingFees rates={bookingExtensionRates} />
                  <Button icon="check" onClick={() => this.handleExtendBooking(closeModal, false)}>Accept Request</Button>
                </Fragment>
              </div>
            )}
            <div>
              <Button hollow color="secondary" onClick={closeModal}>Close</Button>
            </div>
            {availableDates && !!availableDates.length && (
              <Button
                disabled={!rates}
                working={isSubmitting}
                onClick={() => this.handleSubmit(closeModal)}
              >
                {rates ? (
                  `Extend Booking for ${formatPrice(rates.charges[0].total)}`
                ) : (
                  'Extend Booking'
                )}
              </Button>
            )}
          </Fragment>
        )}
      />
    )
  }
}

export default ExtendBooking
