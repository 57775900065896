import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { formatDateRange } from '@/shared/utils/dateTime'
import { formatAddress } from '@/shared/utils/geo'
import { statuses } from '@/shared/utils/guestBookings'
import MessageModal from '@/features/messageModal/components'
import { Avatar, Button, Photo } from '@/shared/components'
import '@/shared/styles/guestCurrentBooking.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
}

const c = 'guestCurrentBooking'

const GuestCurrentBooking = ({ booking }) => {
  let { propertySnapshot, host, roomies } = booking
  roomies = propertySnapshot.listingType === 'hosted' ? [host, ...roomies] : roomies
  return (
    <div className={c}>
      <div className={`${c}_title`}>
        {statuses.primary.find(s => s.value === booking.status).label}
      </div>
      <div className={`${c}_inner`}>
        <div className={`${c}_info`}>
          <Link to={`/properties/${propertySnapshot.id}`} target="_blank">
            <Photo photo={propertySnapshot.photo} />
          </Link>
          <div className={`${c}_dates`}>
            {formatDateRange(booking.startDate, booking.endDate)}
          </div>
          <Link to={`/properties/${propertySnapshot.id}`} target="_blank">
            <div className={`${c}_propertyTitle`}>
              {propertySnapshot.title}
            </div>
            <div className={`${c}_address`}>
              {formatAddress(propertySnapshot)}
            </div>
          </Link>
          {!!roomies.length && (
            <div className={`${c}_roomies`}>
              <div className={`${c}_roomies_label`}>
                Roomies
              </div>
              {roomies.slice(0, 4).map(roomie => (
                <div key={roomie.id} className={`${c}_roomie`}>
                  <Avatar {...roomie} size={36} />
                </div>
              ))}
              {roomies.length === 4 && (
                <Link to="/guest/roomies" className={`${c}_viewAllRoomies`}>
                  See all
                </Link>
              )}
            </div>
          )}
        </div>
        <div className={`${c}_actions`}>
          <div className={`${c}_actions_inner`}>
            <Link to={`/guest/bookings/${booking.id}`}>
              <Button hollow size="small">Stay Details</Button>
            </Link>
            <MessageModal
              description="Questions about your housing that were not addressed in the listing? Ask your hosts! They are here to help."
              recipient={host}
              contextId={{ bookingId: booking.id }}
              renderLink={() => (
                <div className={`${c}_messageLink`}>Message Host</div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

GuestCurrentBooking.propTypes = propTypes

export default GuestCurrentBooking
