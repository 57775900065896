import React, { Component, Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { to404 } from '@/shared/utils/404'
import { formatDate, formatDateRange } from '@/shared/utils/dateTime'
import { handleError } from '@/shared/api'
import * as api from '@/events/api'
import { Head, PageSpinner, Avatar, TextEditedHtml, UsersList,
  Icon, Modal, InfoBanner, Photo } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import Controls from './Controls'
import Location from './Location'
import EventCard from '../EventCard'
import '@/events/styles/event.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object.isRequired,
}

const Event = (props) => {

  const { eventId } = useParams()
  const isFirstRender = useRef(true)

  const [state, setState] = useState({ 
    event: null, otherEvents: [] 
  })

  useEffect(() => {
    fetchEvent()
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return
    }
    fetchOtherEvents()
    analytics.pageview('Event', {
      eventId: state.event.id,
      eventTitle: state.event.title,
    })
  }, [state.event])
  

  const fetchEvent = () => {
    api.fetchEvent(eventId).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        event: data
      }))
    }, () => {
      to404('We could not find this event.')
    })
  }  

  const fetchOtherEvents = () => {
    const { event } = state
    api.fetchEvents({
      userId: event.user.id,
      page: 1,
    }).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        otherEvents: data.filter(e => e.id !== event.id).slice(0, 6)
      }))
    }, () => {})
  }

  const { event, otherEvents, isFetching } = state
  return (
    <div className="event">
      <AppHeader />
      {event && <Head title={`${event.title} - Roomsie`} />}
      <div className="event_page">
        {event ? (
          <Fragment>
            <div className="event_main">
              {props.currentUser.id === event.user.id && (
                <StatusBanners event={event} />
              )}
              <Hero event={event} />
              <Controls event={event} fetchEvent={fetchEvent} />
              <Content event={event} />
              <div className="event_sidebar">
                <Location event={event} />
                <UsersList
                  users={event.attendees}
                  title={`
                    Who's Attending
                    (${event.attendeesCount}${event.maximumGuests ? `/${event.maximumGuests}` : ''})
                  `}
                />
              </div>
            </div>
            {!!state.otherEvents.length && <OtherEvents event={event} otherEvents={otherEvents} />}
          </Fragment>
        ) : (
          <PageSpinner />
        )}
      </div>
      <AppFooter />
    </div>
  )
}

const StatusBanners = ({ event }) => {
  if (event.status === 'pending') {
    return (
      <InfoBanner
        className="event_pendingBanner"
        title="Congrats! Your event has been submitted for approval."
        message="Invitations will go out pending approval by the admin. Don't worry! This usually happens in a matter of hours."
      />
    )
  } else if (event.status === 'rejected') {
    return (
      <InfoBanner
        className="event_rejectedBanner"
        title="Sorry! Your event was rejected."
        message="Please edit your event and apply necessary changes outlined in the email you received. Your event will be automatically re-submitted for approval upon applying the changes."
      />
    )
  }
  return null
}

const Hero = ({ event }) => {
  const c = 'event_hero'
  return (
    <Photo cover photo={event.photo} size="medium" className={c}>
      <div className={`${c}_inner`}>
        {event.categories.includes('roomsie_socials') ? (
          <div className={`${c}_tag ${c}_tag--roomsie`}>
            Roomsie Social
          </div>
        ) : (
          <div className={`${c}_tag`} style={{ opacity: 0 }}>_</div>
        )}
        <div className={`${c}_title`}>{event.title}</div>
        <div className={`${c}_creator`}>
          <Avatar {...event.user} size={44} />
          <span className={`${c}_creator_name`}>
            {`Organized by ${event.user.firstName}`}
          </span>
        </div>
        <div className={`${c}_dates`}>
          {event.endDate && event.endDate !== event.startDate ? (
            formatDateRange(event.startDate, event.endDate)
          ) : (
            formatDate(event.startDate)
          )}
        </div>
        <div className={`${c}_times`}>
          {event.endTime ? (
            `${event.startTime} — ${event.endTime}`
          ) : (
            event.startTime
          )}
        </div>
      </div>
    </Photo>
  )
}

const Content = ({ event }) => (
  <Fragment>
    <div className="event_sectionTitle">Event Details</div>
    <TextEditedHtml html={event.descriptionHtml} />
    <div className="event_sectionTitle">Categorized Under</div>
    <div className="event_categories">
      {event.categories.map(category => (
        <div key={category} className="event_categories_item">
          <Icon type="check" />
          {options.labels.interests[category]}
        </div>
      ))}
    </div>
  </Fragment>
)

const OtherEvents = ({ event, otherEvents }) => (
  <div className="event_otherEvents">
    <div className="event_sectionTitle">
      {`Other Events Organized by ${event.user.firstName}`}
    </div>
    <div className="event_otherEvents_list">
      {otherEvents.map(event => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  </div>
)

Event.propTypes = propTypes

export default connect(mapStateToProps)(Event)
