import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import pubsub from 'sweet-pubsub'
import { useNavigate } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { removeStoredToken } from '@/features/auth/utils'
import { removeCurrentUser } from '@/features/auth/state/actions'

const mapDispatchToProps = { removeCurrentUser }

const propTypes = {
  removeCurrentUser: PropTypes.func.isRequired,
}

const SignoutRedirect = (props) => {
  const { removeCurrentUser } = props
  const navigate = useNavigate()

  useEffect(() => {
    analytics.track('Signed Out')
    removeCurrentUser()
    removeStoredToken()
    showToast({ title: 'You are signed out.', duration: 3 })
    navigate('/')
    pubsub.emit('signout')
  }, [])
  
  return null 
}

SignoutRedirect.propTypes = propTypes

export default connect(null, mapDispatchToProps)(SignoutRedirect)
