import humps from 'humps'
import _ from 'lodash'

const options = humps.camelizeKeys(window.DB_OPTIONS)

options.labels = _.mapValues(options, section => (
  _.fromPairs(
    _.map(section, option => [option?.value, option?.label])
  )
))

export default options
