import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import * as policies from '@/shared/static/policies'
import { getFirstParagraphFromHtml } from '@/shared/utils/html'
import { Switch, TextEditedHtml } from '@/shared/components'
import Amenities from './Amenities'
import '@/property/styles/moreInfo.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
}

const c = 'property_moreInfo'

const MoreInfo = ({ property }) => {
  const rulesHtml = property.rulesHtml.trim() || `<p>${policies.rulesDefault}</p>`
  const rulesFirstParagraph = getFirstParagraphFromHtml(rulesHtml)
  return (
    <div className={c}>
      <h2 className={`${c}_title`}>Amenities</h2>
      <Amenities amenities={property.amenities} />

      <h2 className={`${c}_title`}>House Rules</h2>
      <Switch
        linkText="Read more about the rules"
        shouldShowLink={() => rulesHtml.length > rulesFirstParagraph.length}
        renderDefault={() => <TextEditedHtml html={rulesFirstParagraph} />}
        renderSwitch={() => <TextEditedHtml html={rulesHtml} />}
      />

      <h2 className={`${c}_title`}>Payment</h2>
      <p className={`${c}_payment_paragraph`}>
        {`To protect your payment, never transfer money or communicate outside of the Roomsie website.`}
      </p>

      <h2 className={`${c}_title`}>Cancellation Policy</h2>
      <p className={`${c}_cancellation_paragraph`}>{policies.cancellationShort}</p>
      <Link
        className={`${c}_cancellation_link`}
        to="/cancellation-policy"
        target="_blank"
      >
        Read More
      </Link>
    </div>
  )
}

MoreInfo.propTypes = propTypes

export default MoreInfo
