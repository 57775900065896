import api from '@/shared/api'

export const fetchConversations = query => (
  api.get('/conversations', query, {
    abortCurrentRequest: 'fetchConversations',
  })
)

export const fetchConversation = conversationId => (
  api.get(`/conversations/${conversationId}`)
)

export const createMessage = (conversationId, payload) => (
  api.post(`/conversations/${conversationId}/messages`, payload)
)
