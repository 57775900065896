import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userLinks } from '@/shared/static/menuLinks'
import storage from '@/shared/utils/storage'
import { Media } from '@/shared/components'
import Desktop from './Desktop'
import Mobile from './Mobile'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
  renderAfterLogo: PropTypes.func,
}

class AppHeader extends Component {

  state = { mode: 'guest' }

  componentDidMount() {
    this.setMode()
  }

  componentDidUpdate() {
    this.setMode()
  }

  userId = () => this.props.currentUser.id.toString()

  userLinks = () => (
    this.state.mode === 'host' ? userLinks : (
      userLinks.filter(l => !/events|messages/.test(l.to))
    )
  )

  setMode = () => {
    if (!this.props.currentUser) return
    const storedMode = storage.local.getJSON('navMode', this.userId())
    if (storedMode) {
      if (this.state.mode !== storedMode) {
        this.setState({ mode: storedMode })
      }
    } else {
      this.updateMode(this.props.currentUser.isHost ? 'host' : 'guest')
    }
  }

  updateMode = mode => {
    storage.local.updateJSON('navMode', o => {
      o[this.userId()] = mode
    })
    this.setState({ mode })
  }

  render() {
    const props = {
      ...this.props,
      mode: this.state.mode,
      updateMode: this.updateMode,
      userLinks: this.userLinks(),
    }
    return (
      <Media
        query="(max-width: 767px)"
        match={() => <Mobile {...props} />}
        unmatch={() => <Desktop {...props} />}
      />
    )
  }
}

AppHeader.propTypes = propTypes

export default connect(mapStateToProps, null)(AppHeader)

export const appHeaderClassName = 'appHeader'
