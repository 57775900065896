import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { showToast } from '@/shared/utils/toast'
import { formatPrice } from '@/shared/utils/numbers'
import { handleError } from '@/shared/api'
import { reviewBooking } from '@/hostBookings/api'
import { Form, Field, Button, Modal, ConfirmModal } from '@/shared/components'
import '@/hostBookings/styles/review.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

const c = 'hostBooking_review'

class Review extends Component {

  state = { isDepositStepActive: false }

  handleSubmitWithoutDeposit = () => {
    reviewBooking(this.props.booking.id, { depositDeduction: 0 }).then(() => {
      this.props.fetchBooking(() => {
        showToast({ title: 'Your booking was finalized successfully.' })
      })
    }, handleError)
  }

  renderFirstStep = () => (
    <Fragment>
      <div className={`${c}_title`}>
        Manage Deposit
      </div>
      <p>{`If ${this.props.booking.guest.firstName} didn't do any damage to your property, you can go ahead and finalize the booking. If some damage was done, you can keep a part of the deposit.`}</p>
      <p><strong>{`If you don't take action, the deposit will be refunded in full automatically 45 days after guest's departure date.`}</strong></p>
      <div className={`${c}_actions`}>
        <Button
          hollow
          onClick={() => this.setState({ isDepositStepActive: true })}
        >
          Deduct from deposit
        </Button>
        <ConfirmModal
          dontCloseOnConfirm
          message="Are you sure you want to refund the deposit in full and finalize the booking?"
          confirmText="Refund deposit in full"
          onConfirm={this.handleSubmitWithoutDeposit}
          renderLink={() => <Button>Refund deposit in full</Button>}
        />
      </div>
    </Fragment>
  )

  renderDepositStep = () => (
    <Form
      initialValues={{
        reviewMessage: '',
        depositAmount: '',
      }}
      validations={{
        reviewMessage: Form.is.required(),
        depositAmount: [Form.is.required(), Form.is.match(
          v => +v <= this.props.booking.depositAmount,
          'You have exceeded the deposited amount'
        )],
      }}
      onSubmit={(values, form) => {
        reviewBooking(this.props.booking.id, {
          depositDeduction: +values.depositAmount,
          message: values.reviewMessage,
        }).then(() => {
          this.props.fetchBooking(() => {
            showToast({ title: 'Your booking was finalized successfully.' })
          })
        }, ({ error }) => {
          if (error.details) {
            form.setErrors(error.details)
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className={`${c}_title`}>
            Deduct From Deposit
          </div>
          <p>
            <strong>The guest should not be held responsible for normal wear and tear of the property due to every day use. You should only deduct from their deposit if real damage was done.</strong>
          </p>
          <p>
            <strong>Examples of normal wear and tear:</strong>
          </p>
          <ul>
            <li>A Couple of Small Stains on a Carpet</li>
            <li>A small Scrape or Ding in a Wood Floor</li>
            <li>Loose Door Handles</li>
            <li>Silver Finish on Bathroom Fixtures Beginning to Wear Away</li>
          </ul>
          <p>
            <strong>Examples of damage:</strong>
          </p>
          <ul>
            <li>A Smashed Bathroom Mirror</li>
            <li>Broken Toilet Seat</li>
            <li>A Hole in the Middle of the Door</li>
            <li>Carpet Soaked With Pet Urine</li>
          </ul>
          <Field.Input
            {...fields.depositAmount}
            label={`How much would you like to keep from the
              ${formatPrice(this.props.booking.depositAmount)} deposit?`}
            icon="dollar"
            filter={/^[0-9]*$/}
          />
          <Field.Textarea
            {...fields.reviewMessage}
            label="Provide an itemized list of reasons for keeping the deposit"
            minRows={4}
            placeholder={`— Lost pair of keys\n— Broken kitchen window\n— Cracked bathroom sink`}
          />
          <div className={`${c}_actions`}>
            <Button hollow onClick={() => this.setState({ isDepositStepActive: false })}>
              Back
            </Button>
            <Button working={isSubmitting}>
              {`Keep ${formatPrice(fields.depositAmount.value || 0)} from Deposit`}
            </Button>
          </div>
        </form>
      )}
    />
  )

  render() {
    return (
      <Modal
        className={c}
        containsForm
        renderLink={() => <Button>Manage Deposit</Button>}
        renderContent={
          !this.state.isDepositStepActive ? this.renderFirstStep : this.renderDepositStep
        }
      />
    )
  }
}

Review.propTypes = propTypes

export default Review
