import React, { Component, Fragment } from 'react'
import * as analytics from '@/shared/utils/analytics'
import { toOpen } from '@/features/helpModal/utils'
import { handleError } from '@/shared/api'
import { fetchPrograms } from '@/hostPrograms/api'
import { DashboardMenu, PageSpinner, Head, Button, EmptyState } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import ProgramCard from './ProgramCard'
import '@/hostPrograms/styles/index.scss'

const c = 'hostPrograms'

class Programs extends Component {

  state = { programs: null }

  componentDidMount() {
    analytics.pageview('Host Programs')
    fetchPrograms().then(({ data }) => {
      this.setState({ programs: data })
    }, handleError)
  }

  render() {
    const { programs } = this.state
    return (
      <div className={c}>
        <Head title="Partners - Roomsie" />
        <AppHeader />
        <DashboardMenu role="host" />
        <div className={`${c}_page`}>
          {programs && !!programs.length && (
            <Fragment>
              <h1 className={`${c}_title`}>Partners</h1>
              <div className={`${c}_programs`}>
                {programs.map(program => (
                  <ProgramCard key={program.id} program={program} />
                ))}
              </div>
            </Fragment>
          )}
          {programs && !programs.length && (
            <EmptyState
              title="There are currently no partners that have been assigned to this account"
              message="Our Partners feature gives operators the tools they need to easily assign properties & manage large numbers of beds for their university, non-profit, government or private partners. Please contact us for more information on how to use this function to boost your rental business."
              renderButton={() => <Button onClick={toOpen}>Contact Us</Button>}
            />
          )}
          {!programs && <PageSpinner />}
        </div>
        <AppFooter />
      </div>
    )
  }
}

export default Programs
