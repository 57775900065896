import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import classnames from 'classnames'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { dateToString, formatDate } from '@/shared/utils/dateTime'
import { sortPhotos } from '@/shared/utils/photos'
import { formatAddress } from '@/shared/utils/geo'
import { handleError } from '@/shared/api'
import { fetchBookings, fetchPrograms, fetchProperties } from '@/hostGuests/api'
import { DashboardMenu, Input, Select, DateRangePicker, Pagination,
  PageSpinner, Head, EmptyState, Photo } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import GuestCard from './GuestCard'
import '@/hostGuests/styles/allGuests.scss'

const c = 'hostGuestsAll'

class AllGuests extends Component {

  fetchCount = 0

  getInitialQuery = () => ({
    page: 1,
    query: null,
    startDate: null,
    endDate: null,
    programId: null,
    propertyId: null,
  })

  state = {
    bookings: [],
    pagination: null,
    isFetching: true,
    programs: [],
    properties: [],
    query: this.getInitialQuery(),
  }

  componentDidMount() {
    this.fetchBookings()
    analytics.pageview('Host Guests All')
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.query.page !== prevState.query.page) {
      window.scrollTo(0, 100)
    }
  }

  fetchBookings = (query = {}) => {
    this.fetchCount += 1
    query = {
      ...this.state.query,
      ...query,
      page: query.page || 1,
    }
    this.setState({
      query,
      isFetching: true,
    })
    fetchBookings({
      ...query,
      status: ['accepted', 'started'],
    }).then(({ data, meta }) => {
      this.setState({
        bookings: data,
        pagination: meta,
        isFetching: false,
      })
    }, handleError)
  }

  fetchPrograms = () => {
    if (this.fetchedPrograms) return
    this.fetchedPrograms = true
    fetchPrograms().then(({ data }) => this.setState({ programs: data }))
  }

  fetchProperties = () => {
    if (this.fetchedProperties) return
    this.fetchedProperties = true
    fetchProperties().then(({ data }) => this.setState({ properties: data }))
  }

  renderPage = () => {
    const hasActiveFilters = !_.isEqual(this.state.query, this.getInitialQuery())

    if (this.state.bookings.length || hasActiveFilters || this.fetchCount > 1) {
      return this.renderPageContent(this.state)
    }
    if (this.state.isFetching) {
      return <PageSpinner />
    }
    return (
      <EmptyState
        title="You do not have any guests yet."
        message="After you start receiving bookings, all of your guests will be displayed here. You will be able to see who is in which property, exchange messages, and more."
        buttonText="Manage your properties"
        buttonLink="/host/properties"
      />
    )
  }

  renderPageContent = ({ bookings, pagination, isFetching }) => (
    <Fragment>
      <div className={`${c}_top`}>
        <h1>My Guests</h1>
      </div>
      {this.renderFilters()}
      {this.state.query.propertyId && this.renderProperty()}
      <div className={classnames(`${c}_list_container`, {
        [`${c}_list_container--isFetching`]: isFetching,
      })}>
        {!bookings.length && isFetching && <PageSpinner />}
        {!bookings.length && !isFetching && (
          <div className={`${c}_list_empty`}>
            We could not find any guests.
          </div>
        )}
        {!!bookings.length && (
          <Fragment>
            {bookings.map(booking => (
              <GuestCard key={booking.id} booking={booking} />
            ))}
            <Pagination
              onChange={page => this.fetchBookings({ page })}
              {...pagination}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  )

  renderFilters = () => (
    <div className={`${c}_filters_container`}>
      <div className={classnames(`${c}_filters`, {
        [`${c}_filters--areMobileFiltersOpen`]: this.state.areMobileFiltersOpen,
      })}>
        <div className={`${c}_filters_inner`}>
          <div className={`${c}_filters_item`}>
            <Select
              size="small"
              options={[
                { label: 'All Properties', value: '' },
                ...this.state.properties.map(p => ({
                  label: `${p.title}, ${p.streetAddress}, ${p.streetAddress2 || ''}`,
                  value: p.id,
                })),
              ]}
              value={this.state.query.propertyId || ''}
              onChange={(e, propertyId) => this.fetchBookings({ propertyId })}
              onClick={this.fetchProperties}
            />
            {!this.state.areMobileFiltersOpen && (
              <div
                className={`${c}_filters_showMoreMobile`}
                onClick={() => this.setState({ areMobileFiltersOpen: true })}
              >
                Show more filters
              </div>
            )}
          </div>
          <div className={`${c}_filters_item`}>
            <DateRangePicker
              inputProps={{ size: 'small' }}
              placeholder="Filter guests by date"
              minimumStayDays={0}
              disablePastDays={false}
              onChange={dates => this.fetchBookings({
                startDate: dateToString(dates.from),
                endDate: dateToString(dates.to),
              })}
            />
          </div>
          <div className={`${c}_filters_item`}>
            <Select
              size="small"
              options={[
                { label: 'Select Partner', value: '' },
                ...this.state.programs.map(p => ({ label: p.title, value: p.id })),
              ]}
              value={this.state.query.programId || ''}
              onChange={(e, value) => this.fetchBookings({ programId: value || null })}
              onClick={this.fetchPrograms}
            />
          </div>
          <div className={`${c}_filters_item`}>
            <Input
              size="small"
              icon="search"
              placeholder="Search guests by name"
              onChange={_.debounce((e, query) => this.fetchBookings({ query }), 700)}
            />
          </div>
        </div>
      </div>
    </div>
  )

  renderProperty = () => {
    const property = this.state.properties.find(p => p.id === +this.state.query.propertyId)
    return (
      <div className={`${c}_property`}>
        <Photo photo={sortPhotos(property.photos)[0]} />
        <div className={`${c}_property_content`}>
          <div className={`${c}_property_title`}>
            {property.title}
          </div>
          <div className={`${c}_property_address`}>
            {formatAddress(property)}
          </div>
          <div className={`${c}_property_listingType`}>
            {`${options.labels.placementTypes[property.placementType]}
              ${options.labels.listingTypes[property.listingType].replace('Space', '')} Space`}
          </div>
          <div className={`${c}_property_createdAt`}>
            {`Added on ${formatDate(property.createdAt)}`}
          </div>
          <Link className={`${c}_property_link`} to={`/properties/${property.id}`}>
            View Listing
          </Link>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={c}>
        <Head title={`All Guests - Roomsie`} />
        <AppHeader />
        <DashboardMenu role="host" />
        {this.renderPage()}
        <AppFooter />
      </div>
    )
  }
}

export default AllGuests
