import api, { handleError } from '@/shared/api'
import storage from '@/shared/utils/storage'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { storeToken, storeCurrentUser, removeStoredCurrentUser } from '@/features/auth/utils'
import * as oauth from '@/features/auth/api/oauth'

export const fetchCurrentUser = () => dispatch => (
  api.get('/users/me', {}, {
    abortCurrentRequest: 'authFetchCurrentUser',
  }).then(({ data }) => {
    dispatch(updateCurrentUserState(data))
  }, handleError)
)

export const updateCurrentUser = userData => dispatch => (
  api.put('/user', userData).then(({ data }) => {
    dispatch(updateCurrentUserState(userData))
  })
)

export const signUp = payload => dispatch => (
  api.post(payload.oauthToken ? '/oauth_users' : '/users', payload).then(({ data }) => {
    analytics.handleSignUp(data.user, payload.provider)
    storeToken(data.token)
    dispatch(updateCurrentUserState(data.user))
  })
)

export const signIn = credentials => dispatch => (
  api.post('/session', credentials).then(({ data }) => {
    analytics.handleSignIn(data.user, 'email')
    storeToken(data.token)
    dispatch(updateCurrentUserState(data.user))
  })
)

export const signUpOauth = ({
  provider,
  setSubmitting,
  onSuccess,
  onIncomplete,
}) => dispatch => {

  window.onOauthRedirect = code => {
    oauth.closeWindowIfOpen()
    setSubmitting(provider)

    api.post('/oauth_users', {
      code,
      provider,
      rememberMe: true,
    }).then(({ data }) => {
      analytics.handleSignUp(data.user, provider)
      storeToken(data.token)
      dispatch(updateCurrentUserState(data.user))
      onSuccess()
    }, ({ error, body }) => {
      if (error.code === 'invalid_resource') {
        onIncomplete(body.data)
      } else if (error.code === 'record_not_unique') {
        showToast({
          type: 'danger',
          title: 'You already have an account',
          message: "That's why you can't sign up. Please sign in instead.",
        })
      } else {
        handleError({ error })
      }
      setSubmitting(false)
    })
  }
  oauth.openWindow(provider)
}

export const signInOauth = ({
  provider,
  setSubmitting,
  onSuccess,
}) => dispatch => {

  window.onOauthRedirect = code => {
    oauth.closeWindowIfOpen()
    setSubmitting(provider)

    api.post('/session', {
      code,
      provider,
      rememberMe: true,
    }).then(({ res, data }) => {
      analytics.handleSignIn(data.user, provider)
      storeToken(data.token)
      dispatch(updateCurrentUserState(data.user))
      onSuccess()
    }, ({ error }) => {
      if (error.code === 'not_found') {
        showToast({
          type: 'danger',
          title: "You don't have an account yet",
          message: "That's why you can't sign in. Please sign up instead.",
        })
      } else {
        handleError({ error })
      }
      setSubmitting(false)
    })
  }
  oauth.openWindow(provider)
}

export const resetPassword = email => dispatch => (
  api.post('/password/send_reset', { email })
)

export const createPassword = (password, token) => dispatch => (
  api.patch('/password/reset', { password, token })
)

export const acceptTerms = () => dispatch => (
  api.post('/terms/accept').then(() => {
    analytics.track('Verified Terms')
    dispatch(updateCurrentUserState({ termsAccepted: true }))
  }, handleError)
)

export const sendVerificationEmail = email => dispatch => (
  api.patch('/emails', { email }).then(() => {
    dispatch(updateCurrentUserState({ email }))
  })
)

export const verifyEmail = code => dispatch => (
  api.post('/emails/verify', { code }).then(() => {
    analytics.track('Verified Email')
    dispatch(updateCurrentUserState({ emailVerified: true }))
    showToast({ title: 'Your email was verified successfully.' })
  })
)

export const sendVerificationSms = phoneNumber => dispatch => (
  api.patch('/phone_numbers', { phoneNumber }).then(() => {
    analytics.track('Verify Phone Number Entered')
    dispatch(updateCurrentUserState({ phoneNumber }))
  })
)

export const verifyPhoneNumber = pin => dispatch => (
  api.patch('/phone_numbers/verify', { pin }).then(() => {
    analytics.track('Verified Phone Number')
    dispatch(updateCurrentUserState({ phoneNumberVerified: true }))
    showToast({ title: 'Your phone number was verified successfully.' })
  })
)

export const claimAccount = (password, token) => dispatch => (
  api.patch('/users/claim', { password, token }).then(({ data }) => {
    analytics.handleSignUp(data.user, 'email')
    storeToken(data.token)
    dispatch(updateCurrentUserState(data.user))
  })
)

export const updateCurrentUserState = data => (dispatch, getState) => {
  const currentUser = { ...getState().auth.currentUser, ...data }
  storeCurrentUser(currentUser)
  dispatch({
    type: 'auth/currentUser/UPDATED',
    currentUser,
  })
}

export const removeCurrentUser = () => {
  removeStoredCurrentUser()
  return {
    type: 'auth/currentUser/REMOVED',
  }
}
