import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import usStates from '@/shared/static/usStates'
import { handleError } from '@/shared/api'
import { Form, Button, Field, Icon } from '@/shared/components'

const propTypes = {
  stripe: PropTypes.func,
  account: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  hasCompanyAccount: PropTypes.func.isRequired,
}

const c = 'hostPayment_accountForm'

class CompanyForm extends Component {

  constructor(props) {
    super(props)
    this.state = { isEditing: !props.hasCompanyAccount() }
  }

  isTaxIdProvided = () => _.get(this.props.account, 'company.taxIdProvided')

  getAccountData = () => {
    const get = key => _.get(this.props.account, `company.${key}`) || ''
    return {
      name: get('name'),
      line1: get('address.line1'),
      city: get('address.city'),
      state: get('address.state'),
      postalCode: get('address.postalCode'),
      taxId: get('taxId'),
    }
  }

  handleSubmit = (values, form) => {
    const handleStripeError = description => {
      handleError({ error: { description } })
      form.setSubmitting(false)
    }
    this.stripe = this.props.stripe(window.STRIPE_PUBLISHABLE_KEY)
    this.stripe.createToken('account', {
      business_type: 'company',
      company: {
        address: {
          line1: values.line1,
          city: values.city,
          state: values.state,
          postal_code: values.postalCode,
          country: 'US',
        },
        name: values.name,
        tax_id: values.taxId || undefined,
      },
      tos_shown_and_accepted: true,
    }).then(result => {
      if (result.error) {
        handleStripeError(result.error.message)
      } else {
        this.props.onSubmit({
          account_token: result.token.id,
          business_profile: {
            product_description: 'Apartment/Bedroom rentals for students & interns',
            url: `https://roomsie.com/profile/${this.props.currentUser.id}`,
          },
        }).then(() => {
          this.setState({ isEditing: false })
        }, ({ error }) => {
          handleStripeError(error.description || error.message)
        })
      }
    }, error => handleStripeError(error.message))
  }

  renderForm = () => (
    <div className={c}>
      <h2 className={`${c}_title`}>
        Please enter your company information
        {this.props.hasCompanyAccount() && (
          <div
            className={`${c}_editLink`}
            onClick={() => this.setState({ isEditing: false })}
          >
            <Icon type="edit" />
            Cancel
          </div>
        )}
      </h2>
      <Form
        initialValues={this.getAccountData()}
        validations={{
          name: Form.is.required(),
          line1: Form.is.required(),
          city: Form.is.required(),
          state: Form.is.required(),
          postalCode: Form.is.required(),
          taxId: Form.is.match(v => (
            this.isTaxIdProvided() ? (/^\d{9}$/.test(v) || v === '') : /^\d{9}$/.test(v)
          ), 'Must be 9 digits'),
        }}
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className={`${c}_fields`}>
              <Field.Input {...fields.name} label="Company Name" />
              <Field.Input {...fields.line1} label="Street Address" />
              <Field.Input {...fields.city} label="City" />
              <Field.Select {...fields.state} label="State" placeholder="Select" options={usStates} />
              <Field.Input {...fields.postalCode} label="Postal Code" />
              <Field.Input
                {...fields.taxId}
                label="Employer Identification Number (EIN)"
                placeholder={this.isTaxIdProvided() ? 'Provided' : ''}
                onChange={(e, value) => setFieldValue('taxId', value.trim())}
              />
            </div>
            <Button type="submit" working={isSubmitting} className={`${c}_submit`}>
              Submit
            </Button>
            <p className={`${c}_stripeTerms`}>
              By clicking "Submit", you agree to <a href="https://stripe.com/US/connect-account/legal" target="_blank">Stripe Connected Account Agreement</a>.
            </p>
          </form>
        )}
      />
    </div>
  )

  renderShow = () => {
    const accountData = this.getAccountData()

    const renderValue = (label, value) => (
      <div className={`${c}_value`}>
        <span>{label}</span>
        {value || '—'}
      </div>
    )
    return (
      <div className={c}>
        <h2 className={`${c}_title`}>
          Company Information
          <div
            className={`${c}_editLink`}
            onClick={() => this.setState({ isEditing: true })}
          >
            <Icon type="edit" />
            Edit
          </div>
        </h2>
        <div className={`${c}_values`}>
          {renderValue('Company Name', accountData.name)}
          {renderValue('Street Address', accountData.line1)}
          {renderValue('City', accountData.city)}
          {renderValue('State', accountData.state)}
          {renderValue('Postal Code', accountData.postalCode)}
          {renderValue('EIN', this.isTaxIdProvided() ? 'Provided' : '')}
        </div>
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderForm() : this.renderShow()
  }
}

CompanyForm.propTypes = propTypes

export default CompanyForm
