import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import options from '@/shared/static/options'
import { uniqueDateId } from '@/shared/utils/id'
import { dateToString, stringToDate } from '@/shared/utils/dateTime'
import { syncRatesWithSessions } from '@/listSpace/state/sync'
import { formatDateRange } from '@/shared/utils/dateTime'
import { decimalPriceInputFilter } from '@/shared/utils/numbers'
import { Field, Input, Icon, Radio, Select, Modal, Button,
  FieldErrors, Media, ConfirmModal } from '@/shared/components'
import PhotosUploader from '@/listSpace/components/PhotosUploader'
import SplitSessionRates from './SplitSessionRates'

const propTypes = {
  property: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  bedroom: PropTypes.object.isRequired,
  bedrooms: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  sessions: PropTypes.array.isRequired,
  updateProperty: PropTypes.func.isRequired,
  onBedroomChange: PropTypes.func.isRequired,
  onBedroomRemove: PropTypes.func.isRequired,
  onBedroomClone: PropTypes.func.isRequired,
}

const c = 'listSpace_roomsRates_bedroom'

class Bedroom extends Component {

  constructor(props) {
    super(props)
    this.state = { isOpen: !props.index }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
  }

  componentDidUpdate() {
    const hasErrors = _.values(this.props.errors).filter(e => e).length
    if (!this.state.isOpen && hasErrors) {
      this.setState({ isOpen: true })
    }
  }

  updateBedroom = bedroomData => {
    this.props.onBedroomChange({ ...this.props.bedroom, ...bedroomData })
  }

  updateBeds = bedsCount => {
    const { bedroom } = this.props
    const updatedBeds = []

    _.times(bedsCount, i => {
      updatedBeds.push(
        bedroom.beds[i] ? bedroom.beds[i] : this.getNewBed()
      )
    })
    this.props.onBedroomChange({ ...bedroom, beds: updatedBeds })
  }

  updateBed = (bedId, bedData) => {
    const bedroom = _.cloneDeep(this.props.bedroom)
    const bed = bedroom.beds.find(b => b.uid === bedId)
    _.merge(bed, bedData)
    this.props.onBedroomChange(bedroom)
  }

  updateBedRate = (bedId, sessionUid, amount) => {
    const bedroom = _.cloneDeep(this.props.bedroom)
    const bed = bedroom.beds.find(b => b.uid === bedId)
    const rate = bed.rates.find(r => r.sessionUid === sessionUid)
    rate.amount = amount
    this.props.onBedroomChange(bedroom)
  }

  getNewBed = () => ({
    uid: uniqueDateId(),
    size: 'twin',
    kind: 'regular',
    rates: this.getSortedSessions().map(({ uid }) => ({
      amount: '',
      sessionUid: uid,
    })),
  })

  getSortedSessions = () => (
    this.props.sessions.sort((a, b) => a.uid - b.uid)
  )

   getSessions = () => (
    this.stringsToDates(this.props.sessions)
  )

  getMainSessions = () => {
    let filteredSessions = []
    const sessions = this.getSortedSessions()
    const groupedSessions =  _.groupBy(sessions, 'startDate')
    filteredSessions = Object.entries(groupedSessions).map(sessionGroup => (
      sessionGroup[1][0]
    ))
    return filteredSessions
  }

  datesToStrings = items => items.map(item => ({
    ...item,
    startDate: dateToString(item.startDate),
    endDate: dateToString(item.endDate),
  }))

  stringsToDates = items => (items.map(item => ({
    ...item,
    startDate: stringToDate(item.startDate),
    endDate: stringToDate(item.endDate),
  })))


  handleSessionsChange = items => {
    this.props.updateProperty({
      sessions: this.datesToStrings(items.sessions),
      ...syncRatesWithSessions(items.sessions, this.props.property),
    })
  }


  beforePhotosModalClose = () => {
    if (this.getPhotosProgress().uploading) {
      if (
        !confirm(`You should wait before all photos are finished uploading. If you leave now only uploaded photos will be saved. Would you still like to leave?`)
      ) {
        return false
      }
    }
    this.updateBedroom({
      photos: this.props.bedroom.photos.filter(p => !p.isUploading),
    })
    return true
  }

  renderPhotosModal = bedroom => (
    <Modal
      className={`${c}_photosModal`}
      renderLink={() => (
        <Fragment>
          <Button
            square
            size="small"
            type="button"
            className={`${c}_photosLink`}
          >
            {`Add Photos (${bedroom.photos.length})`}
          </Button>
          <FieldErrors errors={this.props.errors.photos} />
        </Fragment>
      )}
      renderContent={({ closeModal }) => (
        <Fragment>
          <h2 className="listSpace_title">
            {`${bedroom.title} Photos`}
          </h2>
          <p className="listSpace_subtitle">
            {`Show guests what the bedroom looks like. Make sure to include at least one photo of the beds.`}
          </p>
          <PhotosUploader
            initialPhotos={bedroom.photos}
            minPhotos={1}
            maxPhotos={10}
            onPhotosChange={photos => this.updateBedroom({ photos })}
            getProgress={func => this.getPhotosProgress = func}
          />
          <div className={`${c}_photosModal_submit_container`}>
            <Button
              className={`${c}_photosModal_submit`}
              color="secondary"
              onClick={closeModal}
            >
              Okay
            </Button>
          </div>
        </Fragment>
      )}
      beforeClose={this.beforePhotosModalClose}
    />
  )

  renderBeds = bedroom => (
    <div className={`${c}_sessionBeds`}>
      <Media query="(max-width: 767px)" unmatch={() => (
        !!bedroom.beds.length && (
          <div className={`${c}_sessionBeds_labels`}>
            <div className={`${c}_sessionBeds_label`}>Nightly Rate</div>
            <div className={`${c}_sessionBeds_label`}>For Session</div>
            <div className={`${c}_sessionBeds_label`}>Bed Size</div>
            <div className={`${c}_sessionBeds_label`}>Bed Type</div>
          </div>
        )
      )} />
      {bedroom.beds.sort((a, b) => a.uid - b.uid).map((bed, i) => (
        <div key={bed.uid} className={`${c}_bed`}>
          <Media query="(max-width: 767px)" match={() => (
            <div className="listSpace_label">{`Bed ${i + 1} Nightly Rate`}</div>
          )} />
          {this.renderBedRates(bedroom, bed)}
          <div className={`${c}_sessionBed_sizeType`}>
            <Select
              size="small"
              options={options.bedSizes}
              value={bed.size}
              onChange={(e, size) => this.updateBed(bed.uid, { size })}
            />
            <Select
              size="small"
              options={options.bedTypes}
              value={bed.kind}
              onChange={(e, kind) => this.updateBed(bed.uid, { kind })}
            />
          </div>
        </div>
      ))}
    </div>
  )

  renderSplitSessionBeds = bedroom => (
    <div className={`${c}_beds`}>
      {bedroom.beds.sort((a, b) => a.uid - b.uid).map((bed, i) => (
        <div key={bed.uid} className={`${c}_bed`}>
          <Media query="(max-width: 767px)" match={() => (
            <div className="listSpace_label_bed">{`Bed ${i + 1} Nightly Rates`}</div>
          )} />
            <SplitSessionRates
            property={this.props.property}
            updateProperty={this.props.updateProperty}
            bed={bed}
            sessions={this.props.property?.sessions}
            rates={this.props.property?.rates}
            errors={this.props.errors || {}}
            onBedroomChange={this.props.onBedroomChange}
            handleRatesChange={this.updateBedRate}
            clearStepErrors={this.props.clearStepErrors}
          />
          <div className={`${c}_bed_sizeType`}>
            <div className={`${c}_bed_sizeType_selectContainer`}>
              <Media query="(max-width: 767px)" unmatch={() => (
                !!bedroom.beds.length && (
                    <div className={`${c}_beds_selectLabel`}>Bed Size</div>
                )
              )} />
              <Select
                size="small"
                options={options.bedSizes}
                value={bed.size}
                onChange={(e, size) => this.updateBed(bed.uid, { size })}
              />
            </div>
            <div className={`${c}_bed_sizeType_selectContainer`}>
              <Media query="(max-width: 767px)" unmatch={() => (
                !!bedroom.beds.length && (
                  <div className={`${c}_beds_selectLabel`}>Bed Type</div>
                )
              )} />
              <Select
                size="small"
                options={options.bedTypes}
                value={bed.kind}
                onChange={(e, kind) => this.updateBed(bed.uid, { kind })}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  renderBedRates = (bedroom, bed) => (
    <div className={`${c}_sessionBed_rates`}>
      {this.getMainSessions().map(session => (
        <div key={session.uid} className={`${c}_sessionBed_rate`}>
          {session.startDate && session.endDate && 
            <div className={`${c}_sessionBed_input`}>
              <Field.Input
                line
                icon="dollar"
                size="small"
                filter={decimalPriceInputFilter}
                value={_.get(
                  bed.rates.find(r => r.sessionUid === session.uid), 'amount', ''
                )}
                errors={this.props.errors[`${bed.uid}${session.uid}`]}
                onChange={(e, value) => {
                  this.updateBedRate(bed.uid, session.uid, value)
                }}
              />
            </div>
          }
          <div className={`${c}_sessionBed_session`}>
            <Media query="(max-width: 767px)" match={() => 'For '} />
            {`${formatDateRange(session.startDate, session.endDate)}`}
          </div>
        </div>
      ))}
    </div>
  )


  render() {
    const { index, bedroom, bedrooms, errors } = this.props
    const { isOpen } = this.state
    return (
      <div className={classnames(c, {
        [`${c}--isOpen`]: isOpen,
      })}>
        <div className={`${c}_top`}>
          <div className={`${c}_top_left`}>
            <Icon
              type={`chevron-${isOpen ? 'up' : 'down'}-fat`}
              onClick={() => this.setState({ isOpen: !isOpen })}
            />
            <Media query="(max-width: 767px)" unmatch={() => (
              <Input
                className={classnames({ [`${c}_title--hasError`]: errors.title })}
                size="small"
                placeholder="Enter bedroom title"
                value={bedroom.title}
                onChange={(e, title) => this.updateBedroom({ title })}
              />
            )} />
          </div>
          <div className={`${c}_top_right`}>
            <Select
              size="small"
              placeholder="Copy From"
              options={bedrooms.filter(b => b.uid !== bedroom.uid).map(b => ({
                label: b.title,
                value: b.uid,
              }))}
              value=""
              onChange={(e, value) => this.props.onBedroomClone(bedroom.uid, value)}
            />
            <ConfirmModal
              title="Delete bedroom"
              message="Are you sure you want to delete this bedroom? This action is permanent and cannot be reversed."
              confirmText="Delete"
              isDanger
              onConfirm={() => this.props.onBedroomRemove(bedroom.uid)}
              renderLink={() => <Icon type="trash" />}
            />
          </div>
        </div>
        {isOpen && (
          <div className={`${c}_inner`}>
            <Media query="(max-width: 767px)" unmatch={() => (
              <FieldErrors className={`${c}_titleError`} errors={errors.title} />
            )} />
            {this.renderPhotosModal(bedroom)}
            <Media query="(max-width: 767px)" match={() => (
              <Field.Input
                className={`${c}_titleMobile`}
                placeholder="Bedroom title"
                value={bedroom.title}
                onChange={(e, title) => this.updateBedroom({ title })}
                errors={errors.title}
              />
            )} />
            <div className={`${c}_description`}>
              <Field.Textarea
                minRows={2}
                maxRows={25}
                placeholder="Describe the room"
                value={bedroom.description}
                errors={errors.description}
                onChange={(e, description) => this.updateBedroom({ description })}
              />
            </div>
            <div className={`${c}_bathroom`}>
              <div className="listSpace_label">Is bathroom attached?</div>
              <Radio
                name={`${bedroom.uid}Bathroom`}
                label="Yes"
                value={true}
                checked={!!bedroom.bathroomAttached}
                onChange={() => this.updateBedroom({ bathroomAttached: true })}
              />
              <Radio
                name={`${bedroom.uid}Bathroom`}
                label="No"
                value={false}
                checked={!bedroom.bathroomAttached}
                onChange={() => this.updateBedroom({ bathroomAttached: false })}
              />
            </div>
            <div className={`${c}_occupancy`}>
              <Field.Select
                placeholder="Select Occupancy"
                options={options.bedsCount.map(c => ({
                  ...c, label: `${c.label} - ${c.description}`,
                }))}
                value={bedroom.beds.length || ''}
                errors={errors.beds}
                onChange={(e, value) => this.updateBeds(value)}
              />
            </div>
            <Media query="(max-width: 767px)" unmatch={() => (
              _.times(bedroom.beds.length, i => (
                <Icon key={i} type="bed" />
              ))
            )} />
            <div className="listSpace_roomsRates_counts_row">
              <div className="listSpace_label">Nightly Rates</div>
              <p className="listSpace_tip">
                Set the base price that will be charged per night. The nightly rates can be adjusted depending on the length of stay by adding a new rate for a session. Guests will be charged this rate if their stay exceeds the minimum duration of stay for that rate.
              </p>
            </div>
            {this.props.property.prorateForShortStay ? (
              this.renderSplitSessionBeds(bedroom)
            ):(
              this.renderBeds(bedroom)
            )}
          </div>
        )}
      </div>
    )
  }
}

Bedroom.propTypes = propTypes

export default Bedroom
