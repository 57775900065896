import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { sortPhotos } from '@/shared/utils/photos'
import { Button, Media, Photo } from '@/shared/components'
import ShareProperty from '@/features/shareProperty/components'
import Lightbox from './Lightbox'
import HeroGrid from './HeroGrid'
import '@/property/styles/hero.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
}

const Hero = ({ property }) => {
  const ref = useRef();
  const photos = sortPhotos(property.photos)
  return (
    <div className="property_hero">
      <Lightbox
        photos={photos}
        getOpenFunc={func => ref.current = func}
      />
      <Media
        query="(max-width: 767px)"
        match={() => (
          <Photo
            className="property_hero_mobilePhoto"
            photo={photos[0]}
            onClick={ref.current}
          />
        )}
        unmatch={() => (
          <HeroGrid photos={photos} onClick={ref.current} />
        )}
      />
      <div className="property_hero_actions">
        <ShareProperty
          property={property}
          renderLink={() => <Button square size="small" icon="share">Share</Button>}
        />
        <Button square size="small" icon="image" onClick={ref.current}>
          View Photos
        </Button>
      </div>
    </div>
  )
}

Hero.propTypes = propTypes

export default Hero
