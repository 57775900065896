import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@/shared/components'
import '@/shared/styles/verifiedMarkCovid19.scss'

const propTypes = {
  withOutline: PropTypes.bool,
  withoutLink: PropTypes.bool,
  label: PropTypes.string,
}

const VerifiedMarkCovid19 = ({ withOutline, withoutLink, label = 'COVID19 Compliant', customStyle = {} }) => {
  const renderContent = () => (
    <Fragment>
      {withOutline && <Icon type="verified" className="verifiedMarkCovid19_outline" />}
      <Icon type="verified" className="verifiedMarkCovid19_icon" />
      {label && <span>{label}</span>}
    </Fragment>
  )
  return withoutLink ? (
    <div className="verifiedMarkCovid19">{renderContent()}</div>
  ) : (
    <a
      className="verifiedMarkCovid19"
      href="https://roomsie.zendesk.com/hc/en-us/articles/360045060494"
      target="_blank"
      style={customStyle}
    >
      {renderContent()}
    </a>
  )
}

VerifiedMarkCovid19.propTypes = propTypes

export default VerifiedMarkCovid19
