import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import media from '@/shared/utils/media'
import { updateQuery } from '@/search/state/actions'
import { initialFilterState } from '@/search/state/reducers'
import useDidUpdate from '@/shared/hooks/useDidUpdate'
import { Pagination, PageSpinner, Button } from '@/shared/components'
import Spot from './Spot'
import Host from './Host'
import Result from './Result'
import '@/search/styles/results.scss'

const mapStateToProps = ({ search }) => ({
  results: search.results,
  ratesPeriod: search.options.ratesPeriod,
})

const mapDispatchToProps = { updateQuery }

const propTypes = {
  results: PropTypes.object.isRequired,
  ratesPeriod: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
  leftContainerRef: PropTypes.object.isRequired,
}

const c = 'search_results'

const Results = (props) => {
  const containerRef = useRef()

  const {
    results: {
      items,
      isFetching,
      pagination,
    },
    ratesPeriod,
    updateQuery,
    leftContainerRef
  } = props

  const filterPaused = (properties) => properties.filter(property => !property?.listing.paused)
  const unpausedProperties = filterPaused(items)

  useDidUpdate(() => {
    if (media('(max-width: 1139px)')) {
      leftContainerRef.current.scrollTop = 0
    } else {
      containerRef.current.scrollTop = 0
    }
  }, [isFetching])

  const renderEmptyResults = () => (
    <div className={`${c}_empty`}>
      <h2 className={`${c}_empty_title`}>
        No Results
      </h2>
      <p className={`${c}_empty_description`}>
        No properties meet your search criteria. Here are a few suggestions:
      </p>
      <ul className={`${c}_empty_list`}>
        <li>Change your filters</li>
        <li>Zoom out the map</li>
        <li>Search for a specific address</li>
      </ul>
      <Button onClick={() => {
        updateQuery({ filters: initialFilterState })
      }}>
        Clear All Filters
      </Button>
    </div>
  )

  return (
    <div className={c} ref={containerRef}>
      <Spot />
      <Host />
      <div className={classnames(`${c}_opacity`, {
        [`${c}_opacity--isFetching`]: isFetching,
      })}> 
        {!unpausedProperties.length && isFetching && <PageSpinner />}
        {!unpausedProperties.length && !isFetching && renderEmptyResults()}
        {!!unpausedProperties.length && (
          <div className={`${c}_items`}>
            {unpausedProperties.map(item => (
              <Result
                key={item.id}
                item={item}
                ratesPeriod={ratesPeriod}
                highlightMapMarkerOnHover
              />
            ))}
          </div>
        )}
        {!!unpausedProperties.length && (
          <Pagination
            onChange={page => updateQuery({ page })}
            {...pagination}
          />
        )}
      </div>
    </div>
  )
}

Results.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Results)
