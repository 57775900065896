import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { handleError } from '@/shared/api'
import { formatTime } from '../utils/dateTime'
import { timeOptions } from '@/shared/static/dateTime'
import { stringToDate, formatDate } from '@/shared/utils/dateTime'
import { Button, Select, DatePicker, FieldErrors, Icon } from '@/shared/components'
import '@/shared/styles/checkinTime.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  updateBooking: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
}

const c = 'checkinTime'

class CheckinTime extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arrivalTime: props.booking.arrivalTime || '',
      arrivalDate: props.booking.arrivalDate || props.booking.startDate,
      isFormOpen: !props.booking.arrivalTime,
      error: null,
      isSubmitting: false,
    }
  }

  getOptionIndex = (option) => timeOptions.findIndex(o => o.value === option)

  getTimeOptions = () => {
    if (!this.props.booking?.property?.checkInTimeStart) return timeOptions

    const startIndex = timeOptions.findIndex(object => {
      return object.value === formatTime(this.props.booking?.property?.checkInTimeStart)
    })
    const endIndex = timeOptions.findIndex(object => {
      return object.value === formatTime(this.props.booking?.property?.checkInTimeEnd)
    })
    return timeOptions.slice(startIndex, endIndex + 1)
  }

  handleSubmit = () => {
    const { arrivalDate, arrivalTime } = this.state
    if (!arrivalDate) return this.setState({ error: 'Please select check-in date' })
    if (!arrivalTime) return this.setState({ error: 'Please select check-in time' })
    this.setState({ isSubmitting: true })

    this.props.updateBooking(this.props.booking.id, { arrivalTime, arrivalDate }).then(() => {
      this.setState({ isFormOpen: false, error: null, isSubmitting: false })
    }, handleError)
  }

  renderShow = () => (
    <div className={c}>
      <div className={`${c}_container`}>
      <div className={`${c}_title`}>Check-in Time</div>
      <p>
        {`Check-in is time is set to `}
        <strong>{`${this.state.arrivalTime},`}</strong>
        <br/>
        <strong>{formatDate(this.state.arrivalDate)}</strong>
        {` — `}
        <span className={`${c}_editLink`} onClick={() => {
          this.setState({ isFormOpen: true })
        }}>
          Edit
        </span>
      </p>
      </div>
    </div>
  )

  renderCheckinTip = () => (
    <div className={`${c}_tip`}>
      Check-in for this property is available <span className={`${c}_tip__important`}>from {formatTime(this.props.booking?.property?.checkInTimeStart)}{this.props.booking?.property?.checkInTimeEnd && ` to ${formatTime(this.props.booking?.property?.checkInTimeEnd)}`}.</span>
    </div>
  )

  renderForm = () => (
    <div className={c}>
      <div className={`${c}_container`}>
        <div className={`${c}_title`}>Check-in Time</div>   
        <p>{this.props.description}</p>
        <div className={`${c}_form`}>
          <DatePicker
            inputProps={{ size: 'small' }}
            placeholder="Date"
            value={stringToDate(this.state.arrivalDate)}
            onChange={arrivalDate => this.setState({ arrivalDate })}
            dateFormat="monthDay"
            disablePastDays={false}
          />
          <Select
            size="small"
            placeholder="Time"
            options={this.getTimeOptions()}
            value={this.state.arrivalTime}
            onChange={(e, arrivalTime) => this.setState({ arrivalTime })}
          />
          <Button square size="small" working={this.state.isSubmitting} onClick={this.handleSubmit}>
            OK
          </Button>
        </div>
        <FieldErrors errors={this.state.error} />   
      </div>
      {!!this.props.booking?.property?.checkInTimeStart && this.renderCheckinTip()}
    </div>
  )

  render() {
    return this.state.isFormOpen ? this.renderForm() : this.renderShow()
  }
}

CheckinTime.propTypes = propTypes

export default CheckinTime
