import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { sortPhotos } from '@/shared/utils/photos'
import { Button, Photo } from '@/shared/components'
import '@/listSpace/styles/review.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
  property: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  toStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

const c = 'listSpace_review'

class Review extends Component {

  render() {
    const {
      property,
      submitForm,
      isSubmitting,
      steps,
      toStep,
      isEdit,
    } = this.props
    const coverPhoto = sortPhotos(property.photos)[0]
    return (
      <div className={`${c} listSpace_content`}>
        <h1 className="listSpace_title">
          {isEdit ? "You're ready to save" : "You're ready to list!"}
        </h1>
        <div className={`${c}_steps`}>
          {steps.filter(s => s.path !== '/review').map(step => (
            <Fragment key={step.path}>
              <div className="listSpace_label">{step.labelLong}</div>
              <Link to={toStep(step.path)}>Change</Link>
            </Fragment>
          ))}
        </div>
        <div className={`${c}_preview`}>
          {coverPhoto && <Photo photo={coverPhoto} imgTag />}
          <div className={`${c}_preview_title`}>
            {property.title}
          </div>
        </div>
        {isEdit ? (
          <Button working={isSubmitting} onClick={submitForm}>
            Save Changes
          </Button>
        ) : (
          <Fragment>
            <Button working={isSubmitting} onClick={() => {
              submitForm({ shouldActivate: true })
            }}>
              Submit and activate
            </Button>
            <div className={`${c}_justSubmitLink`} onClick={submitForm}>
              or submit without activating
            </div>
            <p className={`${c}_message`}>
              {"Your property will not be visible to guests before you activate it. If you’d like to update your availability, photos, or anything else, you can easily do all that after activating the property."}
            </p>
          </Fragment>
        )}
      </div>
    )
  }
}

Review.propTypes = propTypes

export default connect(mapStateToProps)(Review)
