import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { updateQuery } from '@/search/state/actions'
import { initialFilterSectionsState, getMobileMoreFiltersActiveCount } from '@/search/state/reducers'
import { Button, Media, Icon } from '@/shared/components'
import Dates from './Dates'
import Price from './Price'
import Placement from './Placement'
import Accommodation from './Accommodation'
import More from './more'
import Sort from './Sort'
import '@/search/styles/filters.scss'

const mapStateToProps = ({ search }) => ({
  filters: search.query.filters,
})

const mapDispatchToProps = { updateQuery }

const propTypes = {
  filters: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
}

class Filters extends Component {

  constructor(props) {
    super(props)
    this.state = { filters: props.filters }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.setState({ filters: this.props.filters })
    }
  }

  onFilterChange = updatedFilters => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...updatedFilters,
      }
    })
  }

  applyFilterChanges = () => {
    this.props.updateQuery({ filters: this.state.filters })
  }

  cancelFilterChanges = () => {
    this.setState({ filters: this.props.filters })
  }

  clearFilterSection = section => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...initialFilterSectionsState[section],
      }
    })
  }

  render() {
    const props = {
      filters: this.state.filters,
      onFilterChange: this.onFilterChange,
      applyFilterChanges: this.applyFilterChanges,
      cancelFilterChanges: this.cancelFilterChanges,
      clearFilterSection: this.clearFilterSection,
    }
    return (
      <Media query="(max-width: 767px)"
        unmatch={() => (
          <div className={searchFiltersClassName}>
            <Dates {...props} />
            <Price {...props} />
            <Placement {...props} />
            <Accommodation {...props} />
            <More {...props} />
            <Sort />
          </div>
        )}
        match={() => (
          <Fragment>
            <div className={`${searchFiltersClassName} ${searchFiltersClassName}_mobile`}>
              <Dates {...props} />
              <div
                className={classnames('search_filters_mobile_moreLink', {
                  'search_filters_mobile_moreLink--isOpen': this.state.isMobileDrOpen,
                })}
                onClick={() => this.setState({ isMobileDrOpen: !this.state.isMobileDrOpen })}
              >
                {!getMobileMoreFiltersActiveCount(props.filters) ? (
                  'More Filters'
                ) : (
                  `More Filters · ${getMobileMoreFiltersActiveCount(props.filters)}`
                )}
              </div>
            </div>
            {this.state.isMobileDrOpen && (
              <div className="search_filters_mobile_dropdown">
                <Price {...props} />
                <Placement {...props} />
                <Accommodation {...props} />
                <More {...props} />
                <Sort />
                <div className="search_filters_mobile_dropdown_applyFilters">
                  <Button hollow onClick={() => this.setState({ isMobileDrOpen: false })}>
                    Apply Filters
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        )}
      />
    )
  }
}

Filters.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Filters)

export const searchFiltersClassName = 'search_filters'
