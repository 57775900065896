import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import { addToQueryString } from '@/shared/utils/url'
import { Head, Button, Icon, Slider } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import SearchForm from './SearchForm'
import Guides from './Guides'
import Heading from './Heading'
import BoxGrid from './BoxGrid'
import HowItWorks from './HowItWorks'
import imageTrent from '@/landings/assets/images/general/trent.jpg'
import imageAmy from '@/landings/assets/images/general/amy.jpg'
import imageMelina from '@/landings/assets/images/general/melina.jpg'
import imageJalen from '@/landings/assets/images/general/jalen.jpg'
import imageHero from '@/landings/assets/images/general/hero.jpg'
import imageHowExplore from '@/landings/assets/images/general/how-explore.png'
import imageHowSend from '@/landings/assets/images/general/how-send.png'
import imageHowStay from '@/landings/assets/images/general/how-stay.png'
import imageNetworking from '@/landings/assets/images/general/networking.jpg'
import imageTransparency from '@/landings/assets/images/general/transparency.jpg'
import '@/landings/styles/general.scss'

const General = () => {
  let location = useLocation()

  useEffect(() => {
    analytics.pageview('Landings Main')
  }, [])

  return (
    <div className="landingsGeneral">
      <Head url="" />
      <AppHeader />
      <Hero location={location} />
      <Guides />
      <WhyStay />
      <HowTo />
      <Transparency />
      <TransparencyFeatures />
      <Networking />
      <SafetyAndSecurity />
      <Testimonials />
      <BottomCta />
      <AppFooter />
    </div>
  )
}

const Hero = ({ c = 'landingsGeneral_hero', location }) => (
  <div className={c} style={{ backgroundImage: `url(${imageHero})` }}>
    <div className={`${c}_inner`}>
      <div className={`${c}_top`}>
        <Heading />
        <h1 className={`${c}_subtitle`}>
          {"Affordable, furnished housing for D.C.'s interns, students and recent grads"}
        </h1>
      </div>
      <SearchForm isTop />
      <Link to={{
        pathname: '/search',
        search: addToQueryString(location.search)
      }}>
        <Button size="large" className={`${c}_searchMobile`}>
          Find Rooms
        </Button>
      </Link>
    </div>
  </div>
)

const WhyStay = () => (
  <BoxGrid
    title="Why stay with Roomsie?"
    items={[{
      icon: 'institution',
      title: <span>We know the ins and outs <br/> of Washington D.C.</span>,
      description: "Having a decade long experience helping people find medium-term housing in DC, we understand how difficult finding something comfortable and safe can be. In addition, all our staff and founders live here and we know how to stay healthy and productive in this great city.",
    }, {
      icon: 'piggybank',
      title: <span>You don’t have to <br/> break the bank</span>,
      description: "Washington D.C. is the seventh most expensive city in the world for renters, with heaps of interns flocking in every year. There’s nothing worse than getting your dream internship but not being able to cover the expenses. That’s why we strive to offer options for almost every budget.",
    }, {
      icon: 'location-empty',
      title: <span>Stay close to your <br/> internship or workplace</span>,
      description: "With tons of different locations to choose from, you are more than likely to find one that is close to your internship or workplace. This will save you hundreds of hours you would otherwise spend riding the metro or waiting for a bus.",
    }, {
      icon: 'security',
      title: <span>Relax knowing you’re <br/> safe and secure</span>,
      description: "We understand how important it is to feel safe when staying in a new city. That’s why we verify information of all our hosts and guests. For hosts, we also conduct background checks. Many of our properties have electronic controlled access, 24/7 concierge, and are located within gated communities.",
    }]}
  />
)

const HowTo = () => (
  <HowItWorks
    items={[{
      image: imageHowExplore,
      title: <span>Explore homes, <br/> see future roommates</span>,
      description: "Browse our detailed listings to find a home that fits you. After selecting dates of stay you’ll be able to take a look at restricted profiles of your future roommates to get an idea about who you’re going to be sharing the place with.",
    }, {
      image: imageHowSend,
      title: <span>Send a booking request</span>,
      description: "We give hosts 48 hours to accept your booking request. If you have any questions or just want to get to know the host before booking, feel free to message them.",
    }, {
      image: imageHowStay,
      title: <span>Stay for as long as you’d like</span>,
      description: "Whether you’re visiting for a month or staying for a year, we’ve got you covered. In addition, you can easily extend your stay if necessary. If you run into any last-minute issues, give us a call and we’ll help you get situated.",
    }]}
  />
)

const Transparency = ({ c = 'landingsGeneral_transparency' }) => (
  <div className={c}>
    <img src={imageTransparency} />
    <div className={`${c}_right`}>
      <h2 className={`${c}_title`}>
        Transparency {"&"} <br/> Communication
      </h2>
      <div className={`${c}_subtitle`}>
        {"We make it simple for you to get to know more about any home, guest, or host before deciding to book."}
      </div>
    </div>
  </div>
)

const TransparencyFeatures = ({ c = 'landingsGeneral_transparencyFeatures' }) => (
  <div className={c}>
    <div className={`${c}_items`}>
      {[{
        icon: 'chat-double',
        title: "Chat with future roommates",
        description: "We give you the tools you need to connect with other guests whose stays overlap with your own. Reach out to your new roommates and get to know them before checking into your new home.",
      }, {
        icon: 'thumbsup',
        title: "Get your questions answered",
        description: "Our secure messaging system is an easy way to ask a potential host any questions you have about their place before you book. After booking, you can also message your host to coordinate check-in or ask for assistance.",
      }, {
        icon: 'persons',
        title: "Roomsie profiles",
        description: "Everyone on Roomsie has a profile so that guests and hosts get to know each other. Read about your host and roommates, see where they’re from, which school they’re attending, what their interests are, and more.",
      }].map(item => (
        <div key={item.title} className={`${c}_item`}>
          <Icon type={item.icon} />
          <div className={`${c}_item_title`}>{item.title}</div>
          <p className={`${c}_item_description`}>{item.description}</p>
        </div>
      ))}
    </div>
  </div>
)

const Networking = ({ c = 'landingsGeneral_networking' }) => (
  <div className={c} style={{ backgroundImage: `url(${imageNetworking})` }}>
    <h2 className={`${c}_title`}>
      {"Exclusive Networking & Social Events"}
    </h2>
    <div className={`${c}_subtitle`}>
      {"We believe in the power of face-to-face networking to get"}
      <br/>
      {" the most from your D.C. experience."}
    </div>
    <div className={`${c}_bottom`}>
      <div
        className={`${c}_bottom_blurredBackground`}
        style={{ backgroundImage: `url(${imageNetworking})` }}
      />
      <div className={`${c}_bottom_inner`}>
        <div className={`${c}_item`}>
          <div className={`${c}_item_title`}>
            {"Kick-start your career"}
          </div>
          <p className={`${c}_item_description`}>
            {"Both Roomsie sponsored and guest organized events can help you make connections critical to your career. Meet folks who are on a similar career path and maximize value of your time spent in Washington, D.C."}
          </p>
        </div>
        <div className={`${c}_item`}>
          <div className={`${c}_item_title`}>
            {"Tailored to your interests"}
          </div>
          <p className={`${c}_item_description`}>
            {"We match your personal interests with event categories to automatically notify you of events you might be interested in. Categories range from politics, entrepreneurship, and networking, to art, books, travel, and much more."}
          </p>
        </div>
      </div>
    </div>
  </div>
)

const SafetyAndSecurity = () => (
  <BoxGrid
    title="Safety & Security"
    items={[{
      icon: 'personcheck',
      title: <span>Host and Guest <br/> Verification</span>,
      description: "Everyone on Roomsie is asked to provide their full name, date of birth, phone number, payment information, and email address all of which we verify. In addition, we background check hosts and run them against various watchlists.",
    }, {
      icon: 'clock',
      title: <span>48 Hour Rent Safe <br/> Protection</span>,
      description: "We wait two days after guest check-in before transferring first month’s rent to the host. If you arrive at your home and something is not as advertised, we will rectify the situation and get you situated somewhere else if necessary.",
    }, {
      icon: 'lock-empty',
      title: <span>Secure Payments</span>,
      description: "Our secure platform ensures your money gets to your host — that’s why we ask you to always pay through Roomsie and never wire money or pay someone directly.",
    }, {
      icon: 'tools',
      title: <span>Continuous Support <br/> During Your Stay</span>,
      description: "During the whole length of your stay we will be standing by to help with things like rebooking assistance, refunds, reimbursements, disputes or any other issues.",
    }]}
  />
)

const Testimonials = ({ c = 'landingsGeneral_testimonials' }) => (
  <div className={c}>
    <h2 className={`${c}_title`}>{"Our customers tell it like it is"}</h2>
    <div className={`${c}_content`}>
      <Slider largeDots>
        {[{
          image: imageTrent,
          name: "Trent B",
          title: "Recruiting Analyst",
          quote: "At the risk of descending into cliché, I can attest the highlight of the experience was the ability to make great and potentially life-long friends through my living situation.",
        }, {
          image: imageMelina,
          name: "Melina D",
          title: "Senior Staff Editor at The NYT",
          quote: "This is by far the best temporary housing option in DC. It's been an incredible experience, and it'll be hard to find housing that compares to this in a similar price range.",
        }, {
          image: imageJalen,
          name: "Jalen J",
          title: "Business Consultant at AMEX",
          quote: "The price may initially seem expensive, but overall worth the cost when factoring in furnishings, appliances, all bills paid, location, & amenities. You end up saving money!",
        }, {
          image: imageAmy,
          name: "Amy L",
          title: "Cross Country Runner",
          quote: "I had a wonderful experience living in Senate Square. The location was excellent in terms of its convenience to public transportation and the apartment was beautiful and well kept.",
        }].map(item => (
          <div key={item.name} className={`${c}_item`}>
            <p className={`${c}_body`}>{item.quote}</p>
            <div className={`${c}_customer_container`}>
              <img src={item.image} alt="Roomsie customer" />
              <div className={`${c}_customer`}>
                {item.name} <span>|</span> {item.title}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
)

const BottomCta = ({ c = 'landingsGeneral_bottomCta' }) => (
  <div className={c}>
    <h2 className={`${c}_title`}>
      {"It's easy to get started"}
    </h2>
    <SearchForm />
  </div>
)

export default General
