import api from '@/shared/api'

export const fetchConversations = query => (
  api.get('/conversations')
)

export const fetchBookings = () => (
  api.get('/guest/bookings')
)

export const fetchEvents = (query = {}) => (
  api.get('/events', query)
)
