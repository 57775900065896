import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { getBodyScrollTop } from '@/shared/utils/dom'
import { Icon } from '@/shared/components'
import '@/shared/styles/modal.scss'

const propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  closeOnEscapeKey: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  withoutCloseIcon: PropTypes.bool,
  containsForm: PropTypes.bool,
  isOpen: PropTypes.bool,
  renderLink: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  beforeClose: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
}

const defaultProps = {
  closeOnEscapeKey: true,
  closeOnOutsideClick: true,
  beforeClose: () => true,
  onClose: () => {},
  onOpen: () => {},
}

const $root = document.getElementById('root')

class Modal extends Component {

  state = { isOpen: false }

  componentDidMount() {
    if (this.props.isOpen) {
      this.openModal()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.isBoolean(this.props.isOpen) &&
      this.props.isOpen !== prevProps.isOpen &&
      this.props.isOpen !== this.state.isOpen
    ) {
      this.props.isOpen ? this.openModal() : this.closeModal()
    }
  }

  componentWillUnmount() {
    this.closeModalEventListeners('remove')
    this.removeContainer()
    document.body.classList.remove(...this.bodyClasses())
  }

  openModal = () => {
    this.offset = getBodyScrollTop()
    this.insertContainer()
    this.setState({ isOpen: true }, () => {
      this.closeModalEventListeners('add')
      document.body.classList.add(...this.bodyClasses())
      this.props.onOpen()
    })
  }

  closeModal = (after = () => {}) => {
    if (!this.props.beforeClose()) return
    this.closeModalEventListeners('remove')
    this.setState({ isOpen: false }, () => {
      this.removeContainer()
      document.body.classList.remove(...this.bodyClasses())
      this.props.onClose()
      if (this.offset) {
        window.scrollTo(0, this.offset)
      }
      after()
    })
  }

  bodyClasses = () => {
    const classes = ['body--modal--isOpen']
    if (this.props.containsForm) classes.push('body--modal--containsForm')
    return classes
  }

  insertContainer = () => {
    this.$container = document.createElement('span')
    $root.appendChild(this.$container)
  }

  removeContainer = () => {
    if ($root.contains(this.$container)) {
      $root.removeChild(this.$container)
    }
  }

  closeModalEventListeners(addOrRemove) {
    if (this.props.closeOnEscapeKey) {
      document[`${addOrRemove}EventListener`]('keydown', this.onEscapeKeyDown)
    }
    if (this.props.closeOnOutsideClick && this.$overlay) {
      this.$overlay[`${addOrRemove}EventListener`]('mousedown', e => this.mouseDownTarget = e.target)
      this.$overlay[`${addOrRemove}EventListener`]('mouseup', this.onOverlayMouseClick)
    }
  }

  onEscapeKeyDown = e => {
    if (this.state.isOpen && e.keyCode === 27) {
      this.closeModal()
    }
  }

  onOverlayMouseClick = e => {
    if (
      this.state.isOpen &&
      e.button === 0 &&
      this.$modal !== this.mouseDownTarget &&
      !this.$modal.contains(this.mouseDownTarget) &&
      this.$modal !== e.target &&
      !this.$modal.contains(e.target)
    ) {
      this.closeModal()
    }
  }

  onLinkClick = () => {
    if (!this.state.isOpen) {
      this.openModal()
    }
  }

  renderModal = () => (
    <div
      className={classnames('modal_overlay', this.props.overlayClassName, {
        'modal_overlay--containsForm': this.props.containsForm,
      })}
      ref={el => this.$overlay = el}
    >
      <div
        className={classnames('modal', this.props.className)}
        ref={el => this.$modal = el}
      >
        {!this.props.withoutCloseIcon && (
          <Icon
            type="close"
            className="modal_closeIcon"
            onClick={() => this.closeModal()}
          />
        )}
        {this.props.renderContent({
          closeModal: this.closeModal,
        })}
      </div>
    </div>
  )

  createPortal = () => (
    ReactDOM.createPortal(this.renderModal(), this.$container)
  )

  render() {
    const { renderLink, linkClassName } = this.props
    return (
      <Fragment>
        {renderLink && (
          <span className={classnames(linkClassName)} onClick={this.onLinkClick}>
            {renderLink()}
          </span>
        )}
        {this.state.isOpen && this.createPortal()}
      </Fragment>
    )
  }
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
