import { combineReducers } from 'redux'
import { query, results, location, host, options } from './reducers'

export default combineReducers({
  query,
  results,
  location,
  host,
  options,
})
