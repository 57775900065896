import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import googleMapsApiLoader from '@/shared/hocs/googleMapsApiLoader'
import { getDefaultMapOptions } from '@/shared/utils/map'

const propTypes = {
  event: PropTypes.object.isRequired,
  google: PropTypes.object.isRequired,
}

class Location extends Component {

  componentDidMount() {
    const { google, event } = this.props

    this.map = new google.maps.Map(this.$mapContainer, {
      ...getDefaultMapOptions(google),
      center: event.coordinates,
      zoom: 15,
    })
    this.marker = new google.maps.Marker({
      position: event.coordinates,
      map: this.map,
    })
  }

  render() {
    const { event } = this.props
    return (
      <Fragment>
        <div className="event_sectionTitle">Event Location</div>
        <div className="event_location">{event.location}</div>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${
            event.coordinates.lat},${event.coordinates.lng
          }`}
          target="_blank"
        >
          <div className="event_location_viewDirections">
            View Directions
          </div>
        </a>
        <div
          className="event_location_map"
          ref={el => this.$mapContainer = el}
        />
      </Fragment>
    )
  }
}

Location.propTypes = propTypes

export default googleMapsApiLoader(Location)
