import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import usStates from '@/shared/static/usStates'
import { countryNames } from '@/shared/static/countries'
import * as policies from '@/shared/static/policies'
import { addToQueryString } from '@/shared/utils/url'
import { createUser } from '@/book/api'
import { handleError } from '@/shared/api'
import { Form, Field, Button, Input, Select, Checkbox, BirthDateSelects,
  Textarea, Icon, FieldErrors, Modal } from '@/shared/components'
import ProgressBar from './ProgressBar'
import '@/book/styles/guest.scss'

const c = 'book_guest'

const Other = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const [ 
    property,
    currentUser,
    updateCurrentUser,
    trackEvent,
    warnAndRedirect,
    query,
    isImportingBooking,
    rates
  ] = useOutletContext()

  return (
    <Form
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        emailRepeat: '',
        birthDate: '',
        gender: '',
        city: '',
        state: '',
        country: '',
        school: '',
        schoolYear: '',
        major: '',
        internshipEmployer: '',
        bio: '',
        acceptedRules: false,
      }}
      validations={{
        firstName: Form.is.required(),
        lastName: Form.is.required(),
        email: Form.is.required(),
        emailRepeat: Form.is.required(),
        birthDate: Form.is.required(),
        gender: [
          Form.is.required(),
          Form.is.match(v => (
            _.get(property, 'placementType') === 'unisex' ||
            _.get(property, 'placementType') === v
          ), `This is a ${_.get(property, 'placementType')} only listing.`)
        ],
        city: Form.is.required(),
        country: Form.is.required(),
        school: Form.is.required(),
        schoolYear: Form.is.required(),
        major: Form.is.required(),
        internshipEmployer: Form.is.required(),
        bio: Form.is.required(),
        acceptedRules: Form.is.match(v => !!v, 'Please agree to continue'),
      }}
      onSubmit={(values, form) => {
        if (values.email.trim() !== values.emailRepeat.trim()) {
          form.setSubmitting(false)
          return form.setErrors({
            emailRepeat: 'Both fields have to contain the same email',
          })
        }
        createUser(values).then(({ data }) => {
          trackEvent('Booking Wizard Guest Info Submitted')
          navigate({
            pathname: `${location.pathname.replace('/other', '')}/payment`,
            search: addToQueryString(location.search, { userId: data.id }),
          })
        }, ({ error }) => {
          if (error.details) {
            form.setErrors(error.details)
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit} className={c}>
          <h1 className={`${c}_title`}>Secure Booking</h1>
          <ProgressBar current={2} total={3} />
          <h2 className={`${c}_title_small`}>Please enter your student's information below</h2>
          <p className={`${c}_subtitle`}>
            {"Parents, advisors & coordinators, please enter your student's information here."}
          </p>
          <div className={`${c}_fields`}>
            <Field.Input {...fields.firstName} label="First Name" />
            <Field.Input {...fields.lastName} label="Last Name" />
            <Field.Input {...fields.email} label="Email" />
            <Field.Input {...fields.emailRepeat} label="Repeat Email" />
            <Field.Select
              {...fields.gender}
              label="Gender"
              placeholder="Select"
              options={[{ value: 'female', label: 'Female' }, { value: 'male', label: 'Male' }]}
            />
            <BirthDateSelects
              label="Date of Birth"
              value={fields.birthDate.value}
              onChange={value => setFieldValue('birthDate', value)}
              errors={fields.birthDate.errors}
            />
            <p className={`${c}_subtitle`}>
              The following information helps the host and fellow roomies learn about the student.
            </p>
            <Field.Input {...fields.city} label="City" />
            <Field.Select
              {...fields.state}
              label="State"
              options={[{ value: '', label: 'Select' }, ...usStates]}
            />
            <Field.Select
              {...fields.country}
              label="Country"
              placeholder="Select"
              options={countryNames}
            />
            <Field.Input {...fields.school} label="School/University Name" />
            <Field.Select
              {...fields.schoolYear}
              label="School Year"
              placeholder="Select"
              options={[
                'Freshman',
                'Sophomore',
                'Junior',
                'Senior',
                'Graduate Student',
                'Graduated',
              ].map(value => ({ value, label: value }))}
            />
            <Field.Input {...fields.major} label="Major Field of Study" />
            <Field.Input {...fields.internshipEmployer} label="Internship/Employer" />
          </div>
          <div className={`${c}_about`}>
            <div className={`${c}_about_textareaContainer`}>
              <Field.Textarea {...fields.bio} label="About (Bio)" minRows={7} />
            </div>
            <div className={`${c}_about_tips`}>
              <div className={`${c}_about_tip`}>
                <Icon type="check" />
                <span>Tell the host and roommates a little about the student.</span>
              </div>
              <div className={`${c}_about_tip`}>
                <Icon type="check" />
                <span>What brings the student to this area?</span>
              </div>
              <div className={`${c}_about_tip`}>
                <Icon type="check" />
                <span>Which university is the student going to study in?</span>
              </div>
            </div>
          </div>
          <div className={`${c}_acceptRules`}>
            <Checkbox
              label=""
              checked={fields.acceptedRules.value}
              {...fields.acceptedRules}
            />
            <div className={`${c}_acceptRules_label`}>
              {`I agree to the `}
              <Modal
                className="book_infoModal"
                renderLink={() => (
                  <span>House Rules</span>
                )}
                renderContent={() => (
                  <Fragment>
                    <h2 className="book_infoModal_title">House Rules</h2>
                    <p className="book_infoModal_content">
                      {_.get(property, 'rules') || policies.rulesDefault}
                    </p>
                  </Fragment>
                )}
              />
              {`, `}
              <Link to="/cancellation-policy" target="_blank">
                Cancellation Policy
              </Link>
              {`, and to the `}
              <Link to="/guest-refund-policy" target="_blank">
                Guest Refund Policy
              </Link>
              {`. I also agree to pay the total amount shown, which includes Service Fees.`}
            </div>
            <FieldErrors errors={fields.acceptedRules.errors} />
          </div>
          <Button className={`${c}_submit`} type="submit" working={isSubmitting}>
            Continue
          </Button>
        </form>
      )}
    />
  )
}

export default Other
