import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { formatDate } from '@/shared/utils/dateTime'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { Avatar, Button, Icon, VerifiedMark } from '@/shared/components'
import MessageModal from '@/features/messageModal/components'
import RequestTour from './RequestTour'
import '@/property/styles/host.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
}

const c = 'property_host'
export const hostClassname = c

const Host = ({ property: { user, ...property } }) => {
  
  const { toOpen } = useProfileModalUrl()

  return (
    <div className={c}>
      <h2 className="property_section_title">{`Hosted by ${user.firstName}`}</h2>
      <div className={`${c}_inner`}>
        <Avatar {...user} size={140} />
        {(user.city || user.state || user.country) && (
          <div className={`${c}_address`}>
            {'From: '}
            <span>{[user.city, user.state].filter(p => p).join(', ')}</span>
          </div>
        )}
        <div className={`${c}_memberSince`}>
          {'Member since: '}
          <span>{formatDate(user.createdAt, 'monthYear')}</span>
        </div>
        {user.bio && (
          <p className={`${c}_bio`}>
            {'About: '}
            <span>{_.truncate(user.bio, { length: 140 })}</span>
          </p>
        )}
        <div
          className={`${c}_profileLink`}
          onClick={() => toOpen(user.id)}
        >
          {`View ${user.firstName}'s profile`}
        </div>
        <MessageModal
          description="Questions about your housing that were not addressed in the listing? Ask your hosts! They are here to help."
          recipient={user}
          contextId={{ propertyId: property.id }}
        />
        <RequestTour propertyId={property.id} />
      </div>
      {user.verifiedHost && (
        <div className={`${c}_verified`}>
          <VerifiedMark />
          <p>{`${user.firstName} has earned the status of verified host. Learn more about how Roomsie protects guests:`}</p>
          <a
            className={`${c}_readMoreLink`}
            href="https://roomsie.zendesk.com/hc/en-us/articles/360008126114-What-does-it-mean-to-be-a-verified-host-"
            target="_blank"
          >
            How we verify hosts
          </a>
        </div>
      )}
    </div>
  )
}

Host.propTypes = propTypes

export default Host
