import moment from 'moment'
import _ from 'lodash'

export const dateFormats = {
  monthDay: 'MMM D', // Dec 18
  monthDayYear: 'MMM D, YYYY', // Dec 18, 2017
  monthDayYearTime: 'MMM D, YYYY, h:mm a', // Dec 18, 2017, 4:30 pm
  monthYear: 'MMM YYYY', // Dec 2017
  longMonthYear: 'MMMM YYYY', // December 2017
  dayDay: 'ddd D', // Mon 18
  dayMonthDay: 'ddd, MMM D', // Mon, Dec 18
  dayMonthDayYear: 'ddd, MMM D, YYYY', // Mon, Dec 18, 2017
  dayNumber: 'D', // 18
}

export const timeFormat = 'h:mm a'

export const formatTime = (time) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}


export const formatDate = (date, format = 'monthDayYear') => (
  moment(ensureJsDate(date)).format(dateFormats[format])
)

export const formatDateRange = (dateFrom, dateTo, options = {}) => {
  let result = ''
  if (dateFrom && dateTo) {
    const mFrom = moment(ensureJsDate(dateFrom))
    const mTo = moment(ensureJsDate(dateTo))

    if (mFrom.year() === mTo.year()) {
      if (mFrom.month() === mTo.month()) {
        // Dec 11 — 16, 2017
        result = `${mFrom.format('MMM D')} — ${mTo.format('D, YYYY')}`
      } else {
        // Oct 11 — Dec 16, 2017
        result = `${mFrom.format('MMM D')} — ${mTo.format('MMM D, YYYY')}`
      }
    } else {
      // Dec 11, 2016 — Dec 16, 2017
      result = `${mFrom.format('MMM D, YYYY')} — ${mTo.format('MMM D, YYYY')}`
    }
  } else if (dateFrom) {
    result = `${formatDate(dateFrom)} —`
  } else if (dateTo) {
    result = `— ${formatDate(dateTo)}`
  }
  if (options.omitYears) {
    return result.replace(/\, \d{4}/g, '')
  }
  return result
}

export const dateToString = date => (
  date ? moment(date).format('YYYY-MM-DD') : date
)

export const stringToDate = date => (
  date ? moment(date, 'YYYY-MM-DD').toDate() : date
)

export const stringsToDateRange = dates => ({
  from: stringToDate(dates.startDate),
  to: stringToDate(dates.endDate),
})

export const dateRangeToStrings = dates => ({
  startDate: dateToString(dates.from),
  endDate: dateToString(dates.to),
})

export const yearMonthDayToString = ({ year, month, day }) => (
  year && month && day ? dateToString(moment(`${year}-${month}-${day}`, 'YYYY-M-D')) : ''
)

export const stringToYearMonthDay = str => {
  const [year, month, day] = str.split('-')
  return year && month && day ?
    { year: +year, month: +month, day: +day } :
    { year: '', month: '', day: ''}
}

export const getTimeDifference = (dateTo, dateFrom, unit = 'days') => (
  dateFrom && dateTo ? moment(ensureJsDate(dateTo)).diff(ensureJsDate(dateFrom), unit) : null
)

export const isDateBetween = (date, dateFrom, dateTo) => (
  moment(date).isBetween(dateFrom, dateTo, 'days', '[]')
)

const ensureJsDate = date => (
  _.isString(date) ? (
    /^\d{4}-\d{2}-\d{2}$/.test(date) ? stringToDate(date) : moment(date).toDate()
  ) : (
    date
  )
)
