import _ from 'lodash'
import { is, getErrors } from '@/shared/utils/validation'

export const validateSteps = property => ({
  basics: validateBasics(property),
  location: validateLocation(property),
  amenitiesRules: validateAmenitiesRules(property),
  photos: validatePhotos(property),
  availability: validateAvailability(property),
  ratesRooms: validateRatesRooms(property),
  fees: validateFees(property),
})

const validateBasics = property => {
  const errors = getErrors(property, {
    title: [is.required(), is.max(60)],
    description: [is.required(), is.max(100000)],
    propertyType: is.required(),
  })
  return { errors, isValid: _.isEmpty(errors) }
}

const validateLocation = property => {
  const errors = getErrors(property, {
    streetAddress: is.required(),
    city: is.required(),
    state: is.required(),
    zip: is.required(),
    neighborhood: is.required(),
    coordinates: is.required(),
  })
  return { errors, isValid: _.isEmpty(errors) }
}

const validateAmenitiesRules = property => {
  // const timeRangeRegex = /^((1[0-2]|0?[1-9]):([0-5][0-9])\s([ap][m])-(1[0-2]|0?[1-9]):([0-5][0-9])\s([ap][m]))$/
  const errors = getErrors(property, {
    rules: is.max(100000),
    // checkInTimeEnd: is.match(
    //   v => v === '', 
    //   'Please enter a valid time range.'),
  })
  return { errors, isValid: _.isEmpty(errors) }
}

const validatePhotos = property => {
  const errors = getErrors(property, {
    photos: is.match(photos => photos.length >= 3),
  })
  return { errors, isValid: _.isEmpty(errors) }
}

const validateAvailability = property => {
  const errors = {}
  _.each(['sessions', 'blackoutDates'], type => {
    errors[type] = property[type].reduce((ret, i) => {
      ret[i.uid] = !(i.startDate && i.endDate) && 'Please select start and end dates'
      return ret
    }, {})
  })
  const hasErrors = type => (
    _.values(errors[type]).filter(e => e).length
  )
  return {
    errors,
    isValid: !hasErrors('sessions') && !hasErrors('blackoutDates'),
  }
}

const validateRatesRooms = property => {
  const rates = getRatesErrors(property)
  const bedrooms = getBedroomsErrors(property)
  const isEntireSpace = property.listingType === 'entire_space'
  const isRatesValid = !isEntireSpace || !hasRatesErrors(rates)
  const isBedroomsValid = isEntireSpace || !hasBedroomsErrors(bedrooms)
  return {
    errors: { rates, bedrooms },
    isValid: isRatesValid && isBedroomsValid,
  }
}

const getRatesErrors = property => (
  _.reduce(property.rates, (ret, rate) => {
    ret[rate.sessionUid] = validateAmount(rate)
    return ret
  }, {})
)

const getBedroomsErrors = property => {
  if (!property.bedrooms.length) {
    return { bedroomsCount: 'Please add at least 1 bedroom' }
  }
  return _.reduce(property.bedrooms, (ret, room) => {

    ret[room.uid] = getErrors(room, {
      beds: is.match(beds => beds.length, 'Please add at least one bed'),
      photos: is.match(photos => photos.length, 'Please add at least one photo'),
      title: [is.required(), is.max(60)],
      description: [is.required(), is.max(2000)],
    })
    _.each(room.beds, bed => {
      _.each(bed.rates, rate => {
        ret[room.uid][`${bed.uid}${rate.sessionUid}`] = validateAmount(rate)
      })
    })
    return ret
  }, {})
}

const hasRatesErrors = errors => (
  _.values(errors).filter(e => e).length
)

const hasBedroomsErrors = errors => (
  _.values(_.omit(errors, ['bedroomsCount'])).filter(bedroom => (
    _.values(bedroom).filter(e => e).length
  )).length || errors.bedroomsCount
)

const validateFees = property => {
  const errors = _.reduce(property.fees, (ret, fee) => {
    ret[fee.kind] = fee && validateAmount(fee)
    return ret
  }, {})
  return { errors, isValid: !_.values(errors).filter(e => e).length }
}

const validateAmount = ({ amount }) => (
  (!_.isFinite(+amount) || +amount === 0 || +amount > 99999) && 'Please enter a valid amount'
)
