import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { formatPrice } from '@/shared/utils/numbers'
import { updateOptions } from '@/search/state/actions'
import { Tooltip, Icon, Button, SliderRange, Select } from '@/shared/components'

const mapStateToProps = ({ search }) => ({
  ratesPeriod: search.options.ratesPeriod,
})

const mapDispatchToProps = { updateOptions }

const propTypes = {
  ratesPeriod: PropTypes.string.isRequired,
  updateOptions: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilterChanges: PropTypes.func.isRequired,
  cancelFilterChanges: PropTypes.func.isRequired,
  clearFilterSection: PropTypes.func.isRequired,
}

const defaultMin = 0
const defaultMax = 200

const toComponent = ({ minRate, maxRate }) => ({
  minRate: _.isNull(minRate) ? defaultMin : minRate,
  maxRate: _.isNull(maxRate) ? defaultMax : maxRate,
})

const fromComponent = (minRate, maxRate) => ({
  minRate: minRate === defaultMin ? null : minRate,
  maxRate: maxRate === defaultMax ? null : maxRate,
})

const Price = props => {
  const { minRate, maxRate } = toComponent(props.filters)

  const rateMultiplier = ({ month: 30, week: 7, night: 1 })[props.ratesPeriod]

  const formattedMaxRate = `
    ${formatPrice(maxRate * rateMultiplier)}${maxRate >= defaultMax ? '+' : ''}
  `
  const isInitialState = (
    minRate === defaultMin &&
    maxRate === defaultMax
  )
  return (
    <Tooltip
      className="search_filters_tooltip search_filters_price"
      placement="bottom"
      offset={{ top: -20, left: -200 }}
      onClose={props.cancelFilterChanges}
      renderLink={() => (
        <div className="search_filters_link">
          <Icon type="tag" />
          {isInitialState ? (
            'Price'
          ) : (
            `${formatPrice(minRate * rateMultiplier)} - ${formattedMaxRate}`
          )}
        </div>
      )}
      renderContent={({ closeTooltip }) => (
        <Fragment>
          <span>
            {`From ${formatPrice(minRate * rateMultiplier)}
              to ${formattedMaxRate} per ${props.ratesPeriod}`}
          </span>
          <div className="search_filters_price_priceSlider">
            <SliderRange
              range={{
                'min': [defaultMin],
                '70%': [Math.round(defaultMax / 2)],
                'max': [defaultMax],
              }}
              value={[minRate, maxRate]}
              onChange={([minRate, maxRate]) => {
                props.onFilterChange(
                  fromComponent(minRate, maxRate)
                )
              }}
            />
          </div>
          <div className="search_filters_price_ratesPeriod">
            {`Show rates per `}
            <Select
              line
              size="small"
              value={props.ratesPeriod}
              onChange={(e, ratesPeriod) => props.updateOptions({ ratesPeriod })}
              options={['month', 'week', 'night'].map(p => ({ value: p, label: p }))}
            />
          </div>
          <Button size="small" onClick={() => {
            props.applyFilterChanges()
            closeTooltip()
          }}>
            Apply
          </Button>
          <Button hollow size="small" color="secondary" onClick={() => {
            props.clearFilterSection('rates')
          }}>
            Clear
          </Button>
        </Fragment>
      )}
    />
  )
}

Price.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Price)
