import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@/shared/components'
import Options from './Options'
import Email from './Email'
import '@/features/shareProperty/styles/index.scss'

const propTypes = {
  renderLink: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
}

class ShareProperty extends Component {

  state = { activeSection: 'options' }

  render() {
    const { activeSection } = this.state
    return (
      <Modal
        className={`shareProperty_${activeSection}`}
        renderLink={this.props.renderLink}
        renderContent={({ closeModal }) => {
          const props = {
            property: this.props.property,
            closeModal,
            activeSection,
            changeSection: value => this.setState({ activeSection: value })
          }
          if (activeSection === 'options') {
            return <Options {...props} />
          } else {
            return <Email {...props} />
          }
        }}
      />
    )
  }
}

ShareProperty.propTypes = propTypes

export default ShareProperty
