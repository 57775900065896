import React, { Component, Fragment, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { to404 } from '@/shared/utils/404'
import { formatDate } from '@/shared/utils/dateTime'
import { formatPrice } from '@/shared/utils/numbers'
import { formatAddress } from '@/shared/utils/geo'
import { fetchBooking, updateBooking } from '@/hostBookings/api'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { DashboardMenu, Avatar, Icon, Button, PageSpinner, Switch,
  Photo, BookingFees, Head, Modal, Media, CheckinTime } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import MessageModal from '@/features/messageModal/components'
import StatusModals from './StatusModals'
import Review from './Review'
import Extend from './Extend'
import ExtendBooking from './ExtendBooking'
import CancelImported from './CancelImported'
import MoveGuest from './MoveGuest'
import '@/hostBookings/styles/booking.scss'

const c = 'hostBooking'

const Booking = () => {
  const { bookingId } = useParams()

  const [booking, setBooking] = useState(null)

  useEffect(() => {
    analytics.pageview('Host Booking', {
      bookingId: bookingId,
    })
    fetchHostBooking()
  }, [])

  const fetchHostBooking = (then = () => {}) => {
    fetchBooking(bookingId).then(({ data }) => {
      setBooking(data)
    }, () => {
      to404('We could not find this booking.')
    })
  }

  return (
    <div className={c}>
      {!!booking && <Head title={`${booking.guest.firstName} - Booking - Roomsie`} />}
      <AppHeader />
      <DashboardMenu role="host" />
      {booking ? (
        <div className={`${c}_page`}>
          <Top booking={booking} fetchHostBooking={fetchHostBooking} />
          {booking.charges.find(c => c.status === 'failed') && (
            <FailedGuestPaymentWarning />
          )}
          {booking.status === 'reported' && (
            <ReportedWarning />
          )}
          {booking.program && booking.program.title && (
            <Program booking={booking} />
          )}
          <div className={`${c}_page_inner`}>
            <div className={`${c}_page_left`}>
              <Listing booking={booking} fetchHostBooking={fetchHostBooking} />
              <Guest guest={booking.guest} booking={booking} />
            </div>
            <div className={`${c}_page_middle`}>
              {/accepted|started/.test(booking.status) && (
                <CheckinTime
                  booking={booking}
                  updateBooking={updateBooking}
                  description="Select the check-in time of your guest."
                />
              )}
              <Dates booking={booking} />
              <Rates booking={booking} />
              {booking.bookingExtensionRates && <ExtensionRates rates={booking.bookingExtensionRates} /> }
              <Deposit {...booking} />
            </div>
            <div className={`${c}_page_right`}>
              {booking.bookingExtension && <ExtendDates booking={booking} />}
              <Payouts {...booking} />
            </div>
          </div>
          <Media query="(max-width: 767px)" match={() => (
            <div className={`${c}_bottom_mobile_actions`}>
              <Actions booking={booking} />
            </div>
          )} /> 
        </div>
      ) : (
        <PageSpinner />
      )}
      <AppFooter />
    </div>
  )
}

const Top = ({ booking, fetchHostBooking }) => (
  <div className={`${c}_top`}>
    <div className={`${c}_top_left`}>
      <div className={`${c}_top_breadcrumbs`}>
        <Link to="/host/bookings">My Bookings</Link>
        {' / Booking Details'}
      </div>
      <h1 className={`${c}_top_title`}>
        {`Booking #${booking.id}`}
      </h1>
      <div className={`${c}_top_status ${c}_top_status--${booking.status}`}>
        {/pending|review/.test(booking.status) && (
          <Icon type="warning-circle" />
        )}
        {options.labels.bookingStatuses[booking.status]}
      </div>
      <div className={`${c}_top_createdAt`}>
        {`Submitted on ${formatDate(booking.createdAt)}`}
      </div>
    </div>
    <Actions booking={booking} fetchHostBooking={fetchHostBooking} />
  </div>
)

const Actions = ({ booking, fetchHostBooking }) => (
  <Fragment>
    <StatusModals
      booking={booking}
      fetchBooking={fetchHostBooking}
    />
    {/review/.test(booking.status) && (
      <Review booking={booking} fetchBooking={fetchHostBooking} />
    )}
    {/accepted|started/.test(booking.status) && /import/.test(booking.kind) && (
      <CancelImported booking={booking} fetchBooking={fetchHostBooking}/>
    )}
    {/extended/.test(booking.status) && (
      <ExtendBooking booking={booking} fetchBooking={fetchHostBooking} />
    )}
    {/accepted|started/.test(booking.status) && /program|import/.test(booking.kind) && (
      <Extend booking={booking} fetchBooking={fetchHostBooking} />
    )}
  </Fragment>
)


const Program = ({ booking }) => (
  <div className={`${c}_program`}>
    <strong>{'Partner: '}</strong>
    {booking.program.title}
  </div>
)

const FailedGuestPaymentWarning = () => (
  <div className={`${c}_failedGuestPaymentWarning`}>
    {"We are having difficulties collecting payment from the guest. Payment issues are usually resolved within 24 hours."}
  </div>
)

const ReportedWarning = () => (
  <div className={`${c}_reportedWarning`}>
    {"The guest reported an accommodation issue regarding this booking. Our investigation in to this matter has begun."}
  </div>
)

// const Listing = ({ listing, propertySnapshot, bed }) => (
const Listing = ({ booking, fetchHostBooking }) => (
  <div className={`${c}_listing`}>
    <Photo photo={booking.propertySnapshot.photo} />
    <div className={`${c}_listing_inner`}>
      <div className={`${c}_listing_title`}>
        {booking.propertySnapshot.title}
      </div>
      <div className={`${c}_listing_address`}>
        {formatAddress(booking.propertySnapshot)}
      </div>
      <div className={`${c}_listing_type`}>
        {`${options.labels.placementTypes[booking.propertySnapshot.placementType]}
          ${options.labels.listingTypes[booking.propertySnapshot.listingType].replace('Space', '')} Space`}
      </div>
      {!!booking.bed && (
        <Fragment>
          <div className={`${c}_listing_bedroom_title`}>
            {`${options.labels.bedSizes[booking.propertySnapshot.bedSize]}
              ${options.labels.bedTypes[booking.propertySnapshot.bedKind]} in
              ${booking.propertySnapshot.bedroomTitle}`}
          </div>
          <div className={`${c}_listing_bedroom_details`}>
            <div>
              <Icon type="bed" />
              {`${options.labels.bedsCount[booking.propertySnapshot.bedsCount]}`}
            </div>
            <div>
              <Icon type="bath" />
              {`${booking.propertySnapshot.bathroomAttached ? 'Attached' : 'Shared'} Bathroom`}
            </div>
          </div>
        </Fragment>
      )}
      <Link to={`/properties/${booking.propertySnapshot.id}`} target="_blank">
        <Button hollow size="small" className={`${c}_listing_view`}>View Listing</Button>
      </Link>
      {/accepted|started/.test(booking.status) && (
        <MoveGuest booking={booking} fetchBooking={fetchHostBooking} />
      )}
    </div>
  </div>
)

const Guest = ({ guest, booking }) => {

  const { toOpen } = useProfileModalUrl()

  return (
    <div className={`${c}_guest`}>
      <div className={`${c}_guest_title`}>
        Guest Information
      </div>
      <div className={`${c}_guest_basic`}>
        <Avatar {...guest} size={70} />
        <div className={`${c}_guest_basic_text`}>
          <div className={`${c}_guest_basic_name`}>
            {`${guest.firstName} ${guest.lastName}`}
          </div>
          <div className={`${c}_guest_basic_ageGender`}>
            {`${guest.age}, ${guest.gender}`}
          </div>
        </div>
      </div>
      {guest.phoneNumber && (
        <div className={`${c}_guest_phone`}>
          <Icon type="phone" />
          {`Phone: ${guest.phoneNumber}`}
        </div>
      )}
      <div className={`${c}_guest_meta`}>
        <div>
          <span>{'From: '}</span>
          {[guest.city, guest.state, guest.country].filter(p => p).join(', ')}
        </div>
        {guest.school && <div><span>{'School/University: '}</span>{guest.school}</div>}
        {guest.schoolYear && <div><span>{'School Year: '}</span>{guest.schoolYear}</div>}
        {guest.major && <div><span>{'Area of Study: '}</span>{guest.major}</div>}
        {guest.internshipEmployer && <div><span>{'Internship Employer: '}</span>{guest.internshipEmployer}</div>}
      </div>
      {guest.bio && (
        <div className={`${c}_guest_about`}>
          <div>About</div>
          <p>{_.truncate(guest.bio, { length: 130 })}</p>
        </div>
      )}
      <Button hollow size="small" className={`${c}_guest_details`}onClick={() => toOpen(guest.id)}>Details</Button>
      {booking.conversationId ? (
        <Link to={`/messages/${booking.conversationId}`}>
          <Button size="small">Messages</Button>
        </Link>
      ) : (
        <MessageModal
          description="Make sure to provide your guests with all the information they need. Don't be afraid to over-communicate."
          recipient={guest}
          contextId={{ bookingId: booking.id }}
          buttonProps={{ hollow: false }}
        />
      )}
    </div>
  )
}

//pending exetinsion

const ExtendDates = ({ booking }) => (
  <div className={`${c}_dates`}>
    <div className={`${c}_dates_title`}>Extended Dates of Stay</div>
    <div className={`${c}_dates_inner`}>
      <div className={`${c}_dates_label`}>Starts On</div>
      <div className={`${c}_dates_date`}>{formatDate(booking.endDate, 'dayMonthDayYear')}</div>
      <div className={`${c}_dates_stayLength`}>{`${booking.bookingExtensionStayLength} nights`}</div>
      <div className={`${c}_dates_label`}>Ends On</div>
    </div>
    <div className={`${c}_dates_date`}>{formatDate(booking.bookingExtension.endDate, 'dayMonthDayYear')}</div>
  </div>
)

//Orginal dates of stay
const Dates = ({ booking }) => (
  <div className={`${c}_dates`}>
    <div className={`${c}_dates_title`}>Dates of Stay</div>
    <div className={`${c}_dates_inner`}>
      <div className={`${c}_dates_label`}>Starts On</div>
      <div className={`${c}_dates_date`}>{formatDate(booking.startDate, 'dayMonthDayYear')}</div>
      <div className={`${c}_dates_stayLength`}>{`${booking.stayLength} nights`}</div>
      <div className={`${c}_dates_label`}>Ends On</div>
    </div>
    <div className={`${c}_dates_date`}>{formatDate(booking.endDate, 'dayMonthDayYear')}</div>
  </div>
)

const Rates = ({ booking }) => (
  <div className={`${c}_rates`}>
    <div className={`${c}_rates_title`}>Charges & Fees</div>
    <BookingFees rates={booking} />
  </div>
)

const ExtensionRates = ({ rates }) => (
  <div className={`${c}_rates`}>
    <div className={`${c}_rates_title`}>Extended Charges & Fees</div>
    <BookingFees rates={rates} />
  </div>
)

const Deposit = ({ depositAmount, depositDeduction }) => {
  const renderLabel = () => {
    if (!depositAmount) {
      return '<strong>No escrow deposit was set.</strong>'
    } else if (depositDeduction) {
      return `Deposit: You refunded <strong>${formatPrice(depositAmount - depositDeduction.amount)}</strong>
              out of <strong>${formatPrice(depositAmount)}</strong>
              on <strong>${formatDate(depositDeduction.date)}</strong>.`
    } else if (!depositDeduction) {
      return `Escrow deposit is set to <strong>${formatPrice(depositAmount)}</strong>.`
    }
  }
  return (
    <div className={`${c}_deposit`}>
      <div dangerouslySetInnerHTML={{ __html: renderLabel() }} />
      {depositDeduction && depositDeduction.comments && (
        <div className={`${c}_deposit_comments`}>
          {`You kept `}
          <strong>{formatPrice(depositDeduction.amount)}</strong>
          {` for the `}
          <Modal
            className={`${c}_deposit_comments_modal`}
            renderLink={() => <span>following reasons.</span>}
            renderContent={() => (
              <Fragment>
                <h3>{`Reasons for keeping ${formatPrice(depositDeduction.amount)}:`}</h3>
                <p>{depositDeduction.comments}</p>
              </Fragment>
            )}
          />
        </div>
      )}
    </div>
  )
}

const Payouts = ({ payouts, hostPayout, depositDeduction }) => {
  const sum = payouts => payouts.reduce((sum, payout) => sum + payout.total, 0)
  const totalPaid = sum(payouts.filter(payout => payout.status === 'paid'))
  const totalOwed = sum(payouts.filter(payout => payout.status !== 'paid'))
  return (
    <div className={`${c}_payouts`}>
      <div className={`${c}_payouts_title`}>
        Payout Schedule
      </div>
      {payouts.map((payout, i) => (
        <div key={i} className={`${c}_payouts_item`}>
          {`${payout.status === 'paid' ? 'Paid' : 'Will be paid'} `}
          <span>{formatPrice(payout.total, { precise: true })}</span>
          {` on ${formatDate(payout.date)}`}
          <div className={`${c}_payouts_item_description`}>
            {payout.description}
          </div>
        </div>
      ))}
      <div className={`${c}_payouts_totalOwed`}>
        Total Owed
        <span>{formatPrice(totalOwed, { precise: true })}</span>
      </div>
      <div className={`${c}_payouts_totalPaid`}>
        Total Paid
        <span>{formatPrice(totalPaid, { precise: true })}</span>
      </div>
    </div>
  )
}

export default Booking
