import React from 'react'
import { queryStringToObject } from '@/shared/utils/url'
import { Link, useLocation } from 'react-router-dom'
import { Button, Head } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/staticPages/styles/notFound.scss'

const NotFound = () => { 
  const location = useLocation()

  return (
    <div className="staticPages_notFound">
      <Head title="Not Found - Roomsie" status={404} />
      <AppHeader />
      <div className="staticPages_notFound_page">
        <div className="staticPages_notFound_title">404</div>
        <div className="staticPages_notFound_subtitle">
          {queryStringToObject(location.search).message ||
          'Sorry, the page you requested cannot be found.'}
        </div>
        <Link to="/">
          <Button hollow>Home Page</Button>
        </Link>
      </div>
      <AppFooter />
    </div>
  )
}

export default NotFound
