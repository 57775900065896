import _ from 'lodash'
import { scrollToElement, isElementInViewport } from '@/shared/utils/dom'
import { fieldErrorsClassName } from '@/shared/components/FieldErrors'

const defaultMessage = 'This field is required'

export const is = {
  match: (testFunc, message = defaultMessage) => value => (
    !testFunc(value) && message
  ),
  required: (message = defaultMessage) => value => (
    !value && message
  ),
  min: (min, message = `Must be at least ${min} characters`) => value => (
    !!value && value.length < min && message
  ),
  max: (max, message = `Must be at most ${max} characters`) => value => (
    !!value && value.length > max && message
  ),
  email: (message = 'Must be a valid email') => value => (
    !!value && !/.+@.+/.test(value) && message
  ),
}

export const getErrors = (values, validations) => {
  const errors = {}
  _.each(validations, (validators, fieldName) => {

    _.each(_.flatten([validators]), validator => {
      const errorMessage = validator(values[fieldName])
      if (errorMessage) {
        errors[fieldName] = errorMessage
        return false
      }
    })
  })
  return errors
}

export const scrollToFirstError = () => {
  const $firstError = document.querySelector(`.${fieldErrorsClassName}`)
  if ($firstError && !isElementInViewport($firstError, 100)) {
    scrollToElement($firstError, -120)
  }
}
