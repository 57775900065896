export const getTextFromHtml = html => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent || ''
}

export const getFirstParagraphFromHtml = html => (
  html.includes('</p>') ? html.slice(0, html.indexOf('</p>') + 4) : html
)

export const copyToClipboard = value => {
  const $ta = document.createElement('textarea')
  $ta.value = value
  document.body.appendChild($ta)
  $ta.select()
  document.execCommand('copy')
  document.body.removeChild($ta)
}
