import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Placement from './Placement';
import '@/plugin/property/styles/filters.scss';

const propTypes = {
  handlePlacementTypeChange: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
};

const c = 'plugin_filters';

class Filters extends Component {
  render() {
    const { query, handlePlacementTypeChange } = this.props;

    return (
      <div className={c}>
        <Placement
          handlePlacementTypeChange={handlePlacementTypeChange}
          query={query}
        />
      </div>
    );
  }
}

Filters.propTypes = propTypes;

export default Filters;
