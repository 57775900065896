import _ from 'lodash'
import * as url from '@/shared/utils/url'
import coordinates from '@/shared/static/coordinates'

export const initialFilterSectionsState = {
  dates: {
    startDate: null,
    endDate: null,
  },
  rates: {
    minRate: null,
    maxRate: null,
  },
  placement: {
    placementTypes: [],
  },
  accommodation: {
    privacyTypes: [],
    bedsCount: [],
  },
  more: {
    minBedroomsCount: null,
    maxBedroomsCount: null,
    minBathroomsCount: null,
    maxBathroomsCount: null,
    listingTypes: [],
    bedSizes: [],
    bedTypes: [],
    amenities: [],
    propertyTypes: [],
  },
}

export const initialFilterState = {
  ...initialFilterSectionsState.dates,
  ...initialFilterSectionsState.rates,
  ...initialFilterSectionsState.placement,
  ...initialFilterSectionsState.accommodation,
  ...initialFilterSectionsState.more,
}

const initialQueryState = {
  filters: initialFilterState,
  locationText: undefined,
  boundingBox: [...coordinates.washingtonDC.boundingBox],
  userId: null,
  sort: null,
  page: 1,
}

export const query = (state = initialQueryState, action) => {
  switch (action.type) {
    case 'search/query/UPDATED':
      return { ...state, ...action.query }
    case 'search/query/locationText/UPDATED':
      return { ...state, locationText: action.locationText }
    case 'search/RESET':
      return { ...initialQueryState }
    default:
      return state
  }
}

export const getMoreFiltersActiveCount = filterState => (
  Object.keys(initialFilterSectionsState.more).reduce((count, key) => {
    if (_.isArray(filterState[key])) {
      return count + filterState[key].length
    } else {
      return filterState[key] ? count + 1 : count
    }
  }, 0)
)

export const getMobileMoreFiltersActiveCount = filterState => (
  getMoreFiltersActiveCount(filterState) +
  (filterState.minRate || filterState.maxRate ? 1 : 0) +
  filterState.placementTypes.length +
  filterState.privacyTypes.length
)

export const queryStateToParams = queryState => {
  const flatQueryState = flattenQuery(queryState)
  const flatInitialQueryState = flattenQuery(initialQueryState)
  const params = {}

  _.each(flatQueryState, (val, key) => {
    if (!_.isEqual(val, flatInitialQueryState[key])) {
      params[key] = val
    }
  })
  return params
}

export const queryStringToState = queryString => {
  const params = url.queryStringToObject(queryString)

  return _.merge({}, initialQueryState, {
    filters: {
      startDate: params.startDate,
      endDate: params.endDate,

      minRate: toNumber(params.minRate),
      maxRate: toNumber(params.maxRate),

      placementTypes: params.placementTypes,

      privacyTypes: params.privacyTypes,
      bedsCount: params.bedsCount,

      minBedroomsCount: toNumber(params.minBedroomsCount),
      maxBedroomsCount: toNumber(params.maxBedroomsCount),
      minBathroomsCount: toNumber(params.minBathroomsCount),
      maxBathroomsCount: toNumber(params.maxBathroomsCount),
      listingTypes: params.listingTypes,
      bedSizes: params.bedSizes,
      bedTypes: params.bedTypes,
      amenities: params.amenities,
      propertyTypes: params.propertyTypes,
    },
    locationText: params.locationText,
    boundingBox: (params.boundingBox || []).map(toNumber),
    userId: params.userId,
    sort: params.sort,
    page: toNumber(params.page),
  })
}

const flattenQuery = query => ({
  ...query.filters,
  locationText: query.locationText,
  boundingBox: query.boundingBox,
  userId: query.userId,
  sort: query.sort,
  page: query.page,
})

const toNumber = val => (
  _.isString(val) ? _.toNumber(val) : undefined
)

export const queryKeys = _.keys(flattenQuery(initialQueryState))

const initialLocationState = {
  center: null,
  zoom: null,
}

export const location = (state = initialLocationState, action) => {
  switch (action.type) {
    case 'search/location/UPDATED':
      return { ...state, ...action.location }
    case 'search/RESET':
      return { ...initialLocationState }
    default:
      return state
  }
}

const initialResultsState = {
  items: [],
  pagination: {},
  isFetching: true,
}

export const results = (state = initialResultsState, action) => {
  switch (action.type) {
    case 'search/query/UPDATED':
      return { ...state, isFetching: true }
    case 'search/results/FETCHED':
      return { ...state, ...action.results, isFetching: false }
    case 'search/RESET':
      return { ...initialResultsState }
    default:
      return state
  }
}

export const host = (state = null, action) => {
  switch (action.type) {
    case 'search/host/SET':
      return action.host
    case 'search/RESET':
      return null
    default:
      return state
  }
}

const initialOptionsState = {
  ratesPeriod: 'month',
}

export const options = (state = initialOptionsState, action) => {
  switch (action.type) {
    case 'search/options/UPDATED':
      return { ...state, ...action.options }
    case 'search/RESET':
      return { ...initialOptionsState }
    default:
      return state
  }
}
