import api from '@/shared/api'

export const fetchProperty = propertyId => (
  api.get(`/host/properties/${propertyId}`)
)

export const createProperty = property => (
  api.post('/host/properties', property)
)

export const updateProperty = property => (
  api.patch(`/host/properties/${property.id}`, property)
)

export const pauseListing = (listingId, query) => (
  api.patch(`/host/listings/${listingId}/paused`, query)
)

export const getNeighborhoodByCenter = (google, map, coordinates, then) => {
  new google.maps.places.PlacesService(map).nearbySearch({
    location: coordinates,
    type: 'neighborhood',
    rankBy: google.maps.places.RankBy.DISTANCE,
  }, results => {
    const neighborhood = results.find(result => (
      result.types.length == 2 && result.types.includes('political')
    ))
    then(neighborhood ? neighborhood.name : '')
  })
}
