import moment from 'moment'
import { DateUtils } from 'react-day-picker'

export const availableToUnavailableDates = availableRanges => {
  availableRanges = availableRanges.filter(r => r.from && r.to)

  if (!availableRanges.length) {
    return [{ after: moment().subtract(1, 'd').toDate() }]
  }

  const firstAvailableFrom = availableRanges.sort((a, b) => (
    moment(a.from).valueOf() - moment(b.from).valueOf()
  ))[0].from

  const firstAvailableTo = availableRanges.sort((a, b) => (
    moment(a.to).valueOf() - moment(b.to).valueOf()
  ))[0].to

  const lastAvailableTo = availableRanges.sort((a, b) => (
    moment(b.to).valueOf() - moment(a.to).valueOf()
  ))[0].to

  const unavailableDays = []
  let loopDay = firstAvailableTo

  while (!DateUtils.isSameDay(loopDay, lastAvailableTo)) {

    const isAvailable = !!availableRanges.find(range => (
      DateUtils.isDayInRange(loopDay, range)
    ))
    if (!isAvailable) {
      unavailableDays.push(loopDay)
    }
    loopDay = moment(loopDay).add(1, 'd').toDate()
  }
  return [
    { before: firstAvailableFrom },
    ...unavailableDays,
    { after: lastAvailableTo },
  ]
}
