import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { uniqueDateId } from '@/shared/utils/id'
import { dateToString, stringToDate } from '@/shared/utils/dateTime'
import { syncRatesWithSessions } from '@/listSpace/state/sync'
import { formatDateRange } from '@/shared/utils/dateTime'
import { decimalPriceInputFilter } from '@/shared/utils/numbers'
import { Field, Icon, Select, Button } from '@/shared/components'

const propTypes = {
  sessions: PropTypes.array.isRequired,
  rates: PropTypes.array.isRequired,
  bed: PropTypes.object,
  errors: PropTypes.object.isRequired,
  handleRatesChange: PropTypes.func.isRequired,
}

const c = 'listSpace_roomsRates_sessionRates'

class SplitSessionRates extends Component {

  isEntireSpace = () => this.props.property?.listingType === 'entire_space'

  getSessions = () => (
    this.stringsToDates(this.props.sessions)
  )

  getSortedSessions = () => (
    this.props.sessions.sort((a, b) => a.uid - b.uid)
  )

  datesToStrings = items => items.map(item => ({
    ...item,
    startDate: dateToString(item.startDate),
    endDate: dateToString(item.endDate),
  }))

  stringsToDates = items => (items.map(item => ({
    ...item,
    startDate: stringToDate(item.startDate),
    endDate: stringToDate(item.endDate),
  })))

  getMinimumStayOptions = (sessionsArray, session, minStay) => {
    return (
    _.range(1, 53).map(i => ({
      value: i * 7,
      label: `${i} Week${i === 1 ? '' : 's'}`,
      disabled: (
        session.startDate &&
        session.endDate &&
        this.isMinStayOptionDisabled(sessionsArray, session, minStay, i)
      )
    })))
  }

  isMinStayOptionDisabled = (sessionsArray, session, minStay, i) => {
    const daysDuration = i * 7
    const isLongerThanAvailable = (moment(session.endDate).diff(session.startDate, 'days') < i * 7)
    const isShorterThanMinStay = minStay > i * 7
    const isAlreadySelected = (sessionsArray.some(e => parseInt(e.minimumStayDays) === parseInt(daysDuration)))
    
    return isLongerThanAvailable || isShorterThanMinStay || isAlreadySelected
  }

  maxSessionRatesReached = (sessionsArray) => {
    const minWeeks = sessionsArray[0].minimumStayDays / 7
    const maxWeeks = moment(sessionsArray[0].endDate).diff(sessionsArray[0].startDate, 'weeks')
    const maxNumberOfRates = maxWeeks - minWeeks + 1
    const numberOfRates = sessionsArray.length
    return numberOfRates >= maxNumberOfRates
  }

  getMinStay = (startDate, endDate, sessions) => {
    const usedOptions = sessions
      .map(o => {
        if (dateToString(o.startDate) === startDate){
          return o.minimumStayDays
        }
      })
      .filter(o => !isNaN(o))
    const max = moment(endDate).diff(startDate, 'weeks') * 7
    const min = Math.min(parseInt(usedOptions))
    const options = _.range(min, max + 7, 7)
    const availableOptions = options.filter(option => !usedOptions.includes(option))
    return availableOptions[0]
  }

  addSessionRate = (startDate, endDate) => {
    const sessions = this.getSessions()
    this.handleSessionsChange({
      sessions: [
        ...sessions, {
          uid: uniqueDateId(),
          startDate: startDate,
          endDate: endDate,
          minimumStayDays: this.getMinStay(startDate, endDate, sessions),
        },
      ]
    })
  }

  removeSessionRate = (sessionUid) => {
    this.handleSessionsChange({
      'sessions': this.getSessions().filter(({ uid }) => (
        uid !== sessionUid
      ))
    })
  }

  updateSessionMinStay = (item, value) => {
    const items = [...this.getSessions()]
    const index = _.findIndex(items, { uid: item.uid })
    items[index] = { ...item, minimumStayDays: parseInt(value) }
    this.handleSessionsChange({
      sessions: items,
    })
  }  

  handleSessionsChange = items => {
    this.props.updateProperty({
      sessions: this.datesToStrings(items.sessions),
      ...syncRatesWithSessions(items.sessions, this.props.property),
    })
  }

  handleChange = (sessionUid, amount) => {
    const rates = _.cloneDeep(this.props.rates)
    rates.find(r => r.sessionUid === sessionUid).amount = amount
    this.props.handleRatesChange(rates)
  }

  render() {
    const { bed, rates, errors, handleRatesChange } = this.props
    const groupedSessions = _.groupBy(this.getSortedSessions(), 'startDate')
    return (
      <div className={`${c}_bed_rates`}>
        {Object.entries(groupedSessions).map(sessionGroup => {
          const sessionsArray = sessionGroup[1]
          const mainSession = sessionsArray[0]
          return (
            !!mainSession.startDate && !!mainSession.endDate && (
              <div key={sessionGroup[0]} className={`${c}_bed_rates_session`}>
                <div className={`${c}_bed_rates_session_titleContainer`}>
                  <span className={`${c}_bed_rates_session_title`}>Session:</span>
                  {`${formatDateRange(mainSession.startDate, mainSession.endDate)}`}
                </div>
                <div className={`${c}_bed_rates_session_rates`}>
                  <div className={`${c}_bed_rates_session_rates_labels`}>
                    <div className={`${c}_beds_label`}>Nightly Rate</div>
                    <div className={`${c}_beds_label`}>Minimum Stay Duration
                    </div>
                  </div>
                  {sessionsArray.map((session, i) => (
                    <div key={session.uid} className={`${c}_bed_rate`}>
                      <div className={`${c}_bed_input`}>
                        {this.isEntireSpace()
                          ? <Field.Input
                              line
                              icon="dollar"
                              size="small"
                              filter={decimalPriceInputFilter}
                              value={rates?.find(r => r.sessionUid === session.uid).amount || ''}
                              errors={errors[session.uid]}
                              onChange={(e, value) => this.handleChange(session.uid, value)}
                            /> 
                          : <Field.Input
                              line
                              icon="dollar"
                              size="small"
                              filter={decimalPriceInputFilter}
                              value={_.get(
                                bed.rates?.find(r => r.sessionUid === session.uid), 'amount', ''
                              )}
                              errors={errors[`${this.props.bed.uid}${session.uid}`]}
                              onChange={(e, value) => {
                                handleRatesChange(bed.uid, session.uid, value)
                              }}
                            />
                        }
                      </div>
                      
                      <div className={`${c}_bed_session`}>
                        <Select
                          size="small"
                          placeholder="Select"
                          className={`${c}_bed_session_minDurationSelect`}
                          disabled={i === 0}
                          options={this.getMinimumStayOptions(sessionsArray, session, mainSession.minimumStayDays)}
                          value={session.minimumStayDays ? +session.minimumStayDays : ''}
                          onChange={(e) => this.updateSessionMinStay(session, e.target.value)}
                        />
                        {!!i && (
                          <Icon
                            className={`${c}_bed_rates_session_delete`}
                            type="trash"
                            size={21}
                            onClick={() => this.removeSessionRate(session.uid)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {!this.maxSessionRatesReached(sessionsArray) && 
                  <Button
                  className={`${c}_bed_rates_session_rates_btn`}
                  hollow
                  square
                  icon="plus"
                  type="button"
                  onClick={() => this.addSessionRate(mainSession.startDate, mainSession.endDate)}
                  >
                    Add A New Rate For This Session
                  </Button>
                }
              </div>
            )
          )
        })}
      </div>
    )
  }
}

SplitSessionRates.propTypes = propTypes

export default SplitSessionRates
