import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import '@/listSpace/styles/stepBar.scss'

const propTypes = {
  steps: PropTypes.array.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  toStep: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
}

const c = 'listSpace_stepBar'

const StepBar = ({ steps, currentStepIndex, toStep, isEdit }) => (
  <div className={c}>
    <div className={`${c}_steps`}>
      {steps.map((step, i) => (
        <div
          key={step.path}
          className={classnames(`${c}_step`, {
            [`${c}_step--isPast`]: i < currentStepIndex,
            [`${c}_step--isCurrent`]: i === currentStepIndex,
          })}
        >
          <span>
            {(isEdit || i < currentStepIndex) && (
              <Link to={toStep(step.path)} />
            )}
            {step.label}
          </span>
        </div>
      ))}
    </div>
  </div>
)

StepBar.propTypes = propTypes

export default StepBar
