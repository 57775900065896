import catholic_university_of_america from '@/shared/assets/images/spots/catholic_university_of_america.jpg'
import childrens_national_hospital from '@/shared/assets/images/spots/childrens_national_hospital.jpg'
import cnn from '@/shared/assets/images/spots/cnn.jpg'
import department_of_defense from '@/shared/assets/images/spots/department_of_defense.jpg'
import fbi from '@/shared/assets/images/spots/fbi.jpg'
import george_mason_university from '@/shared/assets/images/spots/george_mason_university.jpg'
import george_washington_university from '@/shared/assets/images/spots/george_washington_university.jpg'
import georgetown_university from '@/shared/assets/images/spots/georgetown_university.jpg'
import house_of_representatives from '@/shared/assets/images/spots/house_of_representatives.jpg'
import howard_university from '@/shared/assets/images/spots/howard_university.png'
import human_rights_campaign from '@/shared/assets/images/spots/human_rights_campaign.jpg'
import inter_american_development_bank from '@/shared/assets/images/spots/inter_american_development_bank.jpg'
import international_monetary_fund from '@/shared/assets/images/spots/international_monetary_fund.jpg'
import maryland_university from '@/shared/assets/images/spots/maryland_university.jpg'
import national_geographic from '@/shared/assets/images/spots/national_geographic.jpg'
import national_institutes_of_health from '@/shared/assets/images/spots/national_institutes_of_health.jpg'
import navy_yard from '@/shared/assets/images/spots/navy_yard.jpg'
import npr from '@/shared/assets/images/spots/npr.jpg'
import org_of_american_states from '@/shared/assets/images/spots/org_of_american_states.jpg'
import sec from '@/shared/assets/images/spots/sec.jpg'
import smithsonian from '@/shared/assets/images/spots/smithsonian.jpg'
import state_department from '@/shared/assets/images/spots/state_department.jpeg'
import us_senate from '@/shared/assets/images/spots/us_senate.jpg'
import washington_capitals from '@/shared/assets/images/spots/washington_capitals.jpg'
import washington_nationals from '@/shared/assets/images/spots/washington_nationals.jpg'
import washington_wizards from '@/shared/assets/images/spots/washington_wizards.jpg'
import whitehouse from '@/shared/assets/images/spots/whitehouse.jpg'
import world_bank from '@/shared/assets/images/spots/world_bank.jpg'

export const spotsCategories = [
  'Government', 'Universities', 'Media & Sports', 'Other',
]

export default [{
  category: 0,
  title: `The White House`,
  address: `1600 Pennsylvania Avenue NW`,
  center: { lat: 38.897776, lng: -77.036530 },
  imageUrl: whitehouse,
  description: `At the center of our nation's capital lies the seat of the Executive Branch of government. Located in Northwest Washington, D.C., the White House is home to almost 300 interns from around the United States & the world. Unfortunately, this area is very commercial and few housing opportunities within walking distance exist. Look along the orange or red metro lines as morning rush hour commutes in Washington can be hectic and/or lengthy. To view possible commute times please use the Official DC Trip Planner.`,
  transport: `Farragut West Station (Orange, Blue & Silver lines), Metro Center (Red Line) & the 32 and 36 metro bus routes.`,
  neighborhoods: `Downtown, Foggy Bottom, Metro Center, Penn Quarter, Dupont Circle`,
}, {
  category: 0,
  title: `US House of Representatives`,
  address: `Independence Avenue & South Capitol Street SE`,
  center: { lat: 38.886913, lng: -77.010110 },
  imageUrl: house_of_representatives,
  description: `Home to 435 U.S. Congressmen & women, the House of Representatives is one of the largest intern destinations in Washington, D.C. "The House" as it's often referred to is comprised of four major office buildings; Canon, Longworth, Rayburn & Ford.\n\nThe area around the House "side" of the Capitol is very residential and housing opportunities should be plentiful. This will keep you within walking distance to your internship and those after work happy hours. Otherwise, go a little further east to the Eastern Market or Potomac Avenue neighborhoods (both have their own metro stations) and you won't be disappointed in the length of your commute. Look along the orange metro line as morning rush hour commutes in Washington can be hectic and/or lengthy. To view possible commute times please use the Official DC Trip Planner.`,
  transport: `Capitol South Station (Orange, Blue & Silver lines) & the 32, 34, 36 & 39 metro bus routes.`,
  neighborhoods: `Capitol Hill South, Eastern Market, Navy Yard, Capitol Hill North`,
}, {
  category: 0,
  title: `US Senate`,
  address: `1st & C Street NE (Russell, Dirksen, Hart Office Buildings)`,
  center: { lat: 38.893468, lng: -77.005896 },
  imageUrl: us_senate,
  description: `Comprised of nearly 120 member and committee offices, the US Senate is one of the most popular destinations for recent graduates and interns looking to get their toe in the door to the world of politics.\n\nCapitol Hill/NoMa/H Street/Atlas District/Eastern Market neighborhoods are your best bets for housing and are often within an easy 12-20 minute walk to Senate office buildings..  Additionally, the Senate is served by Union Station metro rail station (Red Line) and the 96 bus route.  For more information about commute times/cost you may visit the trip calculator at WMATA.com.`,
  transport: `Union Station (Red Line)`,
  neighborhoods: `Capitol Hill, Capitol Hill North, NoMa, H Street`,
}, {
  category: 0,
  title: `The State Department`,
  address: `2201 C Street NW`,
  center: { lat: 38.895444, lng: -77.048277 },
  imageUrl: state_department,
  description: `The epicenter of America's diplomatic corp, the U.S. State Department battles it out with Capitol Hill (both figuratively & literally) for being the largest provider of internships in Washington, D.C. Sharing the neighborhood of Foggy Bottom with The George Washington University & its 25,000 undergraduates gives this neighborhood a serious professional yet academic vibe.\n\nThere are some condominium properties (hello Watergate complex), older apartment buildings and historic row homes throughout this area but a lof of the housing stock is controlled by GWU and is made available only in the Summer. Look along the orange/blue/silver lines as morning rush hour commutes in Washington can be hectic and/or lengthy. To view possible commute times please use the Official DC Trip Planner.`,
  transport: `Foggy Bottom/George Washington University Station (Orange, Blue & Silver lines) & the 32 and 36 metro bus routes.`,
  neighborhoods: `Foggy Bottom, Georgetown, Downtown & Dupont Circle`,
}, {
  category: 1,
  title: `George Washington University`,
  address: `2121 I St NW, Washington, DC 20052`,
  center: { lat: 38.899689, lng: -77.048588 },
  imageUrl: george_washington_university,
  description: `The George Washington University (GW, GWU, or George Washington) is a private research university in Washington, D.C. Charted by an act of the United States Congress in 1821, GWU was founded on the basis of the wishes of George Washington, 1st President of the United States, for a national university within the nation's capital. George Washington is consistently ranked as one of the most prestigious and expensive universities in the United States.`,
  transport: `GWU/Foggy Bottom station (Orange, Blue & Silver Lines)`,
  neighborhoods: `Foggy Bottom, Georgetown, Dupont Circle, Downtown`,
}, {
  category: 1,
  title: `Georgetown University`,
  address: `3700 O St NW, Washington, DC 20057`,
  center: { lat: 38.907609, lng: -77.072226 },
  imageUrl: georgetown_university,
  description: `Georgetown University is a private research university in the Georgetown neighborhood of Washington, D.C., the capital of the United States. Founded in 1789 as Georgetown College, the university has since grown to comprise nine undergraduate and graduate schools, among which are the School of Foreign Service, School of Business, Medical Center, and Law School. Georgetown's main campus is located on a hill above the Potomac River. Georgetown offers degree programs in forty-eight disciplines, enrolling an average of 7,500 undergraduate and 10,000 post-graduate students from more than 130 countries. The campus is identifiable by its flagship Healy Hall, which is a National Historic Landmark.`,
  transport: `GWU/Foggy Bottom station (Orange, Blue & Silver Lines)`,
  neighborhoods: `Georgetown, Glover Park, Foggy Bottom`,
}, {
  category: 1,
  title: `University of Maryland`,
  address: `3100 Chesapeake Rd, College Park, MD 20742`,
  center: { lat: 38.986835, lng: -76.942565 },
  imageUrl: maryland_university,
  description: `The University of Maryland, College Park (commonly referred to as the University of Maryland, UMD, or simply Maryland) is a public research university located in the city of College Park in Prince George's County, Maryland, approximately 4 miles (6.4 km) from the northeast border of Washington, D.C. Founded in 1856, the university is the flagship institution of the University System of Maryland. The University of Maryland is the largest university in the state and the largest in the Washington metropolitan area, with a fall 2016 enrollment of more than 39,000 students, as well as over 100 undergraduate majors and 120 graduate programs. It is a member of the Association of American Universities and competes in intercollegiate athletics as a member of the Big Ten Conference. The University of Maryland has been labeled as one of the "Public Ivies," a publicly funded university considered as providing a quality of education comparable to those of the Ivy League.`,
  transport: `College Park/UMD station (Green & Yellow Lines)`,
  neighborhoods: ``,
}, {
  category: 0,
  title: `Federal Bureau of Investigation`,
  address: `935 Pennsylvania Ave NW, Washington, DC 20535`,
  center: { lat: 38.895702, lng: -77.024931 },
  imageUrl: fbi,
  description: `The Federal Bureau of Investigation (FBI), formerly the Bureau of Investigation (BOI), is the domestic intelligence and security service of the United States, and its principal federal law enforcement agency. Operating under the jurisdiction of the US Department of Justice, the FBI is also a member of the U.S. Intelligence Community and reports to/ both the Attorney General and the Director of National Intelligence. A leading U.S. counter-terrorism, counterintelligence, and criminal investigative organization, the FBI has jurisdiction over violations of more than 200 categories of federal crimes.\n\nAlthough many of the FBI's functions are unique, its activities in support of national security are comparable to those of the British MI5 and the Russian FSB. Unlike the Central Intelligence Agency (CIA), which has no law enforcement authority and is focused on intelligence collection abroad, the FBI is primarily a domestic agency, maintaining 56 field offices in major cities throughout the United States, and more than 400 resident agencies in lesser cities and areas across the nation. At an FBI field office, a senior-level FBI officer concurrently serves as the representative of the Director of National Intelligence.`,
  transport: `Archives Navy Memorial/Penn Quarter Station (Green Line)`,
  neighborhoods: `Downtown, Penn Quarter, Southwest Waterfront`,
}, {
  category: 2,
  title: `Washington Wizards`,
  address: `601 F St NW, Washington, DC 20004`,
  center: { lat: 38.898192, lng: -77.020870 },
  imageUrl: washington_wizards,
  description: `The Washington Wizards are an American professional basketball team based in Washington, D.C. The Wizards compete in the National Basketball Association (NBA), as a member of the league's Eastern Conference Southeast Division. The team plays its home games at the Capital One Arena, in the Chinatown neighborhood of Washington, D.C.\n\nThe franchise was established in 1961 as the Chicago Packers based in Chicago, and were renamed to Chicago Zephyrs the following season. In 1963, they relocated to Baltimore, Maryland and became the Baltimore Bullets, taking the name from previous team of the same name. In 1973, the team changed its name to the Capital Bullets to reflect their move to the Washington metropolitan area, and then to Washington Bullets in the following season. In 1997, they rebranded themselves as the Wizards.`,
  transport: `Chinatown/Gallery Place station (Red Line)`,
  neighborhoods: `Chinatown, Penn Quarter, Truxton Circle, Downtown`,
}, {
  category: 2,
  title: `Washington Nationals`,
  address: `1500 S Capitol St SE, Washington, DC 20003`,
  center: { lat: 38.873060, lng: -77.007422 },
  imageUrl: washington_nationals,
  description: `The Washington Nationals are a professional baseball team based in Washington, D.C. The Nationals compete in Major League Baseball (MLB) as a member club of the National League (NL) East division. From 2005 to 2007, the team played in RFK Stadium; since 2008 their home stadium has been Nationals Park on South Capitol Street in Southeast D.C., near the Anacostia River.\n\nThe Nationals are the fourth major league franchise to be based in Washington, D.C., and the first since 1971. The original Washington Senators, who were often referred to as the Nationals, played in the National League from 1891 to 1899. They were followed by a second Washington Senators franchise in 1901, a charter member of the new American League, who were officially named the Washington Nationals from 1905 to 1956. The second Senators franchise moved to Minnesota in 1961 and became the Minnesota Twins. They were replaced that season by a third Senators franchise, who eventually moved to Arlington, Texas, after the 1971 season and became the Texas Rangers.`,
  transport: `Navy Yard-Ballpark metro station (Green Line)`,
  neighborhoods: `Navy Yard, Southwest Waterfront, The Wharf, Capitol Hill South`,
}, {
  category: 3,
  title: `Smithsonian`,
  address: `1000 Jefferson Drive SW`,
  center: { lat: 38.888772, lng: -77.025991 },
  imageUrl: smithsonian,
  description: `Welcome to the Smithsonian Institution!  As the world's largest museum complex, the Smithsonian spans 19 museums, the National Zoo, cutting edge research facilities, and extensive education and outreach programs across the world.  At any given time, the Smithsonian employs 6,000 staff members and hosts thousands of researchers, volunteers, and over1,300 interns yearly.  The Smithsonian is headquartered in Washington, D.C. but operates museums & facilities in New York, Virginia, Maryland, Florida, Massachusetts, Arizona & Panama.  This is an exciting place to be and we hope you make the best of it!`,
  transport: `Smithsonian Station (Orange/Blue lines).  SW Waterfront, Navy Yard or any locations along the Orange/Blue lines would be your best option for your daily commute to/from the National Mall.`,
  neighborhoods: `Southwest Waterfront, Capitol Hill South`,
}, {
  category: 3,
  title: `Human Rights Campaign`,
  address: `1640 Rhode Island Avenue NW, Washington, DC, 200036`,
  center: { lat: 38.906318, lng: -77.038013 },
  imageUrl: human_rights_campaign,
  description: `Founded in 1980 by Steve Endean, The Human Rights Campaign (a.k.a. HRC) is the largest civil rights organization working to achieve equality for lesbian, gay, bisexual and transgender Americans.  The HRC represents a force of 1.5 million members, including an internship class each Spring, Summer & Fall, working to make The Human Rights Campaign's vision a reality.\n\nThe HRC is headquartered just off Scott Circle in NW Washington, D.C.  making Dupont Circle, Logan Circle, Downtown or any neighborhood located along the Red line the most convenient to this internship.`,
  transport: `Farragut North or Dupont Circle metro stations (Red Line)`,
  neighborhoods: `Dupont Circle, Downtown, Scott Circle, Thomas Circle`,
}, {
  category: 2,
  title: `National Geographic`,
  address: `1145 17th St NW, Washington, DC 20036`,
  center: { lat: 38.905285, lng: -77.037746 },
  imageUrl: national_geographic,
  description: `National Geographic, formerly The National Geographic Magazine, is the official magazine of the National Geographic Society. It has been published continuously since its first issue in 1888, nine months after the Society itself was founded. It primarily contains articles about geography, history, and world culture. The magazine is known for its thick square-bound glossy format with a yellow rectangular border and its extensive use of dramatic photographs.\n\nAs of 2015, the magazine is circulated worldwide in nearly 40 local-language editions and had a global circulation of 6.8 million per month.  Its U.S. circulation is around 3.5 million per month.`,
  transport: `Farragut North station (Red Line)`,
  neighborhoods: `Dupont Circle, Downtown, Scott Circle, Thomas Circle`,
}, {
  category: 3,
  title: `Securities & Exchange Commission`,
  address: `100 F St NE, Washington, DC 20002`,
  center: { lat: 38.898808, lng: -77.004061 },
  imageUrl: sec,
  description: `The U.S. Securities and Exchange Commission (SEC) is an agency of the United States federal government. It holds primary responsibility for enforcing the federal securities laws, proposing securities rules, and regulating the securities industry, the nation's stock and options exchanges, and other activities and organizations, including the electronic securities markets in the United States.`,
  transport: `Union Station (Red Line)`,
  neighborhoods: `Capitol Hill North, NoMa, H Street/Atlas District`,
}, {
  category: 3,
  title: `Organization of American States`,
  address: `200 17th St NW, Washington, DC 20006`,
  center: { lat: 38.892911, lng: -77.040359 },
  imageUrl: org_of_american_states,
  description: `The Organization of American States (Spanish: Organización de los Estados Americanos, Portuguese: Organização dos Estados Americanos, French: Organisation des États Américains), or the OAS or OEA, is an inter-continental organization founded on 30 April 1948, for the purposes of regional solidarity and cooperation among its member states. Headquartered in Washington, D.C., the OAS's members are the 35 independent states of the Americas.`,
  transport: `Smithsonian (Orange/Blue/Silver line) or Metro Center Stations (Red Line)`,
  neighborhoods: `Foggy Bottom, Downtown, Metro Center`,
}, {
  category: 3,
  title: `Washington Navy Yard`,
  address: `700 M St SE, Washington, DC 20003`,
  center: { lat: 38.875602, lng: -76.997740 },
  imageUrl: navy_yard,
  description: `The Washington Navy Yard (WNY) is the former shipyard and ordnance plant of the United States Navy in Southeast Washington, D.C. It is the oldest shore establishment of the U.S. Navy.\n\nThe Yard currently serves as a ceremonial and administrative center for the U.S. Navy, home to the Chief of Naval Operations, and is headquarters for the Naval Sea Systems Command, Naval Historical Center, the Department of Naval History, the U.S. Navy Judge Advocate General's Corps, Naval Reactors, Marine Corps Institute, the United States Navy Band, and other more classified facilities.`,
  transport: `Navy Yard/Ballpark Station (Green Line)`,
  neighborhoods: `Navy Yard, Eastern Market, Southwest Waterfront, The Wharf`,
}, {
  category: 2,
  title: `CNN`,
  address: `820 First St NE, Washington, DC 20002`,
  center: { lat: 38.900466, lng: -77.007552 },
  imageUrl: cnn,
  description: `The Cable News Network (CNN) was founded in 1980 by American media proprietor Ted Turner as a 24-hour cable news channel; however, by April 2016, a CNN executive officially described the channel as "a 24-hour global multiplatform network." Upon its launch, CNN was the first television channel to provide 24-hour news coverage, and was the first all-news television channel in the United States.\n\nAs of February 2015, CNN is available to approximately 96,289,000 cable, satellite, and telco television households (82.7% of households with at least one television set) in the United States.\n\nCNN's Washington, D.C. studio sits near the intersection of H and North Capitol Streets NE and is served by the red line (Union Station stop) and multiple bus lines.`,
  transport: `Union Station (Red Line)`,
  neighborhoods: `NoMa, H Street, Capitol Hill North, Chinatown`,
}, {
  category: 2,
  title: `National Public Radio`,
  address: `1111 N. Capitol Street NE, Washington, D.C. 20002`,
  center: { lat: 38.903928, lng: -77.008439 },
  imageUrl: npr,
  description: `National Public Radio (NPR) is a privately and publicly funded non-profit membership media organization that serves as a national syndicator to a network of 1000 public radio stations in the United States.\n\nNPR produces and distributes news and cultural programming. Individual public radio stations are not required to broadcast all NPR programs; most broadcast a mix of NPR programs, content from rival providers American Public Media, Public Radio International, Public Radio Exchange and WNYC Studios, and locally produced programs. The organization’s flagship shows are two drive-time news broadcasts, Morning Edition and the afternoon All Things Considered; both are carried by most NPR member stations, and are among the most popular radio programs in the country. As of October 2017, the drive time programs attract an audience of 14.63 million and 14.6 million respectively.`,
  transport: `NoMa/Gallaudet Station (Red Line)`,
  neighborhoods: `NoMa, Capitol Hill North, Chinatown, Truxton Circle`,
}, {
  category: 0,
  title: `National Institute of Health`,
  address: `10 Center Dr, Bethesda, MD 20814`,
  center: { lat: 38.999622, lng: -77.106692 },
  imageUrl: national_institutes_of_health,
  description: `The National Institutes of Health (NIH) is a biomedical research facility primarily located in Bethesda, Maryland. It is the primary agency of the United States government responsible for biomedical and health-related research. The NIH both conducts its own scientific research through its Intramural Research Program (IRP) and provides major biomedical research funding to non-NIH research facilities through its Extramural Research Program.\n\nWith 1,200 principal investigators and more than 4,000 postdoctoral fellows in basic, translational, and clinical research, the IRP is the largest biomedical research institution in the world, while, as of 2003, the extramural arm provided 28% of biomedical research funding spent annually in the U.S., or about US$26.4 billion.`,
  transport: `Access to the main campus is served most closely by the Medical Center metro station (red line) and multiple bus lines.`,
  neighborhoods: `Bethesda or Grosvenor-Strathmore stations (Red Line)`,
}, {
  category: 0,
  title: `Department of Defense`,
  address: `Pentagon, Arlington, VA`,
  center: { lat: 38.871932, lng: -77.056256 },
  imageUrl: department_of_defense,
  description: `The Pentagon is the headquarters of the United States Department of Defense, located in Arlington County, Virginia, across the Potomac River from Washington, D.C. As a symbol of the U.S. military, The Pentagon is often used metonymically to refer to the U.S. Department of Defense.\n\nThe Pentagon is one of the world's largest office buildings, with about 6,500,000 sq ft (600,000 m2), of which 3,700,000 sq ft (340,000 m2) are used as offices. Approximately 23,000 military and civilian employees and about 3,000 non-defense support personnel work in the Pentagon. It has five sides, five floors above ground, two basement levels, and five ring corridors per floor with a total of 17.5 mi (28.2 km) of corridors. The Pentagon includes a five-acre (20,000 m2) central plaza, which is shaped like a pentagon and informally known as "ground zero", a nickname originating during the Cold War on the presumption that it would be targeted by the Soviet Union at the outbreak of nuclear war.\n\nCommuters to the pentagon will access it via the Pentagon station (Yellow and Blue lines).  For obvious reasons, security is strict in this area and commuters should calculate this in to their travel plans.`,
  transport: `Pentagon Metro Station (Blue Line)`,
  neighborhoods: `Arlington, Pentagon City`,
}, {
  category: 1,
  title: `George Mason University`,
  address: `4400 University Dr, Fairfax, VA 22030`,
  center: { lat: 38.831454, lng: -77.312051 },
  imageUrl: george_mason_university,
  description: `George Mason University (GMU, Mason, or George Mason) is a public research university in Fairfax County, Virginia. Initially founded as a branch of the University of Virginia in 1949, it became an independent institution in 1972. The university is named after the founding father, George Mason, a Virginia planter and politician who authored the Virginia Declaration of Rights. Mason currently operates four campuses located in Virginia, the main campus being in Fairfax, with another in Songdo, South Korea inside the Incheon Free Economic Zone. Three of the four campuses in Virginia are within the Northern Virginia region of the commonwealth, part of the Washington, D.C. metropolitan area. The university recognizes 500 student groups as well as 41 fraternities and sororities. In 2016, the Carnegie Classification of Institutions of Higher Education released its new listings including George Mason in the “Highest Research Activity” (R1) category.`,
  transport: `Vienna/Fairfax-GMU (Orange Line)`,
  neighborhoods: ``,
}, {
  category: 1,
  title: `Catholic University of America`,
  address: `620 Michigan Ave NE, Washington, DC 20064`,
  center: { lat: 38.934743, lng: -76.999765 },
  imageUrl: catholic_university_of_america,
  description: `The Catholic University of America (CUA) is a private, non-profit Christian university located in Washington, D.C., in the United States. It is a pontifical university of the Catholic Church in the United States and the only institution of higher education founded by the U.S. Catholic bishops. Established in 1887 as a graduate and research center following approval by Pope Leo XIII on Easter Sunday, the university began offering undergraduate education in 1904. The university's campus lies within the Brookland neighborhood, known as "Little Rome", which contains 60 Catholic institutions, including Trinity Washington University and the Dominican House of Studies.`,
  transport: `Brookland/Catholic U. of America (Red line)`,
  neighborhoods: `Brookland, Brentwood, Eckington, NoMa`,
}, {
  category: 1,
  title: `Howard University`,
  address: `2400 Sixth St NW, Washington, DC 20059`,
  center: { lat: 38.923644, lng: -77.020832 },
  imageUrl: howard_university,
  description: `Howard University (HU or simply Howard) is a federally chartered, private, coeducational, nonsectarian, historically black university (HBCU) in Washington, D.C. It is recognized by the Carnegie Foundation as a research university with high research activity and is accredited by the Middle States Commission on Higher Education.\n\nFrom its outset Howard has been nonsectarian and open to people of all genders and races. Howard offers more than 120 areas leading to undergraduate, graduate, and professional degrees`,
  transport: `Shaw/Howard University (Green/Yellow Lines)`,
  neighborhoods: `Shaw/U Street, Bloomingdale, Petworth, Truxton Circle`,
}, {
  category: 2,
  title: `Washington Capitals`,
  address: `601 F Street NW`,
  center: { lat: 38.898192, lng: -77.020870 },
  imageUrl: washington_capitals,
  description: `The Washington Capitals are a professional ice hockey team based in Washington, D.C. They are members of the Metropolitan Division of the Eastern Conference of the National Hockey League (NHL). Since their founding in 1974, the Capitals have won one conference championship (in 1998), and ten division titles. In 1997, the team moved their home ice hockey rink from the suburban Capital Centre (located in Landover, Maryland) to the new MCI Center (now Capital One Arena), in Washington, D.C.\n\nBusinessman Ted Leonsis has owned the team since 1999, and has revitalized the franchise by drafting star players such as Alexander Ovechkin, Nicklas Backstrom, Mike Green and Braden Holtby. The 2009–10 Capitals won the franchise's first-ever Presidents' Trophy for being the team with the most points at the end of the regular season. They won it a second time in 2015–16, and did so for a third time the following season in 2016–17`,
  transport: `Gallery Place/Chinatown Station (Red Line)`,
  neighborhoods: `Penn Quarter, Chintatown,  Truxton Circle, Downtown`,
}, {
  category: 3,
  title: `Inter-American Development Bank`,
  address: `1300 New York Ave NW, Washington, DC 20577`,
  center: { lat: 38.899508, lng: -77.030169 },
  imageUrl: inter_american_development_bank,
  description: `The Inter-American Development Bank (IADB or IDB or BID) is the largest source of development financing for Latin America and the Caribbean.[1] Established in 1959, the IDB supports Latin American and Caribbean economic development, social development and regional integration by lending to governments and government agencies, including State corporations.\n\nThe IDB has four official languages: English, French, Portuguese, and Spanish`,
  transport: `McPherson Square (Orange, Silver, Blue lines) or Metro Center (Red line)`,
  neighborhoods: `Downtown`,
}, {
  category: 3,
  title: `International Monetary Fund`,
  address: `1900 Pennsylvania Ave NW, Washington, DC 20431`,
  center: { lat: 38.898966, lng: -77.044105 },
  imageUrl: international_monetary_fund,
  description: `The International Monetary Fund (IMF) is an international organization headquartered in Washington, D.C., of "189 countries working to foster global monetary cooperation, secure financial stability, facilitate international trade, promote high employment and sustainable economic growth, and reduce poverty around the world." Formed in 1945 at the Bretton Woods Conference primarily by the ideas of Harry Dexter White and John Maynard Keynes, it came into formal existence in 1945 with 29 member countries and the goal of reconstructing the international payment system. It now plays a central role in the management of balance of payments difficulties and international financial crises. Countries contribute funds to a pool through a quota system from which countries experiencing balance of payments problems can borrow money. As of 2016, the fund had SDR477 billion (about $668 billion).\n\nThrough the fund, and other activities such as the gathering of statistics and analysis, surveillance of its members' economies and the demand for particular policies,the IMF works to improve the economies of its member countries. The organisation's objectives stated in the Articles of Agreement are: to promote international monetary co-operation, international trade, high employment, exchange-rate stability, sustainable economic growth, and making resources available to member countries in financial difficulty.`,
  transport: `Foggy Bottom/GWU (Orange, Silver, Blue Lines), Farragut West (Red Line)`,
  neighborhoods: `Downtown, Foggy Bottom`,
}, {
  category: 3,
  title: `World Bank`,
  address: `1818 H St NW, Washington, DC 20433`,
  center: { lat: 38.899563, lng: -77.042303 },
  imageUrl: world_bank,
  description: `The World Bank (French: Banque mondiale) is an international financial institution that provides loans to countries of the world for capital projects. It comprises two institutions: the International Bank for Reconstruction and Development (IBRD), and the International Development Association (IDA). The World Bank is a component of the World Bank Group.\n\nThe World Bank's stated goal is the reduction of poverty. However, according to its Articles of Agreement, all its decisions must be guided by a commitment to the promotion of foreign investment and international trade and to the facilitation of capital investment.`,
  transport: `Foggy Bottom/GWU (Orange, Silver, Blue Lines), Farragut West (Red Line)`,
  neighborhoods: `Downtown, Foggy Bottom`,
}, {
  category: 3,
  title: `Children’s National Hospital`,
  address: `2916, 111 Michigan Ave NW, Washington, DC 20010`,
  center: { lat: 38.926889, lng: -77.014582 },
  imageUrl: childrens_national_hospital,
  description: `Children’s National Health System (formerly DC Children’s Hospital, Children's National Medical Center) is ranked among the top 10 children’s hospitals in the country by U.S. News & World Report. Located just north of the McMillan Reservoir and Howard University, it shares grounds with Washington Hospital Center, National Rehabilitation Hospital, and the DC Veterans Affairs Medical Center. Kurt Newman, M.D., has served as the president and chief executive officer of Children’s National since 2011. Children's National is a not-for-profit institution that performs more than 450,000 visits each year. Featuring 303 beds and a Level IV NICU, Children's National is the regional referral center for pediatric emergency, trauma, cancer, cardiac and critical care as well as neonatology, orthopaedic surgery, neurology and neurosurgery.  Children's National is ranked among the best pediatric hospitals in the United States by U.S. News & World Report and The Leapfrog Group. Children's National is a teaching hospital of The George Washington University School of Medicine.`,
  transport: `Shuttle from Union Station (Red Line)`,
  neighborhoods: `Petworth, Parkview, McMillian, Bloomingdale, Howard U.`,
}]
