import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import * as api from '@/features/guestPaymentForm/api'
import { Spinner } from '@/shared/components'
import CustomerForm from './CustomerForm'
import CreditCardForm from './CreditCardForm'
import '@/features/guestPaymentForm/styles/index.scss'

const propTypes = {
  onReadyChange: PropTypes.func,
  onCreditCardSubmitSuccess: PropTypes.func.isRequired,
  onCreditCardSubmitError: PropTypes.func.isRequired,
  onCustomerFormSubmitSuccess: PropTypes.func.isRequired,
  onCustomerFormSubmitError: PropTypes.func.isRequired,
}

const defaultProps = {
  onReadyChange: isReady => {},
}

const c = 'guestPaymentForm'

class GuestPaymentForm extends Component {

  state = { user: null }

  componentDidMount() {
    this.fetchCustomer()
  }

  hasCustomerInfo = (user = this.state.user) => !_.isEmpty(_.get(user, 'shipping'))
  hasCreditCard = (user = this.state.user) => !!!!_.get(user, 'sources.data[0]')

  fetchCustomer = () => {
    api.fetchCustomer().then(({ data }) => {
      this.setState({ user: data }, this.setReadyChange)
    }, handleError)
  }

  setReadyChange = () => this.props.onReadyChange(
    this.hasCustomerInfo() && this.hasCreditCard()
  )

  upsertCustomer = payload => (
    api.upsertCustomer(payload).then(({ data }) => {
      showToast({
        title: 'Your billing information was saved successfully.',
      })
      this.setState({ user: data }, this.setReadyChange)
    })
  )

  render() {
    const { user } = this.state
    return (
      <div className={c}>
        {!user && (
          <div className={`${c}_fetchingMessage`}>
            <h2>{`Fetching your billing information...`}</h2>
            <Spinner size={52} />
          </div>
        )}
        {user && this.hasCustomerInfo() && (
          <CreditCardForm
            user={user}
            hasCreditCard={this.hasCreditCard}
            onSubmit={this.upsertCustomer}
            onCreditCardSubmitSuccess={this.props.onCreditCardSubmitSuccess}
            onCreditCardSubmitError={this.props.onCreditCardSubmitError}
          />
        )}
        {user && (
          <CustomerForm
            user={user}
            hasCustomerInfo={this.hasCustomerInfo}
            onSubmit={this.upsertCustomer}
            onCustomerFormSubmitSuccess={this.props.onCustomerFormSubmitSuccess}
            onCustomerFormSubmitError={this.props.onCustomerFormSubmitError}
          />
        )}
      </div>
    )
  }
}

GuestPaymentForm.propTypes = propTypes
GuestPaymentForm.defaultProps = defaultProps

export default GuestPaymentForm
