import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import '@/shared/styles/icon.scss'

const propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
}

const Icon = ({
  type,
  size,
  color,
  left = 0,
  top = 0,
  className,
  ...iconProps
}) => (
  <i
    {...iconProps}
    className={classnames('icon', `icon-${type}`, className)}
    style={{
      fontSize: size && `${size}px`,
      color: color && `${color}`,
      transform: (left || top) && `translate(${left}px, ${top}px)`,
    }}
  />
)


Icon.propTypes = propTypes

export default Icon
