import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { countryNames } from '@/shared/static/countries';
import usStates from '@/shared/static/usStates';
import options from '@/shared/static/options';
import {
  Field,
  FieldErrors,
  BirthDateSelects,
  CheckboxGroup,
  Checkbox,
} from '@/shared/components';
import RadioToggle from './RadioToggle';
import { FieldsRequired } from './StaticInfo';
import PrevNext from './PrevNext';
import '@/plugin/property/styles/information.scss';

const propTypes = {
  userInput: PropTypes.object.isRequired,
  updateUserInput: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const c = 'plugin_information';

class Information extends Component {
  handleChange = (values) => {
    this.props.updateUserInput(values);
    this.props.clearStepErrors(_.keys(values));
  };

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next');
    }
  };

  renderInfoNotice = () => (
    <div className={`${c}_infoNotice`}>
      <h3 className={`${c}_subheading`}>Are you a parent or other sponsor booking on behalf of someone else?</h3>
      <p className={`${c}_subtitle`}>
        If yes, please enter your student's information below. Otherwise enter your own information.
      </p>
    </div>
  )

  renderRadioBox = (props) => (
    <label
      className={classnames(`${c}_radioBox`, {
        [`${c}_radioBox--isChecked`]: props.checked,
      })}
    >
      <RadioToggle {...props} />
      <div className={`${c}_radioBox_description`}>{props.description}</div>
    </label>
  );

  renderPersonalInfoForm = (userInput) => (
    <div className={`${c}_personalInfo`}>
      <h2 className={`${c}_heading`}>Personal Information</h2>
      {this.renderInfoNotice()}
      <div className={`${c}_personalInfo_name flexContainer`}>
        <Field.Input
          name="firstName"
          className={'first_name flexItem'}
          label="First Name"
          value={userInput.firstName}
          onChange={(e, value) => this.handleChange({ firstName: value })}
          errors={this.props.errors.firstName}
        />
        <Field.Input
          className={'flexItem'}
          name="lastName"
          label="Last Name"
          value={userInput.lastName}
          onChange={(e, value) => this.handleChange({ lastName: value })}
          errors={this.props.errors.lastName}
        />
      </div>
      <div className={`${c}_personalInfo_contact`}>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="email"
            label="E-mail"
            value={userInput.email}
            onChange={(e, value) => this.handleChange({ email: value })}
            errors={this.props.errors.email}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="phone"
            label="Phone Number"
            value={userInput.phone}
            onChange={(e, value) => this.handleChange({ phone: value })}
            errors={this.props.errors.phone}
          />
        </div>
      </div>
      <h3 className={`${c}_subheading`}>Permanent Address</h3>
      <div className={`${c}_personalInfo_address`}>
        <div className="flexContainer">
          <Field.Input
            name="city"
            className={'flexItem'}
            label="City"
            value={userInput.city}
            onChange={(e, value) => this.handleChange({ city: value })}
          />
          <Field.Select
            className={'flexItem stateSelection_select'}
            label="State"
            options={[{ value: '', label: 'Select' }, ...usStates]}
            onChange={(e, value) => this.handleChange({ usState: value })}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Select
            className={'countrySelection_select'}
            label="Country"
            value={userInput.country}
            options={[{ value: '', label: 'Select' }, ...countryNames]}
            onChange={(e, value) => this.handleChange({ country: value })}
          />
        </div>
      </div>
      <div className={`${c}_personalInfo_birthday`}>
        <h3 className={`${c}_subheading`}>Date of Birth</h3>
        <div className={`${c}_inputContainer`}>
          <BirthDateSelects
            value={userInput.birthDate}
            onChange={(e) => this.handleChange({ birthDate: e })}
            errors={this.props.errors.birthDate}
          />
        </div>
      </div>
      <div className={`${c}_personalInfo_gender`}>
        <h3 className={`${c}_subheading`}>Gender Identity</h3>
        <div className={`${c}_radioBox_row`}>
          {this.renderRadioBox({
            label: 'Female',
            name: 'gender',
            value: 'female',
            checked: userInput.gender === 'female',
            onChange: (e, value) => {
              if (this.props.query.placementTypes === 'unisex') {
                this.handleChange({ gender: value });
              }
            },
          })}
          {this.renderRadioBox({
            label: 'Male',
            name: 'gender',
            value: 'male',
            checked: userInput.gender === 'male',
            onChange: (e, value) => {
              if (this.props.query.placementTypes === 'unisex') {
                this.handleChange({ gender: value });
              }
            },
          })}
        </div>
        <FieldErrors
          className={`${c}_radioBox_row_errors`}
          errors={this.props.errors.gender}
        />
      </div>
      <div className={`${c}_schoolInfo`}>
        <h2 className={`${c}_heading`}>School Information</h2>
        <div className={`${c}_radioBox_row`}>
          {this.renderRadioBox({
            label: 'Undergrad',
            name: 'studentType',
            value: 'Undergrad',
            checked: userInput.studentType === 'Undergrad',
            onChange: (e, value) => this.handleChange({ studentType: value }),
          })}
          {this.renderRadioBox({
            label: 'Intern',
            name: 'studentType',
            value: 'Intern',
            checked: userInput.studentType === 'Intern',
            onChange: (e, value) => this.handleChange({ studentType: value }),
          })}
          {this.renderRadioBox({
            label: 'Graduate',
            name: 'studentType',
            value: 'Graduate',
            checked: userInput.studentType === 'Graduate',
            onChange: (e, value) => this.handleChange({ studentType: value }),
          })}
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="school"
            label="School/University Name"
            value={userInput.school}
            onChange={(e, value) => this.handleChange({ school: value })}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="major"
            label="Major Field of Study"
            value={userInput.major}
            onChange={(e, value) => this.handleChange({ major: value })}
          />
        </div>
      </div>
      <div className={`${c}_personalInterests`}>
        <h2 className={`${c}_heading`}>Personal Interests</h2>
        <h3 className={`${c}_subheading`}>Social Media</h3>
        <p className={`${c}_subtitle`}>
          The links to your social media will only be visible to your fellow
          roommates.
        </p>
        <div className={'flexContainer'}>
          <Field.Input
            className={'flexItem'}
            label="Instagram URL"
            placeholder="https://www.instagram.com/you"
            value={userInput.instagramUrl}
            onChange={(e, value) => this.handleChange({ instagramUrl: value })}
          />
          <Field.Input
            className={'flexItem'}
            label="Facebook URL"
            placeholder="https://www.facebook.com/you"
            value={userInput.facebookUrl}
            onChange={(e, value) => this.handleChange({ facebookUrl: value })}
          />
        </div>
        <div className={`${c}_personalInterests_bio`}>
          <h3 className={`${c}_subheading`}>
            Biography - Tell Your Roomies About Yourself
          </h3>
          <p className={`${c}_subtitle`}>
            Bio helps people learn a bit about you. Tell them about the things
            you like, your values, what it’s like to have you as a guest or
            host.
          </p>
          <div className={`${c}_inputContainer`}>
            <Field.Textarea
              minRows={4}
              value={userInput.bio}
              onChange={(e, value) => this.handleChange({ bio: value })}
            />
          </div>
        </div>
        <div className={`${c}_personalInterests_interests`}>
          <h3 className={`${c}_subheading`}>Areas of Interest</h3>
          <p className={`${c}_subtitle`}>
            We use this info to better match you with roommates, hosts, and
            groups.
          </p>
          <CheckboxGroup
            value={userInput.interests}
            onChange={(e) => {
              this.handleChange({ interests: e });
            }}
            render={({ isChecked, onCheck }) =>
              options.interests.map((interest) => (
                <div key={interest.value}>
                  <Checkbox
                    size="small"
                    value={interest.value}
                    label={interest.label}
                    checked={isChecked(interest.value)}
                    onChange={onCheck}
                  />
                </div>
              ))
            }
          />
        </div>
        <div className={`${c}_tos`}>
          <h3 className={`${c}_subheading`}>Terms and Conditions</h3>
          <p className={`${c}_subtitle`}>
            I have read and accept the terms and conditions of The Washington
            Intern Housing Network, LCC and its affiliates.
          </p>
          <div className={`${c}_radioBox_row`}>
            {this.renderRadioBox({
              label: 'Yes',
              name: 'acceptedToS',
              value: 'true',
              checked: userInput.acceptedToS === 'true',
              onChange: (e, value) => this.handleChange({ acceptedToS: value }),
            })}
            {this.renderRadioBox({
              label: 'No',
              name: 'acceptedToS',
              value: 'false',
              checked: userInput.acceptedToS === 'false',
              onChange: (e, value) => this.handleChange({ acceptedToS: value }),
            })}
          </div>
          <FieldErrors
            className={`${c}_radioBox_row_errors`}
            errors={this.props.errors.acceptedToS}
          />
        </div>
      </div>
    </div>
  );

  render() {
    const { userInput } = this.props;
    return (
      <div className={c}>
        {this.renderPersonalInfoForm(userInput)}
        <FieldsRequired />
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    );
  }
}

Information.propTypes = propTypes;

export default Information;
