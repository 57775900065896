import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import googleMapsApiLoader from '@/shared/hocs/googleMapsApiLoader'
import { getDefaultMapOptions } from '@/shared/utils/map'
import '@/shared/styles/locationMap.scss'

const propTypes = {
  google: PropTypes.object.isRequired,
  center: PropTypes.object,
  zoom: PropTypes.number,
  invalid: PropTypes.bool,
  getGoogleMap: PropTypes.func,
  onCoordinatesChange: PropTypes.func.isRequired,
}

const defaultProps = {
  getGoogleMap: (google, map) => {},
}

class Map extends Component {

  componentDidMount() {
    this.map = new this.props.google.maps.Map(
      this.$mapContainer,
      getDefaultMapOptions(this.props.google)
    )
    this.map.addListener('click', this.handleMapClick)
    this.updateMarker()
    this.props.getGoogleMap(this.props.google, this.map)
  }

  componentDidUpdate() {
    this.updateMarker()
  }

  updateMarker = () => {
    const { center, zoom } = this.props
    if (!center) {
      if (this.marker) {
        this.marker.setMap(null)
        this.marker = null
      }
    } else if ((center && zoom) && (!_.isEqual(center, this.lastCenter))) {
      this.moveOrCreateMarker(center)
      this.map.setCenter(center)
      this.map.setZoom(zoom)
    }
    this.lastCenter = center
  }

  moveOrCreateMarker = center => {
    if (this.marker) {
      this.marker.setPosition(center)
      return
    }
    this.marker = new this.props.google.maps.Marker({
      map: this.map,
      position: center,
      draggable: true,
    })
    this.marker.addListener('dragend', e => {
      this.lastCenter = e.latLng.toJSON()
      this.props.onCoordinatesChange(e.latLng.toJSON())
    })
  }

  handleMapClick = e => {
    this.moveOrCreateMarker(e.latLng)
    this.lastCenter = e.latLng.toJSON()
    this.props.onCoordinatesChange(e.latLng.toJSON())
  }

  render() {
    return (
      <div className="locationMap">
        <div className={classnames('locationMap_label', {
          'locationMap_label--hasError': this.props.invalid,
        })}>
          {this.props.center ? (
            'Make sure the pin is in the correct location. Drag it to make adjustments.'
          ) : (
            'Click on the map to show us the exact location.'
          )}
        </div>
        <div
          className="locationMap_map"
          ref={el => this.$mapContainer = el}
        />
      </div>
    )
  }
}

Map.propTypes = propTypes
Map.defaultProps = defaultProps

export default googleMapsApiLoader(Map)
