import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@/shared/components'

const propTypes = {
  size: PropTypes.number,
  padding: PropTypes.number,
  center: PropTypes.bool,
  color: PropTypes.string,
}

const PageSpinner = ({
  size = 80,
  padding = 80,
  center = true,
  color,
}) => (
  <div className="pageSpinner" style={{
    paddingTop: padding,
    paddingBottom: padding,
    textAlign: center ? 'center' : 'left',
  }}>
    <Spinner size={size} color={color} />
  </div>
)

PageSpinner.propTypes = propTypes

export default PageSpinner
