import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Input } from '@/shared/components'
import '@/shared/styles/confirmModal.scss'

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  confirmInput: PropTypes.string,
  dontCloseOnConfirm: PropTypes.bool,
  isDanger: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  renderLink: PropTypes.func.isRequired,
}

const defaultProps = {
  title: 'Warning',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
}

class ConfirmModal extends Component {

  state = {
    isWorking: false,
    isConfirmEnabled: false,
  }

  handleConfirm = closeModal => {
    if (this.props.dontCloseOnConfirm) {
      this.setState({ isWorking: true })
      this.props.onConfirm({
        close: () => {
          closeModal()
          this.setState({ isWorking: false })
        },
      })
    } else {
      closeModal()
      this.props.onConfirm()
    }
  }

  render() {
    const {
      title,
      message,
      confirmText,
      cancelText,
      confirmInput,
      isDanger,
      renderLink,
    } = this.props
    return (
      <Modal
        className="confirmModal"
        renderLink={renderLink}
        renderContent={({ closeModal }) => (
          <Fragment>
            <div className="confirmModal_title">{title}</div>
            <p className="confirmModal_message">{message}</p>
            {confirmInput && (
              <Fragment>
                <p className="confirmModal_inputLabel">
                  {`Type "${confirmInput}" below to confirm.`}
                </p>
                <Input onChange={(e, value) => this.setState({
                  isConfirmEnabled: value.trim().toLowerCase() === confirmInput.toLowerCase(),
                })} />
                <br />
              </Fragment>
            )}
            <Button hollow onClick={closeModal}>{cancelText}</Button>
            <Button
              color={isDanger ? 'danger' : 'primary'}
              disabled={confirmInput && !this.state.isConfirmEnabled}
              working={this.state.isWorking}
              onClick={() => this.handleConfirm(closeModal)}
            >
              {confirmText}
            </Button>
          </Fragment>
        )}
      />
    )
  }
}

ConfirmModal.propTypes = propTypes
ConfirmModal.defaultProps = defaultProps

export default ConfirmModal
