import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { useLocation, useNavigationType } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import * as url from '@/shared/utils/url'
import { restoreQuery, resetPage } from '@/search/state/actions'
import { Icon, Media, Head } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import SearchBox from './SearchBox'
import Filters from './filters'
import Results from './results'
import Map from './map'
import '@/search/styles/index.scss'

const mapDispatchToProps = { restoreQuery, resetPage }

const propTypes = {
  restoreQuery: PropTypes.func.isRequired,
  resetPage: PropTypes.func.isRequired
}

export const pageContainerClassName = 'search_pageContainer'

const Search = (props) => {

  const { restoreQuery, resetPage } = props
  
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    const { zoom, centerLat, centerLng } = url.queryStringToObject(location.search)

    analytics.pageview('Search')

    if (!zoom || !centerLat || !centerLng) {
      restoreQuery(location.search)
    }
  
    return () => {
      historyUnlisten()
      resetPage()
    }
  }, [])

  useEffect(() => {
    historyUnlisten()
  }, [location])

  const historyUnlisten = () => {
    if (navigationType === 'POP') {
      restoreQuery(location.search)
    }
  }

  return (
    <div className="search">
      <Head url="/search" />
      {<AppHeader renderAfterLogo={() => (
        <Media query="(max-width: 767px)" unmatch={() => <SearchBox />} />
      )} />}
      <Media
        query="(max-width: 1139px)"
        match={() => <MobileLayout />}
        unmatch={() => <DesktopLayout />}
      />
    </div>
  )
}

const MobileLayout = () => { 
  const [mobileMapIsActive, setMobileMapIsActive] = useState(false)

  const toggleMobileMap = () => {
    setMobileMapIsActive(!mobileMapIsActive)
  }

  return (
    <div className={classnames(pageContainerClassName, {
      [`${pageContainerClassName}--mobileMapIsActive`]: mobileMapIsActive,
    })}>
      <Filters />
      <div className="search_pageInner">
        <Content />
        <div className="search_mobileMapLink" onClick={toggleMobileMap}>
          <Icon type={mobileMapIsActive ? 'list' : 'location-hollow'} />
        </div>
      </div>
    </div>
  )
}

const DesktopLayout = () => (
  <div className={pageContainerClassName}>
    <Filters />
    <Content />
  </div>
)

const Content = () => {
  const [refReady, setRefReady] = useState(false)
  const leftContainerRef = useRef()

  const location = useLocation()

  useEffect( () => {
    setRefReady(true)
  }, [])

  return (
    <>
      <div
        className="search_leftContainer"
        ref={leftContainerRef}
      >
        {<Media query="(max-width: 767px)" match={() => <SearchBox />} />}
        {!!refReady && <Results leftContainerRef={leftContainerRef} />}
      </div>
      <div className="search_mapContainer">
        <Map
          routerLocation={location}
        />
      </div>
    </>
  )
}

Search.propTypes = propTypes

export default connect(null, mapDispatchToProps)(Search)
