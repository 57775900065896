import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import { showToast } from '@/shared/utils/toast'
import { sortPhotos } from '@/shared/utils/photos'
import { formatDate } from '@/shared/utils/dateTime'
import { formatAddress } from '@/shared/utils/geo'
import { Icon, Photo, ConfirmModal, Button } from '@/shared/components'
import ShareProperty from '@/features/shareProperty/components'
import TogglePaused from './TogglePaused'
import '@/hostProperties/styles/propertyRow.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  onPause: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const c = 'hostPropertyRow'

const PropertyRow = ({ property, onPause, onClone, onDelete }) => (
  <div key={property.id} className={c}>
    <div className={`${c}_status--${property.listing.paused ? 'paused' : 'active'}`} />
    <Photo photo={sortPhotos(property.photos)[0]}>
      {property.listing.paused && <Icon type="pause" />}
      <div className={`${c}_photo_bottom`}>
        <Link
          className={`${c}_photo_item`}
          to={`/properties/${property.id}`}
          target="_blank"
        >
          <Icon type="share" />
          <span>View</span>
        </Link>
        <ShareProperty
          renderLink={() => (
            <div className={`${c}_photo_item`}>
              <Icon type="share-1" />
              <span>Share</span>
            </div>
          )}
          property={property}
        />
        <TogglePaused
          renderLink={() => (
            <div className={`${c}_photo_item`}>
              <Icon type={`${property.listing.paused ? 'play' : 'pause'}-circle`} />
              <span>{property.listing.paused ? 'Activate' : 'Pause'}</span>
            </div>
          )}
          value={!property.listing.paused}
          onChange={onPause}
        />
      </div>
    </Photo>
    <div className={`${c}_content`}>
      <div className={
        `${c}_content_status
         ${c}_content_status--${property.listing.paused ? 'paused' : 'active'}`
      }>
        {property.listing.paused ? 'Paused' : 'Active'}
      </div>
      <div className={`${c}_content_title`}>
        {property.title}
      </div>
      <div className={`${c}_content_info`}>
        {formatAddress(property, ['zip', 'state', 'streetAddress2'])}
        <br />
        {`${property.streetAddress2 ? property.streetAddress2 + ', ' : ''}
          ${options.labels.placementTypes[property.placementType]}
          ${options.labels.listingTypes[property.listingType].replace('Space', '')} Space`}
      </div>
      <div className={`${c}_content_createdAt`}>
        {`Added on ${formatDate(property.createdAt)}`}
      </div>
      {property.listingType !== 'entire_space' && (
        <Link to={`/host/properties/${property.id}`} className={`${c}_content_bedsLink`}>
          Manage Beds
        </Link>
      )}
    </div>
    <div className={`${c}_actions`}>
      <Link className={`${c}_actions_item`} to={`/list-space?propertyId=${property.id}`}>
        <Icon type="edit" />
        <span>Edit</span>
      </Link>
      <ConfirmModal
        title="Duplicate property"
        message="Are you sure you want to duplicate this property?"
        confirmText="Duplicate"
        onConfirm={onClone}
        dontCloseOnConfirm
        renderLink={() => (
          <div className={`${c}_actions_item`}>
            <Icon type="duplicate" />
            <span>Duplicate</span>
          </div>
        )}
      />
      {property.activeBookingsCount ? (
        <div className={`${c}_actions_item`} onClick={() => showToast({
          type: 'danger',
          title: 'You can not delete a property with active bookings.'
        })}>
          <Icon type="trash" />
          <span>Delete</span>
        </div>
      ) : (
        <ConfirmModal
          title="Delete property"
          message="Are you sure you want to delete this property? This action is permanent and cannot be reversed."
          confirmText="Delete"
          onConfirm={onDelete}
          isDanger
          dontCloseOnConfirm
          renderLink={() => (
            <div className={`${c}_actions_item`}>
              <Icon type="trash" />
              <span>Delete</span>
            </div>
          )}
        />
      )}
    </div>
  </div>
)

PropertyRow.propTypes = propTypes

export default PropertyRow
