import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import LogoBlack from '@/shared/assets/images/LogoBlack.svg'
import LogoWhite from '@/shared/assets/images/LogoWhite.svg'
import { hostLinks, guestLinks } from '@/shared/static/menuLinks'
import { isCurrentUserAuthenticated, isCurrentUserVerified } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import useHelpModalUrl from '@/features/helpModal/hooks/useHelpModalUrl'
import { Button, Tooltip, Avatar } from '@/shared/components'
import PrivateLink from '@/features/auth/components/PrivateLink'
import '@/features/appHeader/styles/desktop.scss'

const propTypes = {
  currentUser: PropTypes.object,
  renderAfterLogo: PropTypes.func,
  whiteText: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  updateMode: PropTypes.func.isRequired,
  userLinks: PropTypes.array.isRequired,
}

const defaultProps = {
  renderAfterLogo: () => null,
}

const Desktop = (props) => {
  const { toOpen } = useHelpModalUrl()
  const { toOpenSignup, toOpenSignin, toOpenConfirm } = useAuthUrl()

  return (
    <header className={classnames('appHeader', {
      'appHeader--whiteText': props.whiteText,
    })}>
      <div className="appHeader_left">
        <Logo props={props} />
        {props.renderAfterLogo()} 
      </div>

      <div className="appHeader_right">
        <div className="appHeader_link" onClick={toOpen}>Help</div>
        {isCurrentUserAuthenticated() ? (
            <SignedIn 
              mode={props.mode}
              updateMode={props.updateMode}
              currentUser={props.currentUser}
              toOpenConfirm={toOpenConfirm}
              userLinks={props.userLinks}
            />
          ) : (
            <SignedOut toOpenSignup={toOpenSignup} toOpenSignin={toOpenSignin} />
          ) 
        }
      </div>
    </header>
  )
}

const Logo = (props) => (
  <Link to="/">
    <img
      className="appHeader_logo"
      src={props.whiteText ? LogoWhite : LogoBlack}
      alt="roomsie logo"
    />
  </Link>
)

const SignedOut = ({ toOpenSignup, toOpenSignin }) => (
  <Fragment>
    <div className="appHeader_link" onClick={() => toOpenSignup()}>
      Sign Up
    </div>
    <div className="appHeader_link" onClick={() => toOpenSignin()}>
      Sign In
    </div>
    <Link to="/hosts">
      <Button hollow size="small" className="appHeader_listSpace">
        Become a Host
      </Button>
    </Link>
  </Fragment>
)

const SignedIn = ({ mode, updateMode, currentUser, toOpenConfirm, userLinks }) => (
  <Fragment>
    {isCurrentUserVerified() ? (
      mode === 'guest' ? (
        <Tooltip
          className="appHeader_dropdown"
          placement="bottom"
          renderLink={() => (
            <div className="appHeader_link">Guest</div>
          )}
          renderContent={() => (
            <Fragment>
              {guestLinks.map(({ to, text }) => (
                <Link key={to} className="appHeader_dropdown_item" to={to}>
                  {text}
                </Link>
              ))}
            </Fragment>
          )}
        />
      ) : (
        currentUser.stripeAccount ? (
          <Tooltip
            className="appHeader_dropdown"
            placement="bottom"
            renderLink={() => (
              <div className="appHeader_link">Host</div>
            )}
            renderContent={() => (
              <Fragment>
                {hostLinks.map(({ to, text }) => (
                  <Link key={to} className="appHeader_dropdown_item" to={to}>
                    {text}
                  </Link>
                ))}
              </Fragment>
            )}
          />
        ) : (
          <Tooltip
            className="appHeader_dropdown"
            placement="bottom"
            renderLink={() => (
              <PrivateLink to="/host/payment-center">
                <div className="appHeader_link">
                  Add Payout Method
                </div>
              </PrivateLink>
            )}
            renderContent={() =>(
              <Fragment>
              </Fragment>
            )}
          />
        )
      )
    ) : (
      <div className="appHeader_link" onClick={toOpenConfirm}>
        Verify Your Account
      </div>
    )}
    <Tooltip
      className="appHeader_dropdown"
      placement="bottom"
      renderLink={() => (
        <div className="appHeader_link">
          <Avatar {...currentUser} withoutLink />
        </div>
      )}
      renderContent={() => (
        <Fragment>
          {userLinks.filter((month,idx) => idx < (userLinks.length-1)).map(({ to, text }) => (
            <Link key={to} className="appHeader_dropdown_item" to={to}>
              {text}
            </Link>
          ))}
          {mode === 'guest' ? (
              <Link
                className="appHeader_dropdown_item"
                to="/host/bookings"
                onClick={() => updateMode('host')}
              >
                Switch to Host
              </Link>
          ) : (
              <Link
                className="appHeader_dropdown_item"
                to="/guest/dashboard"
                onClick={() => updateMode('guest')}
              >
                Switch to Guest
              </Link>
          )}
          {userLinks.filter((month,idx) => idx === (userLinks.length-1)).map(({ to, text }) => (
            <Link key={to} className="appHeader_dropdown_item" to={to}>
              {text}
            </Link>
          ))}
        </Fragment>
      )}
    />
    <PrivateLink to="/list-space">
      <Button hollow size="small" className="appHeader_listSpace">
        List Space
      </Button>
    </PrivateLink>
  </Fragment>
)

Desktop.propTypes = propTypes
Desktop.defaultProps = defaultProps

export default Desktop
