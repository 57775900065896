import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import options from '@/shared/static/options'
import { decimalPriceInputFilter } from '@/shared/utils/numbers'
import { Checkbox, Field, Radio, Media } from '@/shared/components'
import PrevNext from './PrevNext'
import '@/listSpace/styles/fees.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

const c = 'listSpace_fees'

class Fees extends Component {

  getFees = () => _.keyBy(this.props.property.fees, 'kind')

  handleChange = (kind, data) => {
    const fees = _.cloneDeep(this.getFees())
    fees[kind] = data ? { ...fees[kind], ...data } : null
    this.props.updateProperty({
      fees: _.values(fees).filter(f => f),
    })
    this.props.clearStepErrors(kind)
  }

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next')
    }
  }

  render() {
    return (
      <div className={`${c} listSpace_content`}>
        <h1 className="listSpace_title">Additional Fees</h1>
        <div className="listSpace_subtitle">
          You can impose additional fees on your guests. Fees will be added on top of the nightly rate.
        </div>
        <Media query="(max-width: 767px)" unmatch={() => (
          <div className={`${c}_labels`}>
            <div className="listSpace_label">Fee Type</div>
            <div className="listSpace_label">Amount</div>
            <div className="listSpace_label">Charge Per</div>
          </div>
        )} />
        {options.feeTypes.map(({ value: kind, label }) => {
          const fee = this.getFees()[kind]
          return (
            <div key={kind} className={`${c}_fee`}>
              <label className={`${c}_fee_checkbox_container`}>
                <Checkbox
                  size="small"
                  label={label}
                  value={kind}
                  checked={!!fee}
                  onChange={() => this.handleChange(kind, fee ? null : {
                    kind,
                    amount: '',
                    chargeType: 'nightly',
                  })}
                />
              </label>
              <div className={classnames(`${c}_fee_form`, {
                [`${c}_fee_form--disabled`]: !fee,
              })}>
                <div className={`${c}_fee_form_input`}>
                  <Field.Input
                    icon="dollar"
                    filter={decimalPriceInputFilter}
                    value={_.get(fee, 'amount', '')}
                    onChange={(e, value) => this.handleChange(kind, { amount: value })}
                    errors={this.props.errors[kind]}
                    disabled={!fee}
                  />
                </div>
                <Radio
                  name={`${kind}ChargeType`}
                  label="Nightly"
                  value="nightly"
                  checked={_.get(fee, 'chargeType') === 'nightly'}
                  onChange={(e, value) => this.handleChange(kind, { chargeType: value })}
                  disabled={!fee}
                />
                <Radio
                  name={`${kind}ChargeType`}
                  label="Flat"
                  value="flat"
                  checked={_.get(fee, 'chargeType') === 'flat'}
                  onChange={(e, value) => this.handleChange(kind, { chargeType: value })}
                  disabled={!fee}
                />
              </div>
            </div>
          )
        })}
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    )
  }
}

Fees.propTypes = propTypes

export default Fees
