import api from "@/shared/api"

const programSessionId = 10

export const fetchProperty = (propertyId) =>
  api.get(`/guest/properties/${propertyId}`)

export const fetchAvailability = (propertyId, query) =>
  api.get(`/guest/properties/${propertyId}/availability`, query)

export const fetchAvailabilityByBuildings = (buildingId, query) =>
  api.get(`/buildings/${buildingId}/availability`, query)

export const fetchBuildings = () => api.get(`/buildings`)

export const fetchRoomies = (propertyId, query) =>
  api.get(`/guest/properties/${propertyId}/roomies`, query)

export const fetchRates = (listingId, query) =>
  api.get(`/listings/${listingId}/bookings/calculate`, query)

export const fetchUserProperties = (userId) =>
  api.get(`/guest/users/${userId}/properties`)

export const fetchProgramSessionListingIds = (programSessionId, propertyId) =>
  api.get(
    `/guest/program_sessions/${programSessionId}/properties/${propertyId}/availability`
  )

export const fetchProgramSessionRates = (programSessionId, listingId, query) =>
  api.get(
    `/program_sessions/${programSessionId}/listings/${listingId}/bookings/calculate`,
    query
  )

export const createTour = (propertyId, payload) =>
  api.post(`/guest/properties/${propertyId}/tour_requests`, payload)

export const fetchProgramSessions = () =>
  api.get(`/program/sessions/${programSessionId}`)

export const createPluginBooking = (payload) =>
  api.post(`/plugin/bookings`, payload)
