import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import * as analytics from '@/shared/utils/analytics'
import { stringToDate } from '@/shared/utils/dateTime'
import { handleError } from '@/shared/api'
import { fetchEvents } from '@/events/api'
import queryManager from '@/shared/hocs/queryManager'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import { Head, PageSpinner, Button, Pagination } from '@/shared/components'
import EventCard from '../EventCard'
import '@/events/styles/events.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
}

class Events extends Component {

  state = {
    events: [],
    pagination: null,
    isFetching: true,
    otherEvents: null,
  }

  componentDidMount() {
    analytics.pageview('Events')
    this.fetchEvents(this.fetchOtherEvents)
  }

  componentDidUpdate(prevProps) {
    if (this.props.query.page !== prevProps.query.page) {
      window.scrollTo(0, 100)
    }
    if (this.props.query !== prevProps.query) {
      this.fetchEvents()
    }
  }

  updateQuery = query => {
    this.props.updateQuery({ page: 1, ...query })
  }

  fetchEvents = (after = () => {}) => {
    this.setState({ isFetching: true })

    fetchEvents({
      ...this.getTabQuery(),
      ...this.props.query,
    }).then(({ data, meta }) => {
      this.setState({
        events: data,
        isFetching: false,
        pagination: meta,
      }, after)
    }, handleError)
  }

  fetchOtherEvents = () => {
    fetchEvents({
      excludeCategories: this.props.currentUser.interests,
      status: 'accepted',
      page: 1,
    }).then(({ data }) => {
      this.setState({ otherEvents: data })
    }, () => {})
  }

  getTabQuery = () => {
    const { id, interests } = this.props.currentUser
    switch (this.props.query.tab) {
      case 'allEvents':
        return {
          status: 'accepted',
          categories: interests.length ? interests : ['nonexistant_category'],
        }
      case 'roomsieSocial':
        return {
          status: 'accepted',
          categories: ['roomsie_socials'],
        }
      case 'attending':
        return {
          status: 'accepted',
          attending: true,
        }
      case 'myEvents':
        return {
          userId: id,
          includePast: true,
          sort: '-created_at',
        }
    }
  }

  renderListTitle = () => {
    switch (this.props.query.tab) {
      case 'allEvents':
        return 'Events that match your interests'
      case 'roomsieSocial':
        return 'Roomsie organized events'
      case 'attending':
        return "Events you're attending"
      case 'myEvents':
        return 'Events you created'
    }
  }

  renderEmptyMessage = () => {
    switch (this.props.query.tab) {
      case 'allEvents':
      case 'roomsieSocial':
        return 'We could not find any events that overlap with your dates of stay.'
      case 'attending':
        return 'You have not decided to attend any events yet.'
      case 'myEvents':
        return 'You have not created any events yet.'
    }
  }

  renderTop = () => (
    <Fragment>
      <div className="events_top">
        <h1>Events</h1>
        <Link to="/events/create">
          <Button size="small">Create Event</Button>
        </Link>
      </div>
      <div className="events_filters_container">
        <div className="events_filters">
          {[{ value: 'allEvents', label: 'All Events' },
            { value: 'roomsieSocial', label: 'Roomsie Social' },
            { value: 'attending', label: 'Attending' },
            { value: 'myEvents', label: 'My Events' },
          ].map(({ value, label }) => (
            <div
              key={value}
              className={classnames('events_filter', {
                'events_filter--isActive': this.props.query.tab === value,
              })}
              onClick={() => this.updateQuery({ tab: value })}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )

  renderOtherEvents = () => (
    <Fragment>
      <div className="events_list_title">
        Explore all upcoming events
      </div>
      <div className="events_list">
        {this.state.otherEvents.map(this.renderEvent)}
      </div>
    </Fragment>
  )

  renderEvent = event => (
    <EventCard
      key={event.id}
      event={event}
      showStatus={this.props.query.tab === 'myEvents'}
    />
  )

  render() {
    const { events, otherEvents, pagination, isFetching } = this.state
    const { tab } = this.props.query
    return (
      <div className="events">
        <Head title="Events - Roomsie" />
        <AppHeader />
        {this.renderTop()}
        <div className={classnames('events_list_container', {
          'events_list_container--isFetching': isFetching,
        })}>
          {events.length ? (
            <Fragment>
              <div className="events_list_title">
                {this.renderListTitle()}
              </div>
              <div className="events_list">
                {events.map(this.renderEvent)}
              </div>
              {pagination.totalPages > 1 && (
                <Pagination
                  onChange={page => this.updateQuery({ page })}
                  {...pagination}
                />
              )}
            </Fragment>
          ) : (
            isFetching ? (
              <PageSpinner />
            ) : (
              (tab !== 'allEvents' || (otherEvents && !otherEvents.length)) && (
                <div className="events_list_empty">
                  {this.renderEmptyMessage()}
                </div>
              )
            )
          )}
          {tab === 'allEvents' && otherEvents && !!otherEvents.length && !isFetching && (
            this.renderOtherEvents()
          )}
        </div>
        <AppFooter />
      </div>
    )
  }
}

Events.propTypes = propTypes

Events = queryManager({
  defaultQuery: {
    page: 1,
    tab: 'allEvents',
  },
})(Events)

export default connect(mapStateToProps)(Events)

