import coordinates from '@/shared/static/coordinates'
import googleMapStyle from '@/shared/static/googleMapStyle'

export const getDefaultMapOptions = google => ({
  center: coordinates.washingtonDC.center,
  zoom: coordinates.washingtonDC.zoom,
  styles: googleMapStyle,
  fullscreenControl: false,
  clickableIcons: false,
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  streetViewControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  mapTypeControlOptions: {
    mapTypeIds: [],
  },
})
