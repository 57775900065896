import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import * as api from '@/guestBookings/api'
import { Button, ConfirmModal } from '@/shared/components'

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
}

class CancelBooking extends Component {

  handleCancel = () => {
    api.cancelBooking(this.props.booking.id).then(() => {
      this.props.fetchBooking(() => {
        showToast({ title: 'Your booking was cancelled successfully.' })
      })
    }, handleError)
  }

  render() {
    return (
      <ConfirmModal
        renderLink={() => <Button hollow color="danger">Cancel Booking</Button>}
        title="Cancel Booking"
        confirmText="Cancel Booking"
        cancelText="Close"
        confirmInput="CANCEL"
        message={
          <Fragment>
            All Roomsie
            <Link to="/cancellation-policy" target="_blank">
              {' policies, terms, and conditions '}
            </Link>
            will apply upon cancellation of this booking. Are you sure you want to cancel this booking?
          </Fragment>
        }
        isDanger
        dontCloseOnConfirm
        onConfirm={this.handleCancel}
      />
    )
  }
}

CancelBooking.propTypes = propTypes

export default CancelBooking
