import React, { Component, Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import * as api from '@/property/api'
import { formatDateRange } from '@/shared/utils/dateTime'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { Avatar } from '@/shared/components'
import '@/property/styles/roomies.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
}

const c = 'property_roomies'

const Roomies = (props) => {

  const isFirstRender = useRef(true)
  
  const { toOpen } = useProfileModalUrl()

  const [roomies, setRoomies] = useState([])

  useEffect(() => {
    fetchRoomies()
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return
    }
    fetchRoomies()
  }, [props.query.startDate, props.query.endDate ])

  const fetchRoomies = () => {
    const { startDate, endDate } = props.query
    if (startDate && endDate) {
      api.fetchRoomies(props.property.id, { startDate, endDate }).then(({ data }) => {
        setRoomies(data)
      }, () => {})
    }
  }

  const { property, query } = props
  let { user, listingType } = property

  let sortedRoomies = _.uniqBy(roomies, 'id')
  sortedRoomies = listingType === 'hosted' ? [user, ...sortedRoomies] : sortedRoomies
  return (
    <div className={c}>
      <div className={`${c}_title`}>Roomies</div>
      {query.startDate && query.endDate ? (
        sortedRoomies.length ? (
          <Fragment>
            <p className={`${c}_description`}>
              {"The following people have reservations in this property that overlap with your own dates of stay."}
            </p>
            <div className={`${c}_list`}>
              {sortedRoomies.map(roomie => (
                <Link
                  key={roomie.id}
                  className={`${c}_roomie`}
                  state={{ completeBookingCta: true }}
                >
                  <div onClick={() => toOpen(roomie.id)}>
                    <Avatar {...roomie} size={55} withoutLink />
                    <div className={`${c}_roomie_name`}>
                      {_.truncate(roomie.firstName, { length: 15 })}
                    </div>
                    <div className={`${c}_roomie_ageGender`}>
                      {`${roomie.age}, ${_.upperFirst(roomie.gender)}`}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </Fragment>
        ) : (
          <div className={`${c}_message`}>
            {`You will be the first person to book this property from `}
            <span>{formatDateRange(query.startDate, query.endDate)}</span>
          </div>
        )
      ) : (
        <div className={`${c}_message`}>
          {"Please select dates to see who you're going to be sharing the property with during your stay."}
        </div>
      )}
    </div>
  ) 
}

Roomies.propTypes = propTypes

export default Roomies
