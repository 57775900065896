import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import { sortPhotos } from '@/shared/utils/photos'
import { isElementInViewport } from '@/shared/utils/dom'
import { Photo, Switch, Slider } from '@/shared/components'
import '@/shared/styles/propertiesList.scss'

const propTypes = {
  title: PropTypes.string.isRequired,
  setLinkProps: PropTypes.func,
  fetchProperties: PropTypes.func.isRequired,
  renderNoResults: PropTypes.func,
}

const defaultProps = {
  setLinkProps: () => ({}),
  renderNoResults: () => null,
}

const c = 'propertiesList'

class PropertiesList extends Component {

  state = { properties: null }

  componentDidMount() {
    this.props.fetchProperties(properties => {
      this.setState({ properties })
    })
  }

  linkProps = property => ({
    to: `/properties/${property.id}`,
    target: '_blank',
    ...this.props.setLinkProps(property),
  })

  renderPropertiesList = properties => (
    <div className={`${c}_list`}>
      {properties.map(property => (
        <div key={property.id} className={`${c}_property`}>
          <Slider>
            {sortPhotos(property.photos).map(photo => (
              <Link key={photo.id} {...this.linkProps(property)}>
                <Photo photo={photo} lazyLoad="data-slider-image-url" />
              </Link>
            ))}
          </Slider>
          <Link {...this.linkProps(property)}>
            <div className={`${c}_property_accommodation`}>
              {`${options.labels.propertyTypes[property.propertyType]} `}
              &#183;
              {` ${options.labels.listingTypes[property.listingType]} `}
              &#183;
              {` ${options.labels.placementTypes[property.placementType]}
                 ${property.placementType === 'unisex' ? '' : ' Only'} `}
            </div>
            <div className={`${c}_property_title`}>
              {_.truncate(property.title, { length: 58 })}
            </div>
            <div className={`${c}_property_address`}>
              {`${property.neighborhood}, ${property.city}`}
            </div>
          </Link>
        </div>
      ))}
    </div>
  )

  render() {
    const { properties } = this.state
    return (
      <Fragment>
        {properties && !!properties.length && (
          <div className={c}>
            <h2>{`${this.props.title} (${properties.length})`}</h2>
            <Switch
              linkText="See all properties"
              shouldShowLink={() => properties.length > 4}
              renderDefault={() => this.renderPropertiesList(properties.slice(0, 4))}
              renderSwitch={() => this.renderPropertiesList(properties)}
            />
          </div>
        )}
        {properties && !properties.length && (
          this.props.renderNoResults()
        )}
        <div ref={el => this.$trigger = el} />
      </Fragment>
    )
  }
}

PropertiesList.propTypes = propTypes
PropertiesList.defaultProps = defaultProps

export default PropertiesList
