import superagent from 'superagent'
import humps from 'humps'
import _ from 'lodash'
import history from '@/browserHistory'
import { objectToQueryString } from '@/shared/utils/url'
import { showToast } from '@/shared/utils/toast'
import { getStoredToken } from '@/features/auth/utils'

const defaults = {
  baseURL: '/api/v1',
  headers: () => ({
    'Content-Type': 'application/json',
    'Authorization': getStoredToken() ? `Bearer ${getStoredToken()}` : undefined,
  }),
  error: {
    code: 'clientDefaultError',
    description: [
      `Something strange happened and we don't know why:`,
      '- Try refreshing the page',
      '- Try again later',
      '- Contact us and tell us what happened',
    ],
  },
}

const api = {}
const currentRequests = {}

_.each(['get', 'post', 'put', 'patch', 'delete'], method => {

  api[method] = (url, params = {}, { abortCurrentRequest } = {}, signoutUser = true) => (
    new Promise((resolve, reject) => {

      const request = superagent[method](`${defaults.baseURL}${url}`)

      request.set(defaults.headers())

      params = humps.decamelizeKeys(params)

      if (method === 'get') {
        params = objectToQueryString(params)
      }

      request[method === 'get' ? 'query' : 'send'](params)

      request.end((err, res) => {
        const body = humps.camelizeKeys((res || {}).body)

        let error = null
        if (err && body && body.error) {
          error = body.error
        } else if (err && !body) {
          error = defaults.error
        } else if (err) {
          error = {}
        }
        if (error) {
          if (error.code === 'token_invalid') {
            if(signoutUser) {
              history.push('/signout')
            }
            return reject ({ res, body, error: {} })
          }
          return reject ({ res, body, error })
        }
        return resolve({
          res,
          body,
          data: _.get(body, 'data'),
          meta: _.get(body, 'meta'),
        })
      })

      if (abortCurrentRequest) {
        const id = abortCurrentRequest
        if (currentRequests[id]) {
          currentRequests[id].abort()
        }
        currentRequests[id] = request
      }
    })
  )
})

export default api

export const handleError = ({ error }) => {
  if (!error && !error.description) return
  let message = ''
  if (typeof error.description === 'string'){
    message = error.description
  } else {
    let errorMessages = Object.keys(error.description).map(key => (
      `${camelToSnakeCase(key)}: ${error.description[key]}`
    ))
    message = errorMessages.join('. ')
   }
   showToast({
    type: 'danger',
    title: 'Error',
    message: message,
    duration: 10,
  })  
}

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => ` ${letter}`).replace(/(\b[a-z](?!\s))/g, letter => `${letter.toUpperCase()}`);
