import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isCurrentUserVerified, isCurrentUserAuthenticated } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'

const propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const PrivateLink = ({ className, to, children }) => {
  const { toOpenSignup, toOpenConfirm, storeAfterAuthUrl } = useAuthUrl()

  if (isCurrentUserVerified()) {
    return <Link  className={className} to={to}>{children}</Link>
  } else if (isCurrentUserAuthenticated()) {
    return (
      <span  className={className} onClick={() => {
        storeAfterAuthUrl(to)
        toOpenConfirm()
      }}>
        {children}
      </span>
    )
  } else {
    return (
      <span  className={className} onClick={() => {
        storeAfterAuthUrl(to)
        toOpenSignup()
      }}>
        {children}
      </span>
    )
  }
}

PrivateLink.propTypes = propTypes

export default PrivateLink
