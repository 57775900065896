import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { formatAddress } from '@/shared/utils/geo'
import { sortPhotos } from '@/shared/utils/photos'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import { fetchProgram, fetchProperties, updateProperty } from '@/hostPrograms/api'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import { DashboardMenu, PageSpinner, Head, Photo, Modal, Icon,
  Checkbox, Spinner, Select, AssignProperties } from '@/shared/components'
import ProgramCard from './ProgramCard'
import ToggleBeds from './ToggleBeds'
import '@/hostPrograms/styles/assign.scss'

const c = 'hostProgramsAssign'

const Assign = () => {
  
  const { programId } = useParams()
  const isFirstRender = useRef(true)

  const [state, setState] = useState({
    program: null,
    properties: null,
    assignedProperties: [],
  })

  useEffect(() => {
    analytics.pageview('Host Assign Programs')

    fetchProgram(programId).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        program: data
      }))
    }, handleError)

  }, [])


  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    fetchHostProperties()
  }, [state.program])
  
  const isPropertyAssignedSessionsFromThisProgram = property => {
    const sessionIds = state.program.sessions.map(s => s.id)
    return property.programSessionIds.some(sid => sessionIds.includes(sid))
  }

  const fetchHostProperties = () => {
    fetchProperties().then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        properties: data,
        assignedProperties: data.filter(isPropertyAssignedSessionsFromThisProgram)
      }))
    }, handleError)
  }

  const handleUpdateProperties = properties => {
    const removedProperties = _.differenceBy(state.assignedProperties, properties, 'id')

    if (removedProperties.some(isPropertyAssignedSessionsFromThisProgram)) {
      showToast({
        type: 'danger',
        title: 'You can not remove properties that have partnership sessions assigned to them.'
      })
    }
    if (removedProperties.length) {
      properties = state.assignedProperties.filter(property => (
        !removedProperties.find(({ id }) => id === property.id) ||
        isPropertyAssignedSessionsFromThisProgram(property)
      ))
    }
    setState(prevState => ({
      ...prevState,
      assignedProperties: properties
    }))
  }

  const handleAssignSession = (property, session) => {
    setState(prevState => ({
      ...prevState,
      [`${property.id}${session.id}isAssigning`]: true
    }))

    const programSessionIds = property.programSessionIds.includes(session.id) ? (
      property.programSessionIds.filter(id => id !== session.id)
    ) : (
      [...property.programSessionIds, session.id]
    )
    updateProperty(property.id, { programSessionIds }).then(() => {
      setState(prevState => ({
        ...prevState,
        assignedProperties: state.assignedProperties.map(p => (
          p.id === property.id ? { ...p, programSessionIds } : p
        )),
        [`${property.id}${session.id}isAssigning`]: false,
      }))
    }, handleError)
  }

  const handleUnassignProperty = propertyId => setState(prevState => ({
    ...prevState,
    assignedProperties: state.assignedProperties.filter(p => p.id !== propertyId)
  }))

  const { program, properties, assignedProperties } = state

  return (
    <div className={c}>
      <Head title="Partner Assign - Roomsie" />
      <AppHeader />
      <DashboardMenu role="host" />
      <div className={`${c}_page`}>
        {program ? (
          <Fragment>
            <div className={`${c}_breadcrumbs`}>
              <Link to="/host/partners">Partners</Link>
              {' / Assign Properties'}
            </div>
            <h1>Assign Properties</h1>
            <ProgramCard program={program} />
            <div className={`${c}_properties_title`}>
              <Icon type="home" />
              Properties
            </div>
            <div className={`${c}_properties_description`}>
              Select the properties you'd like to include in this partnership.
            </div>
            {properties ? (
              <AssignProperties
                allProperties={properties}
                selectedProperties={assignedProperties}
                onChange={handleUpdateProperties}
              />
            ) : (
              <Spinner size={50} />
            )}
            {assignedProperties.map(property => (
              <div key={property.id} className={`${c}_property`}>
                {!program.sessions.some(s => property.programSessionIds.includes(s.id)) && (
                  <Icon type="trash" onClick={() => handleUnassignProperty(property.id)} />
                )}
                <Photo photo={sortPhotos(property.photos)[0]} />
                <div className={`${c}_property_mid`}>
                  <div className={`${c}_property_title`}>
                    {property.title}
                  </div>
                  <div className={`${c}_property_address`}>
                    {formatAddress(property, ['zip', 'state'])}
                  </div>
                  {property.listingType !== 'entire_space' && (
                    <Modal
                      className={`${c}_toggleBeds`}
                      renderLink={() => (
                        <div className={`${c}_property_toggleBedsLink`}>
                          Manage Beds
                        </div>
                      )}
                      renderContent={() => (
                        <ToggleBeds propertyId={property.id} programId={program.id} />
                      )}
                    />
                  )}
                </div>
                <div className={`${c}_property_sessions`}>
                  {program.sessions.sort((a, b) => a.id - b.id).map(session => (
                    <div className={`${c}_property_session`} key={session.id}>
                      <Checkbox
                        size="small"
                        value={session.id}
                        label={session.name}
                        checked={property.programSessionIds.includes(session.id)}
                        onChange={() => handleAssignSession(property, session)}
                      />
                      {state[`${property.id}${session.id}isAssigning`] && <Spinner size={30} />}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Fragment>
        ) : (
          <PageSpinner />
        )}
      </div>
      <AppFooter />
    </div>
  )
}

export default Assign
