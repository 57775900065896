import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import options from '@/shared/static/options'
import { formatPrice } from '@/shared/utils/numbers'
import { stringToDate, getTimeDifference, formatDateRange } from '@/shared/utils/dateTime'
import { sortPhotos } from '@/shared/utils/photos'
import { getBedsWithDisplayId } from '@/shared/utils/beds'
import { Slider, Icon, Button, Switch, Modal, Photo } from '@/shared/components'
import Lightbox from './Lightbox'
import '@/property/styles/bedrooms.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  selectedDatesListings: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
}

const c = 'property_bedrooms'
export const bedroomsClassname = c

class Bedrooms extends Component {

  state = {}

  handleSelectListing = listingId => {
    if (listingId) {
      this.props.trackEvent('Property Bedroom Selected', { listingId })
    } else {
      this.props.trackEvent('Property Bedroom Deselected')
    }
    this.props.updateQuery({ selectedListingId: listingId })
  }

  handleResetDates = () => {
    this.props.updateQuery({ startDate: null, endDate: null })
  }

  isRoomUnavailable = room => (
    room.beds.every(this.isBedUnavailable)
  )

  isBedUnavailable = bed => (
    !this.props.selectedDatesListings[bed.listing.id]
  )

  formattedDates = () => formatDateRange(
    this.props.query.startDate, this.props.query.endDate
  )

  getSessionDuration = () => {
    const session = this.props.property?.sessions[0]
    const start = stringToDate(session?.startDate)
    const end = stringToDate(session?.endDate)

    return getTimeDifference(end, start, 'days')
  }

  getListingRentalTotal = (bed) => Math.ceil(this.props.selectedDatesListings[bed.listing.id]?.minRate * parseInt(this.getSessionDuration()))

  renderBedrooms = bedrooms => (
    _.sortBy(_.sortBy(bedrooms, 'id'), this.isRoomUnavailable).map(room => (
      <div key={room.id} className={`${c}_room`}>
        <Lightbox
          photos={room.photos}
          getOpenFunc={func => this[`${room.id}openLightbox`] = func}
        />
        <Slider>
          {sortPhotos(room.photos).map((photo, i) => (
            <span key={i} onClick={() => this[`${room.id}openLightbox`](i)}>
              <Photo photo={photo} lazyLoad="data-slider-image-url" />
            </span>
          ))}
        </Slider>
        <div className={`${c}_room_content`}>
          {this.renderRoomTitleAndMeta(room)}

          <p className={`${c}_room_description`}>
            {_.truncate(room.description, { length: 220 })}
            {room.description.length > 220 && this.renderRoomModal(room)}
          </p>
        </div>
        {this.renderBeds(room)}
      </div>
    ))
  )

  renderRoomModal = room => (
    <Modal
      className={`${c}_room_description_modal`}
      renderLink={() => (
        <span className={`${c}_room_description_moreLink`}>Read more</span>
      )}
      renderContent={() => (
        <Fragment>
          {this.renderRoomTitleAndMeta(room)}
          <p className={`${c}_room_description`}>{room.description}</p>
        </Fragment>
      )}
    />
  )

  renderRoomTitleAndMeta = room => (
    <Fragment>
      <h3 className={`${c}_room_title`}>{room.title}</h3>
      <div className={`${c}_room_meta`}>
        <Icon type="bed" />
        {`${options.labels.bedsCount[room.bedsCount]}`}
        <Icon type="bath" />
        {`${room.bathroomAttached ? 'Attached' : 'Shared'} Bath`}
      </div>
    </Fragment>
  )

  renderBeds = room => {
    const isSelected = bed => (
      this.props.query.selectedListingId === bed.listing.id
    )
    return (
      <div>
        {getBedsWithDisplayId(room.beds).map(bed => (
          <div key={bed.id} className={`${c}_bed`}>
            <div className={`${c}_bed_id`}>{bed.displayId}</div>
            <div className={`${c}_bed_size`}>
              {'Bed Size: '}
              <span>{options.labels.bedSizes[bed.size]}</span>
            </div>
            <div className={`${c}_bed_type`}>
              {'Type: '}
              <span>{options.labels.bedTypes[bed.kind]}</span>
            </div>
            {!this.isBedUnavailable(bed) ? (
              <Fragment>
                <Button
                  className={isSelected(bed) ? `${c}_bed_button--isSelected` : ''}
                  size="small"
                  onClick={() => this.handleSelectListing(isSelected(bed) ? null : bed.listing.id)}
                >
                  {isSelected(bed) ? 'Selected' : 'Select'}
                </Button>
                {!this.props.query.programSessionId && (
                  <div className={`${c}_bed_price`}>
                    {!!this.props.property?.prorateForShortStay &&
                      ( 
                        <div>
                          {'From '}
                          <span>{formatPrice(
                            this.props.selectedDatesListings[bed.listing.id].minRate * 30
                          )}</span>
                          {` per month`}
                        </div> 
                      )
                    }
                  </div>
                )}
              </Fragment>
            ) : (
              <div className={`${c}_bed_unavailableLabel`}>
                Not Available
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { property, query, updateQuery } = this.props
    return (
      <div className={c}>
        <div className={`${c}_top`}>
          <h2 className={`${c}_title`}>Select Your Bed</h2>
          {!query.programSessionId && (
            <p className={`${c}_subtitle`}>
              {`Showing available beds for ${this.formattedDates() || 'all dates'}.`}
              {(query.startDate || query.endDate) && (
                <span onClick={() => updateQuery({ startDate: null, endDate: null })}>
                  Reset Dates
                </span>
              )}
            </p>
          )}
        </div>
        {!!property.bedrooms.length ? (
          this.renderBedrooms(property.bedrooms)
        ) : (
          <div className={`${c}_noResults`}>
            We could not find any available beds.
          </div>
        )}
      </div>
    )
  }
}

Bedrooms.propTypes = propTypes

export default Bedrooms
