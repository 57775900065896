import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import options from '@/shared/static/options'
import usStates from '@/shared/static/usStates'
import { countryNames } from '@/shared/static/countries'
import { handleError } from '@/shared/api'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { fetchCurrentUser, updateCurrentUser } from '@/features/auth/state/actions'
import { deleteCurrentUser } from '@/profile/api'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { Form, Spinner, Button, Field, Checkbox, CheckboxGroup, FieldLabel,
  BirthDateSelects, Modal, Head, ConfirmModal, AvatarUploader } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import Email from '@/features/auth/components/confirm/Email'
import PhoneNumber from '@/features/auth/components/confirm/PhoneNumber'
import '@/profile/styles/profileEdit.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { fetchCurrentUser, updateCurrentUser }

const propTypes = {
  currentUser: PropTypes.object,
  updateCurrentUser: PropTypes.func.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
}

const c = 'profileEdit'

const ProfileEdit = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    analytics.pageview('User Profile Edit')
    props.fetchCurrentUser()
  }, [])
  
  const deleteAccount = () => deleteCurrentUser().then(ha => {
    navigate('/signout')
    showToast({ title: 'Your account has been deleted successfully.' })
  })

  const { currentUser } = props
  return (
    <div className={c}>
      <Head title="Edit Profile - Roomsie" url={location.pathname} />
      <AppHeader />
      <div className={`${c}_page`}>
        <div className={`${c}_page_left`}>
          <Avatar currentUser={currentUser} {...props} />
        </div>
        <div className={`${c}_page_right`}>
          <UserForm currentUser={currentUser} {...props} />
          <Contact currentUser={currentUser} />
          <More currentUser={currentUser} deleteAccount={deleteAccount} />
        </div>
      </div>
      <AppFooter />
    </div>
  )
}

const Avatar = ({ currentUser, updateCurrentUser }) => {

  const { toOpen } = useProfileModalUrl()

  return (
    <div className={`${c}_avatar`}>
      <div className={`${c}_avatar_title`}>Edit profile photo</div>
      <AvatarUploader
        currentUser={currentUser}
        onChange={avatar => updateCurrentUser({ avatar })}
      />
      <Button hollow className={`${c}_avatar_profileLink`}onClick={() => toOpen(currentUser.id)}>View Profile</Button>
    </div>
  )
}

const UserForm = ({ currentUser, updateCurrentUser }) => {
  const get = key => currentUser[key] || ''
  return (
    <Form
      enableReinitialize
      initialValues={{
        firstName: get('firstName'),
        lastName: get('lastName'),
        gender: get('gender'),
        birthDate: get('birthDate'),
        city: get('city'),
        state: get('state'),
        country: get('country'),
        school: get('school'),
        schoolYear: get('schoolYear'),
        major: get('major'),
        internshipEmployer: get('internshipEmployer'),
        instagramUrl: get('instagramUrl'),
        facebookUrl: get('facebookUrl'),
        bio: get('bio'),
        interests: currentUser.interests || [],
      }}
      validations={{
        firstName: Form.is.required(),
        lastName: Form.is.required(),
        birthDate: Form.is.required(),
      }}
      onSubmit={(values, form) => {
        updateCurrentUser(values).then(() => {
          showToast({
            title: 'Your profile was updated successfully.',
          })
          form.setSubmitting(false)
        }, ({ error }) => {
          if (error.details) {
            form.setErrors(error.details)
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit} className={`${c}_mainForm`}>
          <h1 className={`${c}_title`}>Edit Profile</h1>
          <p className={`${c}_subtitle`}>
            We will never sell your personal information. The following information will only be shared with people you choose to interact with via Roomsie.
          </p>
          <div className={`${c}_fields`}>
            <Field.Input {...fields.firstName} label="First Name" />
            <div>
              <Field.Input {...fields.lastName} label="Last Name" />
              <div className={`${c}_field_subtitle`}>
                Your public profile won't show last name.
              </div>
            </div>
            <Field.Select
              {...fields.gender}
              label="Gender"
              placeholder="Select"
              options={[
                { label: 'Female', value: 'female' },
                { label: 'Male', value: 'male' },
              ]}
            />
            <Field.Input {...fields.city} label="City" />
            <Field.Select
              {...fields.state}
              label="State"
              options={[{ value: '', label: 'Select' }, ...usStates]}
            />
            <Field.Select
              {...fields.country}
              label="Country"
              options={[{ value: '', label: 'Select' }, ...countryNames]}
            />
            <BirthDateSelects
              label="Date of Birth"
              value={fields.birthDate.value}
              onChange={value => setFieldValue('birthDate', value)}
              errors={fields.birthDate.errors}
            />
          </div>
          <div className={`${c}_bio`}>
            <Field.Textarea {...fields.bio} label="About Me (Bio)" minRows={4} />
            <p className={`${c}_field_subtitle`}>
              Bio helps people learn a bit about you. Tell them about the things you like, your values, what it’s like to have you as a guest or host.
            </p>
          </div>
          <div className={`${c}_divider`} />
          <h2 className={`${c}_title_small`}>Social Media</h2>
          <p className={`${c}_subtitle`}>
            The links to your social media accounts will only be visible to your fellow roommates.
          </p>
          <div className={`${c}_fields`}>
            <Field.Input
              {...fields.instagramUrl}
              label="Instagram URL"
              placeholder="https://www.instagram.com/you"
            />
            <Field.Input
              {...fields.facebookUrl}
              label="Facebook URL"
              placeholder="https://www.facebook.com/you"
            />
          </div>
          <div className={`${c}_divider`} style={{ marginTop: 10 }} />
          <h2 className={`${c}_title_small`}>Interests</h2>
          <p className={`${c}_subtitle`}>
            We use this info to better match you with roommates, hosts, and groups.
          </p>
          <CheckboxGroup
            value={fields.interests.value}
            onChange={interests => (
              setFieldValue('interests', _.uniq([...interests, 'roomsie_socials']))
            )}
            render={({ isChecked, onCheck }) => (
              options.interests.map(interest => (
                <div key={interest.value}>
                  <Checkbox
                    size="small"
                    value={interest.value}
                    label={interest.label}
                    checked={isChecked(interest.value)}
                    onChange={onCheck}
                  />
                </div>
              ))
            )}
          />
          <div className={`${c}_divider`} />
          <h2 className={`${c}_title_small`}>Guest</h2>
          <p className={`${c}_subtitle`}>
            Please provide the following information if you're a guest.
          </p>
          <div className={`${c}_fields`}>
            <Field.Input {...fields.school} label="School/University Name" />
            <Field.Select
              {...fields.schoolYear}
              label="School Year"
              placeholder="Select"
              options={[
                'Freshman',
                'Sophomore',
                'Junior',
                'Senior',
                'Graduate Student',
                'Graduated',
              ].map(value => ({ value, label: value }))}
            />
            <Field.Input {...fields.major} label="Major Field of Study" />
            <Field.Input {...fields.internshipEmployer} label="Internship/Employer" />
          </div>
          <Button type="submit" working={isSubmitting} className={`${c}_submit`}>
            Save
          </Button>
        </form>
      )}
    />
  )
}

const Contact = ({ currentUser }) => (
  <div className={`${c}_contact`}>
    <h2 className={`${c}_title_small`}>Contact</h2>
    <p className={`${c}_subtitle`}>
      You will have to confirm your email or phone number after modifying them.
    </p>
    <div className={`${c}_fields`}>
      <Modal
        containsForm
        className="authConfirm"
        renderLink={() => (
          <Field.Input label="Email" icon="email" value={currentUser.email || ''} />
        )}
        renderContent={({ closeModal }) => (
          <Email onSuccess={closeModal} isChangeEmailDefault />
        )}
      />
      <Modal
        containsForm
        className="authConfirm"
        renderLink={() => (
          <Field.Input label="Phone Number" icon="phone" value={currentUser.phoneNumber || ''} />
        )}
        renderContent={({ closeModal }) => (
          <PhoneNumber onSuccess={closeModal} />
        )}
      />
    </div>
  </div>
)

const More = ({ deleteAccount }) => (
  <div className={`${c}_more`}>
    <Link to="/subscriptions">
      <div className={`${c}_more_link`}>Update subscription settings</div>
    </Link>
    <ConfirmModal
      renderLink={() => (
        <div className={`${c}_more_link`}>Delete your account</div>
      )}
      title="Delete your account"
      confirmText="Delete Account"
      confirmInput="DELETE MY ACCOUNT"
      message="Are you sure you want to delete your account? All your data will be lost. This action is permanent and cannot be reversed."
      isDanger
      dontCloseOnConfirm
      onConfirm={deleteAccount}
    />
  </div>
)

ProfileEdit.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit)
