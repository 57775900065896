import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import colors from '@/shared/static/colors'
import { Spinner, Icon } from './'
import '@/shared/styles/button.scss'

const propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  hollow: PropTypes.bool,
  square: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'danger', 'secondary']),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  working: PropTypes.bool,
  onClick: PropTypes.func,
}

const Button = ({
  children,
  size,
  hollow,
  square,
  color,
  icon,
  className,
  disabled,
  working,
  onClick = () => {},
  ...buttonProps
}) => (
  <button
    {...buttonProps}
    onClick={() => {
      !disabled && !working && onClick()
    }}
    disabled={disabled || working}
    className={classnames(
      'button', {
        [`button--size-${size}`]: size,
        [`button--color-${color}`]: color,
        'button--disabled': disabled || working,
        'button--hollow': hollow,
        'button--square': square,
        'button--icon-only': icon && !children,
      },
      className
    )}
  >
    {working && (
      <Spinner size={30} color={hollow ? colors.text : '#fff'} />
    )}
    {!working && icon && (
      _.isString(icon) ? <Icon type={icon} color={hollow ? colors.text : '#fff'} /> : icon
    )}
    {children}
  </button>
)

Button.propTypes = propTypes

export default Button
