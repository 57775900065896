import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import options from '@/shared/static/options';
import { Icon, Button } from '@/shared/components';
import { FinalizationInfo, EditInfo } from './StaticInfo';
import Details from './Details';
import '@/plugin/property/styles/confirmation.scss';

const propTypes = {
  query: PropTypes.object.isRequired,
  rates: PropTypes.object,
  sessions: PropTypes.array,
  userInput: PropTypes.object.isRequired,
  selectedBedroom: PropTypes.object,
  resetFormState: PropTypes.func,
};

const c = 'plugin_confirmation';

const Confirmation = (props) => {

  const navigate = useNavigate()

  useEffect(() => {
    props.resetFormState()
    return () => navigate(({
      search: '',
    }))
  }, [])
  
  const matchValueWithLabel = (array) => {
    if (!array.length) return 'N/A';
    let interestsArray = [];
    array.forEach((interestValue) => {
      options.interests.filter((obj) => {
        if (obj.value === interestValue) {
          interestsArray.push(obj.label);
        }
      });
    });
    return interestsArray.join(', ');
  };

  const { userInput, query, rates, selectedBedroom, sessions } = props;

  return (
    <div className={`${c}`}>
      <Details
        selectedBedroom={selectedBedroom}
        rates={rates}
        sessions={sessions}
        userInput={userInput}
        query={query}
        label="Paid Today:"
      />
      <FinalizationInfo />
      <StudentInformation userInfo={userInput} matchValueWithLabel={matchValueWithLabel} />
      <WhatsNext/>
      <Buttons />
    </div>
  );
}

const ValueNotAvailable = () => (
  <span className={`${c}_studentInformation_value`}>N/A</span>
);

const PrintButton = () => (
  <div className="print_button">
    <Icon type="printer" size={51} />
    <span className="print_button_text">Print Copy</span>
  </div>
);

const RoomSelection = ({ roomDetails, dueToday }) => (
  <div className={`${c}_roomSelection`}>
    <div className={`${c}_roomSelection_header`}>
      <h2>Your booking request was successfully submitted!</h2>
    </div>
    <div className={`${c}_roomSelection_container`}>
      <div className={`${c}_roomSelection_content`}>
        <div className={`${c}_roomSelection_roomDetails`}>
          {Object.keys(roomDetails).map((keyName, index) => (
            <div
              key={index}
              className={`${c}_roomSelection_roomDetails_container`}
            >
              <div className={`${c}_roomSelection_roomDetails_label`}>
                {roomDetails[keyName].label}:
              </div>
              <div className={`${c}_roomSelection_roomDetails_value`}>
                {roomDetails[keyName].value}
              </div>
            </div>
          ))}
        </div>
        <PrintButton />
      </div>
      <div className={`${c}_roomSelection_dueToday`}>
        <div className={`${c}_roomSelection_dueToday_label`}>Paid Today:</div>
        <div className={`${c}_roomSelection_dueToday_value`}>
          {dueToday === '$NaN' ? 'N/A' : dueToday}
        </div>
      </div>
    </div>
  </div>
);

const StudentInformation = ({ userInfo, matchValueWithLabel }) => (
  <div className={`${c}_studentInformation`}>
    <div className={`${c}_studentInformation_header`}>
      <h2>Student Information</h2>
    </div>
    <div className={`${c}_studentInformation_content`}>
      <h3>Roomsie Profile Details</h3>
      {/* first column */}
      <div className={`${c}_studentInformation_row`}>
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>
              First Name:
            </span>
            <span className={`${c}_studentInformation_value`}>
              {userInfo.firstName}
            </span>
          </div>
        </div>
        {/* second column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>
              Last Name:
            </span>
            <span className={`${c}_studentInformation_value`}>
              {userInfo.lastName}
            </span>
          </div>
        </div>
      </div>

      <h3>Contact Information:</h3>
      <div className={`${c}_studentInformation_row`}>
        {/* first column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>Email:</span>
            <span className={`${c}_studentInformation_value`}>
              {userInfo.email}
            </span>
          </div>
        </div>
        {/* second column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>Mobile:</span>
            <span className={`${c}_studentInformation_value`}>
              {userInfo.phone}
            </span>
          </div>
        </div>
      </div>

      <h3>Social Media:</h3>
      <div className={`${c}_studentInformation_row`}>
        {/* first column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>Facebook:</span>
            {!userInfo.facebookUrl.length ? (
              <ValueNotAvailable />
            ) : (
              <a
                href={`//${userInfo.facebookUrl}`}
                className={`${c}_studentInformation_value`}
                target="_blank"
              >
                {userInfo.facebookUrl}
              </a>
            )}
          </div>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>
              Instagram:
            </span>
            {!userInfo.instagramUrl.length ? (
              <ValueNotAvailable />
            ) : (
              <a
                href={`//${userInfo.instagramUrl}`}
                className={`${c}_studentInformation_value`}
                target="_blank"
              >
                {userInfo.instagramUrl}
              </a>
            )}
          </div>
        </div>
      </div>

      <h3>Mailing Address:</h3>
      <div className={`${c}_studentInformation_row`}>
        {/* first column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>City:</span>
            <span className={`${c}_studentInformation_value`}>
              {!userInfo.city.length
                ? <ValueNotAvailable />
                : userInfo.city}
            </span>
          </div>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>Zip Code:</span>
            <span className={`${c}_studentInformation_value`}>
              {/* TODO zip code? */}
              <ValueNotAvailable />
            </span>
          </div>
        </div>
        {/* second column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>State:</span>
            <span className={`${c}_studentInformation_value`}>
              {!userInfo.usState.length
                ? <ValueNotAvailable />
                : userInfo.usState}
            </span>
          </div>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>Country:</span>
            <span className={`${c}_studentInformation_value`}>
              {!userInfo.country.length
                ? <ValueNotAvailable />
                : userInfo.country}
            </span>
          </div>
        </div>
      </div>

      <h3>Academic Details:</h3>
      <div className={`${c}_studentInformation_row`}>
        {/* first column */}
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>
              University/School:
            </span>
            <span className={`${c}_studentInformation_value`}>
              {!userInfo.school.length
                ? <ValueNotAvailable />
                : userInfo.school}
            </span>
          </div>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>Status:</span>
            <span className={`${c}_studentInformation_value`}>
              {!userInfo.studentType.length
                ? <ValueNotAvailable />
                : userInfo.studentType}
            </span>
          </div>
          <div className={`${c}_studentInformation_column_item`}>
            <span className={`${c}_studentInformation_label`}>
              Internship:
            </span>
            <span className={`${c}_studentInformation_value`}>
              {/* TODO internship? */}
              <ValueNotAvailable />
            </span>
          </div>
        </div>
      </div>

      <h3>Biography:</h3>
      <div className={`${c}_studentInformation_row`}>
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            <div className={`${c}_studentInformation_column_item_bio`}>
              {!userInfo.bio.length
                ? <ValueNotAvailable />
                : userInfo.bio}
            </div>
          </div>
        </div>
      </div>

      <h3>Areas of Interest:</h3>
      <div className={`${c}_studentInformation_row`}>
        <div className={`${c}_studentInformation_column`}>
          <div className={`${c}_studentInformation_column_item`}>
            {!userInfo.interests.length
              ? <ValueNotAvailable />
              : matchValueWithLabel(userInfo.interests)}
          </div>
        </div>
      </div>
    </div>
    <EditInfo />
  </div>
);

const WhatsNext = () => (
  <div className={`${c}_whatsNext`}>
    <h2>What's Next?</h2>
    <p>
      Login to the email account above to find your 'Welcome to Roomsie'
      packet and follow the instructions to complete your registration.
      Monitor the status of your booking request, connect with your Roomies or
      submit cancellation of your stay through your Roomsie account. All email
      correspondence and messaging will be sent via Roomsie from this point
      forward.
    </p>
  </div>
);

const Buttons = () => (
  <div className={`${c}_buttonRow`}>
    <Button
      className={`${c}_grayBtn`}
      type="button"
      color="secondary"
      onClick={() => window.print()}
    >
      Print Copy
    </Button>
    <Button
      className={`${c}_redBtn`}
      type="button"
      color="primary"
      onClick={() => window.open('//roomsie.zendesk.com', '_blank')}
    >
      Visit Our FAQ
    </Button>
  </div>
);

Confirmation.propTypes = propTypes;

export default Confirmation;
