import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import * as url from '@/shared/utils/url'

export default function useHelpModalUrl() {
  const location = useLocation()
  const navigate = useNavigate()

  const toOpen = () => navigate({
    pathname: location.pathname,
    search: url.addToQueryString(location.search, { help_contact: true })
  })
  
  const toClose = key => navigate({
    pathname: location.pathname,
    search: url.omitFromQueryString(location.search, 'help_contact')
  })
  
  const isOpen = key => (
    !!url.queryStringToObject(location.search).help_contact
  )
  
  return { 
    toOpen,
    toClose,
    isOpen
  }
}



