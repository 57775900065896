import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { Avatar, Modal } from '@/shared/components'
import '@/shared/styles/usersList.scss'

const propTypes = {
  title: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
}

const UsersList = (props) => {

  const { title, users } = props

  return (
    <div className="usersList">
      <div className="usersList_title">{title}</div>
      <div className="usersList_users">
        {users.slice(0, 7).map((user) => <User user={user} size={56} />)}
        {users.length > 7 && (
          <Modal
            className="usersList_modal"
            linkClassName="usersList_all"
            renderLink={() => (
              <Fragment>
                <div className="usersList_all_count">
                  {`+${users.length - 7}`}
                </div>
                <span>View All</span>
              </Fragment>
            )}
            renderContent={({ closeModal }) => (
              <Fragment>
                <div className="usersList_modal_title">
                  {`${users.length} Attending`}
                </div>
                {users.map((user) => <User user={user} size={46} />)}
              </Fragment>
            )}
          />
        )}
      </div>
    </div>
  )
}

const User = ({ size, user }) => {
  const { toOpen } = useProfileModalUrl()

  return (
    <div key={user.id} className="usersList_user" onClick={() => toOpen(user.id)}>
      <Avatar {...user} size={size} withoutLink />
      <div className="usersList_user_name">
        {user.firstName}
      </div>
    </div>
  )
}

UsersList.propTypes = propTypes

export default UsersList
