import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { updateQuery } from '@/search/state/actions'
import { Tooltip, Icon, Button, Radio, Media } from '@/shared/components'

const mapStateToProps = ({ search }) => ({
  sort: search.query.sort,
})

const mapDispatchToProps = { updateQuery }

const propTypes = {
  sort: PropTypes.string,
  updateQuery: PropTypes.func.isRequired,
}

const Sort = props => {

  const labels = {
    'min_rate': 'Price: Low to High',
    '-min_rate': 'Price: High to Low',
  }
  const shortLabels = {
    'min_rate': 'Price: Low',
    '-min_rate': 'Price: High',
  }
  return (
    <Tooltip
      className="search_filters_tooltip search_filters_sort"
      placement="bottom"
      offset={{ top: -9 }}
      renderLink={() => (
        <Media query="(max-width: 767px)"
          match={() => (
            <div className="search_filters_link">
              <Icon type="sort" />
              {shortLabels[props.sort] || 'Sort Results'}
            </div>
          )}
          unmatch={() => (
            <Button hollow size="small" color="secondary" className="search_filters_sort_link">
              {shortLabels[props.sort] || 'Sort Results'}
            </Button>
          )}
        />
      )}
      renderContent={({ closeTooltip }) => (
        <Fragment>
          {Object.keys(labels).map(value => (
            <Radio
              key={value}
              value={value}
              label={labels[value]}
              checked={props.sort === value}
              onClick={() => {
                props.updateQuery({ sort: props.sort === value ? null : value })
                closeTooltip()
              }}
            />
          ))}
        </Fragment>
      )}
    />
  )
}

Sort.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Sort)
