import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxThunk from 'redux-thunk'
import rootReducer from './rootReducer'

const middleware = applyMiddleware(...[reduxThunk])

const store = createStore(rootReducer, composeWithDevTools(middleware))

export default store
