import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { scrollToElement } from '@/shared/utils/dom'
import { locationMapClassname } from '../Location'
import { bedroomsClassname } from '../Bedrooms'
import { Button, Icon, Media, Modal } from '@/shared/components'
import Dates from './Dates'
import Rates from './Rates'
import Program from './Program'
import Roomies from '../Roomies'
import '@/property/styles/bookingForm.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  allDatesListings: PropTypes.object.isRequired,
  selectedDatesListings: PropTypes.object.isRequired,
  selectedListing: PropTypes.any,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  isEntireSpace: PropTypes.bool.isRequired,
  trackEvent: PropTypes.func.isRequired,
}

const c = 'property_booking'

const BookingForm = (props) => {
  const { property, allDatesListings, selectedDatesListings, selectedListing, query, updateQuery, isEntireSpace, trackEvent } = props

  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false)
  const [sessionDates, setSessionDates] = useState([])

  const bookingContainerRef = useRef(null)
  const formRef = useRef(null)

  useEffect(() => {
    setSessionDates(getSessionDates(property?.sessions))
    setStickyBookingForm()
    window.addEventListener('scroll', setStickyBookingForm)
  
    return () => {
      window.removeEventListener('scroll', setStickyBookingForm)
    }
  }, [])

  const getSessionDates = (sessions) => {
    const sessionGroups = _.groupBy(sessions, 'startDate')
    let sessionDates = []
    for (const property in sessionGroups){
      let session = { startDate: null, endDate: null }
      session.startDate = sessionGroups[property][0].startDate
      session.endDate = sessionGroups[property][0].endDate
      sessionDates.push(session)
    }
    return sessionDates
  }

  const setStickyBookingForm = () => {
    if (!bookingContainerRef.current || !formRef.current) return

    const formHeight = formRef.current.getBoundingClientRect().height
    const bookingTop = bookingContainerRef.current.getBoundingClientRect().top
    const $map = document.querySelector(`.${locationMapClassname}`)
    const locationTop = $map ? $map.getBoundingClientRect().top - 33 : Infinity

    if (locationTop < formHeight + 50 || bookingTop < 14) {
      formRef.current.classList.add(`${c}--isSticky`)
    } else {
      formRef.current.classList.remove(`${c}--isSticky`)
    }
    if (locationTop < formHeight + 50) {
      formRef.current.style.top = `${locationTop - formHeight}px`
    } else {
      formRef.current.style.removeProperty('top')
    }
  }

  const handleModalDatesSelected = modal => modal.closeModal(() => {
    scrollToElement(document.querySelector(`.${bedroomsClassname}`), -70)
  })

  const renderForm = modal => {
    const isAnythingAvailable = query.programSessionId ? (
      !_.isEmpty(allDatesListings)
    ) : (
      !!allDatesListings.availableDates.length
    )
    return (
      <div className={c} ref={formRef}>
        {isAnythingAvailable ? (
          query.programSessionId ? (
            <Program {...props} />
          ) : (
            !selectedListing ? (
              <Dates
                {...props}
                onDatesSelected={!modal ? undefined : () => handleModalDatesSelected(modal)}
              />
            ) : (
              <Rates 
                {...props}
              />
            )
          )
        ) : (
          <div className={`${c}_noResults`}>
            This listing is not available
            {!query.programSessionId && (
              <Link to="/search">
                <Button square>View Other Properties</Button>
              </Link>
            )}
          </div>
        )}
         {!props.isEntireSpace && (
          <Roomies property={props.property} query={props.query} />
        )}
      </div>
    )
  }

  return (
    <Media
      query="(max-width: 1140px)"
      match={() => (
        <>
          <Modal
            className={`${c}_modal`}
            overlayClassName={`${c}_modal_overlay`}
            renderContent={renderForm}
            isOpen={isMobileModalOpen || (
              !isEntireSpace && !!selectedListing
            )}
            onClose={() => {
              if (!isEntireSpace) {
                updateQuery({ selectedListingId: null })
              }
              setIsMobileModalOpen(false)
            }}
          />
          {!(query.programSessionId && !isEntireSpace) && (
            <Button
              className={`${c}_mobileBookLink`}
              onClick={() => setIsMobileModalOpen(true)}
            >
              {isEntireSpace ? 'Book' : 'Select Dates'}
            </Button>
          )}
        </>
      )}
      unmatch={() => (
        <div
          className={`${c}_container`}
          ref={bookingContainerRef}
        >
          {renderForm()}
        </div>
      )}
    />
  ) 
}

BookingForm.propTypes = propTypes

export default BookingForm
