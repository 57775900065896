import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { copyToClipboard } from '@/shared/utils/html'
import { updateCurrentUser } from '@/features/auth/state/actions'
import { handleError } from '@/shared/api'
// import { createPropertySlug } from '@/hostProperties/api'
import { Modal, Button, Field, FieldLabel, ConfirmModal } from '@/shared/components'
import '@/hostProperties/styles/slug.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { updateCurrentUser }

const propTypes = {
  currentUser: PropTypes.object,
  updateCurrentUser: PropTypes.func.isRequired,
  renderLink: PropTypes.func.isRequired,
}

const c = 'hostProperties_slug'

class Slug extends Component {

  state = { error: null, isSubmitting: false, hasCopied: false }

  handleSubmit = confirmModal => {
    this.setState({ isSubmitting: true })

    this.props.updateCurrentUser({
      slug: this.$input.value.trim(),
    }).then(() => {
      this.setState({ error: null, isSubmitting: false })
      confirmModal.close()
    }, ({ error }) => {
      if (error.details) {
        this.setState({ error: error.details.slug })
      } else {
        handleError({ error })
      }
      this.setState({ isSubmitting: false })
      confirmModal.close()
    })
  }

  handleCopy = hostUrl => {
    copyToClipboard(hostUrl)
    this.setState({ hasCopied: true })
    setTimeout(() => this.setState({ hasCopied: false }), 1500)
  }

  render() {
    const { id, slug } = this.props.currentUser
    const hostUrl = `https://roomsie.com/h/${slug || id}`
    return (
      <Modal
        className={c}
        renderLink={this.props.renderLink}
        renderContent={() => (
          <Fragment>
            <div className={`${c}_title`}>Unique Host URL</div>
            <div className={`${c}_description`}>
              {'Use this unique URL to share and promote all your active properties.'}
            </div>
            <FieldLabel>Your unique host URL is</FieldLabel>
            <div className={`${c}_url`}>
              <span>{hostUrl}</span>
              <Button square size="small" onClick={() => this.handleCopy(hostUrl)}>
                {this.state.hasCopied ? 'Copied' : 'Copy'}
              </Button>
            </div>
            <FieldLabel>Personalize your URL</FieldLabel>
            <div className={`${c}_form`}>
              <Field.Input
                placeholder="Enter handle"
                defaultValue={slug || ''}
                errors={this.state.error}
                getRef={el => this.$input = el}
              />
              <ConfirmModal
                dontCloseOnConfirm
                message="Are you sure you want to update your host URL handle? The previous URL will no longer work."
                renderLink={() => (
                  <Button square working={this.state.isSubmitting}>Update</Button>
                )}
                onConfirm={confirmModal => this.handleSubmit(confirmModal)}
              />
            </div>
          </Fragment>
        )}
      />
    )
  }
}

Slug.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Slug)
