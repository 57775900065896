import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Icon } from '@/shared/components'
import '@/listSpace/styles/inputCount.scss'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  increment: PropTypes.number,
}

const defaultProps = {
  min: 0,
  max: 99999,
  increment: 1,
}

const c = 'listSpace_inputCount'

class InputCount extends Component {

  onMinusClick = () => {
    this.props.onChange(
      this.getBoundedValue(+this.props.value - this.props.increment)
    )
  }

  onPlusClick = () => {
    this.props.onChange(
      this.getBoundedValue(+this.props.value + this.props.increment)
    )
  }

  getBoundedValue = value => (
    _.round(_.clamp(value, this.props.min, this.props.max), 1)
  )

  render() {
    const { value, min, max } = this.props
    return (
      <div className={c}>
        <div
          className={classnames(`${c}_icon`, {
            [`${c}_icon--isMinOrMax`]: value.toFixed(1) === min.toFixed(1),
          })}
          onClick={this.onMinusClick}
        >
          <Icon type="minus" />
        </div>
        <div className={`${c}_value`}>{_.round(value, 1)}</div>
        <div
          className={classnames(`${c}_icon`, {
            [`${c}_icon--isMinOrMax`]: value.toFixed(1) === max.toFixed(1),
          })}
          onClick={this.onPlusClick}
        >
          <Icon type="plus" />
        </div>
      </div>
    )
  }
}

InputCount.propTypes = propTypes
InputCount.defaultProps = defaultProps

export default InputCount
