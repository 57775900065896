import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import { getTextFromHtml } from '@/shared/utils/html'
import * as analytics from '@/shared/utils/analytics'
import { formatDate } from '@/shared/utils/dateTime'
import { fetchConversations } from '@/conversations/api'
import queryManager from '@/shared/hocs/queryManager'
import { Avatar, PageSpinner, Pagination, Head,
  EmptyState, InfoBanner, Input } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/conversations/styles/conversations.scss'

const propTypes = {
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
}

const c = 'conversations'

class Conversations extends Component {

  state = {
    convos: [],
    pagination: null,
    isFetching: true,
  }

  componentDidMount() {
    analytics.pageview('Conversations')

    this.fetchConversations(() => {
      if (!this.state.convos.length) {
        this.updateQuery({ allMessages: false })
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.query.page !== prevProps.query.page) {
      window.scrollTo(0, 100)
    }
    if (this.props.query !== prevProps.query) {
      this.fetchConversations()
    }
  }

  updateQuery = query => {
    this.props.updateQuery({ page: 1, ...query })
  }

  fetchConversations = (after = () => {}) => {
    this.setState({ isFetching: true })

    fetchConversations({
      ...this.props.query,
      unread: this.props.query.allMessages ? undefined : true,
    }).then(({ data, meta }) => {
      this.setState({
        convos: data,
        pagination: meta,
        isFetching: false,
      }, after)
    }, handleError)
  }

  renderConversation = convo => (
    <Link
      key={convo.id}
      to={`/messages/${convo.id}`}
      className={classnames(`${c}_convo`, {
        [`${c}_convo--isUnread`]: !!convo.unreadMessagesCount,
      })}
    >
      <div className={`${c}_convo_avatar`}>
        <Avatar {...convo.users[0]} size={50} withoutLink />
      </div>
      <div className={`${c}_convo_nameDate`}>
        <div>{convo.users[0].firstName}</div>
        <div>{formatDate(convo.lastMessage.createdAt)}</div>
      </div>
      <div className={`${c}_convo_content`}>
        <div>{getTextFromHtml(convo.lastMessage.body)}</div>
        <div>{convo.title}</div>
      </div>
      {!!convo.unreadMessagesCount && (
        <div className={`${c}_convo_newMessagesTag`}>
          <div>{`${convo.unreadMessagesCount} New `}</div>
        </div>
      )}
    </Link>
  )

  render() {
    const { query } = this.props
    const { convos, pagination, isFetching } = this.state
    return (
      <div className={c}>
        <Head title="Messages - Roomsie" />
        <AppHeader />
        <div className={`${c}_page`}>
          <InfoBanner
            message="To insure the integrity of our platform and to protect the interest of both host and guest please do not send or share personal contact information. Efforts to take your rental transaction off-site will void all Roomsie refund cancellation, rent safe & other policies."
          />
          <div className={`${c}_top`}>
            <h1>Messages</h1>
            <div className={`${c}_filters`}>
              <span
                className={classnames(`${c}_filter`, {
                  [`${c}_filter--isActive`]: !query.allMessages,
                })}
                onClick={() => this.updateQuery({ allMessages: false })}
              >
                Unread
              </span>
              <span
                className={classnames(`${c}_filter`, {
                  [`${c}_filter--isActive`]: query.allMessages,
                })}
                onClick={() => this.updateQuery({ allMessages: true })}
              >
                All messages
              </span>
            </div>
            <Input
              icon="search"
              size="small"
              defaultValue={query.q}
              placeholder="Search messages"
              onChange={_.debounce((e, q) => this.updateQuery({ q }), 700)}
            />
          </div>
          <div className={classnames(`${c}_list_container`, {
            [`${c}_list_container--isFetching`]: isFetching,
          })}>
            {!convos.length && isFetching && <PageSpinner />}
            {!convos.length && !isFetching && (
              <EmptyState
                title={`We could not find any ${query.allMessages ? '' : 'Unread'} messages.`}
                message="All your private conversations with other hosts and guests are kept here."
              />
            )}
            {!!convos.length && (
              <Fragment>
                {convos.map(this.renderConversation)}
                <Pagination
                  onChange={page => this.updateQuery({ page })}
                  {...pagination}
                />
              </Fragment>
            )}
          </div>
        </div>
        <AppFooter />
      </div>
    )
  }
}

Conversations.propTypes = propTypes

export default queryManager({
  defaultQuery: {
    page: 1,
    allMessages: false,
    q: '',
  },
})(Conversations)
