import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@/shared/components'

const propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  current: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const SortChevron = ({ label, field, current, onChange }) => {
  let iconType, value;

  if (field === current) {
    iconType = 'sort-down'
    value = `-${field}`
  } else if (`-${field}` === current) {
    iconType = 'sort-up'
    value = undefined
  } else {
    iconType = 'sort'
    value = field
  }
  return (
    <div className="sortChevron" onClick={() => onChange(value)}>
      <Icon className="sortChevron_icon" type={iconType} />
      {label}
    </div>
  )
}

SortChevron.propTypes = propTypes

export default SortChevron
