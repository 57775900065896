import api from '@/shared/api'

export const fetchMessages = () => (
  api.get('/scheduled_messages')
)

export const fetchMessage = messageId => (
  api.get(`/scheduled_messages/${messageId}`)
)

export const createMessage = payload => (
  api.post('/scheduled_messages', payload)
)

export const updateMessage = (messageId, payload) => (
  api.patch(`/scheduled_messages/${messageId}`, payload)
)

export const deleteMessage = messageId => (
  api.delete(`/scheduled_messages/${messageId}`)
)

export const sendTestMessage = messageId => (
  api.post(`/scheduled_messages/${messageId}/test`)
)

export const sendInstantMessage = payload => (
  api.post('/host/messages', payload)
)

export const fetchProperties = () => (
  api.get('/host/properties')
)
