import React, { useState, useEffect } from 'react'
import { Link, useParams, useSearchParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { to404 } from '@/shared/utils/404'
import { getTextFromHtml, getFirstParagraphFromHtml } from '@/shared/utils/html'
import { getPhotoUrl } from '@/shared/utils/photos'
import { formatPrice } from '@/shared/utils/numbers'
import { formatDate, formatDateRange } from '@/shared/utils/dateTime'
import { objectToQueryString } from '@/shared/utils/url'
import { fetchProgram, fetchProperties, fetchAttendees } from '@/program/api'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { PageSpinner, Head, TextEditedHtml, Media, UsersList, PropertiesList,
  Switch, Select, FieldLabel, Avatar, VerifiedMark } from '@/shared/components'
import MessageModal from '@/features/messageModal/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/program/styles/index.scss'

const Program = () => {

  const location = useLocation()
  const { programId } = useParams()
  
  const [program, setProgram] = useState(null)
  const [attendees, setAttendees] = useState(null)

  const [searchParams, setSearchParams] = useSearchParams()
  const sessionId = searchParams.get('sessionId')

  useEffect(() => {
    analytics.pageview('Program', { programId })

    fetchProgram(programId).then(({ data }) => {
      setProgram(data)
      getAttendees(data.sessions)
    }, () => {
      to404('We could not find this program.')
    })
  }, [])

  useEffect(() => {
    if (!!sessionId) {
      getAttendees(program.sessions)
    }
  }, [sessionId])

  const getAttendees = (sessions) => {
    fetchAttendees(getSessionId(sessions)).then(({ data }) => {
      setAttendees(_.uniqBy(data, 'id'))
    }, () => {})
  }

  const getSessionId = (sessions) => {
    if (!!sessionId) {
      return +sessionId
    }
    const dates = sessions.map(s => s.startDate).map(d => moment(d))
    const futureDates = dates.filter(d => d.isSameOrAfter(moment(), 'day'))
    const nextDate = futureDates.length ? moment.min(futureDates) : moment.max(dates)
    return sessions.find(s => moment(s.startDate).isSame(nextDate, 'day')).id
  }

  const updateSearchParams = (params) => {
    setSearchParams(params)
  }

  return (
    <div className="program">
      <AppHeader />
      {!!program ? (
        <>
          <Head
            noindex
            title={`${program.title} - Roomsie`}
            description={getTextFromHtml(program.descriptionHtml)}
            image={getPhotoUrl(program.photo.imageUrl, 'medium')}
            url={location.pathname}
          />
          <div className="program_inner">
            <div className="program_left">
              <Sidebar 
                program={program} 
                getSessionId={getSessionId}
                updateSearchParams={updateSearchParams}
              />
              <Media query="(max-width: 767px)"
                unmatch={() => <AttendeesList attendees={attendees} />}
              />
            </div>
            <div className="program_right">
              <Content program={program} />
              <Media query="(max-width: 767px)"
                match={() => <Info program={program} getSessionId={getSessionId} />}
              />
              <Media query="(max-width: 767px)"
                match={() => <AttendeesList attendees={attendees} />}
              />
              <Properties program={program} getSessionId={getSessionId} />
            </div>
          </div>
          <AppFooter />
        </>
      ) : (
        <PageSpinner padding={220} />
      )}
    </div>
  )
}

const Content = ({ program }) => {
  
  const { toOpen } = useProfileModalUrl()

  const { host } = program
  const descHtml = program.descriptionHtml.trim()
  const descFirstParagraph = getFirstParagraphFromHtml(descHtml)
  return (
    <div className="program_main">
      <h1 className="program_title">
        {program.title}
      </h1>
      <div className="program_host">
        <div className="program_host_inner">
          <Avatar {...host} size={82} />
          <h2 className="program_host_title">{`Hosted by ${host.firstName}`}</h2>
          <div className="program_host_memberSince">
            {'Member since: '}
            <span>{formatDate(host.createdAt, 'monthYear')}</span>
          </div>
          {host.verifiedHost && <VerifiedMark />}
          <div className="program_host_profileLink" onClick={() => toOpen(host.id)}>
            {`View ${host.firstName}'s profile`}
          </div>
          <MessageModal
            description="Questions about your housing that were not addressed in the listing? Ask your hosts! They are here to help."
            recipient={program.host}
            contextId={{ programId: program.id }}
          />
        </div>
      </div>
      <Switch
        linkText="Read more about the program"
        shouldShowLink={() => descHtml.length > descFirstParagraph.length}
        renderDefault={() => <TextEditedHtml html={descFirstParagraph} />}
        renderSwitch={() => <TextEditedHtml html={descHtml} />}
      />
    </div>
  )
}

const Sidebar = ({ program, getSessionId, updateSearchParams }) => (
  <div className="program_sidebar">
    <img src={getPhotoUrl(program.photo.imageUrl, 'small')} alt="program logo" />
    <FieldLabel>Select Session</FieldLabel>
    <Select
      value={getSessionId(program.sessions)}
      options={program.sessions.map(session => ({
        label: session.name, value: session.id,
      }))}
      onChange={(e, sessionId) => updateSearchParams({ sessionId: sessionId })}
    />
    <Media query="(max-width: 767px)"
      unmatch={() => <Info program={program} getSessionId={getSessionId} />}
    />
  </div>
)

const Info = ({ program, getSessionId }) => {
  const session = program.sessions.find(s => s.id === getSessionId(program.sessions))
  return (
    <div className="program_info">
      <div>
        Director:
        <span>{program.directorName}</span>
        <a href={`mailto:${program.directorEmail}`}>{program.directorEmail}</a>
      </div>
      <div>
        Session Dates:
        <span>{formatDateRange(session.startDate, session.endDate)}</span>
      </div>
      <div>
        Reserve your housing no later than:
        <span>{formatDate(session.applyBy)}</span>
      </div>
      <div>
        To receive full refund of deposit, cancel no later than:
        <span>{formatDate(session.cancelBy)}</span>
      </div>
    </div>
  )
}

const AttendeesList = ({ attendees }) => attendees && !!attendees.length && (
  <UsersList
    title={`Who's Attending (${attendees.length})`}
    users={attendees}
  />
)

const Properties = ({ program, getSessionId }) => {
  const session = program.sessions.find(s => s.id === getSessionId(program.sessions))
  return (
    <PropertiesList
      key={session.id}
      title="Choose your housing from the options below"
      fetchProperties={after => {
        fetchProperties(session.id).then(({ data }) => after(data), () => {})
      }}
      setLinkProps={property => ({
        to: {
          pathname: `/properties/${property.id}`,
          search: objectToQueryString({
            startDate: session.startDate,
            endDate: session.endDate,
            programSessionId: session.id,
            programSessionName: `${program.title}: ${session.name}`,
          }),
        },
      })}
      renderNoResults={() => (
        <div className="program_properties_noResults">
          There are no available listings at this time.
        </div>
      )}
    />
  )
}

export default Program
