import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getPhotoUrl } from '@/shared/utils/photos'
import '@/shared/styles/photo.scss'

const propTypes = {
  className: PropTypes.string,
  photo: PropTypes.object,
  size: PropTypes.string,
  cover: PropTypes.bool,
  imgTag: PropTypes.bool,
  lazyLoad: PropTypes.string,
  children: PropTypes.node,
}

const Photo = ({
  className,
  photo,
  size = 'small',
  cover,
  imgTag,
  lazyLoad,
  children,
  ...otherProps
}) => {
  const { imageUrl, height, width } = photo || {}
  const src = getPhotoUrl(imageUrl, size)
  if (imgTag) {
    const props = {
      className: classnames('photo', className),
      ...(lazyLoad ? { [lazyLoad]: src } : { src }),
      ...otherProps
    }
    return <img {...props} />
  } else {
    const hasWeirdAspectRatio = height >= width || width / height >= 2.25
    const props = {
      className: classnames('photo', className),
      style: {
        backgroundImage: !lazyLoad && `url(${src})`,
        backgroundSize: height && width && !cover && hasWeirdAspectRatio ? 'contain' : 'cover',
      },
      ...(lazyLoad ? { [lazyLoad]: src } : {}),
      ...otherProps
    }
    return <div {...props}>{children}</div>
  }
}

Photo.propTypes = propTypes

export default Photo

