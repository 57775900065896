import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import PrevNext from '../PrevNext'
import EntireSpaceRates from './EntireSpaceRates'
import Bedrooms from './Bedrooms'
import Counts from './Counts'
import '@/listSpace/styles/roomsRates.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

class RoomsRates extends Component {

  handleRatesChange = rates => {
    this.props.updateProperty({ rates })
  }

  handleBedroomsChange = bedrooms => {
    this.props.updateProperty({ bedrooms })
  }

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next')
    }
  }

  render() {
    const { property, errors, clearStepErrors } = this.props
    return (
      <div className="listSpace_roomsRates listSpace_content">
        {property.listingType === 'entire_space' ? (
          <Fragment>
            <h1 className="listSpace_title">
              Nightly Rate & Rooms
            </h1>
            <p className="listSpace_subtitle">
              One, flat nightly rate to rent your entire property regardless of the number of beds provided.
            </p>
          </Fragment>
        ) : (
          <h1 className="listSpace_title">
            Build Your Property
          </h1>
        )}
        <Counts
          property={property}
          onChange={this.props.updateProperty}
        />
        {property.listingType === 'entire_space' ? (
          <EntireSpaceRates
            property={property}
            updateProperty={this.props.updateProperty}
            sessions={property.sessions}
            rates={property.rates}
            errors={errors || {}}
            handleRatesChange={this.handleRatesChange}
            clearStepErrors={clearStepErrors}
          />
        ) : (
          <Bedrooms
            property={this.props.property}
            updateProperty={this.props.updateProperty}
            sessions={property.sessions}
            bedrooms={property.bedrooms}
            errors={errors.bedrooms || {}}
            onChange={this.handleBedroomsChange}
            clearStepErrors={clearStepErrors}
          />
        )}
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    )
  }
}

RoomsRates.propTypes = propTypes

export default RoomsRates
