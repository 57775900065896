export const getUserInput = () => ({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  city: '',
  usState: '',
  country: '',
  birthDate: '',
  gender: '',
  studentType: '',
  school: '',
  major: '',
  instagramUrl: '',
  facebookUrl: '',
  bio: '',
  interests: [],
  acceptedToS: 'false',
  billingName: '',
  billingLine1: '',
  billingLine2: '',
  billingCity: '',
  billingState: '',
  billingPostalCode: '',
  billingCountry: '',
  billingPhone: '',
  stripeToken: '',
});

export const getErrors = () => ({
  room: {},
  information: {},
  payment: {},
});
