import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { acceptTerms } from '@/features/auth/state/actions'
import { Button } from '@/shared/components'

const mapDispatchToProps = { acceptTerms }

const propTypes = {
  acceptTerms: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

const c = 'authConfirm_terms'

class Terms extends Component {

  state = { isSubmitting: false }

  onAccept = () => {
    this.setState({ isSubmitting: true })
    this.props.acceptTerms()
  }

  render() {
    return (
      <div className={c}>
        <h1 className={`${c}_title`}>
          Before you join
        </h1>
        <p className={`${c}_paragraph`}>
          {"Roomsie's goal is to create a trusted community of roomies and hosts. We're asking you to accept our terms of service and make a commitment to respect everyone on Roomsie."}
        </p>
        <h2 className={`${c}_subtitle`}>
          Community Commitment
        </h2>
        <p className={`${c}_paragraph`}>
          {"I agree to treat everyone in the Roomsie community — regardless of their race, religion, national origin, ethnicity, skin color, disability, sex or age — with respect, and without judgement or bias."}
        </p>
        <h2 className={`${c}_subtitle`}>
          Terms of Service
        </h2>
        <p className={`${c}_paragraph`}>
          {"I also accept Roomsie's "}
          <Link to="/terms-of-service" target="_blank">
            Terms of Service
          </Link>
          {', '}
          <Link to="/payments-terms-of-service" target="_blank">
            Payments Terms of Service
          </Link>
          {', and '}
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          {'.'}
        </p>
        <Button hollow color="secondary" onClick={this.props.closeModal}>
          Decline
        </Button>
        <Button working={this.state.isSubmitting} onClick={this.onAccept}>
          Accept
        </Button>
      </div>
    )
  }
}

Terms.propTypes = propTypes

export default connect(null, mapDispatchToProps)(Terms)
