import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { dateToString, stringToDate, formatDateRange } from '@/shared/utils/dateTime'
import { DateRangePicker, Icon } from '@/shared/components'

const propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilterChanges: PropTypes.func.isRequired,
  cancelFilterChanges: PropTypes.func.isRequired,
}

const Dates = ({ filters, ...props }) => (
  <DateRangePicker
    className="search_filters_dateRangePicker"
    closeOnPick={false}
    confirmText="Apply"
    tooltipProps={{ offset: { top: -20 } }}
    value={{
      from: stringToDate(filters.startDate),
      to: stringToDate(filters.endDate),
    }}
    onChange={({ from, to }) => {
      props.onFilterChange({
        startDate: dateToString(from),
        endDate: dateToString(
          from && !to ? moment(from).add(7, 'd').toDate() : to
        ),
      })
    }}
    onConfirm={props.applyFilterChanges}
    onClose={props.cancelFilterChanges}
    renderAnchor={() => (
      <div className="search_filters_link">
        <Icon type="calendar" />
        {formatDateRange(filters.startDate, filters.endDate, { omitYears: true }) || 'Dates'}
      </div>
    )}
  />
)

Dates.propTypes = propTypes

export default Dates
