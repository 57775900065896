import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from '@/shared/hooks/withRouter'
import { isCurrentUserVerified } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { Modal } from '@/shared/components'
import Terms from './Terms'
import Email from './Email'
import PhoneNumber from './PhoneNumber'
import UploadAvatar from './UploadAvatar'
import '@/features/auth/styles/confirm.scss'

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const propTypes = {
  currentUser: PropTypes.object,
}

const Confirm = (props) => {
  const { isOpenConfirm, toAfterAuthUrlIfExists, toCloseConfirm } = useAuthUrl()

  useEffect(() => {
    continueIfVerified()
  }, [])

  useEffect(() => {
    continueIfVerified()
  }, [props.currentUser])

  const continueIfVerified = () => {
    if (isOpenConfirm() && isCurrentUserVerified()) {
      if (!toAfterAuthUrlIfExists()) {
        toCloseConfirm()
      }
      showToast({ title: 'Your account was verified successfully.' })
      analytics.track('Verify Completed')
    }
  }
  
  return (
    props.currentUser &&
    !isCurrentUserVerified() &&
    isOpenConfirm()
  ) && (
    <Modal
      isOpen
      containsForm
      onClose={toCloseConfirm}
      className="authConfirm"
      renderContent={() => <Content currentUser={props.currentUser} closeModal={props.closeModal} props={props} />}
    />
  )
}

const Content = ({ currentUser, closeModal, props }) => {
  if (!currentUser.termsAccepted) {
    return <Terms {...props} />
  } else if (!currentUser.emailVerified) {
    return <Email {...props} />
  } else if (!currentUser.phoneNumberVerified) {
    return <PhoneNumber {...props} />
  } else if (!currentUser.avatar) {
    return <UploadAvatar {...props} />
  }
  return null
}

Confirm.propTypes = propTypes

export default withRouter(connect(mapStateToProps)(Confirm))
