import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { loadPhoto, getPhotoPreview } from '@/shared/utils/photos'
import { fetchSignedUrls, uploadPhotoToStorage } from '@/shared/api/photos'
import { Avatar, Button } from '@/shared/components'
import '@/shared/styles/avatarUploader.scss'

const propTypes = {
  currentUser: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

const c = 'avatarUploader'

class AvatarUploader extends Component {

  state = { isUploading: null }

  componentWillUnmount() {
    if (this.promise) this.promise.cancel()
  }

  onDrop = files => {
    this.setState({ isUploading: true })
    fetchSignedUrls(files).then(() => {
      getPhotoPreview(files[0], (preview, { width, height }) => {

        this.promise = uploadPhotoToStorage(files[0])
        this.promise.promise.then(() => {
          loadPhoto(files[0].imageUrl, () => {
            this.setState({ isUploading: false })
            this.props.onChange({
              imageUrl: files[0].imageUrl,
              width,
              height,
            })
          })
        }, () => {
          this.setState({ isUploading: false })
        })
      })
    })
  }

  render() {
    const { currentUser } = this.props
    const { isUploading } = this.state
    return (
      <div className={c}>
        {currentUser.avatar ? (
          <div className={`${c}_imageContainer`}>
            <div className={`${c}_imageBackground`} />
            <Avatar {...currentUser} size={180} fullWidth withoutLink />
          </div>
        ) : (
          <div className={`${c}_placeholder`} />
        )}
        <p>Clear frontal face photo is an important safety feature and a way for hosts and guests to learn about each other.</p>
        <p><strong>Guests: your photo will not be visible to anyone except your host and fellow roommates.</strong></p>
        <Dropzone
          className={`${c}_dropzone`}
          activeClassName={`${c}_dropzone--isActive`}
          maxSize={10000000}
          multiple={false}
          accept="image/jpeg, image/png, image/gif"
          onDrop={this.onDrop}
          disabled={isUploading}
        >
          <Button type="button" working={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload Photo'}
          </Button>
        </Dropzone>
      </div>
    )
  }
}

AvatarUploader.propTypes = propTypes

export default AvatarUploader
