import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from '@/shared/hooks/withRouter'
import { showToast } from '@/shared/utils/toast'
import * as url from '@/shared/utils/url'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { createPassword } from '@/features/auth/state/actions'
import { Modal, Form, Button, Field } from '@/shared/components'
import '@/features/auth/styles/authModal.scss'

const mapDispatchToProps = { createPassword }

const propTypes = {
  createPassword: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

const CreatePassword = (props) => {
  const { isOpenCreatePassword, createPasswordToSignin } = useAuthUrl()

  return (
    isOpenCreatePassword() && (
      <Modal
        isOpen
        containsForm
        withoutCloseIcon
        closeOnEscapeKey={false}
        closeOnOutsideClick={false}
        className="authModal"
        renderContent={() => (
          <CreatePasswordForm 
            createPassword={props.createPassword} 
            createPasswordToSignin={createPasswordToSignin} 
            location={props.router.location} 
          />
        )}
      />
    )
  )
}

const CreatePasswordForm = ({ createPassword, createPasswordToSignin, location }) => (
  <Form
    initialValues={{
      password: '',
      passwordRepeat: '',
    }}
    validations={{
      password: [Form.is.required(), Form.is.min(8)],
      passwordRepeat: [Form.is.required(), Form.is.min(8)],
    }}
    onSubmit={(values, form) => {
      const token = url.queryStringToObject(location.search).token

      if (values.password !== values.passwordRepeat) {
        form.setSubmitting(false)
        return form.setErrors({
          passwordRepeat: 'Both fields have to contain the same password',
        })
      }
      createPassword(values.password, token).then(() => {
        showToast({ title: 'Your new password was set successfully.' })
        createPasswordToSignin()
      }, ({ error }) => {
        if (error.details) {
          form.setErrors(error.details)
        } else {
          showToast({
            type: 'danger',
            title: 'New password could not be set.',
            message: `The token was invalid. Make sure to click on a link
                      from the latest password reset email we sent you.`,
            duration: 0,
          })
        }
        form.setSubmitting(false)
      })
    }}
    render={({ handleSubmit, isSubmitting, fields }) => (
      <form onSubmit={handleSubmit}>
        <div className="authModal_inner">
          <div className="authModal_title">
            Set New Password
          </div>
          <Field.Input
            {...fields.password}
            placeholder="New Password"
            icon="lock"
            type="password"
          />
          <Field.Input
            {...fields.passwordRepeat}
            placeholder="Repeat New Password"
            icon="lock"
            type="password"
          />
          <Button type="submit" className="authModal_submit" working={isSubmitting}>
            Set New Password
          </Button>
        </div>
      </form>
    )}
  />
)

CreatePassword.propTypes = propTypes

export default withRouter(connect(null, mapDispatchToProps)(CreatePassword))
