import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { formatDate } from '@/shared/utils/dateTime'
import { formatAddress } from '@/shared/utils/geo'
import * as utils from '@/hostManagement/utils'
import '@/hostManagement/styles/week.scss'

const propTypes = {
  rangeStart: PropTypes.any.isRequired,
  bookings: PropTypes.array.isRequired,
  properties: PropTypes.object.isRequired,
  propertyIdFilter: PropTypes.number,
  onDaySelect: PropTypes.func.isRequired,
}

const c = 'hostManagement_week'

class Week extends Component {

  shouldComponentUpdate(nextProps) {
    return utils.shouldCalendarRender(nextProps, this.props)
  }

  render() {
    let { bookings, properties, rangeStart, propertyIdFilter, onDaySelect } = this.props

    bookings = utils.filterBookingsByProperty(bookings, propertyIdFilter)

    const weekDates = _.times(7).map(day => moment(rangeStart).add(day, 'days'))
    return (
      <div className={c}>
        {weekDates.map(date => {
          const { checkInIds, checkOutIds } = utils.getCheckInOutIds(bookings, date)
          const dayBookings = utils.getDayBookings(bookings, checkInIds, checkOutIds)
          const dayPropertyBookings = _.groupBy(dayBookings, b => b.propertyId)
          return (
            <div key={date} className={classnames(`${c}_column`, {
              [`${c}_column--isToday`]: moment().isSame(date, 'day'),
            })}>
              <div onClick={() => onDaySelect(date)} className={classnames(`${c}_day`, {
                [`${c}_day--isEmpty`]: !dayBookings.length,
              })}>
                <span>{formatDate(date, 'dayMonthDay').replace(', ', ' | ')}</span>
                <div>{`${dayBookings.length} guests`}</div>
              </div>
              {_.map(dayPropertyBookings, (bookings, propertyId) => (
                <div key={propertyId} className={`${c}_property_group`}>
                  <Link to={`/properties/${propertyId}`} className={`${c}_property`}>
                    <span>{_.truncate(properties[propertyId].title, { length: 30 })}</span>
                    <div>{formatAddress(properties[propertyId])}</div>
                  </Link>
                  <div className={`${c}_bookings`}>
                    {utils.sortBookings(bookings, checkOutIds).map(booking => (
                      <Link
                        key={booking.id}
                        to={`/host/bookings/${booking.id}`}
                        className={classnames(`${c}_booking`, {
                          [`${c}_booking--isCheckIn`]: checkInIds[booking.id],
                          [`${c}_booking--isCheckOut`]: checkOutIds[booking.id],
                          [`${c}_booking--isProcessed`]: utils.isProcessed(booking, checkInIds, checkOutIds),
                        })}
                      >
                        <span>{booking.guest.firstName}</span>
                        <span>{booking.arrivalTime || ''}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )
        })}
      </div>
    )
  }
}

Week.propTypes = propTypes

export default Week
