import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import options from '@/shared/static/options'
import usStates from '@/shared/static/usStates'
import { geocode } from '@/shared/utils/geo'
import { getNeighborhoodByCenter } from '@/listSpace/api'
import { LocationSearch, LocationMap, Field, FieldErrors } from '@/shared/components'
import PrevNext from './PrevNext'
import '@/listSpace/styles/location.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

const c = 'listSpace_location'

class Location extends Component {

  constructor(props) {
    super(props)
    this.fetchCoordinates = _.debounce(this.fetchCoordinates, 800)
  }

  handleChange = values => {
    this.props.updateProperty(values)
    this.props.clearStepErrors(_.keys(values))
  }

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next')
    }
  }

  fetchCoordinates = () => {
    const { streetAddress, city, state, zip } = this.props.property
    if (streetAddress && city && state && zip) {
      geocode(this.google, {
        address: [streetAddress, city, state, zip].join(' '),
      }, {
        success: place => this.handleChange({
          coordinates: place.center || null,
          mapZoom: place.suggestedZoom || null,
        }),
        error: () => this.handleChange({
          coordinates: null,
          mapZoom: null,
        }),
      })
    }
  }

  onLocationSelect = place => {
    const values = {
      streetAddress: place.name || '',
      city: place.city || '',
      state: place.stateCode || '',
      zip: place.postalCode || '',
      coordinates: place.center || null,
      mapZoom: place.suggestedZoom || null,
    }
    this.handleChange(values)
    if (place.center.lat && place.center.lng) {
      getNeighborhoodByCenter(this.google, this.map, place.center, neighborhood => {
        this.handleChange({ neighborhood })
      })
    }
  }

  render() {
    const { property, errors } = this.props

    const fields = fieldName => ({
      value: property[fieldName],
      errors: errors[fieldName],
      onChange: (e, value) => {
        this.fetchCoordinates()
        this.handleChange({ [fieldName]: value })
      }
    })
    return (
      <div className={`${c} listSpace_content`}>
        <h1 className="listSpace_title">Property Location</h1>
        <p className="listSpace_subtitle">
          The exact address will not be shown to guests until you approve their booking request.
        </p>
        <div className={`${c}_fields`}>
          <div>
            <LocationSearch
              placeholder="Street Address"
              onSelect={this.onLocationSelect}
              value={property.streetAddress}
              onChange={streetAddress => {
                this.fetchCoordinates()
                this.handleChange({ streetAddress })
              }}
              inputProps={{
                value: property.streetAddress,
                invalid: !!errors.streetAddress,
                line: true,
                icon: undefined,
              }}
            />
            <FieldErrors errors={errors.streetAddress} />
          </div>
          <Field.Input line placeholder="Apt. or Unit Number" {...fields('streetAddress2')} />
          <Field.Input line placeholder="City" {...fields('city')} />
          <Field.Select line placeholder="State" options={usStates} {...fields('state')} />
          <Field.Input line placeholder="Zip Code" {...fields('zip')} />
          <Field.Input line placeholder="Neighborhood" {...fields('neighborhood')} />
        </div>
        <LocationMap
          center={property.coordinates}
          zoom={property.mapZoom || 15}
          invalid={!!errors.coordinates}
          getGoogleMap={(google, map) => {
            this.google = google
            this.map = map
          }}
          onCoordinatesChange={coordinates => this.handleChange({ coordinates })}
        />
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    )
  }
}

Location.propTypes = propTypes

export default Location
