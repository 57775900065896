import api from '@/shared/api'

export const fetchProperties = () => (
  api.get('/host/properties')
)

export const fetchProperty = propertyId => (
  api.get(`/host/properties/${propertyId}`)
)

export const fetchPropertyByProgram = (propertyId, programId) => (
  api.get(`/programs/${programId}/properties/${propertyId}`)
)

export const updateProperty = (propertyId, payload) => (
  api.patch(`/host/properties/${propertyId}`, payload)
)

export const updateBed = (bedId, payload) => (
  api.patch(`/host/beds/${bedId}/toggle_excluded`, payload)
)

export const updateProgramBed = (programId, bedId, payload) => (
  api.patch(`/host/programs/${programId}/beds/${bedId}/toggle_excluded`, payload)
)

export const fetchPrograms = () => (
  api.get('/host/programs')
)

export const fetchProgram = programId => (
  api.get(`/programs/${programId}`)
)
