import _ from 'lodash'
import storage from '@/shared/utils/storage'

export const getStoredToken = () => (
  storage.local.get('authToken')
)
export const storeToken = token => (
  storage.local.set('authToken', token)
)
export const removeStoredToken = () => (
  storage.local.remove('authToken')
)

export const getStoredCurrentUser = () => (
  storage.local.getJSON('authCurrentUser')
)
export const storeCurrentUser = currentUser => (
  storage.local.setJSON('authCurrentUser', () => currentUser)
)
export const removeStoredCurrentUser = () => (
  storage.local.remove('authCurrentUser')
)

export const isCurrentUserAuthenticated = () => (
  !!getStoredToken()
)
export const isCurrentUserVerified = () => {
  const user = getStoredCurrentUser()
  return (
    user &&
    user.termsAccepted &&
    user.emailVerified &&
    user.phoneNumberVerified &&
    user.avatar
  )
}
