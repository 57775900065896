import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { fetchPolicy } from '@/staticPages/api'
import * as analytics from '@/shared/utils/analytics'
import { PageSpinner, Head } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/staticPages/styles/policy.scss'

const propTypes = {
  fileName: PropTypes.string,
}

const Policy = (props) => {
  const { fileName } = props

  const [htmlString, setHtmlString] = useState(null)
  
  const location = useLocation()

  useEffect(() => {
    analytics.pageview('Policy', {
      type: fileName,
    })
    fetchPolicy(fileName, html => {
      setHtmlString(html)
    })
  }, [])

  const docName = _.startCase(location.pathname.split('/')[1])

  return (
    <div className="staticPages_policy">
      <Head title={`${docName} - Roomsie`} />
      <AppHeader />
      <div className="staticPages_policy_page">
        {htmlString ? (
          <div
            className="staticPages_policy_content"
            dangerouslySetInnerHTML={{ __html: htmlString }}
          />
        ) : (
          <PageSpinner />
        )}
      </div>
      <AppFooter />
    </div>
  )
}

Policy.propTypes = propTypes

export default Policy
