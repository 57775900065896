import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { formatDateRange } from '@/shared/utils/dateTime'
import { Photo, Button, Icon } from '@/shared/components'
import '@/hostPrograms/styles/programCard.scss'

const propTypes = {
  program: PropTypes.object.isRequired,
}

const c = 'hostProgramsProgramCard'

const ProgramCard = ({ program }) => (
  <div className={c}>
    <div className={`${c}_info`}>
      <Photo photo={program.photo} />
      <div className={`${c}_title`}>
        {program.title}
      </div>
      <Link to={`/p/${program.slug}`} className={`${c}_view`}>
        <Icon type="share" />
        View Partner
      </Link>
    </div>
    <div className={`${c}_sessions`}>
      {program.sessions.sort((a, b) => a - b).map(session => (
        <div key={session.id} className={`${c}_session`}>
          <span>{`${session.name} `}</span>
          {` ${formatDateRange(session.startDate, session.endDate)}`}
        </div>
      ))}
    </div>
    <div className={`${c}_assign`}>
      <Link to={`/host/partners-assign/${program.id}`}>
        <Button hollow size="small">Assign Properties</Button>
      </Link>
    </div>
  </div>
)

ProgramCard.propTypes = propTypes

export default ProgramCard
