import React from 'react'
import PropTypes from 'prop-types'
import { Link, useResolvedPath } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatDate } from '@/shared/utils/dateTime'
import useProfileModalUrl from '@/profile/hooks/useProfileModalUrl'
import { Head, Icon, Avatar, VerifiedMark } from '@/shared/components'
import MessageModal from '@/features/messageModal/components'
import '@/search/styles/host.scss'

const mapStateToProps = ({ search }) => ({
  host: search.host,
})

const propTypes = {
  host: PropTypes.object
}

const c = 'search_results_host'

const Host = ({ host }) => {
  const matchUrl = useResolvedPath("").pathname
  const { toOpen } = useProfileModalUrl()
  
  return host && (
  <div className={`${c}_container`}>
    <Head
      title={`${host.firstName}'s listings in Washington DC ${new Date().getFullYear()} - Roomsie`}
      url={matchUrl}
    />
    <div className={`${c}_allLink`} onClick={() => window.location = '/search'}>
      <Icon type="chevron-left-fat" />
      View All Properties
    </div>
    <div className={`${c}`}>
      <div className={`${c}_inner`}>
        <Avatar {...host} size={82} />
        <h2 className={`${c}_title`}>{`${host.firstName}'s Properties`}</h2>
        <div className={`${c}_memberSince`}>
          {'Member since: '}
          <span>{formatDate(host.createdAt, 'monthYear')}</span>
        </div>
        {host.verifiedHost && <VerifiedMark />}
        <div
          className={`${c}_profileLink`}
          onClick={() => toOpen(host.id)}
        >
          {`View ${host.firstName}'s profile`}
        </div>
        <MessageModal
          description="Make sure to be polite and respectful when communicating with others."
          recipient={host}
        />
      </div>
    </div>
  </div>
)}

Host.propTypes = propTypes

export default connect(mapStateToProps)(Host)
