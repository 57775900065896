import api from '@/shared/api'

export const fetchUser = userId => (
  api.get(`/users/${userId}`)
)

export const fetchUserProperties = userId => (
  api.get(`/guest/users/${userId}/properties`)
)

export const deleteCurrentUser = () => (
  api.delete(`/user`)
)
