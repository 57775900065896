import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import * as url from '@/shared/utils/url'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'

const ResetPasswordRedirect = () => {
  const location = useLocation()
  const { toOpenCreatePassword } = useAuthUrl()

  useEffect(() => {
    const { search } = location
    const token = url.queryStringToObject(search).token
    toOpenCreatePassword(token)
  }, [])
  
  return null 
}

export default ResetPasswordRedirect
