import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { uniqueDateId } from '@/shared/utils/id'
import { dateToString, stringToDate } from '@/shared/utils/dateTime'
import { syncRatesWithSessions } from '@/listSpace/state/sync'
import { formatDateRange } from '@/shared/utils/dateTime'
import { decimalPriceInputFilter } from '@/shared/utils/numbers'
import { Field, Input, Icon, Radio, Select, Modal, Button,
  FieldErrors, Media, ConfirmModal } from '@/shared/components'
import SplitSessionRates from './SplitSessionRates'

const propTypes = {
  sessions: PropTypes.array.isRequired,
  rates: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  handleRatesChange: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
}

const c = 'listSpace_roomsRates_entireSpaceRates'

class EntireSpaceRates extends Component {

  getSortedSessions = () => (
    this.props.sessions.sort((a, b) => a.uid - b.uid)
  )

  handleChange = (sessionUid, amount) => {
    const rates = _.cloneDeep(this.props.rates)
    rates.find(r => r.sessionUid === sessionUid).amount = amount
    this.props.handleRatesChange(rates)
    this.props.clearStepErrors(`rates.${sessionUid}`)
  }

  render() {
    const { property, updateProperty, rates, handleRatesChange, errors, clearStepErrors } = this.props
    const groupedSessions = _.groupBy(this.getSortedSessions(), 'startDate')
    return (
      <div className={c}>
        <div className="listSpace_roomsRates_counts_row">
          <div className="listSpace_label">Nightly Rates</div>
          <p className="listSpace_tip">
            Set the base price that will be charged per night. The nightly rates can be adjusted depending on the length of stay by adding a new rate for a session. Guests will be charged this rate if their stay exceeds the minimum duration of stay for that rate.
          </p>
        </div>
        {property.prorateForShortStay ? (
          <SplitSessionRates 
            property={property}
            updateProperty={updateProperty}
            sessions={property.sessions}
            rates={property.rates}
            errors={errors.rates || {}}
            handleRatesChange={handleRatesChange}
            clearStepErrors={clearStepErrors}
          />
        ) : (
          <>
            {Object.entries(groupedSessions).map(sessionGroup => {
              const sessionsArray = sessionGroup[1]
              const session = sessionsArray[0]
              return (
                !!session.startDate && !!session.endDate && (
                  <div key={session.uid} className={`${c}_rate`}>
                    <div className={`${c}_rate_input`}>
                      <Field.Input
                        icon="dollar"
                        filter={decimalPriceInputFilter}
                        errors={errors[session.uid]}
                        value={rates.find(r => r.sessionUid === session.uid).amount || ''}
                        onChange={(e, value) => this.handleChange(session.uid, value)}
                      />
                    </div>
                    <div className={`${c}_rate_session`}>
                      <span>{`${formatDateRange(session.startDate, session.endDate)}`}</span>
                    </div>
                  </div>
                )
              )
            })}
          </>
        )}
      </div>
    )
  }
}

EntireSpaceRates.propTypes = propTypes

export default EntireSpaceRates
