import api from '@/shared/api'

export const fetchProperties = () => (
  api.get('/host/properties')
)

export const fetchBookings = query => (
  api.get('/host/bookings', query, {
    abortCurrentRequest: 'hostGuestsFetchBookings',
  })
)

export const fetchPrograms = () => (
  api.get('/host/programs')
)

export const fetchProperty = propertyId => (
  api.get(`/host/properties/${propertyId}`)
)
