import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PrevNext from './PrevNext'
import PhotosUploader from '@/listSpace/components/PhotosUploader'
import '@/listSpace/styles/photos.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

class Photos extends Component {

  handleSubmit = () => {
    if (!this.getProgress().isAboveMin) {
      return
    }
    if (this.getProgress().uploading) {
      if (
        confirm(`You should wait before all photos are finished uploading. If you leave now only uploaded photos will be saved. Would you still like to leave?`)
      ) {
        this.props.goToStep('next')
      }
    } else {
      this.props.goToStep('next')
    }
  }

  render() {
    return (
      <div className="listSpace_photos listSpace_content" onSubmit={this.handleSubmit}>
        <h1 className="listSpace_title">Property Photos</h1>
        <p className="listSpace_subtitle">
          {`Show guests what your property looks like. You can include photos of the building, common spaces, rooms, amenities, unique features and more.`}
        </p>
        {this.props.property.listingType !== 'entire_space' && (
          <p className="listSpace_photos_note">
            {`You will be able to add images for each individual bedroom in a later step.`}
          </p>
        )}
        <PhotosUploader
          initialPhotos={this.props.property.photos}
          minPhotos={3}
          maxPhotos={24}
          onPhotosChange={photos => this.props.updateProperty({ photos })}
          getProgress={func => this.getProgress = func}
        />
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    )
  }
}

Photos.propTypes = propTypes

export default Photos
