import _ from 'lodash'

export const statuses = {
  primary: [
    { value: 'started', label: 'Current Stay' },
    { value: 'reported', label: 'Reported Stay' },
    { value: 'review', label: 'Awaiting Security Deposit Release' },
    { value: 'accepted', label: 'Upcoming Stay' },
    { value: 'pending', label: 'Pending Stay' },
    { value: 'finished', label: 'Previous Stay' },
  ],
  secondary: [
    { value: 'declined', label: 'Declined Stay' },
    { value: 'canceled', label: 'Cancelled Stay' },
    { value: 'expired', label: 'Expired Stay' },
  ],
}

export const getCurrentBooking = bookings => {
  let booking = null
  _.each(statuses.primary.map(s => s.value), status => {
    booking = bookings.find(b => b.status === status)
    if (booking) return false
  })
  return booking
}
