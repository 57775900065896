import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@/shared/components'
import '@/landings/styles/boxGrid.scss'

const propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

const c = 'landings_boxGrid'

class BoxGrid extends Component {

  state = {}

  render() {
    return (
      <div className={c}>
        <h2 className={`${c}_title`}>
          {this.props.title}
        </h2>
        <div className={`${c}_items`}>
          {this.props.items.map(item => (
            <div
              key={item.icon}
              className={`${c}_item`}
              onMouseOver={() => this.setState({ [item.icon]: true })}
              onMouseLeave={() => this.setState({ [item.icon]: false })}
            >
              <div className={`${c}_item_iconWrapper`}>
                <Icon type={item.icon} />
              </div>
              <div className={`${c}_item_title`}>{item.title}</div>
              <p className={classnames(`${c}_item_description`, {
                [`${c}_item_description--isVisible`]: this.state[item.icon],
              })}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

BoxGrid.propTypes = propTypes

export default BoxGrid
