import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import options from '@/shared/static/options'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { getBedsWithDisplayId } from '@/shared/utils/beds'
import { handleError } from '@/shared/api'
import { fetchProperty, pauseListing, deleteProperty, cloneProperty } from '@/hostProperties/api'
import { DashboardMenu, PageSpinner, Head, Icon } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import PropertyRow from './PropertyRow'
import TogglePaused from './TogglePaused'
import '@/hostProperties/styles/property.scss'

const c = 'hostProperty'

const Property = () => {
  const { propertyId } = useParams()
  const navigate = useNavigate()

  const [property, setProperty] = useState(null)

  useEffect(() => {
    fetchProperty(propertyId).then(({ data }) => {
      analytics.pageview('Host Property', {
        propertyId: data.id,
        propertyTitle: data.title,
      })
      setProperty(data)
    }, () => {
      navigate('/host/properties')
    })
  }, [])

  const handleBedPause = ({ id, paused }, confirmModal) => {
    pauseListing(id, { paused: !paused }).then(() => {
      const updatedProperty = _.cloneDeep(property)
      const listings = _.flatMap(updatedProperty.bedrooms, ({ beds }) => beds.map(bed => bed.listing))
      const listing = listings.find(listing => listing.id === id)
      listing.paused = !paused
      if (!listing.paused) {
        updatedProperty.listing.paused = false
      }
      setProperty(updatedProperty)
      confirmModal.close()
    }, handleError)
  }

  const handlePropertyPause = ({ id, paused }, confirmModal) => {
    pauseListing(id, { paused: !paused }).then(() => {
      const property = _.cloneDeep(property)
      property.listing.paused = !paused
      property.bedrooms.forEach(({ beds }) => beds.forEach(bed => {
        bed.listing.paused = !paused
      }))
      setProperty(property)
      confirmModal.close()
    }, handleError)
  }

  const handlePropertyClone = id => {
    cloneProperty(id).then(() => {
      setTimeout(() => {
        navigate('/host/properties')
        showToast({ title: 'Your property was duplicated successfully.' })
      }, 3000)
    }, handleError)
  }

  const handlePropertyDelete = id => {
    deleteProperty(id).then(() => {
      navigate('/host/properties')
      showToast({ title: 'Your property was deleted successfully.' })
    }, handleError)
  }

  return (
    <div className={c}>
      {property && <Head title={`${property.title} - Roomsie`} />}
      <AppHeader />
      <DashboardMenu role="host" />
      <div className={`${c}_page`}>
        {property ? (
          <Fragment>
            <PropertyRow
              property={property}
              onPause={confirmModal => handlePropertyPause(property.listing, confirmModal)}
              onDelete={() => handlePropertyDelete(property.id)}
              onClone={() => handlePropertyClone(property.id)}
            />
            <Bedrooms bedrooms={property.bedrooms} handleBedPause={handleBedPause} />
          </Fragment>
        ) : (
          <PageSpinner />
        )}
      </div>
      <AppFooter />
    </div>
  )
}

const Bed = ({ bed, handleBedPause }) => (
  <div key={bed.id} className={`${c}_bed`}>
    <div className={`${c}_bed_statusBar--${bed.listing.paused ? 'paused' : 'active'}`} />
    <div className={`${c}_bed_left`}>
      <span className={`${c}_bed_displayId`}>{bed.displayId}</span>
      <span className={`${c}_bed_size`}>Size: {options.labels.bedSizes[bed.size]}</span>
      <span className={`${c}_bed_kind`}>Type: {options.labels.bedTypes[bed.kind]}</span>
      <span className={`${c}_bed_statusLabel--${bed.listing.paused ? 'paused' : 'active'}`}>
        {bed.listing.paused ? 'Paused' : 'Active'}
      </span>
    </div>
    <TogglePaused
      isBed
      value={!bed.listing.paused}
      onChange={confirmModal => handleBedPause(bed.listing, confirmModal)}
      renderLink={() => (
        <div className={`${c}_bed_status`}>
          <span>{bed.listing.paused ? 'Activate' : 'Pause'}</span>
          <Icon type={`${bed.listing.paused ? 'play' : 'pause'}-circle`} />
        </div>
      )}
    />
  </div>
)

const Bedrooms = ({ bedrooms, handleBedPause }) => (
  bedrooms.sort((a, b) => a.id - b.id).map(bedroom => (
    <Fragment key={bedroom.id}>
      <div className={`${c}_bedroom`}>{bedroom.title}</div>
      {getBedsWithDisplayId(bedroom.beds).map((bed) => <Bed key={bed.id} bed={bed} handleBedPause={handleBedPause} />)}
    </Fragment>
  ))
)

export default Property
