import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import options from '@/shared/static/options'
import { formatTime } from '../../shared/utils/dateTime'
import { timeOptions } from '@/shared/static/dateTime'
import { CheckboxGroup, Checkbox, TextEditor, FieldErrors, Select, Icon } from '@/shared/components'
import PrevNext from './PrevNext'
import '@/listSpace/styles/amenitiesRules.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
}

const c = 'listSpace_amenitiesRules'

class AmenitiesRules extends Component {

  state = {
    checkinEndDisabled: true,
    timeOptionsEnd: [],
  }

  componentDidMount() {
    if (this.props.property?.checkInTimeStart){
      this.setState({ checkinEndDisabled: false, timeOptionsEnd: this.getCheckinEndOptions() })
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.property.checkInTimeStart !== prevProps.property.checkInTimeStart) {
      this.props.updateProperty({ checkInTimeEnd: '' })
      this.setState({ timeOptionsEnd: this.getCheckinEndOptions() })
    }
  }

  getCheckinEndOptions = () => {
    const index = timeOptions.findIndex(object => {
      return object.value === this.props.property.checkInTimeStart
    })
    return timeOptions.slice(index+1)
  }

  handleCheckinStartChange = value => {
    this.setState({ checkinEndDisabled: false })
    this.props.clearStepErrors('checkinTimeRange')
    this.props.updateProperty({ checkInTimeStart: value })
    
  }

  handleCheckinEndChange = value => {
    this.props.clearStepErrors('checkinTimeRange')
    this.props.updateProperty({ checkInTimeEnd: value })
  }

  deleteCheckin = () => {
    this.setState({ checkinEndDisabled: true })
    this.props.updateProperty({ checkInTimeStart: null })
  }

  handleChange = values => {
    this.props.updateProperty(values)
    this.props.clearStepErrors(_.keys(values))
  }

  handleSubmit = () => {
    if (this.props.validateStep().isValid) {
      this.props.goToStep('next')
    }
  }


  renderCheckinTime = ({ checkInTimeStart, checkInTimeEnd }) => (
    <div className={`${c}_checkinTimeRange`}>
      <h2 className="listSpace_title">
        Check-In Time
      </h2>
      <p className="listSpace_tip">
        Set a time frame for guest check-in. 
      </p>
      <div className={`${c}_checkinTimeRange_container`}>
        <div className={`${c}_checkinTimeRange_fields`}>
          <div className={`${c}_checkinTimeRange_fields_field`}>
            <span className={`${c}_checkinTimeRange_label`}>From</span>
            <Select className={`${c}_checkinTimeRange_select`}
              size="small"
              placeholder="Select"
              options={timeOptions}
              value={checkInTimeStart ? formatTime(checkInTimeStart) : ''}
              onChange={(e, value) => this.handleCheckinStartChange(value)}
            />
          </div>
          <div className={`${c}_checkinTimeRange_fields_field`}>
            <span className={`${c}_checkinTimeRange_label`}>To</span>
            <Select className={`${c}_checkinTimeRange_select`}
              size="small"
              options={[{label: 'Select', value: ''}, ...this.state.timeOptionsEnd]}
              value={checkInTimeEnd ? formatTime(checkInTimeEnd) : ''}
              onChange={(e, value) => this.handleCheckinEndChange(value)}
              disabled={this.state.checkinEndDisabled}
            />
          </div>  
        </div>
        <div className={`${c}_checkinTimeRange_delete`}>
          <Icon 
            type="trash" 
            onClick={() => this.deleteCheckin()}
          />
        </div>
      </div>
      <FieldErrors errors={this.props.errors.checkinTimeRange} />
    </div>
  )

  render() {
    const { property, errors } = this.props
    return (
      <div className={`${c} listSpace_content`}>
        <h1 className="listSpace_title">Amenities & House Rules</h1>
        {this.renderCheckinTime(property)}
        <h2 className="listSpace_title">Amenities</h2>
        <div className="listSpace_subtitle">
          Extra features that help make your property special or unique.
        </div>
        <CheckboxGroup
          value={property.amenities}
          onChange={amenities => this.handleChange({ amenities })}
          render={({ isChecked, onCheck }) => (
            options.amenities.map(amenity => (
              <div className={`${c}_col`} key={amenity.value}>
                <Checkbox
                  value={amenity.value}
                  label={amenity.label}
                  checked={isChecked(amenity.value)}
                  onChange={onCheck}
                />
              </div>
            ))
          )}
        />
        <h2 className="listSpace_title">House Rules</h2>
        <div className="listSpace_subtitle">
          Let your guests know the house rules or other helpful information.
        </div>
        <TextEditor
          value={property.rules}
          onChange={(e, rules) => this.handleChange({ rules })}
          invalid={!!errors.rules}
        />
        <FieldErrors errors={errors.rules} />
        <PrevNext
          onPrevClick={() => this.props.goToStep('prev')}
          onNextClick={this.handleSubmit}
        />
      </div>
    )
  }
}

AmenitiesRules.propTypes = propTypes

export default AmenitiesRules
