import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { isCurrentUserVerified } from '@/features/auth/utils'
import { isMobileBrowser, isIOS } from '@/shared/utils/device'
import { copyToClipboard } from '@/shared/utils/html'
import { Icon, FieldLabel, Button } from '@/shared/components'
import '@/features/shareProperty/styles/index.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
  changeSection: PropTypes.func.isRequired,
}

const c = 'shareProperty_options'

class Options extends Component {

  state = { isLinkCopied: false }

  openWindow = url => {
    const width = Math.min(720, window.innerWidth)
    const height = 500
    const left = (window.innerWidth / 2) - (width / 2)
    const top = (window.innerHeight / 2) - (height / 2)
    window.open(url, '_blank', `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`)
  }

  propertyUrl = () => (
    `${window.ROOT_URL}properties/${this.props.property.id}`
  )

  handleFacebook = () => {
    this.openWindow(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.propertyUrl())}`)
  }

  handleTwitter = () => {
    const propertyTitle = _.truncate(this.props.property.title, { length: 50 })
    const text = encodeURIComponent(`Love this Roomsie listing! "${propertyTitle}" in Washington D.C.`)
    this.openWindow(`http://twitter.com/share?text=${text}&url=${encodeURI(this.propertyUrl())}`)
  }

  handleCopyToClipboard = () => {
    copyToClipboard(this.propertyUrl())
    this.setState({ isLinkCopied: true })
    setTimeout(() => this.setState({ isLinkCopied: false }), 1500)
  }

  render() {
    const { property } = this.props
    return (
      <Fragment>
        <div className={`${c}_title`}>Share this Property</div>
        <FieldLabel>Link to this property</FieldLabel>
        <div className={`${c}_url`}>
          <span>{this.propertyUrl()}</span>
          <Button square size="small" onClick={this.handleCopyToClipboard}>
            {this.state.isLinkCopied ? 'Copied' : 'Copy'}
          </Button>
        </div>
        <FieldLabel>Share this property with your network</FieldLabel>
        <div className={`${c}_links`}>
          <Button hollow size="small" icon="facebook" onClick={this.handleFacebook}>Facebook</Button>
          <Button hollow size="small" icon="twitter" onClick={this.handleTwitter}>Twitter</Button>
          {isCurrentUserVerified() ? (
            <Button hollow size="small" icon="email" onClick={() => this.props.changeSection('email')}>
              Email
            </Button>
          ) : (
            <a href={`mailto:?subject=Check out this listing on Roomsie!&body=Check out this listing on Roomsie — ${encodeURI(this.propertyUrl())}`}>
              <Button hollow size="small" icon="email">Email</Button>
            </a>
          )}
          {isMobileBrowser() && (
            <a href={`sms:${isIOS() ? '&' : '?'}body=Check out this listing on Roomsie! ${encodeURI(this.propertyUrl())}`}>
              <Button hollow size="small" icon="phone">SMS</Button>
            </a>
          )}
        </div>
      </Fragment>
    )
  }
}

Options.propTypes = propTypes

export default Options
