import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TextareaAutoSize from 'react-textarea-autosize'
import '@/shared/styles/textarea.scss'

const propTypes = {
  line: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  minRows: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  getRef: PropTypes.func,
}

const defaultProps = {
  minRows: 1,
  onChange: (event, value) => {},
  getRef: (element) => {},
}

class Textarea extends Component {

  handleChange = e => {
    this.props.onChange(e, e.target.value)
  }

  render() {
    const {
      line,
      className,
      invalid,
      onChange,
      getRef,
      ...textareaProps
    } = this.props

    return (
      <div className={classnames(
        'textarea', {
          'textarea--line': line,
          'textarea--invalid': invalid,
        },
        className
      )}>
        <TextareaAutoSize
          {...textareaProps}
          className="textarea_element"
          onChange={this.handleChange}
          inputRef={getRef}
        />
      </div>
    )
  }
}

Textarea.propTypes = propTypes
Textarea.defaultProps = defaultProps

export default Textarea
