import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.min.css'
import '@/shared/styles/sliderRange.scss'

const propTypes = {
  range: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

class SliderRange extends Component {

  componentDidMount() {
    this.slider = noUiSlider.create(this.$slider, {
      connect: true,
      start: this.props.value,
      range: this.props.range,
      format: {
        to: value => Math.round(+value),
        from: value => Math.round(+value),
      }
    })
    this.slider.on('slide', this.props.onChange)
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.value, this.slider.get())) {
      this.slider.set(this.props.value)
    }
  }

  componentWillUnmount() {
    if (this.slider) this.slider.destroy()
  }

  render() {
    return (
      <div className="sliderRange" ref={el => this.$slider = el} />
    )
  }
}

SliderRange.propTypes = propTypes

export default SliderRange
