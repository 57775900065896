import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Button } from './'
import '@/shared/styles/pagination.scss'

const propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  totalCount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Pagination = ({
  className,
  currentPage,
  totalCount,
  perPage,
  onChange,
}) => {
  currentPage = +currentPage

  const pageStart = ((currentPage - 1) * perPage) + 1
  const pageEnd = Math.min((currentPage * perPage), totalCount)
  const allPages = _.range(1, (totalCount / perPage) + 1)

  const items = constructItems(allPages, currentPage)

  return (
    <div className={classnames('pagination', className)}>
      {currentPage > 1 && (
        <Button
          className="pagination_button"
          hollow
          size="small"
          icon="chevron-left"
          onClick={() => onChange(currentPage - 1)}
        />
      )}
      {items.map((page, i) => (
        page === 'dots' ? (
          <div className="pagination_dots" key={i}>...</div>
        ) : (
          <div
            key={i}
            onClick={() => {
              if (currentPage !== page) {
                onChange(page)
              }
            }}
            className={classnames(
              'pagination_item', {
                'pagination_item--isActive': currentPage === page,
              }
            )}
          >
            {page}
          </div>
        )
      ))}
      {currentPage < allPages.length && (
        <Button
          className="pagination_button"
          hollow
          size="small"
          icon="chevron-right"
          onClick={() => onChange(currentPage + 1)}
        />
      )}
      <div className="pagination_text">
        {`Showing ${pageStart} - ${pageEnd} of ${totalCount} results`}
      </div>
    </div>
  )
}

const constructItems = (allPages, currentPage) => {
  let prevDotsInserted = false
  let nextDotsInserted = false

  let items = allPages.map(page => {
    if (currentPage - page > 1) {
      if (!prevDotsInserted) {
        prevDotsInserted = true
        return 'dots'
      } else {
        return null
      }
    } else if (page - currentPage > 1) {
      if (!nextDotsInserted) {
        nextDotsInserted = true
        return 'dots'
      } else {
        return null
      }
    } else {
      return page
    }
  }).filter(p => p)

  if (items[0] === 'dots') {
    items.unshift(1)
  }
  if (items[items.length - 1] === 'dots') {
    items.push(allPages.length)
  }

  if (items[2] === 2) {
    items.splice(1, 1)
  }
  if (items[items.length - 3] === allPages.length - 1) {
    items.splice(items.length - 2, 1)
  }

  return items
}

Pagination.propTypes = propTypes

export default Pagination
