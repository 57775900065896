import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateLocationText, updateLocation } from '@/search/state/actions'
import { LocationSearch } from '@/shared/components'
import '@/search/styles/searchBox.scss'

const mapStateToProps = ({ search }) => ({
  locationText: search.query.locationText,
})

const mapDispatchToProps = { updateLocationText, updateLocation }

const propTypes = {
  locationText: PropTypes.string,
  updateLocationText: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
}

class SearchBox extends Component {

  constructor(props) {
    super(props)
    this.state = { value: props.locationText || '' }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationText !== this.props.locationText) {
      this.setState({ value: this.props.locationText || '' })
    }
  }

  render() {
    return (
      <div className="search_searchBox">
        <LocationSearch
          className="search_searchBox_inner"
          value={this.state.value}
          onSelect={({ center, suggestedZoom, name }) => {
            this.props.updateLocationText(name)
            this.props.updateLocation({ center, zoom: Math.min(suggestedZoom, 14) })
          }}
          onChange={value => {
            this.setState({ value })
            if (this.props.locationText && !value) {
              this.props.updateLocationText(undefined)
              this.props.updateLocation({ center: null, zoom: null })
            }
          }}
        />
      </div>
    )
  }
}

SearchBox.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
