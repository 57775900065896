import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { scrollToElement, getBodyScrollTop } from '@/shared/utils/dom'
import ShareProperty from '@/features/shareProperty/components'
import { propertyBasicsClassname } from './PropertyBasics'
import { hostClassname } from './Host'
import { locationClassname } from './Location'
import { Icon } from '@/shared/components'
import '@/property/styles/navigation.scss'

const propTypes = {
  property: PropTypes.object.isRequired,
}

const c = 'property_navigation'

class Navigation extends Component {

  state = { isVisible: false }

  componentDidMount() {
    window.addEventListener('scroll', this.setStickyNavigation)
    this.setStickyNavigation()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setStickyNavigation)
  }

  setStickyNavigation = () => {
    const isVisible = getBodyScrollTop() > 80
    if (this.state.isVisible !== isVisible) this.setState({ isVisible })
  }

  goTo = sectionClassname => {
    scrollToElement(document.querySelector(`.${sectionClassname}`), -48)
  }

  render() {
    return this.state.isVisible && (
      <div className={c}>
        <div className={`${c}_inner`}>
          <div className={`${c}_links`}>
            <div className={`${c}_link`} onClick={() => this.goTo(propertyBasicsClassname)}>Overview</div>
            &#183;
            <div className={`${c}_link`} onClick={() => this.goTo(hostClassname)}>Host</div>
            &#183;
            <div className={`${c}_link`} onClick={() => this.goTo(locationClassname)}>Location</div>
          </div>
          <ShareProperty
            property={this.props.property}
            renderLink={() => (
              <div className={`${c}_share`}>
                <Icon type="share" />
                Share
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

Navigation.propTypes = propTypes

export default Navigation
