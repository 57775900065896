import React from 'react'
import PropTypes from 'prop-types'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { Button } from '@/shared/components'
import ProgressBar from './ProgressBar'
import '@/book/styles/guestOrOther.scss'

const propTypes = {
  query: PropTypes.object.isRequired,
  isImportingBooking: PropTypes.bool.isRequired,
}

const c = 'book_guestOrOther'

const GuestOrOther = ({ query, isImportingBooking }) => {

  const location = useLocation()

  return (
    <div className={c}>
      {isImportingBooking && (
        <Navigate replace to={{ pathname: `${location.pathname}/other`, search: location.search }} />
      )}
      {query.programSessionId && (
        <Navigate replace to={{ pathname: `${location.pathname}/guest`, search: location.search }} />
      )}
      <h1 className={`${c}_title`}>Secure Booking</h1>
      <ProgressBar current={1} total={3} />
      <h2 className={`${c}_title_small`}>Are you a parent or other sponsor booking on behalf of someone else?</h2>
      <p className={`${c}_subtitle`}>
        {"If yes, please enter your son's, daughter's, student's or intern's information on the following page."}
      </p>
      <Link to={{ pathname: `${location.pathname}/guest`, search: location.search }}>
        <Button>{"No, I’m booking for myself"}</Button>
      </Link>
      <Link to={{ pathname: `${location.pathname}/other`, search: location.search }}>
        <Button>{"Yes, I’m booking for someone else"}</Button>
      </Link>
    </div>
  )
}
GuestOrOther.propTypes = propTypes

export default GuestOrOther
