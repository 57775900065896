import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleError } from '@/shared/api'
import countryCallCodes from '@/shared/static/countryCallCodes'
import { sendVerificationSms, verifyPhoneNumber } from '@/features/auth/state/actions'
import { Modal, Form, Button, Select, Field, Icon } from '@/shared/components'

const mapDispatchToProps = { sendVerificationSms, verifyPhoneNumber }

const propTypes = {
  sendVerificationSms: PropTypes.func.isRequired,
  verifyPhoneNumber: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}

const defaultProps = {
  onSuccess: () => {},
}

const c = 'authConfirm_phone'

class PhoneNumber extends Component {

  state = {
    requestPinFormIsActive: true,
    countryCode: countryCallCodes[0].code,
  }

  cleanNumber = number => (
    number.trim().replace(/\-|\/|\—|\s/g, '')
  )

  renderRequestPinForm = () => (
    <Form
      key="RequestPinForm"
      initialValues={{
        phoneNumber: '',
      }}
      validations={{
        phoneNumber: Form.is.required(),
      }}
      onSubmit={(values, form) => {
        this.props.sendVerificationSms(
          `+${this.state.countryCode}${this.cleanNumber(values.phoneNumber)}`
        ).then(() => {
          this.setState({ requestPinFormIsActive: false })
        }, ({ error }) => {
          if (error.details && error.details.number) {
            form.setErrors({ phoneNumber: error.details.number })
          } else if (error.details && error.details.sms) {
            form.setErrors({ phoneNumber: 'We could not send the SMS, please try again' })
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form onSubmit={handleSubmit}>
          <div className={c}>
            <Icon className={`${c}_icon`} type="mobile-phone-circle" />
            <h1 className={`${c}_title`}>
              Confirm your phone number
            </h1>
            <p className={`${c}_tip`}>
              This is so your hosts or guests can reach you during a trip.
            </p>
            <Select
              options={countryCallCodes}
              optionAttributes={o => ({ 'data-code': o.code })}
              onChange={(e, value) => {
                this.setState({
                  countryCode: e.target[e.target.selectedIndex].getAttribute('data-code'),
                })
                this.$input.focus()
              }}
            />
            <div className={`${c}_input_container`}>
              <div className={`${c}_input_prefix`}>
                {`+${this.state.countryCode}`}
              </div>
              <Field.Input
                {...fields.phoneNumber}
                placeholder="Phone Number"
                getRef={el => this.$input = el}
              />
            </div>
            <Button working={isSubmitting}>
              Send Verification PIN
            </Button>
            <div className={`${c}_change`} onClick={() => {
              this.setState({ requestPinFormIsActive: false })
            }}>
              I already have the PIN
            </div>
          </div>
        </form>
      )}
    />
  )

  renderVerifyNumberForm = () => (
    <Form
      key="VerifyNumberForm"
      initialValues={{
        pin: '',
      }}
      validations={{
        pin: Form.is.match(v => /^[0-9]{6}$/.test(v), 'Verification PIN must be a 6 digit number'),
      }}
      onSubmit={(values, form) => {
        this.props.verifyPhoneNumber(values.pin).then(() => {
          this.props.onSuccess()
        }, ({ error }) => {
          if (error.code === 'invalid_resource') {
            form.setErrors({
              pin: 'The PIN does not match. Make sure to enter PIN from the latest SMS we sent you.',
            })
          } else {
            handleError({ error })
          }
          form.setSubmitting(false)
        })
      }}
      render={({ handleSubmit, isSubmitting, fields }) => (
        <form onSubmit={handleSubmit}>
          <div className={c}>
            <Icon className={`${c}_icon`} type="mobile-phone-circle" />
            <h1 className={`${c}_title`}>
              Check your phone and enter the PIN we sent you
            </h1>
            <p className={`${c}_tip`}>
              You're almost there.
            </p>
            <Field.Input
              {...fields.pin}
              type="tel"
              autoComplete="off"
              className={`${c}_pin_input`}
              placeholder="Enter PIN"
            />
            <Button working={isSubmitting}>
              Verify Phone Number
            </Button>
            <div className={`${c}_change`} onClick={() => {
              this.setState({ requestPinFormIsActive: true })
            }}>
              Resend PIN
            </div>
          </div>
        </form>
      )}
    />
  )

  render() {
    return this.state.requestPinFormIsActive ?
      this.renderRequestPinForm() :
      this.renderVerifyNumberForm()
  }
}

PhoneNumber.propTypes = propTypes
PhoneNumber.defaultProps = defaultProps

export default connect(null, mapDispatchToProps)(PhoneNumber)
