import api from '@/shared/api'

export const fetchBookings = query => (
  api.get('/host/bookings', query, {
    abortCurrentRequest: 'hostBookingsFetchBookings',
  })
)

export const fetchBooking = bookingId => (
  api.get(`/host/bookings/${bookingId}`)
)

export const fetchPrograms = () => (
  api.get('/host/programs')
)

export const acceptBooking = bookingId => (
  api.patch(`/host/bookings/${bookingId}/accept`)
)

export const declineBooking = bookingId => (
  api.patch(`/host/bookings/${bookingId}/decline`)
)

export const cancelBooking = bookingId => (
  api.patch(`/host/bookings/${bookingId}/cancel`)
)

export const reviewBooking = (bookingId, payload) => (
  api.patch(`/host/bookings/${bookingId}/review`, payload)
)

export const extendBooking = (bookingId, dates) => (
  api.patch(`/host/bookings/${bookingId}/extend`, dates)
)

export const acceptExtendedBooking = (bookingId, withFees) => (
  api.patch(`/host/bookings/${bookingId}/accept_extend`, withFees)
)

export const rejectExtendedBooking = (bookingId) => (
  api.patch(`/host/bookings/${bookingId}/reject_extend`)
)

export const updateBooking = (bookingId, payload) => (
  api.patch(`/host/bookings/${bookingId}`, payload)
)

export const fetchProperties = query => (
  api.get('/host/properties', query)
)

export const fetchProperty = propertyId => (
  api.get(`/host/properties/${propertyId}`)
)

export const fetchAvailability = (propertyId, query) => (
  api.get(`/guest/properties/${propertyId}/availability`, query)
)

export const fetchMoveBedAvailability = (propertyId, query) => (
  api.get(`/guest/properties/${propertyId}/move_bed_availability`, query)
)

export const relocateBooking = (bookingId, listingId) => (
  api.patch(`/host/bookings/${bookingId}/relocate`, { listingId })
)
