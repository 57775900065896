import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import options from '@/shared/static/options'
import { Icon, Switch } from '@/shared/components'

const propTypes = {
  amenities: PropTypes.array.isRequired,
}

const c = 'property_moreInfo_amenities'

class Amenities extends Component {

  isAmenityMissing = amenity => (
    !this.props.amenities.find(val => val === amenity.value)
  )

  renderAllAmenities = () => (
    <div className={c}>
      {options.amenities.map((amenity, i) => (
        <div key={amenity.value} className={classnames(`${c}_item`, {
          [`${c}_item--missing`]: this.isAmenityMissing(amenity),
        })}>
          {!this.isAmenityMissing(amenity) && <Icon type="check" />}
          {amenity.label}
        </div>
      ))}
    </div>
  )

  renderPropertyAmenities = () => {
    const amenities = options.amenities.filter(amenity => (
      !this.isAmenityMissing(amenity)
    ))
    return (
      <div className={c}>
        {amenities.map((amenity, i) => (
          <div key={amenity.value} className={`${c}_item`}>
            <Icon type="check" />
            {amenity.label}
          </div>
        ))}
      </div>
    )
  }

  render() {
    return (
      <Switch
        linkText="See all amenities"
        shouldShowLink={() => (
          options.amenities.length > this.props.amenities.length
        )}
        renderDefault={this.renderPropertyAmenities}
        renderSwitch={this.renderAllAmenities}
      />
    )
  }
}

Amenities.propTypes = propTypes

export default Amenities
