import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { uniqueDateId } from '@/shared/utils/id'
import { Button, Field } from '@/shared/components'
import Bedroom from './Bedroom'

const propTypes = {
  property: PropTypes.object.isRequired,
  sessions: PropTypes.array.isRequired,
  bedrooms: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
}

const c = 'listSpace_roomsRates_bedrooms'

class Bedrooms extends Component {

  handleBedroomsCountSelect = count => {
    let { bedrooms, onChange, clearStepErrors } = this.props

    if (count < bedrooms.length) {
      if (confirm(
        `You are about to delete bedrooms ${+count + 1} through
        ${bedrooms.length}. Are you sure you want to continue?`
      )) {
        onChange(bedrooms.slice(0, count))
      }
    } else {
      onChange([
        ...bedrooms,
        ..._.times(count - bedrooms.length, i => (
          this.getNewBedroom(bedrooms.length + i + 1)
        ))
      ])
    }
    clearStepErrors('bedrooms.bedroomsCount')
  }

  handleBedroomAdd = () => {
    this.props.onChange([
      ...this.props.bedrooms,
      this.getNewBedroom(this.props.bedrooms.length + 1)
    ])
  }

  handleBedroomChange = bedroom => {
    this.props.onChange([
      ...this.props.bedrooms.filter(b => b.uid !== bedroom.uid),
      bedroom
    ])
    this.props.clearStepErrors(`bedrooms.${bedroom.uid}`)
  }

  handleBedroomRemove = bedroomId => {
    this.props.onChange(
      this.props.bedrooms.filter(b => b.uid !== bedroomId)
    )
  }

  handleBedroomClone = (hostBedroomUid, donorBedroomUid) => {
    const donorBedroom = this.removeIdsFromBedroom(
      _.cloneDeep(this.props.bedrooms.find(b => b.uid === +donorBedroomUid))
    )
    this.handleBedroomChange({
      ...donorBedroom,
      title: `${donorBedroom.title} Copy`,
      uid: hostBedroomUid,
    })
  }

  removeIdsFromBedroom = bedroom => {
    const withoutId = item => ({ ...item, id: undefined })
    return {
      ...withoutId(bedroom),
      photos: bedroom.photos.map(withoutId),
      beds: bedroom.beds.map(bed => ({
        ...withoutId(bed),
        rates: bed.rates.map(withoutId),
      })),
    }
  }

  getNewBedroom = number => ({
    uid: uniqueDateId(),
    bathroomAttached: false,
    title: `Bedroom ${number}`,
    description: '',
    beds: [],
    photos: [],
  })

  getSortedBedrooms = () => (
    this.props.bedrooms.sort((a, b) => a.uid - b.uid)
  )

  render() {
    return (
      <div className={c}>
        <div className="listSpace_label">
          How many bedrooms are available for Roomsie guests?
        </div>
        <Field.Select
          className={`${c}_bedroomsCountSelect`}
          placeholder="Select the number of bedrooms"
          value={this.props.bedrooms.length || ''}
          options={_.range(1, 21).map(i => ({
            value: i,
            label: `${i} Bedroom${i === 1 ? '' : 's'}`,
          }))}
          onChange={(e, value) => this.handleBedroomsCountSelect(value)}
          errors={this.props.errors.bedroomsCount}
        />
        {!!this.props.bedrooms.length && (
          <Fragment>
            <div className={`${c}_list`}>
              {this.getSortedBedrooms().map((bedroom, i) => (
                <Bedroom
                  key={i}
                  index={i}
                  property={this.props.property}
                  updateProperty={this.props.updateProperty}
                  bedroom={bedroom}
                  bedrooms={this.props.bedrooms}
                  errors={this.props.errors[bedroom.uid] || {}}
                  sessions={this.props.sessions}
                  onBedroomChange={this.handleBedroomChange}
                  onBedroomRemove={this.handleBedroomRemove}
                  onBedroomClone={this.handleBedroomClone}
                />
              ))}
            </div>
            <div className={`${c}_addBedroom_container`}>
              <Button
                hollow
                className={`${c}_addBedroom`}
                size="small"
                type="button"
                icon="plus"
                onClick={this.handleBedroomAdd}
              >
                Add Bedroom
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}

Bedrooms.propTypes = propTypes

export default Bedrooms
