import queryString from 'query-string'
import _ from 'lodash'

export const objectToQueryString = (obj, options = {}) => (
  queryString.stringify(_.omitBy(obj, _.isNil), {
    arrayFormat: 'bracket',
    ...options,
  })
)

export const queryStringToObject = (str, options = {}) => (
  queryString.parse(str, {
    arrayFormat: 'bracket',
    ...options,
  })
)

export const pickFromQueryString = (str, keys) => (
  objectToQueryString(
    _.pick(queryStringToObject(str), keys)
  )
)

export const omitFromQueryString = (str, keys) => (
  objectToQueryString(
    _.omit(queryStringToObject(str), keys)
  )
)

export const addToQueryString = (str, params) => (
  objectToQueryString({
    ...queryStringToObject(str),
    ...params,
  })
)

export const joinPathnameSearch = (pathname, search) => (
  `${pathname}${search ? '?' : ''}${search}`.replace('??', '?')
)

export const splitPathnameSearch = url => ({
  pathname: url.split('?')[0],
  search: url.split('?')[1] || '',
})
