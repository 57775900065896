import React from 'react'
import PropTypes from 'prop-types'
import { timeOptions } from '@/shared/static/dateTime'
import { dateToString } from '@/shared/utils/dateTime'
import { showToast } from '@/shared/utils/toast'
import { isCurrentUserVerified } from '@/features/auth/utils'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { handleError } from '@/shared/api'
import { createTour } from '@/property/api'
import { Modal, Button, Field, Form } from '@/shared/components'
import '@/property/styles/requestTour.scss'

const propTypes = {
  propertyId: PropTypes.number.isRequired,
}

const c = 'property_requestTour'

const RequestTour = ({ propertyId }) => {

  const { toAuth } = useAuthUrl()

  if (!isCurrentUserVerified()) {
    return <Button size="small" className={`${c}_link`} onClick={toAuth}>Request Tour</Button>
  }

  return (
    <Modal
      className={c}
      containsForm
      renderLink={() => (
        <Button size="small" className={`${c}_link`}>Request Tour</Button>
      )}
      renderContent={({ closeModal }) => (
        <Form
          initialValues={{
            date: null,
            time: '',
          }}
          validations={{
            date: Form.is.required(),
            time: Form.is.required(),
          }}
          onSubmit={(values, form) => {
            createTour(propertyId, {
              date: dateToString(values.date),
              time: values.time,
            }).then(() => {
              closeModal()
              showToast({ title: 'Your tour was booked successfully.' })
            }, ({ error }) => {
              if (error.details) {
                form.setErrors(error.details)
              } else {
                handleError({ error })
              }
              form.setSubmitting(false)
            })
          }}
          render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className={`${c}_title`}>
                Request a Tour
              </div>
              <p className={`${c}_message`}>
                {"Tour availability is subject to a number of various factors and is offered or not offered at the sole discretion of the host. We understand the value in personally visiting a property. However, transacting outside of the Roomsie platform makes you ineligible for protection under Roomsie's policies and terms."}
              </p>
              <Field.DatePicker
                {...fields.date}
                label="Select tour date"
                placeholder="Date"
                onChange={value => setFieldValue('date', value)}
              />
              <Field.Select
                {...fields.time}
                label="Select tour time"
                options={[{ label: 'Time', value: '' }, ...timeOptions]}
              />
              <Button hollow color="secondary" onClick={closeModal}>Cancel</Button>
              <Button type="submit" working={isSubmitting}>Request Tour</Button>
            </form>
          )}
        />
      )}
    />
  )
}

RequestTour.propTypes = propTypes

export default RequestTour
