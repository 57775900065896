import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import * as api from '@/hostMessages/api'
import { Head, Form, Button, Field, Checkbox, Spinner,
  DashboardMenu, FieldLabel, Icon, AssignProperties } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import '@/hostMessages/styles/formInstant.scss'

const c = 'hostMessagesFormInstant'

const HostMessagesFormInstant = () => {
  const navigate = useNavigate()
  const [properties, setProperties] = useState(null)

  useEffect(() => {
    analytics.pageview('Host Guest Messaging Instant Form')

    api.fetchProperties().then(({ data }) => {
      setProperties(data)
    }, handleError)
  }, [])

  const handleSubmit = (values, form) => {
    api.sendInstantMessage({
      ...values,
      bookingStatus: values.moment === 'current' ? 'started' : 'accepted',
      days: values.moment === 'all upcoming' ? 1500 : (
        values.moment === 'upcoming' ? +values.days : undefined
      ),
    }).then(() => {
      navigate('/host/scheduled-messaging')
      showToast({ title: 'Your message was sent successfully.' })
    }, ({ error }) => {
      if (error.details) {
        form.setErrors(error.details)
      } else {
        handleError({ error })
      }
      form.setSubmitting(false)
    })
  }

  return (
    <div className={c}>
      <Head title="Send Instant Message - Roomsie" />
      <AppHeader />
      <DashboardMenu role="host" />
      <FormInstant properties={properties} handleSubmit={handleSubmit} />
      <AppFooter />
    </div>
  )
}

const FormInstant = ({ properties, handleSubmit }) => (
  <Form
    enableReinitialize
    initialValues={{
      subject: '',
      body: '',
      moment: 'current',
      days: 7,
      propertyIds: [],
      includeBooking: true,
    }}
    validations={{
      subject: [Form.is.required(), Form.is.max(70)],
      body: [Form.is.required(), Form.is.max(10000)],
      propertyIds: Form.is.min(1, 'Please add at least 1 property'),
    }}
    onSubmit={handleSubmit}
    render={(props) => (
      <FormContent properties={properties} {...props} />
    )}
  />
)

const FormContent = ({ properties, handleSubmit, isSubmitting, fields, setFieldValue }) => {
  const renderScheduleDescription = ({ moment, days }) => {
    if (moment.value === 'current') {
      return 'Message will be sent instantly to guests who are <span>currently staying in selected properties</span>.'
    } else if (moment.value === 'all upcoming') {
      return 'Message will be sent instantly to guests who have a <span>confirmed booking in selected properties</span> but have <span>not checked-in yet</span>.'
    } else if (moment.value === 'upcoming') {
      return `Message will be sent instantly to guests who have a <span>confirmed booking in selected properties</span> and are expected to <span>check-in within the next ${days.value} days</span>.`
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className={`${c}_container--white`}>
        <div className={`${c}_breadcrumbs`}>
          <Link to="/host/scheduled-messaging">Message Templates</Link>
          {' / Send Instant'}
        </div>
        <h1 className={`${c}_title`}>
          Send Instant Message
        </h1>
        <div className={`${c}_subtitle`}>
          Send a one-off, instant message to all current or future guests in selected properties.
        </div>
        <div className={`${c}_recipients_title`}>
          <Icon type="people" />
          Recipients
        </div>
        <div
          className={`${c}_recipients_description`}
          dangerouslySetInnerHTML={{ __html: renderScheduleDescription(fields) }}
        />
      </div>
      <div className={`${c}_container--grey`}>
        <div className={`${c}_container--grey_inner`}>
          <div className={`${c}_schedule`}>
            <FieldLabel>Send message to</FieldLabel>
            <Field.Select
              {...fields.moment}
              options={['current', 'all upcoming', 'upcoming'].map(v => ({
                value: v,
                label: `${_.upperFirst(v)} guests`,
              }))}
            />
            {fields.moment.value === 'upcoming' && (
              <Field.Select
                {...fields.days}
                className={`${c}_schedule_daysSelect`}
                options={_.range(1, 61).map(v => ({
                  value: v,
                  label: `Checking in within ${_.upperFirst(v)} days`,
                }))}
              />
            )}
            <FieldLabel>From these properties:</FieldLabel>
          </div>
          {properties ? (
            <AssignProperties
              allProperties={properties}
              selectedProperties={fields.propertyIds.value.map(id => (
                properties.find(p => p.id === id)
              )).filter(p => p)}
              onChange={properties => setFieldValue('propertyIds', properties.map(p => p.id))}
              errors={fields.propertyIds.errors}
            />
          ) : (
            <Spinner size={50} />
          )}
        </div>
      </div>
      <div className={`${c}_container--white`}>
        <Field.Input {...fields.subject} label="Message Subject" />
        <Field.Textarea minRows={5} {...fields.body} label="Message Body" />
        <Checkbox
          {...fields.includeBooking}
          className={`${c}_bookingCheckbox`}
          label="Include booking dates and basic property information at the bottom of the email (recommended)"
          checked={!!fields.includeBooking.value}
        />
        <div className={`${c}_actions`}>
          <Link to="/host/scheduled-messaging">
            <Button hollow type="button">Cancel</Button>
          </Link>
          <Button type="submit" working={isSubmitting}>
            Send Message Now
          </Button>
        </div>
      </div>
    </form>
  )
}



export default HostMessagesFormInstant
