import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import { fetchBooking, createReview } from '@/leaveReview/api'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { formatAddress } from '@/shared/utils/geo'
import { formatDate } from '@/shared/utils/dateTime'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { Head, Form, Field, Button, Icon, Photo, Avatar } from '@/shared/components'
import LogoBlack from '@/shared/assets/images/LogoBlack.svg'
import '@/leaveReview/styles/index.scss'

const LeaveReview = () => {
  
  const { bookingId } = useParams()
  const navigate = useNavigate()
  const { toOpenSigninAndReload } = useAuthUrl()

  
  const [booking, setBooking] = useState(null)

  useEffect(() => {
    analytics.pageview('Leave Review', { bookingId })

    fetchBooking(bookingId, false).then(({ data }) => {
      setBooking(data)
    }, () => {
      toOpenSigninAndReload("/leave-review/" + bookingId)
    })
  }, [])

  const handleSubmit = (values, form) => {
    createReview(bookingId, values).then(() => {
      showToast({
        title: 'Thank you!',
        message: 'Your review was submitted successfully.',
      })
      navigate('/', { replace: true })
    }, ({ error }) => {
      if (error.details) {
        form.setErrors(error.details)
        if (error.details.bookingId) {
          showToast({
            type: 'danger',
            title: 'You have already reviewed your stay.',
          })
        }
      } else {
        handleError({ error })
      }
      form.setSubmitting(false)
    })
  }

  return (
    <div className="leaveReview">
      <Head title="Leave Review - Roomsie" />
      <div className="leaveReview_navigation">
        <Link to="/"><img src={LogoBlack} /></Link>
      </div>
      <div className="leaveReview_page">
        {!!booking && (
          <div className="leaveReview_property">
            <Photo photo={booking.propertySnapshot.photo}>
              <Avatar {...booking.host} size={70} />
            </Photo>
            <div className="leaveReview_property_title">
              {booking.propertySnapshot.title}
            </div>
            <div className="leaveReview_property_address">
              {formatAddress(booking.propertySnapshot)}
            </div>
            <div className="leaveReview_dates">
              <span>{formatDate(booking.startDate)}</span>
              <Icon type="chevron-right" />
              <span>{formatDate(booking.endDate)}</span>
            </div>
          </div>
        )}
        <div className="leaveReview_right">
          <h1 className="leaveReview_title">Leave a review</h1>
          <p className="leaveReview_description">
            Thank you for staying with Roomsie. Help your host by leaving a short review, telling other students & interns how your stay was.
          </p>
          <Form
            initialValues={{
              accuracy: 5,
              location: 5,
              communication: 5,
              safety: 5,
              value: 5,
              body: '',
              privateComments: '',
            }}
            validations={{
              body: [Form.is.required(), Form.is.max(1000)],
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit, isSubmitting, fields, setFieldValue }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Field.Textarea
                  {...fields.body}
                  minRows={3}
                  label="Describe Your Experience"
                  placeholder="What was it like to stay in this property?"
                />
                <div className="leaveReview_field_description">
                  {"Your review will be public on your hosts listings and profile."}
                </div>
                <Field.Textarea
                  {...fields.privateComments}
                  minRows={3}
                  label="Private Guest Feedback (optional)"
                  placeholder="Thank your host or offer some tips to help them improve."
                />
                <div className="leaveReview_field_description">
                  {"This feedback is just for your host. We won't make it public."}
                </div>
                <div className="leaveReview_ratings">
                  <Rating
                    type="accuracy"
                    label="Accuracy"
                    fields={fields}
                    setFieldValue={setFieldValue}
                  />
                  <Rating
                    type="location"
                    label="Location"
                    fields={fields}
                    setFieldValue={setFieldValue}
                  />
                  <Rating
                    type="communication"
                    label="Communication"
                    fields={fields}
                    setFieldValue={setFieldValue}
                  />
                  <Rating
                    type="safety"
                    label="Safety"
                    fields={fields}
                    setFieldValue={setFieldValue}
                  />
                  <Rating
                    type="value"
                    label="Value"
                    fields={fields}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <Button type="submit" working={isSubmitting} className="leaveReview_submit">
                  Submit Review
                </Button>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  ) 
}

const Rating = ({ type, label, fields, setFieldValue }) => {
  const [hover, setHover] = useState(null)

  const renderRatingDescription = type => {
    switch (type) {
      case 'accuracy':
        return "Was everything as advertised?"
      case 'location':
        return "Were you satisfied with the location?"
      case 'communication':
        return "Was the host responsive and clear in their communication?"
      case 'safety':
        return "Did you feel safe in this property?"
      case 'value':
        return "Was it a good value for the money?"
    }
  }

  return (
    <div className="leaveReview_rating">
      <div className="leaveReview_rating_label">{label}</div>
      <div className="leaveReview_field_description">
        {renderRatingDescription(type)}
      </div>
      {_.times(5, i => (
        <Icon
          key={i}
          className={i + 1 <= (hover || fields[type].value) ?
            'leaveReview_rating_star--isActive' : ''
          }
          type="star-full"
          onClick={() => setFieldValue(type, i + 1)}
          onMouseEnter={() => setHover(i + 1)}
          onMouseLeave={() => setHover(null)}
        />
      ))}
    </div>
  )
}

export default LeaveReview
