import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Checkbox, CheckboxGroup, FieldLabel, FieldErrors, Icon } from '@/shared/components'
import '@/shared/styles/assignProperties.scss'

const propTypes = {
  allProperties: PropTypes.array.isRequired,
  selectedProperties: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.any,
}

const c = 'assignProperties'

class AssignProperties extends Component {

  availableProperties = () => _.differenceBy(
    this.props.allProperties, this.props.selectedProperties, 'id'
  )

  toggleProperty = property => this.props.onChange(
    this.props.selectedProperties.find(p => p.id === property.id) ? (
      this.props.selectedProperties.filter(p => p.id !== property.id)
    ) : (
      _.uniqBy([property, ...this.props.selectedProperties], 'id')
    )
  )

  addAll = () => this.props.onChange(this.props.allProperties)
  removeAll = () => this.props.onChange([])

  renderProperty = property => property && (
    <div key={property.id} className={`${c}_property`}>
      <span onClick={() => this.toggleProperty(property)}>
        <div className={`${c}_property_title`}>
          {property.title}
        </div>
        <div className={`${c}_property_address`}>
          {[property.streetAddress, property.streetAddress2].filter(p => p).join(', ')}
        </div>
      </span>
      <Link to={`/properties/${property.id}`} target="_blank">
        <Icon type="share" />
      </Link>
    </div>
  )

  render() {
    return this.props.allProperties && (
      <div className={c}>
        <div className={`${c}_container`}>
          <div className={`${c}_top`}>
            <div className={`${c}_label`}>Available Properties</div>
            <div className={`${c}_addRemoveAll`} onClick={this.addAll}>
              Add All
            </div>
          </div>
          <div className={`${c}_list`}>
            {this.availableProperties().map(this.renderProperty)}
          </div>
          {this.props.errors && <FieldErrors errors={this.props.errors} />}
        </div>
        <div className={`${c}_middle`}>
          <Icon type="switch" />
        </div>
        <div className={`${c}_container`}>
          <div className={`${c}_top`}>
            <div className={`${c}_label`}>Selected Properties</div>
            <div className={`${c}_addRemoveAll`} onClick={this.removeAll}>
              Remove All
            </div>
          </div>
          <div className={`${c}_list`}>
            {this.props.selectedProperties.map(this.renderProperty)}
          </div>
        </div>
      </div>
    )
  }
}

AssignProperties.propTypes = propTypes

export default AssignProperties
