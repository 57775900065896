import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { formatPrice } from '@/shared/utils/numbers'
import '@/shared/styles/bookingFees.scss'

const propTypes = {
  rates: PropTypes.object.isRequired,
}

const BookingFeesGuest = ({ rates }) => {
  const sessionOverlap = rates?.bookableRates?.length > 1
  
  const renderItem = (label, kind) => {
    const item = rates.items.find(i => i.kind === kind)
    return item && renderFee(label, item.total)
  }

  const renderFee = (label, total) => {
    return (
      <div key={label} className="bookingFees_fee">
        {label}
        <span>
          {`${total < 0 ? '—' : ''}
            ${formatPrice(Math.abs(total), { precise: true })}`}
        </span>
      </div>
    )
  }
  const renderRoomsieFee = (total) => {
    return (
      <div key="Roomsie Fees" className="bookingFees_fee">
        Roomsie Fees
        <span>
          Free
        </span>
      </div>
    )
  }
  const renderSessionRate = (rate, i) => {
    const rateTotal = rate.rateAmount*rate.stayLength
     return (
      <div key={`sessionRate-${i}`} className="bookingFees_sessionRate_container">
        <div className="bookingFees_sessionRate_label">
          {`${formatPrice(rate.rateAmount, { precise: true })} x ${rate.stayLength} Nights`} 
        </div>
        <div>
          {`${rateTotal < 0 ? '—' : ''}
            ${formatPrice(Math.abs(rateTotal), { precise: true })}`}
        </div> 
      </div>
    )
  }
  return (
    <div className="bookingFees">
      {sessionOverlap ? (
          <div>
            {rates.bookableRates.map((rate, i) => renderSessionRate(rate, i))}
            <div className="bookingFees_sessionRate_rentalTotal">
              {renderItem('Rental', 'rental')}
            </div>
          </div>
        ) : (
          !!rates?.prorateForShortStay ? (
            renderItem(
              `${formatPrice(rates.avgRate, { precise: true })} x ${rates.stayLength} Nights`,
              'rental'
            )
          ) : (
            renderItem(
              `Housing Cost`,
              'rental'
            )
          )
        )
      }
      {renderItem('Cleaning Fee', 'cleaning')}
      {renderItem('Parking Fee', 'parking')}
      {renderItem('Pets Fee', 'pets')}
      {renderItem('Gym Membership Fee', 'gym')}
      {renderItem('Security Deposit', 'security_deposit')}
      {renderItem('Occupancy Tax', 'taxes')}
      {renderRoomsieFee()}

      <div className="bookingFees_total">
        {rates.hostPayout ? (
          renderFee('Total Payout', rates.hostPayout - (rates.hostPayout * 0.075))
        ) : (
          renderFee('Grand Total', rates.total)
        )}
      </div>
    </div>
  )
}

BookingFeesGuest.propTypes = propTypes

export default BookingFeesGuest
