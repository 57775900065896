import React, { Component } from 'react'
import * as analytics from '@/shared/utils/analytics'
import { DashboardGuestSideMenu, Head } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import GuestPaymentForm from '@/features/guestPaymentForm/components'
import FailedPayments from './FailedPayments'
import '@/guestPayment/styles/index.scss'

class GuestPayment extends Component {

  componentDidMount() {
    analytics.pageview('Guest Payment Center')
  }

  render() {
    return (
      <div className="guestPayment">
        <Head title="Guest Payment Center - Roomsie" />
        <AppHeader />
        <DashboardGuestSideMenu>
          <h1>Billing Info</h1>
          <FailedPayments />
          <GuestPaymentForm
            onCreditCardSubmitSuccess={() => {
              analytics.track('Guest Payment Center Credit Card Submitted')
            }}
            onCreditCardSubmitError={errorString => {
              analytics.track('Guest Payment Center Credit Card Submitted', {
                error: errorString || 'true',
              })
            }}
            onCustomerFormSubmitSuccess={() => {
              analytics.track('Guest Payment Center Billing Info Submitted')
            }}
            onCustomerFormSubmitError={errorString => {
              analytics.track('Guest Payment Center Billing Info Submitted', {
                error: errorString || 'true',
              })
            }}
          />
        </DashboardGuestSideMenu>
        <AppFooter />
      </div>
    )
  }
}

export default GuestPayment
