import React from "react";
import { useOutletContext } from "react-router-dom";

const StepComponent = (props) => {
  const { id, path, Component } = props
  
  const [ 
    state, 
    updateProperty, 
    validateStep, 
    clearStepErrors, 
    submitForm, 
    isEdit, 
    steps, 
    toStep, 
    goToStep 
   ] = useOutletContext()

  return (
    <Component
      property={state.property}
      sessions={state.property.sessions}
      errors={state.errors[id]}
      updateProperty={updateProperty}
      validateStep={validateStep(id)}
      clearStepErrors={clearStepErrors(id)}
      isSubmitting={state.isSubmitting}
      submitForm={submitForm}
      isEdit={isEdit()}
      steps={steps}
      toStep={toStep}
      goToStep={goToStep}
    />
  )
}

export default StepComponent