import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import colors from '@/shared/static/colors';
import { countryNames } from '@/shared/static/countries';
import { countryCodes2 } from '@/shared/static/countries';
import stripeClientLoader from '@/shared/hocs/stripeClientLoader';
import { createPluginBooking } from '../api';
import { handleError } from '@/shared/api';
import { formatPrice } from '@/shared/utils/numbers';
import { Button, Field, Modal } from '@/shared/components';
import { AvailabilityInfo, FieldsRequired, PolicyInfo } from './StaticInfo';
import PrevNext from './PrevNext';
import Details from './Details';
import visa from '@/features/guestPaymentForm/assets/visa.jpg';
import mastercard from '@/features/guestPaymentForm/assets/mastercard.jpg';
import amex from '@/features/guestPaymentForm/assets/amex.jpg';
import discover from '@/features/guestPaymentForm/assets/discover.png';
import '@/plugin/property/styles/payment.scss';

const propTypes = {
  query: PropTypes.object.isRequired,
  stripe: PropTypes.func,
  rates: PropTypes.object,
  sessions: PropTypes.array,
  selectedBedroom: PropTypes.object,
  userInput: PropTypes.object.isRequired,
  updateUserInput: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const c = 'plugin_payment';

const cardIcons = {
  Visa: visa,
  MasterCard: mastercard,
  Discover: discover,
  'American Express': amex,
};

class Payment extends Component {
  state = {
    isSubmitting: false,
    isEditing: true,
    promoCode: '',
    total: '',
  };

  componentDidMount() {
    this.toggleStripeElements();
  }

  componentDidUpdate() {
    this.toggleStripeElements();
  }

  toggleStripeElements = () => {
    if (!this.props.stripe) return;
    if (this.state.isEditing) {
      if (!this.stripe) this.initStripeCreditCardForm();
    } else {
      this.stripe = null;
    }
    if (!this.stripe) this.initStripeCreditCardForm();
  };

  // TODO font
  initStripeCreditCardForm = () => {
    this.stripe = this.props.stripe(window.STRIPE_PUBLISHABLE_KEY);
    this.card = this.stripe.elements().create('card', {
      style: {
        base: {
          color: colors.text,
          fontFamily: 'sans-serif',
          fontSize: '16px',
          fontWeight: '400',
        },
      },
      hidePostalCode: true,
    });
    this.card.mount(this.$creditCardInput);
    this.card.addEventListener('change', (event) => {
      this.$creditCardErrors.textContent = event.error
        ? event.error.message
        : '';
    });
  };

  formatMonth = (month) => (`${month}`.length === 1 ? `0${month}` : month);

  handleChange = (values) => {
    this.props.updateUserInput(values);
    this.props.clearStepErrors(_.keys(values));
  };

  handleCreditCardSubmit = (closeModal) => {
    this.setState({ isSubmitting: true });
    this.stripe
      .createToken(this.card, {
        name: this.props.userInput.billingName,
        address_line1: this.props.userInput.billingLine1,
        address_line2: this.props.userInput.billingLine2,
        address_city: this.props.userInput.billingCity,
        address_state: this.props.userInput.billingState,
        address_zip: this.props.userInput.billingPostalCode,
        address_country: _.get(
          countryCodes2.find(
            (c) => c.label === this.props.userInput.billingCountry
          ),
          'value'
        ),
      })
      .then(
        (result) => {
          if (result.error) {
            this.$creditCardErrors.textContent = result.error.message;
            this.setState({ isSubmitting: false });
            closeModal();
          } else {
            this.props.userInput.stripeTokenId = result.token.id;
            createPluginBooking({
              ...this.props.userInput,
              listingId: this.props.query.selectedListingId,
              startDate: this.props.query.startDate,
              endDate: this.props.query.endDate,
            }).then(
              () => {
                this.setState({ isSubmitting: false });
                this.onSuccess();
              },
              ({ error }) => {
                handleError({
                  error: {
                    description:
                      error.details || error.description || error.message,
                  },
                });
                this.setState({ isSubmitting: false });
                closeModal();
              }
            );
          }
        },
        () => {}
      );
  };

  handleSubmit = (closeModal) => {
    if (this.props.validateStep().isValid) {
      this.props.submitForm();
      this.handleCreditCardSubmit(closeModal);
    } else {
      closeModal();
    }
  };

  onSuccess = () => {
    this.props.goToStep('confirmation');
  };

  renderCreditCardForm = (userInput) => (
    <div className={`${c}_creditCard_form`}>
      <div className={`${c}_creditCard_form_inputContainer`}>
        <div
          className={`${c}_creditCard_form_input`}
          ref={(el) => (this.$creditCardInput = el)}
        />
        <div
          className={`${c}_creditCard_form_error`}
          ref={(el) => (this.$creditCardErrors = el)}
        />
      </div>
      <div className={`${c}_creditCard_form_iconsContainer`}>
        <div className={`${c}_creditCard_form_acceptedCards`}>
          Accepted Cards
        </div>
        <div className={`${c}_creditCard_form_icons`}>
          {_.values(cardIcons).map((image) => (
            <img
              key={image}
              src={image}
              className={`${c}_creditCard_form_icon`}
            />
          ))}
        </div>
      </div>
    </div>
  );

  renderCardIcon = (type) =>
    cardIcons[type] && (
      <img className={`${c}_show_icon`} src={cardIcons[type]} />
    );

  renderBillingInfoForm = (userInput) => (
    <div className={`${c}_paymentForm`}>
      <div className={`${c}_paymentForm_fields`}>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="name"
            label="Full Name"
            value={userInput.billingName}
            onChange={(e, value) => this.handleChange({ billingName: value })}
            errors={this.props.errors.billingName}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="address1"
            label="Street Address"
            value={userInput.billingLine1}
            onChange={(e, value) => this.handleChange({ billingLine1: value })}
            errors={this.props.errors.billingLine1}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="address2"
            label="Street Address 2"
            value={userInput.billingLine2}
            onChange={(e, value) => this.handleChange({ billingLine2: value })}
            errors={this.props.errors.billingLine2}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="city"
            label="City"
            value={userInput.billingCity}
            onChange={(e, value) => this.handleChange({ billingCity: value })}
            errors={this.props.errors.billingCity}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="state"
            label="State"
            value={userInput.billingState}
            onChange={(e, value) => this.handleChange({ billingState: value })}
            errors={this.props.errors.billingState}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="zip"
            label="Postal Code"
            value={userInput.billingPostalCode}
            onChange={(e, value) =>
              this.handleChange({ billingPostalCode: value })
            }
            errors={this.props.errors.billingPostalCode}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Select
            className={'countrySelection_select'}
            name="country"
            label="Country"
            value={userInput.billingCountry}
            options={[{ value: '', label: 'Select' }, ...countryNames]}
            onChange={(e, value) =>
              this.handleChange({ billingCountry: value })
            }
            errors={this.props.errors.billingCountry}
          />
        </div>
        <div className={`${c}_inputContainer`}>
          <Field.Input
            name="phone"
            label="Phone Number"
            value={userInput.billingPhone}
            onChange={(e, value) => this.handleChange({ billingPhone: value })}
            errors={this.props.errors.billingPhone}
          />
        </div>
      </div>
    </div>
  );

  renderPromoCodeInput = () => (
    <div className={`${c}_promoCode`}>
      <Field.Input
        name="promoCode"
        label="Promo Code (optional)"
        value={this.state.promoCode}
        onChange={(e, value) => this.setState({ promoCode: value })}
      />
    </div>
  );

  render() {
    const { userInput, query, rates, selectedBedroom, sessions } = this.props;

    return (
      <div className={`${c}`}>
        <Details
          selectedBedroom={selectedBedroom}
          rates={rates}
          userInput={userInput}
          query={query}
          sessions={sessions}
          label="Amount Due Today:"
        />
        <AvailabilityInfo />
        <div className={`${c}_paymentMethod`}>
          <div className={`${c}_paymentMethod_header`}>
            <h2>Payment Method</h2>
          </div>
          <div className={`${c}_paymentMethod_body`}>
            <h3>Credit Card Information</h3>
            {this.renderCreditCardForm(userInput)}
            <h3>Billing Information</h3>
            {this.renderBillingInfoForm(userInput)}
            {this.renderPromoCodeInput()}
          </div>
        </div>
        <PolicyInfo />
        <FieldsRequired />
        <Modal
          className={`${c}_confirmModal`}
          renderLink={() => (
            <PrevNext
              label="Book the Room"
              onPrevClick={() => this.props.goToStep('prev')}
            />
          )}
          renderContent={({ closeModal }) => (
            <Fragment>
              <h3>{`If the host accepts your booking, you will be charged ${formatPrice(
                this.props.rates?.charges[0].total
              )} immediately.`}</h3>
              <p>{`Before proceeding with your request, please make sure you have taken the following steps to insure a successful transaction:`}</p>
              <p>
                <span>{`1) Contact your bank at the number listed on the back of your card to inform them of a charge in this amount. Sometimes banks will mistakenly flag larger transactions as fraudulent and decline the charges.`}</span>
              </p>
              <p>
                <span>{`2) Insure that your available credit or debit balance is enough to cover this, and any other, Roomsie related charges or fees.`}</span>
              </p>
              <p>{`Failure to follow these two simple steps can, and may, result in the cancellation of your reservation.`}</p>
              <Button
                hollow
                working={this.state.isSubmitting}
                onClick={() => {
                  this.handleSubmit(closeModal);
                }}
              >
                I understand. Send my booking request!
              </Button>
            </Fragment>
          )}
        />
      </div>
    );
  }
}

Payment.propTypes = propTypes;

export default stripeClientLoader(Payment);
