import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import Flickity from 'flickity'
import * as device from '@/shared/utils/device'
import 'flickity/css/flickity.css'
import '@/shared/styles/slider.scss'

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.array.isRequired,
  largeDots: PropTypes.bool,
}

class Slider extends Component {

  state = { flickityInitialized: false }

  componentDidMount() {
    this.setSliderWidth()
    window.addEventListener('resize', this.setSliderWidth)

    this.flickity = new Flickity(this.$slider, {
      draggable: true,
      cellSelector: '.slider_cell',
      cellAlign: 'left',
      wrapAround: true,
      setGallerySize: false,
      dragThreshold: 20,
    })

    this.setState({ flickityInitialized: true })
  }

  componentDidUpdate(prevProps, prevState) {
    // Portal did mount
    if (!prevState.flickityInitialized && this.state.flickityInitialized) {
      this.flickity.reloadCells()
      this.flickity.resize()
      this.initializeLazyLoad()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSliderWidth)
    setTimeout(() => {
      this.flickity.destroy()
      this.flickity = null
    }, 1000)
  }

  initializeLazyLoad = () => {
    if (this.flickity.selectedElement) {
      this.loadElementImages(this.flickity.selectedElement)
      this.preloadImageOnFirstDrag()
      this.preloadImageOnFirstHover('previous')
      this.preloadImageOnFirstHover('next')
      this.preloadImagesOnSelect()
    }
  }

  preloadImagesOnSelect = () => {
    // we track current index because 'select' event triggers
    // on window resize and we don't want to preload images then
    let currentIndex = this.flickity.selectedIndex

    this.flickity.on('select', () => {
      if (currentIndex !== this.flickity.selectedIndex) {
        this.getAdjacentCellElements().forEach(this.loadElementImages)
      }
      currentIndex = this.flickity.selectedIndex
    })
  }

  preloadImageOnFirstHover = direction => {
    const $button = this.$slider.querySelector(`.flickity-prev-next-button.${direction}`)

    const onMouseover = () => {
      this.loadElementImages(
        direction === 'next' ? this.getNextCellElement() : this.getPrevCellElement()
      )
      $button.removeEventListener('mouseover', onMouseover)
    }
    $button.addEventListener('mouseover', onMouseover)
  }

  preloadImageOnFirstDrag = () => {
    this.flickity.once('dragStart', () => {
      this.loadElementImages(this.getNextCellElement())
    })
  }

  getPrevCellElement = () => {
    const cells = this.flickity.getCellElements()
    const selectedIndex = this.flickity.selectedIndex
    return cells[(selectedIndex + (cells.length - 1)) % cells.length]
  }

  getNextCellElement = () => {
    const cells = this.flickity.getCellElements()
    const selectedIndex = this.flickity.selectedIndex
    return cells[(selectedIndex + 1) % cells.length]
  }

  getAdjacentCellElements = () => {
    const cells = this.flickity.getCellElements()
    const selectedIndex = this.flickity.selectedIndex
    const prev = i => (
      (i + (cells.length - 1)) % cells.length
    )
    const next = i => (
      (i + 1) % cells.length
    )
    return _.uniq([
      cells[prev(prev(selectedIndex))],
      cells[prev(selectedIndex)],
      cells[selectedIndex],
      cells[next(selectedIndex)],
      cells[next(next(selectedIndex))],
    ])
  }

  loadElementImages = $element => {
    const $images = [...$element.querySelectorAll('[data-slider-image-url]')]

    if ($element.getAttribute('data-slider-image-url')) {
      $images.push($element)
    }
    _.each($images, $image => {
      $image.style.opacity = '0'
      const imageUrl = $image.getAttribute('data-slider-image-url')
      let dummyImage = new Image()

      const onLoad = () => {
        if ($image.tagName === 'IMG') {
          $image.src = imageUrl
        } else {
          $image.style.backgroundImage = `url(${imageUrl})`
        }
        $image.style.transition = 'all ease-in 0.15s'
        $image.style.opacity = '1'
        dummyImage.removeEventListener('load', onLoad)
        dummyImage = null
      }
      dummyImage.addEventListener('load', onLoad)
      dummyImage.src = imageUrl
      $image.removeAttribute('data-slider-image-url')
    })
  }

  setSliderWidth = () => {
    // We're doing this because of a chrome bug
    // (percentage width transform calculations are inaccurate)
    const width = this.$container.offsetWidth
    this.$slider.style.width = `${width}px`
  }

  renderPortal () {
    // Flickity moves children (cells) into another container
    // when initialized and that causes react errors. That's why we have
    // to create a portal and render children into the container ourselves
    // after flickity is initialized
    if (!this.state.flickityInitialized) return null

    const childrenCells = React.Children.map(this.props.children, cell => (
      React.cloneElement(cell, {
        className: classnames('slider_cell', cell.props.className)
      })
    ))
    return ReactDOM.createPortal(
      childrenCells,
      this.$slider.querySelector('.flickity-slider')
    )
  }

  render() {
    const { children, className, largeDots } = this.props
    return (
      <div
        className={classnames('slider', {
          'slider--deviceIsTouch': device.isTouch(),
          'slider--largeDots': largeDots,
        })}
        ref={el => this.$container = el}
      >
        <div
          className={classnames('slider_inner', className)}
          ref={el => this.$slider = el}
        />
        {this.renderPortal()}
      </div>
    )
  }
}

Slider.propTypes = propTypes

export default Slider
