import React, { Component, Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import * as url from '@/shared/utils/url'
import { showToast } from '@/shared/utils/toast'
import { getTimeDifference } from '@/shared/utils/dateTime'
import { sortPhotos, getPhotoUrl } from '@/shared/utils/photos'
import { updateCurrentUser } from '@/features/auth/state/actions'
import { fetchProperty, fetchRates, fetchProgramSessionRates } from '@/book/api'
import { Media, Head, PageSpinner } from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import Details from './Details'
import GuestOrOther from './GuestOrOther'
import Guest from './Guest'
import Other from './Other'
import Payment from './Payment'
import '@/book/styles/index.scss'

export const bookRoutes = [
  {
    path: "/book/:listingId/:propertyId/guest",
    element: <Guest />,
  },
  {
    path: "/book/:listingId/:propertyId/other",
    element: <Other />,
  },
  {
    path: "/book/:listingId/:propertyId/payment",
    element: <Payment />,
  },
]

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

const mapDispatchToProps = { updateCurrentUser }

const propTypes = {
  currentUser: PropTypes.object,
  updateCurrentUser: PropTypes.func.isRequired
}

const Book = (props) => {

  const location = useLocation()
  const navigate = useNavigate()
  const { listingId, propertyId } = useParams()

  const { currentUser, updateCurrentUser } = props

  const [state, setState] = useState({ property: null, rates: null })

  useEffect(() => {
    const { programSessionId, startDate, endDate, additionalFees } = getQuery()

    if (!listingId || !startDate || !endDate || !propertyId) {
      return redirectToListing()
    }
    fetchProperty(propertyId).then(({ data }) => {
      if (currentUser.id === data.user.id && programSessionId) {
        return warnAndRedirect('You can not book your own program spot.')
      }
      trackPageview(data)
      setState(prevState => ({
        ...prevState,
        property: data 
      }))
    }, redirectToListing)

    if (programSessionId) {
      fetchProgramSessionRates(programSessionId, listingId, { additionalFees }).then(({ data }) => {
        setState(prevState => ({
          ...prevState,
          rates: data
        }))
      }, redirectToListing)
    } else {
      fetchRates(listingId, { startDate, endDate, additionalFees }).then(({ data }) => {
        setState(prevState => ({
          ...prevState,
          rates: data
        }))
      }, redirectToListing)
    }
  }, [])

  const getQuery = () => (
    url.queryStringToObject(location.search)
  )

  const redirectToListing = () => navigate({
    pathname: `/properties/${propertyId}`,
    search: location.search,
  }, { replace: true })

  const warnAndRedirect = title => {
    showToast({ type: 'danger', title })
    redirectToListing()
  }

  const trackPageview = (property, error) => {
    analytics.pageview('Booking Wizard', {
      ...getAnalyticsProps(property),
      error,
    })
  }

  const trackEvent = (eventName, eventProps) => {
    analytics.track(eventName, {
      ...getAnalyticsProps(),
      ...eventProps
    })
  }

  const getAnalyticsProps = (property = state.property) => {
    const { startDate, endDate, additionalFees } = getQuery()
    return {
      hostId: props.currentUser.id,
      listingId: listingId,
      propertyId: propertyId,
      propertyTitle: _.get(property, 'title'),
      placementType: _.get(property, 'placementType'),
      additionalFees,
      startDate,
      endDate,
      stayDuration: getTimeDifference(endDate, startDate),
    }
  }
  
  const detailsRoutes = ["/guest", "/other", "/payment"]

  const { property, rates } = state
  const isImportingBooking = currentUser.id === property?.user?.id
  const query = getQuery()
  return (
    <div className="book">
      {property && (
        <Head
          title={`Book ${property.title} - Roomsie`}
          image={getPhotoUrl(sortPhotos(property.photos)[0].imageUrl, 'medium')}
          url={`/properties/${property.id}`}
        />
      )}
      <AppHeader />
      {property && rates ? (
        <div className="book_page">
          {!detailsRoutes.some(path => location.pathname.includes(path)) && 
            <Fragment>
              <GuestOrOther
                query={getQuery()}
                isImportingBooking={isImportingBooking}
              />
            </Fragment>
          }
          <Outlet
            context={[ 
              property,
              currentUser,
              updateCurrentUser,
              trackEvent,
              warnAndRedirect,
              query,
              isImportingBooking,
              rates
            ]} 
          />
          {!location.pathname.includes("payment") ? (
            <Media
              query="(min-width: 768px)" 
              match={() => (
                <Details
                  listingId={listingId}
                  property={property}
                  rates={rates}
                  query={getQuery()}
                />
              )} 
            /> ) : (
            <Details
              listingId={listingId}
              property={property}
              rates={rates}
              query={getQuery()}
            />
          )}
        </div>
      ) : (
        <PageSpinner />
      )}
    </div>
  )
}

Book.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(Book)
