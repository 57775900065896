import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@/shared/components'
import '@/shared/styles/infoBanner.scss'

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  renderButton: PropTypes.func,
}

const defaultProps = {
  renderButton: () => null,
}

class InfoBanner extends Component {

  state = { isOpen: true }

  render() {
    return this.state.isOpen && (
      <div className={classnames('infoBanner', this.props.className)}>
        <Icon type="lightbulb" />
        <Icon type="close" onClick={() => this.setState({ isOpen: false })} />
        {this.props.title && <div className="infoBanner_title">{this.props.title}</div>}
        {this.props.message && <p className="infoBanner_message">{this.props.message}</p>}
        {this.props.renderButton()}
      </div>
    )
  }
}

InfoBanner.propTypes = propTypes
InfoBanner.defaultProps = defaultProps

export default InfoBanner
