import React from 'react'
import PropTypes from 'prop-types'
import '@/shared/styles/textEditedHtml.scss'

const propTypes = {
  html: PropTypes.string.isRequired,
}

const TextEditedHtml = ({ html }) => (
  <div
    className="textEditedHtml"
    dangerouslySetInnerHTML={{ __html: html }}
  />
)

TextEditedHtml.propTypes = propTypes

export default TextEditedHtml
