import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@/shared/components'
import '@/listSpace/styles/prevNext.scss'

const propTypes = {
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
}

const PrevNext = ({ onPrevClick, onNextClick }) => (
  <div className="listSpace_prevNext">
    <div className="listSpace_prevNext_inner">
      {onPrevClick && (
        <Button
          className="listSpace_prevNext_prev"
          type="button"
          color="secondary"
          onClick={onPrevClick}
        >
          Back
        </Button>
      )}
      <Button
        className="listSpace_prevNext_next"
        onClick={onNextClick}
      >
        Next
      </Button>
    </div>
  </div>
)

PrevNext.propTypes = propTypes

export default PrevNext
