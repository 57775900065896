import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { reassignBooking } from '@/guestBookings/api'
import { showToast } from '@/shared/utils/toast'
import { Modal, Field, FieldLabel, ConfirmModal, Button } from '@/shared/components'
import '@/guestBookings/styles/reassignBooking.scss'

const propTypes = {
  booking: PropTypes.object.isRequired,
  fetchBooking: PropTypes.func.isRequired,
  renderLink: PropTypes.func.isRequired,
}

const c = 'guestBooking_reassignBooking'

const getInitialState = () => ({ email: '', error: null, isSubmitting: false })

class ReassignBooking extends Component {

  state = getInitialState()

  handleSubmit = confirmModal => {
    this.setState({ isSubmitting: true })

    reassignBooking(this.props.booking.id, {
      email: this.state.email,
    }).then(() => {
      this.props.fetchBooking(() => {
        this.setState(getInitialState())
        confirmModal.close()
        showToast({ title: 'Guest email was updated successfully.' })
      })
    }, ({ error }) => {
      if (error.details) {
        this.setState({ error: error.details.email })
      } else {
        handleError({ error })
      }
      this.setState({ isSubmitting: false })
      confirmModal.close()
    })
  }

  isEmailValid = () => /.+@.+/.test(this.state.email)

  render() {
    return (
      <Modal
        className={c}
        renderLink={this.props.renderLink}
        renderContent={() => (
          <Fragment>
            <div className={`${c}_title`}>Change guest email</div>
            <FieldLabel>Current guest email is</FieldLabel>
            <div className={`${c}_email`}>
              {this.props.booking.guest.email}
            </div>
            <FieldLabel>Enter new email</FieldLabel>
            <div className={`${c}_form`}>
              <Field.Input
                errors={this.state.error}
                value={this.state.email}
                onChange={(e, email) => this.setState({ email })}
              />
              {this.isEmailValid() ? (
                <ConfirmModal
                  dontCloseOnConfirm
                  message="Are you sure you want to update the email address and create a new account for the guest?"
                  renderLink={() => (
                    <Button square working={this.state.isSubmitting}>Update</Button>
                  )}
                  onConfirm={confirmModal => this.handleSubmit(confirmModal)}
                />
              ) : (
                <Button square disabled>Update</Button>
              )}
            </div>
          </Fragment>
        )}
      />
    )
  }
}

ReassignBooking.propTypes = propTypes

export default ReassignBooking
