import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import options from '@/shared/static/options'
import { Tooltip, Button, Icon, CheckboxGroup, Checkbox } from '@/shared/components'

const propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilterChanges: PropTypes.func.isRequired,
  cancelFilterChanges: PropTypes.func.isRequired,
  clearFilterSection: PropTypes.func.isRequired,
}

const typeDescriptions = {
  entire_space: 'Have the whole unit all to yourself',
  private_bedroom: 'Have a private bedroom and share common spaces',
  shared_bedroom: 'Split a bedroom among friends or other roomies',
}

const c = 'search_filters_accommodation'

const Accommodation = props => (
  <Tooltip
    className={`search_filters_tooltip ${c}`}
    placement="bottom"
    offset={{ top: -20 }}
    windowBounds={{ bottom: true }}
    onClose={props.cancelFilterChanges}
    renderLink={() => (
      <div className="search_filters_link">
        <Icon type="home" />
        {!props.filters.privacyTypes.length ? (
          'Room Type'
        ) : (
          `Room Type · ${props.filters.privacyTypes.length}`
        )}
      </div>
    )}
    renderContent={({ closeTooltip }) => (
      <Fragment>
        <CheckboxGroup
          className={`${c}_checkboxGroup`}
          value={props.filters.privacyTypes}
          onChange={value => props.onFilterChange({
            privacyTypes: value,
            bedsCount: value.find(t => t === 'shared_bedroom') ? props.filters.bedsCount : [],
          })}
          render={({ isChecked, onCheck }) => (
            options.privacyTypes.map(({ value, label }) => (
              <Fragment key={value}>
                <Checkbox
                  name="privacyTypes"
                  value={value}
                  label={label}
                  checked={isChecked(value)}
                  onChange={onCheck}
                />
                <div className={`${c}_tip`}>
                  {typeDescriptions[value]}
                </div>
              </Fragment>
            ))
          )}
        />
        {props.filters.privacyTypes.includes('shared_bedroom') && (
          <CheckboxGroup
            className={`${c}_bedsCount_checkboxGroup`}
            value={props.filters.bedsCount}
            onChange={value => props.onFilterChange({ bedsCount: value })}
            render={({ isChecked, onCheck }) => (
              <Fragment>
                <div className={`${c}_bedsCount_occupancy`}>
                  Occupancy
                </div>
                <div className={`${c}_bedsCount_tip`}>
                  How many roomies would you like to share your room with?
                </div>
                {options.bedsCount.slice(1).map(({ value, label, description }) => (
                  <Fragment key={value}>
                    <Checkbox
                      name="bedsCount"
                      value={value}
                      label={label}
                      checked={isChecked(value)}
                      onChange={onCheck}
                    />
                    <div className={`${c}_bedsCount_tip`}>
                      {description}
                    </div>
                  </Fragment>
                ))}
              </Fragment>
            )}
          />
        )}
        <Button size="small" onClick={() => {
          props.applyFilterChanges()
          closeTooltip()
        }}>
          Apply
        </Button>
        <Button hollow size="small" color="secondary" onClick={() => {
          props.clearFilterSection('accommodation')
        }}>
          Clear
        </Button>
      </Fragment>
    )}
  />
)

Accommodation.propTypes = propTypes

export default Accommodation
