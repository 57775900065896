import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RadioToggle from '../RadioToggle';

const propTypes = {
  handlePlacementTypeChange: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
};

const typeDescription = {
  female: 'Female guests only',
  male: 'Male guests only',
  unisex: 'Both female and male guests are welcome',
};

const c = 'plugin_filters_placement';

class Placement extends Component {
  renderRadioBox = (props) => (
    <label
      className={classnames(`${c}_radioBox`, {
        [`${c}_radioBox--isChecked`]: props.checked,
      })}
    >
      <RadioToggle {...props} />
      <div className={`${c}_radioBox_description`}>{props.description}</div>
    </label>
  );

  render() {
    const { handlePlacementTypeChange, query } = this.props;

    return (
      <div className={c}>
        <div className={`${c}_radioBox_row`}>
          {this.renderRadioBox({
            label: 'Co-ed',
            name: 'placementType',
            value: 'unisex',
            checked: query.placementTypes === 'unisex',
            onChange: (e, value) => handlePlacementTypeChange(value),
          })}
          {this.renderRadioBox({
            label: 'Female',
            name: 'placementType',
            value: 'female',
            checked: query.placementTypes === 'female',
            onChange: (e, value) => handlePlacementTypeChange(value),
          })}
          {this.renderRadioBox({
            label: 'Male',
            name: 'placementType',
            value: 'male',
            checked: query.placementTypes === 'male',
            onChange: (e, value) => handlePlacementTypeChange(value),
          })}
        </div>
      </div>
    );
  }
}

Placement.propTypes = propTypes;

export default Placement;
