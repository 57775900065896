import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as analytics from '@/shared/utils/analytics';
import { to404 } from '@/shared/utils/404';
import { scrollToElement } from '@/shared/utils/dom';
import { availableToUnavailableDates } from '@/shared/utils/availability';
import {
  getTimeDifference,
  stringsToDateRange,
  dateRangeToStrings,
} from '@/shared/utils/dateTime';
import { formatPrice } from '@/shared/utils/numbers';
import { handleError } from '@/shared/api';
import {
  fetchProgramSessionListingIds,
  fetchPropertiesByBuilding,
} from '@/property/api';
import { fetchAvailabilityByBuildings } from '../api';
import { showToast } from '@/shared/utils/toast';
import {
  Field,
  PageSpinner,
  Radio,
  DateRangePicker,
  Icon,
  FieldErrors,
  Spinner,
  BookingFeesGuest,
} from '@/shared/components';
import { DepositInfo, FieldsRequired, PolicyInfo } from './StaticInfo';
import Filters from './filters';
import RoomTypeFilters from './RoomTypeFilters';
import Bedrooms from './Bedrooms';
import classnames from 'classnames';
import PrevNext from './PrevNext';
import '@/plugin/property/styles/room.scss';

const propTypes = {
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  buildings: PropTypes.array.isRequired,
  buildingId: PropTypes.string.isRequired,
  setBuildingId: PropTypes.func.isRequired,
  sessions: PropTypes.array,
  selectedBedroom: PropTypes.object,
  setSelectedBedroom: PropTypes.func.isRequired,
  userInput: PropTypes.object.isRequired,
  updateUserInput: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  validateStep: PropTypes.func.isRequired,
  clearStepErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const c = 'plugin_room';

class Room extends Component {
  state = {
    selectedDatesListings: null,
    allDatesListings: null,
    availability: null,
    sortedProperties: null,
    property: null,
    placementSelected: false,
    datesSelected: false,
    allListingsUnavailable: false,
  };

  componentDidMount() {
    this.buildingId = this.props.buildingId;

    if (this.props.query.placementTypes) {
      this.setState({ placementSelected: true });
    }
    if (this.props.query.startDate && this.props.query.endDate) {
      this.setState({ datesSelected: true });
    }

    fetchPropertiesByBuilding(this.buildingId).then(
      ({ data }) => {
        this.fetchAvailability();
        this.setState({ sortedProperties: data.sortedBedrooms });
        this.setState({ property: data }, () => {
          analytics.pageview('Property', this.getAnalyticsProps());
        });
      },
      () => {
        to404('We could not find this property — perhaps it was deleted.');
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.query.placementTypes !== prevProps.query.placementTypes) {
      this.fetchAvailability();
      if (this.props.query.placementTypes) {
        this.setState({ placementSelected: true });
      } else {
        this.setState({ placementSelected: false });
      }
    }
    if (
      this.props.query.startDate !== prevProps.query.startDate ||
      this.props.query.endDate !== prevProps.query.endDate
    ) {
      this.fetchAvailability();
      if (this.props.query.startDate && this.props.query.endDate) {
        this.setState({ datesSelected: true });
      } else {
        this.setState({ datesSelected: false });
      }
    }
    if (
      this.props.query.selectedListingId !== prevProps.query.selectedListingId
    ) {
      this.fetchAvailability();
    }
  }

  fetchAvailability = () => {
    const { startDate, endDate, placementTypes } = this.props.query;
    fetchAvailabilityByBuildings(this.buildingId, {
      startDate,
      endDate,
    }).then(({ data }) => {
      if (this.props.query.selectedListingId) {
        let selectedListing = data.filter(
          (o) => o.listingId === parseInt(this.props.query.selectedListingId)
        );
        this.setState({
          selectedDatesListings: this.availabilityToListings(data),
        });
        this.setState({
          allDatesListings: this.availabilityToListings(selectedListing),
        });
      } else {
        this.setState({
          selectedDatesListings: this.availabilityToListings(data),
        });
        this.setState({
          allDatesListings: this.availabilityToListings(data),
        });
        this.trackEvent('Property Reloaded');
      }
      this.setState({ availability: data });
    }, handleError);
  };

  fetchProgramSessionListingIds = () => {
    fetchProgramSessionListingIds(
      this.props.query.programSessionId,
      this.buildingId
    ).then(({ data }) => {
      this.setState({
        selectedDatesListings: _.keyBy(data),
        allDatesListings: _.keyBy(data),
      });
    }, handleError);
  };

  availabilityToListings = (allAvailability) => {
    const getData = (availability) => ({
      availableDates: availability,
      minRate: _.min(availability.map((a) => a.rate)),
      minStayDays: _.min(availability.map((a) => a.minimumStayDays)),
    });
    const listings = getData(allAvailability);
    _.each(
      _.groupBy(allAvailability, 'listingId'),
      (listingAvailability, listingId) => {
        listings[listingId] = {
          id: listingId,
          ...getData(listingAvailability),
        };
      }
    );
    return listings;
  };

  getMinStayDays = () => {
    if (this.props.query.selectedListingId) {
      let selectedListing = this.state.availability?.find(
        (listing) => listing.listingId === this.props.query.selectedListingId
      );
      return selectedListing?.minimumStayDays;
    } else {
      return this.state.allDatesListings?.minStayDays;
    }
  };

  isRoomUnavailable = (room) => room.beds.every(this.isBedUnavailable);

  isBedUnavailable = (bed) => {
    if (this.props.query.startDate && this.props.query.endDate) {
      return !this.state.selectedDatesListings[bed.listing.id];
    }
    return !this.state.allDatesListings[bed.listing.id];
  };

  getAllDisabledListingsDates = () =>
    availableToUnavailableDates(
      this.state.allDatesListings?.availableDates.map(stringsToDateRange)
    );

  filterListingsByPlacement = (availability) => {
    let listings = [];
    if (this.props.query.placementTypes) {
      listings = availability?.filter(
        (listing) =>
          listing.pluginInfo.placementType === this.props.query.placementTypes
      );
    } else {
      listings = availability;
    }
    return listings;
  };

  getAnalyticsProps = () => {
    const { property } = this.state;
    const { startDate, endDate } = this.props.query;

    return {
      propertyTitle: _.get(property, 'title'),
      hostId: _.get(property, 'user.id'),
      placementType: _.get(property, 'placementType'),
      isEntireSpace: this.isEntireSpace,
      bedroomsCount: _.get(property, 'bedrooms.length'),
      roomiesCount: _.get(property, 'roomies.length'),
      stayDuration: getTimeDifference(endDate, startDate),
      startDate,
      endDate,
    };
  };

  trackEvent = (eventName, eventProps = {}) => {
    analytics.track(eventName, {
      ...this.getAnalyticsProps(),
      ...eventProps,
    });
  };

  isQueryValid = () => {
    if (!this.props.query.placementTypes) {
      showToast({
        type: 'danger',
        duration: 3,
        title: `Please select a placement type`,
      });
      scrollToElement(
        document.querySelector(`.plugin_room_roomSelection`, -120)
      );
      return false;
    } else if (!this.props.query.startDate || !this.props.query.endDate) {
      showToast({
        type: 'danger',
        duration: 3,
        title: 'Please select a semester or set custom dates of stay',
      });
      scrollToElement(
        document.querySelector('.plugin_room_dateSelection', 100)
      );
      return false;
    } else if (!this.props.query.selectedListingId) {
      showToast({
        type: 'danger',
        duration: 3,
        title: `Please select a bed you're interested in booking`,
      });
      scrollToElement(
        document.querySelector(`.plugin_room_roomSelection`, -120)
      );
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = () => {
    if (this.isQueryValid()) {
      if (this.props.validateStep().isValid) {
        this.props.goToStep('next');
      }
    }
  };

  handleChange = (values) => {
    this.props.updateUserInput(values);
    this.props.clearStepErrors(_.keys(values));
  };

  handleBuildingChange = (newId) => {
    this.props.setBuildingId(newId);
  };

  handlePlacementTypeChange = (value) => {
    this.props.updateQuery({ placementTypes: value, selectedListingId: null });
    this.props.setSelectedBedroom(null);
    // set user gender
    if (value !== 'unisex') {
      this.props.updateUserInput({ gender: value });
    }
  };

  handleDatesChange = (value) => {
    if (value === 'custom_dates') {
      this.props.updateQuery({ sessionId: value, startDate: null, endDate: null });
      return;
    }
    let selectedSemester = this.props.sessions.find(
      (semester) => semester.id === parseInt(value)
    );
    this.props.updateQuery({
      sessionId: value,
      startDate: selectedSemester?.startDate,
      endDate: selectedSemester?.endDate,
      selectedListingId: null
    });
  };

  renderRadioBox = (props) => (
    <label
      className={classnames(`${c}_radioBox`, {
        [`${c}_radioBox--isChecked`]: props.checked,
      })}
    >
      <Radio {...props} />
      <div className={`${c}_radioBox_description`}>{props.description}</div>
    </label>
  );

  filterBedrooms = (bedrooms) => {
    let { placementTypes, privacyTypes, bedsCount } = this.props.query;
    let filteredBedrooms = bedrooms;

    // filter by placement type
    if (placementTypes) {
      filteredBedrooms = filteredBedrooms.filter((bedroom) => {
        return (
          bedroom.property?.placementType === placementTypes ||
          bedroom.property?.placementType === 'unisex'
        );
      });
    }

    // filter by room type
    if (privacyTypes) {
      filteredBedrooms = filteredBedrooms.filter((bedroom) => {
        return (
          privacyTypes.includes(bedroom.property?.listingType) ||
          privacyTypes.includes(bedroom.beds[0]?.listing.privacyType)
        );
      });
    }
    // filter by bedsCount
    if (privacyTypes?.includes('shared_bedroom') && bedsCount) {
      filteredBedrooms = filteredBedrooms.filter((bedroom) => {
        // don't filter out entire space and private bedrooms if those filters are checked
        if (privacyTypes) {
          if (
            privacyTypes.includes('entire_space') &&
            bedroom.bedsCount === 0
          ) {
            return true;
          }
          if (
            privacyTypes.includes('private_bedroom') &&
            bedroom.bedsCount === 1
          ) {
            return true;
          }
        }
        return bedsCount.includes(bedroom.bedsCount.toString());
      });
    }
    return filteredBedrooms;
  };

  renderBedrooms = () => {
    // all available bedrooms array
    let bedrooms = [];
    this.state.sortedProperties.forEach((data) => {
      data.bedrooms.forEach((bedroom) => bedrooms.push(bedroom));
    });

    return (
      !!this.state.sortedProperties && (
        <Fragment>
          {this.filterBedrooms(bedrooms).map((bedroom) =>
            bedroom ? (
              <div key={bedroom.id}>{this.renderBedroom(bedroom)}</div>
            ) : (
              <PageSpinner padding={220} />
            )
          )}
        </Fragment>
      )
    );
  };

  renderBedroom = (bedroom) => (
    <>
      {!!this.state.selectedDatesListings && !!this.state.sortedProperties && (
        <Bedrooms
          bedroom={bedroom}
          selectedDatesListings={this.state.selectedDatesListings}
          query={this.props.query}
          updateQuery={this.props.updateQuery}
          trackEvent={this.trackEvent}
          setSelectedBedroom={this.props.setSelectedBedroom}
        />
      )}
    </>
  );

  renderListingsUnavailableError = (availability) => {
    let bedrooms = [];
    this.state.sortedProperties.forEach((data) => {
      data.bedrooms.forEach((bedroom) => bedrooms.push(bedroom));
    });
    let filteredBedrooms = this.filterBedrooms(bedrooms);

    // extract filtered listing ids from sorted bedrooms
    let filteredBedIds = [];
    filteredBedrooms.forEach((bedroom) => {
      if (bedroom.property.listingType === 'entire_space') {
        filteredBedIds.push(bedroom.property.listing.id);
      }
      bedroom.beds.forEach((bed) => filteredBedIds.push(bed.listing.id));
    });

    // filter listings by availability (dates)
    let filteredListings = availability?.filter((listing) =>
      filteredBedIds.includes(listing.listingId)
    );

    return (
      <>
        {!!availability &&
          !!this.state.sortedProperties &&
          filteredListings.length === 0 && (
            <div className={`${c}_listingsUnavailable`}>
              <div className={`${c}_listingsUnavailable_icon`}>
                <Icon
                  type="warning-circle"
                  className={`${c}_listingsUnavailable_icon`}
                />
              </div>
              <div>
                We couldn't find any available listings. Try modifying your
                search criteria.
              </div>
            </div>
          )}
      </>
    );
  };

  render() {
    const {
      availability,
      sortedProperties,
      allDatesListings,
      placementSelected,
      datesSelected,
    } = this.state;

    const {
      userInput,
      query,
      updateQuery,
      sessions,
      buildings,
      selectedBedroom,
      rates,
    } = this.props;

    let allBuildings = buildings?.map((building) => ({
      value: building.id,
      label: building.name,
    }));

    let allSessions = sessions?.map((session) => {
      return {
        value: session.id,
        label: session.name,
      };
    });

    const isLoaded = !!allDatesListings && !!sortedProperties;

    return isLoaded ? (
      <div className={c}>
        {/*    PLACEMENT TYPE FILTERS    */}
        <div className={`${c}_placementSelection`}>
          <div className={`${c}_header`}>
            <h2>1. Choose Your Placement Type</h2>
          </div>
          <Filters
            handlePlacementTypeChange={this.handlePlacementTypeChange}
            query={query}
          />
        </div>
        {placementSelected && (
          <Fragment>
            {/*    SEMESTER/DATE SELECTION    */}
            <div className={`${c}_dateSelection`}>
              <div className={`${c}_header`}>
                <h2>2. Choose Your Semester or Custom Dates</h2>
              </div>
              <div className={`${c}_dateSelection_body`}>
                <Field.Select
                  className="dateSelection_select"
                  placeholder="Select A Semester Or Enter Custom Dates Of Stay"
                  options={[
                    ...allSessions,
                    {
                      value: 'custom_dates',
                      label: 'Enter Custom Dates of Stay',
                    },
                  ]}
                  value={query?.sessionId || ''}
                  onChange={(e, value) => this.handleDatesChange(value)}
                />
                {query?.sessionId === 'custom_dates' && (
                  <div className={`${c}_dateSelection_customDates`}>
                    <DateRangePicker
                      className={`${c}_dateRangePicker_customColors`}
                      placeholder="Check In — Check Out"
                      value={stringsToDateRange(query)}
                      onChange={(dates) => {
                        updateQuery(dateRangeToStrings(dates));
                      }}
                      disabledDays={this.getAllDisabledListingsDates()}
                      minimumStayDays={
                        query.homePage ? 0 : this.getMinStayDays()
                      }
                      getTooltip={(comp) => (this.tooltip = comp)}
                      tooltipProps={{ windowBounds: { bottom: true } }}
                    />
                    <div className={`${c}_message`}>
                      {!query.homePage &&
                        (query.startDate && query.endDate
                          ? !!this.state.selectedDatesListings?.availableDates
                              ?.length
                            ? !this.props.query.selectedListingId && (
                                <span>
                                  Please select a bed you're interested in
                                  booking
                                </span>
                              )
                            : `There are no available beds for selected dates`
                          : `Please select your arrival and departure dates`)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
        {placementSelected && datesSelected && (
          <Fragment>
            {/*    ROOM TYPE FILTERS    */}
            <div className={`${c}_roomTypeFilters`}>
              <div className={`${c}_header`}>
                <h2>3. Select Your Room Type</h2>
              </div>
              <div>
                <RoomTypeFilters query={query} updateQuery={updateQuery} />
              </div>
            </div>
            {/*    BUILDING SELECTION    */}
            <div className={`${c}_buildingSelection`}>
              <div className={`${c}_header`}>
                <h2>4. Choose Your Building</h2>
              </div>
              <div>
                <Field.Select
                  placeholder="Select A Building"
                  options={allBuildings}
                  value={query.homePage ? '' : this.buildingId}
                  onChange={(e, value) => {
                    this.handleBuildingChange(value);
                  }}
                />
              </div>
            </div>
            {/*    ROOM SELECTION    */}
            {!query.homePage && (
              <div className={`${c}_roomSelection`}>
                <div className={`${c}_header`}>
                  <h2 id={'roomHeading'}>5. Choose Your Room</h2>
                  <FieldErrors
                    className={`${c}_error`}
                    errors={this.props.errors.gender}
                  />
                  {this.renderListingsUnavailableError(availability)}
                </div>
                {this.renderBedrooms()}
              </div>
            )}
            {!!query.selectedListingId && !!selectedBedroom && !!rates && (
              <Fragment>
                <h2>Booking Fees</h2>
                <div className={`${c}_bookingFees`}>
                  <BookingFeesGuest rates={rates} />
                </div>
              </Fragment>
            )}
            <DepositInfo />
          </Fragment>
        )}
        <PolicyInfo />
        <FieldsRequired />
        <PrevNext onNextClick={this.handleSubmit} />
      </div>
    ) : (
      <div className={`${c}_spinner`}>
        <Spinner size={50} color="#F0725B" />
      </div>
    );
  }
}

Room.propTypes = propTypes;

export default Room;
