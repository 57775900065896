export const getBodyScrollTop = () => (
  window.pageYOffset ||
  document.documentElement.scrollTop ||
  document.body.scrollTop || 0
)

export const scrollToElement = ($element, offset = 0) => {
  if (!$element) return
  const elementTop = $element.getBoundingClientRect().top
  window.scrollTo(0, elementTop + getBodyScrollTop() + offset)
}

export const isElementInViewport = ($element, padding = 0) => {
  if (!$element) return
  const rect = $element.getBoundingClientRect()
  return (
    rect.top - padding >= 0 &&
    rect.bottom + padding <= window.innerHeight
  )
}
