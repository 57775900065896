import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import options from '@/shared/static/options';
import { formatPrice } from '@/shared/utils/numbers';
import { stringToDate, getTimeDifference, formatDateRange } from '@/shared/utils/dateTime';
import { sortPhotos } from '@/shared/utils/photos';
import { getBedsWithDisplayId } from '@/shared/utils/beds';
import { Slider, Icon, Button, Switch, Modal, Photo } from '@/shared/components';
import Lightbox from './Lightbox';
import '@/plugin/property/styles/bedrooms.scss';

const propTypes = {
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired,
  bedroom: PropTypes.object.isRequired,
  selectedDatesListings: PropTypes.object.isRequired,
  trackEvent: PropTypes.func.isRequired,
  setSelectedBedroom: PropTypes.func.isRequired,
};

const placementTypes = {
  female: 'Female only',
  male: 'Male only',
  unisex: 'Co-ed',
};

const c = 'plugin_property_bedrooms';
export const bedroomsClassname = c;

class Bedrooms extends Component {

  getSessionDuration = () => {
    const session = this.props.bedroom?.property?.sessions[0]
    const start = stringToDate(session?.startDate)
    const end = stringToDate(session?.endDate)

    return getTimeDifference(end, start, 'days')
  }

  handleSelectListing = (listingId) => {
    if (listingId) {
      this.props.trackEvent('Property Bedroom Selected', { listingId });
      this.props.setSelectedBedroom(this.props.bedroom);
    } else {
      this.props.trackEvent('Property Bedroom Deselected');
      this.props.setSelectedBedroom(null);
    }
    this.props.updateQuery({ selectedListingId: listingId });
  };

  handleResetDates = () => {
    this.props.updateQuery({ startDate: null, endDate: null });
  };

  isPropertyEntireSpace = (room) => room.bedsCount === 0;

  isRoomUnavailable = (room) => room.beds.every(this.isBedUnavailable);

  isBedUnavailable = (bed) => !this.props.selectedDatesListings[bed.listing.id];

  isEntireSpacePropertyUnavailable = (id) =>
    !this.props.selectedDatesListings[id];

  formattedDates = () =>
    formatDateRange(this.props.query.startDate, this.props.query.endDate);

  renderBedrooms = (bedrooms) =>
    _.sortBy(_.sortBy(bedrooms, 'id'), this.isRoomUnavailable).map((room) => (
      <div key={room.id} className={`${c}_room`}>
        <Lightbox
          photos={room.photos}
          getOpenFunc={(func) => (this[`${room.id}openLightbox`] = func)}
        />
        <Slider>
          {sortPhotos(room.photos).map((photo, i) => (
            <span key={i} onClick={() => this[`${room.id}openLightbox`](i)}>
              <Photo photo={photo} lazyLoad="data-slider-image-url" />
            </span>
          ))}
        </Slider>
        <div className={`${c}_room_content`}>
          {this.renderRoomTitleAndMeta(room)}

          <p className={`${c}_room_description`}>
            {_.truncate(room.description, { length: 220 })}
            {room.description.length > 220 && this.renderRoomModal(room)}
          </p>
        </div>
        {this.renderBeds(room)}
      </div>
    ));

  renderRoomModal = (room) => (
    <Modal
      className={`${c}_room_description_modal`}
      renderLink={() => (
        <span className={`${c}_room_description_moreLink`}>Read more</span>
      )}
      renderContent={() => (
        <Fragment>
          {this.renderRoomTitleAndMeta(room)}
          <p className={`${c}_room_description`}>{room.description}</p>
        </Fragment>
      )}
    />
  );

  renderRoomTitleAndMeta = (room) => (
    <Fragment>
      <h3 className={`${c}_room_title`}>{room.title}</h3>
      <div className={`${c}_room_meta`}>
        <span className={`${c}_room_meta_details`}>
          <Icon type="bed" />
          {room.listingType === 'entire_space'
            ? room.bedroomsCount === 1
              ? `${room.bedroomsCount} Bedroom`
              : `${room.bedroomsCount} Bedrooms`
            : `${options.labels.bedsCount[room.bedsCount]}`}
        </span>
        <span className={`${c}_room_meta_details`}>
          <Icon type="bath" />
          {room.listingType === 'entire_space'
            ? room.bathroomsCount === 1
              ? `${room.bathroomsCount} Bathroom`
              : `${room.bathroomsCount} Bathrooms`
            : `${room.bathroomAttached ? 'Attached' : 'Shared'} Bath`}
        </span>
      </div>
    </Fragment>
  );

  renderPlacementType = (room) => (
    <span className={`${c}_room_placementType`}>
      <Icon className={`${c}_room_placementIcon`} type="people" />
      Placement Type:
      <span className={`${c}_room_placementType_value`}>
        {placementTypes[room.property.placementType]}
      </span>
    </span>
  );

  renderBeds = (room) => {
    const isSelected = (bed) =>
      parseInt(this.props.query.selectedListingId) === bed.listing.id;
    return (
      <div>
        {getBedsWithDisplayId(room.beds).map((bed) => (
          <div
            key={bed.id}
            className={this.isBedUnavailable(bed) ? `${c}_hide` : `${c}_bed`}
          >
            <div className={`${c}_bed_id`}>{bed.displayId}</div>
            <div className={`${c}_bed_size`}>
              {'Bed Size: '}
              <span>{options.labels.bedSizes[bed.size]}</span>
            </div>
            <div className={`${c}_bed_type`}>
              {'Type: '}
              <span>{options.labels.bedTypes[bed.kind]}</span>
            </div>
            {!this.isBedUnavailable(bed) ? (
              <Fragment>
                <Button
                  className={
                    isSelected(bed)
                      ? `${c}_bed_button--isSelected btnRed`
                      : 'btnRed'
                  }
                  size="small"
                  onClick={() =>
                    this.handleSelectListing(
                      isSelected(bed) ? null : bed.listing.id
                    )
                  }
                >
                  {isSelected(bed) ? 'Selected' : 'Select'}
                </Button>
                {!this.props.query.programSessionId && (
                  <div className={`${c}_bed_price`}>
                    
                    {!!this.props.bedroom?.property?.prorateForShortStay && 
                      (
                        <>
                          {'From '}
                          <span>
                            {this.props.selectedDatesListings[bed.listing.id]?.minRate
                              .avgRate
                              ? formatPrice(
                                  this.props.selectedDatesListings[bed.listing.id]
                                    ?.minRate.avgRate * 30
                                )
                              : formatPrice(
                                  this.props.selectedDatesListings[bed.listing.id]
                                    ?.minRate * 30
                                )}
                          </span>
                          {` per month`}
                        </> 
                      )
                    }
                  </div>
                )}
              </Fragment>
            ) : (
              <div className={`${c}_bed_unavailableLabel`}>Not Available</div>
            )}
          </div>
        ))}
      </div>
    );
  };

  renderEntireSpaceSelection = (property) => {
    const isSelected = (property) =>
      parseInt(this.props.query.selectedListingId) === property.listing.id;
    return (
      <div>
        <div key={property.listing.id} className={`${c}_entireSpace`}>
          <div className={`${c}_entireSpace_accommodates`}>
            {`Accommodates `}
            <span>{`${property.accommodates} Guests`}</span>
          </div>

          <Fragment>
            <Button
              className={
                isSelected(property)
                  ? `${c}_bed_button--isSelected btnRed`
                  : 'btnRed'
              }
              size="small"
              onClick={() =>
                this.handleSelectListing(
                  isSelected(property) ? null : property.listing.id
                )
              }
            >
              {isSelected(property) ? 'Selected' : 'Select'}
            </Button>
            {this.props.selectedDatesListings[property.listing.id] &&
              !this.props.query.programSessionId && (
                <div className={`${c}_bed_price`}>
                  {'From'}
                  <span>
                    {this.props.selectedDatesListings[property.listing.id]
                      .minRate?.avgRate
                      ? formatPrice(
                          this.props.selectedDatesListings[property.listing.id]
                            .minRate?.avgRate * 30
                        )
                      : formatPrice(
                          this.props.selectedDatesListings[property.listing.id]
                            .minRate * 30
                        )}
                  </span>
                  {` per month :)`}
                </div>
              )}
          </Fragment>
        </div>
      </div>
    );
  };

  renderEntireSpace = (bedroom) => {
    let isPropertyUnavailable = this.isEntireSpacePropertyUnavailable(
      bedroom.property.listing.id
    );
    return (
      <div className={isPropertyUnavailable ? `${c}_hide` : `${c}`}>
        <div key={bedroom.property.id} className={`${c}_room`}>
          <Lightbox
            photos={bedroom.property.photos}
            getOpenFunc={(func) =>
              (this[`${bedroom.property.id}openLightbox`] = func)
            }
          />
          <Slider>
            {sortPhotos(bedroom.property.photos).map((photo, i) => (
              <span
                key={i}
                onClick={() => this[`${bedroom.property.id}openLightbox`](i)}
              >
                <Photo photo={photo} lazyLoad="data-slider-image-url" />
              </span>
            ))}
          </Slider>
          <div className={`${c}_room_content`}>
            {this.renderRoomTitleAndMeta(bedroom.property)}

            <p className={`${c}_room_description`}>
              {this.renderPlacementType(bedroom)}
              {_.truncate(bedroom?.property.description, { length: 220 })}
              {bedroom?.property.description?.length > 220 &&
                this.renderRoomModal(bedroom.property)}
            </p>
          </div>
          {this.renderEntireSpaceSelection(bedroom.property)}
        </div>
      </div>
    );
  };

  renderSharedSpace = (bedroom) => {
    let isRoomUnavailable = this.isRoomUnavailable(bedroom);
    return (
      <div className={isRoomUnavailable ? `${c}_hide` : `${c}`}>
        <div key={bedroom.id} className={`${c}_room`}>
          <Lightbox
            photos={bedroom.photos}
            getOpenFunc={(func) => (this[`${bedroom.id}openLightbox`] = func)}
          />
          <Slider>
            {sortPhotos(bedroom.photos).map((photo, i) => (
              <span
                key={i}
                onClick={() => this[`${bedroom.id}openLightbox`](i)}
              >
                <Photo photo={photo} lazyLoad="data-slider-image-url" />
              </span>
            ))}
          </Slider>
          <div className={`${c}_room_content`}>
            {this.renderRoomTitleAndMeta(bedroom)}

            <p className={`${c}_room_description`}>
              {this.renderPlacementType(bedroom)}
              {_.truncate(bedroom?.description, { length: 220 })}
              {bedroom?.description?.length > 220 &&
                this.renderRoomModal(bedroom)}
            </p>
          </div>
          {this.renderBeds(bedroom)}
        </div>
      </div>
    );
  };

  render() {
    const { bedroom, query, updateQuery } = this.props;
    let isPropertyEntireSpace = this.isPropertyEntireSpace(bedroom);
    return (
      <Fragment>
        {isPropertyEntireSpace
          ? this.renderEntireSpace(bedroom, query, updateQuery)
          : this.renderSharedSpace(bedroom, query, updateQuery)}
      </Fragment>
    );
  }
}

Bedrooms.propTypes = propTypes;

export default Bedrooms;
